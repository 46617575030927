import withShipperLogic from "#components/HOC/withShipperLogic";
import _ from "lodash";
import SelectWarehouseCode from "../components/common/SelectWarehouseCode";
import CurrentShippersList from "../components/shipper/CurrentShippersList";
import GenerateTruck from "../components/shipper/GenerateTruck";
import AddButton from "#components/utils/AddButton";

const Shipper = ({
  warehouses,
  currentWarehouse,
  submitWarehouseCode,
  removeWarehouse,
  shippers,
  truckIdentifier,
  setTruckIdentifier,
  generateTruck,
  currentTruck,
  generateBarcode,
  resetAllBarcodes,
  printShipperBarcode,
}) => {
  if (!currentWarehouse) {
    return (
      <>
        <SelectWarehouseCode
          warehouses={warehouses}
          onSubmit={submitWarehouseCode}
        />
      </>
    );
  }
  return (
    <>
      <div className="flex text-2xl bg-black w-full text-center text-white items-center justify-center space-x-4 p-2">
        {warehouses.find((item) => item.id === currentWarehouse)?.name}{" "}
        <AddButton text="Change" onClick={removeWarehouse} styles={["ml-4"]} />
      </div>
      <div className="mt-20">
        <GenerateTruck
          truckIdentifier={truckIdentifier}
          setTruckIdentifier={setTruckIdentifier}
          generateTruck={generateTruck}
          resetAllBarcodes={resetAllBarcodes}
        />
        <div className="flex items-center text-2xl mt-4 p-4">
          Current Truck: {currentTruck?.name ? currentTruck.name : "None"}
        </div>
        <CurrentShippersList
          shippers={shippers}
          noValuesText="No shippers. Check the selected warehouse."
          generateBarcode={generateBarcode}
          printShipperBarcode={printShipperBarcode}
        />
      </div>
    </>
  );
};

export default withShipperLogic(Shipper);
