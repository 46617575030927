import { useState, useEffect } from "react";
import FbaTransportStatus from "#components/packer/FbaTransportStatus";

const AwaitingPackingLabel = ({
  currentBatch,
  order,
  confirmTransportInformation,
  transportLoading,
  confirmDropoff,
  markOrderException,
  restartActivity,
  pauseActivity,
  getFbaLabelGenerationStatus,
  fbaLabelGenerationStatus,
}) => {
  useEffect(() => {
    if (!order?.transportFailed) {
      if (order?.source === "FBA") {
        if (currentBatch.status === "AWAITING_LABEL") {
          getFbaLabelGenerationStatus();
        }
      } else {
        confirmTransportInformation();
      }
    }
  }, []);
  return (
    <div className="w-full mt-10 flex items-center justify-center">
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg w-full">
          <div className="flex items-center justify-center pt-5 pb-5">
            {order?.source !== "FBA" && (
              <div className="border-r border-gray-400 p-5">
                <div className="rounded-full p-6 bg-primaryAccent shadow-md">
                  {" "}
                  <img
                    src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                    className="w-14"
                  />
                </div>
              </div>
            )}

            <div className="text-3xl font-medium text-454A4F py-5 px-5">
              {order?.transportFailed && order?.source === "FBA" ? (
                <div>
                  Could not send this shipment to Amazon. <br />
                  You can print the box labels and provide the tracking
                  information in the next step.
                </div>
              ) : order?.source !== "FBA" ? (
                <div>
                  Order packed successfully. <br />
                  Shipping and box labels can now be generated.
                </div>
              ) : (
                <div className="mb-2">Order packed successfully.</div>
              )}
              {order?.source !== "FBA" && (
                <div className="flex space-x-4">
                  <div
                    className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                      transportLoading ? "bg-gray-600" : "bg-2C7695"
                    }`}
                    onClick={
                      transportLoading ? {} : confirmTransportInformation
                    }>
                    {transportLoading ? "Please wait..." : "Generate Labels"}
                  </div>
                  {order?.transportFailed && (
                    <div
                      className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                        transportLoading ? "bg-gray-600" : "bg-2C7695"
                      }`}
                      onClick={
                        transportLoading ? {} : confirmTransportInformation
                      }>
                      {transportLoading ? "Please wait..." : "Print Box Labels"}
                    </div>
                  )}

                  <div
                    className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                      transportLoading ? "bg-gray-600" : "bg-red-600"
                    }`}
                    onClick={transportLoading ? {} : confirmDropoff}>
                    {transportLoading ? "Please wait..." : "Skip"}
                  </div>
                  <div
                    className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                      transportLoading ? "bg-gray-600" : "bg-red-600"
                    }`}
                    onClick={transportLoading ? {} : restartActivity}>
                    {transportLoading ? "Please wait..." : "Restart Packing"}
                  </div>
                  <div
                    className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                      transportLoading ? "bg-gray-600" : "bg-red-600"
                    }`}
                    onClick={transportLoading ? {} : pauseActivity}>
                    {transportLoading ? "Please wait..." : "Pause Packing"}
                  </div>
                </div>
              )}

              {!order?.transportFailed && order?.source === "FBA" && (
                <FbaTransportStatus
                  fbaLabelGenerationStatus={fbaLabelGenerationStatus}
                />
              )}
              {order?.source !== "FBA" && (
                <>
                  <div className="mt-4">
                    Problem with the address on the order?
                  </div>
                  <div>It needs to handled by the system admin.</div>
                  <div className="flex space-x-4">
                    <div
                      className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                        transportLoading ? "bg-gray-600" : "bg-2C7695"
                      }`}
                      onClick={transportLoading ? {} : markOrderException}>
                      {transportLoading ? "Please wait..." : "Report"}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {order?.source === "FBA" && (
          <div className="flex space-x-4">
            {order?.transportFailed && (
              <div
                className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                  transportLoading ? "bg-gray-600" : "bg-2C7695"
                }`}
                onClick={transportLoading ? {} : confirmTransportInformation}>
                {transportLoading ? "Please wait..." : "Print Box Labels"}
              </div>
            )}

            <div
              className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                transportLoading ? "bg-gray-600" : "bg-red-600"
              }`}
              onClick={transportLoading ? {} : confirmDropoff}>
              {transportLoading ? "Please wait..." : "Skip"}
            </div>
            <div
              className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                transportLoading ? "bg-gray-600" : "bg-red-600"
              }`}
              onClick={transportLoading ? {} : restartActivity}>
              {transportLoading ? "Please wait..." : "Restart Packing"}
            </div>
            <div
              className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer ${
                transportLoading ? "bg-gray-600" : "bg-red-600"
              }`}
              onClick={transportLoading ? {} : pauseActivity}>
              {transportLoading ? "Please wait..." : "Pause Packing"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AwaitingPackingLabel;
