import Barcode from "react-barcode";

const ThreeByTwoLabel = ({ id, value }) => (
  <div
    id={`${id}_3x2`}
    style={{ backgroundColor: "#fff", paddingTop: 20, paddingBottom: 20 }}
  >
    <Barcode
      value={value}
      fontSize={36}
      font="Arial"
      textMargin={-2}
      fontOptions="bold"
    />
  </div>
);

export default ThreeByTwoLabel;
