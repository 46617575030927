const ExpandedShippingAddress = ({ order }) => {
  let address = order.shippingAddress || {};
  return (
    <div className="w-max border border-gray-500 rounded-xl p-3">
      <div className="grid grid-cols-4 p-2" key={address.name}>
        <span className="font-bold">Name: </span>
        <span className="ml-2 font-medium col-span-3">{address.name}</span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.email || order.email}>
        <span className="font-bold">Email: </span>
        <span className="ml-2 font-medium col-span-3">
          {address.email || order.email}
        </span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.phone}>
        <span className="font-bold">Phone: </span>
        <span className="ml-2 font-medium col-span-3">{address.phone}</span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.line1}>
        <span className="font-bold">Line 1: </span>
        <span className="ml-2 font-medium col-span-3">{address.line1}</span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.line2}>
        <span className="font-bold">Line 2: </span>
        <span className="ml-2 font-medium col-span-3">{address.line2}</span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.city}>
        <span className="font-bold">City: </span>
        <span className="ml-2 font-medium col-span-3">{address.city}</span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.state}>
        <span className="font-bold">State: </span>
        <span className="ml-2 font-medium col-span-3">{address.state}</span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.zip}>
        <span className="font-bold">Zip: </span>
        <span className="ml-2 font-medium col-span-3">{address.zip}</span>
      </div>
      <div className="grid grid-cols-4 p-2" key={address.country}>
        <span className="font-bold">Country: </span>
        <span className="ml-2 font-medium col-span-3">{address.country}</span>
      </div>
    </div>
  );
};

export default ExpandedShippingAddress;
