const NotFound = ({ title, description, tenant }) => {
  return (
    <div className="min-h-screen min-w-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-primaryAccent to-1D3752 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            {/* <div className="flex">
              <RenderImage tenant={tenant} />
            </div> */}
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <p className="text-3xl text-red-500">
                  {title ? title : "Page Not Found"}
                </p>

                <p>
                  {description
                    ? description
                    : "Looks like the page you're looking for doesn't exist."}
                </p>
              </div>
              <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                <p>
                  <a href="/" className="text-green-600 hover:text-green-700">
                    &larr; Go Back
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderImage = ({ tenant }) => (
  <div className="flex justify-center items-center">
    {tenant && tenant.logo ? (
      <img className="h-20 w-auto" src={tenant.logo} alt="Custom Logo" />
    ) : (
      <div className="flex justify-center items-center">
        <img
          className="h-12 w-auto"
          src="https://hopstack-pub.s3.amazonaws.com/logo.png"
          alt="Hopstack"
        />
        <h1 className="font-hammersmith text-2xl text-gray-800 px-2 font-normal">
          Hopstack Inc
        </h1>
      </div>
    )}
  </div>
);

export default NotFound;
