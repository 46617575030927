import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import QRCode from "react-qr-code";
import _ from "lodash";
import WorkflowProductImages from "#components/products/WorkflowProductImages";

const DetailedView = ({
  currentItem,
  currentBatch,
  scanBarcode,
  confirmPickItem,
  skipItem,
  onChangeScannedQuantity,
  displayScan,
  setDisplayScan,
  currentProduct,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const scannedBin = currentItem.scannedBin === true;
  const scannedSubTote = currentItem.scannedSubTote === true;

  return (
    <div className="flex-col items-center justify-center max-w-7xl mx-auto">
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        <div className="grid grid-cols-3">
          <div
            className={`flex-1 bg-gray-100 w-full ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="text-xl font-medium text-454A4F space-y-2">
              <LineItemValue
                value={currentItem.binLocation}
                title="Bin Location"
                idx={2}
              />
              {currentItem.tote && (
                <LineItemValue value={currentItem.tote} title="Tote" idx={3} />
              )}
              {currentItem.bestByDate && (
                <LineItemValue
                  value={currentItem.bestByDate}
                  title="Expiry Date"
                  idx={3}
                />
              )}
              <LineItemValue
                value={currentItem.quantity}
                title="Quantity"
                idx={5}
              />
              <LineItemValue value={currentItem.sku} title="SKU" idx={3} />
              {currentItem.customAttributes?.stockMoveSize && (
                <LineItemValue
                  value={currentItem.customAttributes.stockMoveSize}
                  title="Size"
                  idx={4}
                />
              )}
              {currentItem.fnSku && (
                <LineItemValue
                  value={currentItem.fnSku}
                  title="FN SKU"
                  idx={4}
                />
              )}
              {currentItem.asin && (
                <LineItemValue value={currentItem.asin} title="ASIN" idx={5} />
              )}
              {currentItem.upc && (
                <LineItemValue
                  value={currentItem?.upc?.join(", ")}
                  title="UPC"
                  idx={5}
                />
              )}
              <LineItemValue
                value={currentItem.productName}
                title="Product Name"
                idx={4}
              />
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex-1 flex items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>

      <div
        onClick={() => {
          if (scannedBin) {
            if (currentItem.tote && !currentItem.scannedTote) {
              scanBarcode(currentItem.tote);
              return;
            }
            scanBarcode(currentItem.sku);
          } else {
            scanBarcode(currentItem.binLocation);
          }
        }}>
        <div className="mt-4 flex items-center justify-center">
          <div
            className={`mt-4 rounded-full border-4 border-FC8862 p-5 shadow-md ${
              scannedBin && scannedSubTote && "bg-gray-400 border-gray-600"
            }`}>
            <QRCode
              value={qrcodeValue(scannedBin, currentItem.binLocation)}
              size="70"
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          {scanItemMessage(scannedBin)}
        </div>
      </div>

      <div className="flex-col items-center justify-center p-10 pt-5 pb-5 bg-white mt-2">
        <div>
          <TextField
            type="text"
            id="name"
            label="Scan Bin/Tote/Item"
            placeholder=" "
            onChange={(e) => setManualValue(e.target.value)}
            value={manualValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                scanBarcode(manualValue);
                setManualValue("");
              }
            }}
            autoFocus={true}
          />
        </div>
      </div>

      {!scannedBin && (
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          Please Scan Bin Location
        </div>
      )}

      {scannedBin && currentBatch?.skuConfirmationNeeded && (
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          Please Scan Item
        </div>
      )}

      {currentItem?.scannedSkus > 0 || displayScan ? (
        <div className="grid grid-cols-2 mt-10 self-center">
          <div className="bg-white text-3xl py-2 flex items-center justify-cente p-6">
            <div className="text-3xl text-5F666B py-2">Picked Quantity</div>
          </div>
          <div className="bg-white text-3xl text-5F666B py-2 flex items-center justify-center p-6">
            <TextField
              type="number"
              id="scannedSkus"
              label="Picked Quantity"
              placeholder=" "
              onChange={(e) => {
                onChangeScannedQuantity(e.target.value);
                setDisplayScan(true);
              }}
              value={currentItem.scannedSkus}
            />
          </div>
        </div>
      ) : null}

      <div className="mt-20 flex items-center justify-center">
        {(scannedBin || currentItem.scannedSkus === 0) && (
          <div
            className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
            onClick={confirmPickItem}>
            Confirm Item
          </div>
        )}
        {!scannedBin && currentItem.scannedSkus > 0 && (
          <div className="text-2xl text-white w-64 rounded-md bg-gray-400 text-center py-4">
            Confirm Item
          </div>
        )}
        {skipItem && (
          <div
            className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4 ml-4 cursor-pointer"
            onClick={skipItem}>
            Skip Item
          </div>
        )}
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div
      className={`bg-white items-center flex flex-1 border p-4 rounded-tr-md rounded-br-md`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const qrcodeValue = (scannedBin, binLocation) => {
  if (scannedBin) {
    return `NOVALUE`;
  }

  return binLocation;
};

const scanItemMessage = (scannedBin) => {
  if (scannedBin) {
    return `Scanned`;
  }

  if (!scannedBin) {
    return "Scan Bin";
  }
};

export default DetailedView;
