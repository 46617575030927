import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import QRCode from "react-qr-code";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";

const DetailedView = ({
  currentItem,
  scanBarcode,
  confirmPickItem,
  onChangeScannedQuantity,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const isBinScanned = currentItem.isBinScanned === true;

  return (
    <div className="min-w-max flex-col items-center justify-center max-w-4xl">
      <PageTitle>Product Details</PageTitle>

      <div className="text-xl font-medium text-454A4F space-y-2 ">
        <LineItemValue
          value={currentItem.binLocation}
          title="Bin Location"
          idx={2}
        />
        {currentItem.tote && (
          <LineItemValue value={currentItem.tote} title="Tote" idx={3} />
        )}
        {currentItem.bestByDate && (
          <LineItemValue
            value={currentItem.bestByDate}
            title="Expiry Date"
            idx={3}
          />
        )}
        <LineItemValue value={currentItem.quantity} title="Quantity" idx={5} />
        <LineItemValue value={currentItem.sku} title="SKU" idx={3} />
        {currentItem.fnSku && (
          <LineItemValue value={currentItem.fnSku} title="FN SKU" idx={4} />
        )}
        {currentItem.asin && (
          <LineItemValue value={currentItem.asin} title="ASIN" idx={5} />
        )}
        {currentItem.upc && (
          <LineItemValue
            value={currentItem?.upc?.join(", ")}
            title="UPC"
            idx={5}
          />
        )}
        <LineItemValue
          value={currentItem.productName}
          title="Product Name"
          idx={4}
        />
      </div>

      <div
        onClick={() => {
          if (isBinScanned) {
            scanBarcode(currentItem.sku);
          } else {
            scanBarcode(currentItem.binLocation);
          }
        }}>
        <div className="mt-4 flex items-center justify-center">
          <div
            className={`mt-4 rounded-full border-4 border-FC8862 p-5 shadow-md ${
              isBinScanned && "bg-gray-400 border-gray-600"
            }`}>
            <QRCode
              value={qrcodeValue(isBinScanned, currentItem.binLocation)}
              size="100"
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          {scanItemMessage(isBinScanned)}
        </div>
      </div>

      <div className="flex-col items-center justify-center p-10 pt-5 pb-5 bg-white mt-2">
        <div>
          <TextField
            type="text"
            id="name"
            label="Scan Bin/Tote/Item"
            placeholder=" "
            onChange={(e) => setManualValue(e.target.value)}
            value={manualValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                scanBarcode(manualValue);
                setManualValue("");
              }
            }}
            autoFocus={true}
          />
        </div>
      </div>

      {!isBinScanned && (
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          Please Scan Bin Location
        </div>
      )}

      {isBinScanned ? (
        <div className="grid grid-cols-2 mt-10 self-center">
          <div className="bg-white text-3xl py-2 flex items-center justify-cente p-6">
            <div className="text-3xl text-5F666B py-2">Picked Quantity</div>
          </div>
          <div className="bg-white text-3xl text-5F666B py-2 flex items-center justify-center p-6">
            <TextField
              type="number"
              id="scannedQuantity"
              label="Picked Quantity"
              placeholder=" "
              onChange={(e) => onChangeScannedQuantity(e.target.value)}
              value={currentItem.scannedQuantity}
            />
          </div>
        </div>
      ) : null}

      <div className="mt-20 flex items-center justify-center">
        {(isBinScanned || currentItem.scannedQuantity === 0) && (
          <div
            className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
            onClick={confirmPickItem}>
            Confirm Item
          </div>
        )}
        {!isBinScanned && currentItem.scannedQuantity > 0 && (
          <div className="text-2xl text-white w-64 rounded-md bg-gray-400 text-center py-4">
            Confirm Item
          </div>
        )}
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div
      className={`bg-white items-center flex flex-1 border p-4 rounded-tr-md rounded-br-md`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const qrcodeValue = (isBinScanned, binLocation) => {
  if (isBinScanned) {
    return `NOVALUE`;
  }

  return binLocation;
};

const scanItemMessage = (isBinScanned) => {
  if (isBinScanned) {
    return `Scanned`;
  }

  if (!isBinScanned) {
    return "Scan Bin";
  }
};

export default DetailedView;
