import { useState } from "react";
import withProductsLogic from "#components/HOC/withProductsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import { UserAddIcon } from "@heroicons/react/outline";
import ProductsList from "#components/products/ProductsList";
import ExpandedSku from "#components/inventory/ExpandedSku";
import Pagination from "#components/common/Pagination";
import ProductForm from "#components/products/ProductForm";
import BundleAsProductForm from "#components/products/BundleAsProductForm";
import ProductFilters from "#components/products/ProductFilters";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import CustomEntityForm from "#components/common/CustomEntityForm";
import Modal from "#components/utils/Modal";
import Accordian from "#components/utils/Accordian";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import "antd/lib/tooltip/style/index.css";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "../constants/feature-flags";
import FnSkuLabelOptions from "#components/orders/FnSkuLabelOptions";
import CategoryModalForm from "#components/catalogs/CategoryModalForm";

const Products = ({
  displayProducts,
  fetchedSku,
  setFetchedSku,
  saveProduct,
  getSku,
  total,
  pageNumber,
  productSizes,
  productShapes,
  productColors,
  productCategories,
  productsTypes,
  AttributeValues,
  deleteAttributes,
  perPage,
  setPerPage,
  onSubmitCustomEntity,
  checkPagination,
  sort,
  setSort,
  filters,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  showFilters,
  setShowFilters,
  clearFilters,
  getSpecificProduct,
  addCustomEntity,
  setAddCustomEntity,
  customers,
  warehouses,
  onChangeFilter,
  onChangeAttributes,
  addNewProduct,
  selectedProduct,
  setSelectedProduct,
  onChange,
  onChangeDropdown,
  writable,
  deleteProduct,
  productDashboardFields,
  bundleDashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
  filterQueryOperator,
  setFilterQueryOperator,
  deleteImage,
  showProductForm,
  setShowProductForm,
  showBundleAsProductForm,
  setShowBundleAsProductForm,
  addBundleProduct,
  removeBundleProduct,
  availableInventory,
  onChangeInventorySearch,
  getMarketplaceProducts,
  customAttributes,
  setCustomAttributes,
  multiAccountSupportEnabled,
  getMarketplaces,
  sellerIds,
  fnSkuOptions,
  setFnSkuOptions,
  submitFnSkuOptions,
  downloadFnSkuLabels,
  printFnSkuLabels,
  isPrintLabel,
  createProductIdentifiers,
  productHasInventory,
  addNewCategory,
  setAddNewCategory,
  submitCategoryForm,
  tenant,
  convertToBundle,
}) => {
  const ldClient = useLDClient();
  const isBundlingWorkflowEnabled =
    ldClient?.variation(featureFlags.IS_BUNDLING_WORKFLOW, false) ?? false;
  const [showNewAdditionModal, setShowNewAdditionModal] = useState(false);
  const newAdditionModalDetails = [
    {
      title: "Product",
      isActive: true,
      single: {
        text: "Create Single Product",
        onClick: () => addNewProduct({ typeOfProduct: "STANDALONE" }),
      },
      bulk: {
        dashboardFields: { ...productDashboardFields },
        saveBulkUpload: (rows) => saveBulkUpload(rows, "STANDALONE"),
        errorMessage: errorMessage,
        successMessage: successMessage,
        validate: (rows) => validate(rows, "STANDALONE"),
        validationResult: validationResult,
        uploadBttnText: "Bulk Upload Products",
        templateLink:
          "https://templates-onboarding.s3.amazonaws.com/prod/Products.xlsx",
      },
    },
    {
      title: "Bundle / Kit",
      single: {
        text: "Create Single Bundle",
        onClick: () => addNewProduct({ typeOfProduct: "BUNDLE" }),
      },
      // bulk: {
      //   dashboardFields: { ...bundleDashboardFields },
      //   saveBulkUpload: (rows) => saveBulkUpload(rows, "BUNDLE"),
      //   errorMessage: errorMessage,
      //   successMessage: successMessage,
      //   validate: (rows) => validate(rows, "BUNDLE"),
      //   validationResult: validationResult,
      //   uploadBttnText: "Bulk Upload Bundles",
      //   templateLink:
      //     "https://templates-onboarding.s3.amazonaws.com/uat/Bundles.xlsx",
      // },
    },
  ];

  const ProductManagementActions = () => {
    return (
      <>
        {writable && (
          <div className="items-center justify-end flex space-x-2">
            <AddButton
              disabled={false}
              text={
                isBundlingWorkflowEnabled
                  ? "Add New Product / Bundle / Kit"
                  : "Add Product"
              }
              onClick={() =>
                isBundlingWorkflowEnabled
                  ? setShowNewAdditionModal(true)
                  : addNewProduct({ typeOfProduct: "STANDALONE" })
              }
              icon={UserAddIcon}
            />

            {filters &&
              filters["customer"] &&
              filters["customer"].length === 1 && (
                <AddButton
                  disabled={false}
                  text="Sync Products"
                  onClick={getMarketplaceProducts}
                />
              )}

            {!isBundlingWorkflowEnabled && (
              <>
                <AddButton
                  text="Download Template"
                  onClick={() =>
                    (window.location =
                      "https://templates-onboarding.s3.amazonaws.com/prod/Products.xlsx")
                  }
                />
                <UploadBulk
                  dashboardFields={{ ...productDashboardFields }}
                  saveBulkUpload={(rows) => saveBulkUpload(rows, "STANDALONE")}
                  errorMessage={errorMessage}
                  successMessage={successMessage}
                  validate={(rows) => validate(rows, "STANDALONE")}
                  validationResult={validationResult}
                />
              </>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="p-5 w-full">
      <div className="flex items-center mb-4">
        <div className="flex-1">
          <PageTitle>Product Management</PageTitle>
        </div>

        <ProductManagementActions />
      </div>

      <ProductsList
        products={displayProducts}
        noValuesText="No Products found"
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        filters={filters}
        getSku={getSku}
        getSpecificProduct={getSpecificProduct}
        customers={customers}
        onChangeFilter={onChangeFilter}
        writable={writable}
        deleteProduct={deleteProduct}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        warehouses={warehouses}
        printFnSkuLabels={printFnSkuLabels}
        downloadFnSkuLabels={downloadFnSkuLabels}
        productCategories={productCategories}
        convertToBundle={convertToBundle}
      />

      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />

      {showFilters && (
        <ProductFilters
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          productSize={productSizes}
          productShape={productShapes}
          productColor={productColors}
          productCategory={productCategories}
          productType={productsTypes}
          onChangeAttributes={onChangeAttributes}
          filterQueryOperator={filterQueryOperator}
          setFilterQueryOperator={setFilterQueryOperator}
        />
      )}

      {showProductForm && (
        <ProductForm
          selectedProduct={selectedProduct}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={selectedProduct.id ? "Edit Product" : "Add Product"}
          onClose={() => {
            setShowProductForm(false);
            setSelectedProduct(null);
          }}
          onSubmit={saveProduct}
          customers={customers}
          warehouses={warehouses}
          setSelectedProduct={setSelectedProduct}
          productSizes={productSizes}
          productShapes={productShapes}
          productColors={productColors}
          productCategories={productCategories}
          productsTypes={productsTypes}
          AttributeValues={AttributeValues}
          deleteAttributes={deleteAttributes}
          deleteImage={deleteImage}
          customAttributes={customAttributes}
          setCustomAttributes={setCustomAttributes}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          getMarketplaces={getMarketplaces}
          sellerIds={sellerIds}
          productHasInventory={productHasInventory}
          addNewCategory={addNewCategory}
          setAddNewCategory={setAddNewCategory}
          submitCategoryForm={submitCategoryForm}
        />
      )}

      {showBundleAsProductForm && (
        <BundleAsProductForm
          selectedProduct={selectedProduct}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={selectedProduct.id ? "Edit Bundle" : "Add Bundle"}
          onClose={() => {
            setShowBundleAsProductForm(false);
            setSelectedProduct(null);
          }}
          onSubmit={saveProduct}
          customers={customers}
          warehouses={warehouses}
          setSelectedProduct={setSelectedProduct}
          productSizes={productSizes}
          productShapes={productShapes}
          productColors={productColors}
          productCategories={productCategories}
          productsTypes={productsTypes}
          deleteImage={deleteImage}
          addBundleProduct={addBundleProduct}
          removeBundleProduct={removeBundleProduct}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          getMarketplaceProducts={getMarketplaceProducts}
          customAttributes={customAttributes}
          setCustomAttributes={setCustomAttributes}
          productHasInventory={productHasInventory}
          tenant={tenant}
        />
      )}

      {addCustomEntity && (
        <CustomEntityForm
          addCustomEntity={addCustomEntity}
          setAddCustomEntity={setAddCustomEntity}
          onSubmit={onSubmitCustomEntity}
          showCode={false}
        />
      )}

      {fetchedSku && !isPrintLabel && (
        <ExpandedSku
          fetchedSku={fetchedSku}
          negativeAction={() => setFetchedSku(null)}
          customers={customers}
        />
      )}

      {fnSkuOptions && isPrintLabel && fetchedSku && (
        <FnSkuLabelOptions
          fnSkuLabelOptions={fnSkuOptions}
          setFnSkuLabelOptions={setFnSkuOptions}
          positiveAction={submitFnSkuOptions}
          isSelectLabel={true}
          productIdentifiers={createProductIdentifiers(fetchedSku)}
        />
      )}

      {showNewAdditionModal && (
        <Modal
          title={`Add New Product / Bundle / Kit`}
          negativeAction={() => setShowNewAdditionModal(false)}
          minWidth={"450px"}>
          {newAdditionModalDetails.map((details) => (
            <Accordian title={details.title} isActive={details.isActive}>
              <div className="flex flex-col items-center">
                <AddButton
                  text={details.single.text}
                  onClick={details.single.onClick}
                  styles={["w-1/2", "mb-2"]}
                />
                {details.bulk && (
                  <div className="flex w-full justify-center">
                    <UploadBulk
                      dashboardFields={details.bulk.dashboardFields}
                      saveBulkUpload={details.bulk.saveBulkUpload}
                      errorMessage={details.bulk.errorMessage}
                      successMessage={details.bulk.successMessage}
                      validate={details.bulk.validate}
                      validationResult={details.bulk.validationResult}
                      uploadBttnText={details.bulk.uploadBttnText}
                      uploadBttnStyles={["ml-10", "w-1/2"]}
                    />
                    <Tooltip
                      title={
                        <span>
                          Click{" "}
                          <a
                            href={details.bulk.templateLink}
                            style={{ textDecoration: "underline" }}>
                            here
                          </a>{" "}
                          to download template
                        </span>
                      }>
                      <QuestionMarkCircleIcon className="h-8 w-8 ml-2" />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Accordian>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default withProductsLogic(Products);
