import { useState, useEffect } from "react";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import AddButton from "#components/utils/AddButton";

const formFactorList = [
  { name: "Each", size: 0 },
  { name: "Case", size: 1 },
  { name: "Carton", size: 2 },
  { name: "Pallet", size: 3 },
];

const UOMConfiguration = ({
  selectedProduct,
  setSelectedProduct,
  appState,
}) => {
  const [baseUOM, setBaseUOM] = useState(
    formFactorList.find((i) => i.name === selectedProduct.baseUom) ||
      formFactorList[0],
  );

  const [possibleTargetUOMs, setPossibleTargetUOMs] = useState(
    formFactorList.filter((formFactor) => formFactor.size > baseUOM.size),
  );

  useEffect(() => {
    const baseUOMFormFactor =
      formFactorList.find(
        (formFactor) => formFactor.name == selectedProduct.baseUom,
      ) || formFactorList[0];
    setBaseUOM(baseUOMFormFactor);
    setPossibleTargetUOMs(
      formFactorList.filter(
        (formFactor) => formFactor.size > baseUOMFormFactor.size,
      ),
    );
    setShowAddConfiguration(selectedProduct.baseUom !== largestUOM);
  }, [selectedProduct.baseUom]);
  const [showAddConfiguration, setShowAddConfiguration] = useState(true);
  const largestUOM = _.maxBy(formFactorList, "size").name;

  const updateBaseUOM = (uom) => {
    setSelectedProduct({
      ...selectedProduct,
      baseUom: uom,
      uomConfiguration: [],
    });
    const baseUOMFormFactor = formFactorList.find(
      (formFactor) => formFactor.name == uom,
    );
    setBaseUOM(baseUOMFormFactor);
    setPossibleTargetUOMs(
      formFactorList.filter(
        (formFactor) => formFactor.size > baseUOMFormFactor.size,
      ),
    );
    setShowAddConfiguration(uom !== largestUOM);
  };

  const changeBaseUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUom = name;
    setSelectedProduct({ ...clonedProduct });
  };

  const changeBaseUomQuantity = (idx, e) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUomQuantity =
      isNaN(parseInt(e.target.value)) === false
        ? parseInt(e.target.value)
        : null;
    setSelectedProduct({ ...clonedProduct });
  };

  const changeTargetUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].targetUom = name;
    setSelectedProduct({ ...clonedProduct });
    setShowAddConfiguration(name !== largestUOM);
  };

  const addConfiguration = () => {
    const existingUomConfigurations = selectedProduct.uomConfiguration.filter(
      (i) => i.isActive,
    );
    if (existingUomConfigurations.length > 0) {
      const previousUOMConfig =
        existingUomConfigurations[existingUomConfigurations.length - 1];
      if (
        !previousUOMConfig.baseUomQuantity ||
        isNaN(previousUOMConfig.baseUomQuantity) ||
        !previousUOMConfig.baseUom ||
        !previousUOMConfig.targetUom
      ) {
        appState.setAlert("Provide a valid UOM Configuration", "error", 5000);
        return;
      }
    }

    setSelectedProduct({
      ...selectedProduct,
      uomConfiguration: [
        ...existingUomConfigurations,
        {
          baseUom: "",
          baseUomQuantity: 1,
          targetUom: "",
        },
      ],
    });
    setShowAddConfiguration(
      existingUomConfigurations.length + 1 < possibleTargetUOMs.length,
    );
  };

  useEffect(() => {
    if (
      (!selectedProduct.uomConfiguration ||
        selectedProduct.uomConfiguration.length === 0) &&
      selectedProduct.baseUom !== largestUOM
    ) {
      setShowAddConfiguration(true);
    }
  }, [selectedProduct.uomConfiguration]);

  const removeUomConfiguration = (idx) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration = clonedProduct.uomConfiguration.filter(
      (_, index) => idx !== index && _.isActive,
    );
    setSelectedProduct({ ...clonedProduct });
    setShowAddConfiguration(
      clonedProduct.uomConfiguration.length < possibleTargetUOMs.length,
    );
  };

  return (
    <>
      <div className="flex space-x-4 items-center px-2">
        <div>
          <Dropdown
            placeholder={"Base Unit of Measure"}
            list={formFactorList}
            labelKey="name"
            valueKey="name"
            name="weightMeasure"
            setSelected={(uom) => updateBaseUOM(uom)}
            selectedValue={selectedProduct.baseUom}
          />
        </div>
      </div>

      <>
        {selectedProduct?.uomConfiguration?.map((uomConfiguration, idx) => {
          let baseUOMFormFactorList = [];
          let targetUOMFormFactorList = [];
          if (idx === 0) {
            baseUOMFormFactorList = [baseUOM];
            targetUOMFormFactorList = formFactorList.filter(
              (formFactor) => formFactor.size > baseUOM.size,
            );
          } else {
            const previousTargetUOM = formFactorList.find(
              (formFactor) =>
                formFactor.name ===
                selectedProduct.uomConfiguration[idx - 1].targetUom,
            );
            baseUOMFormFactorList = [previousTargetUOM];
            targetUOMFormFactorList = formFactorList.filter(
              (formFactor) => formFactor.size > previousTargetUOM.size,
            );
          }

          return (
            <UOMConfig
              uomConfiguration={uomConfiguration}
              idx={idx}
              changeBaseUomQuantity={changeBaseUomQuantity}
              baseUOMFormFactorList={baseUOMFormFactorList}
              targetUOMFormFactorList={targetUOMFormFactorList}
              changeBaseUom={changeBaseUom}
              changeTargetUom={changeTargetUom}
              removeUomConfiguration={removeUomConfiguration}
              disableInteraction={
                selectedProduct.uomConfiguration.length - 1 !== idx
              }
              key={idx}
            />
          );
        })}
      </>

      {showAddConfiguration && (
        <AddButton text="Add Configuration" onClick={addConfiguration} />
      )}
    </>
  );
};

const UOMConfig = ({
  uomConfiguration,
  idx,
  changeBaseUomQuantity,
  baseUOMFormFactorList,
  changeBaseUom,
  targetUOMFormFactorList,
  changeTargetUom,
  removeUomConfiguration,
  disableInteraction,
}) => {
  return (
    <div className="flex space-x-4 items-center">
      <div>
        <TextField
          type="number"
          id="quantity"
          label="Quantity"
          placeholder=" "
          onChange={(qty) => changeBaseUomQuantity(idx, qty)}
          value={parseInt(uomConfiguration.baseUomQuantity)}
          name="quantity"
          disabled={disableInteraction}
        />
      </div>
      <div>
        <Dropdown
          placeholder={"Base UOM"}
          list={baseUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          name="baseUom"
          setSelected={(uom) => changeBaseUom(idx, uom)}
          selectedValue={uomConfiguration.baseUom}
          disabled={disableInteraction}
        />
      </div>
      <div className="flex items-center justify-center text-2xl">=</div>
      <div>
        <Dropdown
          placeholder={"Target UOM"}
          list={targetUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          name="formFactor"
          setSelected={(uom) => changeTargetUom(idx, uom)}
          selectedValue={uomConfiguration.targetUom}
          disabled={disableInteraction}
        />
      </div>
      {!disableInteraction && (
        <div
          className="text-red-500 text-lg pl-2 font-bold cursor-pointer"
          onClick={() => removeUomConfiguration(idx)}>
          Remove
        </div>
      )}
    </div>
  );
};

export default UOMConfiguration;
