import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import Toggle from "#components/utils/Toggle";
import { useEffect, useState } from "react";
import { useCodeSuggestion } from "#hooks/useCodeSuggestion";
import { Tooltip } from "antd";
import "antd/lib/tooltip/style/index.css";

const WarehouseForm = ({
  onClose,
  title,
  onChange,
  onChangeAdress,
  selectedWarehouse,
  onSubmit,
  warehouses,
  setSelectedWarehouse,
}) => {
  const [codesList, setCodesList] = useState([]);
  const {
    code,
    error,
    usingGeneratedCode,
    setUsingGeneratedCode,
    handleNameChange,
  } = useCodeSuggestion();

  useEffect(() => {
    setCodesList(warehouses.map((warehouse) => warehouse.code));
  }, [warehouses]);

  useEffect(() => {
    if (usingGeneratedCode) {
      setSelectedWarehouse({
        ...selectedWarehouse,
        code: error?.error ? "" : code,
      });
    }
  }, [code]);

  useEffect(() => {
    if (selectedWarehouse.name == "") {
      setSelectedWarehouse({ ...selectedWarehouse, code: "" });
    }
    if (selectedWarehouse.id) {
      setUsingGeneratedCode(false);
    }
  }, [selectedWarehouse.name, selectedWarehouse.id]);

  const handleOnChangeForName = (e) => {
    !selectedWarehouse?.id && handleNameChange(e.target.value, codesList);
    onChange(e);
  };
  const handleOnChangeForCode = (e) => {
    setUsingGeneratedCode(false);
    onChange(e);
  };

  return (
    <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Warehouse Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name"
            placeholder="Name"
            onChange={handleOnChangeForName}
            value={selectedWarehouse.name}
          />
        </div>

        <div>
          <Tooltip title={"0-9 digits, A-Z letters are allowed."}>
            <TextField
              type="text"
              id="code"
              label="Code"
              placeholder=" "
              onChange={handleOnChangeForCode}
              value={
                usingGeneratedCode
                  ? error?.error
                    ? ""
                    : code
                  : selectedWarehouse.code?.toUpperCase()
              }
            />
          </Tooltip>
        </div>
        {usingGeneratedCode && !(error?.error || code == "") && (
          <div className="italic text-sm">
            *This is a generated code. You can still edit it.
          </div>
        )}
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Warehouse Contact Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="email"
            label="Email"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.email}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="phone"
            label="Phone"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.phone}
          />
        </div>
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Warehouse Address Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="line1"
            label="Street Address 1"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.line1}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="line2"
            label="Street Address 2"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.line2}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="city"
            label="City"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.city}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="state"
            label="State / Province"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.state}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="zip"
            label="ZIP code"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.zip}
          />
        </div>
        <div>
          <TextField
            type="text"
            id="country"
            label="Country"
            placeholder=" "
            onChange={onChangeAdress}
            value={selectedWarehouse.address?.country}
          />
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Type of warehouse
            </span>
          </label>
          <MultiSelectAutoComplete
            options={[
              { name: "B2B" },
              { name: "D2C" },
              { name: "PREP CENTER" },
            ]}
            labelKey="name"
            valueKey="name"
            setValues={(e) =>
              onChange({
                target: {
                  name: "typeOfWarehouse",
                  value: e,
                },
              })
            }
            values={selectedWarehouse.typeOfWarehouse || []}
          />
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Storage Types
            </span>
          </label>
          <MultiSelectAutoComplete
            options={[{ name: "Ambient" }, { name: "Chilled" }]}
            labelKey="name"
            valueKey="name"
            setValues={(e) =>
              onChange({
                target: {
                  name: "storageTypes",
                  value: e,
                },
              })
            }
            values={selectedWarehouse.storageTypes || []}
          />
        </div>
        <div className="flex space-x-4 items-center px-2">
          <div>
            <Toggle
              enabled={selectedWarehouse.active}
              setEnabled={(e) =>
                onChange({
                  target: {
                    name: "active",
                    value: e,
                  },
                })
              }
            />
          </div>
          <div className="text-lg">Active</div>
        </div>
      </div>
    </Modal>
  );
};

export default WarehouseForm;
