import TextField from "#components/utils/TextField";
import _ from "lodash";
import FileBase64 from "react-file-base64";
import Dropdown from "#components/utils/Dropdown";

const UploadFileWithSource = ({
  label = "Shipping Label",
  setFileData,
  fileData,
  uploadFile,
  noSystemGenerated = false,
}) => {
  const labelSources = [{ name: "Public URL" }, { name: "Upload File" }];
  if (!noSystemGenerated) {
    labelSources.unshift({ name: "System Generated" });
  }
  return (
    <>
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          {label}
        </span>
      </div>
      <>
        <div>
          <Dropdown
            placeholder={"Select Label Source"}
            list={labelSources}
            labelKey="name"
            valueKey="name"
            name="labelSource"
            setSelected={(e) => {
              setFileData({
                ...fileData,
                labelSource: e,
              });
            }}
            selectedValue={fileData.labelSource}
          />
        </div>
      </>
      <div className="space-y-4">
        {fileData.labelSource === "Public URL" && (
          <div>
            <TextField
              type="text"
              id="labelUrl"
              label="Label File"
              placeholder=" "
              onChange={(e) => {
                setFileData({
                  ...fileData,
                  labelUrl: e.target.value,
                });
              }}
              value={fileData.labelUrl}
              name="labelUrl"
            />
            <span className="font-medium italic font-montserrat text-454A4F text-sm pb-4">
              * Ensure the file is publically accessible via the internet.
            </span>
          </div>
        )}
        {fileData.labelSource === "Upload File" && (
          <div>
            <FileBase64
              multiple={false}
              onDone={uploadFile}
              accept=".xls,.xlsx"
            />
            <br />
            <span className="font-medium italic font-montserrat text-454A4F text-sm pb-4">
              * Accepted formats (PNG, JPG, PDF)
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadFileWithSource;
