import Modal from "#components/utils/Modal";
import _ from "lodash";

const ReceiverNotes = ({ onClose, title, onChange, notes, onSubmit }) => (
  <Modal
    title={title}
    negativeAction={onClose}
    positiveAction={onSubmit}
    id="userFormModal">
    <div className="space-y-4">
      <div>
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Notes
          </span>
        </label>
        <textarea
          id="notes"
          rows="4"
          cols="50"
          onChange={onChange}
          name="notes"
          value={notes}
        />
      </div>
    </div>
  </Modal>
);

export default ReceiverNotes;
