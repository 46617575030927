import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, PrinterIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import Pagination from "#components/common/Pagination";
import { useEffect, useState } from "react";
import QuickFilters from "../common/QuickFilters";

const CurrentShippersList = ({
  shippers,
  noValuesText,
  generateBarcode,
  printShipperBarcode,
}) => {
  const headers = ["Shipper", "Shipping Bag #", "Barcode", "Action"];
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      <CustomTableWithTheme>
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {shippers.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {shippers &&
            shippers.map((shipper, index) => (
              <tr
                key={shipper.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {shipper.name}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {shipper.currentBin}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {shipper.currentBarcode}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  <div className="flex space-x-4 items-center">
                    <TableFieldButton
                      onClick={() => generateBarcode(shipper)}
                      text={"Generate"}
                    />

                    <TableFieldButton
                      text={<PrinterIcon className="w-6 h-6" />}
                      onClick={() => printShipperBarcode(shipper)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default CurrentShippersList;
