import { useState } from "react";

function Carousel({ images }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  const slideStyle = {
    transform: `translateX(-${activeIndex * 100}%)`,
    transition: "transform 0.3s ease-in-out",
  };

  return (
    <div className="relative max-w-2xl mx-auto overflow-hidden">
      <div className="flex" style={slideStyle}>
        {images &&
          images.map((url, index) => (
            <div key={index} className="flex-none w-full">
              <img src={url} alt={`Image ${index + 1}`} className="w-full" />
            </div>
          ))}
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        {images &&
          images.map((_, index) => (
            <button
              key={index}
              className={`h-4 w-4 rounded-full ${
                activeIndex === index ? "bg-primaryAccent" : "bg-[#DFEEFD]"
              }`}
              onClick={() => goToSlide(index)}
              aria-label={`Go to slide ${index + 1}`}></button>
          ))}
      </div>
    </div>
  );
}

export default Carousel;
