import { useState } from "react";
import Select from "react-select";

const SelectWarehouseCustomerCode = ({ customers, warehouses, onSubmit }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  const handleOnSubmit = () => {
    if (selectedWarehouse && selectedCustomer) {
      return onSubmit({
        warehouse: selectedWarehouse,
        customer: selectedCustomer,
      });
    }
  };

  return (
    <div className="w-full h-screen -mt-20 flex items-center justify-center">
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg">
          <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
            <Select
              options={
                warehouses &&
                warehouses.map((item) => ({
                  value: item.id,
                  label: `${item.name} - ${item.code}`,
                }))
              }
              value={selectedWarehouse}
              onChange={(e) => setSelectedWarehouse(e)}
              className="w-96"
              placeholder="Select Warehouse"
              isClearable={true}
            />
            <div className="mt-4">
              <Select
                options={
                  customers &&
                  customers.map((item) => ({
                    value: item.id,
                    label: `${item.name} - ${item.code}`,
                  }))
                }
                value={selectedCustomer}
                onChange={(e) => setSelectedCustomer(e)}
                className="w-96"
                placeholder="Select Client"
                isClearable={true}
              />
            </div>
            <div className="flex space-x-2">
              <div
                className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 ${
                  selectedCustomer && selectedWarehouse
                    ? "bg-2C7695"
                    : "bg-gray-400"
                }`}
                onClick={handleOnSubmit}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectWarehouseCustomerCode;
