import withEmailVerificationLogic from "#components/HOC/withEmailVerificationLogic";
import PageTitle from "#components/utils/PageTitle";

const EmailVerification = ({}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Verifying your email...</PageTitle>
      </div>
    </div>
    <div className="w-96 flex space-x-4 mb-5">
      Please wait, while we verify your email.
    </div>
  </div>
);

export default withEmailVerificationLogic(EmailVerification);
