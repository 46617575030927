import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import ModalButton from "#components/utils/ModalButton";

const ConsignmentFilters = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  onSubmit,
  negativeAction,
  selectedCombinedOrder,
  masterData,
  onChangeFilter,
  filters,
  outboundExceptions,
  customers,
  warehouses,
  clearFilters,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="flex space-x-6 mt-4">
      <ModalButton
        onClick={clearFilters}
        text={`Reset Filters`}
        className="bg-red-500"
      />
    </div>
    <div className="flex space-x-6 mt-4">
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Start Date
          </span>
        </label>
        <TextField
          type="date"
          label="Select Date"
          placeholder=" "
          onChange={(e) => onChangeFilter("startDate", e.target.value)}
          value={filters["startDate"]}
        />
      </div>
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            End Date
          </span>
        </label>
        <TextField
          type="date"
          label="Select Date"
          placeholder=" "
          onChange={(e) => onChangeFilter("endDate", e.target.value)}
          value={filters["endDate"]}
        />
      </div>
    </div>
    <div className="flex space-x-6 justify-between mt-4">
      <>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Status
            </span>
          </label>
          <MultiSelectAutoComplete
            options={[
              { name: "DRAFT" },
              { name: "UNPROCESSED" },
              { name: "RECEIVING_STARTED" },
              { name: "COMPLETED" },
              { name: "CLOSED" },
            ]}
            labelKey="name"
            valueKey="name"
            setValues={(e) => onChangeFilter("status", e)}
            values={filters["status"]}
            rounded={true}
          />
        </div>
      </>
      <>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Client(s)
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey="name"
            valueKey="id"
            setValues={(e) => onChangeFilter("customers", e)}
            values={filters["customers"]}
            rounded={true}
          />
        </div>
        <div className="flex space-x-6 justify-between mt-4">
          <>
            <div className="flex-1">
              <label className="block text-left mb-2">
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Order Type
                </span>
              </label>
              <MultiSelectAutoComplete
                options={[
                  { name: "Regular", key: "Regular" },
                  { name: "Stock Transfer", key: "STO" },
                ]}
                labelKey="name"
                valueKey="key"
                setValues={(e) => onChangeFilter("orderType", e)}
                values={filters["orderType"]}
                rounded={true}
              />
            </div>
          </>
        </div>
      </>
    </div>
    <div className="flex space-x-6 justify-between mt-4"></div>
  </Modal>
);

export default ConsignmentFilters;
