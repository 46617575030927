import ModalV3 from "#components/utils/ModalV3";
import {
  XIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";

const CategoryModalForm = ({
  addNewCategory,
  setAddNewCategory,
  componentAlert,
  submitCategoryForm,
  setComponentAlert,
}) => {
  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }
    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] p-4 rounded-md flex items-center border-[${borderColor}] border-2 mb-2`}>
        <span className="mr-2 flex-1 flex items-center text-lg space-x-2">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="text-[#717679] font-bold float-right flex items-center justify-center"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };
  return (
    <ModalV3 isOpen={true} onClose={() => setAddNewCategory(null)}>
      <div className="p-4 min-w-3xl modalV3">
        <div className="flex items-center justify-between pb-4 font-inter">
          <span className="font-bold text-2xl text-[#454A4F] font-inter">
            {addNewCategory.id ? "Edit Category" : "Create Category"}
          </span>
          <XIcon
            className="w-6 h-6 cursor-pointer"
            onClick={() => setAddNewCategory(null)}
          />
        </div>
        {componentAlert && renderAlert(componentAlert)}
        <div className="flex flex-col space-y-4 max-w-content">
          <div>
            <span className="font-medium text-[#717679] text-lg pb-6">
              Category Name
            </span>
            <input
              type="text"
              className="border-2 border-[#DDDFE0] p-2 w-full rounded-md"
              placeholder="Enter Category Name"
              value={addNewCategory.name}
              onChange={(e) =>
                setAddNewCategory({
                  ...addNewCategory,
                  name: e.target.value.toUpperCase(),
                })
              }
            />
          </div>
          <div>
            <span className="font-medium text-[#717679] text-lg pb-6">
              Category Description
            </span>
            <textarea
              className="border-2 border-[#DDDFE0] p-2 w-full rounded-md"
              placeholder="Enter Category Description"
              value={addNewCategory.description}
              onChange={(e) =>
                setAddNewCategory({
                  ...addNewCategory,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-1 items-end justify-end space-x-2">
            <button
              className="border-primaryAccent border-2 text-primaryAccent p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
              onClick={() => setAddNewCategory(null)}>
              Cancel
            </button>
            <button
              className="bg-primaryAccent border-primaryAccent border-2 text-white p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
              onClick={submitCategoryForm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalV3>
  );
};

export default CategoryModalForm;
