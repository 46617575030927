import withSkuBinMappingsLogic from "#components/HOC/withSkuBinMappingsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import { UserAddIcon } from "@heroicons/react/outline";
import SkuBinMappingsList from "#components/skuBinMappings/SkuBinMappingsList";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import Modal from "#components/utils/Modal";
import ModalButton from "#components/utils/ModalButton";
import TextField from "#components/utils/TextField";
import Autocomplete from "#components/utils/Autocomplete";
import Dropdown from "#components/utils/Dropdown";
import Pagination from "#components/common/Pagination";
import ExpandedSku from "#components/inventory/ExpandedSku";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../utils/getFilteredCustomerList";

const SkuBinMappings = ({
  skuBinMappings,
  displaySkuBinMappings,
  total,
  pageNumber,
  checkPagination,
  perPage,
  onClick,
  uploadSkuBinMappings,
  setSkuQrCode,
  setBinBarcode,
  binBarcode,
  skuQrCode,
  printBarcode,
  printQrCode,
  setSku,
  setBinLocation,
  setSkuBinMap,
  skuDetails,
  binLocationDetails,
  skuBinMapDetails,
  submitSku,
  submitBinLocation,
  submitSkuBinMap,
  unmappedSkus,
  unmappedBinLocations,
  binLocations,
  setPerPage,
  deleteButtonClicked,
  sort,
  setSort,
  filters,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  getSku,
  fetchedSku,
  setFetchedSku,
  onChangeMultiSelect,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  customers,
  selectedCustomer,
  setSelectedCustomer,
}) => {
  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-1">
          <PageTitle>SKU-Bin Management</PageTitle>
        </div>

        <div className="flex items-center justify-end space-x-1">
          <AddButton
            text="Add SKU-Bin Map"
            onClick={() =>
              setSkuBinMap({
                sku: "",
                binLocation: "",
                quantity: 0,
              })
            }
            icon={UserAddIcon}
          />
          <label
            className={`flex items-center justify-center text-sm font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-full ring-0 focus:ring-0 outline-none focus:outline-none p-0`}
            as="label">
            <span className="w-full p-3 px-6">Upload File</span>
            <input
              type="file"
              hidden
              onChange={(e) => uploadSkuBinMappings(e.target.files[0])}
              color="primary"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </label>
        </div>
      </div>
      <div className="w-96 flex space-x-4 mb-5">
        <RoundedDropdown
          placeholder={"Warehouse"}
          list={warehouses}
          labelKey="name"
          valueKey="id"
          name="warehouse"
          selectedValue={selectedWarehouse}
          setSelected={setSelectedWarehouse}
        />
        <RoundedDropdown
          placeholder={"Client"}
          list={customers}
          labelKey="name"
          valueKey="id"
          name="customer"
          selectedValue={selectedCustomer}
          setSelected={setSelectedCustomer}
        />
      </div>
      <SkuBinMappingsList
        skuBinMappings={displaySkuBinMappings}
        headers={["SKU", "Bin Location", "Warehouses", "Clients", "Action"]}
        noValuesText="No SKU-Bin Mappings"
        binBarcodeClicked={setBinBarcode}
        skuQrCodeClicked={setSkuQrCode}
        editButtonClicked={setSkuBinMap}
        deleteButtonClicked={deleteButtonClicked}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        filters={filters}
        getSku={getSku}
        customers={customers}
        warehouses={warehouses}
      />
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
      {fetchedSku && (
        <ExpandedSku
          fetchedSku={fetchedSku}
          negativeAction={() => setFetchedSku(null)}
        />
      )}
      {skuDetails && (
        <SkuForm
          setSku={setSku}
          submitSku={submitSku}
          skuDetails={skuDetails}
        />
      )}
      {binLocationDetails && (
        <BinLocationForm
          setBinLocation={setBinLocation}
          submitBinLocation={submitBinLocation}
          binLocationDetails={binLocationDetails}
        />
      )}
      {skuBinMapDetails && (
        <SkuBinMapForm
          setSkuBinMap={setSkuBinMap}
          submitSkuBinMap={submitSkuBinMap}
          skuBinMapDetails={skuBinMapDetails}
          unmappedSkus={unmappedSkus}
          binLocations={binLocations}
          customers={customers}
          warehouses={warehouses}
        />
      )}
      {binBarcode && (
        <Modal
          negativeAction={() => setBinBarcode(null)}
          title={`Generate Barcode`}>
          <div className="space-y-4">
            <Dropdown
              placeholder={"Select Barcode Type"}
              list={[{ name: "1D Barcode" }, { name: "2D Barcode" }]}
              labelKey="name"
              valueKey="name"
              name="barcodeType"
              setSelected={(e) => {
                setBinBarcode({
                  ...binBarcode,
                  barcodeType: e,
                });
              }}
              selectedValue={binBarcode.barcodeType}
            />
            <TextField
              type="number"
              id="noOfCopies"
              label="Number of Copies"
              placeholder=" "
              onChange={(e) =>
                setBinBarcode({
                  ...binBarcode,
                  noOfCopies: e.target.value,
                })
              }
              value={binBarcode.noOfCopies}
              min="0"
            />
          </div>
          <div className="flex items-center justify-center mt-2">
            {binBarcode.barcodeType === "1D Barcode" && (
              <Barcode value={binBarcode.binLocation} />
            )}
            {binBarcode.barcodeType === "2D Barcode" && (
              <QRCode value={binBarcode.binLocation} />
            )}
          </div>

          <div className="flex items-center justify-center mt-5">
            <ModalButton onClick={printBarcode} text={"Print"} />
          </div>
        </Modal>
      )}
      {skuQrCode && (
        <Modal
          negativeAction={() => setSkuQrCode(null)}
          title={`Generate Barcode`}>
          <div className="space-y-4">
            <Dropdown
              placeholder={"Select Barcode Type"}
              list={[{ name: "1D Barcode" }, { name: "2D Barcode" }]}
              labelKey="name"
              valueKey="name"
              name="barcodeType"
              setSelected={(e) => {
                setSkuQrCode({
                  ...skuQrCode,
                  barcodeType: e,
                });
              }}
              selectedValue={skuQrCode.barcodeType}
            />
            <TextField
              type="number"
              id="noOfCopies"
              label="Number of Copies"
              placeholder=" "
              onChange={(e) =>
                setSkuQrCode({
                  ...skuQrCode,
                  noOfCopies: e.target.value,
                })
              }
              value={skuQrCode.noOfCopies}
              min="0"
            />
          </div>
          <div className="flex items-center justify-center mt-2">
            {skuQrCode.barcodeType === "1D Barcode" && (
              <Barcode value={skuQrCode.sku} />
            )}
            {skuQrCode.barcodeType === "2D Barcode" && (
              <QRCode value={skuQrCode.sku} />
            )}
          </div>
          <div className="flex items-center justify-center mt-10">
            <ModalButton onClick={printQrCode} text={"Print"} />
          </div>
        </Modal>
      )}
    </div>
  );
};

const SkuForm = ({ skuDetails, setSku, submitSku }) => {
  return (
    <Modal
      negativeAction={() => setSku(null)}
      title={`Add SKU`}
      positiveAction={submitSku}>
      <div>
        <TextField
          type="text"
          id="sku"
          label="SKU"
          placeholder="SKU"
          onChange={(e) =>
            setSku({
              ...skuDetails,
              sku: e.target.value,
            })
          }
          value={skuDetails.sku}
        />
      </div>
    </Modal>
  );
};

const BinLocationForm = ({
  setBinLocation,
  submitBinLocation,
  binLocationDetails,
}) => {
  return (
    <Modal
      negativeAction={() => setBinLocation(null)}
      title={`Add Bin Location`}
      positiveAction={submitBinLocation}>
      <div>
        <TextField
          type="text"
          id="sku"
          label="Bin Location"
          placeholder=" "
          onChange={(e) =>
            setBinLocation({
              ...binLocationDetails,
              binLocation: e.target.value,
            })
          }
          value={binLocationDetails.binLocation}
        />
      </div>
    </Modal>
  );
};

const SkuBinMapForm = ({
  setSkuBinMap,
  submitSkuBinMap,
  skuBinMapDetails,
  unmappedSkus,
  binLocations,
  customers,
  warehouses,
}) => {
  return (
    <Modal
      negativeAction={() => setSkuBinMap(null)}
      title={`Add SKU-Bin Map`}
      positiveAction={submitSkuBinMap}>
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            SKU
          </span>
        </label>
        <TextField
          type="text"
          id="sku"
          label="SKU"
          placeholder=" "
          onChange={(e) =>
            setSkuBinMap({
              ...skuBinMapDetails,
              sku: e.target.value,
            })
          }
          value={skuBinMapDetails.sku}
        />
      </div>
      <div className="flex-1 mt-3">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Bin Location
          </span>
        </label>
        <div>
          <TextField
            type="text"
            id="binLocation"
            label="Bin Location"
            placeholder="Bin Location"
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                binLocation: e.target.value,
              })
            }
            value={skuBinMapDetails.binLocation}
          />
        </div>
      </div>
      <div className="flex-1 mt-3">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Quantity
          </span>
        </label>
        <div>
          <TextField
            type="number"
            id="quantity"
            label="Quantity"
            placeholder=" "
            onChange={(e) =>
              setSkuBinMap({
                ...skuBinMapDetails,
                quantity: parseInt(e.target.value),
              })
            }
            value={skuBinMapDetails.quantity}
            min="0"
          />
        </div>
        <div>
          <div id="selectingStations">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Select Warehouses
              </span>
            </label>
            <MultiSelectAutoComplete
              options={warehouses}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOption) =>
                setSkuBinMap({
                  ...skuBinMapDetails,
                  warehouses: selectedOption,
                })
              }
              values={skuBinMapDetails.warehouses || []}
            />
          </div>
        </div>
        <div>
          <div id="selectingStations">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Select Clients
              </span>
            </label>
            <MultiSelectAutoComplete
              options={getFilteredCustomerList(
                skuBinMapDetails.warehouses,
                customers,
              )}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOption) =>
                setSkuBinMap({
                  ...skuBinMapDetails,
                  customers: selectedOption,
                })
              }
              values={skuBinMapDetails.customers || []}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withSkuBinMappingsLogic(SkuBinMappings);
