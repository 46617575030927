import withSoftwareIntegrationsLogic from "#components/HOC/withSoftwareIntegrationsLogic";
import Modal from "#components/utils/Modal";
import moment from "moment-timezone";
import IntegrationForm from "#components/integrations/IntegrationForm";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import AddButton from "#components/utils/AddButton";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import IntegrationProviders from "./IntegrationProviders";
import ManageIntegrationProvider from "./ManageIntegrationProvider";

const SoftwareIntegrations = ({
  integrationProviders,
  getProducts,
  getOrders,
  setOrders,
  setProducts,
  orders,
  products,
  connectIntegration,
  integrations,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  selectedIntegration,
  setSelectedIntegration,
  selectedIntegrationToManage,
  setSelectedIntegrationToManage,
  selectedIntegrationToConfigure,
  setSelectedIntegrationToConfigure,
  multiAccountSupportEnabled,
  configureIntegrationSubmit,
  onChange,
  onChangeDropdown,
  setIntegrationDropdownValue,
  saveIntegration,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  logoutIntegration,
  testIntegration,
  testResults,
  setTestResults,
  writable,
  shopifyLocations,
  shipstationCarriers,
}) => (
  <>
    <div className="w-96 flex space-x-4 mb-5">
      {warehouses && warehouses.length > 1 && (
        <RoundedDropdown
          placeholder={"Warehouse"}
          list={warehouses}
          labelKey="name"
          valueKey="id"
          name="warehouse"
          selectedValue={selectedWarehouse}
          setSelected={setSelectedWarehouse}
        />
      )}
      {customers && customers.length > 1 && (
        <RoundedDropdown
          placeholder={"Client"}
          list={getFilteredCustomerList(selectedWarehouse, customers)}
          labelKey="name"
          valueKey="id"
          name="customer"
          selectedValue={selectedCustomer}
          setSelected={setSelectedCustomer}
        />
      )}
    </div>
    {selectedIntegrationToManage ? (
      <ManageIntegrationProvider
        integrations={integrations.filter(
          (item) =>
            item.integrationType === selectedIntegrationToManage &&
            item.customer === selectedCustomer,
        )}
        integrationProvider={integrationProviders.find(
          (item) => item.name === selectedIntegrationToManage,
        )}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
        writable={writable}
        connectIntegration={connectIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        configureIntegrationSubmit={configureIntegrationSubmit}
      />
    ) : (
      <IntegrationProviders
        integrations={integrations}
        integrationProviders={integrationProviders}
        connectIntegration={connectIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        selectedCustomer={selectedCustomer}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        writable={writable}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
      />
    )}

    {selectedIntegration && (
      <IntegrationForm
        title={selectedIntegration.id ? "Edit Integration" : "Add Integration"}
        selectedIntegration={selectedIntegration}
        integrationProviders={integrationProviders}
        setSelectedIntegration={setSelectedIntegration}
        onChange={onChange}
        onClose={() => setSelectedIntegration()}
        onSubmit={saveIntegration}
        customers={customers}
        onChangeDropdown={onChangeDropdown}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        shopifyLocations={shopifyLocations}
        shipstationCarriers={shipstationCarriers}
      />
    )}
    {testResults && (
      <Modal
        title={`Connection Result`}
        negativeAction={() => setTestResults(null)}>
        <div className="space-y-4">
          <div>
            <span className="font-bold font-montserrat text-454A4F text-lg pb-4">
              Tested retrieval of 1 product. Success.
            </span>
          </div>
          {Object.keys(testResults).map((item, idx) => (
            <div key={idx}>
              <span className="font-bold font-montserrat text-454A4F text-lg pb-4">
                {item} : {testResults[item]}
              </span>
            </div>
          ))}
        </div>
      </Modal>
    )}
  </>
);

export default withSoftwareIntegrationsLogic(SoftwareIntegrations);
