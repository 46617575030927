import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import AddButton from "#components/utils/AddButton";
import Autocomplete from "#components/utils/Autocomplete";

const StockTransferForm = ({
  onClose,
  title,
  warehouses,
  selectedStockTransfer,
  onSubmit,
  onChangeDropdown,
  addItems,
  onChangeItem,
  removeItem,
  users,
  availableInventory,
  onChangeInventorySearch,
  skuBinLocations,
  onChangeSkuBinLocationSearch,
  binLocations,
  onChangeBinLocationSearch,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={onSubmit}
      positiveText={"Submit"}>
      <div className="space-y-4">
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Select Warehouse
            </span>
          </label>
          <Autocomplete
            options={warehouses}
            boxWidth="w-full"
            labelKey="name"
            valueKey="id"
            onChange={(e) => {
              onChangeDropdown("warehouse", e);
            }}
            value={selectedStockTransfer.warehouse}
            placeholder="Start typing warehouse"
            name="warehouse"
            id="WAREHOUSE_ID"
          />
        </div>

        {selectedStockTransfer.warehouse && (
          <div>
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                SKU
              </span>
            </label>
            <Autocomplete
              options={
                availableInventory &&
                availableInventory.map((item) => ({
                  name: `${item.sku}`,
                  id: item.id,
                }))
              }
              boxWidth="w-full"
              labelKey="name"
              valueKey="name"
              onChange={(e) => {
                onChangeDropdown("sku", e);
              }}
              value={selectedStockTransfer.sku}
              useValueDirectly={true}
              placeholder="Start typing SKU"
              name="sku"
              onKeyDown={onChangeInventorySearch}
              id="SKU_ID"
            />
          </div>
        )}

        {selectedStockTransfer.warehouse && selectedStockTransfer.sku && (
          <>
            <div>
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Bin to Bin Transfer Details
              </span>
            </div>

            {selectedStockTransfer.items &&
              selectedStockTransfer.items.length > 0 &&
              selectedStockTransfer.items.map((item, idx) => (
                <div className="flex space-x-2 items-center" key={idx}>
                  <div className="w-64">
                    <Autocomplete
                      options={skuBinLocations}
                      labelKey="barcode"
                      valueKey="barcode"
                      onChange={(e) => {
                        onChangeItem(idx, {
                          target: {
                            name: "fromBin",
                            value: e,
                          },
                        });
                      }}
                      value={item.fromBin}
                      useValueDirectly={true}
                      onKeyDown={(e) => onChangeSkuBinLocationSearch(e)}
                      placeholder="From"
                      name="fromBin"
                      id="FROM_BIN"
                    />
                  </div>{" "}
                  <div className="w-64">
                    <Autocomplete
                      options={binLocations}
                      labelKey="code"
                      valueKey="code"
                      onChange={(e) => {
                        onChangeItem(idx, {
                          target: {
                            name: "toBin",
                            value: e,
                          },
                        });
                      }}
                      value={item.toBin}
                      useValueDirectly={true}
                      onKeyDown={(e) => onChangeBinLocationSearch(e)}
                      placeholder="To"
                      name="toBin"
                      id="TO_BIN"
                    />
                  </div>{" "}
                  <div>
                    <TextField
                      type="number"
                      id="quantity"
                      label="Quantity"
                      min="0"
                      placeholder=" "
                      onChange={(e) => {
                        onChangeItem(idx, e);
                      }}
                      value={parseInt(item.quantity)}
                      name="quantity"
                    />
                  </div>
                  <div className="w-96">
                    <Autocomplete
                      options={users}
                      labelKey="name"
                      valueKey="id"
                      onChange={(e) => {
                        onChangeItem(idx, {
                          target: {
                            name: "personnel",
                            value: e,
                          },
                        });
                      }}
                      value={users?.find((u) => u.id === item.personnel)?.name}
                      useValueDirectly={true}
                      placeholder="Assign Personnel"
                      name="personnel"
                      id="PERSONNEL"
                    />
                  </div>
                  <span
                    className="text-red-500 text-lg pl-2 font-bold cursor-pointer"
                    onClick={() => removeItem(idx)}>
                    Remove
                  </span>
                </div>
              ))}

            {skuBinLocations.length > 0 ? (
              <>
                <AddButton text="Add Transfer" onClick={addItems} />
              </>
            ) : (
              <div>
                <a className="text-red-500 text-center text-lg pl-2 font-medium cursor-pointer">
                  No SKU Bin Mappings found for the selected warehouse and
                  product
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default StockTransferForm;
