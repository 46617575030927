import PageTitle from "#components/utils/PageTitle";

import QRCode from "react-qr-code";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import _ from "lodash";

const ListView = ({ scanBarcode, eligibleConsignments, tenant }) => {
  if (!eligibleConsignments || eligibleConsignments.length === 0) {
    return null;
  }

  const combinedConsignment = {
    ...eligibleConsignments[0],
    workingList: _.flatten(
      eligibleConsignments.map((consignment) => consignment.workingList),
    ),
    items: _.flatten(
      eligibleConsignments.map((consignment) => consignment.items),
    ),
  };

  const isPrepCenter = tenant?.typeOfCustomer?.includes("Prep Center") === true;

  const headers = () => {
    const returnHeaders = [
      "SKU",
      "Product Name",
      "Form Factor",
      "Expected / Received",
      "Action",
    ];

    if (isPrepCenter) {
      returnHeaders.unshift("ASIN");
    }

    return returnHeaders;
  };

  return (
    <div className="flex-1 w-screen items-center justify-center text-center">
      <PageTitle>Total ({combinedConsignment?.workingList?.length})</PageTitle>
      <div className="bg-EBEBEB rounded-md m-auto mt-10">
        <table className="divide-y divide-gray-200 m-auto -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {headers().map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {combinedConsignment.workingList.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {isPrepCenter
                    ? buildMarketplaceHyperlink(item.asin, "ASIN", item.source)
                    : item.sku}
                </td>
                {isPrepCenter && (
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.sku}
                  </td>
                )}

                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.productName}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.formFactor}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider text-blue-500">
                  <div className="flex space-x-4">
                    <div>{item.quantity}</div>
                    <div>/</div>
                    <div
                      className={`${
                        item.quantity - item.availableQuantity > item.quantity
                          ? "text-red-500"
                          : "text-yellow-500"
                      }`}>
                      {item.quantity - item.availableQuantity}
                    </div>
                  </div>
                </td>
                <td
                  className={`pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider ${
                    item.quantity - item.availableQuantity > item.quantity
                      ? "text-red-500"
                      : "text-yellow-500"
                  }`}></td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  <div
                    className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white"
                    onClick={() => scanBarcode({ data: item.sku })}>
                    Scan
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListView;
