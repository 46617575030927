import PageTitle from "#components/utils/PageTitle";

import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import { useState } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";
const headers = [
  "ASIN",
  "FNSKU",
  "SKU",
  "Product Name",
  "Qty",
  "Expiry Date",
  "Status",
  "Action",
  "Redo",
];

/**
 * Consolidates an array of data objects by combining objects with matching sku, status, and bestByDate properties.
 *
 * The resulting consolidated objects will have the combined quantity of all matching objects and an array
 * of ids from all the matching objects. The returned array of consolidated objects is sorted such that
 * objects with a status of "UNPROCESSED" are at the beginning.
 *
 * @param {Array<Object>} data - An array of data objects to be consolidated. Each object should have the properties:
 *     - {string} sku - The SKU identifier.
 *     - {string} status - The status of the data object.
 *     - {string} bestByDate - The best by date for the data.
 *     - {number} quantity - The quantity of the data.
 *     - {number|string} id - The unique identifier of the data.
 * @returns {Array<Object>} An array of consolidated data objects. Each consolidated object will have the same properties as the input objects,
 *                          plus an additional ids property which is an array of ids from all combined objects.
 * @example
 input : [
  { sku: 'A', status: 'UNPROCESSED', bestByDate: '2024-01-01', quantity: 1, id: 1 },
  { sku: 'A', status: 'UNPROCESSED', bestByDate: '2024-01-01', quantity: 1, id: 2 },
  { sku: 'A', status: 'UNPROCESSED', bestByDate: '2024-01-02', quantity: 1, id: 3 },
  { sku: 'A', status: 'CONFIRMED', bestByDate: '2024-01-01', quantity: 1, id: 4 },
  { sku: 'B', status: 'UNPROCESSED', bestByDate: '2024-01-01', quantity: 1, id: 5 },
  { sku: 'B', status: 'UNPROCESSED', bestByDate: '2024-01-01', quantity: 1, id: 6 }
]
 output : [
  { sku: 'A', status: 'UNPROCESSED', bestByDate: '2024-01-01', quantity: 2, id: 1, ids: [ 1, 2 ] },
  { sku: 'A', status: 'UNPROCESSED', bestByDate: '2024-01-02', quantity: 1, id: 3, ids: [ 3 ] },
  { sku: 'B', status: 'UNPROCESSED', bestByDate: '2024-01-01', quantity: 2, id: 5, ids: [ 5, 6 ] },
  { sku: 'A', status: 'CONFIRMED', bestByDate: '2024-01-01', quantity: 1, id: 4, ids: [ 4 ]
  }
]
**/
function consolidateData(data) {
  let consolidated = {};

  for (let obj of data) {
    const status = obj.status || "UNPROCESSED";
    const bestByDate = obj.bestByDate;
    let key = JSON.stringify({
      sku: obj.sku,
      status,
      bestByDate,
    });
    if (consolidated[key]) {
      consolidated[key].quantity += obj.quantity;
      consolidated[key].ids.push(obj.id);
    } else {
      let deepCopyObj = _.cloneDeep(obj);
      consolidated[key] = {
        ...deepCopyObj,
        ids: [obj.id],
      };
    }
  }
  return Object.values(consolidated).sort((a, b) => {
    if (a.status === "UNPROCESSED" && b.status !== "UNPROCESSED") {
      return -1;
    }
    if (a.status !== "UNPROCESSED" && b.status === "UNPROCESSED") {
      return 1;
    }
    return 0;
  });
}

const ListView = ({
  currentBatch,
  simulateItemScan,
  showStatus,
  unprepItems,
  order,
  setSelectedIndex,
}) => {
  const [redoItem, setRedoItem] = useState(null);
  const [redoQty, setRedoQty] = useState(0);
  return (
    <div className="flex-1">
      <PageTitle>Total ({currentBatch?.workingList?.length}) </PageTitle>
      <div className="bg-EBEBEB mt-10 rounded-md">
        <table className="min-w-full divide-y divide-gray-200 -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {consolidateData(currentBatch.workingList).map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.fnSku}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.sku}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider whitespace-nowrap">
                  <div className="w-96 overflow-hidden">{item.productName}</div>
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.quantity}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.bestByDate}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.status === "DEFAULT" ? "NOT PREPPED" : item.status}
                </td>
                {showStatus && (
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.scannedItem === true ? "Yes" : "No"}
                  </td>
                )}
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  <div
                    className={`rounded-full px-2 py-1 text-lg text-center text-white ${
                      item.status !== "UNPROCESSED"
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-500 cursor-pointer"
                    }`}
                    onClick={() => {
                      if (item.status === "UNPROCESSED") {
                        simulateItemScan(item, setSelectedIndex);
                        setSelectedIndex("Detail View");
                      }
                    }}>
                    Scan
                  </div>
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  <div
                    className={`rounded-full px-2 py-1 text-lg text-center text-white ${
                      item.status === "UNPROCESSED"
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-500 cursor-pointer"
                    }`}
                    onClick={() => {
                      if (item.status !== "UNPROCESSED") {
                        setRedoItem(item);
                      }
                    }}>
                    Re-do
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {redoItem && (
        <Modal
          negativeAction={() => setRedoItem(null)}
          positiveAction={() => {
            if (unprepItems(redoItem, redoQty)) {
              setRedoItem(null);
            }
          }}
          positiveText={"Re-do"}
          title={`Unprep Items`}>
          <div className="h-96 overflow-auto">
            <table className="min-w-full divide-y divide-gray-200 mt-2">
              <thead className="p-4 rounded-full">
                <tr className="text-white font-montserratborder-left bg-primaryAccent">
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                    ASIN
                  </th>
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                    FNSKU
                  </th>
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                    SKU
                  </th>
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                    Status
                  </th>
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                    Prepped Qty
                  </th>
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-center tracking-wider font-medium">
                    Unprep Qty
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={redoItem.id} className={"bg-white"}>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {buildMarketplaceHyperlink(
                      redoItem.asin,
                      "ASIN",
                      redoItem.source,
                    )}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {redoItem.fnSku}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {redoItem.sku}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {redoItem.productName}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {redoItem.status}
                  </td>
                  <td className="pl-4 px-1 py-1 text-center font-medium text-5F666B tracking-wider">
                    {redoItem.quantity}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    <TextField
                      type="number"
                      id="unPrepQuantity"
                      placeholder="Unprep Qty"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      onChange={(e) => {
                        setRedoQty(e.target.value);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ListView;
