const AmazonPrepInstructions = ({
  prepInstructions,
  title = "Amazon Prep Instructions",
}) => (
  <div className="w-full flex space-x-1 shadow-md">
    <div className="text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 border-primaryAccent">
      {title}
    </div>
    <div
      className={`bg-white flex-1 border p-4 rounded-tr-md rounded-br-md space-y-2`}>
      {prepInstructions?.map((item, idx) => (
        <div key={idx} className="flex">
          Instruction: {item.PrepInstruction} | Owner: {item.PrepOwner}
        </div>
      ))}
    </div>
  </div>
);

export default AmazonPrepInstructions;
