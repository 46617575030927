import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_STANDALONE_PUTAWAY_ITEM,
  GET_SKU,
  GET_CONSIGNMENTS,
  GET_NESTED_ENTITY_ITEMS,
} from "#queries";
import {
  SCAN_STANDALONE_PUTAWAY_BARCODE,
  CONFIRM_STANDALONE_PUTAWAY_ITEM,
  IMMEDIATE_SCAN,
  CANCEL_STANDALONE_PUTAWAY_ITEM,
  CHECK_FOR_CONSIGNMENT_PUTAWAY,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const ALERT_TIMEOUT_IN_MS = 5000;
const CONSIGNMENT_STATUS_LIST = [
  {
    label: "Eligible Orders",
    value: "eligible",
    filterValue: ["RECEIVING_STARTED", "COMPLETED", "RECEIVING_COMPLETED"],
  },
  {
    label: "Completed Orders",
    value: "completed",
    filterValue: ["COMPLETED", "RECEIVING_COMPLETED"],
  },
  {
    label: "All Orders",
    value: "all",
    filterValue: [
      "RECEIVING_STARTED",
      "RECEIVING_COMPLETED",
      "UNPROCESSED",
      "COMPLETED",
    ],
  },
];

const DEFAULT_CONSIGNMENTS_DATA = {
  consignments: [],
  filters: {
    status: CONSIGNMENT_STATUS_LIST[0].filterValue,
  },
  pageNumber: 1,
  perPage: 25,
  sort: "-status",
};

const withConsignmentPutawayLogic = (WrappedComponent) => {
  return (props) => {
    const history = useHistory();
    const location = useLocation();

    const auth = useContext(AuthContext);
    const appState = useContext(AppStateContext);

    const consignmentsQuery = useQuery(GET_CONSIGNMENTS);
    const checkForConsignmentQuery = useQuery(CHECK_FOR_CONSIGNMENT_PUTAWAY);
    const immediateScanQuery = useQuery(IMMEDIATE_SCAN);
    const scanBarcodeQuery = useQuery(SCAN_STANDALONE_PUTAWAY_BARCODE);
    const currentSkuQuery = useQuery(GET_CURRENT_STANDALONE_PUTAWAY_ITEM);
    const getCurrentProductQuery = useQuery(GET_SKU);
    const getNestedEntityItemsQuery = useQuery(GET_NESTED_ENTITY_ITEMS);
    const confirmItemQuery = useQuery(CONFIRM_STANDALONE_PUTAWAY_ITEM);
    const cancelItemQuery = useQuery(CANCEL_STANDALONE_PUTAWAY_ITEM);

    const [consignmentsData, setConsignmentsData] = useState(
      DEFAULT_CONSIGNMENTS_DATA,
    ); // after selecting client for putaway, the list of consignments are fetched and stored in this.
    const [specificConsignments, setSpecificConsignments] = useState(null); // when scanning a specific consignment from list view, this is shown.
    const [trackingNumber, setTrackingNumber] = useState(undefined); // the provided tracking number if any.
    const [currentScan, setCurrentScan] = useState(null); // holds the information of currentScan details.
    const [currentSku, setCurrentSku] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [nestedEntityItems, setNestedEntityItems] = useState([]);
    const [requireConsignmentForPutaway, setRequireConsignmentForPutaway] =
      useState(false);

    // Check for tenant settings once appState is loaded
    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (tenantSettings?.requireConsignmentForPutaway) {
        setRequireConsignmentForPutaway(true);
      }
    }, [appState]);

    // INITIAL EFFECT - check if there is any putaway batch presently assigned to this user.
    useEffect(() => {
      currentSkuQuery.fetchData();
    }, []);

    useEffect(() => {
      setQueryParamsToCurrentScan();
    }, [location]);

    const setQueryParamsToCurrentScan = (newScan = false) => {
      // Parse the query parameters
      const queryParams = new URLSearchParams(location.search);
      const selectedCustomer = queryParams.get("customer");
      const skipConsignmentSelection =
        queryParams.get("skipConsignmentSelection") === "true";

      if (currentScan && !newScan) {
        setCurrentScan({
          ...currentScan,
          customer: selectedCustomer,
          skipConsignmentSelection,
        });
      } else {
        setCurrentScan({
          customer: selectedCustomer,
          skipConsignmentSelection,
        });
      }
    };

    // currentSkuQuery - checks and retrieves the current putaway batch assigned for this user.
    useEffect(() => {
      if (currentSkuQuery.loading) {
        appState.setLoading();
      }

      if (
        currentSkuQuery.data &&
        currentSkuQuery.data.getCurrentStandalonePutawayItem
      ) {
        const response = currentSkuQuery.data.getCurrentStandalonePutawayItem;
        let quantity = response.currentItem.quantity;
        quantity = !currentSku
          ? 1
          : Math.max(currentSku.currentItem.quantity, quantity);

        if (currentSku?.currentItem?.receivingBatchId) {
          getNestedEntityItemsQuery.fetchData({
            batchId: response.currentItem.id,
          });
        }

        setCurrentSku({
          ...response,
          currentItem: {
            ...response.currentItem,
            quantity,
          },
        });
        setCurrentScan({
          ...currentScan,
          trackingNumber: null,
          consignmentId: response.currentItem.consignmentId || null,
          receivingBatchId: response.currentItem.receivingBatchId || null,
          customer: response.currentItem.customer,
        });
        appState.removeLoading();

        if (response.currentItem.productId) {
          getCurrentProductQuery.fetchData({
            id: response.currentItem.productId,
          });
        }
      }

      if (currentSkuQuery.error) {
        appState.removeLoading();
        setCurrentSku(null);
        setCurrentProduct(null);
      }
    }, [currentSkuQuery.loading, currentSkuQuery.data, currentSkuQuery.error]);

    // getCurrentProductQuery - when a putaway batch is assigned, this query is used to get the current putaway item product information
    useEffect(() => {
      getCurrentProductQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);

    // getNestedEntityItemsQuery - To fetch the items present inside a nested entity - tote/pallet/lpn
    useEffect(() => {
      if (getNestedEntityItemsQuery.loading) {
        appState.setLoading();
      }

      if (getNestedEntityItemsQuery.data) {
        setNestedEntityItems(
          getNestedEntityItemsQuery.data.getNestedEntityItems,
        );
        appState.removeLoading();
      }

      if (getNestedEntityItemsQuery.error) {
        setNestedEntityItems([]);
        appState.setAlert(
          getNestedEntityItemsQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        appState.removeLoading();
      }
    }, [
      getNestedEntityItemsQuery.loading,
      getNestedEntityItemsQuery.data,
      getNestedEntityItemsQuery.error,
    ]);

    // When customer is chosen, we need to fetch the consignments for that customer.
    useEffect(() => {
      if (requireConsignmentForPutaway && currentScan && currentScan.customer) {
        const filters = {
          customers: [currentScan.customer],
          status:
            consignmentsData.filters?.status ||
            CONSIGNMENT_STATUS_LIST[0].filterValue,
        };
        setConsignmentsData({
          ...consignmentsData,
          consignments: consignmentsData.consignments || [],
          pageNumber: 1,
          perPage: 25,
          sort: "-status",
          filters,
        });
        consignmentsQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters,
          sort: "-status",
        });
      }
    }, [currentScan?.customer, consignmentsData.filters?.status]);

    // consignmentsQuery - to fetch list of consignments belonging to seleceted customer.
    useEffect(() => {
      if (consignmentsQuery.loading) {
        appState.setLoading();
      }

      if (consignmentsQuery.data) {
        setConsignmentsData({
          ...consignmentsData,
          consignments: consignmentsQuery.data.consignments.entities,
          total: consignmentsQuery.data.consignments.total,
        });
        appState.removeLoading();
      }

      if (consignmentsQuery.error) {
        setConsignmentsData({});
        appState.removeLoading();
      }
    }, [
      consignmentsQuery.data,
      consignmentsQuery.loading,
      consignmentsQuery.error,
    ]);

    // checkForConsignmentQuery - responsible for fetching consignments for provided tracking info or for single consignment selection.
    useEffect(() => {
      if (checkForConsignmentQuery.loading) {
        appState.setLoading();
      }

      if (checkForConsignmentQuery.data) {
        if (
          checkForConsignmentQuery.data.checkForConsignmentPutaway.consignments
        ) {
          const consignments =
            checkForConsignmentQuery.data.checkForConsignmentPutaway
              .consignments;

          setSpecificConsignments(consignments);
        }
        if (currentScan) {
          if (
            checkForConsignmentQuery.data.checkForConsignmentPutaway
              .consignments.length === 1
          ) {
            setCurrentScan({
              ...currentScan,
              trackingNumber: null,
              consignmentId:
                checkForConsignmentQuery.data.checkForConsignmentPutaway
                  .consignments[0].id,
            });
          } else {
            setCurrentScan({
              ...currentScan,
              trackingNumber: currentScan.consignmentId ? null : trackingNumber,
            });
          }
        }
        appState.removeLoading();
      }

      if (checkForConsignmentQuery.error) {
        setSpecificConsignments(null);
        appState.setAlert(
          checkForConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        setTrackingNumber(null);
        setCurrentScan({
          ...currentScan,
          trackingNumber: null,
        });
        appState.removeLoading();
      }
    }, [
      checkForConsignmentQuery.loading,
      checkForConsignmentQuery.error,
      checkForConsignmentQuery.data,
    ]);

    // immediateScanQuery
    useEffect(() => {
      immediateScanQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (immediateScanQuery.data) {
        const barcode = immediateScanQuery.data.immediateScan.barcode;

        if (!currentScan.customer) {
          appState.setAlert("Please select a client", "danger", 5000);
          return;
        }

        if (
          requireConsignmentForPutaway &&
          currentScan.trackingNumber === undefined
        ) {
          // TRACKING NUMBER
          setTrackingNumber(barcode);
          updateUrlParams("skipConsignmentSelection", false);
          setCurrentScan({ ...currentScan, skipConsignmentSelection: false });
          return checkForConsignmentQuery.fetchData({
            trackingNumber: [barcode],
            customer: currentScan.customer,
          });
        }

        const scanBarcodeVariables = {
          code: barcode,
          customer: currentScan.customer,
        };
        if (currentScan.consignmentId) {
          scanBarcodeVariables.consignmentId = currentScan.consignmentId;
        }
        if (currentScan.receivingBatchId) {
          scanBarcodeVariables.receivingBatchId = currentScan.receivingBatchId;
        }

        return scanBarcodeQuery.fetchData(scanBarcodeVariables);
      }
    }, [
      immediateScanQuery.loading,
      immediateScanQuery.error,
      immediateScanQuery.data,
    ]);

    // scanBarcodeQuery - once we scan a code, we call the currentSkuQuery to get the updated current putaway batch.
    useEffect(() => {
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
      }

      if (
        scanBarcodeQuery.data &&
        scanBarcodeQuery.data.scanStandalonePutawayBarcode &&
        scanBarcodeQuery.data.scanStandalonePutawayBarcode.message
      ) {
        currentSkuQuery.fetchData();
        appState.setAlert(
          scanBarcodeQuery.data.scanStandalonePutawayBarcode.message,
          "success",
          2000,
        );
      }

      if (scanBarcodeQuery.error) {
        appState.setAlert(scanBarcodeQuery.error.message, "danger");
        appState.removeLoading();
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);

    // cancelItemQuery - when we want to cancel the current putaway batch.
    useEffect(() => {
      if (cancelItemQuery.loading) {
        appState.setLoading();
      }

      if (cancelItemQuery.data) {
        appState.setAlert(
          cancelItemQuery.data.cancelStandalonePutawayItem.message,
          "success",
          5000,
        );
        currentSkuQuery.fetchData();
      }

      if (cancelItemQuery.error) {
        appState.setAlert(cancelItemQuery.error.message, "danger", 5000);
        appState.removeLoading();
      }
    }, [cancelItemQuery.error, cancelItemQuery.data, cancelItemQuery.loading]);

    // confirmItemQuery - confirms the current putaway batch item and calls the currentSkuQuery.
    useEffect(() => {
      if (confirmItemQuery.loading) {
        appState.setLoading();
      }

      if (
        confirmItemQuery.data &&
        confirmItemQuery.data.confirmStandalonePutawayItem.message
      ) {
        appState.setAlert(
          confirmItemQuery.data.confirmStandalonePutawayItem.message,
          "success",
          2000,
        );

        currentSkuQuery.fetchData();
        fetchSpecificConsignmentsData();
      }

      if (confirmItemQuery.error) {
        appState.setAlert(confirmItemQuery.error.message, "danger", 5000);
        appState.removeLoading();
      }
    }, [
      confirmItemQuery.loading,
      confirmItemQuery.data,
      confirmItemQuery.error,
    ]);

    const fetchSpecificConsignmentsData = () => {
      if (!currentScan.skipConsignmentSelection) {
        // re-fetch the updated consignment data
        const checkForConsignmentFilter = {
          customer: currentScan.customer,
        };
        if (currentScan.consignmentId) {
          checkForConsignmentFilter.consignmentId = currentScan.consignmentId;
        } else {
          checkForConsignmentQuery.trackingNumber = trackingNumber;
        }
        checkForConsignmentQuery.fetchData(checkForConsignmentFilter);
      }
    };

    const checkConsignmentsPagination = (direction) => {
      let vars = {
        filters: consignmentsData.filters,
        sort: consignmentsData.sort,
        perPage: 25,
      };
      if (direction === "backward") {
        vars["pageNumber"] = consignmentsData.pageNumber - 1;
      } else {
        vars["pageNumber"] = consignmentsData.pageNumber + 1;
      }
      setConsignmentsData({
        ...consignmentsData,
        pageNumber: vars["pageNumber"],
      });
      return consignmentsQuery.fetchData(vars);
    };

    const checkConsignmentById = (e) => {
      if (e.data) {
        setCurrentScan({
          ...currentScan,
          trackingNumber: null,
          consignmentId: e.data,
          skipConsignmentSelection: false,
        });
        updateUrlParams("skipConsignmentSelection", false);
        setTrackingNumber(null);
        checkForConsignmentQuery.fetchData({
          trackingNumber: null,
          customer: currentScan.customer,
          consignmentId: e.data,
        });
      }
    };

    const scanBarcode = (e) => {
      setCurrentScan({
        ...currentScan,
        consignmentId: e.consignmentId || currentScan.consignmentId || null,
        receivingBatchId: e.receivingBatchId || null,
      });

      if (e.data) {
        immediateScanQuery.fetchData({ barcode: e.data });
      }
    };

    const submitCustomerCode = (customerCodeInput) => {
      if (!customerCodeInput) {
        return appState.setAlert(
          `Please provide a customer code`,
          "danger",
          5000,
        );
      }
      customerCodeInput = customerCodeInput.value;

      const customersList = auth.user.customersList;
      if (!customersList || customersList.length === 0) {
        return appState.setAlert(`No customers found`, "danger", 5000);
      }

      let customer = customersList.find(
        (item) => item.id === customerCodeInput,
      );
      if (!customer) {
        return appState.setAlert(
          `No customer found with that code`,
          "danger",
          5000,
        );
      }

      updateUrlParams("customer", customer.id);

      const currentScanInfo = currentScan
        ? { ...currentScan, customer: customer.id }
        : { customer: customer.id };

      return setCurrentScan(currentScanInfo);
    };

    const cancelItem = () => {
      appState.showConfirmation(
        "Confirm",
        "This action cannot be undone.",
        () => {
          cancelItemQuery.fetchData({
            id: currentSku.currentItem.id,
          }),
            appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const confirmItem = ({ id, quantity }) => {
      if (currentSku.currentItem.quantity) {
        if (quantity <= 0) {
          return appState.setAlert(
            `Please input a valid quantity`,
            "error",
            5000,
          );
        }
        confirmItemQuery.fetchData({
          item: {
            id: currentSku.currentItem.id,
            quantity: parseInt(quantity),
          },
        });
      } else {
        confirmItemQuery.fetchData({
          item: {
            id: currentSku.currentItem.id,
          },
        });
      }
    };

    const cancelCurrentCustomerAndTracking = (trigger) => {
      switch (trigger) {
        case "CONSIGNMENT_SELECTION":
          history.push({
            pathname: location.pathname,
            search: "",
          });
          setCurrentScan(null);
          break;
        case "CONSIGNMENTS_ITEMS_SELECTION":
          setSpecificConsignments([]);
          setTrackingNumber(undefined);
          setCurrentScan({
            customer: currentScan.customer,
            trackingNumber: undefined,
            skipConsignmentSelection: false,
          });
          updateUrlParams("skipConsignmentSelection", false);
          break;
        default:
          setCurrentScan(null);
      }
    };

    const updateUrlParams = (paramName, paramValue) => {
      // Create a URLSearchParams object based on the current search string
      const searchParams = new URLSearchParams(location.search);

      searchParams.has(paramName)
        ? searchParams.set(paramName, paramValue)
        : searchParams.append(paramName, paramValue);

      // Use history to update the URL without navigating away
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    };

    const skipConsignmentSelection = () => {
      updateUrlParams("skipConsignmentSelection", true);
      setCurrentScan({
        ...currentScan,
        trackingNumber: null,
        skipConsignmentSelection: true,
      });
    };

    return (
      <WrappedComponent
        customers={
          auth.user && auth.user.customersList ? auth.user.customersList : []
        }
        submitCustomerCode={submitCustomerCode}
        scanBarcode={scanBarcode}
        currentScan={currentScan}
        currentSku={currentSku}
        loading={currentSkuQuery.loading}
        currentProduct={currentProduct}
        cancelItem={cancelItem}
        confirmItem={confirmItem}
        requireConsignmentForPutaway={requireConsignmentForPutaway}
        // consignments related props
        consignmentsData={consignmentsData}
        checkConsignmentById={checkConsignmentById}
        specificConsignments={specificConsignments}
        consignmentStatusList={CONSIGNMENT_STATUS_LIST}
        consignmentFilters={consignmentsData.filters}
        setConsignmentFilters={(filters) =>
          setConsignmentsData({
            ...consignmentsData,
            filters,
          })
        }
        submitConsignmentFilters={() =>
          consignmentsQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: consignmentsData.filters,
          })
        }
        checkConsignmentsPagination={checkConsignmentsPagination}
        cancelCurrentCustomerAndTracking={cancelCurrentCustomerAndTracking}
        nestedEntityItems={nestedEntityItems}
        setNestedEntityItems={setNestedEntityItems}
        getNestedEntityItems={(batchId) => {
          getNestedEntityItemsQuery.fetchData({ batchId });
        }}
        skipConsignmentSelection={skipConsignmentSelection}
      />
    );
  };
};

export default withConsignmentPutawayLogic;
