import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment-timezone";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import { groupBy } from "lodash";

const ReceivingItemListPdf = ({ consignment, tenant, customerName }) => {
  const getExpectedTotal = (workingList, sku, formFactor) => {
    let consignment = workingList.find((consignment) => {
      return consignment.sku === sku && consignment.formFactor === formFactor;
    });
    if (consignment) return consignment.quantity;
    return null;
  };

  const ConsignmentTable = ({ receivingBatches, workingList }) => {
    if (receivingBatches.length === 0) {
      let tables = [];
      workingList.map((listItem, index) => {
        tables.push(
          <ReactPdfTable
            showColumnHeader={index === 0}
            columns={tableColumns(tenant?.typeOfCustomer)}
            data={[
              {
                skuName: `${listItem.sku} \n${
                  listItem.name ? listItem.name : ""
                }`,
                expectedQuantity: listItem.quantity,
                quantity: "-",
                formFactor: listItem.formFactor,
              },
              {
                skuName: "Not received",
              },
            ]}
          />,
        );
      });
      return tables;
    } else {
      let sections = groupBy(receivingBatches, ({ sku, formFactor }) =>
        JSON.stringify({ sku, formFactor }),
      );

      let tables = [];
      Object.keys(sections).map((batchKey, index) => {
        let { sku, formFactor } = JSON.parse(batchKey);
        let totalQuantity = sections[batchKey].reduce(
          (total, batch) => total + batch.quantity,
          0,
        );
        tables.push(
          <ReactPdfTable
            showColumnHeader={index === 0}
            columns={tableColumns(tenant?.typeOfCustomer)}
            data={[
              {
                skuName: `${sku} \n${
                  sections[batchKey].length > 0
                    ? sections[batchKey][0].name
                    : ""
                }`,
                expectedQuantity: getExpectedTotal(
                  workingList,
                  sku,
                  formFactor,
                ),
                quantity: totalQuantity,
                formFactor:
                  sections[batchKey].length > 0
                    ? sections[batchKey][0].formFactor
                    : "",
              },
              ...sections[batchKey],
            ]}
          />,
        );
      });

      return tables;
    }
  };

  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            backgroundColor: "#224E73",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 10,
          }}>
          <Image
            src={{
              uri:
                tenant && tenant.logo
                  ? tenant.logo
                  : "https://hopstack-pub.s3.amazonaws.com/logo.png",
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            style={{ width: 100 }}
            fixed={true}
          />
        </View>
        <View
          style={{
            fontSize: "15px",
            padding: "20px",
          }}>
          <View>
            <Text
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                fontSize: 24,
                fontWeight: "bold",
              }}>
              Receiving Item List:
            </Text>
          </View>
          <View>
            {consignment.orderId && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Order Id: {consignment.orderId}
              </Text>
            )}
            {consignment.consignmentNumber && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Consignment Number: {consignment.consignmentNumber}
              </Text>
            )}
            {consignment.trackingNumber &&
              consignment.trackingNumber.length > 0 && (
                <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                  Tracking Number(s): {consignment.trackingNumber.join(", ")}
                </Text>
              )}
            {consignment.consignmentDate && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Consignment Date:{" "}
                {moment(consignment.consignmentDate).format("YYYY-MM-DD")}
              </Text>
            )}
            {customerName && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Client Name: {customerName}
              </Text>
            )}
            {consignment.createdAt && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Added on:{" "}
                {moment(consignment.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a",
                )}
              </Text>
            )}
          </View>
        </View>
        <View style={{ padding: 20 }}>
          <Text style={{ marginBottom: "10px", fontWeight: "bold" }}>
            Items({consignment.items ? consignment.items.length : 0}
            ):
          </Text>
        </View>
        <View style={{ padding: 20 }}>
          <ConsignmentTable
            receivingBatches={consignment?.receivingBatches}
            workingList={consignment?.workingList}
          />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "20px",
            marginTop: "60px",
            justifyContent: "space-around",
          }}>
          <View>
            <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Condition:
            </Text>
            <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Container Type:
            </Text>
            <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Number of Wrapping Pallets:
            </Text>
            <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Crossdocking:
            </Text>
          </View>

          <View>
            <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Vehicle Type & Size
            </Text>
            <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Vehicle Type:
            </Text>
            <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
              Size/Length:
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const tableColumns = (tenantType) => {
  const returnArr = [];
  returnArr.push({ title: "SKU", dataIndex: "skuName" });
  if (tenantType?.indexOf("Prep Center") !== -1) {
    returnArr.push({
      title: "ASIN",
      dataIndex: "asin",
      render: (productData, product) => {
        return buildMarketplaceHyperlink(productData, "ASIN", product.source);
      },
    });
  }

  returnArr.push(
    { title: "Lot/Batch ID", dataIndex: "lotId" },
    { title: "Expiry Date", dataIndex: "bestByDate" },
    { title: "Expected Quantity", dataIndex: "expectedQuantity" },
    { title: "Received Quantity", dataIndex: "quantity" },
    { title: "Form Factor", dataIndex: "formFactor" },
  );

  return returnArr;
};

const ReactPdfTable = ({ columns, data, showColumnHeader }) => {
  const colWidth = Math.ceil(100 / columns.length);
  const colStyle = { width: colWidth.toString().concat("%") };
  const rowStyles = {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #ccc",
    padding: "4px",
  };
  const headerStyles = {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #ccc",
    padding: "4px",
    backgroundColor: "#ececec",
  };
  return (
    <View style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }}>
      {showColumnHeader && (
        <View style={headerStyles}>
          {columns.map((col) => (
            <Text style={{ ...colStyle, fontSize: "12px" }}>{col.title}</Text>
          ))}
        </View>
      )}
      <View>
        {data.map((row, index) => (
          <View style={rowStyles}>
            {columns.map((col) =>
              col.render ? (
                <Text style={{ ...colStyle, fontSize: "10px" }}>
                  {col.render(row[col.dataIndex], row)}
                </Text>
              ) : (
                <Text
                  style={
                    index === 0
                      ? { ...colStyle, fontSize: "12px", fontWeight: "bold" }
                      : { ...colStyle, fontSize: "10px" }
                  }>
                  {row[col.dataIndex]}
                </Text>
              ),
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

const Logo = ({ tenant = {} }) => (
  <div className="flex items-center justify-center space-x-2 py-2">
    {tenant && tenant.logo ? (
      <img src={tenant.logo} alt="Custom Logo" className="h-20" />
    ) : (
      <div className="flex items-center justify-center space-x-2 py-2">
        <img
          src="https://hopstack-pub.s3.amazonaws.com/logo.png"
          alt="Custom Logo"
          className="w-10 h-10"
        />
        <span className="font-hammersmith text-white text-xl">
          Hopstack Inc
        </span>
      </div>
    )}
  </div>
);

export default ReceivingItemListPdf;
