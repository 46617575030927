import { useState } from "react";
import Modal from "#components/utils/Modal";

const DEFAULT_EXPAND_THRESHOLD = 2;

const ExpandedMultipleItem = ({
  items,
  emptyText = null,
  title = "Click to View",
  expandThreshold = DEFAULT_EXPAND_THRESHOLD,
}) => {
  const [listOfElements, setListOfElements] = useState(false);

  if (!items || items.length === 0) {
    return emptyText;
  }

  if (items.length < expandThreshold) {
    return items.join(", ");
  }

  return (
    <>
      <div>
        <button
          onClick={() => {
            setListOfElements(items);
          }}
          className="text-md underline text-2C7695">
          {title}
        </button>
      </div>
      {listOfElements && (
        <Modal
          negativeAction={() => setListOfElements(null)}
          title={`Details`}
          noPadding={true}
          positiveAction={() => setListOfElements(null)}
          positiveText="OK">
          <div className="bg-white p-4 mx-auto overflow-auto relative max-w-xl">
            <div className="font-semibold text-lg myb-2">
              Total Count : {listOfElements.length}
            </div>
            <div className="border-2 border-gray-700 w-full my-2"></div>
            <div className="max-h-96 w-full grid grid-cols-2 overflow-scroll">
              {listOfElements.map((element, index) => (
                <div id={element}>
                  {index + 1}. {element}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ExpandedMultipleItem;
