const ModalButton = ({
  onClick,
  transparent,
  text,
  className,
  specifybg,
  style,
  disabled,
}) => (
  <button
    type="button"
    style={style}
    className={`inline-flex justify-center px-6 py-2 text-lg font-medium ${
      transparent
        ? `bg-transparent border border-F4C261 ${!specifybg && "text-B3BFCA"}`
        : `${!specifybg && "bg-textWhite"}`
    } font-montserrat font-medium border rounded-md ring-0 focus:ring-0 outline-none focus:outline-none ${className} ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`}
    onClick={!disabled ? onClick : null}
    disabled={disabled}>
    {text || "Button"}
  </button>
);

export default ModalButton;
