export const  OrderSource = {
  HOPSTACK: "Hopstack",
  FBA: "FBA",
  SHOPIFY: "Shopify", 
  SALLA: "Salla", 
  NETSUITE:"NetSuite", 
  ZID: "Zid", 
  APPARELMAGIC: "ApparelMagic"
}

