import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

const SkuBinMappingFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  outboundExceptions,
  customers,
  warehouses,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="flex space-x-6 justify-between mt-4">
      <>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Clients
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey="name"
            valueKey="id"
            setValues={(e) => onChangeFilter("customer", e)}
            values={filters["customer"]}
            rounded={true}
          />
        </div>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Status
            </span>
          </label>
          <div className="flex">
            <Checkbox
              role="checkbox"
              onChange={() =>
                onChangeFilter(
                  "hasQuantity",
                  filters["hasQuantity"] === true ? null : true,
                )
              }
              name="binLocations"
              value={"Has Products"}
              checked={filters && filters["hasQuantity"] === true}
            />
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Has Products
              </span>
            </label>
          </div>
          <div className="flex">
            <Checkbox
              role="checkbox"
              onChange={() =>
                onChangeFilter(
                  "hasQuantity",
                  filters["hasQuantity"] === false ? null : false,
                )
              }
              name="hasQuantity"
              value={"Empty Location"}
              checked={filters && filters["hasQuantity"] === false}
            />
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Empty Location
              </span>
            </label>
          </div>
        </div>
      </>
    </div>
  </Modal>
);

export default SkuBinMappingFilters;
