import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useQuery } from "#hooks/useQuery";
import {
  GET_ALL_BATCHES,
  GET_USERS,
  FETCH_SPECIFIC_BATCH,
  GET_BATCHES_FOR_STATS,
  GET_STATIONS,
} from "#queries";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";

import { GET_PACKERS, GET_PACKINGS_GRAPH, GET_PACKINGS_TABLE } from "#queries";
import { useCubeQuery } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import moment from "moment-timezone";

import Papa from "papaparse";

const withPackingsLogic = (WrappedComponent) => {
  return (props) => {
    const [expandedBatch, setExpandedBatch] = useState(null);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const batchesQuery = useQuery(GET_ALL_BATCHES);
    const batchesForStatsQuery = useQuery(GET_BATCHES_FOR_STATS);
    const batchesForProductivityStatsQuery = useQuery(GET_BATCHES_FOR_STATS);
    const fetchSpecificBatch = useQuery(FETCH_SPECIFIC_BATCH);
    const usersQuery = useQuery(GET_USERS);
    const [showFilters, setShowFilters] = useState(false);
    const [batchItems, setBatchItems] = useState(false);
    const stationsQuery = useQuery(GET_STATIONS);

    const [showView, setShowView] = useState("BATCHES");
    const [pickingsStats, setPickingsStats] = useState(null);

    const packersResult = useCubeQuery(GET_PACKERS);
    const packingsTableResult = useCubeQuery(GET_PACKINGS_TABLE);
    const [packingsTableData, setPackingsTableData] = useState(null);
    const [stats, setStats] = useState(null);
    const [productivityStats, setProductivityStats] = useState(null);
    const [selectedPacker, setSelectedPacker] = useState(null);
    const [stations, setStations] = useState(null);

    const auth = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [usersMap, setUsersMap] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [showOnlySelected, setShowOnlySelected] = useState(false);

    useEffect(() => {
      if (packingsTableResult.resultSet && packingsTableData === null) {
        setPackingsTableData(packingsTableResult.resultSet.chartPivot());
      }
    }, [packingsTableResult]);

    useEffect(() => {
      loadFirstTimeData();
      usersQuery.fetchData({
        perPage: 1000,
        paginated: false,
      });
      stationsQuery.fetchData({
        filters: { all: true, hopstackModule: ["Packing"] },
      });
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (usersQuery.data?.users?.entities) {
        let usersData = usersQuery.data.users.entities;
        let usersDataMap = {};
        usersData.forEach((user) => {
          usersDataMap[`${user.id}`] = user;
        });
        setUsers(usersData);
        setUsersMap(usersDataMap);
      }
    }, [usersQuery.data]);

    useEffect(() => {
      if (stationsQuery.data) {
        setStations(stationsQuery.data.stations.entities);
      }

      if (stationsQuery.error) {
        alert(stationsQuery.error.message);
        setStations(null);
      }
    }, [stationsQuery.error, stationsQuery.data]);

    const loadFirstTimeData = () => {
      const setFilters = {
        typeOfBatch: "PACKING",
        startDate: moment().utc().startOf("day").format("YYYY-MM-DD"),
      };
      entity.setFilters(setFilters);

      batchesQuery.fetchData({
        perPage: entity.perPage,
        filters: setFilters,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    useEffect(() => {
      if (batchesQuery.data && batchesQuery.data.getAllBatches) {
        entity.setEntities({
          ...batchesQuery.data.getAllBatches,
          ...batchesQuery.variables,
        });
      }
    }, [batchesQuery.loading, batchesQuery.error, batchesQuery.data]);

    useEffect(() => {
      if (batchesForStatsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (batchesForStatsQuery.data) {
        const batches = batchesForStatsQuery.data.exportBatches.filter((item) =>
          ["CONFIRMED_DROPOFF"].includes(item.status),
        );
        const groupedByUser = _.groupBy(batches, "user");
        const stats = {};
        for (const key in groupedByUser) {
          const usersBatches = groupedByUser[key].sort(
            (a, b) => a.assignedTime - b.assignedTime,
          );
          const itemsProcessed = _.sumBy(usersBatches, "completedItems");
          const mappedActiveMinutes = usersBatches.map(
            (item) =>
              moment(item.updatedAt).diff(
                moment(item.assignedTime),
                "seconds",
              ) / 60,
          );
          console.log(mappedActiveMinutes);
          const activeMinutes = Math.abs(_.sum(mappedActiveMinutes)).toFixed(2);
          stats[key] = {
            name: users.find((item) => item.id === key)?.name,
            itemsProcessed,
            firstBatchAssignedAt: moment(usersBatches[0].assignedTime).format(
              "YYYY-MM-DD hh:mm a",
            ),
            lastBatchAssignedAt: moment(
              usersBatches[usersBatches.length - 1].assignedTime,
            ).format("YYYY-MM-DD hh:mm a"),
            activeMinutes,
            timePerItem: ((activeMinutes * 60) / itemsProcessed).toFixed(2),
            itemsPerHour: ((itemsProcessed / activeMinutes) * 60).toFixed(2),
          };
        }
        setStats(stats);
      }

      if (batchesForStatsQuery.error) {
        appState.setAlert(batchesForStatsQuery.error.message, "error", 5000);
      }
    }, [
      batchesForStatsQuery.loading,
      batchesForStatsQuery.error,
      batchesForStatsQuery.data,
    ]);

    useEffect(() => {
      if (batchesForProductivityStatsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (batchesForProductivityStatsQuery.data) {
        const batches =
          batchesForProductivityStatsQuery.data.exportBatches.filter((item) =>
            ["CONFIRMED_DROPOFF"].includes(item.status),
          );
        const groupedByUser = _.groupBy(batches, "user");
        const stats = {};
        for (const key in groupedByUser) {
          const usersBatches = groupedByUser[key].sort(
            (a, b) => a.assignedTime - b.assignedTime,
          );
          const itemsProcessed = _.sumBy(usersBatches, "completedItems");
          const mappedActiveMinutes = usersBatches.map(
            (item) =>
              moment(item.updatedAt).diff(
                moment(item.assignedTime),
                "seconds",
              ) / 60,
          );
          const activeMinutes = Math.abs(_.sum(mappedActiveMinutes)).toFixed(2);
          stats[key] = {
            name: users.find((item) => item.id === key)?.name,
            itemsProcessed,
            firstBatchAssignedAt: moment(usersBatches[0].assignedTime).format(
              "YYYY-MM-DD hh:mm a",
            ),
            lastBatchAssignedAt: moment(
              usersBatches[usersBatches.length - 1].assignedTime,
            ).format("YYYY-MM-DD hh:mm a"),
            activeMinutes,
            timePerItem: ((activeMinutes * 60) / itemsProcessed).toFixed(2),
            itemsPerHour: ((itemsProcessed / activeMinutes) * 60).toFixed(2),
          };
        }
        setProductivityStats(stats);
      }

      if (batchesForProductivityStatsQuery.error) {
        appState.setAlert(
          batchesForProductivityStatsQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      batchesForProductivityStatsQuery.loading,
      batchesForProductivityStatsQuery.error,
      batchesForProductivityStatsQuery.data,
    ]);

    useEffect(() => {
      if (fetchSpecificBatch.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificBatch.data) {
        setExpandedBatch(fetchSpecificBatch.data.batch);
      }

      if (fetchSpecificBatch.error) {
        setExpandedBatch(null);
      }
    }, [
      fetchSpecificBatch.loading,
      fetchSpecificBatch.error,
      fetchSpecificBatch.data,
    ]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return batchesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const selectAllRows = () => {
      if (allRowsSelected === true) {
        setSelectedRows([]);
        setAllRowsSelected(false);
      } else {
        setAllRowsSelected(true);
        setSelectedRows(entity.entities.map((item) => item.id));
      }
    };

    /** Action to select a row in the table and add it to selectedRows */
    const selectRow = (index) => {
      const selectedRowsCopy = [...selectedRows];
      if (selectedRowsCopy.includes(entity.entities[index].id)) {
        selectedRowsCopy.splice(
          selectedRowsCopy.indexOf(entity.entities[index].id),
          1,
        );
      } else {
        selectedRowsCopy.push(entity.entities[index].id);
      }
      setSelectedRows(selectedRowsCopy);
    };

    const displayBatches = () => {
      if (showOnlySelected === true) {
        return entity.entities?.filter((item) =>
          selectedRows.includes(item.id),
        );
      } else {
        return entity.displayEntities;
      }
    };

    /** utility function to download csv file */
    const downloadCsv = (csvString, fileName) => {
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    /** generate csv string for batces based on selected rows in the table */
    const generateBatchesCsvString = () => {
      let csvHeaders = [
        "Batch ID",
        "Order ID",
        "SKU",
        "Item Name",
        "Storage location",
        "Required Quantity",
        "Packed Quantity",
        "Packer",
        "Assigned Time(batch)",
        "First Pack(batch)",
        "Last Pack(batch)",
        "Batch Status",
      ];
      let csvData = [csvHeaders];

      selectedRows.forEach((batchId) => {
        const batch = entity.entities.find((item) => item.id === batchId);
        const firstCompletedTime = batch?.attributes?.firstCompletedTime;
        const firstPackTime = firstCompletedTime
          ? moment(firstCompletedTime).format("MMM Do YYYY, h:mm a")
          : null;

        const lastCompletedTime = batch?.attributes?.lastCompletedTime;
        const lastPackTime = lastCompletedTime
          ? moment(lastCompletedTime).format("MMM Do YYYY, h:mm a")
          : null;

        const assignedTime = batch?.assignedTime
          ? moment(batch.assignedTime).format("MMM Do YYYY, h:mm a")
          : null;

        batch.items.forEach((item) => {
          csvData.push([
            batch.id,
            item?.orderId,
            item.sku,
            item.productName,
            item.binLocation,
            item.quantity,
            item.availableQuantity,
            usersMap[batch.user]?.name,
            assignedTime,
            firstPackTime,
            lastPackTime,
            batch.status,
          ]);
        });
      });
      return Papa.unparse(csvData);
    };

    /** Action to download batches csv file based on selected rows in the table */
    const downloadBatchesCsv = () => {
      const csvString = generateBatchesCsvString();
      const fileName = `${new Date()
        .toDateString()
        .replace(/\s+/g, "-")}-Packing-Batch-List.csv`;
      downloadCsv(csvString, fileName);
    };

    return (
      <WrappedComponent
        packingsTableResult={packingsTableData}
        packingsTableError={packingsTableResult.error}
        batchItems={batchItems}
        setBatchItems={setBatchItems}
        allData={batchesQuery.data ? batchesQuery.data.getAllBatches : {}}
        batches={entity.entities}
        displayBatches={displayBatches()}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          batchesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            batchesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        showView={showView}
        setShowView={setShowView}
        loadingDataTable={packingsTableResult?.isLoading}
        users={users}
        usersMap={usersMap}
        expandBatch={(batchId) => fetchSpecificBatch.fetchData({ batchId })}
        expandedBatch={expandedBatch}
        setExpandedBatch={setExpandedBatch}
        stats={stats}
        setStats={setStats}
        productivityStats={productivityStats}
        setProductivityStats={setProductivityStats}
        fetchBatchesForStats={() =>
          batchesForStatsQuery.fetchData({
            filters: entity.filters,
          })
        }
        fetchBatchesForProductivityStats={() =>
          batchesForProductivityStatsQuery.fetchData({
            filters: entity.filters,
          })
        }
        setSelectedPacker={setSelectedPacker}
        selectedPacker={selectedPacker}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        stations={stations}
        selectedRows={selectedRows}
        selectRow={selectRow}
        selectAllRows={selectAllRows}
        allRowsSelected={allRowsSelected}
        showOnlySelected={showOnlySelected}
        setShowOnlySelected={setShowOnlySelected}
        clearSelectedBatches={() => {
          setSelectedRows([]);
          setAllRowsSelected(false);
          setShowOnlySelected(false);
        }}
        downloadBatchesCsv={downloadBatchesCsv}
      />
    );
  };
};

export default withPackingsLogic;
