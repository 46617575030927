import React, { useState } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import Stats from "#components/common/Stats";
import _ from "lodash";
import DetailedStat from "./DetailedStat";
import { Table } from "antd";
import TrendsOfAvgIBTimeChart from "./TrendsOfAvgIBTime";
import TrendsOfAvgIBTimeByProduct from "./TrendsOfAvgIBTimeByProduct";

const InboundStats = ({
  dateRange,
  customDate,
  tenantId,
  customers,
  warehouses,
}) => {
  const isCustomDate = dateRange === "Custom Date";
  const appliedDateRange = isCustomDate
    ? { dateRange: [customDate.start, customDate.end] }
    : { dateRange };

  const byMerchantQuery = {
    measures: ["Batches.receiving_cycle_time", "Batches.putaway_cycle_time"],
    timeDimensions: [
      {
        dimension: "Batches.createdat",
        dateRange: appliedDateRange.dateRange,
        granularity: "day",
      },
    ],
    order: {
      "Batches.createdat": "asc",
    },
    dimensions: ["customers.name"],
    filters: [
      {
        member: "Batches.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "BatchesCustomer.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "BatchesWarehouse.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  };
  const bySkuQuery = {
    measures: ["Batches.receiving_cycle_time", "Batches.putaway_cycle_time"],
    timeDimensions: [
      {
        dimension: "Batches.createdat",
        dateRange: appliedDateRange.dateRange,
        granularity: "day",
      },
    ],
    order: {
      "Batches.createdat": "asc",
    },
    dimensions: ["Productvariants.sku"],
    filters: [
      {
        member: "Batches.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "BatchesCustomer.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "BatchesWarehouse.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  };
  const byMerchantSkuQuery = {
    measures: ["Batches.receiving_cycle_time", "Batches.putaway_cycle_time"],
    timeDimensions: [
      {
        dimension: "Batches.createdat",
        dateRange: appliedDateRange.dateRange,
        granularity: "day",
      },
    ],
    order: {
      "Batches.createdat": "asc",
    },
    dimensions: ["Productvariants.sku", "customers.name"],
    filters: [
      {
        member: "Batches.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "BatchesCustomer.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "BatchesWarehouse.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  };
  const binLocationsFilledQuery = {
    dimensions: [],
    order: {},
    filters: [
      {
        member: "Skubinmappings.binlocationtype",
        operator: "equals",
        values: ["BIN"],
      },
      {
        member: "Skubinmappings.unallocatedQuantity",
        operator: "gt",
        values: ["0"],
      },
      {
        member: "Skubinmappings.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "Skubinmappings.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "Skubinmappings.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
    measures: ["Skubinmappings.count"],
  };

  const binLocationTotalQuery = {
    measures: ["Binlocations.count"],
    dimensions: [],
    order: {},
    filters: [
      {
        member: "Binlocations.binlocationtype",
        operator: "equals",
        values: ["BIN"],
      },
      {
        member: "Binlocations.tenant",
        operator: "equals",
        values: [tenantId],
      },
    ],
  };

  const receivingEfficiencyQuery = {
    measures: [
      "Batches.product_receiving_completed_count",
      "Batches.receiving_completed_count",
      "Batches.receiving_cycle_time",
    ],
    timeDimensions: [
      {
        dimension: "Batches.createdat",
        dateRange: appliedDateRange.dateRange,
      },
    ],
    order: {
      "Batches.product_receiving_completed_count": "desc",
    },
    dimensions: ["Users.name"],
    filters: [
      {
        member: "Batches.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "BatchesCustomer.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "BatchesWarehouse.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  };

  const dailyPackagesQuery = {
    measures: ["Batches.receiving_completed_count"],
    timeDimensions: [
      {
        dimension: "Batches.createdat",
        granularity: "day",
        dateRange: appliedDateRange.dateRange,
      },
    ],
    order: {},
    dimensions: [],
    filters: [
      {
        member: "Batches.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "BatchesCustomer.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "BatchesWarehouse.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  };

  const { resultSet: byMerchantResultSet, isLoading: byMerchantIsLoading } =
    useCubeQuery(byMerchantQuery);
  const { resultSet: bySkuResultSet, isLoading: bySkuIsLoading } =
    useCubeQuery(bySkuQuery);
  const {
    resultSet: byMerchantSkuResultSet,
    isLoading: byMerchantSkuIsLoading,
  } = useCubeQuery(byMerchantSkuQuery);
  const {
    resultSet: binLocationsFilled,
    isLoading: binLocationsFilledIsloading,
  } = useCubeQuery(binLocationsFilledQuery);
  const { resultSet: binLocationTotal, isLoading: binLocationTotalIsLoading } =
    useCubeQuery(binLocationTotalQuery);
  const {
    resultSet: receivingEfficiencyResultSet,
    isLoading: receivingEfficiencyIsLoading,
  } = useCubeQuery(receivingEfficiencyQuery);

  const { resultSet: dailyPackageResultSet, isLoading: dailyPackageIsLoading } =
    useCubeQuery(dailyPackagesQuery);

  const [selectedStatDetail, setSelectedStatDetail] = useState();
  const [searchFilter, setSearchFilter] = useState("");

  const onChangeSearch = (e) => setSearchFilter(e);

  const debouncedSearch = _.debounce(onChangeSearch, 1000);

  function getListFrom(resultSet, field, timeConverter = 1) {
    let list = resultSet
      .map((i) => i[field] / timeConverter)
      .filter((i) => i != null);
    console.log("Length: List Values" + list.toString());
    return list?.length ? list : [0];
  }

  function getFormatedTime(timeInHours) {
    if (timeInHours > 0) {
      let timeString = `${Math.floor(timeInHours)} hr ${Math.round(
        (timeInHours - Math.floor(timeInHours)) * 60,
      )} min`;
      return timeString;
    } else return "0 hr";
  }

  function getAvgInboundTime(resultSet, groupBy) {
    if (resultSet && resultSet.loadResponse.results[0]) {
      let dataList = [];
      let dataSet = {};

      resultSet.loadResponse.results[0].data.map((item) => {
        dataSet[item[groupBy]] = {
          "Batches.receiving_cycle_time":
            (item[groupBy] in dataSet
              ? Number(
                  dataSet[item[groupBy]]["Batches.receiving_cycle_time"] ?? 0,
                )
              : 0) + Number(item["Batches.receiving_cycle_time"] ?? 0),
          countRE:
            (item[groupBy] in dataSet
              ? Number(dataSet[item[groupBy]]["countRE"])
              : 0) + 1,
          "Batches.putaway_cycle_time":
            (item[groupBy] in dataSet
              ? Number(
                  dataSet[item[groupBy]]["Batches.putaway_cycle_time"] ?? 0,
                )
              : 0) + Number(item["Batches.putaway_cycle_time"] ?? 0),
          countPA:
            (item[groupBy] in dataSet
              ? Number(dataSet[item[groupBy]]["countPA"])
              : 0) + 1,
        };
      });

      resultSet.loadResponse.results[0].data.forEach((element) => {
        element.countRE = Number(dataSet[element[groupBy]]["countRE"]);
        element.countPA = Number(dataSet[element[groupBy]]["countPA"]);
        element["avg_inbound_time"] = Math.round(
          dataSet[element[groupBy]]["Batches.receiving_cycle_time"] /
            dataSet[element[groupBy]].countRE +
            dataSet[element[groupBy]]["Batches.putaway_cycle_time"] /
              dataSet[element[groupBy]].countPA,
        );
        dataList.push(element);
      });
      dataList = Array.from(new Set(dataList.map((a) => a[groupBy]))).map(
        (id) => {
          return dataList.find((a) => a[groupBy] === id);
        },
      );
      console.log("Sum:" + JSON.stringify(dataList) + dataList.length);
      return dataList.sort(
        (a, b) => b["avg_inbound_time"] - a["avg_inbound_time"],
      );
    }
  }

  function getDetailedData(view) {
    switch (view) {
      case "AVG_IB_TIME_PER_MERCHANT":
        return getAvgInboundTime(byMerchantResultSet, "customers.name");
      case "AVG_IB_TIME_PER_MERCHANT_PER_SKU":
        return getAvgInboundTime(byMerchantSkuResultSet, "Productvariants.sku");
      default:
        return [];
    }
  }

  function getReceivingEfficiencyData() {
    let resultSet =
      receivingEfficiencyResultSet?.loadResponse?.results[0]?.data
        .filter(
          (i) =>
            i["Batches.receiving_cycle_time"] != null &&
            i["Batches.receiving_cycle_time"] != 0,
        )
        .sort(
          (a, b) => b.receiving_completed_count - a.receiving_completed_count,
        ) ?? [];
    return resultSet;
  }

  return (
    <div className="w-full">
      <div>
        <div>
          Average Inbound Time
          {selectedStatDetail ? (
            <DetailedStat
              title={selectedStatDetail.title}
              onClose={() => {
                setSelectedStatDetail(null);
                setSearchFilter("");
              }}
              stat={selectedStatDetail}
              data={
                getDetailedData(selectedStatDetail.view)?.filter(
                  (item) =>
                    item[selectedStatDetail.tableColumns[0].key]
                      ?.toLowerCase()
                      .includes(searchFilter.toLowerCase()) ||
                    item[selectedStatDetail.tableColumns[1].key]
                      ?.toLowerCase()
                      .includes(searchFilter.toLowerCase()),
                ) ?? []
              }
              onChangeSearchFilter={debouncedSearch}
              dataIsLoading={byMerchantIsLoading || byMerchantSkuIsLoading}
              searchFilter={searchFilter}
            />
          ) : (
            <Stats
              stats={[
                {
                  name: "By Merchant",
                  stringValue: true,
                  renderValue: () =>
                    byMerchantIsLoading
                      ? "Loading..."
                      : byMerchantResultSet?.loadResponse?.results[0]?.data
                      ? getFormatedTime(
                          _.mean(
                            getListFrom(
                              getAvgInboundTime(
                                byMerchantResultSet,
                                "Batches.createdat",
                              ),
                              "avg_inbound_time",
                              60 * 60,
                            ),
                          ),
                        )
                      : "0 hr",
                  setShowView: (view) =>
                    setSelectedStatDetail({
                      query: byMerchantQuery,
                      tableColumns: [
                        {
                          title: "Merchant Name",
                          dataIndex: "customers.name",
                          key: "customers.name",
                          render: (text) => (
                            <a className="text-blue-500 cursor-pointer">
                              {text}
                            </a>
                          ),
                        },
                        {
                          title: "Avg Inbound Time",
                          dataIndex: "avg_inbound_time",
                          key: "Batches.receiving_cycle_time",
                          render: (text) => (
                            <a className="text-blue-500 cursor-pointer">
                              {getFormatedTime(Number(text) / 3600)}
                            </a>
                          ),
                        },
                      ],
                      view: view,
                      title: "Inbound Time By Merchant",
                    }),
                  view: "AVG_IB_TIME_PER_MERCHANT",
                },
                {
                  name: "By SKU",
                  stringValue: true,
                  renderValue: () =>
                    bySkuIsLoading
                      ? "Loading..."
                      : bySkuResultSet?.loadResponse?.results[0]?.data
                      ? getFormatedTime(
                          _.mean(
                            getListFrom(
                              getAvgInboundTime(
                                bySkuResultSet,
                                "Batches.createdat",
                              ),
                              "avg_inbound_time",
                              60 * 60,
                            ),
                          ),
                        )
                      : "0 hr",
                  setShowView: (view) =>
                    setSelectedStatDetail({
                      query: bySkuQuery,
                      tableColumns: [
                        {
                          title: "Merchant Name",
                          dataIndex: "customers.name",
                          key: "customers.name",
                          render: (text) => (
                            <a className="text-blue-500 cursor-pointer">
                              {text}
                            </a>
                          ),
                        },
                        {
                          title: "Product Variants Sku",
                          dataIndex: "Productvariants.sku",
                          key: "Productvariants.sku",
                          render: (text) => (
                            <a className="text-blue-500 cursor-pointer">
                              {text}
                            </a>
                          ),
                        },
                        {
                          title: "Avg Inbound Time",
                          dataIndex: "avg_inbound_time",
                          key: "Batches.receiving_cycle_time",
                          render: (text) => (
                            <a className="text-blue-500 cursor-pointer">
                              {getFormatedTime(Number(text) / 3600)}
                            </a>
                          ),
                        },
                      ],
                      view: view,
                      title: "Inbound Time By SKU",
                    }),
                  view: "AVG_IB_TIME_PER_MERCHANT_PER_SKU",
                },
                {
                  name: "Avg. Daily Packages",
                  renderValue: () =>
                    dailyPackageIsLoading
                      ? "Loading..."
                      : dailyPackageResultSet?.loadResponse?.results[0]?.data
                      ? Math.round(
                          _.mean(
                            getListFrom(
                              dailyPackageResultSet.loadResponse.results[0]
                                .data,
                              "Batches.receiving_completed_count",
                            ),
                          ),
                        )
                      : 0,
                  view: "AVG_PKGS_RECEIVED_PER_DAY",
                },
                {
                  name: "Space Utilization (%)",
                  renderValue: () =>
                    binLocationTotalIsLoading || binLocationsFilledIsloading
                      ? "Loading..."
                      : binLocationsFilled?.loadResponse?.results[0]?.data[0] &&
                        binLocationTotal?.loadResponse?.results[0]?.data[0]
                      ? Math.ceil(
                          (binLocationsFilled.loadResponse.results[0].data[0][
                            "Skubinmappings.count"
                          ] /
                            binLocationTotal?.loadResponse?.results[0]?.data[0][
                              "Binlocations.count"
                            ]) *
                            100,
                        )
                      : 0,
                },
                {
                  name: "Total Merchants",
                  renderValue: () =>
                    byMerchantIsLoading
                      ? "Loading..."
                      : byMerchantResultSet?.loadResponse?.results[0]?.data
                      ? getAvgInboundTime(byMerchantResultSet, "customers.name")
                          ?.length ?? 0
                      : 0,
                },
              ]}
            />
          )}
          Inbound Time Trends
          <div className="py-5 w-full gap-5 flex">
            <TrendsOfAvgIBTimeChart
              dateRange={appliedDateRange.dateRange}
              tenantId={tenantId}
              customers={customers}
              warehouses={warehouses}
            />
            <TrendsOfAvgIBTimeByProduct
              dateRange={appliedDateRange.dateRange}
              tenantId={tenantId}
              customers={customers}
              warehouses={warehouses}
            />
          </div>
          <div className="py-4">Receiving Efficiency</div>
          <Table
            loading={receivingEfficiencyIsLoading}
            columns={[
              {
                title: "Associate",
                dataIndex: "Users.name",
                key: "Users.name",
                render: (text) => (
                  <a className="text-blue-500 cursor-pointer">{text}</a>
                ),
              },
              {
                title: "Batches Completed Count",
                dataIndex: "Batches.receiving_completed_count",
                key: "Batches.receiving_completed_count",
                render: (text) => (
                  <a className="text-blue-500 cursor-pointer">{text}</a>
                ),
              },
              {
                title: "Product Completed Count",
                dataIndex: "Batches.product_receiving_completed_count",
                key: "Batches.product_receiving_completed_count",
                render: (text) => (
                  <a className="text-blue-500 cursor-pointer">{text}</a>
                ),
              },
              {
                title: "Batches Receiving Time",
                dataIndex: "Batches.receiving_cycle_time",
                key: "Batches.receiving_cycle_time",
                render: (text) => (
                  <a className="text-blue-500 cursor-pointer">
                    {getFormatedTime(Number(text) / 3600)}
                  </a>
                ),
              },
            ]}
            dataSource={getReceivingEfficiencyData()}
            pagination={{ showSizeChanger: false }}
            // onRow={(record) => ({
            //   onClick: () => setSelectedBinLocation(record['binLocation']),
            // })}
          />
        </div>
      </div>
    </div>
  );
};

export default InboundStats;
