import withCustomersLogic from "#components/HOC/withCustomersLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import CustomersList from "#components/customers/CustomersList";
import CustomerForm from "#components/customers/CustomerForm";
import { UserAddIcon } from "@heroicons/react/outline";
import AssignBillingProfileForm from "#components/customers/AssignBillingProfileForm";

const Customers = ({
  customers,
  warehouses,
  selectedCustomer,
  setSelectedCustomer,
  onChangeMultiSelect,
  fetchCustomer,
  saveCustomer,
  deleteButtonClicked,
  onChange,
  setShowAssignBillingProfileForm,
  showAssignBillingProfileForm,
  billingProfiles,
  onChangeBillingProfile,
  selectedBillingProfile,
  assignBillingProfile,
  uploadMultipleEntries,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
  fba2dBarcodeEnabled,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Client Management</PageTitle>
      </div>

      <div className="items-center justify-end flex space-x-2 mb-4">
        <AddButton
          text="Add Client"
          onClick={() => setSelectedCustomer({ active: true })}
          icon={UserAddIcon}
          disabled={!writable}
        />
        <AddButton
          text="Download Template"
          onClick={() =>
            (window.location =
              "https://templates-onboarding.s3.amazonaws.com/prod/Clients.xlsx")
          }
          disabled={!writable}
        />
        <UploadBulk
          dashboardFields={dashboardFields}
          saveBulkUpload={(rows) => saveBulkUpload(rows)}
          errorMessage={errorMessage}
          successMessage={successMessage}
          validate={validate}
          validationResult={validationResult}
        />
      </div>
    </div>
    <CustomersList
      customers={customers}
      editButtonClicked={(e) => {
        fetchCustomer(e.id);
      }}
      deleteButtonClicked={(e) => deleteButtonClicked(e)}
      headers={[
        "Name",
        "Code",
        "Warehouses",
        "Status",
        "Billing Profiles",
        "Last Updated",
        "Action",
      ]}
      noValuesText="No Clients"
      warehouses={warehouses}
      assignButtonClicked={(customer) => {
        setShowAssignBillingProfileForm(customer);
        onChangeBillingProfile(customer?.currentBillingProfile.id);
      }}
      writable={writable}
      onChangeSearchKeyword={onChangeSearchKeyword}
      filters={filters}
      submitFilters={submitFilters}
      clearKeyword={clearKeyword}
      setSort={setSort}
      sort={sort}
      setShowFilters={setShowFilters}
      showFilters={showFilters}
      clearFilters={clearFilters}
      onChangeFilter={onChangeFilter}
      total={total}
      pageNumber={pageNumber}
      checkPagination={checkPagination}
      perPage={perPage}
      setPerPage={setPerPage}
    />
    {showAssignBillingProfileForm && (
      <AssignBillingProfileForm
        billingProfileOptions={billingProfiles}
        onSubmit={assignBillingProfile}
        negativeAction={() => setShowAssignBillingProfileForm(null)}
        onChangeBillingProfile={onChangeBillingProfile}
        selectedBillingProfile={selectedBillingProfile}
      />
    )}
    {selectedCustomer && (
      <CustomerForm
        title={selectedCustomer.id ? "Edit Client" : "Add Client"}
        warehouses={warehouses}
        onChangeMultiSelect={onChangeMultiSelect}
        selectedCustomer={selectedCustomer}
        onChange={onChange}
        onClose={() => setSelectedCustomer()}
        onSubmit={() => saveCustomer(selectedCustomer)}
        customers={customers}
        setSelectedCustomer={setSelectedCustomer}
        fba2dBarcodeEnabled={fba2dBarcodeEnabled}
      />
    )}
  </div>
);

export default withCustomersLogic(Customers);
