import { useState, useEffect, useContext } from "react";
import TextField from "#components/utils/TextField";
import { useQuery } from "#hooks/useQuery";
import { GET_USER } from "#queries";
import { SAVE_USER } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import ModalButton from "#components/utils/ModalButton";
import { MasterDataContext } from "#contexts/masterData";
import Modal from "#components/utils/Modal";

const EmailNotSetLayout = ({ user, onLogout }) => {
  const appState = useContext(AppStateContext);
  const saveUserQuery = useQuery(SAVE_USER);
  const masterData = useContext(MasterDataContext);
  const [email, setEmail] = useState("");
  const getUserQuery = useQuery(GET_USER);
  const [fetchedUser, setFetchedUser] = useState(null);

  useEffect(() => {
    getUserQuery.fetchData({ id: user.id });
  }, []);

  useEffect(() => {
    if (getUserQuery.data) {
      setFetchedUser(getUserQuery.data.user);
    }
    if (getUserQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

  useEffect(() => {
    if (saveUserQuery.data) {
      appState.hideConfirmation();
      appState.setAlert(
        "Email verification link has been sent. Please verify to continue",
        "success",
        5000,
      );
      setTimeout(() => onLogout(), 2000);
    }

    if (saveUserQuery.error) {
      appState.setAlert(saveUserQuery.error.message, "error", 5000);
      appState.hideConfirmation();
    }

    if (saveUserQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [saveUserQuery.loading, saveUserQuery.data, saveUserQuery.error]);

  return (
    <Modal negativeAction={() => {}} title={"Please provide your email"}>
      <div className="space-y-4">
        <p>
          This would enable you to reset your password in case it's forgotten
        </p>
        <TextField
          type="text"
          id="email"
          label="Email"
          placeholder=" "
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          name="email"
        />
      </div>
      <div className="flex justify-center mt-5">
        <div className="mr-4">
          <ModalButton onClick={onLogout} text={"Logout"} />
        </div>
        <ModalButton
          onClick={() => saveUserQuery.fetchData({ ...fetchedUser, email })}
          text={"Submit"}
        />
      </div>
    </Modal>
  );
};

const RenderImage = () => (
  <div className="flex justify-center items-center">
    <img
      className="h-12 w-auto"
      src="https://hopstack-pub.s3.amazonaws.com/logo.png"
      alt="Hopstack"
    />
    <h1 className="font-hammersmith text-2xl text-gray-800 px-2 font-normal">
      Hopstack Inc
    </h1>
  </div>
);

export default EmailNotSetLayout;
