import { getHeaderObject } from "#utils/getHeaderObject";
import cellStyleForTable from "#components/common/CellStyleForTable";

const ChildOrderLineItems = ({ orderLineItems }) => {
  const headers = [
    getHeaderObject("SKU", "sku"),
    getHeaderObject("Quantity", "quantity"),
    getHeaderObject("Form Factor", "formFactor"),
    getHeaderObject("Lot ID", "lotId"),
    getHeaderObject("LPN", "nestedFormFactorId"),
    getHeaderObject("Status", "status"),
  ];

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="relative text-[16px] xl:text-sm w-full h-fit overflow-auto border border-gray-300">
      <table className="text-[16px] w-full divide-y relative divide-gray-200 whitespace-nowrap">
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 z-10">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 pl-4 py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orderLineItems.length === 0 ? (
            <tr className="bg-white">
              <td className="text-tableText pl-2 px-1 py-2 text-left font-medium tracking-wider rounded-br rounded-tr">
                {"No line items found for this order"}
              </td>
            </tr>
          ) : null}
          {orderLineItems.map((item, rowIndex) => (
            <tr
              key={rowIndex}
              className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              {headers.map((header, columnIndex) => {
                let value = header.correspondingValue;
                let cellStyle = cellStyleForTable(
                  value,
                  ["orderId"],
                  columnIndex + 1,
                  rowIndex,
                );

                if (value === "sku") {
                  return (
                    <td className={classNames(cellStyle, "select-text")}>
                      {item.sku}
                    </td>
                  );
                }
                if (value === "quantity") {
                  return <td className={cellStyle}>{item.quantity}</td>;
                }
                if (value === "formFactor") {
                  return <td className={cellStyle}>{item.formFactor}</td>;
                }
                if (value === "lotId") {
                  return <td className={cellStyle}>{item.lotId}</td>;
                }
                if (value === "nestedFormFactorId") {
                  return (
                    <td className={cellStyle}>{item.nestedFormFactorId}</td>
                  );
                }
                if (value === "status") {
                  return <td className={cellStyle}>{item.status}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChildOrderLineItems;
