import React, { useState } from "react";
import Modal from "#components/utils/Modal";

const ReportPreview = ({
  onClose,
  title,
  onSubmit,
  fetchedReport,
  previewData,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      positiveAction={onSubmit}
      xIconClicked={onClose}>
      <div className="space-y-4">
        <div>
          <div className="font-medium font-montserrat text-454A4F text-lg pb-4">
            {fetchedReport.reportName}
          </div>
        </div>
        <div className="font-medium font-montserrat text-454A4F text-sm pb-4">
          This is a preview of the report. Only a maximum of the first 30 rows
          will be shown.
        </div>
        <div className="overflow-auto h-96">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {previewData[0] &&
                  previewData[0].map((item) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {item}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {previewData &&
                previewData.slice(1).map((row) => (
                  <tr>
                    {row.map((item) => (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default ReportPreview;
