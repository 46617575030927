import Checkbox from "#components/utils/Checkbox";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

const CasePackProducts = ({
  eligibleProducts,
  onChangeItem,
  setEligibleProducts,
  setKeyword,
  keyword,
}) => {
  return (
    <div className="p-4 pt-0">
      <div className="flex">
        <div className="text-xl font-montserrat p-4 font-bold pl-2">
          Products:
        </div>
        <div className="text-xl font-montserrat p-4 font-bold pl-2">
          Number of Products Selected:{" "}
          {eligibleProducts.filter((item) => item.enabled).length} <br />
          Total Quantity:{" "}
          {isNaN(
            _.sumBy(
              eligibleProducts.filter((item) => item.enabled),
              "quantity",
            ),
          ) === false &&
          _.sumBy(
            eligibleProducts.filter((item) => item.enabled),
            "quantity",
          ) > 0
            ? _.sumBy(
                eligibleProducts.filter((item) => item.enabled),
                "quantity",
              )
            : 0}{" "}
          <br />
          Total Cases:{" "}
          {isNaN(
            _.sumBy(
              eligibleProducts.filter((item) => item.enabled),
              "numberOfCases",
            ),
          ) === false &&
          _.sumBy(
            eligibleProducts.filter((item) => item.enabled),
            "numberOfCases",
          ) > 0
            ? _.sumBy(
                eligibleProducts.filter((item) => item.enabled),
                "numberOfCases",
              )
            : 0}
        </div>
      </div>
      <div className="relative p-2">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block p-4 pl-10 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder={"Search by ASIN/SKU/FNSKU/UPC/LPN/Name"}
          onChange={(e) => setKeyword(e.target.value)}
          value={keyword}
        />
      </div>
      <div className="overflow-y-auto" style={{ maxHeight: 500 }}>
        <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
          <thead className="p-4 rounded-full bg-primaryAccent px-12">
            <tr className="text-textWhite font-montserrat border-left px-12">
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                <div className="flex justify-center space-x-2">
                  <Checkbox
                    role="checkbox"
                    onChange={(e) =>
                      setEligibleProducts(
                        eligibleProducts.map((i) => ({
                          ...i,
                          enabled: !!!eligibleProducts.find(
                            (item) => item.enabled === true,
                          ),
                        })),
                      )
                    }
                    name="binLocations"
                    value={"Select All Bin Locations"}
                    checked={
                      !!eligibleProducts.find((item) => item.enabled === true)
                    }
                  />
                </div>
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                ASIN
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                SKU
              </th>
              {/* <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                FN SKU
              </th> */}
              {/* <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Name
              </th> */}
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Stock
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Per Case
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                # cases
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Total (Per case * # cases)
              </th>
            </tr>
          </thead>
          <tbody>
            {eligibleProducts.map((item, idx) => (
              <tr className="bg-white" key={item.id}>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
                  <Checkbox
                    role="checkbox"
                    onChange={(e) =>
                      onChangeItem(item.id, "enabled", e.target.checked)
                    }
                    name="binLocations"
                    value={""}
                    checked={item.enabled}
                  />
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {item.sku}
                </td>

                {/* <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {item.fnSku}
                </td> */}
                {/* <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {item.name}
                </td> */}
                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {item.quantities.availableToShip}
                </td>
                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  <input
                    type="number"
                    onChange={(e) =>
                      onChangeItem(
                        item.id,
                        "quantityInCase",
                        parseInt(e.target.value),
                      )
                    }
                    value={item.quantityInCase}
                    min="0"
                  />
                </td>
                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  <input
                    type="number"
                    onChange={(e) =>
                      onChangeItem(
                        item.id,
                        "numberOfCases",
                        parseInt(e.target.value),
                      )
                    }
                    value={item.numberOfCases}
                    min="0"
                  />
                </td>
                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {isNaN(item.quantity) === false && item.quantity}
                  {item.quantity > item.quantities.availableToShip && (
                    <span className="text-red-500"> (Not enough stock)</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CasePackProducts;
