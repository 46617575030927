import React from "react";
import { SearchIcon, XIcon } from "@heroicons/react/solid";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  PencilIcon,
  TrashIcon,
  DocumentAddIcon,
} from "@heroicons/react/outline";

const Transfer = ({
  leftItems,
  rightItems,
  totalSearchResults,
  onChangeSearch,
  onClickAdd,
  onClickRemove,
  leftHeaders,
  rightHeaders,
  onClickEditQuantity,
}) => {
  return (
    <>
      <div className="flex-1">
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="keyword"
            id="keyword"
            className="p-2 block w-full pl-12 text-lg border-gray-300 rounded-md focus:outline-none"
            placeholder="Search"
            onChange={onChangeSearch}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <span className="w-3/5 overflow-hidden overflow-y-scroll h-screen border-2 border-gray-100">
          <table className="min-w-full divide-y divide-gray-200 mb-10">
            <thead className="p-4 rounded-full">
              <tr className="text-primaryAccent font-montserratborder-left">
                {leftHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}
                    >
                      {header}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}
                    >
                      {header}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {leftItems && leftItems.length === 0 ? (
                <tr className="bg-white">
                  {leftHeaders.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                        key={headerIdx}
                      >
                        No Results
                      </td>
                    ) : (
                      <td
                        className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                        key={headerIdx}
                      ></td>
                    )
                  )}
                </tr>
              ) : null}
              {leftItems &&
                leftItems.map((item, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
                  >
                    <td
                      className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                        index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                      }`}
                    >
                      {item.sku}
                    </td>
                    <td>{item.name}</td>
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      <TableFieldButton
                        text={<DocumentAddIcon className="w-6 h-6" />}
                        onClick={() => onClickAdd(item.sku)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </span>
        <span className="w-3/5 overflow-hidden overflow-y-scroll h-screen border-2 border-gray-100">
          <table className="min-w-full divide-y divide-gray-200 mb-10">
            <thead className="p-4 rounded-full">
              <tr className="text-primaryAccent font-montserratborder-left">
                {rightHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}
                    >
                      {header}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}
                    >
                      {header}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {rightItems && rightItems.length === 0 ? (
                <tr className="bg-white">
                  {rightHeaders.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                        key={headerIdx}
                      >
                        Add SKUs
                      </td>
                    ) : (
                      <td
                        className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                        key={headerIdx}
                      ></td>
                    )
                  )}
                </tr>
              ) : null}
              {rightItems &&
                rightItems.map((item, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
                  >
                    <td
                      className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                        index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                      }`}
                    >
                      {item.sku}
                    </td>

                    <td className="pl-5">{item.quantity}</td>
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      <div className="flex space-x-4 items-center">
                        <TableFieldButton
                          onClick={() => onClickEditQuantity(item.sku)}
                          text={<PencilIcon className="w-6 h-6" />}
                        />
                        <TableFieldButton
                          text={<TrashIcon className="w-6 h-6" />}
                          onClick={() => onClickRemove(item.sku)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </span>
      </div>
    </>
  );
};

export default Transfer;
