import { useState } from "react";
import Accordian from "#components/utils/Accordian";
import ChildOrderLineItems from "#components/orders/expandedOrder/ChildOrderLineItems";
import { Menu } from "@headlessui/react";
import {
  DocumentSearchIcon,
  DotsVerticalIcon,
  CheckCircleIcon,
  DownloadIcon,
  MinusCircleIcon,
} from "@heroicons/react/outline";
import { PrinterIcon } from "@heroicons/react/solid";
import { isDelmarTenant } from "#utils/tenantCheck";

const ExpandedChildOrders = ({
  childOrders,
  packingLabelsEnabled,
  printFnSkuLabels,
  downloadFnSkuLabels,
  printPackingLabels,
  downloadPackingLabel,
  csvPackingListEnabled,
  downloadPackingList,
  getAudit,
  tenant,
  downloadOrderReport,
  printPalletLabels,
  downloadPalletLabels,
  printBoxLabels,
  bolUploadEnabled,
  downloadBol,
  printBol,
  downloadBoxLabels,
  printShippingLabel,
  downloadShippingLabel,
  printOrder,
  showOnlyAudit = false,
  cancelOrders,
  markCompleted,
  writable,
}) => {
  const [activeIdx, setActiveIdx] = useState(0);

  if (!childOrders || childOrders.length === 0) {
    return <p>No Suborders Found for this order.</p>;
  }

  const menuItems = (order) => {
    const arr = [];

    arr.push({
      title: "Audit Trail",
      icon: DocumentSearchIcon,
      onClick: getAudit,
    });

    if (showOnlyAudit) {
      return arr;
    }
    if (order.source === "FBA") {
      arr.push({
        title: "Print FNSKUs",
        icon: PrinterIcon,
        onClick: printFnSkuLabels,
        field: "id",
      });
      arr.push({
        title: "Download FNSKUs",
        icon: DownloadIcon,
        onClick: downloadFnSkuLabels,
        field: "id",
      });
    }
    if (packingLabelsEnabled && order?.subStatus === "PACKING_COMPLETED") {
      arr.push({
        title: "Print Packing Label",
        icon: PrinterIcon,
        onClick: printPackingLabels,
      });
      arr.push({
        title: "Download Packing Label",
        icon: DownloadIcon,
        onClick: downloadPackingLabel,
      });
    }
    if (order?.subStatus === "PACKING_COMPLETED" && csvPackingListEnabled) {
      arr.push({
        title: "Packing List (CSV)",
        icon: DownloadIcon,
        onClick: downloadPackingList,
      });
    }
    if (tenant?.typeOfCustomer?.includes("B2B") || bolUploadEnabled) {
      arr.push({
        title: "Download BOL",
        icon: DownloadIcon,
        onClick: downloadBol,
        field: "id",
      });
      arr.push({
        title: "Print BOL",
        icon: PrinterIcon,
        onClick: printBol,
        field: "id",
      });
    }
    if (order?.shippingLabel) {
      arr.push({
        title: "Print Shipping Label",
        icon: PrinterIcon,
        onClick: printShippingLabel,
        field: "id",
      });
      arr.push({
        title: "Download Shipping Label",
        icon: DownloadIcon,
        onClick: downloadShippingLabel,
        field: "id",
      });
    }
    if (order.source === "FBA" && order?.boxLabels) {
      arr.push(
        {
          title: "Print Box Labels",
          icon: PrinterIcon,
          onClick: printBoxLabels,
          field: "id",
        },
        {
          title: "Download Box Labels",
          icon: DownloadIcon,
          onClick: downloadBoxLabels,
          field: "id",
        },
      );
    }
    if (order.source === "FBA" && order.typeOfShipment === "LTL") {
      arr.push(
        {
          title: "Print Pallet Labels",
          icon: PrinterIcon,
          onClick: printPalletLabels,
          field: "id",
        },
        {
          title: "Download Pallet Labels",
          icon: DownloadIcon,
          onClick: downloadPalletLabels,
          field: "id",
        },
      );
    }
    if (downloadOrderReport) {
      arr.push({
        title: "Download Report",
        icon: DownloadIcon,
        onClick: downloadOrderReport,
        vars: "id",
      });
    }
    if (isDelmarTenant(tenant?.subdomain)) {
      arr.push({
        title: "Print Order",
        icon: PrinterIcon,
        onClick: printOrder,
      });
    }

    // ANY ORDER THAT'S NOT COMPLETED OR CANCELLED CAN BE CANCELLED.
    // WE WILL HAVE BACKEND PROTECTIONS LATER ON FOR FULL STATE DIAGRAM IMPLEMENTATION OF ORDER STATUS CHANGES
    if (
      ["CANCELLED", "COMPLETED"].includes(order.orderStatus) === false &&
      writable
    ) {
      arr.push({
        title: "Mark as Cancelled",
        icon: MinusCircleIcon,
        onClick: cancelOrders,
      });
      arr.push({
        title: "Mark Complete",
        icon: CheckCircleIcon,
        onClick: markCompleted,
      });
    }

    return arr;
  };

  const MenuActions = ({ order }) => {
    return (
      <Menu as="div" className="text-left relative">
        <Menu.Button>
          <DotsVerticalIcon className="w-8 bg-E1D3B8 p-2 text-md text-5F666B font-montserrat rounded h-8" />
        </Menu.Button>
        <Menu.Items
          className={`absolute mt-2 origin-top-right right-0 z-20 bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div className="max-h-96 overflow-y-scroll">
            {menuItems(order).map((menuItem, idx) => (
              <Menu.Item key={idx}>
                {({ active }) => (
                  <button
                    className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                    onClick={(e) => {
                      e.preventDefault();
                      menuItem["onClick"](
                        order[menuItem["field"] ? menuItem["field"] : "id"],
                      );
                    }}>
                    {menuItem.icon && (
                      <menuItem.icon className="w-8 h-8 pr-2" />
                    )}
                    {menuItem.title}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    );
  };

  return (
    <>
      <div className="flex flex-col gap-y-2">
        {childOrders.map((childOrder, idx) => {
          const originalOrderTitleComponent = (
            <div>
              <p className="font-semibold text-lg">{`Suborder ${idx + 1}`}</p>
              <div className="w-max my-2">
                <div className="grid grid-cols-3 pl-6">
                  <span className="font-semibold">Status: </span>
                  <span className="ml-4 font-medium col-span-2">
                    {childOrder.orderStatus}
                  </span>
                </div>
                <div className="grid grid-cols-3 pl-6">
                  <span className="font-semibold">SubStatus: </span>
                  <span className="ml-4 font-medium col-span-2">
                    {childOrder.subStatus}
                  </span>
                </div>
                {childOrder.tote && (
                  <div className="grid grid-cols-3 pl-6">
                    <span className="font-semibold">Tote: </span>
                    <span className="ml-4 font-medium col-span-2">
                      {childOrder.tote}
                    </span>
                  </div>
                )}
              </div>
            </div>
          );

          return (
            <div key={childOrder.id} className="flex gap-x-2">
              <Accordian
                key={activeIdx}
                titleComponent={originalOrderTitleComponent}
                isActive={true}
                toggleCallback={(index) => {
                  if (index === activeIdx) setActiveIdx(-1);
                  else setActiveIdx(index);
                }}
                index={idx}>
                <div className="flex justify-items-end"></div>
                <ChildOrderLineItems
                  orderLineItems={
                    childOrder.orderLineItems !== null &&
                    childOrder.orderLineItems.length > 0
                      ? childOrder.orderLineItems
                      : childOrder.orderProducts
                  }
                />
              </Accordian>
              <MenuActions order={childOrder} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ExpandedChildOrders;
