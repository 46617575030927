import { useContext, useEffect, useState } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_PRESIGNED_URL_FOR_S3_BUCKET_CONTAINING_PRODUCT_IMAGES } from "#queries";
import { AppStateContext } from "#contexts/appState";

/**
 * Custom hook for managing and fetching presigned URLs for an AWS S3 bucket.
 *
 * @returns {string} .presignedURL The current presigned URL.
 * @returns {Function} .setPresignedURL Function to manually set the presigned URL.
 * @returns {Function} .getPresignedURL Function to fetch a new presigned URL from the server.
 *
 * @example
 * const { presignedURL, setPresignedURL, getPresignedURL } = usePresignedURL();
 */
export const usePresignedURL = () => {
  const getPresignedURLForS3Bucket = useQuery(
    GET_PRESIGNED_URL_FOR_S3_BUCKET_CONTAINING_PRODUCT_IMAGES,
  );
  const appState = useContext(AppStateContext);

  const [presignedURL, setPresignedURL] = useState();

  useEffect(() => {
    if (getPresignedURLForS3Bucket.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (getPresignedURLForS3Bucket.data) {
      setPresignedURL(
        getPresignedURLForS3Bucket.data
          .getPresignedUrlForS3BucketContainingProductImages.url,
      );
    }

    if (getPresignedURLForS3Bucket.error) {
      appState.setAlert(
        getPresignedURLForS3Bucket.error.message,
        "error",
        5000,
      );
    }
  }, [
    getPresignedURLForS3Bucket.error,
    getPresignedURLForS3Bucket.data,
    getPresignedURLForS3Bucket.loading,
  ]);

  const getPresignedURL = ({ key, method }) => {
    getPresignedURLForS3Bucket.fetchData({ key, method });
  };

  return {
    presignedURL,
    setPresignedURL,
    getPresignedURL,
    getPresignedURLForS3Bucket,
  };
};
