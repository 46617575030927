import _ from "lodash";
import SelectWarehouseCustomerCode from "#components/putaway/bundle/SelectWarehouseCustomerCode";
import ScanOrEnterManually from "#components/putaway/bundle/ScanOrEnterManually";
import DetailedView from "#components/putaway/bundle/DetailedView";
import withBundlePutawayLogic from "#components/HOC/withBundlePutawayLogic";

const BundlePutaway = ({
  currentSku,
  setCurrentSku,
  scanBarcode,
  confirmItem,
  submitWarehouseCustomerCode,
  customer,
  customers,
  warehouse,
  warehouses,
}) => {
  if (!customer || !warehouse) {
    return (
      <>
        <SelectWarehouseCustomerCode
          customers={customers}
          warehouses={warehouses}
          onSubmit={submitWarehouseCustomerCode}
        />
      </>
    );
  }

  if (currentSku) {
    return (
      <>
        <div className="text-xl bg-green-600 fixed bottom-0 right-0 p-4 text-center text-white">
          Listening for Print Commands
        </div>
        <div className="text-2xl bg-black left-0 w-full text-center text-white">
          {customers.find((item) => item.id === customer)?.name}
        </div>
        <div className="mt-10">
          <DetailedView
            currentSku={currentSku}
            confirmItem={confirmItem}
            scanBarcode={scanBarcode}
            setCurrentSku={setCurrentSku}
            showCancelOption={false}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-2xl bg-black left-0 w-full text-center text-white">
        {customers.find((item) => item.id === customer)?.name}
      </div>
      <div className="text-xl bg-green-600 fixed bottom-0 right-0 p-4 text-center text-white">
        Listening for Print Commands
      </div>
      <div className="pt-48">
        <ScanOrEnterManually onSubmit={scanBarcode} text="Scan an Item Code" />
      </div>
    </>
  );
};

export default withBundlePutawayLogic(BundlePutaway);
