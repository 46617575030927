const Button = ({
  onClick,
  transparent,
  text,
  icon: Icon,
  disabled,
  styles,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={`flex items-center justify-center text-white bg-2C7695 font-montserrat font-medium border-transparent ${
      Icon ? "rounded-full" : "rounded-md"
    } ring-0 focus:ring-0 outline-none focus:outline-none p-0 ${
      styles ? styles.join(" ") : ""
    } ${disabled ? "bg-gray-500 hover:cursor-default" : ""}`}
    onClick={onClick}>
    {Icon && (
      <>
        <Icon className="w-10 h-10 py-1 md:py-2 text-white border border-primaryAccent rounded-full bg-primaryAccent" />
        <span className="px-2 pr-2 md:pr-4">{text || "Button"}</span>
      </>
    )}
    {!Icon && (
      <>
        <span className="w-full py-1 md:py-2 px-2">{text || "Button"}</span>
      </>
    )}
  </button>
);

export default Button;
