import {
  DesktopComputerIcon,
  LogoutIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

const IntegrationProviders = ({ children, ...props }) => {
  const {
    integrations,
    integrationProviders = [],
    connectIntegration,
    logoutIntegration,
    testIntegration,
    getProducts,
    selectedCustomer,
    setSelectedIntegrationToManage,
    writable,
    multiAccountSupportEnabled,
  } = props;
  return (
    <div className="grid grid-cols-3 gap-4">
      {integrationProviders
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .map((integrationProvider, idx) => (
          <div className="bg-white shadow-lg rounded-lg py-8 pt-0" key={idx}>
            <div className="flex bg-integrationLogoBackground rounded-t-lg py-6 w-full justify-center">
              <img
                src={integrationProvider.logo}
                className="w-32 h-20 object-contain"
              />
            </div>
            <div className="flex h-40 flex-col items-center py-6 px-4 text-center justify-center">
            <div className="text-xl font-medium text-primaryAccent">
                {integrationProvider.name}
              </div>
              <div className="text-lg font-normal text-5F666B">
                {integrationProvider.description}
              </div>
            </div>
            <div className="flex mx-6 mt-4 space-x-2">
              {integrationProvider.multipleAccountSupport &&
              multiAccountSupportEnabled ? (
                <button
                  className={`w-full px-10 py-2 text-white ${
                    writable ? "bg-2C7695" : "bg-gray-500"
                  } items-center justify-center cursor-pointer text-center rounded-lg`}
                  onClick={() =>
                    writable &&
                    setSelectedIntegrationToManage(integrationProvider.name)
                  }>
                  Manage
                </button>
              ) : (
                integrations.findIndex(
                  (item) =>
                    item.integrationType === integrationProvider.name &&
                    item.customer === selectedCustomer,
                ) === -1 && (
                  <button
                    className={`w-full px-10 py-2 text-white ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center cursor-pointer text-center rounded-lg`}
                    onClick={() =>
                      writable && connectIntegration(integrationProvider.name)
                    }>
                    Connect
                  </button>
                )
              )}
              {!(
                integrationProvider.multipleAccountSupport &&
                multiAccountSupportEnabled
              ) &&
                integrations.findIndex(
                  (item) =>
                    item.integrationType === integrationProvider.name &&
                    item.customer === selectedCustomer,
                ) !== -1 && (
                  <div className="flex-col w-full space-y-4">
                    <div className="flex w-full space-between space-x-2">
                      <button
                        className={`flex w-full px-5 py-2 text-white text-md ${
                          writable ? "bg-2C7695" : "bg-gray-500"
                        } items-center justify-center text-center rounded-lg`}
                        onClick={() =>
                          writable &&
                          testIntegration(
                            integrations.find(
                              (item) =>
                                item.integrationType ===
                                  integrationProvider.name &&
                                item.customer === selectedCustomer,
                            ).id,
                          )
                        }>
                        <DesktopComputerIcon className="w-5 h-5 mr-1" />
                        Test
                      </button>
                      {(integrations.find(
                        (item) =>
                          item.integrationType === integrationProvider.name &&
                          item.customer === selectedCustomer,
                      )?.tags?.[0] === "E_COMMERCE_MARKETPLACE" ||
                        integrations.find(
                          (item) =>
                            item.integrationType === integrationProvider.name &&
                            item.customer === selectedCustomer,
                        )?.tags?.[0] === "MARKETPLACE_FULFILLMENT" ||
                        integrations.find(
                          (item) =>
                            item.integrationType === integrationProvider.name &&
                            item.customer === selectedCustomer,
                        )?.tags?.[0] === "ERP") && (
                        <button
                          className={`flex w-full px-5 py-2 text-white text-md ${
                            writable ? "bg-2C7695" : "bg-gray-500"
                          } items-center justify-center text-center rounded-lg`}
                          onClick={() =>
                            writable &&
                            getProducts(
                              integrations.find(
                                (item) =>
                                  item.integrationType ===
                                    integrationProvider.name &&
                                  item.customer === selectedCustomer,
                              ).id,
                            )
                          }>
                          <RefreshIcon className="w-5 h-5 mr-1" />
                          Sync Now
                        </button>
                      )}
                    </div>
                    <div className="flex w-full space-between space-x-2">
                      <button
                        className={`flex w-full px-5 py-2 text-white text-md ${
                          writable ? "bg-2C7695" : "bg-gray-500"
                        } items-center justify-center text-center rounded-lg`}
                        onClick={() =>
                          writable &&
                          logoutIntegration(
                            integrations.find(
                              (item) =>
                                item.integrationType ===
                                  integrationProvider.name &&
                                item.customer === selectedCustomer,
                            ).id,
                          )
                        }>
                        <LogoutIcon className="w-5 h-5 mr-1" />
                        Logout
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default IntegrationProviders;
