import { useState, useContext } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";

import { AppStateContext } from "#contexts/appState";

const CompletedBatch = ({
  childTask,
  selectedBundle,
  onChangeScannedQuantity,
  printBundlingLabels,
  confirmBundle,
  skipBundle,
}) => {
  const appState = useContext(AppStateContext);

  const [showConfirm, setShowConfirm] = useState(true);

  const handleConfirmation = () => {
    if (showConfirm) {
      printBundlingLabels();
      setShowConfirm(false);
    } else {
      setShowConfirm(true);
    }
  };

  return (
    <>
      <div className="w-full h-screen -mt-20 flex flex-col items-center justify-center">
        <div className="min-w-max">
          <PageTitle>Bundle Details</PageTitle>
          <div className="text-xl font-medium text-454A4F space-y-2">
            <LineItemDesc title={"SKU"} value={selectedBundle.sku} idx={1} />
            <LineItemDesc
              title={"Hopstack SKU"}
              value={selectedBundle.hopstackSkuId}
              idx={1}
            />
            <LineItemDesc
              title={"Bundle"}
              value={truncate(selectedBundle.productName)}
              idx={2}
            />
            {selectedBundle.fnSku && (
              <>
                <LineItemDesc
                  title={"FN SKU"}
                  value={selectedBundle.fnSku}
                  idx={1}
                />
              </>
            )}
            {selectedBundle.asin && (
              <LineItemDesc
                title={"ASIN"}
                value={selectedBundle.asin}
                idx={1}
              />
            )}
          </div>

          <div className="flex mt-10 text-center items-center justify-center">
            <div className="text-xl pr-2">Quantity</div>
            <input
              type="number"
              onChange={(e) =>
                onChangeScannedQuantity(parseInt(e.target.value), "BUNDLE")
              }
              placeholder="Quantity"
              value={selectedBundle.scannedQuantity}
              className={`p-4 text-2xl ${
                !showConfirm ? "cursor-not-allowed disabled:opacity-80" : ""
              }`}
              disabled={!showConfirm}
              min="0"
            />
          </div>

          {showConfirm ? (
            <div className="mt-20 flex items-center justify-center space-x-4">
              <div className="text-2xl text-black w-64 text-center py-4">
                Have you finished bundling?
              </div>
              <div
                className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
                onClick={handleConfirmation}>
                Yes
              </div>
              <div
                className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4"
                onClick={skipBundle}>
                Cancel
              </div>
            </div>
          ) : (
            <div className="mt-20 flex items-center justify-center space-x-4">
              <div
                className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4"
                onClick={handleConfirmation}>
                Back
              </div>
              <div
                className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
                onClick={confirmBundle}>
                Confirm Bundle
              </div>
              <div
                className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
                onClick={printBundlingLabels}>
                Re-Print SKU
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="fixed" style={{ marginTop: 2000 }}>
        <img
          id={`label-placeholder-${selectedBundle.hopstackSkuId}`}
          style={{ position: "fixed", top: 10000 }}
        />
      </div>
    </>
  );
};

const LineItemDesc = ({ title, value, idx }) => (
  <div className="w-full flex space-x-1 shadow-md">
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`bg-white flex-1 border p-4 rounded-tr-md rounded-br-md`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};

export default CompletedBatch;
