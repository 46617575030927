import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import ModalButton from "#components/utils/ModalButton";

const VendorFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  warehouses,
  clearFilters,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="flex space-x-6 mt-4">
      <ModalButton
        onClick={clearFilters}
        text={`Reset Filters`}
        className="bg-red-500"
      />
    </div>
    <div className="flex space-x-6 justify-between mt-4">
      <>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Warehouse(s)
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey="name"
            valueKey="id"
            setValues={(e) => onChangeFilter("warehouse", e)}
            values={filters["warehouse"]}
            rounded={true}
          />
        </div>
      </>
    </div>
  </Modal>
);

export default VendorFilters;
