import { useState, useContext, useEffect } from "react";
import { useQuery } from "#hooks/useQuery";
import { SAVE_PRODUCT } from "#mutations";
import ModalV2 from "#components/utils/ModalV2";
import { AppStateContext } from "#contexts/appState";
import { TrashIcon } from "@heroicons/react/outline";

import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import ProductInfo from "#components/consignments/ConsignmentForm/ProductInfo";

const ALERT_TIMEOUT_IN_MS = 5000;

const formFactorList = [
  { name: "Each", size: 0 },
  { name: "Case", size: 1 },
  { name: "Carton", size: 2 },
  { name: "Pallet", size: 3 },
];

const UOMConfigurationV2 = ({ product, onUpdate = null, onClose }) => {
  const appState = useContext(AppStateContext);

  const saveProductQuery = useQuery(SAVE_PRODUCT); // to save the product with modified UOM Changes.

  const [selectedProduct, setSelectedProduct] = useState({ ...product });
  const [baseUOM, setBaseUOM] = useState({ name: "Each", size: 0 });
  const [possibleTargetUOMs, setPossibleTargetUOMs] = useState(
    formFactorList.filter((formFactor) => formFactor.size > baseUOM.size),
  );
  const [showAddConfiguration, setShowAddConfiguration] = useState(true);

  const largestUOM = formFactorList[formFactorList.length - 1].name;

  // Save Product Query Effect to save the product with updated UOM Config.
  useEffect(() => {
    saveProductQuery.loading ? appState.setLoading() : appState.removeLoading();

    if (saveProductQuery.data) {
      appState.setAlert(saveProductQuery.data.saveProduct.message);
      // once the product is saved, trigger the onUpdate method call if passed.
      if (onUpdate) {
        onUpdate();
      }
    }

    if (saveProductQuery.error) {
      appState.setAlert(
        saveProductQuery.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  }, [saveProductQuery.data, saveProductQuery.loading, saveProductQuery.error]);

  const updateBaseUOM = (uom) => {
    setSelectedProduct({
      ...selectedProduct,
      baseUom: uom,
      uomConfiguration: [],
    });
    const baseUOMFormFactor = formFactorList.find(
      (formFactor) => formFactor.name == uom,
    );
    setBaseUOM(baseUOMFormFactor);
    setPossibleTargetUOMs(
      formFactorList.filter(
        (formFactor) => formFactor.size > baseUOMFormFactor.size,
      ),
    );
    setShowAddConfiguration(uom !== largestUOM);
  };

  const changeBaseUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUom = name;
    setSelectedProduct({ ...clonedProduct });
  };

  const changeBaseUomQuantity = (idx, e) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].baseUomQuantity =
      isNaN(parseInt(e.target.value)) === false
        ? parseInt(e.target.value)
        : null;
    setSelectedProduct({ ...clonedProduct });
  };

  const changeTargetUom = (idx, name) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration[idx].targetUom = name;
    setSelectedProduct({ ...clonedProduct });
    setShowAddConfiguration(name !== largestUOM);
  };

  const addConfiguration = () => {
    const existingUomConfigurations = selectedProduct.uomConfiguration;
    if (existingUomConfigurations.length > 0) {
      const previousUOMConfig =
        existingUomConfigurations[existingUomConfigurations.length - 1];
      if (
        !previousUOMConfig.baseUomQuantity ||
        isNaN(previousUOMConfig.baseUomQuantity) ||
        !previousUOMConfig.baseUom ||
        !previousUOMConfig.targetUom
      ) {
        appState.setAlert("Provide a valid UOM Configuration", "error", 5000);
        return;
      }
    }

    setSelectedProduct({
      ...selectedProduct,
      uomConfiguration: [
        ...existingUomConfigurations,
        {
          baseUom: "",
          baseUomQuantity: 1,
          targetUom: "",
        },
      ],
    });
    setShowAddConfiguration(
      existingUomConfigurations.length + 1 < possibleTargetUOMs.length,
    );
  };

  const removeUomConfiguration = (idx) => {
    const clonedProduct = { ...selectedProduct };
    clonedProduct.uomConfiguration = clonedProduct.uomConfiguration.filter(
      (_, index) => idx !== index,
    );
    setSelectedProduct({ ...clonedProduct });
    setShowAddConfiguration(
      clonedProduct.uomConfiguration.length < possibleTargetUOMs.length,
    );
  };

  const updateProduct = () => {
    saveProductQuery.fetchData({
      ...selectedProduct,
    });
  };

  return (
    <ModalV2
      title={`UOM Configuration`}
      positiveText={"Update"}
      positiveAction={updateProduct}
      negativeText={"Cancel"}
      negativeAction={onClose}
      onClose={onClose}
      noPadding={true}>
      <div className="mx-6">
        {/* Product Info */}
        <div className="w-full">
          <label className="block text-md font-medium mb-2">Product Info</label>
          <div className="border border-borderGray rounded-lg p-2">
            <ProductInfo
              itemDetails={selectedProduct}
              style={{ totalWidth: "w-full", width: "w-422" }}
            />
          </div>
        </div>
        {/* Base Unit of Measurement */}
        <div className="my-4">
          <label className="block text-md font-medium mb-2">
            Base Unit of Measurement
          </label>
          <div className="w-204">
            <AutocompleteDropdownV2
              options={formFactorList}
              labelKey="name"
              valueKey="name"
              onChange={(uom) => updateBaseUOM(uom)}
              value={selectedProduct.baseUom}
              placeholder="Select Base UOM"
              searchable={false}
            />
          </div>
          {selectedProduct.baseUOM && (
            <div className="text-xs">
              <span className="text-trashIconRed">*</span> Changing Base form
              factor will reset the configuration
            </div>
          )}
        </div>
        {/* UOM Configuration */}
        {selectedProduct.baseUom && (
          <div className="mb-2">
            <label className="block text-md font-medium mb-2">
              Configuration
            </label>
            <div className="space-y-4">
              {selectedProduct?.uomConfiguration?.map(
                (uomConfiguration, idx) => {
                  let baseUOMFormFactorList = [];
                  let targetUOMFormFactorList = [];
                  if (idx === 0) {
                    baseUOMFormFactorList = [baseUOM];
                    targetUOMFormFactorList = formFactorList.filter(
                      (formFactor) => formFactor.size > baseUOM.size,
                    );
                  } else {
                    const previousTargetUOM = formFactorList.find(
                      (formFactor) =>
                        formFactor.name ===
                        selectedProduct.uomConfiguration[idx - 1].targetUom,
                    );
                    baseUOMFormFactorList = [previousTargetUOM];
                    targetUOMFormFactorList = formFactorList.filter(
                      (formFactor) => formFactor.size > previousTargetUOM.size,
                    );
                  }
                  return (
                    <UOMConfig
                      uomConfiguration={uomConfiguration}
                      idx={idx}
                      changeBaseUomQuantity={changeBaseUomQuantity}
                      baseUOMFormFactorList={baseUOMFormFactorList}
                      targetUOMFormFactorList={targetUOMFormFactorList}
                      changeBaseUom={changeBaseUom}
                      changeTargetUom={changeTargetUom}
                      removeUomConfiguration={removeUomConfiguration}
                      disableInteraction={
                        selectedProduct.uomConfiguration.length - 1 !== idx
                      }
                    />
                  );
                },
              )}
            </div>
          </div>
        )}
        {/* Add UOM Config */}
        {showAddConfiguration && selectedProduct.baseUom && (
          <button
            className="border bg-primaryAccent text-white rounded w-171 h-33 p-1 mr-4 cursor-pointer text-sm font-medium"
            onClick={addConfiguration}>
            + Add Configuration
          </button>
        )}
      </div>
    </ModalV2>
  );
};

const UOMConfig = ({
  uomConfiguration,
  idx,
  changeBaseUomQuantity,
  baseUOMFormFactorList,
  changeBaseUom,
  targetUOMFormFactorList,
  changeTargetUom,
  removeUomConfiguration,
  disableInteraction,
}) => {
  return (
    <div className="flex space-x-4 items-center">
      <div>
        <input
          type="number"
          id="quantity"
          label="Quantity"
          placeholder=" "
          onChange={(qty) => changeBaseUomQuantity(idx, qty)}
          value={parseInt(uomConfiguration.baseUomQuantity)}
          name="quantity"
          min="1"
          className="border border-borderGray rounded w-111 p-2"
        />
      </div>
      <div className="w-204">
        <AutocompleteDropdownV2
          options={baseUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          onChange={(uom) => changeBaseUom(idx, uom)}
          value={uomConfiguration.baseUom}
          placeholder="UOM"
          disabled={disableInteraction}
          searchable={false}
        />
      </div>
      <div className="text-2xl">=</div>
      <div className="w-204">
        <AutocompleteDropdownV2
          options={targetUOMFormFactorList}
          labelKey="name"
          valueKey="name"
          onChange={(uom) => changeTargetUom(idx, uom)}
          value={uomConfiguration.targetUom}
          placeholder="Target UOM"
          disabled={disableInteraction}
          searchable={false}
        />
      </div>
      {!disableInteraction && (
        <div
          className="text-red-500 text-lg font-bold cursor-pointer"
          onClick={() => removeUomConfiguration(idx)}>
          <TrashIcon className="w-8 h-8" />
        </div>
      )}
    </div>
  );
};

export default UOMConfigurationV2;
