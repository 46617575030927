import PageTitle from "#components/utils/PageTitle";
import withConsignmentsLogic from "#components/HOC/withConsignmentsLogic";
import ConsignmentsList from "#components/consignments/ConsignmentsList";
import AuditLog from "#components/orders/AuditLog";
import Modal from "#components/utils/Modal";
import _ from "lodash";
import Pagination from "#components/common/Pagination";
import ConsignmentNotesForm from "#components/consignments/ConsignmentNotesForm";
import AddButton from "#components/utils/AddButton";
import ConsignmentFilters from "#components/consignments/ConsignmentFilters";
import UploadBulk from "#components/bulkUpload/UploadBulk";
import ReceivingConsignmentForm from "#components/consignments/ReceivingConsignmentForm";
import ChangeStatusReasonForm from "#components/consignments/ChangeStatusReasonForm";
import OrderDetailsModal from "#components/consignments/OrderDetailsModal";
import ConsignmentForm from "#components/consignments/ConsignmentForm";
import Popover from "#components/utils/Popover";

const consignmentAdditionTypes = [
  { name: "Standard", value: "STANDARD" },
  { name: "Dropship", value: "DROPSHIP" },
];

const Consignments = ({
  writable,
  displayConsignments,
  masterData,
  filters,
  onChangeFilter,
  selectedAudit,
  selectedAuditUsersInfo,
  setSelectedAuditUsersInfo,
  showAudit,
  setShowAudit,
  getAudit,
  setSelectedAudit,
  setItems,
  items,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  showFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  addNewConsignment,
  selectedConsignment,
  setSelectedConsignment,
  onChange,
  onChangeNotes,
  onChangeDropdown,
  saveConsignment,
  saveConsignmentNotes,
  saveConsignmentItemNotes,
  removeItem,
  editConsignment,
  editConsignmentNotes,
  deleteConsignment,
  undoConsignmentReceive,
  selectedConsignmentNotes,
  setSelectedConsignmentNotes,
  selectedWarehouseNotes,
  setSelectedWarehouseNotes,
  selectedConsignmentItemNotes,
  setSelectedConsignmentItemNotes,
  submitAddedProduct,
  editWarehouseNotes,
  saveConsignmentDraft,
  subdomain,
  saveUserTablePreferences,
  dropshipData,
  setDropshipData,
  uploadFile,
  downloadConsignmentReport,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  receiveConsignment,
  receivingConsignment,
  setReceivingConsignment,
  submitReceivingConsignment,
  tenant,
  user,
  debouncedBinLocationSearch,
  binLocationsOptions,
  onChangeReceivingConsignment,
  closeConsignment,
  changeStatusConsignment,
  onChangeStatusConsignment,
  changeStatusConsignmentHandle,
  setChangeStatusConsignment,
  receiveConsignmentPartially,
  isPartiallyReceived,
  setIsPartiallyReceived,
  submitReceiveConsignmentPartially,
  downloadConsignmentItemList,
  validate,
  validationResult,
  casePackEnabled,
  multiAccountSupportEnabled,
  getDamagedInfo,
  showConsignmentDamageInfo,
  closeConsignmentDamageInfo,
  refreshConsignmentItems,
  getConsignmentDetails,
  showConsignmentDetails,
  closeConsignmentDetails,
}) => {
  return (
    <div className="p-5">
      <div className="flex items-center pb-4">
        <div className="flex-1">
          <PageTitle>Consignments</PageTitle>
        </div>

        {writable && (
          <div className="flex space-x-2 items-center justify-end">
            {/* Add Consignment */}
            <Popover
              title={"Add Consignment"}
              showChevron={true}
              panelClassName={
                "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray w-full"
              }
              showOverlay={true}>
              {consignmentAdditionTypes.map((consignmentType, index) => {
                return (
                  <div
                    className="w-full p-3 rounded-lg font-medium text-unselectedTextGray hover:text-primaryAccent hover:bg-hoverHighlight"
                    onClick={() => addNewConsignment(consignmentType.value)}>
                    {consignmentType.name}
                  </div>
                );
              })}
            </Popover>
            {/* Download template for bulk upload */}
            <AddButton
              text="Download Template"
              onClick={() =>
                (window.location =
                  "https://templates-onboarding.s3.amazonaws.com/prod/Consignments.xlsx")
              }
            />
            {/* Bulk upload consignment data */}
            <UploadBulk
              dashboardFields={dashboardFields}
              saveBulkUpload={saveBulkUpload}
              errorMessage={errorMessage}
              successMessage={successMessage}
              validate={validate}
              validationResult={validationResult}
            />
          </div>
        )}
      </div>
      <ConsignmentsList
        writable={writable}
        consignments={displayConsignments}
        masterData={masterData}
        filters={filters}
        onChangeFilter={onChangeFilter}
        getAudit={getAudit}
        setItems={setItems}
        saveUserTablePreferences={saveUserTablePreferences}
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        customers={customers}
        warehouses={warehouses}
        editConsignment={editConsignment}
        deleteConsignment={deleteConsignment}
        undoConsignmentReceive={undoConsignmentReceive}
        editConsignmentNotes={editConsignmentNotes}
        editWarehouseNotes={editWarehouseNotes}
        subdomain={subdomain}
        downloadConsignmentReport={downloadConsignmentReport}
        receiveConsignment={receiveConsignment}
        user={user}
        changeStatusConsignmentHandle={changeStatusConsignmentHandle}
        receiveConsignmentPartially={receiveConsignmentPartially}
        downloadConsignmentItemList={downloadConsignmentItemList}
        tenant={tenant}
        getDamagedInfo={getDamagedInfo}
        showConsignmentDamageInfo={showConsignmentDamageInfo}
        closeConsignmentDamageInfo={closeConsignmentDamageInfo}
        getConsignmentDetails={getConsignmentDetails}
      />
      {showFilters && (
        <ConsignmentFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          warehouses={warehouses}
          clearFilters={clearFilters}
        />
      )}
      {selectedConsignment && (
        <ConsignmentForm
          selectedConsignment={selectedConsignment}
          setSelectedConsignment={setSelectedConsignment}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={`${selectedConsignment.id ? "Edit" : "Add"} Consignment`}
          saveConsignment={saveConsignment}
          customers={customers}
          warehouses={warehouses}
          removeItem={removeItem}
          submitAddedProduct={submitAddedProduct}
          saveConsignmentDraft={saveConsignmentDraft}
          setDropshipData={setDropshipData}
          dropshipData={dropshipData}
          uploadFile={uploadFile}
          tenant={tenant}
          casePackEnabled={casePackEnabled}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          refreshConsignmentItems={refreshConsignmentItems}
        />
      )}
      {receivingConsignment && !isPartiallyReceived && (
        <ReceivingConsignmentForm
          receivingConsignment={receivingConsignment}
          setReceivingConsignment={setReceivingConsignment}
          onSubmit={submitReceivingConsignment}
          title="Receive Consignment"
          onClose={() => setReceivingConsignment(null)}
          onChangeDropdown={onChangeReceivingConsignment}
          debouncedBinLocationSearch={debouncedBinLocationSearch}
          binLocationsOptions={binLocationsOptions}
        />
      )}

      {receivingConsignment && isPartiallyReceived && (
        <Modal
          title={"Receive Consignment Current State"}
          negativeAction={() => {
            setReceivingConsignment(null);
            setIsPartiallyReceived(false);
          }}
          id="receive-consignment-partially"
          onClose={() => {
            setReceivingConsignment(null);
            setIsPartiallyReceived(false);
          }}
          positiveText="Confirm"
          positiveAction={submitReceiveConsignmentPartially}>
          <div>
            <span className="font-small font-montserrat text-454A4F text-lg pb-4">
              Are you sure you want to receive this consignment in its current
              state?
            </span>
          </div>
        </Modal>
      )}

      {changeStatusConsignment && (
        <ChangeStatusReasonForm
          changeStatusConsignment={changeStatusConsignment}
          onSubmit={closeConsignment}
          onChange={onChangeStatusConsignment}
          title="Reason"
          onClose={() => {
            setChangeStatusConsignment(null);
          }}
        />
      )}

      {selectedConsignmentNotes && (
        <ConsignmentNotesForm
          selectedConsignmentNotes={selectedConsignmentNotes}
          onChange={onChangeNotes}
          title="Edit Notes"
          onClose={() => setSelectedConsignmentNotes(null)}
          onSubmit={saveConsignmentNotes}
        />
      )}
      {showAudit && (
        <AuditLog
          selectedAudit={selectedAudit}
          selectedAuditUsersInfo={selectedAuditUsersInfo}
          negativeAction={() => {
            setShowAudit(false);
            setSelectedAudit(null);
            setSelectedAuditUsersInfo(null);
          }}
        />
      )}
      {selectedConsignmentItemNotes && (
        <Modal
          title={`Add Notes`}
          negativeAction={() => setSelectedConsignmentItemNotes(null)}
          positiveAction={saveConsignmentItemNotes}>
          <div className="space-y-4">
            <div>
              <label className="block text-left mb-2">
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Notes
                </span>
              </label>
              <textarea
                id="notes"
                rows="4"
                cols="50"
                onChange={(e) =>
                  setSelectedConsignmentItemNotes({
                    ...selectedConsignmentItemNotes,
                    notes: e.target.value,
                  })
                }
                name="notes"
                value={selectedConsignmentItemNotes.notes}
              />
            </div>
          </div>
        </Modal>
      )}
      {selectedWarehouseNotes && (
        <Modal
          title={`Warehouse Notes`}
          negativeAction={() => setSelectedWarehouseNotes(null)}>
          <div className="text-xl font-montserrat p-4 font-bold pl-2">
            Notes
          </div>
          <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
            <thead className="p-4 rounded-full bg-primaryAccent px-12">
              <tr className="text-textWhite font-montserrat border-left px-12">
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                  SKU
                </th>
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                  Notes
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedWarehouseNotes.workingList
                .filter((item) => !!item.warehouseNotes)
                .map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}>
                    <td
                      className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {item.sku}
                    </td>

                    <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                      {item.warehouseNotes.split(", ").map((i) => (
                        <>
                          <div key={i}>{i}</div>
                        </>
                      ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal>
      )}

      {items && items.length > 0 && showConsignmentDetails && (
        <OrderDetailsModal
          items={items}
          setItems={setItems}
          setSelectedConsignmentItemNotes={setSelectedConsignmentItemNotes}
          showConsignmentDetails={showConsignmentDetails}
          closeConsignmentDetails={closeConsignmentDetails}
        />
      )}
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default withConsignmentsLogic(Consignments);
