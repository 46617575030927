import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import moment from "moment-timezone";

const AveragePickupDelay = ({ data, currentTimezone }) => {
  if (data) {
    data.sort((a, b) => {
      return new Date(a._id) - new Date(b._id);
    });
    data.map((obj) => {
      return (obj._id = moment(obj._id).format("MMM D, YYYY")); // June 1, 2019
    });
  }
  const renderColorfulLegendText = (value, entry) => {
    return <span className="text-gray-900 text-xl">{value}</span>;
  };
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="_id" />
        <YAxis label={<CustomizedLabel value="# Delayed Pickups" />}></YAxis>
        <Legend formatter={renderColorfulLegendText} />
        <Tooltip />
        <Bar
          dataKey="total"
          label="Pick up delay"
          fill="#ffc658"
          stackId="a"
          name={`Date (${currentTimezone})`}
          stroke="#000000"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomizedLabel = ({ value }) => (
  <text
    x={-30}
    y={10}
    dy={20}
    fill={"#000000"}
    fontSize={20}
    textAnchor="left"
    transform={`rotate(-90 100 100)`}
    letterSpacing="1.5"
  >
    {value}
  </text>
);

export default AveragePickupDelay;
