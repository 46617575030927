const CompletedBatch = ({ confirmDropoff, currentBatch }) => {
  return (
    <div className="w-full h-screen -mt-20 flex items-center justify-center">
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg w-full">
          <div className="flex items-center justify-center pt-5 pb-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full p-6 bg-primaryAccent shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="text-3xl font-medium text-454A4F py-5 px-5">
              <div>Batch Completed Successfully</div>
              {currentBatch && currentBatch.workflow !== "Pick then Sort"
                ? "Go to Pack-1"
                : "Go to Sort1"}
              <div
                className="mt-2 text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
                onClick={confirmDropoff}>
                Confirm Dropoff
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedBatch;
