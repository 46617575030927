import { useState, useEffect } from "react";
import _ from "lodash";
import { GET_TAGS } from "#queries";
import { SAVE_TAG, DELETE_TAG, SAVE_PRODUCT } from "#mutations";
import { useQuery } from "../../hooks/useQuery";
import ModalV3 from "#components/utils/ModalV3";
import {
  XIcon,
  ExclamationIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { ALERT_TYPES } from "../common/ContainerWithLoaderAndAlert";
import Checkbox from "#components/utils/Checkbox";
import PopoverOnTableAction from "#components/utils/PopoverOnTableAction";
import { pluralize } from "../../utils/helper-functions";

const SEARCH_DEBOUNCE_TIME_IN_MS = 500;

const CatalogManageTags = ({ product: incomingProduct }) => {
  const [product, setProduct] = useState(incomingProduct);
  const [componentAlert, setComponentAlert] = useState(null);
  const [addNewTag, setAddNewTag] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const tagsQuery = useQuery(GET_TAGS);
  const saveTagQuery = useQuery(SAVE_TAG);
  const deleteTagQuery = useQuery(DELETE_TAG);
  const saveProductQuery = useQuery(SAVE_PRODUCT);

  const submitTags = async () => {
    const response = await saveProductQuery.fetchData({
      id: product.id,
      tags: product.tags,
      customer: product.customer,
      sku: product.sku,
      name: product.name,
    });
    if (response.error) {
      setComponentAlert({
        msg: response.error.message,
        alertType: "error",
      });
    }

    if (response.data) {
      setComponentAlert({
        msg: response.data.saveProduct.message,
        alertType: "success",
      });
    }
  };

  const submitTagForm = async (e) => {
    e.preventDefault();

    const response = await saveTagQuery.fetchData(addNewTag);
    if (response.error) {
      setComponentAlert({
        msg: response.error.message,
        alertType: "error",
      });
    }

    if (response.data) {
      setComponentAlert({
        msg: response.data.saveTag.message,
        alertType: "success",
      });
      setAddNewTag({
        name: "",
        description: "",
      });
    }
  };

  const setKeywordValue = async (e) => {
    if (e.target.value.trim() === "") {
      setSearching(false);
    } else {
      setSearching(true);
      const response = await tagsQuery.fetchData({
        filters: { keyword: e.target.value },
      });
      if (response.error) {
        setComponentAlert({
          msg: response.error.message,
          alertType: "error",
        });
        setSearchResults([]);
      }

      if (response.data && response.data.tags) {
        setSearchResults(response.data.tags.entities);
      }
    }
  };
  const debouncedInventorySearch = _.debounce(
    setKeywordValue,
    SEARCH_DEBOUNCE_TIME_IN_MS,
  );

  const deleteTag = async (id) => {
    const tag = tagsToShow.find((tag) => tag.id === id);
    if (tag) {
      const response = await deleteTagQuery.fetchData({ id });
      if (response.error) {
        setComponentAlert({
          msg: response.error.message,
          alertType: "error",
        });
      }

      if (response.data) {
        setComponentAlert({
          msg: response.data.deleteTag.message,
          alertType: "success",
        });
        if (searching) {
          setSearchResults(searchResults.filter((tag) => tag.id !== id));
        } else if (product?.tags?.length > 0) {
          product.tags = product.tags.filter((tag) => tag.id !== id);
          setProduct({ ...product });
        }
      }
    }
  };

  const actionsOnTag = (tag) => {
    return [
      {
        name: "Remove Tag",
        value: "delete",
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => deleteTag(tag.id),
      },
    ];
  };

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }
    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] p-4 rounded-md flex items-center border-[${borderColor}] border-2 mb-2`}>
        <span className="mr-2 flex-1 flex items-center text-lg space-x-2">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="text-[#717679] font-bold float-right flex items-center justify-center"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };

  const toggleTagSelection = (tagId) => {
    product.tags = product.tags || [];
    const tag = tagsToShow.find((t) => t.id === tagId);
    if (tag) {
      if (product?.tags?.findIndex((t) => t.id === tagId) > -1) {
        product.tags = product.tags.filter((t) => t.id !== tagId);
      } else {
        product.tags.push(tag);
      }
    }
    setProduct({ ...product });
  };

  const tagsToShow = searching
    ? searchResults
    : product?.tags
    ? product.tags
    : [];

  return (
    <>
      {addNewTag && (
        <ModalV3
          isOpen={true}
          onClose={() => setAddNewTag(null)}
          title="Create Tag">
          <div className="p-4 min-w-3xl modalV3">
            <div className="flex items-center justify-between pb-4 font-inter">
              <span className="font-bold text-2xl text-[#454A4F] font-inter">
                Create Tag
              </span>
              <XIcon
                className="w-6 h-6 cursor-pointer"
                onClick={() => setAddNewTag(null)}
              />
            </div>
            {componentAlert && renderAlert(componentAlert)}
            <div className="flex flex-col space-y-4 max-w-content">
              <div>
                <span className="font-medium text-[#717679] text-lg pb-6">
                  Tag Name
                </span>
                <input
                  type="text"
                  className="border-2 border-[#DDDFE0] p-2 w-full rounded-md"
                  placeholder="Enter Tag Name"
                  value={addNewTag.name}
                  onChange={(e) =>
                    setAddNewTag({ ...addNewTag, name: e.target.value })
                  }
                />
              </div>
              <div>
                <span className="font-medium text-[#717679] text-lg pb-6">
                  Tag Description
                </span>
                <textarea
                  className="border-2 border-[#DDDFE0] p-2 w-full rounded-md"
                  placeholder="Enter Tag Description"
                  value={addNewTag.description}
                  onChange={(e) =>
                    setAddNewTag({
                      ...addNewTag,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-1 items-end justify-end space-x-2">
                <button
                  className="border-primaryAccent border-2 text-primaryAccent p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
                  onClick={() => setAddNewTag(null)}>
                  Cancel
                </button>
                <button
                  className="bg-primaryAccent border-primaryAccent border-2 text-white p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
                  onClick={submitTagForm}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalV3>
      )}
      <div className="flex-col">
        <div className="flex-1">
          <div className="text-xl px-6 pt-0 text-black font-normal">
            Available Tags
          </div>
          {!addNewTag && componentAlert && (
            <div className="mx-6">{renderAlert(componentAlert)}</div>
          )}
          <div className="flex px-6 py-2 space-x-2">
            <input
              type="text"
              className="border-2 border-[#DDDFE0] p-2 w-full rounded-md"
              placeholder="Search Tags..."
              onChange={(e) =>
                e.target.value.trim() !== ""
                  ? debouncedInventorySearch(e)
                  : setSearching(false)
              }
            />
            <button
              className="border-primaryAccent border-2 text-primaryAccent p-2 rounded-md whitespace-nowrap font-semibold px-10 cursor-pointer"
              onClick={() =>
                setAddNewTag({
                  name: "",
                  description: "",
                })
              }>
              Create Tag
            </button>
          </div>
          <div className="listOfTags">
            {tagsToShow &&
              tagsToShow.map((tag) => (
                <div className="flex p-4 px-6 items-center" key={tag.id}>
                  <Checkbox
                    role="checkbox"
                    onChange={() => toggleTagSelection(tag.id)}
                    name="selectAllRows"
                    checked={
                      product?.tags?.findIndex((t) => t.id === tag.id) > -1
                    }
                  />
                  <div className="flex-col px-6 py-2 flex-1">
                    <div className="text-lg text-black">{tag.name}</div>
                    <div className="text-lg text-[#717679]">
                      {tag.description}
                    </div>
                    <div className="text-lg text-[#717679]">
                      {pluralize(tag.products?.length || 0, "Product")}
                    </div>
                  </div>
                  <div className="relative">
                    <PopoverOnTableAction
                      icon={
                        <DotsVerticalIcon className="h-6 w-6 text-[#737579]" />
                      }
                      panelClassName={
                        "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
                      }
                      customClassName={"py-4 mr-2"}>
                      {actionsOnTag(tag).map((action) => {
                        return (
                          <div
                            className={`p-3 rounded-lg font-medium whitespace-nowrap cursor-pointer ${
                              action.disabled
                                ? "cursor-not-allowed text-gray-200"
                                : "text-unselectedTextGray hover:text-primaryAccent hover:bg-hoverHighlight"
                            }`}
                            onClick={
                              action.onClick && !action.disabled
                                ? action.onClick
                                : () =>
                                    action.disabledMessage
                                      ? alert(action.disabledMessage)
                                      : console.log(action.value, action.name)
                            }>
                            <div className="flex space-x-2">
                              <span>{action.icon ? action.icon : null}</span>
                              <span>{action.name}</span>
                            </div>
                          </div>
                        );
                      })}
                    </PopoverOnTableAction>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="items-end justify-end flex p-6 absolute bottom-0 right-0 space-x-2">
          <button
            className="border-primaryAccent border-2 text-primaryAccent p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
            onClick={() => setAddNewTag(null)}>
            Cancel
          </button>
          <button
            className="bg-primaryAccent border-primaryAccent border-2 text-white p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
            onClick={submitTags}>
            Assign Tags
          </button>
        </div>
      </div>
    </>
  );
};

export default CatalogManageTags;
