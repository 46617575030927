import { useState, useEffect, useContext } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import { getFilteredCustomerList } from "#utils/getFilteredCustomerList";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import Tooltip from "#components/utils/Tooltip";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { ArrowCircleUpIcon } from "@heroicons/react/outline";
import FileBase64 from "react-file-base64";
import { AppStateContext } from "#contexts/appState";

import AddProductStepV2 from "./AddProductStep";

const ConsignmentForm = ({
  selectedConsignment,
  setSelectedConsignment,
  onChange,
  onChangeDropdown,
  title,
  saveConsignment,
  customers,
  warehouses,
  removeItem,
  submitAddedProduct,
  saveConsignmentDraft,
  setDropshipData,
  dropshipData,
  uploadFile,
  tenant,
  casePackEnabled,
  multiAccountSupportEnabled,
  refreshConsignmentItems,
}) => {
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);

  const appState = useContext(AppStateContext);

  const dropshipEnabled = tenant?.features?.dropship ?? false;

  useEffect(() => {
    const formedSteps = getFormedSteps(selectedConsignment, dropshipEnabled);
    setSteps(formedSteps);
    setSelectedStep(formedSteps[0]);
  }, []);

  const setNextStep = () => {
    if (!selectedConsignment.warehouse || !selectedConsignment.customer) {
      return appState.setAlert(
        `Please select warehouse and customer`,
        "error",
        3000,
      );
    }
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const nextStep = copiedSteps.find((_, idx) => idx === foundIdx + 1);
    if (!nextStep.visible) {
      foundIdx += 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
      setShowSubmit(foundIdx === copiedSteps.length - 2);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    let foundIdx = copiedSteps.findIndex((item) => item.status === "current");
    const prevStep = copiedSteps.find((_, idx) => idx === foundIdx - 1);
    if (!prevStep.visible) {
      foundIdx -= 1; // skip the step which is disabled or not visible
    }

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setShowSubmit(false);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  const toggleStepVisiblity = (stepId) => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));
    copiedSteps = copiedSteps.map((item, idx) => {
      if (item.id === stepId) {
        return { ...item, visible: !item.visible };
      }
      return { ...item };
    });
    setSteps(copiedSteps);
  };

  const onSubmit = () => {
    if (selectedConsignment.customer && selectedConsignment.warehouse) {
      saveConsignment();
    }
  };

  const onSubmitDraft = () => {
    if (selectedConsignment.customer && selectedConsignment.warehouse) {
      saveConsignmentDraft();
    }
  };

  return (
    <SlideOverModal
      open={selectedConsignment ? true : false}
      onClose={() => setSelectedConsignment(null)}
      title={title}
      staticBackdrop={true}>
      <div className="relative flex flex-col w-full h-full overflow-auto">
        <header className="py-4 space-y-2">
          <Navbar steps={steps} />
        </header>
        <main className="flex-grow overflow-hidden h-full my-2">
          {selectedStep?.id === "01" && (
            <BasicInfo
              warehouses={warehouses}
              customers={customers}
              selectedConsignment={selectedConsignment}
              onChangeDropdown={onChangeDropdown}
            />
          )}
          {selectedStep?.id === "02" && (
            <OrderDetails
              selectedConsignment={selectedConsignment}
              onChange={onChange}
              dropshipEnabled={dropshipEnabled}
              toggleStepVisiblity={toggleStepVisiblity}
            />
          )}
          {selectedStep?.id === "03" && (
            <DropshipDetails
              selectedConsignment={selectedConsignment}
              onChange={onChange}
              casePackEnabled={casePackEnabled}
              dropshipData={dropshipData}
              setDropshipData={setDropshipData}
              uploadFile={uploadFile}
            />
          )}
          {selectedStep?.id === "04" && (
            <AddProductStepV2
              selectedConsignment={selectedConsignment}
              setSelectedConsignment={setSelectedConsignment}
              warehouses={warehouses}
              multiAccountSupportEnabled={multiAccountSupportEnabled}
              tenant={tenant}
              upsertSelectedConsignmentItem={submitAddedProduct}
              removeSelectedConsignmentItem={removeItem}
              refreshConsignmentItems={refreshConsignmentItems}
            />
          )}
          {selectedStep?.id === "05" && (
            <TrackingDetails
              selectedConsignment={selectedConsignment}
              onChange={onChange}
            />
          )}
        </main>
        <footer className="h-16 flex justify-between items-center">
          <FormActions
            selectedConsignment={selectedConsignment}
            setSelectedConsignment={setSelectedConsignment}
            steps={steps}
            selectedStep={selectedStep}
            setPrevStep={setPrevStep}
            setNextStep={setNextStep}
            onSubmitDraft={onSubmitDraft}
            showSubmit={showSubmit}
            onSubmit={onSubmit}
          />
        </footer>
      </div>
    </SlideOverModal>
  );
};

const Navbar = ({ steps }) => {
  const visibleSteps = steps.filter((item) => item.visible);
  return (
    <nav className="flex space-x-2 w-524">
      {visibleSteps.map((step, index) => {
        const activeStep = step.status === "current";
        return (
          <div className="flex flex-col items-center" key={index}>
            <span
              className={`mb-1 w-32 h-2.5 rounded-md ${
                activeStep ? "bg-blueBackground" : "bg-lightBlueBackground"
              }`}></span>
            <p
              className={`decoration-solid text-sm ${
                activeStep ? "text-textDarkGray" : "text-textGray"
              }`}>
              {step.name}
            </p>
          </div>
        );
      })}
    </nav>
  );
};

const FormActions = ({
  selectedConsignment,
  setSelectedConsignment,
  steps,
  selectedStep,
  setPrevStep,
  setNextStep,
  onSubmitDraft,
  showSubmit,
  onSubmit,
}) => {
  const showSaveAsDraft =
    (!selectedConsignment.id || selectedConsignment.status === "DRAFT") &&
    selectedConsignment.customer &&
    selectedConsignment.warehouse;

  return (
    <>
      <div>
        {showSaveAsDraft && (
          <button
            onClick={onSubmitDraft}
            className={`cursor-pointer text-primaryAccent underline text-base font-semibold py-2`}>
            Save as Draft
          </button>
        )}
      </div>
      <div className="flex space-x-4">
        <button
          className={`text-base font-semibold underline cursor-pointer py-3 mr-2`}
          onClick={() => setSelectedConsignment(null)}>
          Cancel
        </button>
        <button
          onClick={setPrevStep}
          className={`rounded-md cursor-pointer px-6 py-3 border text-base font-semibold ${
            selectedStep?.id === steps[0]?.id
              ? "border-mediumGray text-mediumGray cursor-not-allowed"
              : "border-primaryAccent text-primaryAccent cursor-pointer"
          }`}>
          Previous
        </button>
        {!showSubmit && (
          <button
            onClick={setNextStep}
            className={`rounded-md px-6 py-3 text-base font-semibold ${
              selectedStep?.id === steps[steps.length - 1]?.id ||
              !(selectedConsignment.warehouse && selectedConsignment.customer)
                ? "border border-mediumGray text-mediumGray cursor-not-allowed"
                : "bg-primaryAccent text-white cursor-pointer"
            }`}>
            Next
          </button>
        )}
        {showSubmit && (
          <button
            onClick={onSubmit}
            className={`rounded-md cursor-pointer px-6 py-3 text-base font-semibold ${
              selectedConsignment.customer && selectedConsignment.warehouse
                ? "bg-primaryAccent text-white cursor-pointer"
                : "border border-mediumGray text-mediumGray cursor-not-allowed"
            }`}>
            {selectedConsignment.id ? "Update" : "Create"}
          </button>
        )}
      </div>
    </>
  );
};

const BasicInfo = ({
  warehouses,
  customers,
  selectedConsignment,
  onChangeDropdown,
}) => {
  return (
    <div className="w-1/2">
      <h1 className="text-2xl font-semibold mb-4">Basic Info</h1>
      {selectedConsignment.warehouseToBeSelected && (
        <div className="mb-4 w-538">
          <label
            className="block text-sm font-medium text-lightGray mb-1"
            htmlFor="warehouse">
            Warehouse
          </label>
          <AutocompleteDropdownV2
            options={warehouses}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              onChangeDropdown("warehouse", selectedOption);
            }}
            value={selectedConsignment.warehouse}
            placeholder=""
          />
        </div>
      )}
      {selectedConsignment.customerToBeSelected && (
        <div className="mb-4 w-538">
          <label
            className="block text-sm font-medium text-lightGray mb-1"
            htmlFor="client">
            Client
          </label>
          <AutocompleteDropdownV2
            options={getFilteredCustomerList(
              selectedConsignment.warehouse,
              customers,
            )}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              onChangeDropdown("customer", selectedOption);
            }}
            value={selectedConsignment.customer}
            placeholder=""
          />
        </div>
      )}
    </div>
  );
};

const OrderDetails = ({
  selectedConsignment,
  onChange,
  dropshipEnabled,
  toggleStepVisiblity,
}) => {
  return (
    <div className="w-1/2">
      <h1 className="text-2xl font-semibold mb-4">Order Details</h1>
      <div className="mb-4">
        <label
          htmlFor="orderID"
          className="block text-sm font-medium text-lightGray mb-1">
          Order ID
        </label>
        <input
          type="text"
          id="order_id"
          name="orderId"
          placeholder=""
          onChange={onChange}
          value={selectedConsignment.orderId}
          className="border border-borderGray rounded w-538 h-48 p-2"
        />
      </div>

      <div className="mb-4 flex-1">
        <label
          htmlFor="consignmentDate"
          className="block text-sm font-medium text-lightGray mb-1">
          Consignment Date
        </label>
        <input
          type="date"
          placeholder=""
          onChange={onChange}
          value={selectedConsignment.consignmentDate}
          name={"consignmentDate"}
          className="border border-borderGray rounded w-538 h-48 p-2"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="consignmentNumber"
          className="block text-sm font-medium text-lightGray mb-1">
          Consignment Number
        </label>
        <input
          type="text"
          id="consignment_number"
          placeholder=" "
          onChange={onChange}
          value={selectedConsignment.consignmentNumber}
          name="consignmentNumber"
          className="border border-borderGray rounded w-538 h-48 p-2"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="supplier"
          className="block text-sm font-medium text-lightGray mb-1">
          Supplier / Vendor
        </label>
        <input
          type="text"
          id="vendor"
          placeholder=" "
          onChange={onChange}
          value={selectedConsignment.supplier}
          name="supplier"
          className="border border-borderGray rounded w-538 h-48 p-2"
        />
      </div>
    </div>
  );
};

const DropshipDetails = ({
  selectedConsignment,
  onChange,
  casePackEnabled,
  dropshipData,
  setDropshipData,
  uploadFile,
  noSystemGeneratedLabel = false,
}) => {
  const packingTypes = [{ name: "Regular Pack", value: false }];
  if (casePackEnabled) {
    packingTypes.push({ name: "Case Pack", value: true });
  }

  const labelSources = [{ name: "Public URL" }, { name: "Upload File" }];
  if (!noSystemGeneratedLabel) {
    labelSources.unshift({ name: "System Generated" });
  }

  const showShippingAddressForm =
    selectedConsignment.dropshipType === "DEFAULT" &&
    dropshipData.labelSource === "System Generated";
  const showURLUpload =
    selectedConsignment.dropshipType === "DEFAULT" &&
    dropshipData.labelSource === "Public URL";
  const showFileUpload =
    selectedConsignment.dropshipType === "DEFAULT" &&
    dropshipData.labelSource === "Upload File";

  return (
    <div className="w-1/2">
      <h1 className="text-2xl font-semibold mb-4">Dropship Details</h1>
      <div className="flex justify-between w-626">
        <div className="w-297">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Dropship Type
          </label>
          <AutocompleteDropdownV2
            options={[
              { name: "FBA", value: "FBA" },
              { name: "Regular", value: "DEFAULT" },
            ]}
            labelKey="name"
            valueKey="value"
            onChange={(selectedOption) => {
              onChange({
                target: {
                  name: "dropshipType",
                  value: selectedOption,
                },
              });
            }}
            value={selectedConsignment.dropshipType}
            searchable={false}
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />
        </div>
        {selectedConsignment.dropshipType === "FBA" && (
          <div className="w-297">
            <label className="block text-sm font-medium text-lightGray mb-1">
              Pack Type
            </label>
            <AutocompleteDropdownV2
              options={packingTypes}
              labelKey="name"
              valueKey="value"
              onChange={(selectedOption) => {
                onChange({
                  target: {
                    name: "isCasePack",
                    value: selectedOption,
                  },
                });
              }}
              value={selectedConsignment.isCasePack}
              placeholder=""
              searchable={false}
              disabled={
                selectedConsignment.id &&
                selectedConsignment.status !== "UNPROCESSED" &&
                selectedConsignment.status !== "DRAFT"
              }
            />
          </div>
        )}
        {selectedConsignment.dropshipType === "DEFAULT" && (
          <div className="w-297">
            <label className="block text-sm font-medium text-lightGray mb-1">
              Shipping Label
            </label>
            <AutocompleteDropdownV2
              options={labelSources}
              labelKey="name"
              valueKey="name"
              onChange={(selectedOption) => {
                setDropshipData({
                  ...dropshipData,
                  labelSource: selectedOption,
                });
              }}
              value={dropshipData.labelSource}
              placeholder=""
              searchable={false}
            />
          </div>
        )}
      </div>
      {selectedConsignment.isCasePack && (
        <div className="mt-4 w-626 flex items-center border bg-lightYellow border-yellow rounded h-48 p-2">
          <InformationCircleIcon className="w-6 h-6 text-yellow" />
          <p className="font-normal text-sm text-lightGray ml-2">
            Case pack information will be asked post adding products to your
            consignment.
          </p>
        </div>
      )}
      {showShippingAddressForm && (
        <ShippingAddressForm
          selectedConsignment={selectedConsignment}
          onChange={onChange}
        />
      )}
      {showURLUpload && (
        <div className="w-626">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Link
          </label>
          <input
            type="text"
            id="labelUrl"
            placeholder="https://"
            onChange={(e) => {
              setDropshipData({
                ...dropshipData,
                labelUrl: e.target.value,
              });
            }}
            value={dropshipData.labelUrl}
            name="labelUrl"
            className="border border-borderGray rounded w-full h-48 p-2"
          />
          <div className="mt-4 w-626 flex items-center border bg-lightYellow border-yellow rounded h-48 p-2">
            <InformationCircleIcon className="w-6 h-6 text-yellow" />
            <p className="font-normal text-sm text-lightGray ml-2">
              Ensure the file is publically accessible via the internet.
            </p>
          </div>
        </div>
      )}
      {showFileUpload && (
        <div className="mt-4 w-626 h-163">
          <FileUpload uploadFile={uploadFile} dropshipData={dropshipData} />
        </div>
      )}
    </div>
  );
};

const ShippingAddressForm = ({ selectedConsignment, onChange }) => {
  return (
    <div className="w-658">
      <h2 className="text-sm font-semibold my-4">Shipping Address</h2>
      <div className="grid grid-cols-3 gap-4">
        {/* Name */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Name
          </label>
          <input
            type="text"
            id="name"
            label="Name"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    name: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.name}
            name="shippingAddress.name"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />
        </div>
        {/* Email */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Email
          </label>
          <input
            type="text"
            id="name"
            label="Email"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    email: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.email}
            name="shippingAddress.email"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />
        </div>
        {/* Phone Number */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Phone Number
          </label>
          <input
            type="text"
            id="name"
            label="Phone"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    phone: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.phone}
            name="shippingAddress.phone"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />
        </div>
        {/* Address Line 1 */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Address Line 1
          </label>
          <input
            type="text"
            id="name"
            label="Address Line 1"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    line1: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.line1}
            name="shippingAddress.line1"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />
        </div>
        {/* Address Line 2 */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Address Line 2
          </label>
          <input
            type="text"
            id="name"
            label="Address Line 2"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    line2: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.line2}
            name="shippingAddress.line2"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />{" "}
        </div>
        {/* Pincode */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Pincode
          </label>
          <input
            type="text"
            id="name"
            label="Pincode"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    zip: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.zip}
            name="shippingAddress.zip"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />{" "}
        </div>
        {/* City */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            City
          </label>
          <input
            type="text"
            id="name"
            label="City"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    city: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.city}
            name="shippingAddress.city"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />{" "}
        </div>
        {/* State */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            State
          </label>
          <input
            type="text"
            id="name"
            label="State"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    state: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.state}
            name="shippingAddress.state"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />{" "}
        </div>
        {/* Country */}
        <div className="w-204">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Country
          </label>
          <input
            type="text"
            id="name"
            label="Country"
            placeholder=" "
            onChange={(e) =>
              onChange({
                target: {
                  name: "shippingAddress",
                  value: {
                    ...(selectedConsignment?.shippingAddress || {}),
                    country: e.target.value,
                  },
                },
              })
            }
            value={selectedConsignment?.shippingAddress?.country}
            name="shippingAddress.country"
            className="border border-borderGray rounded w-full h-48 p-2"
            disabled={
              selectedConsignment.id &&
              selectedConsignment.status !== "UNPROCESSED" &&
              selectedConsignment.status !== "DRAFT"
            }
          />{" "}
        </div>
      </div>
    </div>
  );
};

const FileUpload = ({ uploadFile }) => {
  return (
    <div className="flex flex-col items-center justify-center border-dashed border-2 rounded-lg w-full h-full cursor-pointer">
      <div className="flex items-center mb-2">
        <ArrowCircleUpIcon className="w-8 h-8" />
        <span className="text-xl font-medium ml-2">Upload File</span>
      </div>
      <span className="text-gray-500">Accepted formats (PNG, JPG, PDF)</span>
      <div>
        <FileBase64
          multiple={false}
          onDone={uploadFile}
          accept=".png,.jpg,.jpeg,.pdf"
        />
      </div>
    </div>
  );
};

const TrackingDetails = ({ selectedConsignment, onChange }) => {
  return (
    <div className="w-1/2">
      <h1 className="text-2xl font-semibold mb-4">Tracking</h1>
      <div className="mb-4">
        <label
          htmlFor="orderID"
          className="block text-sm font-medium text-lightGray mb-1">
          Carrier
        </label>
        <input
          type="text"
          name="carrier"
          placeholder=""
          id="carrier"
          onChange={onChange}
          value={selectedConsignment.carrier}
          className="border border-borderGray rounded w-538 h-48 p-2"
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center">
          <label
            htmlFor="consignmentNumber"
            className="block text-sm font-medium text-lightGray mb-1">
            Tracking Number(s)
          </label>
          <Tooltip message="Enter comma separated values" />
        </div>
        <input
          type="text"
          name="trackingNumber"
          id="tracking_number"
          placeholder=" "
          onChange={onChange}
          value={
            selectedConsignment.trackingNumber &&
            selectedConsignment.trackingNumber.length > 0
              ? selectedConsignment.trackingNumber.join(",")
              : selectedConsignment.trackingNumber
          }
          className="border border-borderGray rounded w-538 h-48 p-2"
        />
      </div>
    </div>
  );
};

const getFormedSteps = (selectedConsignment, dropshipEnabled) => {
  let formedSteps = [];
  if (
    selectedConsignment?.warehouseToBeSelected ||
    selectedConsignment?.customerToBeSelected
  ) {
    formedSteps.push({
      id: "01",
      name: "Basic Info",
      href: "#",
      status: "current",
      visible: true,
    });
  }

  formedSteps.push({
    id: "02",
    name: "Order Details",
    href: "#",
    status: "upcoming",
    visible: true,
  });

  if (dropshipEnabled) {
    formedSteps.push({
      id: "03",
      name: "Dropship Details",
      href: "#",
      status: "upcoming",
      visible: selectedConsignment.dropship,
    });
  }

  formedSteps.push(
    {
      id: "04",
      name: "Add Products",
      href: "#",
      status: "upcoming",
      visible: true,
    },
    {
      id: "05",
      name: "Tracking",
      href: "#",
      status: "upcoming",
      visible: true,
    },
  );

  formedSteps[0] = {
    ...formedSteps[0],
    status: "current",
  };

  return formedSteps;
};

export default ConsignmentForm;
