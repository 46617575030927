import { useState, useEffect } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import AddButton from "#components/utils/AddButton";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Toggle from "#components/utils/Toggle";
import { useCodeSuggestion } from "../../hooks/useCodeSuggestion";
import { Tooltip } from "antd";
import "antd/lib/tooltip/style/index.css";

const VendorForm = ({
  onClose,
  title,
  onChange,
  selectedVendor,
  onSubmit,
  warehouses,
  availableInventory,
  onChangeInventorySearch,
  onChangeMultiSelect,
  addAddress,
  onChangeAddress,
  removeAddress,
  vendors,
  setSelectedVendor,
}) => {
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [codesList, setCodesList] = useState([]);
  const {
    code,
    error,
    usingGeneratedCode,
    setUsingGeneratedCode,
    handleNameChange,
  } = useCodeSuggestion();

  useEffect(() => {
    setCodesList(vendors.map((vendor) => vendor.code));
  }, [vendors]);

  useEffect(() => {
    if (usingGeneratedCode) {
      setSelectedVendor({ ...selectedVendor, code: error?.error ? "" : code });
    }
  }, [code]);

  useEffect(() => {
    if (selectedVendor.name == "") {
      setSelectedVendor({ ...selectedVendor, code: "" });
    }
    if (selectedVendor.id) {
      setUsingGeneratedCode(false);
    }
  }, [selectedVendor.name, selectedVendor.id]);

  const handleOnChangeForName = (e) => {
    !selectedVendor?.id && handleNameChange(e.target.value, codesList);
    onChange(e);
  };
  const handleOnChangeForCode = (e) => {
    setUsingGeneratedCode(false);
    onChange(e);
  };

  useEffect(() => {
    let formedSteps = [];
    formedSteps.push({
      id: "01",
      name: "Warehouse Info",
      href: "#",
      status: "current",
    });

    formedSteps = [
      ...formedSteps,
      { id: "02", name: "Basic Details", href: "#", status: "upcoming" },
      { id: "03", name: "Products", href: "#", status: "upcoming" },
    ];

    formedSteps[0] = {
      ...formedSteps[0],
      status: "current",
    };
    setSteps(formedSteps);
    setSelectedStep(formedSteps[0]);
  }, []);

  const selectStep = (idx) => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    copiedSteps = copiedSteps.map((item) => ({
      ...item,
      status: "upcoming",
    }));

    copiedSteps[idx].status = "current";

    setSteps(copiedSteps);
    setSelectedStep(copiedSteps[idx]);
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  return (
    <Modal
      title={title}
      negativeAction={onClose}
      id="userFormModal"
      onClose={() => {}}
      minWidth="960px">
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Vendor Details
          </span>
        </div>
        <nav aria-label="Progress">
          <ol
            role="list"
            className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex md:flex-1">
                {step.status === "complete" ? (
                  <div className="group flex w-full items-center">
                    <span className="flex items-center px-6 py-4 text-md font-medium">
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                        <CheckIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      <span className="ml-4 text-lg font-medium text-gray-900">
                        {step.name}
                      </span>
                    </span>
                  </div>
                ) : step.status === "current" ? (
                  <div
                    className="flex items-center px-4 py-4 text-md font-medium"
                    aria-current="step">
                    <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                      <span className="text-indigo-600">
                        {(
                          steps.findIndex((i) => i.id === step.id) + 1
                        ).toString()}
                      </span>
                    </span>
                    <span className="ml-4 text-md font-medium text-indigo-600">
                      {step.name}
                    </span>
                  </div>
                ) : (
                  <div
                    className="group flex items-center"
                    onClick={() => selectStep(stepIdx)}>
                    <div className="flex items-center px-4 py-4 text-md font-medium">
                      <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">
                          {(
                            steps.findIndex((i) => i.id === step.id) + 1
                          ).toString()}
                        </span>
                      </span>
                      <span className="ml-4 text-md font-medium text-gray-500 group-hover:text-gray-900">
                        {step.name}
                      </span>
                    </div>
                  </div>
                )}

                {stepIdx !== steps.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div
                      className="absolute top-0 right-0 hidden h-full w-5 md:block"
                      aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none">
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
        {selectedStep && selectedStep.id === "01" && (
          <>
            <>
              <div>
                <MultiSelectAutoComplete
                  options={warehouses}
                  labelKey="name"
                  valueKey="id"
                  setValues={(e) => onChangeMultiSelect("warehouses", e)}
                  values={selectedVendor.warehouses}
                />
              </div>
            </>
          </>
        )}

        {selectedStep && selectedStep.id === "02" && (
          <>
            <div>
              <TextField
                type="text"
                id="name"
                label="Name"
                placeholder=" "
                onChange={handleOnChangeForName}
                value={selectedVendor.name}
                name="name"
              />
            </div>
            <div>
              <Tooltip title={"0-9 digits, A-Z letters are allowed."}>
                <TextField
                  type="text"
                  id="code"
                  label="Vendor Code"
                  placeholder=" "
                  name="code"
                  onChange={handleOnChangeForCode}
                  value={
                    usingGeneratedCode
                      ? error?.error
                        ? ""
                        : code
                      : selectedVendor.code?.toUpperCase()
                  }
                />
              </Tooltip>
            </div>
            {usingGeneratedCode && !(error?.error || code == "") && (
              <div className="italic text-sm">
                *This is a generated code. You can still edit it.
              </div>
            )}

            <div>
              <TextField
                type="text"
                id="email"
                label="Email"
                placeholder=" "
                onChange={onChange}
                value={selectedVendor.email}
                name="email"
              />
            </div>

            <div>
              <TextField
                type="text"
                id="phone"
                label="Phone No."
                placeholder=" "
                onChange={onChange}
                value={selectedVendor.phone}
                name="phone"
              />
            </div>

            {selectedVendor &&
              selectedVendor.addresses &&
              selectedVendor?.addresses?.map((item, index) => (
                <>
                  <div id="addAddresses">
                    <label className="flex text-left mb-2 flex-row">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Address - {index + 1}
                      </span>

                      <span
                        className="text-red-500 text-lg pl-2 font-bold cursor-pointer text-right"
                        onClick={() => removeAddress(index)}>
                        Remove
                      </span>
                      <br />
                    </label>
                  </div>
                  <div>
                    <TextField
                      type="text"
                      id="address"
                      label="Address"
                      placeholder=" "
                      onChange={(e) => onChangeAddress(e, index)}
                      value={item.address}
                      name="address"
                    />
                  </div>

                  <div>
                    <TextField
                      type="text"
                      id="suiteNumber"
                      label="Suite Number"
                      placeholder=" "
                      onChange={(e) => onChangeAddress(e, index)}
                      value={item.suiteNumber}
                      name="suiteNumber"
                    />
                  </div>

                  <div>
                    <TextField
                      type="text"
                      id="city"
                      label="City"
                      placeholder=" "
                      onChange={(e) => onChangeAddress(e, index)}
                      value={item.city}
                      name="city"
                    />
                  </div>

                  <div>
                    <TextField
                      type="text"
                      id="state"
                      label="State"
                      placeholder=" "
                      onChange={(e) => onChangeAddress(e, index)}
                      value={item.state}
                      name="state"
                    />
                  </div>

                  <div>
                    <TextField
                      type="text"
                      id="pincode"
                      label="Pincode"
                      placeholder=" "
                      onChange={(e) => onChangeAddress(e, index)}
                      value={item.pincode}
                      name="pincode"
                    />
                  </div>

                  <div>
                    <TextField
                      type="text"
                      id="country"
                      label="Country"
                      placeholder=" "
                      onChange={(e) => onChangeAddress(e, index)}
                      value={item.country}
                      name="country"
                    />
                  </div>
                </>
              ))}

            <AddButton
              text="Add Address"
              onClick={() =>
                addAddress([
                  {
                    address: "",
                    suiteNumber: "",
                    city: "",
                    state: "",
                    pincode: "",
                  },
                ])
              }
            />

            <div>
              <div>Active</div>
              <Toggle
                setEnabled={() =>
                  onChange({
                    target: {
                      name: "active",
                      value: !selectedVendor.active,
                    },
                  })
                }
                enabled={selectedVendor.active}
              />
            </div>
          </>
        )}
        {selectedStep && selectedStep.id === "03" && (
          <>
            <div className="mt-5">
              <label className="block text-left mb-2">
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Select Products
                </span>
              </label>
              <MultiSelectAutoComplete
                options={_.uniqBy(availableInventory, "sku")}
                labelKey="sku"
                valueKey="sku"
                onChange={onChangeInventorySearch}
                useLocalLabels={true}
                setValues={(e) => onChangeMultiSelect("supplyDetails", e)}
                values={selectedVendor.supplyDetails}
              />
            </div>
          </>
        )}
      </div>
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination">
        <div className="flex flex-1 justify-between sm:justify-end">
          <div className="flex flex-1 justify-between sm:justify-end space-x-2">
            <div
              onClick={onClose}
              className="rounded-md cursor-pointer bg-red-600 inline-flex items-center text-lg justify-center px-2 py-2 text-md text-white font-montserrat font-medium border textWhite-300 ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-red-700">
              Cancel
            </div>
            <div
              onClick={onSubmit}
              className="rounded-md cursor-pointer bg-primaryAccent inline-flex items-center text-lg justify-center px-2 py-2 text-md text-white font-montserrat font-medium border textWhite-300 ring-0 focus:ring-0 outline-none focus:outline-none">
              Submit
            </div>
            <div
              onClick={setPrevStep}
              className={`rounded-md cursor-pointer  inline-flex items-center text-lg justify-center px-2 py-2 text-md text-white font-montserrat font-medium border textWhite-300 ring-0 focus:ring-0 outline-none focus:outline-none ${
                selectedStep?.id === steps[0]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"< Previous"}
            </div>
            <div
              onClick={setNextStep}
              className={`rounded-md cursor-pointer  inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat border textWhite-300 ring-0 focus:ring-0 outline-none focus:outline-none ${
                selectedStep?.id === steps[steps.length - 1]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"Next >"}
            </div>
          </div>
        </div>
      </nav>
    </Modal>
  );
};
export default VendorForm;
