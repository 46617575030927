import Modal from "#components/utils/Modal";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import TextField from "#components/utils/TextField";
import _, { filter } from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { useEffect, useState } from "react";

const DetailedProductivityReport = ({
  setSelectedPrepper,
  selectedPrepper,
  onChangeFilter,
  submitFilters,
  filters,
  fetchBatchesForStats,
  fetchBatchesForProductivityStats,
}) => {
  const [range, setRange] = useState("Today");
  useEffect(() => {
    let today = new Date();
    let d1 = new Date(today);
    let d2 = new Date(d1);
    switch (range) {
      case "Today":
        onChangeFilter("startDate", d1.toISOString().split("T")[0]);
        break;
      case "Last Week":
        d1 = new Date(d1.setDate(d1.getDate() - d1.getDay() - 7));
        d2 = new Date(d2.setDate(d2.getDate() - d2.getDay()));
        onChangeFilter("startDate", d1.toISOString().split("T")[0]);
        break;
      case "Last Month":
        d1 = new Date(d1.setDate(-30));
        d2 = new Date(d2.setDate(0));
        onChangeFilter("startDate", d1.toISOString().split("T")[0]);
        break;
      case "Current Week":
        d1 = new Date(d1.setDate(d1.getDate() - d1.getDay()));
        onChangeFilter("startDate", d1.toISOString().split("T")[0]);
        break;
      case "Current Month":
        d1 = new Date(d1.setDate(0));
        onChangeFilter("startDate", d1.toISOString().split("T")[0]);
        break;
    }
  }, [range]);
  useEffect(() => {
    if (filters["startDate"]) {
      let today = new Date();
      let d1 = new Date(today);
      let d2 = new Date(d1);
      switch (range) {
        case "Today":
          onChangeFilter("endDate", d2.toISOString().split("T")[0]);
          break;
        case "Last Week":
          d1 = new Date(d1.setDate(d1.getDate() - d1.getDay() - 7));
          d2 = new Date(d2.setDate(d2.getDate() - d2.getDay()));
          onChangeFilter("endDate", d2.toISOString().split("T")[0]);
          break;
        case "Last Month":
          d1 = new Date(d1.setDate(-30));
          d2 = new Date(d2.setDate(0));
          onChangeFilter("endDate", d2.toISOString().split("T")[0]);
          break;
        case "Current Week":
          d1 = new Date(d1.setDate(d1.getDate() - d1.getDay()));
          onChangeFilter("endDate", d2.toISOString().split("T")[0]);
          break;
        case "Current Month":
          d1 = new Date(d1.setDate(0));
          onChangeFilter("endDate", d2.toISOString().split("T")[0]);
          break;
      }
    }
  }, [filters["startDate"]]);
  useEffect(() => {
    if (filters["startDate"] && filters["endDate"]) {
      // fetchBatchesForStats();
      // submitFilters()
      fetchBatchesForProductivityStats();
    }
  }, [filters["startDate"], filters["endDate"]]);
  return (
    <>
      <Modal
        title={"Detailed Performance Report"}
        negativeAction={() => {
          setSelectedPrepper(null);
        }}
        initialFocus={null}>
        <div className="space-y-4 w-92 px-8 py-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <div>
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Date Range
                </span>
              </div>

              <div>
                <Dropdown
                  placeholder={"Select Date Range"}
                  list={[
                    { name: "Today" },
                    { name: "Last Week" },
                    { name: "Last Month" },
                    { name: "Current Week" },
                    { name: "Current Month" },
                    { name: "Custom Range" },
                  ]}
                  labelKey="name"
                  valueKey="name"
                  name="binLocationType"
                  selectedValue={range}
                  setSelected={(e) => {
                    setRange(e);
                    onChangeFilter("startDate", undefined);
                    onChangeFilter("endDate", undefined);
                  }}
                />
              </div>
            </div>
            <div></div>
            {range === "Custom Range" && (
              <div>
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                    Start Date
                  </span>
                </label>
                <TextField
                  type="date"
                  label="Select Date"
                  placeholder=" "
                  onChange={(e) => onChangeFilter("startDate", e.target.value)}
                  value={filters["startDate"]}
                />
              </div>
            )}
            {range === "Custom Range" && (
              <div>
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                    End Date
                  </span>
                </label>
                <TextField
                  type="date"
                  label="Select Date"
                  placeholder=" "
                  onChange={(e) => onChangeFilter("endDate", e.target.value)}
                  value={filters["endDate"]}
                />
              </div>
            )}
            <div>
              <div>
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  No of Preps
                </span>
              </div>

              <div className="mt-1 text-3xl font-semibold text-gray-900">
                {selectedPrepper.itemsProcessed}
              </div>
            </div>
            <div>
              <div>
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Active Prepping Time
                </span>
              </div>

              <div className="mt-1 text-3xl font-semibold text-gray-900">
                {selectedPrepper.activeMinutes} mins
              </div>
            </div>
            <div>
              <div>
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Time Per Prep
                </span>
              </div>

              <div className="mt-1 text-3xl font-semibold text-gray-900">
                {selectedPrepper.timePerItem} sec
              </div>
            </div>
            <div>
              <div>
                <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                  Prepping Speed
                </span>
              </div>

              <div className="mt-1 text-3xl font-semibold text-gray-900">
                {selectedPrepper.itemsPerHour} items/hour
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetailedProductivityReport;
