import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import BarcodeForm from "#components/receiver/BarcodeForm";
import NumberField from "#components/utils/NumberField";

const DetailedView = ({
  currentSku,
  confirmItem,
  setCurrentSku,
  scanBarcode,
}) => {
  const [barcode, setBarcode] = useState(null);

  return (
    <div className="flex-col items-center justify-center max-w-4xl mx-auto">
      {barcode && (
        <BarcodeForm
          onChange={setBarcode}
          onSubmit={() => {
            setBarcode(null);
            scanBarcode({ barcode, formFactor: currentSku.formFactor });
          }}
          onClose={() => setBarcode(null)}
          title="Enter Value"
        />
      )}
      <div className="pt-20">
        <div className="text-xl font-medium text-454A4F space-y-2 ">
          <PageTitle>Product Details</PageTitle>
          <LineItemValue
            value={currentSku.productName}
            title={"Name"}
            idx={1}
          />
          <LineItemValue value={currentSku.sku} title={"SKU"} idx={2} />
          {currentSku.upc && (
            <LineItemValue
              value={currentSku?.upc?.join(", ")}
              title="UPC"
              idx={3}
            />
          )}
          <LineItemValueWithOptions
            value={currentSku.binLocation}
            title="Bin"
            idx={1}
            setBarcode={setBarcode}
          />
        </div>

        <div className="flex mt-10 text-center items-center justify-center">
          <div>
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-2xl pb-4">
                Quantity
              </span>
            </label>
            <NumberField
              onChange={(e) =>
                setCurrentSku({
                  ...currentSku,
                  scannedQuantity:
                    isNaN(parseInt(e.target.value)) === false
                      ? parseInt(e.target.value)
                      : null,
                })
              }
              placeholder="Qty"
              value={currentSku.scannedQuantity}
              className="p-4 text-lg rounded-md w-36"
              min="0"
            />
          </div>
        </div>

        <div className="mt-20 flex items-center justify-center space-x-6">
          <div
            className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
            onClick={confirmItem}>
            Confirm Item
          </div>
        </div>
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md">
      {value}
    </div>
  </div>
);

const LineItemValueWithOptions = ({ title, value, idx, setBarcode }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md">
      {value}
    </div>
    <div
      className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md"
      onClick={() => setBarcode({ data: "" })}>
      Scan or{" "}
      <span className="text-blue-600 cursor-pointer">Enter Manually</span>
    </div>
  </div>
);

export default DetailedView;
