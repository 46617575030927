import { Fragment, useContext, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { SwitchHorizontalIcon, UserCircleIcon } from "@heroicons/react/outline";
import { AuthContext } from "#contexts/auth";
import { useHistory, Link } from "react-router-dom";
import { AppStateContext } from "#contexts/appState";
import { GET_STATION } from "#queries";
import { LOGOUT_USER, SET_ACTIVITY, SET_STATION } from "#mutations";
import { useQuery } from "#hooks/useQuery";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AssociateLayout = ({ children }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const logoutQuery = useQuery(LOGOUT_USER);
  const getStationQuery = useQuery(GET_STATION);
  const setStationQuery = useQuery(SET_STATION);
  const setActivityQuery = useQuery(SET_ACTIVITY);

  useEffect(() => {
    if (setStationQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [setStationQuery.loading, setStationQuery.data, setStationQuery.error]);

  useEffect(() => {
    if (logoutQuery.data) {
      appState.setAlert(logoutQuery.data.logout.message);
      auth.logout();
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

  useEffect(() => {
    if (auth?.user?.station) {
      getStationQuery.fetchData({ id: auth.user.station });
    }
  }, [auth?.user?.station]);

  return (
    <div className="h-full min-h-screen bg-E5E5E5">
      <div className="w-full flex justify-between left-0 bg-white shadow-md">
        {/*Logo*/}
        <div className="flex items-center justify-center space-x-4 py-2 px-2">
          {appState.tenant && appState.tenant.logo ? (
            <img
              src={appState.tenant.logo}
              alt="Custom Logo"
              className="h-20"
            />
          ) : (
            <span className="flex items-center justify-center space-x-4 py-2 px-2">
              <img
                src="https://hopstack-pub.s3.amazonaws.com/logo.png"
                alt="Custom Logo"
                className="w-12 h-12"
              />
              <span className="font-hammersmith text-primaryAccent text-2xl">
                Hopstack Inc
              </span>
            </span>
          )}
        </div>
        {/*. User Details */}
        <div className="ml-4 flex items-center md:ml-6 mr-5">
          {auth?.user?.hopstackModules?.length > 1 && (
            <Menu as="div" className="ml-3 mr-3 relative z-50">
              <div>
                <Menu.Button className="max-w-xs bg-white flex gap-1 items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <SwitchHorizontalIcon
                    className="h-8 w-8 rounded-full"
                    alt="Switch Role"
                  />
                  {auth?.user?.activity}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {auth.user?.hopstackModules?.includes("Receiving") && (
                    <Link to="/receiver">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Receiving",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Receiving
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}

                  {auth.user?.hopstackModules?.includes("Picking") && (
                    <Link to="/picker">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Picking",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Picking
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}
                  {auth.user?.hopstackModules?.includes("Packing") && (
                    <Link to="/packer">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Packing",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Packing
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}

                  {auth.user?.hopstackModules?.includes("Prepping") && (
                    <Link to="/prepper">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Prepping",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Prepping
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}
                  {auth.user?.hopstackModules?.includes("Bundling") && (
                    <Link to="/bundler">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Bundling",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Bundling
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}
                  {auth.user?.hopstackModules?.includes("Putaway") && (
                    <Link to="/putaway">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Putaway",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Putaway
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}
                  {auth.user?.hopstackModules?.includes(
                    "Inbound Sorting Overview",
                  ) && (
                    <Link to="/inboundSortingOverview">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Inbound Sorting Overview",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Inbound Sorting Overview
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}
                  {auth.user?.hopstackModules?.includes(
                    "Outbound Sorting Overview",
                  ) && (
                    <Link to="/outboundSortingOverview">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setStationQuery.fetchData({ station: null });
                              setActivityQuery.fetchData({
                                activity: "Outbound Sorting Overview",
                              });
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Outbound Sorting Overview
                          </div>
                        )}
                      </Menu.Item>
                    </Link>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          )}

          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center justify-start text-left text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Open user menu</span>
                <UserCircleIcon className="h-8 w-8 rounded-full" alt="" />
                <div className="text-md pl-2">
                  {auth?.user?.name} <br />
                  {getStationQuery?.data?.station?.name}
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        logoutQuery.fetchData();
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700 cursor-pointer",
                      )}>
                      Logout
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {children}
    </div>
  );
};

export default AssociateLayout;
