import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";

const TransactionFiltersForm = ({
  onSubmit,
  negativeAction,
  transactionFilters,
  setTransactionFilters,
}) => (
  <Modal
    title={"Export Transaction Report"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Choose Date Range
        </span>
      </div>

      <div className="flex space-x-6 mt-4">
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Start Date
            </span>
          </label>
          <TextField
            type="date"
            label="Select Date"
            placeholder=" "
            onChange={(e) =>
              setTransactionFilters({
                ...transactionFilters,
                startDate: e.target.value,
              })
            }
            value={transactionFilters.startDate}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              End Date
            </span>
          </label>
          <TextField
            type="date"
            label="Select Date"
            placeholder=" "
            onChange={(e) =>
              setTransactionFilters({
                ...transactionFilters,
                endDate: e.target.value,
              })
            }
            value={transactionFilters.endDate}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default TransactionFiltersForm;
