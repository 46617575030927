export const renderStatus = (status) => {
  switch (status) {
    case "PARTIAL":
    case "AWAITING_LABEL":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-lightBlueBackground text-center text-gray-600">
          {status}
        </div>
      );
    case "UNPROCESSED":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-gray-500 text-center text-white">
          {status}
        </div>
      );
    case "MISMATCH":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-red-500 text-center text-white">
          {status}
        </div>
      );
    case "IN-PROCESS":
    case "STARTED":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-blue-500 text-center text-white">
          {status}
        </div>
      );
    case "COMPLETED":
    case "CONFIRMED_DROPOFF":
    case "CONFIRMED":
    case "PICKED":
    case "PREPPED":
    case "PACKED":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-green-500 text-center text-white">
          {status}
        </div>
      );
    default:
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-gray-500 text-center text-white">
          {status}
        </div>
      );
  }
};
