import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import Autocomplete from "#components/utils/Autocomplete";
import { TrashIcon } from "@heroicons/react/outline";

const ActivatePlanForm = ({
  onClose,
  title,
  onChange,
  planToActivate,
  onSubmit,
  users,
  onAddUserToPlan,
  onRemoveUserFromPlan,
  onChangePersonnelMapping,
  itemsAvailableForAssignment,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Name
          </span>
        </label>
        <div>{planToActivate.name}</div>
      </div>
      <div>
        <RoundedDropdown
          placeholder={"Select to Assign Personnel"}
          list={users}
          labelKey="name"
          valueKey="id"
          name="personnel"
          setSelected={(e) => onAddUserToPlan(e)}
        />
      </div>
      <div>
        {planToActivate.personnel?.map((item, idx) => {
          const alreadyAssignedItems = planToActivate.personnel
            .reduce((prev, curr) => {
              if (curr.id === item.id) return prev;
              return [...prev, ...curr.assignedItems];
            }, [])
            .join(",");
          return (
            <div className="flex flex-row w-full">
              <div className="w-1/3 text-xl font-medium mt-3">{item.name}</div>
              <div className="w-2/3">
                <MultiSelectAutoComplete
                  options={[
                    ...itemsAvailableForAssignment.skus.map(
                      (item) => `Product: ${item}`,
                    ),
                    ...itemsAvailableForAssignment.binRanges.rows.map(
                      (item) => `Row: ${item}`,
                    ),
                    ...itemsAvailableForAssignment.binRanges.sections.map(
                      (item) => `Section: ${item}`,
                    ),
                    ...itemsAvailableForAssignment.binRanges.bins.map(
                      (item) => `Bin: ${item}`,
                    ),
                    ...itemsAvailableForAssignment.specificBins.map(
                      (item) => `${item}`,
                    ),
                  ]
                    .filter((item) => !alreadyAssignedItems.includes(item))
                    .map((item) => ({
                      name: item,
                    }))}
                  labelKey="name"
                  valueKey="name"
                  setValues={(e) => onChangePersonnelMapping(idx, e)}
                  values={planToActivate.personnel[idx].assignedItems}
                />
              </div>
              <div
                onClick={() => onRemoveUserFromPlan(item.id)}
                className="cursor-pointer">
                <TrashIcon className="w-8 h-8 mt-3 ml-3" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </Modal>
);

export default ActivatePlanForm;
