import { useState, useContext } from "react";
import CommonTable from "../common/CommonTable";
import moment from "moment-timezone";
import PopoverOnTableAction from "#components/utils/PopoverOnTableAction";
import {
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
  XIcon,
  ExclamationIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { UPSERT_CATEGORY, DELETE_CATEGORY } from "#mutations";
import { useQuery } from "../../hooks/useQuery";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";
import { AppStateContext } from "#contexts/appState";
import CategoryModalForm from "#components/catalogs/CategoryModalForm";

export const initialCategoryFormValues = {
  name: "",
  description: "",
};

const ManageCategories = ({ productCategories, editCategory }) => {
  const appState = useContext(AppStateContext);
  const [addNewCategory, setAddNewCategory] = useState(null);
  const [componentAlert, setComponentAlert] = useState(null);

  const upsertCategoryQuery = useQuery(UPSERT_CATEGORY);
  const deleteCategoryQuery = useQuery(DELETE_CATEGORY);

  const deleteCategory = (id) => {
    // ASK FOR CONFIRMATION
    appState.showConfirmation(
      "Delete Category",
      "Are you sure you want to delete this category?",
      () => confirmDeleteCategory(id),
      appState.hideConfirmation,
    );
  };

  const confirmDeleteCategory = async (id) => {
    const response = await deleteCategoryQuery.fetchData({ id });
    if (response.error) {
      setComponentAlert({
        msg: response.error.message,
        alertType: "error",
      });
      appState.hideConfirmation();
    }

    if (response.data) {
      setComponentAlert({
        msg: response.data.deleteCategory.message,
        alertType: "success",
      });
      appState.hideConfirmation();
    }
  };

  const actionsOnCategory = (category) => {
    return [
      {
        name: "Edit Category",
        value: "delete",
        icon: <PencilIcon className="h-6 w-6" />,
        onClick: () => setAddNewCategory(category),
      },
      {
        name: "Remove Category",
        value: "delete",
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => deleteCategory(category.id),
      },
    ];
  };

  const submitCategoryForm = async (e) => {
    e.preventDefault();

    const response = await upsertCategoryQuery.fetchData(addNewCategory);
    if (response.error) {
      setComponentAlert({
        msg: response.error.message,
        alertType: "error",
      });
    }

    if (response.data) {
      setComponentAlert({
        msg: response.data.upsertCategory.message,
        alertType: "success",
      });
      setAddNewCategory(addNewCategory.id ? null : initialCategoryFormValues);
    }
  };

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }
    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] p-4 rounded-md flex items-center border-[${borderColor}] border-2 mb-2`}>
        <span className="mr-2 flex-1 flex items-center text-lg space-x-2">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="text-[#717679] font-bold float-right flex items-center justify-center"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };

  return (
    <div className="flex-col">
      <div className="flex items-center">
        <div className="font-semibold text-gray-900 text-2xl font-inter m-4 mt-6 flex-1">
          Manage Categories
        </div>
        <div>
          <button
            className="bg-primaryAccent border-primaryAccent border-2 text-white p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
            onClick={() => setAddNewCategory(initialCategoryFormValues)}>
            Add Product Category
          </button>
        </div>
      </div>
      {componentAlert && renderAlert(componentAlert)}
      <CommonTable
        headers={[
          "Name",
          "Description",
          "Products Associated",
          "Last Updated",
          "Action",
        ]}>
        <tbody>
          {(!productCategories || !productCategories.length) && (
            <tr>
              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <div className="text-[#111827] text-lg">No categories.</div>
              </td>
            </tr>
          )}
          {productCategories &&
            productCategories?.map((category) => (
              <tr key={category.id}>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <div className="text-[#111827] text-lg">{category.name}</div>
                </td>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <div className="text-[#111827] text-lg">
                    {category.description}
                  </div>
                </td>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <div className="text-[#111827] text-lg">
                    {category.associatedProducts}
                  </div>
                </td>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <div className="text-[#111827] text-lg">
                    {moment(category.updatedAt).format("YYYY-MM-DD HH:mm")}
                  </div>
                </td>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <PopoverOnTableAction
                    icon={
                      <DotsVerticalIcon className="h-6 w-6 text-[#737579]" />
                    }
                    panelClassName={
                      "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
                    }
                    customClassName={"py-4 mr-2"}>
                    {actionsOnCategory(category).map((action) => {
                      return (
                        <div
                          className={`p-3 rounded-lg font-medium whitespace-nowrap cursor-pointer ${
                            action.disabled
                              ? "cursor-not-allowed text-gray-200"
                              : "text-unselectedTextGray hover:text-primaryAccent hover:bg-hoverHighlight"
                          }`}
                          onClick={
                            action.onClick && !action.disabled
                              ? action.onClick
                              : () =>
                                  action.disabledMessage
                                    ? alert(action.disabledMessage)
                                    : console.log(action.value, action.name)
                          }>
                          <div className="flex space-x-2">
                            <span>{action.icon ? action.icon : null}</span>
                            <span>{action.name}</span>
                          </div>
                        </div>
                      );
                    })}
                  </PopoverOnTableAction>
                </td>
              </tr>
            ))}
        </tbody>
      </CommonTable>
      {addNewCategory && (
        <CategoryModalForm
          addNewCategory={addNewCategory}
          setAddNewCategory={setAddNewCategory}
          submitCategoryForm={submitCategoryForm}
          componentAlert={componentAlert}
        />
      )}
    </div>
  );
};

export default ManageCategories;
