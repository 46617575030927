import { useState } from "react";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";

const ScanOrEnterManually = ({ text, onSubmit }) => {
  const [barcode, setBarcode] = useState(null);
  const [formFactor, setFormFactor] = useState(null);

  return (
    <div className="w-full flex items-center justify-center">
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg">
          <div className="flex items-center justify-center pt-5 pb-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full p-6 bg-primaryAccent shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="text-xl font-medium text-454A4F py-5 px-5">
              {text || "Scan a Tracking Number"}
            </div>
          </div>
          <div className="text-2xl font-montserrat items-center text-center">
            OR ENTER MANUALLY
          </div>
          <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
            <div>
              <TextField
                type="text"
                id="name"
                label="Enter Value"
                placeholder=" "
                onChange={(e) => setBarcode(e.target.value)}
                value={barcode}
                onKeyDown={(e) =>
                  e.key === "Enter" ? onSubmit({ barcode, formFactor }) : {}
                }
                autoFocus={true}
              />
            </div>
            <div className="mt-4">
              <Dropdown
                placeholder={"Form Factor"}
                list={[
                  { name: "Each" },
                  { name: "Case" },
                  { name: "Carton" },
                  { name: "Pallet" },
                ]}
                labelKey="name"
                valueKey="name"
                name="formFactor"
                setSelected={(selectedValue) => setFormFactor(selectedValue)}
                selectedValue={formFactor}
              />
            </div>
            <div className="flex space-x-2">
              <div
                className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 ${
                  barcode && barcode.trim() !== "" && formFactor
                    ? "bg-2C7695"
                    : "bg-gray-400"
                }`}
                onClick={() => onSubmit({ barcode, formFactor })}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanOrEnterManually;
