import { useEffect, useContext, useState } from "react";
import readXlsxFile from "read-excel-file";
import { ModalContext } from "./useReducer";
import AddButton from "../utils/AddButton";
import Modal from "../utils/Modal";
import ModalButton from "../utils/ModalButton";
import {
  uploadBulkConstants,
  uploadBulkStyles,
} from "../../utils/constants/bulkupload/UploadBulk";
import Error from "../common/Error";
import PropTypes from "prop-types";
import { UploadIcon } from "@heroicons/react/outline";

const {
  AddBttn,
  fileuploadModal,
  matchcolumnsModal,
  successModal,
  validation,
  finalError,
} = uploadBulkConstants;
const { dropDown, fileuploadModalstyles, matchcolumnsModalstyles, errorModal } =
  uploadBulkStyles;

const Dropdown = ({ title, list, onChange, value, selectlist, disabled }) => {
  return (
    <div className={dropDown.outerDiv}>
      <div className={dropDown.titleDiv}>
        <span>{title}</span>
      </div>
      <div className={dropDown.innerDiv}>
        {selectlist.map((_, i) => {
          return (
            <select
              key={i}
              value={value[i]}
              onChange={onChange.bind(null, i)}
              disabled={disabled}
              className={dropDown.select}>
              {list.map((item, index) => {
                return (
                  <option key={index} value={list[index]}>
                    {item}
                  </option>
                );
              })}
            </select>
          );
        })}
      </div>
    </div>
  );
};

/**
 * @param {object} dashboardFields - dashboardFields object
 * @param {function} saveBulkUpload - saveBulkUpload function
 * @param {string} errorMessage - errorMessage string
 * @param {string} successMessage - successMessage string
 * @param {function} validate - validate function
 * @param {object} validationResult - validationResult object
 * @param {string} [uploadBttnText] - text to be displayed for upload button
 * @param {Array<string>} [uploadBttnStyles] - styles to be applied for upload button
 * @returns {JSX.Element}
 * @description - UploadBulk component to upload bulk data
 */

const Upload = ({
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
  uploadBttnText = AddBttn.text,
  uploadBttnStyles = [],
}) => {
  const modals = useContext(ModalContext);
  const [percentage, setPercentage] = useState(null);
  const [file, setFile] = useState(null);
  const [successText, setSuccessText] = useState(false);
  const [differentiator, setDifferentiator] = useState(false);

  useEffect(() => {
    let Timeout;
    if (modals.uploadFileStep) {
      if (modals.tableRows.length === 0) {
        setPercentage(0);
      } else if (modals.tableRows.length !== 0) {
        setPercentage(1);
      }
    } else if (modals.matchColumnsStep) {
      Timeout = setTimeout(() => setPercentage(2), 500);
    } else if (modals.finalStep) {
      Timeout = setTimeout(() => setPercentage(3), 500);
    }
    return () => clearTimeout(Timeout);
  }, [
    modals.tableRows,
    modals.uploadFileStep,
    modals.matchColumnsStep,
    modals.finalStep,
  ]);

  useEffect(() => {
    if (!modals.uploadedFile) return;
    else {
      const readFile = async () => {
        const readfile = await readXlsxFile(modals.uploadedFile);
        modals.readFile(dashboardFields, readfile);
        setFile(readfile);
      };
      readFile();
    }
  }, [modals.uploadedFile]);

  useEffect(() => {
    setDifferentiator(false);
    let timer;
    let message = successMessage;
    message = message
      ? message
          .split("")
          .filter((item) => item !== " ")
          .join("")
          .toLowerCase()
          .includes("uploaded")
      : false;
    setDifferentiator(message);
    if (errorMessage || !message) {
      modals.removeLoading();
    } else if (message) {
      modals.removeLoading();
      timer = setTimeout(() => {
        setSuccessText(true);
        modals.stopModal();
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [errorMessage, successMessage]);

  const errorColor = (color) => {
    return { background: color };
  };

  /**
   *
   * @param {string} string - string
   * @returns {string}
   * @description - remove "\n" and remove all whitespaces from string
   * @example
   * removeSpecialCharactersAndWhiteSpaces(" hello\nworld ")
   */
  const removeSpecialCharactersAndWhiteSpaces = (string) => {
    return string?.replace(/\n/g, "").replace(/\s/g, "") ?? "";
  };

  /**
   * @param {number} rowIndex - rowIndex number
   * @param {number} colIndex - colIndex number
   * @returns {void}
   * @example
   * onChange(1, 1)
   */
  const fillColor = (rowIndex, colIndex) => {
    if (
      Object.keys(validationResult.errorObject.errors).indexOf(
        rowIndex.toString(),
      ) !== -1
    ) {
      let rowObject = validationResult.errorObject.errors[rowIndex];

      if (typeof rowObject === "object") {
        for (let columns in rowObject) {
          if (
            removeSpecialCharactersAndWhiteSpaces(
              dashboardFields[columns].display,
            ) ==
            removeSpecialCharactersAndWhiteSpaces(modals.tableHeaders[colIndex])
          ) {
            return errorColor(
              validationResult.errorDetails[rowObject[columns]].color,
            );
          }
        }
        return errorColor("#777777");
      } else {
        return errorColor(validationResult.errorDetails[rowObject].color);
      }
    } else {
      return {};
    }
  };

  const validationTable = () => {
    return (
      <div className={fileuploadModalstyles.tableDiv}>
        <table className={fileuploadModalstyles.table}>
          <thead>
            <tr>
              {modals.tableHeaders.map((header, i) => {
                return (
                  <td key={i} className={fileuploadModalstyles.thead}>
                    {header}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody className={fileuploadModalstyles.tbody}>
            {modals.tableRows.map((row, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  {row.map((col, colIndex) => {
                    return (
                      <td
                        key={colIndex}
                        style={
                          validationResult ? fillColor(rowIndex, colIndex) : {}
                        }
                        className={`${fileuploadModalstyles.td}`}>
                        {col}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    validationTable();
  }, [validationResult]);

  return (
    <>
      <AddButton
        onClick={modals.startUploading}
        text={uploadBttnText}
        styles={uploadBttnStyles}
        icon={UploadIcon}
      />

      {modals.uploadFileStep && (
        <Modal
          title={fileuploadModal.title}
          positiveText={fileuploadModal.positiveText}
          negativeAction={modals.stopModal}
          positiveAction={modals.secondModal}
          ButtonStyles={fileuploadModalstyles.ButtonStyles}
          positiveClassName={fileuploadModalstyles.positiveClassName}
          negativeClassname={fileuploadModalstyles.negativeClassname}
          progressBar={true}
          percent={percentage}
          specifybg={true}
          style={{ backgroundColor: "#0042FF" }}
          transparent={false}>
          <div className={fileuploadModalstyles.inputouterDiv}>
            <div className={fileuploadModalstyles.inputinnerDiv}>
              <label className={fileuploadModalstyles.label}>
                <span className={fileuploadModalstyles.span}>
                  {fileuploadModal.label}
                </span>
              </label>
              <input
                type={fileuploadModal.inputType}
                className={fileuploadModalstyles.input}
                onChange={modals.setUploadfile}
                color={fileuploadModalstyles.inputColor}
                accept={fileuploadModal.inputAccept}
              />
            </div>
            {modals.error && <Error children={modals.error} />}
            {modals.tableHeaders.length !== 0 && (
              <ModalButton
                text={fileuploadModal.modalbuttonText}
                transparent={true}
                specifybg={true}
                className={fileuploadModalstyles.modalbutton}
                onClick={modals.showPreview}
                textcolor={true}
              />
            )}
          </div>
          <div className={fileuploadModalstyles.tableDiv}>
            {modals.Preview && (
              <table className={fileuploadModalstyles.table}>
                <thead>
                  <tr>
                    {modals.tableHeaders.map((header, i) => {
                      return (
                        <td key={i} className={fileuploadModalstyles.thead}>
                          {header}
                        </td>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className={fileuploadModalstyles.tbody}>
                  {modals.tableRows.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {row.map((data, dataIndex) => {
                          return (
                            <td
                              key={dataIndex}
                              className={fileuploadModalstyles.td}>
                              {data}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </Modal>
      )}

      {modals.matchColumnsStep && (
        <Modal
          title={matchcolumnsModal.title}
          negativeAction={modals.stopModal}
          ButtonStyles={matchcolumnsModalstyles.ButtonStyles}
          negativeClassname={matchcolumnsModalstyles.negativeClassname}
          specifybg={true}
          progressBar={true}
          percent={percentage}
          style={{ backgroundColor: "#0042FF" }}
          validate={true}
          validateAction={modals.startValidation.bind(
            null,
            file,
            dashboardFields,
            validate,
          )}
          validateText={matchcolumnsModal.validateText}
          validateClassName={matchcolumnsModalstyles.validateClassname}
          transparent={false}>
          <div className={matchcolumnsModalstyles.outerDiv}>
            <div className={matchcolumnsModalstyles.innerDiv}>
              <Dropdown
                title={matchcolumnsModal.columns[0]}
                list={modals.tableHeaders}
                onChange={modals.changeselectedValue}
                value={modals.tableHeaders}
                selectlist={modals.tableHeaders}
                disabled={true}
              />
              <Dropdown
                title={matchcolumnsModal.columns[1]}
                list={modals.fields}
                onChange={modals.changeselectedValue}
                value={modals.defaultValue}
                selectlist={modals.attributes}
              />
            </div>
            {modals.error && <Error children={modals.error} />}
            <div className={matchcolumnsModalstyles.modalbuttonDiv}>
              <ModalButton
                text={matchcolumnsModal.modalbuttonText}
                transparent={true}
                specifybg={true}
                textcolor={true}
                className={matchcolumnsModalstyles.modalbutton}
                onClick={modals.addAttribute}
              />
            </div>
          </div>
        </Modal>
      )}
      {modals.finalStep && !modals.loading && !successText && (
        <Modal
          title={differentiator ? successModal.title : finalError.title}
          negativeAction={modals.stopModal}
          positiveText={"Upload another File"}
          positiveAction={modals.startUploading}
          specifybg={true}
          progressBar={true}
          percent={percentage}
          style={{ backgroundColor: "#0042FF" }}
          positiveClassName={fileuploadModalstyles.positiveClassName}
          transparent={false}>
          {differentiator ? (
            <p className="text-center">{successMessage}</p>
          ) : (
            <div className={errorModal.modalbtnDiv}>
              <div className={errorModal.content}>
                {errorMessage !== null ? errorMessage : successMessage}
              </div>
            </div>
          )}
        </Modal>
      )}
      {modals.validation && (
        <Modal
          title={validation.title}
          positiveText={
            validationResult == null ? validation.positiveText[0] : null
          }
          positiveAction={
            validationResult == null
              ? modals.thirdModal.bind(null, saveBulkUpload)
              : null
          }
          negativeText={
            validationResult != null ? "Upload another File" : "Cancel"
          }
          negativeAction={
            validationResult != null
              ? modals.startUploading
              : modals.stopValidation
          }
          ButtonStyles={fileuploadModalstyles.ButtonStyles}
          positiveClassName={fileuploadModalstyles.positiveClassName}
          negativeClassname={fileuploadModalstyles.negativeClassname}
          progressBar={true}
          percent={percentage}
          specifybg={true}
          style={{ backgroundColor: "#0042FF" }}
          transparent={false}>
          <div className="flex flex-col px-4">
            <div className="text-2C7695 font-semibold">
              Your file statistics:
            </div>
            <div className="flex flex-col gap-2 text-black font-medium mt-2">
              {validationResult != null ? (
                <>
                  <div>
                    <span className="text-green-600 mr-2">
                      {validationResult
                        ? validationResult.errorObject.totalRows -
                          validationResult.errorObject.totalErrorRows
                        : "Loading..."}
                    </span>
                    Rows are validated
                  </div>
                  <div>
                    <span className="text-red-700 mr-2">
                      {validationResult
                        ? validationResult.errorObject.totalErrorRows
                        : "Loading..."}
                    </span>
                    Rows can't be uploaded due to errors
                  </div>
                  <div>
                    *All Rows could not be validated. Upload a new file.
                  </div>
                </>
              ) : (
                <div>
                  All Rows have been validated. Click on Proceed to Upload.
                </div>
              )}
            </div>
          </div>
          <ModalButton
            text={fileuploadModal.modalbuttonText}
            transparent={true}
            specifybg={true}
            className={fileuploadModalstyles.modalbutton}
            onClick={modals.showPreview}
            textcolor={true}
          />

          {modals.Preview && (
            <>
              {validationResult ? (
                <div className="ml-6 text-small flex flex-row  space-x-4 mb-1">
                  <div className="flex flex-row items-center">
                    <span
                      style={{
                        backgroundColor: "#777777",
                      }}
                      className={"h-4 w-4 rounded-full block m-1"}></span>
                    <span>{"Invalid Row"}</span>
                  </div>
                  {Object.keys(validationResult.errorDetails).map((error) => {
                    return (
                      <div key={error} className="flex flex-row items-center">
                        <span
                          style={{
                            backgroundColor:
                              validationResult.errorDetails[error].color,
                          }}
                          className={"h-4 w-4 rounded-full block m-1"}></span>
                        <span>
                          {validationResult.errorDetails[error].message}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              {validationTable()}
            </>
          )}
        </Modal>
      )}
    </>
  );
};

Upload.propTypes = {
  dashboardFields: PropTypes.array,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  validationResult: PropTypes.object,
  saveBulkUpload: PropTypes.func,
  validate: PropTypes.func,
};

export default Upload;
