import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import PermissionsForm from "#components/users/PermissionsForm";
import _ from "lodash";

const CustomEntityForm = ({
  onSubmit,
  setAddCustomEntity,
  addCustomEntity,
  permissionOptions,
  onChangePermission,
  showCode = true,
}) => (
  <Modal
    title={"Add Custom"}
    negativeAction={() => setAddCustomEntity(null)}
    positiveAction={onSubmit}
    onClose={() => setAddCustomEntity(null)}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Details
        </span>
      </div>

      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={(e) =>
            setAddCustomEntity({
              ...addCustomEntity,
              name: e.target.value,
            })
          }
          value={addCustomEntity.name}
        />
      </div>

      {showCode && (
        <div>
          <TextField
            type="text"
            id="code"
            label="Code"
            placeholder=" "
            onChange={(e) =>
              setAddCustomEntity({
                ...addCustomEntity,
                code: e.target.value,
              })
            }
            value={addCustomEntity.code}
          />
        </div>
      )}
      {permissionOptions && (
        <PermissionsForm
          permissionOptions={permissionOptions}
          onChangePermission={onChangePermission}
        />
      )}
    </div>
  </Modal>
);

export default CustomEntityForm;
