import Tabs from "#components/utils/Tabs";
import PageTitle from "#components/utils/PageTitle";
import SoftwareIntegrations from "#components/integrations/SoftwareIntegrations";
import HardwareIntegrations from "#components/integrations/HardwareIntegrations";

const Integrations = ({ writable, location }) => (
  <div className="p-5">
    <PageTitle>Integration Management</PageTitle>
    <Tabs
      tabs={{
        Software: (
          <SoftwareIntegrations writable={writable} location={location} />
        ),
        Hardware: (
          <HardwareIntegrations writable={writable} location={location} />
        ),
      }}
    />
  </div>
);

export default Integrations;
