import React from "react";
const PrivacyPolicy = () => {
  return (
    <div className="h-screen w-screen">
      <div className="w-full flex justify-between absolute top-0 left-0 bg-primaryAccent">
        <div className="flex items-center justify-center space-x-4 py-2 px-2">
          <img
            src="https://hopstack-pub.s3.amazonaws.com/logo.png"
            alt="Picture of the author"
            className="w-12 h-12"
          />
          <span className="font-hammersmith text-white text-2xl">
            Hopstack Inc
          </span>
        </div>
      </div>
      <div
        name="termly-embed"
        data-id="6a88f003-a4ac-481d-8688-b3a68eb97b48"
        data-type="iframe"></div>
      {(function (d, s, id) {
        var js,
          tjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://app.termly.io/embed-policy.min.js";
        tjs.parentNode.insertBefore(js, tjs);
      })(document, "script", "termly-jssdk")}
    </div>
  );
};

export default PrivacyPolicy;
