import Modal from "#components/utils/Modal";
import {
  DesktopComputerIcon,
  LogoutIcon,
  CogIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import Dropdown from "#components/utils/Dropdown";
import { useState } from "react";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

import { ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/outline";

const ManageIntegrationProvider = ({ children, ...props }) => {
  const {
    integrations,
    integrationProvider,
    setSelectedIntegrationToManage,
    selectedIntegrationToConfigure,
    setSelectedIntegrationToConfigure,
    writable,
    connectIntegration,
    logoutIntegration,
    testIntegration,
    getProducts,
    configureIntegrationSubmit,
  } = props;
  return (
    <div className="w-full">
      <ControlButtonsRow
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        writable={writable}
        connectIntegration={connectIntegration}
        integrationProvider={integrationProvider}
      />
      <ConnectedIntegrationsGrid
        integrations={integrations.filter(
          (item) => item.integrationType === integrationProvider.name,
        )}
        writable={writable}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        getProducts={getProducts}
        integrationProvider={integrationProvider}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
      />
      {selectedIntegrationToConfigure && (
        <ConfigureIntegrationModal
          selectedIntegration={selectedIntegrationToConfigure}
          setSelectedIntegration={setSelectedIntegrationToConfigure}
          onClose={() => setSelectedIntegrationToConfigure(null)}
          onSubmit={configureIntegrationSubmit}
          integrationProvider={integrationProvider}
        />
      )}
    </div>
  );
};

const ConfigureIntegrationModal = ({ ...props }) => {
  const {
    selectedIntegration,
    setSelectedIntegration,
    onClose,
    onSubmit,
    integrationProvider,
  } = props;
  return (
    <Modal
      title={`Configure ${integrationProvider.name} Integration - ${selectedIntegration.sellerId}`}
      negativeAction={onClose}
      positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Region
          </span>
        </div>
      </div>
      <div>
        <Dropdown
          placeholder={"Region"}
          list={integrationProvider.regions}
          labelKey="name"
          valueKey="code"
          name="region"
          setSelected={(e) => {
            setSelectedIntegration({
              ...selectedIntegration,
              region: e,
              marketplaces:
                e !== selectedIntegration.region
                  ? []
                  : selectedIntegration.marketplaces,
            });
          }}
          selectedValue={selectedIntegration.region}
        />
      </div>
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Marketplaces
          </span>
        </label>
        <MultiSelectAutoComplete
          options={integrationProvider.marketplaces.filter(
            (marketplace) => marketplace.region === selectedIntegration.region,
          )}
          labelKey="name"
          valueKey="countryCode"
          setValues={(e) =>
            setSelectedIntegration({
              ...selectedIntegration,
              marketplaces: e,
            })
          }
          values={selectedIntegration.marketplaces}
          rounded={true}
        />
      </div>
    </Modal>
  );
};

const ControlButtonsRow = ({ ...props }) => {
  const { writable } = props;
  return (
    <div className="flex w-full">
      <BackButton {...props} />
      {writable && <AddNewIntegrationButton {...props} style={"ml-auto"} />}
    </div>
  );
};

const BackButton = ({ ...props }) => {
  const { setSelectedIntegrationToManage } = props;
  return (
    <div
      onClick={() => setSelectedIntegrationToManage(null)}
      className="cursor-pointer flex justify-center items-center gap-2 font-medium text-lg text-primaryAccent">
      <ArrowLeftIcon className="h-6 w-6" />
      Back
    </div>
  );
};

const AddNewIntegrationButton = ({ ...props }) => {
  const { style } = props;
  const { connectIntegration, integrationProvider } = props;
  return (
    <div
      onClick={() => connectIntegration(integrationProvider.name)}
      className={
        style +
        " cursor-pointer flex justify-center items-center gap-2 font-medium text-lg text-primaryAccent"
      }>
      <PlusCircleIcon className="h-6 w-6" />
      Connect New Account
    </div>
  );
};

const ConnectedIntegrationsGrid = ({ ...props }) => {
  const {
    integrations,
    writable,
    logoutIntegration,
    testIntegration,
    getProducts,
    integrationProvider,
    selectedIntegrationToConfigure,
    setSelectedIntegrationToConfigure,
  } = props;
  if (integrations.length === 0) {
    return (
      <div className="flex h-32">
        <div className="m-auto">
          No {integrationProvider.name} accounts connected.
        </div>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-3 gap-4">
      {integrations
        .sort((a, b) =>
          a?.region?.toLowerCase() > b?.region?.toLowerCase() ? -1 : 1,
        )
        .map((integration, idx) => (
          <div className="bg-white shadow-lg rounded-lg py-8 pt-0" key={idx}>
            <div className="flex bg-integrationLogoBackground rounded-t-lg py-6 w-full justify-center">
              <img
                src={integrationProvider.logo}
                className="w-32 h-20 object-contain"
              />
            </div>
            <div className="flex h-40 flex-col items-center py-6 px-4 text-center justify-center">
            {(integrationProvider.name === "Amazon") && (<div><div className="text-xl font-medium text-primaryAccent">
                {integrationProvider.name} -{" "}
                {
                  integrationProvider.regions.find(
                    (item) => item.code === integration.region,
                  ).name
                }
              </div>
              <div className="text-lg font-normal text-5F666B">
                Seller ID: {integration.sellerId}
              </div></div>)}
              {(integrationProvider.name === "Mirakl") && (<div> <div className="text-xl font-medium text-primaryAccent">
                {integrationProvider.name} - {integration.configurations.marketplace} 
              </div>
              <div className="text-lg font-normal text-5F666B">
                {integrationProvider.description}
              </div>
            </div>)}
            </div>
            <div className="flex mx-6 mt-4 space-x-2">
              <div className="flex-col w-full space-y-4">
               { (integration.integrationType === "Amazon")  && ( <div className="flex w-full space-between space-x-2">
                  <button
                    className={`flex w-full px-5 py-2 text-white text-md ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center text-center rounded-lg`}
                    onClick={() =>
                    writable && setSelectedIntegrationToConfigure(integration)
                    }>
                    <CogIcon className="w-5 h-5 mr-1" />
                    Configure Integration
                  </button>
                </div>)}
                <div className="flex w-full space-between space-x-2">
                  <button
                    className={`flex w-full px-5 py-2 text-white text-md ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center text-center rounded-lg`}
                    onClick={() => writable && testIntegration(integration.id)}>
                    <DesktopComputerIcon className="w-5 h-5 mr-1" />
                    Test
                  </button>
                  <button
                    className={`flex w-full px-5 py-2 text-white text-md ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center text-center rounded-lg`}
                    onClick={() => writable && getProducts(integration.id)}>
                    <RefreshIcon className="w-5 h-5 mr-1" />
                    Sync Now
                  </button>
                </div>
                <div className="flex w-full space-between space-x-2">
                  <button
                    className={`flex w-full px-5 py-2 text-white text-md ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center text-center rounded-lg`}
                    onClick={() =>
                      writable && logoutIntegration(integration.id)
                    }>
                    <LogoutIcon className="w-5 h-5 mr-1" />
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ManageIntegrationProvider;
