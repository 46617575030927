// ManualBatchOld

import Modal from "#components/utils/Modal";
import _ from "lodash";
import Autocomplete from "#components/utils/Autocomplete";
import Toggle from "#components/utils/Toggle";
import TextField from "#components/utils/TextField";

const SelectPickerForm = ({
  onSubmit,
  negativeAction,
  pickers,
  manualBatchConfig,
  setManualBatchConfig,
  stations,
  selectedOrders = [],
  tenant,
  batchSettings,
  isManualBatchExecuting,
}) => (
  <Modal
    title={`Create Manual Batch(${selectedOrders.length} orders)`}
    negativeAction={negativeAction}
    disabled={isManualBatchExecuting}
    positiveAction={onSubmit}>
    <div className="space-y-4">
      {batchSettings &&
        batchSettings.pickingStrategy &&
        batchSettings.pickingStrategy !== "PICK_BY_ORDER" && (
          <div>
            <TextField
              type="number"
              id="quantity"
              label="Batch Size"
              placeholder=""
              onChange={(e) =>
                setManualBatchConfig({
                  ...manualBatchConfig,
                  maxOrdersInBatch: isNaN(parseInt(e.target.value))
                    ? null
                    : parseInt(e.target.value),
                })
              }
              name="revisedStock"
              value={manualBatchConfig.maxOrdersInBatch}
              min="1"
              autoFocus={false}
            />
          </div>
        )}
      <div>
        <Autocomplete
          options={pickers}
          labelKey="name"
          valueKey="id"
          onChange={(selectedOption) => {
            setManualBatchConfig({
              ...manualBatchConfig,
              picker: selectedOption,
            });
          }}
          value={manualBatchConfig.picker}
          placeholder="Select Picker (optional)"
          id="SELECT_PICKER"
        />
      </div>
      {selectedOrders.findIndex((i) => i.source === "FBA") !== -1 && (
        <div>
          <Autocomplete
            options={stations.filter((i) => i.hopstackModule === "Prepping")}
            labelKey="name"
            valueKey="name"
            onChange={(selectedOption) => {
              setManualBatchConfig({
                ...manualBatchConfig,
                preppingStation: selectedOption,
              });
            }}
            value={manualBatchConfig.preppingStation}
            placeholder="Select Prepping Station (optional)"
            id="SELECT_PREPPING_STATION"
          />
        </div>
      )}
      <div>
        <Autocomplete
          options={stations.filter((i) => i.hopstackModule === "Packing")}
          labelKey="name"
          valueKey="name"
          onChange={(selectedOption) => {
            setManualBatchConfig({
              ...manualBatchConfig,
              packingStation: selectedOption,
            });
          }}
          value={manualBatchConfig.packingStation}
          placeholder="Select Packing Station (optional)"
          id="SELECT_PACKING_STATION"
        />
      </div>

      {tenant?.features?.combinedOrders === true &&
        selectedOrders.findIndex(
          (i) => i.customAttributes?.combinedOrder === true,
        ) !== -1 && (
          <div className="flex space-x-4 items-center px-2">
            <Toggle
              enabled={manualBatchConfig.combineOrders === true}
              setEnabled={(e) =>
                setManualBatchConfig({
                  ...manualBatchConfig,
                  combineOrders: e,
                })
              }
            />
            <div className="text-lg">Combine Orders?</div>
          </div>
        )}
    </div>
  </Modal>
);

export default SelectPickerForm;
