import { useState, useEffect, useContext } from "react";
import PackerRateShopping from "#components/packer/PackerRateShopping";
import RateShoppingLtlPallet from "#components/rateShopping/RateShoppingLtlPallet";
import { useQuery } from "#hooks/useQuery";
import TextField from "#components/utils/TextField";
import { AppStateContext } from "#contexts/appState";
import Select from "react-select";
import {
  CONFIRM_PALLET_INFORMATION,
  SWITCH_TYPE_OF_SHIPMENT,
  HANDLE_FBA_LABEL_GENERATION,
} from "#mutations";
import {
  GET_FBA_LABEL_GENERATION_STATUS,
  FETCH_SPECIFIC_ORDER,
} from "#queries";
import FbaTransportStatus from "#components/packer/FbaTransportStatus";
import Modal from "#components/utils/Modal";

let fbaLabelGenerationStatusInterval = null;
const shipmentTypes = {
  LTL: "LTL",
  SP: "SP",
};

const LabelGenerationOperation = {
  START: 1,
  UPDATE_TRANSPORTATION_MODE_SIGNAL: 2,
  UPDATE_TRACKING_DETAILS_SIGNAL: 3,
  PALLETIZATION_SIGNAL: 4,
  TERMINATE: 5,
};

const FbaShippingHandler = ({
  canShowRateShoppingScreen,
  order,
  currentBatch,
  restartActivity,
  fetchSpecificOrder,
  tenant,
  navigateToBoxContentsConfirmation,
  masterData,
  printBoxLabels,
  downloadBoxLabels,
  getCurrentBatch,
  confirmDropoff,
  pauseActivity,
}) => {
  const appState = useContext(AppStateContext);
  const switchTypeOfShipmentQuery = useQuery(SWITCH_TYPE_OF_SHIPMENT);
  const handleFbaLabelGenerationQuery = useQuery(HANDLE_FBA_LABEL_GENERATION);
  const getFbaLabelGenerationStatusQuery = useQuery(
    GET_FBA_LABEL_GENERATION_STATUS,
  );

  const [shipmentType, setShipmentType] = useState(
    order?.typeOfShipment || "SP",
  );
  const [isPartneredCarrier, setIsPartneredCarrier] = useState(
    order?.isPartneredCarrier ||
      [null, undefined].includes(order?.isPartneredCarrier)
      ? true
      : order.isPartneredCarrier,
  );
  const [palletInfo, setPalletInfo] = useState(order?.pallets || []);
  const [trackingInformation, setTrackingInformation] = useState({});
  const [labelGenerationStatus, setLabelGenerationStatus] = useState(null);
  const [printedBoxLabels, setPrintedBoxLabels] = useState(false);
  const [
    showShipmentTypeAndTransportSelection,
    setShowShipmentTypeAndTransportSelection,
  ] = useState(false);
  const [shouldAskForTrackingInformation, setShouldAskForTrackingInformation] =
    useState(false);

  useEffect(() => {
    if (
      labelGenerationStatus?.status === "IN_PROGRESS" &&
      labelGenerationStatus?.stageProgress
        ?.filter((i) => i.status !== "WAITING")
        .findIndex(
          (i) =>
            i.activityName === "getBoxLabelsFromAmazonAndSave" &&
            i.status === "COMPLETED",
        ) ===
        labelGenerationStatus?.stageProgress?.filter(
          (i) => i.status !== "WAITING",
        )?.length -
          1
    ) {
      setShowShipmentTypeAndTransportSelection(true);
    } else if (
      labelGenerationStatus?.status === "IN_PROGRESS" &&
      labelGenerationStatus?.stageProgress
        ?.filter((i) => i.status !== "WAITING")
        .findIndex(
          (i) =>
            i.activityName === "determineTransportType" &&
            i.status === "COMPLETED",
        ) ===
        labelGenerationStatus?.stageProgress?.filter(
          (i) => i.status !== "WAITING",
        )?.length -
          1 &&
      (shipmentType === "LTL" || !isPartneredCarrier)
    ) {
      setShouldAskForTrackingInformation(true);
    } else if (
      labelGenerationStatus?.status === "IN_PROGRESS" &&
      labelGenerationStatus?.stageProgress
        ?.filter((i) => i.status !== "WAITING")
        .findIndex(
          (i) =>
            i.activityName === "getPalletLabelsFromAmazonAndSave" &&
            i.status === "COMPLETED",
        ) ===
        labelGenerationStatus?.stageProgress?.filter(
          (i) => i.status !== "WAITING",
        )?.length -
          1 &&
      (shipmentType === "LTL" || !isPartneredCarrier)
    ) {
      setShouldAskForTrackingInformation(true);
    } else if (labelGenerationStatus?.status === "COMPLETED") {
      getCurrentBatch();
    } else {
      setShouldAskForTrackingInformation(false);
      setShowShipmentTypeAndTransportSelection(false);
    }
  }, [labelGenerationStatus]);

  useEffect(() => {
    if (order) {
      getFbaLabelGenerationStatusQuery.fetchData({ shipmentId: order.orderId });
    }

    return () => {
      clearInterval(fbaLabelGenerationStatusInterval);
      fbaLabelGenerationStatusInterval = null;
    };
  }, []);

  const fetchFbaLabelGenerationStatus = () => {
    if (order?.orderId) {
      getFbaLabelGenerationStatusQuery.fetchData({
        shipmentId: order.orderId,
      });
    }
  };

  useEffect(() => {
    if (getFbaLabelGenerationStatusQuery.data) {
      setLabelGenerationStatus(
        getFbaLabelGenerationStatusQuery.data.getFbaLabelGenerationStatus,
      );

      const labelGenerationStatusResult =
        getFbaLabelGenerationStatusQuery.data.getFbaLabelGenerationStatus;
      if (
        [
          "TERMINATED",
          "NOT_STARTED",
          "COMPLETED",
          "FAILED",
          "CANCELED",
        ].includes(labelGenerationStatusResult.status) === false &&
        currentBatch?.status === "AWAITING_LABEL"
      ) {
        if (labelGenerationStatusResult.status === "IN_PROGRESS") {
          if (
            labelGenerationStatusResult.stageProgress.findIndex((i) =>
              ["SCHEDULED", "IN_PROGRESS", "STARTED"].includes(i.status),
            ) !== -1
          ) {
            // REMOVE WAITING STATUS IF IT EXISTS
            labelGenerationStatusResult.stageProgress =
              labelGenerationStatusResult.stageProgress.filter(
                (item) => item.status !== "WAITING",
              );
          } else {
            labelGenerationStatusResult.stageProgress.push({
              status: "WAITING",
              humanReadableActivityName: "FBA Label Generation",
              timestamp: Date.now(),
              activityName: "FBA_LABEL_GENERATION",
            });
          }

          setLabelGenerationStatus(labelGenerationStatusResult);
          if (!fbaLabelGenerationStatusInterval) {
            fbaLabelGenerationStatusInterval = setInterval(
              fetchFbaLabelGenerationStatus,
              1000,
            );
          }
        } else if (labelGenerationStatusResult.status === "STARTED") {
          if (!fbaLabelGenerationStatusInterval) {
            fbaLabelGenerationStatusInterval = setInterval(
              fetchFbaLabelGenerationStatus,
              1000,
            );
          }
        } else {
          setLabelGenerationStatus(labelGenerationStatusResult);
          clearInterval(fbaLabelGenerationStatusInterval);
          fbaLabelGenerationStatusInterval = null;
        }
      } else {
        clearInterval(fbaLabelGenerationStatusInterval);
        fbaLabelGenerationStatusInterval = null;
        if (labelGenerationStatusResult.status !== "FAILED") {
          setLabelGenerationStatus(null);
        }
        getCurrentBatch();
      }
    }

    if (getFbaLabelGenerationStatusQuery.error) {
      clearInterval(fbaLabelGenerationStatusInterval);
      fbaLabelGenerationStatusInterval = null;
      setLabelGenerationStatus(null);
    }
  }, [
    getFbaLabelGenerationStatusQuery.loading,
    getFbaLabelGenerationStatusQuery.error,
    getFbaLabelGenerationStatusQuery.message,
  ]);

  const onPalletSubmit = (pallets) => {
    handleFbaLabelGenerationQuery.fetchData({
      batchId: currentBatch.id,
      orderId: order.id,
      operation: LabelGenerationOperation.PALLETIZATION_SIGNAL,
      pallets,
    });
  };

  const onPalletSubmitEvent = (pallets) => {
    setPalletInfo(pallets);
    onPalletSubmit(pallets);
    appState.setAlert(`Saved Pallet Information`, "success");
  };

  const validatedAllPallets = (pallets) => {
    return pallets.every((pallet) => {
      return pallet.weight && pallet.height && pallet.length && pallet.width;
    });
  };

  const requestConfirmDropoff = () => {
    appState.showConfirmation(
      "Are you sure you want to skip this step?",
      "This would mark the order as completed.",
      () => {
        confirmDropoff();
        appState.hideConfirmation();
      },
      () => {
        appState.hideConfirmation();
      },
    );
  };

  const handleSubmit = () => {
    if (showShipmentTypeAndTransportSelection) {
      handleFbaLabelGenerationQuery.fetchData({
        batchId: currentBatch.id,
        orderId: order.id,
        operation: LabelGenerationOperation.UPDATE_TRANSPORTATION_MODE_SIGNAL,
        typeOfShipment: shipmentType,
        isPartneredCarrier,
      });
    } else {
      if (shipmentType === "LTL" && !palletInfo?.length) {
        appState.setAlert(
          `Please add and save pallet information before proceeding.`,
          "error",
        );
        if (!validatedAllPallets(palletInfo)) {
          appState.setAlert(
            `Please provide valid pallet information for all pallets.`,
            "error",
          );
        }
      } else if (shipmentType === "LTL" && !trackingInformation?.proNumber) {
        appState.setAlert(
          `Please provide Pro Number before proceeding.`,
          "error",
        );
      } else if (
        shipmentType === "SP" &&
        !trackingInformation?.trackingNumber &&
        !isPartneredCarrier
      ) {
        appState.setAlert(
          `Please provide Tracking Number before proceeding.`,
          "error",
        );
      } else if (shipmentType === "LTL" && !validatedAllPallets(palletInfo)) {
        appState.setAlert(
          `Please provide valid pallet information for all pallets.`,
          "error",
        );
      } else if (!trackingInformation?.carrier?.value && !isPartneredCarrier) {
        appState.setAlert(`Please provide Carrier before proceeding.`, "error");
      } else {
        handleFbaLabelGenerationQuery.fetchData({
          batchId: currentBatch.id,
          orderId: order.id,
          operation: LabelGenerationOperation.UPDATE_TRACKING_DETAILS_SIGNAL,
          shipmentType,
          trackingNumbers: trackingInformation?.trackingNumber
            ? trackingInformation?.trackingNumber
                .split(",")
                .map((i) => i.trim())
            : [],
          proNumber: trackingInformation?.proNumber,
          carrier: trackingInformation?.carrier?.value,
          isPartneredCarrier,
        });
      }
    }
  };

  useEffect(() => {
    if (handleFbaLabelGenerationQuery.data) {
      fetchFbaLabelGenerationStatus();
      if (
        handleFbaLabelGenerationQuery.variables.operation ===
          LabelGenerationOperation.UPDATE_TRANSPORTATION_MODE_SIGNAL &&
        isPartneredCarrier &&
        shipmentType === shipmentTypes.SP
      ) {
        setShowShipmentTypeAndTransportSelection(false);
        setShouldAskForTrackingInformation(false);
      } else if (
        handleFbaLabelGenerationQuery.variables.operation ===
        LabelGenerationOperation.UPDATE_TRANSPORTATION_MODE_SIGNAL
      ) {
        setShowShipmentTypeAndTransportSelection(false);
        setShouldAskForTrackingInformation(true);
      } else if (
        handleFbaLabelGenerationQuery.variables.operation ===
        LabelGenerationOperation.UPDATE_TRACKING_DETAILS_SIGNAL
      ) {
        setShouldAskForTrackingInformation(false);
      }
    }
    if (handleFbaLabelGenerationQuery.error) {
      appState.setAlert(handleFbaLabelGenerationQuery.error.message, "error");
    }
  }, [
    handleFbaLabelGenerationQuery.loading,
    handleFbaLabelGenerationQuery.error,
    handleFbaLabelGenerationQuery.data,
  ]);

  const handlePrev = () => {
    if (labelGenerationStatus && labelGenerationStatus.status === "FAILED") {
      setLabelGenerationStatus(null);
      navigateToBoxContentsConfirmation();
      return;
    }
    handleFbaLabelGenerationQuery.fetchData({
      batchId: currentBatch.id,
      orderId: order.id,
      operation: LabelGenerationOperation.TERMINATE,
    });
    if (
      showShipmentTypeAndTransportSelection ||
      labelGenerationStatus?.status
    ) {
      navigateToBoxContentsConfirmation();
    }
  };

  const ChoiceComponent = ({ label, choices }) => {
    return (
      <div className="mb-5">
        <h1 className="text-xl font-bold mb-4">
          {label}
          <span className="text-red-500">*</span>
        </h1>
        <div className="space-y-4">
          {choices.map((choice) => (
            <div className="flex item-center" key={choice.label}>
              <div className="cursor-pointer inline-block text-xl">
                <input
                  name={choice.label}
                  type="radio"
                  id={choice.label}
                  className="mr-4 cursor-pointer text-2C7695 w-8 h-8"
                  checked={choice.value}
                  onChange={choice.selectChoice}
                />
                <label className="cursor-pointer" htmlFor={choice.label}>
                  {choice.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (canShowRateShoppingScreen) {
    return (
      <PackerRateShopping
        order={order}
        orderBatch={currentBatch}
        restartActivity={restartActivity}
        fetchSpecificOrder={fetchSpecificOrder}
        tenant={tenant}
      />
    );
  }

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="mx-auto p-10 mt-32 mb-8 bg-white rounded-lg border-solid">
          <div className="flex items-center justify-center pt-5 pb-5">
            {labelGenerationStatus && (
              <FbaTransportStatus
                fbaLabelGenerationStatus={labelGenerationStatus}
              />
            )}
          </div>
          {showShipmentTypeAndTransportSelection && (
            <Modal
              negativeAction={requestConfirmDropoff}
              positiveAction={handleSubmit}
              title="Configure Transport">
              <ChoiceComponent
                label="Select Shipment Type"
                choices={[
                  {
                    label: "Small Parcel",
                    value: shipmentType === "SP",
                    selectChoice: () => {
                      setShipmentType(shipmentTypes.SP);
                    },
                  },
                  {
                    label: "LTL/FTL",
                    value: shipmentType === shipmentTypes.LTL,
                    selectChoice: () => {
                      setShipmentType(shipmentTypes.LTL);
                    },
                  },
                ]}
              />

              <ChoiceComponent
                label="Carrier"
                choices={[
                  {
                    label: "Partnered(Amazon)",
                    value: isPartneredCarrier === true,
                    selectChoice: () => setIsPartneredCarrier(true),
                  },
                  {
                    label: "Non-Partnered",
                    value: isPartneredCarrier === false,
                    selectChoice: () => setIsPartneredCarrier(false),
                  },
                ]}
              />
              {(shipmentType === shipmentTypes.LTL ||
                isPartneredCarrier === false) && (
                <div className="flex-1 flex space-x-2">
                  <div
                    className={`flex-1 text-xl text-black border-black border mt-4 rounded-md text-center py-2 bg-transparent cursor-pointer`}
                    onClick={printBoxLabels}>
                    Print Box Labels
                  </div>
                  <div
                    className={`flex-1 text-xl text-black border-black border mt-4 rounded-md text-center py-2 cursor-pointer`}
                    onClick={downloadBoxLabels}>
                    Download Box Labels
                  </div>
                </div>
              )}
            </Modal>
          )}

          {shouldAskForTrackingInformation && (
            <Modal
              negativeAction={requestConfirmDropoff}
              positiveAction={handleSubmit}
              title="Provide Tracking Information">
              <div className="flex-col items-center justify-center p-10 pt-5 pb-5 space-y-2">
                {shipmentType === "LTL" && (
                  <RateShoppingLtlPallet
                    palletInfo={palletInfo}
                    setPalletInfo={setPalletInfo}
                    onPalletSubmit={onPalletSubmitEvent}
                  />
                )}
                {!isPartneredCarrier && (
                  <>
                    <Select
                      options={
                        masterData &&
                        masterData.amazonCarriers &&
                        masterData.amazonCarriers.map((item) => ({
                          value: item.id,
                          label: `${item.name}`,
                        }))
                      }
                      value={trackingInformation.carrier}
                      onChange={(e) =>
                        setTrackingInformation({
                          ...trackingInformation,
                          carrier: e,
                        })
                      }
                      className="text-2xl border border-black mb-8"
                      placeholder="Select Carrier"
                      isClearable={true}
                    />
                    {shipmentType === "LTL" ? (
                      <TextField
                        type="text"
                        id="proNumber"
                        label="Pro Number"
                        placeholder=" "
                        value={trackingInformation.proNumber}
                        onChange={(e) =>
                          setTrackingInformation({
                            ...trackingInformation,
                            proNumber: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <TextField
                        type="text"
                        id="trackingNumber"
                        label="Tracking Number(s) (Comma Separated)"
                        placeholder=" "
                        value={trackingInformation.trackingNumber}
                        onChange={(e) =>
                          setTrackingInformation({
                            ...trackingInformation,
                            trackingNumber: e.target.value,
                          })
                        }
                      />
                    )}
                  </>
                )}

                <div className="flex-1 flex space-x-2">
                  <div
                    className={`flex-1 text-xl text-black border-black border mt-4 rounded-md text-center py-2 bg-transparent cursor-pointer`}
                    onClick={printBoxLabels}>
                    Print Box Labels
                  </div>
                  <div
                    className={`flex-1 text-xl text-black border-black border mt-4 rounded-md text-center py-2 cursor-pointer`}
                    onClick={downloadBoxLabels}>
                    Download Box Labels
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {showShipmentTypeAndTransportSelection ||
          shouldAskForTrackingInformation ? (
            <div className="flex space-x-4">
              <div
                className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer bg-red-600`}
                onClick={handlePrev}>
                Previous
              </div>
              <div
                className={`mt-2 text-2xl text-primaryAccent w-64 rounded-md text-center py-4 cursor-pointer bg-transparent border-gray-400 border`}
                onClick={confirmDropoff}>
                Skip
              </div>
              <div
                className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer bg-2C7695`}
                onClick={handleSubmit}>
                Submit
              </div>
            </div>
          ) : (
            <>
              {labelGenerationStatus?.status === "FAILED" &&
                labelGenerationStatus?.errorMessage &&
                labelGenerationStatus?.errorMessage
                  .toLowerCase()
                  .includes("hazmat") && (
                  <div className="text-2xl text-red-600">
                    This shipment contains Hazmat items, so it cannot be sent
                    via partnered carrier to Amazon.
                    <br />
                    Go back and select Non-Partnered in the Carrier section.
                  </div>
                )}
              <div className="mt-8 flex items-center justify-center space-x-4">
                <div
                  className={`mt-2 text-2xl text-white w-64 rounded-md text-center py-4 cursor-pointer bg-red-600`}
                  onClick={handlePrev}>
                  Go Back
                </div>
                <div
                  className="mt-2 text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4 cursor-pointer items-center justify-center flex"
                  onClick={confirmDropoff}>
                  Skip
                </div>
                <div
                  className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4 cursor-pointer"
                  onClick={restartActivity}>
                  Restart Packing
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FbaShippingHandler;
