import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useEffect } from "react";
import ModalButton from "#components/utils/ModalButton";
import { XIcon } from "@heroicons/react/solid";
import ProgressBar from "./ProgressBar";
import PropTypes from "prop-types";

/**
 *
 * @param {string} title - Title of the modal
 * @param {string} negativeText - Text for the negative button
 * @param {string} positiveText - Text for the positive button
 * @param {string} validateText - Text for the validate button
 * @param {function} negativeAction - Function to be called when negative button is clicked
 * @param {function} positiveAction - Function to be called when positive button is clicked
 * @param {function} validateAction - Function to be called when validate button is clicked
 * @param {string} negativeClassname - Classname for the negative button
 * @param {string} positiveClassName - Classname for the positive button
 * @param {string} validateClassName - Classname for the validate button
 * @param {boolean} validate - Boolean to show validate button
 * @param {boolean} transparent - Boolean to make the negative button transparent
 * @param {boolean} specifybg - Boolean to specify the background color of the positive button
 * @param {boolean} noPadding - Boolean to remove padding from the modal
 * @param {boolean} progressBar - Boolean to show the progress bar
 * @param {number} percent - Number to specify the percentage of the progress bar
 * @param {string} style - Style for the positive button
 * @param {function} onClose - Function to be called when the modal is closed
 * @param {string} minWidth - Minimum width of the modal specified in pixels or relative units.
 * @param {string} maxWidth - Maximum width of the modal specified in pixels or relative units.
 * @param {string} minHeight - Minimum height of the modal specified in pixels or relative units.
 * @param {string} maxHeight - Maximum height of the modal specified in pixels or relative units.
 * @param {boolean} scrollWithin = Boolean to specify if the scroll behaviour needs to be within the modal and not with respect to page
 * @param {function} xIconClicked - Function to be called when the x icon is clicked
 * @param {ReactNode} children - Children of the modal
 * @param {string} ButtonStyles - Styles for the buttons
 *
 *
 * @returns {ReactNode} - Returns the modal
 * @example
 * <Modal
 * title="Modal Title"
 * negativeText="Cancel"
 * positiveText="Submit"
 * negativeAction={() => console.log("Negative Button Clicked")}
 * positiveAction={() => console.log("Positive Button Clicked")}
 * validateAction={() => console.log("Validate Button Clicked")}
 * negativeClassname="bg-red-500"
 * positiveClassName="bg-green-500"
 * validateClassName="bg-blue-500"
 * validate={true}
 * transparent={true}
 * specifybg={true}
 * >
 */
const Modal = ({
  title,
  negativeText,
  positiveText,
  negativeAction,
  positiveAction,
  children,
  transparent,
  ButtonStyles,
  negativeClassname,
  positiveClassName,
  validate = false,
  validateAction,
  validateClassName,
  validateText,
  noPadding,
  onClose,
  progressBar,
  percent,
  specifybg,
  style,
  minWidth = "640px",
  maxWidth = "1280px",
  minHeight,
  maxHeight,
  scrollWithin = false,
  xIconClicked,
  disabled,
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (scrollWithin && contentRef.current) {
      // Wait for the next frame to set the scroll position
      requestAnimationFrame(() => {
        contentRef.current.scrollTop = 0;
      });
    }
  }, []);

  const getClassesForChildren = () => {
    const classNames = [];
    if (!noPadding) classNames.push("mx-4 py-4");
    if (scrollWithin) classNames.push("overflow-y-auto");
    return classNames.join(" ").trim();
  };

  const getStylePropsForChildren = () => {
    const styles = {};
    if (scrollWithin) {
      // Set a max height for the content area to enable internal scrolling
      styles.maxHeight = "calc(100vh - 12rem)"; // Adjust the value as needed
      styles.overflowY = "auto";
    }
    if (minHeight) styles.minHeight = minHeight;
    if (maxHeight) styles.maxHeight = maxHeight;
    return styles;
  };

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto userFormModal"
          onClose={onClose ? onClose : negativeAction}
          initialFocus={null}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <div className="flex items-center justify-center min-h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div
                  className={`inline-block mx-auto overflow-visible text-left align-middle transition-all transform bg-white shadow-xl rounded-3xl border-blue-500`}
                  style={{ minWidth, maxWidth }}>
                  {progressBar && <ProgressBar percent={percent} />}
                  <div className="flex justify-center p-6 text-xl font-medium leading-6 text-primaryAccent border-b border-B3BFCA">
                    <h1 className="flex-1">{title}</h1>
                    <XIcon
                      className="w-6 h-6 cursor-pointer"
                      onClick={xIconClicked ? xIconClicked : negativeAction}
                    />
                  </div>
                  <div
                    ref={contentRef}
                    className={getClassesForChildren()}
                    style={getStylePropsForChildren()}>
                    {children}
                  </div>

                  <div
                    className={
                      ButtonStyles ||
                      `pt-4 w-full bg-primaryAccent p-4 flex items-center justify-center space-x-4 rounded-bl-3xl rounded-br-3xl`
                    }>
                    <ModalButton
                      onClick={negativeAction}
                      transparent={transparent || true}
                      className={negativeClassname}
                      text={negativeText || "Cancel"}
                      specifybg={specifybg}
                    />
                    {positiveAction && (
                      <ModalButton
                        onClick={positiveAction}
                        className={positiveClassName}
                        style={style}
                        specifybg={specifybg}
                        disabled={disabled}
                        text={positiveText || "Submit"}
                      />
                    )}
                    {validate && (
                      <ModalButton
                        onClick={validateAction}
                        className={validateClassName}
                        specifybg={true}
                        text={validateText}
                      />
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  negativeText: PropTypes.string,
  positiveText: PropTypes.string,
  negativeAction: PropTypes.func,
  positiveAction: PropTypes.func,
  children: PropTypes.node,
  transparent: PropTypes.bool,
  ButtonStyles: PropTypes.string,
  negativeClassname: PropTypes.string,
  positiveClassName: PropTypes.string,
  validate: PropTypes.bool,
  validateAction: PropTypes.func,
  validateClassName: PropTypes.string,
  validateText: PropTypes.string,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  progressBar: PropTypes.bool,
  percent: PropTypes.number,
  specifybg: PropTypes.bool,
  style: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  scrollWithin: PropTypes.bool,
  xIconClicked: PropTypes.func,
};

export default Modal;
