import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import HeaderWithoutSidebar from "#components/common/HeaderWithoutSidebar";
import LoginBox from "#components/login/LoginBox";
import useLoginLogic from "#hooks/login/useLoginLogic";
import { LOGOUT_USER, SET_ACTIVITY } from "#mutations";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import MultipleSelectActivityForm from "#components/common/MultipleSelectActivityForm";
import ResetPasswordBox from "#components/login/ResetPasswordBox";

const Login = () => {
  const logoutQuery = useQuery(LOGOUT_USER);
  const setActivityQuery = useQuery(SET_ACTIVITY);
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const { login, resetPassword } = useLoginLogic();
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);

  useEffect(() => {
    if (setActivityQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }

    if (setActivityQuery.error) {
      appState.setAlert(setActivityQuery.error.message, "error", 5000);
    }

    if (setActivityQuery.data) {
      window.location = "/";
    }
  }, [setActivityQuery.data, setActivityQuery.error, setActivityQuery.loading]);

  useEffect(() => {
    if (
      auth.user &&
      auth.user.role &&
      auth.user.role.toLowerCase() === "associate" &&
      auth.user.hopstackModules.includes(
        "Prepping",
        "Bundling",
        "Packing",
        "Outbound Sorting Overview",
        "Inbound Sorting Overview",
        "Picking",
        "Receiving",
        "Putaway",
        "Shipping",
      ) === false
    ) {
      // logoutQuery.fetchData();
    }
  }, [auth.user]);

  useEffect(() => {
    if (logoutQuery.data) {
      appState.setAlert(
        "You need to login from a mobile or PDA",
        "error",
        5000,
      );
      auth.logout();
    }

    if (logoutQuery.error) {
      auth.logout();
    }
  }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

  if (!!auth.isAuthenticated && !!auth.token) {
    const foundUser = auth.user;
    if (foundUser && foundUser.role && foundUser.role !== "ASSOCIATE") {
      return <Redirect to={initialRedirect(foundUser)} />;
    }
    if (
      foundUser.hopstackModules.findIndex((item) =>
        [
          "Prepping",
          "Bundling",
          "Packing",
          "Outbound Sorting Overview",
          "Inbound Sorting Overview",
          "Picking",
          "Picking",
          "Receiving",
          "Putaway",
          "Shipping",
        ].includes(item),
      ) === -1
    ) {
      // NEED TO LOGIN FROM A PDA
      return null;
    }

    const hopstackModules = foundUser.hopstackModules.filter((item) =>
      [
        "Prepping",
        "Bundling",
        "Packing",
        "Outbound Sorting Overview",
        "Inbound Sorting Overview",
        "Picking",
        "Picking",
        "Receiving",
        "Putaway",
        "Shipping",
      ].includes(item),
    );

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Packing") ||
      foundUser.activity === "Packing"
    ) {
      return <Redirect to="/packer" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Shipping") ||
      foundUser.activity === "Shipping"
    ) {
      return <Redirect to="/shipper" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Prepping") ||
      foundUser.activity === "Prepping"
    ) {
      return <Redirect to="/prepper" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Bundling") ||
      foundUser.activity === "Bundling"
    ) {
      return <Redirect to="/bundler" />;
    }

    if (
      (hopstackModules.length === 1 &&
        hopstackModules[0] === "Outbound Sorting Overview") ||
      foundUser.activity === "Outbound Sorting Overview"
    ) {
      return <Redirect to="/outboundSortingOverview" />;
    }

    if (
      (hopstackModules.length === 1 &&
        hopstackModules[0] === "Inbound Sorting Overview") ||
      foundUser.activity === "Inbound Sorting Overview"
    ) {
      return <Redirect to="/inboundSortingOverview" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Picking") ||
      foundUser.activity === "Picking"
    ) {
      return <Redirect to="/picker" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Receiving") ||
      foundUser.activity === "Receiving"
    ) {
      return <Redirect to="/receiver" />;
    }

    if (
      (hopstackModules.length === 1 && hopstackModules[0] === "Putaway") ||
      foundUser.activity === "Putaway"
    ) {
      return <Redirect to="/putaway" />;
    }

    return (
      <MultipleSelectActivityForm
        title={"You have multiple Roles. Please choose one."}
        selectedActivity={selectedActivity}
        onClose={() => logoutQuery.fetchData()}
        onSubmit={() => {
          if (selectedActivity) {
            setActivityQuery.fetchData({ activity: selectedActivity });
          } else {
            appState.setAlert("Please select an activity", "error", 5000);
          }
        }}
        hopstackModules={hopstackModules.map((item) => ({
          name: item,
        }))}
        onChangeDropdown={(e) => setSelectedActivity(e)}
      />
    );
  }
  if (!auth.isAuthenticated && !auth.user && !auth.loading) {
    return (
      <>
        <div className="h-screen w-full bg-primaryAccent flex items-center justify-center">
          {/* Header */}
          <HeaderWithoutSidebar tenant={appState.tenant} />
          {/*Center Box*/}
          {showResetPassword ? (
            <ResetPasswordBox
              values={resetPassword.values}
              onSubmit={(data) => {
                resetPassword.onSubmit(data);
                setShowResetPassword(false);
              }}
              resetPassword={() => setShowResetPassword(!showResetPassword)}
              onChange={resetPassword.onChange}
              tenant={appState.tenant}
            />
          ) : (
            <LoginBox
              values={login.values}
              onSubmit={login.onSubmit}
              resetPassword={() => setShowResetPassword(!showResetPassword)}
              onChange={login.onChange}
              tenant={appState.tenant}
            />
          )}
        </div>
        <footer className="-mt-8 w-full items-center justify-center flex text-white space-x-5">
          <div>
            Copyright ©
            <a href="http://hopstack.io" className="px-2" target="_blank">
              Hopstack Inc.
            </a>
            {new Date().getFullYear()}
          </div>
          <div></div>
          <div>
            <a
              className="no-underline"
              href="https://hopstack.io/privacy-policy">
              Privacy Policy
            </a>
          </div>
          <div>
            <a className="no-underline" href="https://hopstack.io/terms-of-use">
              Terms of Use
            </a>
          </div>
          <div>
            <a
              className="no-underline"
              href="https://hopstack.io/terms-of-service">
              Terms of Service
            </a>
          </div>
        </footer>
      </>
    );
  }
  return null;
};

const DEFAULT_ROUTE = "/dashboard";

// This function is used to redirect the user to the first page that they have access to
// If they have access to the dashboard, they will be redirected to the dashboard first
// If they do not have access to the dashboard, they will be redirected to the first page in the permissions array with readable=true
const initialRedirect = (user) => {
  if (user?.permissions?.length > 0) {
    if (
      user.permissions.findIndex(
        (i) => i.route.indexOf("/dashboard") !== -1 && i.readable,
      ) !== -1
    ) {
      return DEFAULT_ROUTE;
    }
    const readableRoute = user.permissions.find((page) => page.readable);
    if (readableRoute) return readableRoute.route;

    return DEFAULT_ROUTE;
  }

  return DEFAULT_ROUTE;
};

export default Login;
