import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import CurrencyInput from "../utils/CurrencyInput";

const MaterialForm = ({
  onClose,
  title,
  onChange,
  selectedMaterial,
  onSubmit,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Material Details
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedMaterial.name}
        />
      </div>
      <div>
        <TextField
          type="number"
          id="quantity"
          label="Quantity"
          placeholder=" "
          onChange={(e) =>
            onChange({
              target: {
                name: e.target.name,
                value: parseInt(e.target.value),
              },
            })
          }
          value={selectedMaterial.quantity}
          min="0"
        />
      </div>
      <div>
        {/** Using CurrencyInput for using decimal input functionality.
         * TODO: Rename CurrencyInput -> DecimalInput */}
        <CurrencyInput
          type="text"
          id="weight"
          label="Weight (KG)"
          value={selectedMaterial.weight || 1}
          onChange={(e) =>
            onChange({
              target: {
                name: "weight",
                value: e,
              },
            })
          }
        />
      </div>
      <div>
        <TextField
          type="text"
          id="dimensions"
          label="Dimensions"
          placeholder="Dimensions"
          onChange={onChange}
          value={selectedMaterial.dimensions}
        />
      </div>
      <div>
        <TextField
          type="number"
          id="threshold"
          label="Threshold"
          placeholder=" "
          onChange={(e) =>
            onChange({
              target: {
                name: e.target.name,
                value: parseInt(e.target.value),
              },
            })
          }
          value={selectedMaterial.threshold}
        />
      </div>
    </div>
  </Modal>
);

export default MaterialForm;
