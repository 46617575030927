import { useState, useEffect, useContext } from "react";

import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import TagsInput from "#components/catalogs/TagsInput";
import _ from "lodash";
import Toggle from "#components/utils/Toggle";
import { CheckIcon } from "@heroicons/react/outline";
import AddAttributes from "./AddAttributes";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import WizardProductSelector from "../utils/WizardProductSelector";
import { AppStateContext } from "#contexts/appState";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "../../constants/feature-flags";
import ProductImages from "./ProductImages";
import UOMConfiguration from "./UOMConfiguration";
import ReactTooltip from "react-tooltip";
import { Tenants } from "#constants/tenant";

const BundleAsProductForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedProduct,
  setSelectedProduct,
  onSubmit,
  warehouses,
  customers,
  productSizes,
  productShapes,
  productColors,
  productCategories,
  productsTypes,
  deleteImage,
  addBundleProduct,
  removeBundleProduct,
  availableInventory,
  onChangeInventorySearch,
  getMarketplaceProducts,
  customAttributes,
  setCustomAttributes,
  productHasInventory,
  tenant,
}) => {
  const appState = useContext(AppStateContext);
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);

  const ldClient = useLDClient();
  const isBranded =
    ldClient?.variation(featureFlags.IS_BRANDED, false) ?? false;

  const marketPlaces = (() => {
    // TO DO: have a separate api call to retrieve marketplace sources based on the user, tenant id.
    if (tenant?.id.toString() === Tenants.GBA) {
      return [{ name: "NetSuite", value: "NetSuite" }];
    }
    return [
      { name: "Hopstack", value: "Hopstack" },
      { name: "FBA", value: "FBA" },
      { name: "Amazon FBM", value: "FBM" },
      { name: "FBW", value: "FBW" },
      { name: "Shopify", value: "Shopify" },
      { name: "Other FBM", value: "Other" },
    ];
  })();

  useEffect(() => {
    let formedSteps = [];
    if (
      selectedProduct?.warehouseToBeSelected ||
      selectedProduct?.customerToBeSelected
    ) {
      formedSteps.push({
        id: "01",
        name: "Client Info",
        href: "#",
        status: "current",
      });
    }

    formedSteps = [
      ...formedSteps,
      { id: "02", name: "Basic Details", href: "#", status: "upcoming" },
      { id: "03", name: "Bundle Details", href: "#", status: "upcoming" },
      { id: "04", name: "Select Products", href: "#", status: "upcoming" },
      { id: "05", name: "Codes", href: "#", status: "upcoming" },
      { id: "06", name: "Attributes", href: "#", status: "upcoming" },
      { id: "07", name: "Unit of Measurement", href: "#", status: "upcoming" },
      { id: "08", name: "Images", href: "#", status: "upcoming" },
    ];

    formedSteps[0] = {
      ...formedSteps[0],
      status: "current",
    };
    setSteps(formedSteps);
    setSelectedStep(formedSteps[0]);
  }, []);

  const selectStep = (idx) => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    copiedSteps = copiedSteps.map((item) => ({
      ...item,
      status: "upcoming",
    }));

    copiedSteps[idx].status = "current";

    setSteps(copiedSteps);
    setSelectedStep(copiedSteps[idx]);
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  return (
    <Modal
      title={title}
      negativeAction={onClose}
      minWidth="1280px"
      onClose={() => {}}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Bundle Details
          </span>
        </div>
        <nav aria-label="Progress">
          <ol
            role="list"
            className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex md:flex-1">
                {step.status === "complete" ? (
                  <div className="group flex w-full items-center">
                    <span className="flex items-center px-6 py-4 text-md font-medium">
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                        <CheckIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      <span className="ml-4 text-lg font-medium text-gray-900">
                        {step.name}
                      </span>
                    </span>
                  </div>
                ) : step.status === "current" ? (
                  <div
                    className="flex items-center px-4 py-4 text-md font-medium"
                    aria-current="step">
                    <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                      <span className="text-indigo-600">
                        {(
                          steps.findIndex((i) => i.id === step.id) + 1
                        ).toString()}
                      </span>
                    </span>
                    <span className="ml-4 text-md font-medium text-indigo-600">
                      {step.name}
                    </span>
                  </div>
                ) : (
                  <div
                    className="group flex items-center"
                    onClick={() => selectStep(stepIdx)}>
                    <div className="flex items-center px-4 py-4 text-md font-medium">
                      <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">
                          {(
                            steps.findIndex((i) => i.id === step.id) + 1
                          ).toString()}
                        </span>
                      </span>
                      <span className="ml-4 text-md font-medium text-gray-500 group-hover:text-gray-900">
                        {step.name}
                      </span>
                    </div>
                  </div>
                )}

                {stepIdx !== steps.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div
                      className="absolute top-0 right-0 hidden h-full w-5 md:block"
                      aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none">
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
        {selectedStep && selectedStep.id === "01" && (
          <>
            {selectedProduct.customerToBeSelected && (
              <>
                <div>
                  <Dropdown
                    placeholder={"Select Client"}
                    list={getFilteredCustomerList(
                      selectedProduct.warehouse,
                      customers,
                    )}
                    labelKey="name"
                    valueKey="id"
                    name="customer"
                    setSelected={(selectedOption) => {
                      onChangeDropdown("customer", selectedOption);
                    }}
                    selectedValue={selectedProduct.customer}
                  />
                </div>
              </>
            )}
          </>
        )}
        {selectedStep && selectedStep.id === "02" && (
          <>
            <>
              <div className="flex space-x-4 items-center px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct.active}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "active",
                          value: e,
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Active</div>
              </div>
            </>
            <>
              <div>
                <Dropdown
                  placeholder={"Source"}
                  list={marketPlaces}
                  labelKey="name"
                  valueKey="value"
                  name="source"
                  setSelected={(e) => {
                    onChangeDropdown("source", e);
                  }}
                  selectedValue={selectedProduct.source}
                />
              </div>
            </>
          </>
        )}

        {selectedStep && selectedStep.id === "03" && (
          <>
            <div>
              <TextField
                type="text"
                id="name"
                label="Name"
                placeholder=" "
                onChange={onChange}
                value={selectedProduct.name}
                name="name"
              />
            </div>
            {["FBA", "FBM"].includes(selectedProduct.source) && (
              <>
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="ASIN"
                    placeholder=" "
                    onChange={onChange}
                    value={selectedProduct.asin}
                    name="asin"
                  />
                </div>
                {selectedProduct.source === "FBA" && (
                  <div>
                    <TextField
                      type="text"
                      id="name"
                      label="FN SKU"
                      placeholder=" "
                      onChange={onChange}
                      value={selectedProduct.fnSku}
                      name="fnSku"
                    />
                  </div>
                )}
              </>
            )}
            <div>
              <TextField
                type="text"
                id="name"
                label={
                  selectedProduct.source &&
                  selectedProduct.source?.indexOf("Amazon") !== -1
                    ? "Seller SKU"
                    : "SKU"
                }
                placeholder=" "
                onChange={onChange}
                value={selectedProduct.sku}
                name="sku"
                disabled={selectedProduct.id ? true : false}
              />
            </div>
            <div>
              <TextField
                type="text"
                id="desription"
                label="Bundle Description"
                placeholder=" "
                onChange={onChange}
                value={selectedProduct?.description}
                name="description"
              />
            </div>
            <div>
              <Dropdown
                placeholder={"Bundle Category"}
                list={
                  productCategories
                    ? [...productCategories, { name: "Add Custom" }]
                    : [{ name: "Add Custom" }]
                }
                labelKey="name"
                valueKey="name"
                name="category"
                setSelected={(selectedValue) => {
                  onChangeDropdown("category", selectedValue);
                }}
                selectedValue={selectedProduct?.category}
              />
            </div>
            <div>
              <Dropdown
                placeholder={"Bundle Type"}
                list={
                  productsTypes
                    ? [...productsTypes, { name: "Add Custom" }]
                    : [{ name: "Add Custom" }]
                }
                labelKey="name"
                valueKey="name"
                name="type"
                setSelected={(selectedValue) => {
                  onChangeDropdown("type", selectedValue);
                }}
                selectedValue={selectedProduct?.type}
              />
            </div>

            {isBranded && (
              <TagsInput
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            )}
          </>
        )}

        {selectedStep && selectedStep.id === "04" && (
          <>
            <div>
              <WizardProductSelector
                warehouses={warehouses}
                selectedEntity={{
                  ...selectedProduct,
                  items: selectedProduct.products,
                }}
                removeItem={removeBundleProduct}
                onChangeInventorySearch={onChangeInventorySearch}
                availableInventory={availableInventory}
                submitAddedProduct={addBundleProduct}
                getMarketplaceProducts={getMarketplaceProducts}
                isMultiFormFactor={false}
                addingProductCallback={(productBeingAdded) => {}}
                multipleFormFactors={false}
              />
            </div>
          </>
        )}

        {selectedStep && selectedStep.id === "05" && (
          <>
            <div>
              <TextField
                type="text"
                id="name"
                label="UPC(s)"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedProduct.upc
                    ? selectedProduct.upc.join(",")
                    : selectedProduct.upc
                }
                name="upc"
              />
              <span className="italic">Enter comma separated values</span>
            </div>
            <div>
              <TextField
                type="text"
                id="name"
                label="EAN(s)"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedProduct.ean
                    ? selectedProduct.ean.join(",")
                    : selectedProduct.ean
                }
                name="ean"
              />
              <span className="italic">Enter comma separated values</span>
            </div>
            <div>
              <TextField
                type="text"
                id="name"
                label="LPN(s)"
                placeholder=" "
                onChange={onChange}
                value={
                  selectedProduct.lpn
                    ? selectedProduct.lpn.join(",")
                    : selectedProduct.lpn
                }
                name="lpn"
              />
              <span className="italic">Enter comma separated values</span>
            </div>
          </>
        )}

        {selectedStep && selectedStep.id === "06" && (
          <>
            <>
              <div className="flex space-x-4 items-center px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct?.attributes?.perishable}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "perishable",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Perishable</div>
              </div>
            </>
            <>
              <div className="flex space-x-4 items-center px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct?.attributes?.lotIdRequired}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "lotIdRequired",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Lot ID/Batch ID required</div>
              </div>
            </>
            <>
              <div className="flex space-x-4 items-center px-2">
                <div data-for={"serialNumberRequired"} data-tip={true}>
                  <Toggle
                    enabled={selectedProduct?.attributes?.serialNumberRequired}
                    disabled={productHasInventory}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "serialNumberRequired",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                  {productHasInventory && (
                    <ReactTooltip
                      id="serialNumberRequired"
                      place="top"
                      type="info"
                      effect="solid">
                      <div className="text-xl max-w-xl whitespace-normal">
                        Cannot make changes to Serial Number requirement while
                        the product has inventory.
                      </div>
                    </ReactTooltip>
                  )}
                </div>
                <div className="text-lg">Serial Number Required</div>
              </div>
            </>
            <>
              <div className="flex space-x-4 items-center px-2">
                <div>
                  <Toggle
                    enabled={selectedProduct?.attributes?.expiryDateRequired}
                    setEnabled={(e) =>
                      onChange({
                        target: {
                          name: "expiryDateRequired",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                  />
                </div>
                <div className="text-lg">Expiry Date required</div>
              </div>
            </>
            <>
              <div className="flex space-x-4 items-center px-2">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Weight"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "weight",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.weight}
                    name="weight"
                  />
                </div>
                <div>
                  <Dropdown
                    placeholder={"Unit"}
                    list={[
                      { name: "Pounds" },
                      { name: "Kilograms" },
                      { name: "Ounces" },
                      { name: "Litres" },
                    ]}
                    labelKey="name"
                    valueKey="name"
                    name="weightMeasure"
                    setSelected={(e) =>
                      onChange({
                        target: {
                          name: "weightMeasure",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                    selectedValue={selectedProduct.attributes?.weightMeasure}
                  />
                </div>
              </div>
            </>
            <>
              <div className="flex space-x-4 items-center px-2">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Length"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "length",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.length}
                    name="length"
                  />
                </div>

                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Width"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "width",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.width}
                    name="width"
                  />
                </div>

                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Height"
                    placeholder=" "
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "height",
                          value:
                            isNaN(parseFloat(e.target.value)) === false
                              ? parseFloat(e.target.value)
                              : null,
                          type: "attribute",
                        },
                      })
                    }
                    value={selectedProduct.attributes?.height}
                    name="height"
                  />
                </div>
                <div>
                  <Dropdown
                    placeholder={"Unit"}
                    list={[
                      { name: "Inches" },
                      { name: "Centimetres" },
                      { name: "Metres" },
                      { name: "Feet" },
                    ]}
                    labelKey="name"
                    valueKey="name"
                    name="dimensionsUnit"
                    setSelected={(e) =>
                      onChange({
                        target: {
                          name: "dimensionsUnit",
                          value: e,
                          type: "attribute",
                        },
                      })
                    }
                    selectedValue={selectedProduct.attributes?.dimensionsUnit}
                  />
                </div>
              </div>
              <div>
                <Dropdown
                  placeholder={"Select Size"}
                  list={
                    productSizes
                      ? [...productSizes, { name: "Add Custom" }]
                      : [{ name: "Add Custom" }]
                  }
                  labelKey="name"
                  valueKey="name"
                  name="size"
                  setSelected={(e) => {
                    onChangeDropdown("size", e);
                  }}
                  selectedValue={selectedProduct.attributes?.size}
                />
              </div>
              <div>
                <Dropdown
                  placeholder={"Select Shape"}
                  list={
                    productShapes
                      ? [...productShapes, { name: "Add Custom" }]
                      : [{ name: "Add Custom" }]
                  }
                  labelKey="name"
                  valueKey="name"
                  name="shape"
                  setSelected={(e) => {
                    onChangeDropdown("shape", e);
                  }}
                  selectedValue={selectedProduct.attributes?.shape}
                />
              </div>
              <div>
                <Dropdown
                  placeholder={"Select Color"}
                  list={
                    productColors
                      ? [...productColors, { name: "Add Custom" }]
                      : [{ name: "Add Custom" }]
                  }
                  labelKey="name"
                  valueKey="name"
                  name="color"
                  setSelected={(e) => {
                    onChangeDropdown("color", e);
                  }}
                  selectedValue={selectedProduct.attributes?.color}
                />
              </div>
              <AddAttributes
                customAttributes={customAttributes}
                setCustomAttributes={setCustomAttributes}
              />
            </>
          </>
        )}
        {selectedStep && selectedStep.id === "08" && (
          <ProductImages
            product={selectedProduct}
            onChange={onChange}
            deleteImage={deleteImage}
          />
        )}
        {selectedStep && selectedStep.id === "07" && (
          <UOMConfiguration
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            appState={appState}
          />
        )}
      </div>
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination">
        <div className="flex flex-1 justify-between sm:justify-end">
          <div className="flex flex-1 justify-between sm:justify-end space-x-2">
            <div
              onClick={onClose}
              className="cursor-pointer bg-red-600 inline-flex items-center text-lg justify-center px-2 py-2 text-md text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-red-700">
              Cancel
            </div>
            <div
              onClick={!appState.loading ? onSubmit : () => {}}
              className="cursor-pointer bg-primaryAccent inline-flex items-center text-lg justify-center px-2 py-2 text-md text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none">
              Submit
            </div>
            <div
              onClick={setPrevStep}
              className={`cursor-pointer  inline-flex items-center text-lg justify-center px-2 py-2 text-md text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ${
                selectedStep?.id === steps[0]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"< Previous"}
            </div>
            <div
              onClick={setNextStep}
              className={`cursor-pointer  inline-flex items-center text-lg justify-center px-2 py-2 text-md text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ${
                selectedStep?.id === steps[steps.length - 1]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"Next >"}
            </div>
          </div>
        </div>
      </nav>
    </Modal>
  );
};
export default BundleAsProductForm;
