import { useState, useEffect, useContext } from "react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

import { AppStateContext } from "../../contexts/appState";

export const ALERT_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
};

const ContainerWithLoaderAndAlert = ({ children, alert }) => {
  const appState = useContext(AppStateContext);

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-12 w-12" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-12 w-12" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-12 w-12" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }

    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] p-4 rounded-md flex items-center border-[${borderColor}] border-2`}>
        <span className="mr-2 flex-1 flex items-center text-xl space-x-2">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="text-[#717679] font-bold float-right flex items-center justify-center"
          onClick={() => appState.removeDismissableAlert(showAlert.id)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };

  return (
    <div className="relative py-4">
      {appState?.dismissableAlerts?.length > 0 && (
        <div className="space-y-4">
          {appState.dismissableAlerts.map(renderAlert)}
        </div>
      )}
      {children}
    </div>
  );
};

export default ContainerWithLoaderAndAlert;
