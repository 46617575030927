import React, { useState, useEffect } from "react";
import Stats from "#components/common/Stats";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import OrderCycleTimeRow from "#components/dashboard/OrderCycleTimeRow";
import OrderLeadTimeRow from "#components/dashboard/OrderLeadTimeRow";
import OrderReturnRateRow from "#components/dashboard/OrderReturnRateRow";
import OrderFillRateRow from "#components/dashboard/OrderFillRateRow";
import TotalOrdersRow from "#components/dashboard/TotalOrdersRow";

const OrderStats = ({ dateRange, tenantId, customers, warehouses }) => {
  const [ordersCreatedCount, setOrdersCreatedCount] = useState(0);
  return (
    <>
      <Stats
        grid={"orders"}
        stats={[
          {
            name: "Created",
            query: {
              measures: ["orders.total_orders_count"],
              timeDimensions: [
                {
                  dimension: "orders.createdat",
                  dateRange,
                },
              ],
              order: {
                "orders.createdat": "asc",
              },
              filters: [
                {
                  member: "orders.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "orders.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "orders.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
            setQueryResult: setOrdersCreatedCount,
          },
          {
            name: "Unprocessed",
            query: {
              measures: ["orders.unprocessed_count"],
              timeDimensions: [
                {
                  dimension: "orders.createdat",
                  dateRange: dateRange,
                },
              ],
              order: {
                "orders.createdat": "asc",
              },
              filters: [
                {
                  member: "orders.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "orders.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "orders.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
          },
          {
            name: "Completed",
            query: {
              measures: ["orders.completed_count"],
              timeDimensions: [
                {
                  dimension: "orders.completedDate",
                  dateRange: dateRange,
                },
              ],
              order: {
                "orders.createdat": "asc",
              },
              filters: [
                {
                  member: "orders.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "orders.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "orders.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
          },
          {
            name: "Exceptions",
            query: {
              measures: ["orders.exceptions_count"],
              timeDimensions: [
                {
                  dimension: "orders.createdat",
                  dateRange,
                },
              ],
              order: {
                "orders.createdat": "asc",
              },
              filters: [
                {
                  member: "orders.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "orders.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "orders.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
            },
          },
          {
            name: "24 Hour Fulfillment",
            query: {
              measures: ["orders.fulfilled_in_24_hours"],
              timeDimensions: [
                {
                  dimension: "orders.createdat",
                  dateRange,
                },
              ],
              order: {},
              filters: [
                {
                  member: "orders.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "orders.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "orders.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
              dimensions: [],
            },
            display: (value) => {
              return ordersCreatedCount
                ? `${((value / ordersCreatedCount) * 100).toFixed(
                    2
                  )}% (${value})`
                : `${value}`;
            },
          },
          {
            name: "48 Hour Fulfillment",
            query: {
              measures: ["orders.fulfilled_in_48_hours"],
              timeDimensions: [
                {
                  dimension: "orders.createdat",
                  dateRange,
                },
              ],
              order: {},
              filters: [
                {
                  member: "orders.tenant",
                  operator: "equals",
                  values: [tenantId],
                },
                {
                  member: "orders.customer",
                  operator: "equals",
                  values: customers,
                },
                {
                  member: "orders.warehouse",
                  operator: "equals",
                  values: warehouses,
                },
              ],
              dimensions: [],
            },
            display: (value) => {
              return ordersCreatedCount
                ? `${((value / ordersCreatedCount) * 100).toFixed(
                    2
                  )}% (${value})`
                : `${value}`;
            },
          },
        ]}
      />

      <div className="flex-col space-y-8">
        <OrderCycleTimeRow
          dateRange={dateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
        <OrderLeadTimeRow
          dateRange={dateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
        <OrderReturnRateRow
          dateRange={dateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      </div>
    </>
  );
};

export default OrderStats;
