import TextField from "#components/utils/TextField";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useState } from "react";
import passwordStrengthValidator from "#utils/passwordStrengthValidator";

const NewPasswordBox = ({ onSubmit, onChange, values, isExpired }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const renderPasswordStrengthCriteria = () => {
    const returnArr = [];

    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testPasswordLength(
            newPassword,
          )} text-lg`}>
          Password should be a minimum of 8 characters
        </span>{" "}
        <br />
      </>,
    );
    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testSequentialPassword(
            newPassword,
          )} text-lg`}>
          Password can't be all the same letter or number.
        </span>{" "}
        <br />
      </>,
    );
    returnArr.push(
      <>
        <span
          className={`${passwordStrengthValidator.testPasswordMatch(
            newPassword,
            values.password,
          )} text-lg`}>
          Passwords must match
        </span>{" "}
        <br />
      </>,
    );

    return returnArr;
  };

  return (
    <div className="bg-white xs:10/12 sm:w-8/12 md:6/12 max-w-xl min-w-xl rounded-2xl p-10 flex flex-col space-y-6 items-center justify-center">
      <div className="text-primaryAccent text-2xl font-medium">
        Reset Password
      </div>
      {isExpired ? (
        <div className="text-454A4F text-xl font-medium">
          Oops! This link is either invalid or expired. Please contact admin.
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            passwordStrengthValidator.testPassword(
              newPassword,
              values.password,
            ) && onSubmit(e);
          }}
          className="w-full flex-col space-y-6 items-center justify-center"
          noValidate>
          <div className="flex gap-2">
            <TextField
              label="Password"
              id="newPassword"
              type={showPassword ? "text" : "password"}
              placeholder=" "
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <div className="flex items-center justify-center">
              {showPassword ? (
                <EyeIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <TextField
              label="Confirm Password"
              id="password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder=" "
              required
              onChange={onChange}
            />
            <div className="flex items-center justify-center">
              {showConfirmPassword ? (
                <EyeIcon
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="h-7 w-14 cursor-pointer"
                />
              )}
            </div>
          </div>
          <div className="mt-2">{renderPasswordStrengthCriteria()}</div>
          <div className="w-full flex items-center justify-center">
            <input
              type="submit"
              className={`bg-primaryAccent cursor-pointer inline-flex justify-center p-4 text-2xl font-normal text-textWhite font-montserrat border border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none w-64`}
              value="Submit"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default NewPasswordBox;
