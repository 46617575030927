import withBinLocationsLogic from "#components/HOC/withBinLocationsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import BinLocationsList from "#components/binLocations/BinLocationsList";
import BinLocationForm from "#components/binLocations/BinLocationForm";
import EmptyBinLocationForm from "#components/binLocations/EmptyBinLocationForm";
import { PrinterIcon, UserAddIcon } from "@heroicons/react/outline";
import CustomEntityForm from "#components/common/CustomEntityForm";
import Pagination from "#components/common/Pagination";
import BinLocationFilters from "#components/binLocations/BinLocationFilters";
import BulkEditBinLocationForm from "#components/binLocations/BulkEditBinLocationForm";
import { PencilAltIcon } from "@heroicons/react/solid";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import PrintSettingsModal from "#components/common/PrintSettingsModal";

const BinLocations = ({
  masterData,
  displayBinLocations,
  selectedBinLocation,
  setSelectedBinLocation,
  fetchBinLocation,
  saveBinLocation,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  devices,
  onChangeMultiSelect,
  setEmptyBinLocation,
  emptyBinLocation,
  saveEmptyBinLocation,
  selectedBinLocations,
  selectBinLocation,
  selectedPrints,
  setSelectedPrints,
  printSelectedBarcodes,
  queueMultiplePrints,
  queueSinglePrint,
  selectAllBinLocations,
  allBinLocationsSelected,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  addCustomEntity,
  setAddCustomEntity,
  onSubmitCustomEntity,
  locationTypes,
  addBlankBinLocation,
  addBlankEmptyBinLocation,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeMultiSelectFilter,
  writable,
  bulkEditBinlocations,
  selectedBulkEditBinLocations,
  setSelectedBulkEditBinLocations,
  saveBulkEditBinLocations,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
}) => (
  <div className="p-5 w-full">
    <div id="previewBarcode"></div>
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Location Management</PageTitle>
      </div>

      {selectedBinLocations.length === 0 && (
        <div className="items-center justify-end flex space-x-2 mb-4">
          <AddButton
            text="Add Location"
            onClick={addBlankBinLocation}
            icon={UserAddIcon}
            disabled={!writable}
          />
          <AddButton
            text="Add Multiple Locations"
            onClick={addBlankEmptyBinLocation}
            icon={UserAddIcon}
            disabled={!writable}
          />
          <AddButton
            text="Download Template"
            onClick={() =>
              (window.location =
                "https://templates-onboarding.s3.amazonaws.com/prod/Locations.xlsx")
            }
            disabled={!writable}
          />
          <UploadBulk
            dashboardFields={dashboardFields}
            saveBulkUpload={saveBulkUpload}
            errorMessage={errorMessage}
            successMessage={successMessage}
            validate={validate}
            validationResult={validationResult}
          />
        </div>
      )}
      {selectedBinLocations.length > 0 && (
        <>
          <div className="items-center justify-end flex space-x-2 mb-4">
            <div className="items-center justify-end flex space-x-2 mb-4">
              <AddButton
                text="Print"
                onClick={queueMultiplePrints}
                icon={PrinterIcon}
              />
              <AddButton
                text="Edit"
                onClick={bulkEditBinlocations}
                icon={PencilAltIcon}
              />
            </div>
          </div>
        </>
      )}
    </div>

    <BinLocationsList
      binLocations={displayBinLocations}
      editButtonClicked={(e) => {
        fetchBinLocation(e.id);
      }}
      deleteButtonClicked={(e) => deleteButtonClicked(e)}
      masterData={masterData}
      headers={["Location Type", "Barcode", "Warehouse", "Action"]}
      noValuesText="No Locations"
      selectedBinLocations={selectedBinLocations}
      selectBinLocation={selectBinLocation}
      queueSinglePrint={queueSinglePrint}
      selectAllBinLocations={selectAllBinLocations}
      allBinLocationsSelected={allBinLocationsSelected}
      warehouses={warehouses}
      customers={customers}
      onChangeSearchKeyword={onChangeSearchKeyword}
      filters={filters}
      submitFilters={submitFilters}
      clearKeyword={clearKeyword}
      setSort={setSort}
      sort={sort}
      setShowFilters={setShowFilters}
      showFilters={showFilters}
      clearFilters={clearFilters}
      onChangeFilter={onChangeFilter}
      writable={writable}
    />

    <Pagination
      showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
      showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
      showingTotal={total}
      perPage={perPage}
      setPerPage={setPerPage}
      pageNumber={pageNumber}
      checkPagination={checkPagination}
    />
    {selectedPrints && (
      <PrintSettingsModal
        setSelectedPrints={setSelectedPrints}
        selectedPrints={selectedPrints}
        printSelectedBarcodes={printSelectedBarcodes}
      />
    )}

    {showFilters && (
      <BinLocationFilters
        negativeAction={() => setShowFilters(false)}
        onChangeFilter={onChangeFilter}
        filters={filters}
        onSubmit={submitFilters}
        onChangeDropdown={onChangeDropdown}
        warehouses={warehouses}
        customers={customers}
        locationTypes={locationTypes}
        onChangeMultiSelect={onChangeMultiSelectFilter}
      />
    )}

    {selectedBinLocation && (
      <BinLocationForm
        title={selectedBinLocation.id ? "Edit Location" : "Add Location"}
        selectedBinLocation={selectedBinLocation}
        onChange={onChange}
        onClose={() => setSelectedBinLocation(null)}
        onSubmit={() => saveBinLocation(selectedBinLocation)}
        hopstackModules={masterData?.hopstackModules}
        onChangeDropdown={onChangeDropdown}
        devices={devices}
        onChangeMultiSelect={onChangeMultiSelect}
        customers={customers}
        warehouses={warehouses}
        locationTypes={locationTypes}
      />
    )}

    {emptyBinLocation && (
      <EmptyBinLocationForm
        title={"Add Location"}
        selectedBinLocation={emptyBinLocation}
        onChange={(e) => onChange(e, "emptyBinLocation")}
        onClose={() => setEmptyBinLocation(null)}
        onSubmit={() => saveEmptyBinLocation(emptyBinLocation)}
        onChangeDropdown={(field, value) =>
          onChangeDropdown(field, value, "emptyBinLocation")
        }
        onChangeMultiSelect={onChangeMultiSelect}
        customers={customers}
        warehouses={warehouses}
        locationTypes={locationTypes}
      />
    )}

    {selectedBulkEditBinLocations && (
      <BulkEditBinLocationForm
        title={"Edit Location(s)"}
        selectedBulkEditBinLocations={selectedBulkEditBinLocations}
        setSelectedBulkEditBinLocations={setSelectedBulkEditBinLocations}
        onClose={() => setSelectedBulkEditBinLocations(null)}
        onSubmit={() => saveBulkEditBinLocations(selectedBulkEditBinLocations)}
        customers={customers}
        warehouses={warehouses}
        locationTypes={locationTypes}
      />
    )}

    {addCustomEntity && (
      <CustomEntityForm
        addCustomEntity={addCustomEntity}
        setAddCustomEntity={setAddCustomEntity}
        onSubmit={onSubmitCustomEntity}
      />
    )}
  </div>
);

export default withBinLocationsLogic(BinLocations);
