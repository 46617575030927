import { Popover } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";

const InformationIcon = ({ message }) => (
  <Popover className="relative bg-white">
    <Popover.Button>
      <InformationCircleIcon className="text-yellow-600 text-xs" width={30} />
    </Popover.Button>

    <Popover.Panel className="absolute z-50 bg-white">
      <div className="p-4 w-48 bg-white border border-gray-600">
        <div>{message}</div>
      </div>
    </Popover.Panel>
  </Popover>
);

export default InformationIcon;
