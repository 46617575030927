import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  usePDF,
  pdf,
  Font,
  View,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import _ from "lodash";

const BolDoc = ({
  id,
  date,
  pickupDate,
  deliveryDate,
  orderNo,
  weight,
  orderLineItems,
  shippingAddress,
  order,
  tenant,
  thirdPartyFreightChargesBill,
}) => {
  return (
    <div
      className="bg-white font-verdana p-4"
      id={id}
      style={{ width: 1240, height: 1754 }}>
      <div className="flex justify-evenly items-start mb-4">
        <div className="text-left">Date {date}</div>
        <div className="flex-1 text-center font-bold">
          BILL OF LADING - SHORT FORM - NOT NEGOTIABLE
        </div>
        <div className="text-right">Page 1 of 1</div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1">
          <div className="bg-gray-200 text-center w-full h-12 items-center justify-center pt-2 font-bold text-black border-b border-black">
            SHIP FROM
          </div>
          <div className="p-2 h-20">
            {tenant?.address?.name}
            <br />
            {tenant?.address?.line1}, {tenant?.address?.line2}
            <br />
            {tenant?.address?.city}, {tenant?.address?.state}{" "}
            {tenant?.address?.zip}
          </div>
        </div>
        <div className="border-black border flex-1">
          <div className="ml-2 pt-2 font-bold">
            Bill of Lading Number: {order?.bolNumber}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1">
          <div className="bg-gray-200 text-center w-full h-12 items-center justify-center pt-2 font-bold text-black border-b border-black">
            SHIP TO
          </div>
          <div className="p-2 h-20">
            {shippingAddress?.name}
            <br />
            {shippingAddress?.line1} {shippingAddress?.line2}
            <br />
            {shippingAddress?.city}, {shippingAddress?.state}{" "}
            {shippingAddress?.zip}
          </div>
        </div>
        <div className="border-black border flex-1">
          <div className="ml-2 pt-2 font-bold">Carrier Name:</div>
          <div className="ml-2 pt-4 font-bold">PICK UP DATE: {pickupDate}</div>
          <div className="ml-2 font-bold">DELIVER: {deliveryDate}</div>
        </div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1">
          <div className="bg-gray-200 text-center w-full h-12 items-center justify-center pt-2 font-bold text-black border-b border-black">
            THIRD PARTY FREIGHT CHARGES BILL TO
          </div>
          <div className="p-2 h-20">{thirdPartyFreightChargesBill}</div>
        </div>
        <div className="border-black border flex-1">
          <div className="ml-2 pt-2 font-bold">SCAC: {order?.scacCode}</div>
          <div className="ml-2 pt-4 font-bold">TRAILER:</div>
          <div className="ml-2 pt-4 font-bold">
            SEAL#: {order?.bolSealNumber}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1">
          <div className="p-2 h-20">
            <b>SPECIAL INSTRUCTIONS:</b> <br />
            <br />
            <b>Customer Order No: ASN: {orderNo}</b>
          </div>
        </div>
        <div className="border-black border flex-1">
          <div className="p-2 border-b border-black">
            <b>
              Freight Charge Terms (Freight charges are prepaid unless marked
              otherwise):
            </b>
            <br />
            <input type="checkbox" /> Prepaid{" "}
            <input type="checkbox" className="ml-2" /> Collect
            <input type="checkbox" className="ml-2" /> 3rd Party
          </div>
          <div className="p-2">
            <input type="checkbox" /> Master bill of lading with attached
            underlying bills of lading
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1">
          <div className="bg-gray-200 text-center w-full h-12 items-center justify-center pt-2 font-bold text-black border-b border-black">
            CUSTOMER ORDER INFORMATION
          </div>
          <div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  # of Packages
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  Weight
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  Pallet/Slip
                  <br />
                  (circle one)
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  Additional Information
                </div>
              </div>
            </div>
            {orderLineItems &&
              orderLineItems.map((item, idx) => (
                <div className="flex" key={idx}>
                  <div className="flex w-full justify-evenly">
                    <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                      {item.sku}
                    </div>
                    <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                      {item.quantity}
                    </div>
                    <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                      {item?.attributes?.weight}
                    </div>
                    <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                      Y | N
                    </div>
                    <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                  </div>
                </div>
              ))}

            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  {""}
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  Y | N
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
              </div>
            </div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  <b>Grand Total</b>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  <b>{_.sumBy(orderLineItems, "quantity")} Pallets</b>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  <b>{weight}</b>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1">
          <div className="bg-gray-200 text-center w-full h-12 items-center justify-center pt-2 font-bold text-black border-b border-black">
            CARRIER INFORMATION
          </div>
          <div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  <b>Handling Unit</b>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  <b>Package</b>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 block text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  <b>LTL Only</b>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Qty
                  </div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Type
                  </div>
                </div>
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Qty
                  </div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Type
                  </div>
                </div>
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Weight
                  </div>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                  <div className="block">
                    <b>Commodity Description </b>
                  </div>
                  <div className="p-1" style={{ fontSize: 8 }}>
                    Commodities requiring special or additional care or
                    attention in handling or stowing must be so marked and
                    packaged as to ensure safe transportation with ordinary
                    care. See Section 2(e) of NMFC item 360
                  </div>
                </div>
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    NMFC No.
                  </div>
                </div>
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Class
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="w-full flex justify-evenly">
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Cartons
                  </div>
                </div>
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    Pallet
                  </div>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
              </div>
            </div>
            <div className="flex">
              <div className="w-full flex justify-evenly">
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    <div className="invisible">Pallet</div>
                  </div>
                </div>
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
              </div>
            </div>
            <div className="flex">
              <div className="w-full flex justify-evenly">
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0 font-bold">
                    Cartons
                  </div>
                </div>
                <div className="flex-1 flex justify-evenly">
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0">
                    {_.sumBy(orderLineItems, "quantity")}
                  </div>
                  <div className="flex-1 text-center items-center justify-center border border-black border-l-0 font-bold">
                    Pallets
                  </div>
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0 font-bold">
                  {weight}
                </div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
                <div className="flex-1 text-center items-center justify-center border border-black border-l-0"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-evenly items-start mb-4 pt-4">
        <div className="flex-1 text-md">
          Where the rate is dependent on value, shippers are required to state
          specifically in writing the agreed or declared value of the property
          as follows: “The agreed or declared value of the property is
          specifically stated by the shipper to be not exceeding _____________
          per ______________.
        </div>
        <div className="flex-1 pl-4">
          <b>COD Amount: $ _______________________</b>
          <br />
          <br />
          <b className="pr-2">Fee Terms:</b> Collect
          <input type="checkbox" /> Prepaid <input type="checkbox" /> Customer
          check acceptable
          <input type="checkbox" />
          <br />
        </div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1 pb-4">
          <div className="bg-white text-center w-full items-center justify-center py-2 font-bold text-black border-b border-black text-sm">
            Note: Liability Information for loss or damage in this shipment may
            be applicable. See 49 USC § 14706(c)(1)(A) and (B).
          </div>
          <div className="flex">
            <div className="flex-1 text-sm p-2">
              Received, subject to individually determined rates or contracts
              that have been agreed upon in writing between the carrier and
              shipper, if applicable, otherwise to the rates, classifications,
              and rules that have been established by the carrier and are
              available to the shipper, on request, and to all applicable state
              and federal regulations.
            </div>
            <div className="flex-1">
              The carrier shall not make delivery of this shipment without
              payment of charges and all other lawful fees.
              <br />
              <b style={{ verticalAlign: -10 }}>
                Consignee Signature _______________
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="border-black border flex-1">
          <div className="flex">
            <div className="flex-1 p-2 font-bold">
              Shipper Signature / Date
              <br />
              <br />
              __________________________________
              <br />
              <span className="text-xs font-normal">
                This is to certify that the above named materials are properly
                classified, packaged, marked, and labeled, and are in proper
                condition for transportation according to the applicable
                regulations of the DOT.
              </span>
            </div>
            <div className="flex-1 border-l border-r border-black p-2">
              <b>Trailer Loaded:</b>
              <br />
              <input type="checkbox" /> By Shipper
              <br />
              <input type="checkbox" /> By driver
              <br />
            </div>
            <div className="flex-1 border-l border-r border-black p-2">
              <b>Freight Counted:</b>
              <br />
              <input type="checkbox" /> By Shipper
              <br />
              <input type="checkbox" /> By driver/pallets said to contain
              <br />
              <input type="checkbox" /> By driver/pieces
              <br />
            </div>
            <div className="flex-1 p-1 font-bold">
              Carrier Signature / Pickup Date
              <br />
              <br />
              __________________________________
              <br />
              <span className="text-sm font-normal">
                Carrier acknowledges receipt of packages and required placards.
                Carrier certifies emergency response information was made
                available and/or carrier has the DOT emergency response
                guidebook or equivalent documentation in the vehicle. Property
                described above is received in good order, except as noted.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BolDoc;
