const CheckboxInput = ({ text, name, value, onChange }) => {
  return (
    <div className="flex">
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-1 form-checkbox rounded text-primaryAccent focus:ring-0 focus:ring-primaryAccent"
        checked={value}
      />
      <label
        for={name}
        className="ml-2 text-sm font-normal leading-6 tracking-normal text-left">
        {text}
      </label>
    </div>
  );
};

export { CheckboxInput };
