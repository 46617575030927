import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_BUNDLE_PUTAWAY_TASK,
  SCAN_BUNDLE_PUTAWAY_ITEM,
  COMPLETE_BUNDLE_PUTAWAY_ITEM,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const withBundlePutawayLogic = (WrappedComponent) => {
  return (props) => {
    // contexts declarations
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);

    // state declarations
    const [currentTask, setCurrentTask] = useState(null);
    const [currentSku, setCurrentSku] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [customer, setCustomer] = useState(null);

    // query declarations
    const currentBundlePutawayTaskQuery = useQuery(GET_BUNDLE_PUTAWAY_TASK);
    const scanBundlePutawayItemQuery = useQuery(SCAN_BUNDLE_PUTAWAY_ITEM);
    const completeBundlePutawayItemQuery = useQuery(
      COMPLETE_BUNDLE_PUTAWAY_ITEM,
    );

    useEffect(() => {
      if (warehouse && customer) {
        currentBundlePutawayTaskQuery.fetchData({ warehouse, customer });
      }
    }, [warehouse, customer]);

    // to get current bundle putaway task.
    useEffect(() => {
      currentBundlePutawayTaskQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (currentBundlePutawayTaskQuery.data) {
        const task = currentBundlePutawayTaskQuery.data.getBundlePutawayTask;

        setCurrentTask(task);
        setCurrentSku(task?.currentItem ?? null);
      }

      if (currentBundlePutawayTaskQuery.error) {
        appState.removeLoading();
      }
    }, [
      currentBundlePutawayTaskQuery.loading,
      currentBundlePutawayTaskQuery.data,
      currentBundlePutawayTaskQuery.error,
    ]);

    // to scan an item (sku / bin)
    useEffect(() => {
      scanBundlePutawayItemQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (scanBundlePutawayItemQuery.data) {
        const response = scanBundlePutawayItemQuery.data.scanBundlePutawayItem;

        if (response.type === "BUNDLE_PUTAWAY_CHILD") {
          // when they initially scan a sku with form factor, we receive the task in the response.
          setCurrentTask(response);
          setCurrentSku(response.currentItem);
        } else {
          appState.setAlert(
            scanBundlePutawayItemQuery.data.scanBundlePutawayItem.message,
            "success",
            2000,
          );
          currentBundlePutawayTaskQuery.fetchData({ warehouse, customer });
        }
      }

      if (scanBundlePutawayItemQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          scanBundlePutawayItemQuery.error.message,
          "danger",
          5000,
        );
      }
    }, [
      scanBundlePutawayItemQuery.loading,
      scanBundlePutawayItemQuery.data,
      scanBundlePutawayItemQuery.error,
    ]);

    // to complete bundle putaway item.
    useEffect(() => {
      completeBundlePutawayItemQuery.loading
        ? appState.setLoading()
        : appState.removeLoading();

      if (
        completeBundlePutawayItemQuery.data &&
        completeBundlePutawayItemQuery.data.completeBundlePutawayItem.message
      ) {
        appState.setAlert(
          completeBundlePutawayItemQuery.data.completeBundlePutawayItem.message,
          "success",
          2000,
        );

        currentBundlePutawayTaskQuery.fetchData({ warehouse, customer });
      }

      if (completeBundlePutawayItemQuery.error) {
        appState.removeLoading();
        appState.setAlert(
          completeBundlePutawayItemQuery.error.message,
          "danger",
          5000,
        );
      }
    }, [
      completeBundlePutawayItemQuery.loading,
      completeBundlePutawayItemQuery.data,
      completeBundlePutawayItemQuery.error,
    ]);

    const submitWarehouseCustomerCode = ({ warehouse, customer }) => {
      if (!warehouse || !warehouse.value) {
        return appState.setAlert(`Please provide a warehouse`, "danger", 5000);
      }
      if (!customer || !customer.value) {
        return appState.setAlert(`Please provide a customer`, "danger", 5000);
      }
      setWarehouse(warehouse.value);
      setCustomer(customer.value);
    };

    const confirmItem = () => {
      completeBundlePutawayItemQuery.fetchData({
        childTask: currentTask.id,
        quantity: currentSku.scannedQuantity,
      });
    };

    const scanBarcode = ({ barcode, formFactor }) => {
      if (!barcode || barcode.trim() === "") {
        return appState.setAlert(
          `Please provide a valid barcode`,
          "danger",
          5000,
        );
      }
      if (!formFactor) {
        return appState.setAlert(
          `Please provide a valid form factor`,
          "danger",
          5000,
        );
      }

      scanBundlePutawayItemQuery.fetchData({
        barcode: barcode.trim(),
        warehouse,
        customer,
        formFactor,
        childTask: currentTask?.id ?? null,
      });
    };

    return (
      <WrappedComponent
        currentTask={currentTask}
        currentSku={currentSku}
        setCurrentSku={setCurrentSku}
        scanBarcode={scanBarcode}
        confirmItem={confirmItem}
        customer={customer}
        warehouse={warehouse}
        customers={
          auth.user && auth.user.customersList ? auth.user.customersList : []
        }
        warehouses={
          auth.user && auth.user.warehousesList ? auth.user.warehousesList : []
        }
        submitWarehouseCustomerCode={submitWarehouseCustomerCode}
      />
    );
  };
};

export default withBundlePutawayLogic;
