import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import _ from "lodash";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import Autocomplete from "#components/utils/Autocomplete";
import Tabs from "#components/utils/Tabs";

const CycleCountPlanForm = ({
  onClose,
  title,
  warehouses,
  onChange,
  onChangeMultiSelect,
  selectedCycleCountPlan,
  onSubmit,
  skus,
  availableInventory,
  onChangeInventorySearch,
  onChangeSKUs,
  binLocationOptions,
  specificBinLocationOptions,
  customers,
  onChangeMultiSelectUsers,
  debouncedBinLocationSearch,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Cycle Count Plan Details
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedCycleCountPlan.name}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="code"
          label="Code"
          placeholder=" "
          onChange={onChange}
          value={selectedCycleCountPlan.code}
        />
      </div>
      <div>
        <RoundedDropdown
          placeholder={"Frequency"}
          list={[{ name: "Daily" }, { name: "Weekly" }, { name: "Monthly" }]}
          labelKey="name"
          valueKey="name"
          name="frequency"
          selectedValue={selectedCycleCountPlan.frequency}
          setSelected={(e) =>
            onChange({
              target: {
                name: "frequency",
                value: e,
              },
            })
          }
        />
      </div>
      <div>
        <TextField
          type="date"
          id="startDate"
          label="StartDate"
          placeholder=" "
          onChange={onChange}
          value={selectedCycleCountPlan.startDate}
        />
      </div>
      <div>
        <div id="selectingStations">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Select Clients
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey={"name"}
            valueKey={"id"}
            disabled={selectedCycleCountPlan.executions?.length >= 1}
            setValues={(e) => onChangeMultiSelectUsers("customers", e)}
            values={selectedCycleCountPlan.customers || []}
            emptyValuesAccountsForAll={true}
          />
        </div>
      </div>

      <div>
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Cycle Count Type
          </span>
        </label>
        <RoundedDropdown
          placeholder={"Cycle Count Type"}
          list={[{ name: "Locations" }]}
          labelKey="name"
          valueKey="name"
          name="type"
          selectedValue={selectedCycleCountPlan.type}
          disabled={
            selectedCycleCountPlan.executions?.length >= 1 ? true : false
          }
          setSelected={(e) =>
            onChange({
              target: {
                name: "type",
                value: e,
              },
            })
          }
        />
      </div>
      {selectedCycleCountPlan.type === "Locations" && (
        <div>
          <div className="mt-5">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Specific Bins
              </span>
            </label>
            <MultiSelectAutoComplete
              options={specificBinLocationOptions}
              labelKey="code"
              valueKey="code"
              disabled={
                selectedCycleCountPlan.executions?.length >= 1 ? true : false
              }
              onKeyDown={(e) => debouncedBinLocationSearch(e)}
              setValues={(e) => onChangeMultiSelect(e, "specificBins")}
              values={selectedCycleCountPlan.specificBins}
            />
          </div>
        </div>
      )}
    </div>
  </Modal>
);

export default CycleCountPlanForm;

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};
