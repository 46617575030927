import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { CameraIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

const GenerateLpn = ({
  setAddLpnToProduct,
  setSearchTerm,
  setInventorySearchKeyword,
  inventorySearchKeyword,
  availableInventory,
  addLpnToProduct,
  saveAndPrint,
  formFactors,
  generateLpn,
  setGenerateLpn,
}) => {
  const productSelected =
    generateLpn.addingProduct && generateLpn.addingProduct.productId
      ? availableInventory.find(
          (i) => i.id === generateLpn.addingProduct.productId,
        )
      : {};
  return (
    <Modal
      title={`Generate LPN`}
      negativeAction={() => setGenerateLpn(null)}
      positiveAction={saveAndPrint}
      id="userFormModal">
      <div className="space-y-4">
        {generateLpn.addingProduct && (
          <>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
              Search
            </label>
            <div className="relative">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block p-4 pl-10 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Add Products..."
                onChange={(e) => setInventorySearchKeyword(e.target.value)}
                value={inventorySearchKeyword}
              />
            </div>

            <div
              class="cursor-pointer inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-primaryAccent dark:hover:bg-red-700 dark:focus:ring-red-800"
              onClick={() =>
                setGenerateLpn({
                  ...generateLpn,
                  addingProduct: null,
                })
              }>
              Cancel
            </div>
            {(!inventorySearchKeyword ||
              inventorySearchKeyword.trim() === "") &&
              generateLpn.addingProduct &&
              generateLpn.addingProduct.productId &&
              Object.keys(productSelected).length > 0 && (
                <>
                  <div class="space-y-4 p-6 max-w-2xl bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {productSelected.name}
                      </h5>
                    </a>
                    {productSelected.sku && (
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        SKU:
                        <span className="font-bold px-2">
                          {productSelected.sku}
                        </span>
                      </p>
                    )}
                    {productSelected.asin && (
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        ASIN:
                        <span className="font-bold px-2">
                          {buildMarketplaceHyperlink(
                            productSelected.asin,
                            "ASIN",
                            productSelected.source,
                          )}
                        </span>
                      </p>
                    )}

                    <div>
                      <Dropdown
                        placeholder={"Select Form Factor"}
                        list={formFactors}
                        labelKey="name"
                        valueKey="name"
                        name="formFactor"
                        setSelected={(e) =>
                          setGenerateLpn({
                            ...generateLpn,
                            addingProduct: {
                              ...generateLpn.addingProduct,
                              formFactor: e,
                            },
                          })
                        }
                        selectedValue={generateLpn.addingProduct.formFactor}
                      />
                    </div>
                    <div>
                      <TextField
                        type="number"
                        id="quantity"
                        label="Quantity"
                        placeholder=" "
                        onChange={(e) =>
                          setGenerateLpn({
                            ...generateLpn,
                            addingProduct: {
                              ...generateLpn.addingProduct,
                              quantity:
                                isNaN(parseInt(e.target.value)) === false
                                  ? parseInt(e.target.value)
                                  : null,
                            },
                          })
                        }
                        value={generateLpn.addingProduct.quantity}
                        min="0"
                      />
                    </div>
                    <div className="space-x-4">
                      <div
                        class="cursor-pointer inline-flex items-center py-2 px-3 text-lg font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-primaryAccent dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          setGenerateLpn({
                            ...generateLpn,
                            addingProduct: {
                              ...generateLpn.addingProduct,
                              productId: null,
                            },
                          })
                        }>
                        Cancel
                      </div>
                      <div
                        class="cursor-pointer inline-flex items-center py-2 px-3 text-lg font-medium text-center text-white bg-primaryAccent rounded-lg hover:bg-primaryAccent focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-primaryAccent dark:hover:bg-primaryAccent dark:focus:ring-blue-800"
                        onClick={() =>
                          setGenerateLpn({
                            ...generateLpn,
                            products:
                              generateLpn.products &&
                              generateLpn.products.length > 0
                                ? [
                                    ...generateLpn.products,
                                    {
                                      productId:
                                        generateLpn.addingProduct.productId,
                                      formFactor: generateLpn.addingProduct
                                        .formFactor
                                        ? generateLpn.addingProduct.formFactor
                                        : "Each",
                                      quantity:
                                        generateLpn.addingProduct.quantity,
                                      sku: generateLpn.addingProduct.sku,
                                    },
                                  ]
                                : [
                                    {
                                      productId:
                                        generateLpn.addingProduct.productId,
                                      formFactor: generateLpn.addingProduct
                                        .formFactor
                                        ? generateLpn.addingProduct.formFactor
                                        : "Each",
                                      quantity:
                                        generateLpn.addingProduct.quantity,
                                      sku: generateLpn.addingProduct.sku,
                                    },
                                  ],
                            addingProduct: null,
                          })
                        }>
                        Submit
                      </div>
                    </div>
                  </div>
                </>
              )}
            {inventorySearchKeyword && inventorySearchKeyword.trim() !== "" && (
              <>
                <table className="table-auto items-start text-left">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-2 text-md text-gray-500">ASIN</th>
                      <th className="px-6 py-2 text-md text-gray-500">SKU</th>
                      <th className="px-6 py-2 text-md text-gray-500">Name</th>
                      <th className="px-6 py-2 text-md text-gray-500">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    {availableInventory.length === 0 && (
                      <tr className="whitespace-nowrap">
                        <td className="px-6 py-4 text-md text-gray-500">
                          No Results
                        </td>
                        <td className="px-6 py-4 text-md text-gray-500"></td>
                        <td className="px-6 py-4 text-md text-gray-500"></td>
                        <td className="px-6 py-4 text-md text-gray-500"></td>
                      </tr>
                    )}

                    {availableInventory.length > 0 &&
                      availableInventory.map((item, idx) => (
                        <tr index={idx} className="whitespace-nowrap">
                          <td className="px-6 py-4 text-md text-gray-500">
                            {buildMarketplaceHyperlink(
                              item.asin,
                              "ASIN",
                              item.source,
                            )}
                          </td>
                          <td className="px-6 py-4 text-md text-gray-500">
                            {item.sku}
                          </td>
                          <td
                            className="px-6 py-4 text-md text-gray-500"
                            title={item.name}>
                            {truncate(item.name)}
                          </td>
                          <td className="px-6 py-4 text-md text-gray-500">
                            <div
                              onClick={() => {
                                setGenerateLpn({
                                  ...generateLpn,
                                  addingProduct: {
                                    ...generateLpn.addingProduct,
                                    productId: item.id,
                                    sku: item.sku,
                                  },
                                });
                                setInventorySearchKeyword(null);
                              }}
                              className="text-md text-white rounded-md text-center p-2 bg-2C7695 cursor-pointer">
                              Select
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}

        {!generateLpn.addingProduct && (
          <>
            <div>
              <Dropdown
                placeholder={"Select Form Factor"}
                list={formFactors}
                labelKey="name"
                valueKey="name"
                name="formFactor"
                setSelected={(e) =>
                  setGenerateLpn({ ...generateLpn, formFactor: e })
                }
                selectedValue={generateLpn.formFactor}
              />
            </div>
            <div>
              <TextField
                type="number"
                id="quantity"
                label="Quantity"
                placeholder=" "
                onChange={(e) =>
                  setGenerateLpn({
                    ...generateLpn,
                    quantity:
                      isNaN(parseInt(e.target.value)) === false
                        ? parseInt(e.target.value)
                        : null,
                  })
                }
                value={generateLpn.quantity}
                min="0"
              />
            </div>
            <div>
              <TextField
                type="text"
                id="useCode"
                label="Code (Leave blank to auto-generate)"
                placeholder=" "
                onChange={(e) =>
                  setGenerateLpn({
                    ...generateLpn,
                    useCode: e.target.value,
                  })
                }
                value={generateLpn.useCode}
              />
            </div>

            {(!inventorySearchKeyword ||
              inventorySearchKeyword.trim() === "") &&
              generateLpn && (
                <>
                  <div
                    onClick={() => {
                      setGenerateLpn({
                        ...generateLpn,
                        addingProduct: { quantity: 1, formFactor: "Each" },
                      });
                    }}
                    className="text-md text-white rounded-md text-center p-2 bg-2C7695 cursor-pointer inline-block">
                    Add Product
                  </div>
                  <table className="table-auto items-start text-left">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-2 text-md text-gray-500">SKU</th>
                        <th className="px-6 py-2 text-md text-gray-500">
                          Quantity
                        </th>
                        <th className="px-6 py-2 text-md text-gray-500">
                          Form Factor
                        </th>
                        <th className="px-6 py-2 text-md text-gray-500">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      {(!generateLpn.products ||
                        generateLpn.products.length === 0) && (
                        <tr className="whitespace-nowrap">
                          <td className="px-6 py-4 text-md text-gray-500">
                            No Products Added
                          </td>
                          <td className="px-6 py-4 text-md text-gray-500"></td>
                          <td className="px-6 py-4 text-md text-gray-500"></td>
                          <td className="px-6 py-4 text-md text-gray-500"></td>
                        </tr>
                      )}
                      {generateLpn.products &&
                        generateLpn.products.length > 0 &&
                        generateLpn.products.map((item, idx) => (
                          <tr className="whitespace-nowrap" key={idx}>
                            <td className="px-6 py-4 text-md text-gray-500">
                              {item.sku}
                            </td>
                            <td className="px-6 py-4 text-md text-gray-500">
                              {item.quantity}
                            </td>
                            <td className="px-6 py-4 text-md text-gray-500">
                              {item.formFactor}
                            </td>
                            <td className="px-6 py-4 text-md text-gray-500">
                              {" "}
                              <div
                                class="cursor-pointer inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                onClick={() =>
                                  setGenerateLpn({
                                    ...generateLpn,
                                    products: generateLpn.products.filter(
                                      (i, index) => index !== idx,
                                    ),
                                  })
                                }>
                                Remove
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
          </>
        )}
      </div>
    </Modal>
  );
};

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};

export default GenerateLpn;
