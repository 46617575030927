import Checkbox from "#components/utils/Checkbox";
import _ from "lodash";

const PermissionsForm = ({ permissionOptions, onChangePermission }) => {
  return (
    <div className="overflow-x-scroll h-96">
      <div className="font-medium font-montserrat flex justify-between text-darkText text-lg pb-4">
        <div className="flex-1">Permissions</div>
        <div className="flex flex-1 justify-evenly">
          <div className="w-1/2 flex justify-center">Readable</div>
          <div className="w-1/2 flex justify-center">Writable</div>
        </div>
      </div>
      {permissionOptions &&
        permissionOptions.map((item) => (
          <div key={item.name} className="my-4">
            {!item.children && (
              <div className="flex justify-between">
                <div className="flex-1 font-medium text-gray-700 text-lg font-montserrat">
                  {item.name}
                </div>
                <div className="flex flex-1 justify-around">
                  <div className="w-1/2 flex justify-center">
                    <Checkbox
                      role="checkbox"
                      checked={item.readable}
                      onChange={() =>
                        onChangePermission(item, !item.readable, false)
                      }
                    />
                  </div>
                  <div className="w-1/2 flex justify-center">
                    <Checkbox
                      role="checkbox"
                      checked={item.writable}
                      disabled={!item.readable}
                      onChange={() =>
                        onChangePermission(item, item.readable, !item.writable)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {item.children?.length && (
              <div>
                <div className="flex justify-between">
                  <div className="flex-1 font-medium text-gray-700 text-lg font-montserrat">
                    {item.name}
                  </div>
                  <div className="flex flex-1 justify-around">
                    <div className="w-1/2 flex justify-center">
                      <Checkbox
                        role="checkbox"
                        filled={
                          item.children.filter((child) => child.readable)
                            .length > 0
                        }
                        checked={
                          item.children.filter((child) => child.readable)
                            .length === item.children.length
                        }
                        onChange={() =>
                          onChangePermission(
                            item,
                            item.children.filter((child) => child.readable)
                              .length !== item.children.length,
                            false,
                          )
                        }
                      />
                    </div>
                    <div className="w-1/2 flex justify-center">
                      <Checkbox
                        role="checkbox"
                        label={item.writable}
                        checked={
                          item.children.filter((child) => child.writable)
                            .length === item.children.length
                        }
                        disabled={
                          item.children.filter((child) => child.readable)
                            .length !== item.children.length
                        }
                        onChange={() =>
                          onChangePermission(
                            item,
                            true,
                            item.children.filter(
                              (child) => child.readable && child.writable,
                            ).length !== item.children.length,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  {item.children.map((child) => (
                    <div
                      key={child.name}
                      className="flex my-3 first-letter:justify-between">
                      <div className="flex-1 ">
                        <div className="pl-5 font-medium text-gray-700 text-lg font-montserrat">
                          {child.name}
                        </div>
                      </div>
                      <div className="flex flex-1 justify-around">
                        <div className="w-1/2 flex justify-center">
                          <Checkbox
                            role="checkbox"
                            checked={child.readable}
                            onChange={() =>
                              onChangePermission(child, !child.readable, false)
                            }
                          />
                        </div>
                        <div className="w-1/2 flex justify-center">
                          <Checkbox
                            role="checkbox"
                            checked={child.writable}
                            disabled={!child.readable}
                            onChange={() =>
                              onChangePermission(child, true, !child.writable)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default PermissionsForm;
