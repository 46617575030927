import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { CalendarIcon, XIcon } from "@heroicons/react/outline";
import { useState, useRef, useEffect } from "react";
import TextField from "#components/utils/TextField";
import moment from "moment-timezone";
const MAX_FILTERS_VISIBLE_BY_DEFAULT = 3;
const MAX_APPLIED_FILTERS_VISIBLE_BY_DEFAULT = 4;
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TableFilters = ({
  onChangeFilter,
  filters,
  totalResults,
  dataTableFilters,
  clearFilters,
  submitFilters,
}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [showAllAppliedFilters, setShowAllAppliedFilters] = useState(false);
  const filtersWithValues = Object.keys(filters).filter(
    (key) => filters[key]?.length > 0,
  );

  const filtersToShow = showAllFilters
    ? dataTableFilters
    : dataTableFilters?.slice(0, MAX_FILTERS_VISIBLE_BY_DEFAULT) || [];

  const Datepicker = ({ data }) => {
    const [showPicker, setShowPicker] = useState(false);
    const ref = useRef();
    return (
      <div className="datePickerFieldWrapper">
        <input
          type="text"
          placeholder={data.displayLabel}
          onChange={(e) => onChangeFilter(data.key, e.target.value, true)}
          onFocus={(e) => {
            e.target.type = "date";
            e.target.showPicker();
            setShowPicker(true);
          }}
          onBlur={(e) => {
            e.target.type = "text";
            setShowPicker(false);
          }}
          className="datePickerField placeholder-black non-italic placeholder:non-italic"
          ref={ref}
          value={
            filters[data.key]
              ? moment.tz(filters[data.key], userTimezone).format("YYYY-MM-DD")
              : null
          }
        />
        {!showPicker && (
          <CalendarIcon
            className="datePickerIcon cursor-pointer"
            onClick={() => ref.current.focus()}
          />
        )}
      </div>
    );
  };

  const getFilterDivs = () => {
    const returnDivs = [];
    if (filtersWithValues && filtersWithValues.length > 0) {
      for (const filter of filtersWithValues) {
        const dataSource = dataTableFilters.find((i) => i.key === filter);

        if (!dataSource) {
          continue;
        }

        if (dataSource.typeOfField === "date") {
          returnDivs.push(
            <div
              className="appliedFilterWrapper"
              key={Math.random()}
              title={dataSource.displayLabel}>
              <span className="appliedFilterValue">
                {dataSource.displayLabel}:
                {moment.tz(filters[filter], userTimezone).format("YYYY-MM-DD")}
              </span>
              <span
                className="appliedFilterClose"
                onClick={() => {
                  onChangeFilter(filter, null, true);
                }}>
                <XIcon className="h-6 w-6" />
              </span>
            </div>,
          );
          continue;
        }

        if (dataSource.typeOfField === "single-select") {
          returnDivs.push(
            <div
              className="appliedFilterWrapper"
              key={Math.random()}
              title={dataSource.displayLabel}>
              <span className="appliedFilterValue">
                {dataSource.displayLabel}: {filters[filter]?.toUpperCase()}
              </span>
              <span
                className="appliedFilterClose"
                onClick={() => {
                  onChangeFilter(filter, null, true);
                }}>
                <XIcon className="h-6 w-6" />
              </span>
            </div>,
          );
          continue;
        }

        // Look up the filter value in the dataSource
        const filterValues =
          (dataSource?.data &&
            dataSource?.data?.filter((item) =>
              filters[filter].includes(item.id),
            )) ||
          [];
        filterValues.map((filterValue) => {
          return returnDivs.push(
            <div
              className="appliedFilterWrapper"
              key={Math.random()}
              title={dataSource.displayLabel}>
              <span className="appliedFilterValue">
                {filterValue?.name || null}
              </span>
              <span
                className="appliedFilterClose"
                onClick={() => {
                  onChangeFilter(
                    filter,
                    filters[filter].filter(
                      (item) =>
                        item !== filterValue[dataSource.valueKey || "id"],
                    ).length > 0
                      ? filters[filter].filter(
                          (item) =>
                            item !== filterValue[dataSource.valueKey || "id"],
                        )
                      : null,
                    true,
                  );
                }}>
                <XIcon className="h-6 w-6" />
              </span>
            </div>,
          );
        });
      }
    }
    return returnDivs;
  };

  const allFilters = getFilterDivs();

  const filtersToRender = showAllAppliedFilters
    ? allFilters
    : allFilters?.slice(0, MAX_APPLIED_FILTERS_VISIBLE_BY_DEFAULT) || [];
  const moreCount = allFilters.length - filtersToRender.length;

  return (
    <div className="filterWrapper">
      <div className="filterSection">
        <div className="filterOuterWrapper">
          <span className="filterTitle">Filter</span>
        </div>
        <div className="allFilterDropdownsWrapper">
          {filtersToShow &&
            filtersToShow.map((data) => {
              if (data.typeOfField === "date") {
                return <Datepicker data={data} />;
              } else if (data.typeOfField === "single-select") {
                return (
                  <AutocompleteSingleSelectDropdown
                    key={data.key}
                    options={data.data}
                    labelKey={data.labelKey || "name"}
                    valueKey={data.valueKey || "id"}
                    onChange={(value) => {
                      onChangeFilter(data.key, value, true);
                    }}
                    value={filters[data.key]}
                    placeholder={data.displayLabel}
                  />
                );
              } else {
                return (
                  <AutocompleteMultiSelectDropdown
                    key={data.key}
                    options={data.data}
                    labelKey={data.labelKey || "name"}
                    valueKey={data.valueKey || "id"}
                    onChange={(values) => {
                      onChangeFilter(
                        data.key,
                        values && values.length ? values : null,
                        true,
                      );
                    }}
                    values={filters[data.key]}
                    placeholder={data.displayLabel}
                    multiSelect={data.typeOfField !== "single-select"}
                  />
                );
              }
            })}
          {dataTableFilters.length > MAX_FILTERS_VISIBLE_BY_DEFAULT && (
            <div
              className="showAllFilters"
              onClick={() => setShowAllFilters(!showAllFilters)}>
              {showAllFilters ? "Less Filters" : "More Filters"}
            </div>
          )}
        </div>
      </div>
      <div className="applyFilters" onClick={submitFilters}>
        Apply Filters
      </div>
      <div className="appliedFiltersSection">
        <div className="appliedFiltersOuterWrapper">
          <div className="appliedFiltersTitle">
            <span className="appliedFiltersTitleTextHeader">
              {totalResults}
            </span>
            <span className="appliedFiltersTitleText"> results found</span>
          </div>
        </div>
        <div className="appliedFiltersInnerWrapper">
          {filtersToRender}
          {allFilters.length > MAX_APPLIED_FILTERS_VISIBLE_BY_DEFAULT && (
            <div
              className="appliedFilterWrapper cursor-pointer border-2 border-primaryAccent rounded-md"
              onClick={() => setShowAllAppliedFilters(!showAllAppliedFilters)}>
              <span className="appliedFilterValue">
                {!showAllAppliedFilters ? `+${moreCount}` : `See Less`}
              </span>
            </div>
          )}
          {filtersWithValues?.filter((i) => i !== "keyword").length > 0 && (
            <div className="clearAllFilters" onClick={clearFilters}>
              Clear Filters
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
