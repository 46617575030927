import { Tab } from "@headlessui/react";
import DetailedView from "#components/prepper/DetailedView";
import ListView from "#components/prepper/ListView";
import CompletedBatch from "#components/prepper/CompletedBatch";
import { useState } from "react";

const PrepperBatch = ({
  currentBatch,
  scanBarcode,
  currentItemMismatch,
  setCurrentItemMismatch,
  confirmPrepItem,
  confirmDropoff,
  printShippingLabel,
  simulateItemScan,
  weight,
  workflow,
  printAllFnSkus,
  changeQuantity,
  subdomain,
  cancelItem,
  proceedToPacking,
  stations,
  selectedStation,
  restartActivity,
  pauseActivity,
  unprepItems,
  order,
  updateBestByDate,
  currentProduct,
  onChangeMultiSelect,
  tenant,
}) => {
  const [selectedIndex, setSelectedIndex] = useState("List View");
  const tabs = {
    "Detail View": (
      <DetailedView
        currentBatch={currentBatch}
        currentItemMismatch={currentItemMismatch}
        setCurrentItemMismatch={setCurrentItemMismatch}
        confirmPrepItem={confirmPrepItem}
        weight={weight}
        simulateItemScan={simulateItemScan}
        workflow={workflow}
        printAllFnSkus={printAllFnSkus}
        changeQuantity={changeQuantity}
        subdomain={subdomain}
        cancelItem={cancelItem}
        scanBarcode={scanBarcode}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
        updateBestByDate={updateBestByDate}
        currentProduct={currentProduct}
        onChangeMultiSelect={onChangeMultiSelect}
        tenant={tenant}
      />
    ),
    "List View": (
      <ListView
        currentBatch={currentBatch}
        scanBarcode={scanBarcode}
        confirmPrepItem={confirmPrepItem}
        simulateItemScan={simulateItemScan}
        unprepItems={unprepItems}
        order={order}
        setSelectedIndex={setSelectedIndex}
      />
    ),
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  if (currentBatch && currentBatch.status === "COMPLETED") {
    return (
      <CompletedBatch
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
        prep={true}
        printShippingLabel={printShippingLabel}
        printAllFnSkus={printAllFnSkus}
        workflow={workflow}
        subdomain={subdomain}
        stations={stations}
        selectedStation={selectedStation}
        proceedToPacking={proceedToPacking}
        restartActivity={restartActivity}
        pauseActivity={pauseActivity}
      />
    );
  }

  return (
    <>
      <div className="flex-col pt-4">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex items-center justify-center">
            <div className=" p-2 space-x-2 rounded-full bg-primaryAccent">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "px-6 py-2.5 leading-5 font-regular rounded-full",
                        "focus:outline-none focus:ring-0 font-montserrat text-lg z-50 ",
                        selected
                          ? "bg-white text-primaryAccent font-medium"
                          : "border-primaryAccent bg-transparent border rounded-full text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col mt-4 bg-transparent p-4 items-center">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default PrepperBatch;
