import { useState } from "react";
import PickerBagCode from "#components/picker/common/PickerBagCode";
import DetailedView from "#components/picker/bundle/DetailedView";
import ListView from "#components/picker/bundle/ListView";
import CompletedBatch from "#components/picker/bundle/CompletedBatch";
import { Tab } from "@headlessui/react";
import _ from "lodash";

const SingleLineItemBatch = ({
  currentBatch,
  setCurrentBatch,
  scanBarcode,
  confirmPickItem,
  confirmDropoff,
  currentItem = {},
  onChangeScannedQuantity,
  stations,
}) => {
  // Scan an order tray to get started
  if (!currentBatch.tote) {
    return <PickerBagCode scanBarcode={scanBarcode} />;
  }

  // when the task is completed, we show them the drop off screen
  const confirmedAllItems =
    currentBatch?.inventory?.filter((item) => item.status === "COMPLETE")
      ?.length === currentBatch?.inventory?.length;

  if (currentBatch && confirmedAllItems) {
    return (
      <CompletedBatch
        currentBatch={currentBatch}
        setCurrentBatch={setCurrentBatch}
        confirmDropoff={confirmDropoff}
        stations={stations}
      />
    );
  }

  const [selectedIndex, setSelectedIndex] = useState("List View");
  const tabs = {
    "Detail View": (
      <DetailedView
        currentItem={currentItem}
        scanBarcode={scanBarcode}
        confirmPickItem={confirmPickItem}
        onChangeScannedQuantity={onChangeScannedQuantity}
      />
    ),
    "List View": (
      <ListView
        currentBatch={currentBatch}
        scanBarcode={scanBarcode}
        setSelectedIndex={setSelectedIndex}
      />
    ),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="flex-col pt-28">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="flex items-center justify-center">
            <div className=" p-2 space-x-2 rounded-full bg-primaryAccent">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "px-6 py-2.5 leading-5 font-regular rounded-full",
                        "focus:outline-none focus:ring-0 font-montserrat text-lg",
                        selected
                          ? "bg-white text-primaryAccent font-medium"
                          : "border-primaryAccent bg-transparent border rounded-full text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col mt-4 bg-transparent p-4 items-center">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default SingleLineItemBatch;
