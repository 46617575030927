import TableFieldButton from "#components/utils/TableFieldButton";
import Checkbox from "#components/utils/Checkbox";
import { PencilIcon, TrashIcon, PrinterIcon } from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import Pagination from "#components/common/Pagination";
import { useEffect, useState } from "react";
import Modal from "../utils/Modal";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
import QuickFilters from "../common/QuickFilters";

const TotesList = ({
  totes,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  noValuesText,
  selectedTotes,
  selectTote,
  queueSinglePrint,
  selectAllTotes,
  allTotesSelected,
  customers,
  warehouses,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeFilter,
}) => {
  const [attributes, setAttributes] = useState(null);

  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      {attributes && (
        <Modal
          negativeAction={() => setAttributes(null)}
          title={`Tote Attributes`}
          noPadding={true}
          positiveAction={() => setAttributes(null)}
          positiveText="OK">
          <div className="space-y-4 p-4">
            <div>
              <span className="font-bold font-montserrat text-454A4F text-lg pb-4">
                Attributes
              </span>
            </div>
            {Object.keys(attributes).map((item, idx) => (
              <div key={idx}>
                <span className="font-bold font-montserrat text-454A4F text-md pb-4">
                  {item.toUpperCase()}:{" "}
                  {[true, false].includes(attributes[item])
                    ? attributes[item] === true
                      ? "Yes"
                      : "No"
                    : attributes[item]}
                </span>
              </div>
            ))}
          </div>
        </Modal>
      )}
      {(customers.length > 1 || warehouses.length > 1) && (
        <QuickFilters
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          customers={customers}
          warehouses={warehouses}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      )}
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={null}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Tote Type",
            value: "toteType",
          },
          {
            name: "barcode",
            value: "barcode",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
          <tr className="text-textWhite font-montserrat border-left">
            <th
              scope="col"
              className="px-2 py-3 text-left tracking-wider font-medium border-transparent border-l-8 pl-5">
              <Checkbox
                role="checkbox"
                onChange={selectAllTotes}
                name="totes"
                value={"Select All Totes"}
                checked={allTotesSelected}
              />
            </th>
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {!totes ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"></td>
            </tr>
          ) : (
            totes.map((tote, index) => (
              <tr
                key={tote.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-wider rounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  <Checkbox
                    role="checkbox"
                    onChange={(_) => selectTote(index, shiftKeyPressed)}
                    name="tote"
                    value={tote.id}
                    checked={selectedTotes.includes(tote.id)}
                  />
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {tote.toteType}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {tote.barcode}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {tote.subTotes?.map((item) => (
                    <div className="flex" key={item}>
                      {item}
                    </div>
                  ))}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  <ExpandedMultipleItem
                    items={
                      tote.warehouses &&
                      warehouses
                        .filter((item) => tote.warehouses.includes(item.id))
                        .map((item) => item.name)
                    }
                  />
                </td>
                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {tote.attributes &&
                  Object.keys(tote.attributes).length > 0 &&
                  Object.values(tote.attributes).filter((i) => i !== null) ? (
                    <div
                      className="text-primaryAccent cursor-pointer"
                      onClick={() => setAttributes(tote.attributes)}>
                      View
                    </div>
                  ) : (
                    "No Attributes"
                  )}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {tote.customers
                    ? customers
                        .filter((item) => tote.customers.includes(item.id))
                        .map((item) => item.name)
                        .join(", ")
                    : ""}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  <div className="flex space-x-4 items-center">
                    <TableFieldButton
                      onClick={() => editButtonClicked(tote)}
                      text={<PencilIcon className="w-6 h-6" />}
                      disabled={!writable}
                    />

                    <TableFieldButton
                      text={<TrashIcon className="w-6 h-6" />}
                      onClick={() => deleteButtonClicked(tote.id)}
                      disabled={!writable}
                    />
                    <TableFieldButton
                      text={<PrinterIcon className="w-6 h-6" />}
                      onClick={() => queueSinglePrint(tote.id)}
                      disabled={!writable}
                    />
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </CustomTableWithTheme>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default TotesList;
