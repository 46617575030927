import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";

const headers = [
  "SKU",
  "Bin Location",
  "Qty",
  "Picked Qty",
  "Status",
  "Action",
];

const ListView = ({ currentBatch, scanBarcode, setSelectedIndex }) => {
  return (
    <div className="flex-1 max-w-4xl">
      <PageTitle>Total ({currentBatch.inventory.length}) </PageTitle>
      <div className="bg-EBEBEB mt-10 rounded-md">
        <table className="min-w-full divide-y divide-gray-200 -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {currentBatch.inventory.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {item.sku}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.binLocation}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.quantity}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.scannedQuantity}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {renderButton(item.status)}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.status != "COMPLETE" && (
                    <div
                      className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white"
                      onClick={() => {
                        scanBarcode(item.sku);
                        setSelectedIndex("Detail View");
                      }}>
                      Scan
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const renderButton = (status) => {
  switch (status) {
    case "COMPLETE":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-50BFC3 text-center text-454A4F">
          Completed
        </div>
      );
    case "UNPROCESSED":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-EFE9DC text-center text-454A4F">
          Incomplete
        </div>
      );
  }
};

export default ListView;
