/**
 * Build a hyperlink based on specific conditions.
 *
 * @param {string} value - The value to be linked and displayed.
 * @param {string} type - The type of value (e.g., 'ASIN', 'SKU').
 * @param {string} source - The source of the value (e.g., 'FBA', 'FBM', 'ebay').
 * @param {string} marketplaceCountryCode - Marketplace xountry code of product. (e.g., "US", "UK", "DE").
 * @returns {JSX.Element|string} - The built hyperlink (or) the original value.
 */
export const buildMarketplaceHyperlink = (value, type, source ,marketplaceCountryCode) => {
  if (type === "ASIN") {
    // ["FBA", "FBM"].includes(source) - commented as we don't have access to source attribute in all places
    const asinPattern = /^[A-Za-z0-9]{10}$/;
    if (asinPattern.test(value) && amazonUrlmapping[marketplaceCountryCode]) {
      return (
        <a
          href={`${amazonUrlmapping[marketplaceCountryCode]}/dp/${value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
            color: "blue",
          }}>
          {value}
        </a>
      );
    }
  }
  // handle other marketplace logic here

  return value;
};

const amazonUrlmapping = {
  "UK": "https://www.amazon.co.uk",
  "US": "https://www.amazon.com",
  "CA": "https://www.amazon.ca",
  "ES": "https://www.amazon.es",
  "FR": "https://www.amazon.fr",
  "DE":"https://www.amazon.de",
  "IT":"https://www.amazon.it",
  "JP":"https://www.amazon.co.jp"
}