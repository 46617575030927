import React from "react";
import Select from "react-select";

const CustomSelectDropDown = ({
  label,
  options,
  value,
  onChange,
  className,
  placeholder,
  defaultValue,
  zIndex = 10,
  applyCustomZIndex = false,
}) => {
  return (
    <div className={`relative rounded-lg overflow-visible w-full z-8`}>
      {label && (
        <label
          className={`px-1 text-sm duration-300 origin-0 font-montserrat font-medium text-primaryAccent`}>
          {label}
        </label>
      )}
      <Select
        options={options.sort((a, b) => a.label.localeCompare(b.label))}
        value={value}
        onChange={onChange}
        className={"text-sm " + className}
        menuPortalTarget={applyCustomZIndex ? document.body : undefined}
        styles={
          applyCustomZIndex
            ? { menuPortal: (base) => ({ ...base, zIndex }) }
            : undefined
        }
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default CustomSelectDropDown;
