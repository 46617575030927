import { CheckIcon } from "@heroicons/react/solid";
import moment from "moment-timezone";
import Modal from "#components/utils/Modal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AuditLog = ({ selectedAudit = { stages: [] }, negativeAction }) => {
  const steps = selectedAudit.stages || [];
  const noData = steps.length === 0;

  while (steps.length < 3) {
    steps.push({});
  }
  return (
    <Modal negativeAction={negativeAction} title={`Audit Trail`}>
      {noData && <div>No Activity so far</div>}
      {!noData && (
        <nav aria-label="Progress">
          <ol role="list" className="overflow-hidden">
            {steps.map((step, stepIdx) => (
              <li
                key={step.name}
                className={classNames(
                  stepIdx !== steps.length - 1 ? "pb-10" : "",
                  "relative",
                )}>
                {step.status ? (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="-ml-px absolute mt-0.5 top-4 left-4 w-1 h-full bg-F4C261"
                        aria-hidden="true"
                      />
                    ) : null}
                    <a
                      href={step.href}
                      className="relative flex items-start group">
                      <span className="h-9 flex items-center">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-primaryAccent rounded-full">
                          <CheckIcon
                            className="w-5 h-5 text-white bg-primaryAccent"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="ml-4 min-w-0 flex flex-col">
                        <span className="text-sm text-gray-600">Status</span>
                        <span className="text-sm font-semibold tracking-wide uppercase">
                          {step.status}
                        </span>
                        {step.notes &&
                          step.notes.map((ex, id) => (
                            <div
                              key={id}
                              className="text-sm font-semibold tracking-wide uppercase text-FC8862">
                              {ex}
                            </div>
                          ))}

                        <span className="text-sm font-normal tracking-wide uppercase text-gray-500">
                          {moment(step.createdAt).format("MMM Do YYYY, h:mm a")}
                        </span>
                      </span>
                    </a>
                  </>
                ) : (
                  <>
                    {stepIdx !== steps.length - 1 ? (
                      <div
                        className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                        aria-hidden="true"
                      />
                    ) : null}
                    <a
                      href={step.href}
                      className="relative flex items-start group">
                      <span
                        className="h-9 flex items-center"
                        aria-hidden="true">
                        <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                          <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                        </span>
                      </span>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      )}
    </Modal>
  );
};

export default AuditLog;
