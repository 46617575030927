import withQualityCheckerLogic from "#components/HOC/withQualityCheckerLogic";
import QualityCheckerNewBatchRequest from "#components/qualityChecker/QualityCheckerNewBatchRequest";
import DetailedView from "#components/qualityChecker/DetailedView";
import ListView from "#components/qualityChecker/ListView";
import { Tab } from "@headlessui/react";

const QualityChecker = ({
  getNewQualityChecking,
  currentQualityChecking,
  confirmItem,
  loading,
}) => {
  if (!loading && !currentQualityChecking) {
    return (
      <QualityCheckerNewBatchRequest
        getNewQualityChecking={getNewQualityChecking}
      />
    );
  }
  if (currentQualityChecking) {
    const tabs = {
      "Detail View": (
        <DetailedView
          currentItem={currentQualityChecking.currentItem}
          confirmItem={confirmItem}
          completion={currentQualityChecking.completion}
          // increaseItemQuantity={increaseItemQuantity}
          // decreaseItemQuantity={decreaseItemQuantity}
          // confirmPickItem={confirmPickItem}
        />
      ),
      "List View": <ListView currentQualityChecking={currentQualityChecking} />,
    };
    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
    return (
      <>
        <div className="flex-col pt-24">
          <Tab.Group>
            <Tab.List className="flex items-center justify-center">
              <div className=" p-2 space-x-2 rounded-full bg-primaryAccent">
                {tabs &&
                  Object.keys(tabs).map((tab) => (
                    <Tab
                      key={tab}
                      className={({ selected }) =>
                        classNames(
                          "px-6 py-2.5 leading-5 font-regular rounded-full",
                          "focus:outline-none focus:ring-0 font-montserrat text-lg",
                          selected
                            ? "bg-white text-primaryAccent font-medium"
                            : "border-primaryAccent bg-transparent border rounded-full text-white"
                        )
                      }
                    >
                      {tab}
                    </Tab>
                  ))}
              </div>
            </Tab.List>
            <div className="block">
              <Tab.Panels className="mt-2">
                {tabs &&
                  Object.values(tabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="flex flex-col mt-4 bg-transparent p-4 items-center"
                    >
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
      </>
    );
  }
  return null;
};

export default withQualityCheckerLogic(QualityChecker);
