import Modal from "#components/utils/Modal";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Toggle from "#components/utils/Toggle";
import RoundedDropdown from "#components/utils/RoundedDropdown";

const InventoryFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  customers,
  productCategory,
  productType,
  productSize,
  productShape,
  productColor,
  onChangeAttributes,
  filterQueryOperator,
  setFilterQueryOperator,
}) => {
  return (
    <Modal
      title={"Filters"}
      negativeAction={negativeAction}
      positiveAction={onSubmit}
      initialFocus={null}>
      <div className="grid space-x-4 space-y-4 mt-4">
        <div className="flex items-center">
          <div className="mr-4 text-xl font-medium">When</div>
          <div className="w-24">
            <RoundedDropdown
              placeholder={"Condition"}
              list={[
                { name: "All", value: "all" },
                { name: "Any", value: "any" },
              ]}
              labelKey="name"
              valueKey="value"
              name="queryOperator"
              selectedValue={filterQueryOperator}
              setSelected={setFilterQueryOperator}
            />
          </div>
          <div className="mx-4 text-xl font-medium">
            of the below criteria is met
          </div>
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Clients
            </span>
          </label>
          <MultiSelectAutoComplete
            options={customers}
            labelKey="name"
            valueKey="id"
            setValues={(selectedOptions) =>
              onChangeFilter("customer", selectedOptions)
            }
            values={filters["customer"]}
            rounded={true}
          />
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Category
            </span>
          </label>
          <MultiSelectAutoComplete
            options={productCategory ? productCategory : null}
            labelKey="name"
            valueKey="name"
            setValues={(e) => onChangeFilter("category", e)}
            values={filters["category"]}
            rounded={true}
          />
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Type
            </span>
          </label>
          <MultiSelectAutoComplete
            options={productType ? productType : null}
            labelKey="name"
            valueKey="name"
            setValues={(e) => onChangeFilter("type", e)}
            values={filters["type"]}
            rounded={true}
          />
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Size
            </span>
          </label>
          <MultiSelectAutoComplete
            options={productSize ? productSize : null}
            labelKey="name"
            valueKey="name"
            setValues={(e) => onChangeAttributes("size", e)}
            values={filters["attributes"] ? filters["attributes"]["size"] : []}
            rounded={true}
          />
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Shape
            </span>
          </label>
          <MultiSelectAutoComplete
            options={productShape ? productShape : null}
            labelKey="name"
            valueKey="name"
            setValues={(e) => onChangeAttributes("shape", e)}
            values={filters["attributes"] ? filters["attributes"]["shape"] : []}
            rounded={true}
          />
        </div>
        <div>
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Color
            </span>
          </label>
          <MultiSelectAutoComplete
            options={productColor ? productColor : null}
            labelKey="name"
            valueKey="name"
            setValues={(e) => onChangeAttributes("color", e)}
            values={filters["attributes"] ? filters["attributes"]["color"] : []}
            rounded={true}
          />
        </div>
        <div className="flex space-x-4 items-center px-2">
          <div>
            <div className="text-lg">Include Inactive Products</div>
            <Toggle
              enabled={filters["showInactive"]}
              setEnabled={(e) => onChangeFilter("showInactive", e)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InventoryFilters;
