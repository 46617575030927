import React from "react";
import Modal from "#components/utils/Modal";
import Checkbox from "#components/utils/Checkbox";

const UserPreferenceForm = ({
  title,
  onClose,
  onSubmit,
  onChangePreference,
  options,
}) => (
  <Modal
    title={title}
    negativeAction={onClose}
    positiveAction={onSubmit}
    id="userPermissionFormModal"
  >
    <div className="space-y-4">
      <div className="overflow-y-scroll h-96">
        <span className="grid grid-cols-5">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4 col-span-3">
            Page
          </span>
        </span>
        {options.map((item) => (
          <>
            {!item.children && (
              <span className="grid grid-cols-5 mt-4 mb-4">
                <span className="col-span-3 flex">
                  <Checkbox
                    role="checkbox"
                    label={item.name}
                    checked={item.visible}
                    onChange={() => onChangePreference(item, !item.visible)}
                  />
                </span>
              </span>
            )}
            {item.children?.length && (
              <>
                <span className="grid grid-cols-5 mt-4 mb-4">
                  <span className="col-span-3 flex">
                    <Checkbox
                      role="checkbox"
                      label={item.name}
                      filled={
                        item.children.filter((child) => child.visible).length >
                        0
                      }
                      checked={
                        item.children.filter((child) => child.visible)
                          .length === item.children.length
                      }
                      onChange={() =>
                        onChangePreference(
                          item,
                          item.children.filter((child) => child.visible)
                            .length !== item.children.length
                        )
                      }
                    />
                  </span>
                </span>
                {item.children.map((child) => (
                  <span className="grid grid-cols-5 mt-3">
                    <span className="col-span-3 pl-6 flex">
                      <Checkbox
                        role="checkbox"
                        label={child.name}
                        checked={child.visible}
                        onChange={() =>
                          onChangePreference(child, !child.visible)
                        }
                      />
                    </span>
                  </span>
                ))}
              </>
            )}
          </>
        ))}
      </div>
    </div>
  </Modal>
);

export default UserPreferenceForm;
