import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import _ from "lodash";

const EmptyBinLocationForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedBinLocation,
  onSubmit,
  customers,
  warehouses,
  onChangeMultiSelect,
  locationTypes,
  selectedBulkEditBinLocations,
  setSelectedBulkEditBinLocations,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Location Details
        </span>
      </div>

      <div>
        <Dropdown
          placeholder={"Select Location Type"}
          list={
            locationTypes
              ? [...locationTypes, { name: "Add Custom", id: "addCustom" }]
              : [{ name: "Add Custom", id: "addCustom" }]
          }
          labelKey="name"
          valueKey="id"
          name="type"
          setSelected={(e) => {
            setSelectedBulkEditBinLocations({
              ...selectedBulkEditBinLocations,
              type: e,
            });
          }}
          selectedValue={selectedBulkEditBinLocations.type}
        />
      </div>
      <div>
        <Dropdown
          placeholder={"Storage Type"}
          list={[
            { name: "Regular" },
            { name: "Air Conditioned" },
            { name: "Freezer" },
            { name: "Chilled" },
          ]}
          labelKey="name"
          valueKey="name"
          name="storageType"
          setSelected={(e) => {
            setSelectedBulkEditBinLocations({
              ...selectedBulkEditBinLocations,
              storageType: e,
            });
          }}
          selectedValue={selectedBulkEditBinLocations.storageType}
        />
      </div>
      {warehouses.length > 1 && (
        <div>
          <div id="selectingStations">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Select Warehouse
              </span>
            </label>

            <Dropdown
              placeholder={"Select Warehouse"}
              list={warehouses}
              labelKey={"name"}
              valueKey={"id"}
              setSelected={(e) => {
                setSelectedBulkEditBinLocations({
                  ...selectedBulkEditBinLocations,
                  warehouse: e,
                });
              }}
              selectedValue={selectedBulkEditBinLocations.warehouse}
            />
          </div>
        </div>
      )}
      {customers.length > 1 && (
        <div>
          <div id="selectingStations">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Select Clients
              </span>
            </label>
            <MultiSelectAutoComplete
              options={getFilteredCustomerList(
                selectedBulkEditBinLocations.warehouses,
                customers,
              )}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOptions) => {
                setSelectedBulkEditBinLocations({
                  ...selectedBulkEditBinLocations,
                  customers: selectedOptions,
                });
              }}
              values={selectedBulkEditBinLocations.customers || []}
              emptyValuesAccountsForAll={true}
            />
          </div>
        </div>
      )}
    </div>
  </Modal>
);

export default EmptyBinLocationForm;
