import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_CYCLE_COUNTER_BATCH,
  GET_NEW_CYCLE_COUNTER_BATCH,
  GET_TOTES,
} from "#queries";
import {
  SCAN_CYCLE_COUNTER_BARCODE,
  CONFIRM_CYCLE_COUNT_ITEM,
  CONFIRM_PICKER_DROPOFF,
  CONFIRM_INCOMPLETE_BATCH,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
const config = require("config");
import io from "socket.io-client";
import axios from "axios";

const withCycleCounterLogic = (WrappedComponent) => {
  return (props) => {
    const [connected, setConnected] = useState(false);
    const [currentBatch, setCurrentBatch] = useState(null);
    const confirmCycleCountItemQuery = useQuery(CONFIRM_CYCLE_COUNT_ITEM);
    const confirmIncompleteBatchQuery = useQuery(CONFIRM_INCOMPLETE_BATCH);
    const [workingItems, setWorkingItems] = useState([]);
    const totesQuery = useQuery(GET_TOTES);
    const [mainItems, setMainItems] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [loadingFirstTime, setLoadingFirstTime] = useState(true);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const currentBatchQuery = useQuery(GET_CURRENT_CYCLE_COUNTER_BATCH);
    const getNewBatchQuery = useQuery(GET_NEW_CYCLE_COUNTER_BATCH);
    const scanBarcodeQuery = useQuery(SCAN_CYCLE_COUNTER_BARCODE);

    const baseURL =
      "https://hemwj9q994.execute-api.us-east-1.amazonaws.com/latest";
    const liveURL =
      "wss://0kujneg194.execute-api.us-east-1.amazonaws.com/latest";
    const customerID = "tvotd8dq";
    const clientID = "4ttraeqsei39no0s3f7i23le8r";
    const userName = "kunal@hopstack.io";
    const password = "Kunal@hopstack123";

    let ws = null;
    useEffect(() => {
      connectToProglove()
        .then((resp) => console.log(resp))
        .catch((err) => console.error(err));

      setInterval(
        () =>
          connectToProglove()
            .then((resp) => console.log(resp))
            .catch((err) => console.error(err)),
        1800000,
      );
    }, []);

    const connectToProglove = async () => {
      appState.setLoading();
      let error = false;
      // try {
      //   const res = await axios.get(
      //     `${baseURL}/auth-information?id=${customerID}`
      //   );
      //   console.log(res.data.user_pool_client_id);
      // } catch (e) {
      //   error = true;
      //   console.log(e, "error");
      // }
      if (!error) {
        try {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/x-amz-json-1.1",
              "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
            },
            body: JSON.stringify({
              AuthFlow: "USER_PASSWORD_AUTH",
              ClientId: `${clientID}`, //4ttraeqsei39no0s3f7i23le8r
              AuthParameters: {
                USERNAME: `${userName}`,
                PASSWORD: `${password}`,
              },
            }),
          };
          fetch(
            `https://cognito-idp.us-east-1.amazonaws.com/login`,
            requestOptions,
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              const tokn = data.AuthenticationResult.IdToken;
              console.log(tokn, "response");

              ws = new WebSocket(
                `${liveURL}/?customer=${customerID}&token=${tokn}`,
              );
              console.log(ws);
              ws.onopen = function () {
                onListen();
                setReadyState();
              };
            });

          // setReadyState();
          // return tokn;
        } catch (err) {
          console.error(err);
        }
      }
      appState.removeLoading();
      return { message: "Proglove Connect Attempt Done" };
    };

    function onListen() {
      ws.onmessage = async function (event) {
        // called if a scan event has been received for your account
        let scanEvent = event.data; // this holds a JSON with all the scans details
        const res = await axios.post(
          `${config.SOCKET_URL}/api/scan`,
          JSON.parse(scanEvent),
        );
        console.info(res);
        // await testPost(scanEvent);
        // console.log(scanEvent, "scanEvent");
      };
    }

    function setReadyState() {
      // appState.setAlert("Connection Established");
      console.log(`ProGlove connection established`);
      setConnected(true);
    }

    useEffect(() => {
      setTimeout(() => {
        connectToSocketService()
          .then((resp) => console.log(resp))
          .catch((err) => console.error(err));
      }, 2000);
    }, []);

    const connectToSocketService = async () => {
      const socket = io(config.SOCKET_URL, {
        path: "/socket-service/socket.io",
      });

      socket.on("observeMessages", (payload) => {
        if (payload.error === true) {
          // dispatch(setAlert(payload.message, "error", 5000));
          appState.setAlert(payload.message, "error", 5000);
        } else if (payload.message) {
          appState.setAlert(payload.message, "success", 5000);
          currentBatchQuery.fetchData();
        }
      });

      socket.on("connect", async function () {
        console.log("connected!");
      });

      socket.on("message", (message) => {
        console.log(message);
      });
      socket.emit("subscribe", { roomId: "picker_room" });
    };

    const scanBarcode = (e) => {
      if (e.data) {
        scanBarcodeQuery.fetchData({ code: e.data });
      }
    };

    useEffect(() => {
      currentBatchQuery.fetchData();
      totesQuery.fetchData();
    }, []);

    useEffect(() => {
      if (currentBatchQuery.loading) {
        appState.setLoading();
      }
      if (
        currentBatchQuery.data &&
        currentBatchQuery.data.getCurrentCounterBatch
      ) {
        const batch = currentBatchQuery.data.getCurrentCounterBatch;
        setCurrentBatch(batch);
        setCurrentItem(batch.currentItem);
        appState.removeLoading();
        //setLoadingFirstTime(false);
      }

      if (currentBatchQuery.error) {
        setCurrentBatch(null);
        appState.removeLoading();
      }
    }, [
      currentBatchQuery.loading,
      currentBatchQuery.data,
      currentBatchQuery.error,
    ]);
    useEffect(() => {
      if (getNewBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          getNewBatchQuery.data &&
          getNewBatchQuery.data.getNewCounterBatch.message
        ) {
          currentBatchQuery.fetchData();
        } else if (getNewBatchQuery.error) {
          appState.setAlert(getNewBatchQuery.error.message, "error", 5000);
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      getNewBatchQuery.loading,
      getNewBatchQuery.data,
      getNewBatchQuery.error,
    ]);
    useEffect(() => {
      if (confirmCycleCountItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmCycleCountItemQuery.data &&
          confirmCycleCountItemQuery.data.confirmCounterItem.message
        ) {
          appState.setAlert(
            confirmCycleCountItemQuery.data.confirmCounterItem.message,
            "success",
            5000,
          );

          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }

      if (confirmCycleCountItemQuery.error) {
        appState.setAlert(
          confirmCycleCountItemQuery.error.message,
          "error",
          5000,
        );
        currentBatchQuery.fetchData();
      }
    }, [
      confirmCycleCountItemQuery.loading,
      confirmCycleCountItemQuery.data,
      confirmCycleCountItemQuery.error,
    ]);

    useEffect(() => {
      if (confirmIncompleteBatchQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmIncompleteBatchQuery.data &&
          confirmIncompleteBatchQuery.data.confirmIncompleteBatch.message
        ) {
          appState.setAlert(
            confirmIncompleteBatchQuery.data.confirmIncompleteBatch.message,
            "success",
            5000,
          );
          currentBatchQuery.fetchData();
          setLoadingFirstTime(false);
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      confirmIncompleteBatchQuery.loading,
      confirmIncompleteBatchQuery.data,
      confirmIncompleteBatchQuery.error,
    ]);
    useEffect(() => {
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
      } else {
        if (
          scanBarcodeQuery.data &&
          scanBarcodeQuery.data.scanCounterBarcode &&
          scanBarcodeQuery.data.scanCounterBarcode.message
        ) {
          appState.setAlert(
            scanBarcodeQuery.data.scanCounterBarcode.message,
            "success",
            4000,
          );

          currentBatchQuery.fetchData();
        } else if (scanBarcodeQuery.error) {
          appState.setAlert(scanBarcodeQuery.error.message, "error", 5000);
          currentBatchQuery.fetchData();
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);

    const increaseItemQuantity = () => {
      const currentItemCopy = { ...currentItem };
      currentItemCopy.scannedSkus += 1;

      setCurrentItem(currentItemCopy);
    };

    const decreaseItemQuantity = () => {
      const currentItemCopy = { ...currentItem };
      if (currentItemCopy.scannedSkus > 0) {
        currentItemCopy.scannedSkus -= 1;
      }

      setCurrentItem(currentItemCopy);
    };

    const simulateTote = () => {
      const totes = totesQuery.data?.totes;

      if (!totes || totes.length === 0) {
        appState.setAlert("No totes found", "error", 5000);
        return;
      }
      let activity = "PICKING";
      if (currentBatch && currentBatch.workflow === "Pick by order") {
        activity = "ORDER";
      }
      let items = totesQuery.data?.totes
        .filter((item) => item.toteType === activity)
        .map((item) => item.code);
      if (currentBatch.workflow === "Pick + Sort") {
        items = totesQuery.data?.totes
          .filter(
            (item) =>
              item.toteType === activity &&
              item.subTotes &&
              item.subTotes.length > 0,
          )
          .map((item) => item.code);
      }
      const item = items[Math.floor(Math.random() * items.length)];
      return scanBarcode({ data: item });
    };

    return (
      <WrappedComponent
        currentItem={currentItem}
        currentBatch={currentBatch}
        getNewBatch={() => getNewBatchQuery.fetchData()}
        scanBarcode={(e) => scanBarcode({ data: e })}
        workingItems={workingItems}
        decreaseItemQuantity={decreaseItemQuantity}
        increaseItemQuantity={increaseItemQuantity}
        confirmPickItem={() => {
          confirmCycleCountItemQuery.fetchData({
            id: currentBatchQuery.data.getCurrentCounterBatch.id,
            item: {
              id: currentItem.id,
              quantity: currentItem.quantity,
              pickedQuantity: currentItem.pickedQuantity,
              scannedSkus: currentItem.scannedSkus,
            },
          });
        }}
        confirmDropoff={() => {}}
        loading={currentBatchQuery.loading}
        loadingFirstTime={loadingFirstTime}
        simulateTote={simulateTote}
        connected={connected}
        retryProgloveConnection={() =>
          connectToProglove()
            .then((resp) => console.log(resp))
            .catch((err) => console.error(err))
        }
      />
    );
  };
};

export default withCycleCounterLogic;
