import { useState } from "react";
import TextField from "#components/utils/TextField";

const PickerBagCode = ({
  scanBarcode,
  text,
  tabView,
  associateUsernameInputEnabled,
  showAssociateUsernameInput,
  setShowAssociateUsernameInput,
  username,
  setUsername,
  submitUsername,
}) => {
  const [manualValue, setManualValue] = useState(null);

  if (associateUsernameInputEnabled && showAssociateUsernameInput) {
    return (
      <div
        className={`w-full ${
          !tabView && "h-screen -mt-20"
        } flex items-center justify-center`}>
        <div className="flex-col">
          <div className="bg-white border rounded-2xl shadow-lg">
            <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
              <div>
                <TextField
                  type="text"
                  id="name"
                  label="Enter your name"
                  placeholder=" "
                  onChange={(e) => {
                    setUsername(e.target.value);
                    localStorage.setItem("username", e.target.value);
                  }}
                  value={username}
                  className="text-2xl"
                  autoFocus={true}
                />
              </div>
              <div
                className={`flex-1 text-2xl text-white mt-4 rounded-md text-center py-6 bg-2C7695`}
                onClick={submitUsername}>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`w-full ${
        !tabView && "h-screen -mt-20"
      } flex items-center justify-center`}>
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg">
          <div className="flex items-center justify-center pt-5 pb-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full p-6 bg-primaryAccent shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="text-xl font-medium text-454A4F py-5 px-5">
              {text || "Scan an order tray to get started"}
            </div>
          </div>
          <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
            <div>
              <TextField
                type="text"
                id="name"
                label="Enter Value"
                placeholder=" "
                onChange={(e) => setManualValue(e.target.value)}
                value={manualValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scanBarcode(manualValue);
                    setManualValue("");
                  }
                }}
                autoFocus={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickerBagCode;
