import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import Pagination from "#components/common/Pagination";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const MaterialsList = ({
  materials,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  noValuesText,
  writable,
  entity,
  setPerPage,
  checkPagination,
  submitFilters,
  setSort,
  clearFilters,
}) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      <SearchSortFilter
        onChangeSearchKeyword={(e) => {
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          });
        }}
        submitFilters={submitFilters}
        filters={entity.filters}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
        }}
        setSort={setSort}
        sort={entity.sort}
        setShowFilters={null}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Name",
            value: "name",
          },
          {
            name: "Last Updated",
            value: "updatedAt",
          },
          {
            name: "Created At",
            value: "createdAt",
          }
        ]}
      />
      <CustomTableWithTheme>
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {materials.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {materials.map((material, index) => (
            <tr
              key={material.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {material.name}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {material.quantity}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {(material.weight / 100).toFixed(2)}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {moment(material.updatedAt).format("MMM Do, YYYY")}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {material.dimensions}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {material.threshold}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <div className="flex space-x-4 items-center">
                  {writable && (
                    <>
                      <TableFieldButton
                        onClick={() => editButtonClicked(material)}
                        text={<PencilIcon className="w-6 h-6" />}
                      />
                      <TableFieldButton
                        text={<TrashIcon className="w-6 h-6" />}
                        onClick={() => deleteButtonClicked(material.id)}
                      />
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>

      <Pagination
        showingLhs={
          entity.total > 0 ? (entity.pageNumber - 1) * entity.perPage + 1 : 0
        }
        showingRhs={Math.min(
          (entity.pageNumber - 1) * entity.perPage + entity.perPage,
          entity.total,
        )}
        showingTotal={entity.total}
        perPage={entity.perPage}
        setPerPage={setPerPage}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default MaterialsList;
