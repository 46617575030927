const FilledButton = ({ text, colour, onClick, className }) => {
  return (
    <div
      className={`px-4 py-1.5 rounded bg-${colour} cursor-pointer ${className} `}
      onClick={onClick}>
      <div className={`text-sm text-white font-semibold`}>{text}</div>
    </div>
  );
};
export default FilledButton;
