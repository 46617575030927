import moment from "moment-timezone";

const ReceivedAgeTableColumns = [
  {
    title: "SKU",
    dataIndex: "Productvariants.sku",
    key: "Productvariants.sku",
    render: (text) => <a>{text}</a>,
  },

  {
    title: "Last Received",
    dataIndex: "Productvariants.last_received",
    key: "Productvariants.last_received",
    render: (text) => (
      <a className="text-black">{text && moment(text).format("LL")}</a>
    ),
  },
  {
    title: "Last Putaway",
    dataIndex: "Productvariants.last_putaway",
    key: "Productvariants.last_putaway",
    render: (text) => (
      <a className="text-black">{text && moment(text).format("LL")}</a>
    ),
  },
  {
    title: "Received Age (in Days)",
    dataIndex: "Productvariants.doc_to_stock",
    key: "Productvariants.doc_to_stock",
    render: (text) => (
      <a className="text-black">
        {Math.ceil(parseInt(text) / (1000 * 3600 * 24))}
      </a>
    ),
  },
  {
    title: "Name",
    dataIndex: ["Productvariants.attributesCollectionname"],
    key: "Productvariants.attributesCollectionname",
    render: (text) => (
      <div className="flex flex-col">
        <p>{text}</p>
      </div>
    ),
  },
  {
    title: "Color",
    dataIndex: ["Productvariants.attributesColorname"],
    key: "Productvariants.attributesColorname",
    render: (text) => (
      <div className="flex flex-col">
        <p>{text}</p>
      </div>
    ),
  },
  {
    title: "Size",
    dataIndex: ["Productvariants.attributesSizename"],
    key: "Productvariants.attributesSizename",
    render: (text) => (
      <div className="flex flex-col">
        <p>{text}</p>
      </div>
    ),
  },
];

const ReceivedAgeQuery = {
  dimensions: [
    "Productvariants.sku",
    "Productvariants.last_received",
    "Productvariants.last_putaway",
    "Productvariants.doc_to_stock",
    "Productvariants.attributesCollectionname",
    "Productvariants.attributesColorname",
    "Productvariants.attributesSizename",
  ],
  timeDimensions: [],
  order: [["Productvariants.doc_to_stock", "desc"]],
};

const InventoryAgeTableColumns = [
  {
    title: "SKU",
    dataIndex: "Productvariants.sku",
    key: "Productvariants.sku",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Bin",
    dataIndex: "Skubinmappings.binlocation",
    key: "Skubinmappings.binlocation",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Putaway",
    dataIndex: "Skubinmappings.created_at",
    key: "Skubinmappings.created_at",
    render: (text) => (
      <a className="text-black">{text && moment(text).format("LL")}</a>
    ),
  },

  {
    title: "Last Received",
    dataIndex: "Productvariants.last_received",
    key: "Productvariants.last_received",
    render: (text) => (
      <a className="text-black">{text && moment(text).format("LL")}</a>
    ),
  },
  {
    title: "Last Fulfilled",
    dataIndex: "Productvariants.last_fulfilled",
    key: "Productvariants.last_fulfilled",
    render: (text) => (
      <a className="text-black">{text && moment(text).format("LL")}</a>
    ),
  },
  {
    title: "Inventory Age (in Days)",
    dataIndex: "Productvariants.inventory_age",
    key: "Productvariants.inventory_age",
    render: (text) => (
      <a className="text-black">
        {Math.ceil(parseInt(text) / (1000 * 3600 * 24))}
      </a>
    ),
  },
];

const InventoryAgeQuery = {
  dimensions: [
    "Productvariants.sku",
    "Productvariants.last_received",
    "Productvariants.last_fulfilled",
    "Productvariants.inventory_age",
    "Productvariants.since_received",
    "Skubinmappings.binlocation",
    "Skubinmappings.created_at",
  ],
  timeDimensions: [],
  order: [["Productvariants.since_received", "desc"]],
};

const ShelfTimeTableColumns = [
  {
    title: "SKU",
    dataIndex: "Skubinmappings.sku",
    key: "Skubinmappings.sku",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Bin Location",
    dataIndex: "Skubinmappings.binlocation",
    key: "Skubinmappings.binlocation",
    render: (text) => <a className="text-black">{text}</a>,
  },

  {
    title: "Quantity",
    dataIndex: "Skubinmappings.unallocatedQuantity",
    key: "Skubinmappings.unallocatedQuantity",
    render: (text) => <a className="text-black">{text}</a>,
  },

  {
    title: "Shelf Time (in Days)",
    dataIndex: "Skubinmappings.shelf_time",
    key: "Skubinmappings.shelf_time",
    render: (text) => <a className="text-black">{parseInt(text)}</a>,
  },
  {
    title: "Name",
    dataIndex: ["Productvariants.attributesCollectionname"],
    key: "Skubinmappings.updated_at",
    render: (text) => (
      <div className="flex flex-col">
        <p>{text}</p>
      </div>
    ),
  },
  {
    title: "Color",
    dataIndex: ["Productvariants.attributesColorname"],
    key: "Skubinmappings.updated_at",
    render: (text) => (
      <div className="flex flex-col">
        <p>{text}</p>
      </div>
    ),
  },
  {
    title: "Size",
    dataIndex: ["Productvariants.attributesSizename"],
    key: "Skubinmappings.updated_at",
    render: (text) => (
      <div className="flex flex-col">
        <p>{text}</p>
      </div>
    ),
  },
];

const ShelfTimeQuery = {
  dimensions: [
    "Skubinmappings.sku",
    "Skubinmappings.unallocatedQuantity",
    "Skubinmappings.shelf_time",
    "Skubinmappings.binlocation",
    "Productvariants.attributesCollectionname",
    "Productvariants.attributesColorname",
    "Productvariants.attributesSizename",
  ],
  timeDimensions: [],
  order: [["Skubinmappings.shelf_time", "desc"]],
  filters: [
    {
      member: "Skubinmappings.binlocationtype",
      operator: "equals",
      values: ["BIN"],
    },
    {
      member: "Skubinmappings.unallocatedQuantity",
      operator: "gt",
      values: ["0"],
    },
  ],
};
export const makeDetailedStatQueries = (
  dateRange,
  customDate,
  searchTerm,
  tenantId,
  customers,
  warehouses
) => {
  const appliedDate =
    dateRange === "Custom Date"
      ? { dateRange: [customDate.start, customDate.end] }
      : { dateRange };
  return [
    {
      title: "Active SKUs",
      statKey: "ACTIVE_SKU",
      tableColumns: [
        {
          title: "SKU",
          dataIndex: "Productvariants.sku",
          key: "Productvariants.sku",
          render: (text) => <a>{text}</a>,
        },
        {
          title: "Last Received",
          dataIndex: "Productvariants.last_received",
          key: "Productvariants.last_received",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Last Putaway",
          dataIndex: "Productvariants.last_putaway",
          key: "Productvariants.last_putaway",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Name",
          dataIndex: ["Productvariants.attributesCollectionname"],
          key: "Productvariants.attributesCollectionname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Color",
          dataIndex: ["Productvariants.attributesColorname"],
          key: "Productvariants.attributesColorname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Size",
          dataIndex: ["Productvariants.attributesSizename"],
          key: "Productvariants.attributesSizename",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
      ],
      query: {
        dimensions: [
          "Productvariants.sku",
          "Productvariants.last_putaway",
          "Productvariants.last_received",
          "Productvariants.attributesCollectionname",
          "Productvariants.attributesColorname",
          "Productvariants.attributesSizename",
        ],
        timeDimensions: [],
        order: {
          "Productvariants.last_putaway": "desc",
          "Productvariants.last_received": "desc",
        },
        filters: [
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: `SKUs Putaway in ${dateRange}`,
      statKey: "TOTAL_PUTAWAY",
      tableColumns: [
        {
          title: "SKU",
          dataIndex: "Productvariants.sku",
          key: "Productvariants.sku",
          render: (text) => <a>{text}</a>,
        },
        {
          title: "Last Putaway",
          dataIndex: "Productvariants.last_putaway",
          key: "Productvariants.last_putaway",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Name",
          dataIndex: ["Productvariants.attributesCollectionname"],
          key: "Productvariants.attributesCollectionname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Color",
          dataIndex: ["Productvariants.attributesColorname"],
          key: "Productvariants.attributesColorname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Size",
          dataIndex: ["Productvariants.attributesSizename"],
          key: "Productvariants.attributesSizename",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
      ],
      query: {
        dimensions: [
          "Productvariants.sku",
          "Productvariants.last_putaway",
          "Productvariants.attributesCollectionname",
          "Productvariants.attributesColorname",
          "Productvariants.attributesSizename",
        ],
        timeDimensions: [
          {
            dimension: "Productvariants.last_putaway",
            ...appliedDate,
          },
        ],
        filters: [
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
        order: {
          "Productvariants.last_putaway": "asc",
        },
      },
    },
    {
      title: "Received SKUs",
      statKey: "TOTAL_RECEIVED",
      tableColumns: [
        {
          title: "SKU",
          dataIndex: "Productvariants.sku",
          key: "Productvariants.sku",
          render: (text) => <a>{text}</a>,
        },
        {
          title: "Last Received",
          dataIndex: "Productvariants.last_received",
          key: "Productvariants.last_received",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Name",
          dataIndex: ["Productvariants.attributesCollectionname"],
          key: "Productvariants.attributesCollectionname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Color",
          dataIndex: ["Productvariants.attributesColorname"],
          key: "Productvariants.attributesColorname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Size",
          dataIndex: ["Productvariants.attributesSizename"],
          key: "Productvariants.attributesSizename",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
      ],
      query: {
        dimensions: [
          "Productvariants.sku",
          "Productvariants.last_received",
          "Productvariants.attributesCollectionname",
          "Productvariants.attributesColorname",
          "Productvariants.attributesSizename",
        ],
        timeDimensions: [
          {
            dimension: "Productvariants.last_received",
            ...appliedDate,
          },
        ],
        order: {
          "Productvariants.last_received": "desc",
        },
        filters: [
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKUs Awaiting Putaway",
      statKey: "AWAITING_PUTAWAY",
      tableColumns: [
        {
          title: "SKU",
          dataIndex: "Productvariants.sku",
          key: "Productvariants.sku",
          render: (text) => <a>{text}</a>,
        },
        {
          title: "Last Received",
          dataIndex: "Productvariants.last_received",
          key: "Productvariants.last_received",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Name",
          dataIndex: ["Productvariants.attributesCollectionname"],
          key: "Productvariants.attributesCollectionname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Color",
          dataIndex: ["Productvariants.attributesColorname"],
          key: "Productvariants.attributesColorname",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
        {
          title: "Size",
          dataIndex: ["Productvariants.attributesSizename"],
          key: "Productvariants.attributesSizename",
          render: (text) => (
            <div className="flex flex-col">
              <p>{text}</p>
            </div>
          ),
        },
      ],
      query: {
        dimensions: [
          "Productvariants.sku",
          "Productvariants.last_received",
          "Productvariants.attributesCollectionname",
          "Productvariants.attributesColorname",
          "Productvariants.attributesSizename",
        ],
        timeDimensions: [
          {
            dimension: "Productvariants.last_received",
            ...appliedDate,
          },
        ],
        order: {
          "Productvariants.sku": "asc",
        },
        segments: ["Productvariants.skus_awaiting_putaway"],
        filters: [
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Received SKUs",
      statKey: "TOTAL_RECEIVED",
      tableColumns: [
        {
          title: "SKU",
          dataIndex: "Productvariants.sku",
          key: "Productvariants.sku",
          render: (text) => <a>{text}</a>,
        },
        {
          title: "Last Received",
          dataIndex: "Productvariants.last_received",
          key: "Productvariants.last_received",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
      ],
      query: {
        dimensions: ["Productvariants.sku", "Productvariants.last_received"],
        timeDimensions: [
          {
            dimension: "Productvariants.last_received",
            ...appliedDate,
          },
        ],
        order: {
          "Productvariants.last_received": "desc",
        },
        filters: [
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Doc To Stock (in Days)",
      statKey: "AVG_DOC_TO_STOCK",
      tableColumns: [
        {
          title: "SKU",
          dataIndex: "Productvariants.sku",
          key: "Productvariants.sku",
          render: (text) => <a>{text}</a>,
        },
        {
          title: "Last Received",
          dataIndex: "Productvariants.last_received",
          key: "Productvariants.last_received",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Last Putaway",
          dataIndex: "Productvariants.last_putaway",
          key: "Productvariants.last_putaway",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Doc to Stock (in Days)",
          dataIndex: "Productvariants.doc_to_stock",
          key: "Productvariants.doc_to_stock",
          render: (text) => <a className="text-black">{text}</a>,
        },
      ],
      query: {
        dimensions: [
          "Productvariants.sku",
          "Productvariants.last_received",
          "Productvariants.last_putaway",
        ],
        timeDimensions: [],
        order: [["Productvariants.doc_to_stock", "desc"]],
        filters: [
          {
            member: "Productvariants.doc_to_stock",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Shelf time (in Days)",
      statKey: "AVG_SHELF",
      tableColumns: [...ShelfTimeTableColumns],
      query: {
        ...ShelfTimeQuery,
        filters: [
          {
            member: "Productvariants.shelf_time",
            operator: "gt",
            values: ["0"],
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Shelf time <15 Days",
      statKey: "SHELF_TIME_LT_15",
      tableColumns: [...ShelfTimeTableColumns],
      query: {
        ...ShelfTimeQuery,
        filters: [
          ...ShelfTimeQuery.filters,
          {
            member: "Skubinmappings.shelf_time",
            operator: "lt",
            values: [(15).toString()],
          },
          {
            member: "Skubinmappings.shelf_time",
            operator: "gt",
            values: ["0"],
          },
          {
            member: "Skubinmappings.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Shelf time 15 - 30 Days",
      statKey: "SHELF_TIME_15_30",
      tableColumns: [...ShelfTimeTableColumns],
      query: {
        ...ShelfTimeQuery,
        filters: [
          ...ShelfTimeQuery.filters,
          {
            member: "Skubinmappings.shelf_time",
            operator: "gt",
            values: [(15).toString()],
          },
          {
            member: "Skubinmappings.shelf_time",
            operator: "lt",
            values: [(30).toString()],
          },
          {
            member: "Skubinmappings.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Shelf time 30 - 45 Days",
      statKey: "SHELF_TIME_30_45",
      tableColumns: [...ShelfTimeTableColumns],
      query: {
        ...ShelfTimeQuery,
        filters: [
          ...ShelfTimeQuery.filters,
          {
            member: "Skubinmappings.shelf_time",
            operator: "gt",
            values: [(30).toString()],
          },
          {
            member: "Skubinmappings.shelf_time",
            operator: "lt",
            values: [(45).toString()],
          },
          {
            member: "Skubinmappings.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Shelf time 45 - 60 Days",
      statKey: "SHELF_TIME_45_60",
      tableColumns: [...ShelfTimeTableColumns],
      query: {
        ...ShelfTimeQuery,
        filters: [
          ...ShelfTimeQuery.filters,
          {
            member: "Skubinmappings.shelf_time",
            operator: "gt",
            values: [(45).toString()],
          },
          {
            member: "Skubinmappings.shelf_time",
            operator: "lt",
            values: [(60).toString()],
          },
          {
            member: "Skubinmappings.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Shelf time >60 Days",
      statKey: "SHELF_TIME_GT_60",
      tableColumns: [...ShelfTimeTableColumns],
      query: {
        ...ShelfTimeQuery,
        filters: [
          ...ShelfTimeQuery.filters,
          {
            member: "Skubinmappings.shelf_time",
            operator: "gt",
            values: [(60).toString()],
          },
          {
            member: "Skubinmappings.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Received Age <2 Days",
      statKey: "RECEIVED_AGE_LT_2",
      tableColumns: [...ReceivedAgeTableColumns],
      query: {
        ...ReceivedAgeQuery,
        filters: [
          {
            member: "Productvariants.doc_to_stock",
            operator: "lte",
            values: [(2 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.doc_to_stock",
            operator: "gte",
            values: ["0"],
          },
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.last_putaway_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Received Age 2-4 Days",
      statKey: "RECEIVED_AGE_2_4",
      tableColumns: [...ReceivedAgeTableColumns],
      query: {
        ...ReceivedAgeQuery,
        filters: [
          {
            member: "Productvariants.doc_to_stock",
            operator: "gt",
            values: [(2 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.doc_to_stock",
            operator: "lte",
            values: [(4 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.last_putaway_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Received Age 4-7 Days",
      statKey: "RECEIVED_AGE_4_7",
      tableColumns: [...ReceivedAgeTableColumns],
      query: {
        ...ReceivedAgeQuery,
        filters: [
          {
            member: "Productvariants.doc_to_stock",
            operator: "gt",
            values: [(4 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.doc_to_stock",
            operator: "lte",
            values: [(7 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.last_putaway_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Received Age 7-14 Days",
      statKey: "RECEIVED_AGE_7_14",
      tableColumns: [...ReceivedAgeTableColumns],
      query: {
        ...ReceivedAgeQuery,
        filters: [
          {
            member: "Productvariants.doc_to_stock",
            operator: "gt",
            values: [(7 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.doc_to_stock",
            operator: "lte",
            values: [(14 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.last_putaway_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Received Age >14 Days",
      statKey: "RECEIVED_AGE_GT_14",
      tableColumns: [...ReceivedAgeTableColumns],
      query: {
        ...ReceivedAgeQuery,
        filters: [
          {
            member: "Productvariants.doc_to_stock",
            operator: "gt",
            values: [(14 * 24 * 3600 * 1000).toString()],
          },
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.last_putaway_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Inventory Age <2 Days",
      statKey: "INVENTORY_AGE_LT_2",
      tableColumns: [...InventoryAgeTableColumns],
      query: {
        ...InventoryAgeQuery,
        filters: [
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
          {
            member: "Skubinmappings.unallocatedQuantity",
            operator: "gt",
            values: ["0"],
          },
        ],
        segments: ["Productvariants.seg_ig_lt_2"],
      },
    },
    {
      title: "Inventory Age 2-4 Days",
      statKey: "INVENTORY_AGE_2_4",
      tableColumns: [...InventoryAgeTableColumns],
      query: {
        ...InventoryAgeQuery,
        filters: [
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
          {
            member: "Skubinmappings.unallocatedQuantity",
            operator: "gt",
            values: ["0"],
          },
        ],
        segments: ["Productvariants.seg_ig_gt_2_lt_4"],
      },
    },
    {
      title: "Inventory Age 4-7 Days",
      statKey: "INVENTORY_AGE_4_7",
      tableColumns: [...InventoryAgeTableColumns],
      query: {
        ...InventoryAgeQuery,
        filters: [
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
          {
            member: "Skubinmappings.unallocatedQuantity",
            operator: "gt",
            values: ["0"],
          },
        ],
        segments: ["Productvariants.seg_ig_gt_4_lt_7"],
      },
    },
    {
      title: "Inventory Age 7-14 Days",
      statKey: "INVENTORY_AGE_7_14",
      tableColumns: [...InventoryAgeTableColumns],
      query: {
        ...InventoryAgeQuery,
        filters: [
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
          {
            member: "Skubinmappings.unallocatedQuantity",
            operator: "gt",
            values: ["0"],
          },
        ],
        segments: ["Productvariants.seg_ig_gt_7_lt_14"],
      },
    },
    {
      title: "Inventory Age >14 Days",
      statKey: "INVENTORY_AGE_GT_14",
      tableColumns: [...InventoryAgeTableColumns],
      query: {
        ...InventoryAgeQuery,
        filters: [
          {
            member: "Productvariants.last_received_str",
            operator: "set",
          },
          {
            member: "Productvariants.sku",
            operator: "contains",
            values: [searchTerm],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
          {
            member: "Skubinmappings.unallocatedQuantity",
            operator: "gt",
            values: ["0"],
          },
        ],
        segments: ["Productvariants.seg_ig_gt_14"],
      },
    },
  ];
};
