import _, { capitalize, isEmpty } from "lodash";
import { ArrowLeftIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { useState, useEffect, useContext } from "react";
import TariffSidePanel from "./TariffSidePanel";
import InformationIcon from "#components/utils/InformationIcon";
import TariffAccordion from "./TariffAccordion";
import { AddClientModal, StatusIcon } from "./BillingProfilesList";
import { BillingStatus } from "../../pages/billing-profiles";
import subTariffIcon1 from "../../static/images/subtariff1.png";
import subTariffIcon2 from "../../static/images/subtariff2.png";
import subTariffIcon3 from "../../static/images/subtariff3.png";
import subTariffIcon4 from "../../static/images/subtariff4.png";
import subTariffIcon5 from "../../static/images/subtariff5.png";
import ReactTooltip from "react-tooltip";
import { AppStateContext } from "../../contexts/appState";

const BillingProfileSummary = ({
  setSelectedProfile,
  billingProfileFormConfig,
  saveBillingProfile,
  billingProfile,
  setBillingProfile,
}) => {
  const [fulfillmentType, setFulfillmentType] = useState(
    billingProfileFormConfig?.enums?.fulfillmentTypes[0],
  );
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [tariffType, setTariffType] = useState(null);
  const [subTariffType, setSubTariffType] = useState(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  let isDraft = billingProfile.status === BillingStatus.DRAFT;
  // billing profile is in edit mode if there is no ID or the saved profile is in DRAFT status
  let isEditMode = !billingProfile.id || isDraft;
  const subTariffIcons = [
    subTariffIcon1,
    subTariffIcon2,
    subTariffIcon3,
    subTariffIcon4,
    subTariffIcon5,
  ];

  const appState = useContext(AppStateContext);
  const saveTariff = (updatedTariff) => {
    let existingTariff = billingProfile.tariffs.find((tariff) => {
      return (
        tariff.tariffType === updatedTariff.tariffType &&
        tariff.subTariffType === updatedTariff.subTariffType &&
        tariff.fulfillmentType === updatedTariff.fulfillmentType
      );
    });
    if (existingTariff) {
      setBillingProfile({
        ...billingProfile,
        tariffs: billingProfile.tariffs.map((tariff) => {
          if (
            tariff.tariffType === updatedTariff.tariffType &&
            tariff.subTariffType === updatedTariff.subTariffType &&
            tariff.fulfillmentType === updatedTariff.fulfillmentType
          )
            return updatedTariff;
          return tariff;
        }),
      });
    } else {
      setBillingProfile({
        ...billingProfile,
        tariffs: [...billingProfile.tariffs, updatedTariff],
      });
    }

    setShowSidePanel(false);
  };

  const deleteTariff = (selectedTariff) => {
    setBillingProfile({
      ...billingProfile,
      tariffs: billingProfile.tariffs.filter((tariff) => {
        return !(
          tariff.tariffType === selectedTariff.tariffType &&
          tariff.subTariffType === selectedTariff.subTariffType &&
          tariff.fulfillmentType === selectedTariff.fulfillmentType
        );
      }),
    });
  };

  useEffect(() => {
    setFulfillmentType(billingProfileFormConfig?.enums?.fulfillmentTypes[0]);
    if (isEmpty(billingProfile)) {
      setBillingProfile({
        currency: billingProfileFormConfig?.enums?.currency[0],
        metricSystem: billingProfileFormConfig?.enums?.metricSystems[0],
        tariffs: [],
      });
    }
  }, [billingProfileFormConfig]);

  const SavedTariffCard = ({ savedTariff }) => {
    const [selectedTier, setSelectedTier] = useState(0);
    return (
      <div>
        <div className="flex gap-2 my-3">
          {savedTariff?.tiers?.map((tier, index) => {
            return (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTier(index);
                }}
                className={`${
                  selectedTier === index
                    ? "border-b border-[#224E73] text-[#224E73]"
                    : "text-[#717679]"
                }`}>
                Rate {index + 1}
              </button>
            );
          })}
        </div>
        <div className="flex gap-4">
          <div>
            <div className="text-[#94999C] text-[14px]">Rate Type</div>
            <div className="text-[16px]">
              {savedTariff.flatRate ? "Flat" : "Variable"}
            </div>
          </div>

          {savedTariff.resetTime && (
            <div>
              <div className="text-[#94999C] text-[14px]">Frequency</div>
              <div className="text-[16px]">{savedTariff.resetTime}</div>
            </div>
          )}

          {savedTariff.tiers &&
            savedTariff.tiers.length > 0 &&
            savedTariff.tiers[selectedTier].ranges.map((range) => {
              let uom = savedTariff.uoms.find((uom) => uom.id === range.id);
              return (
                <>
                  <div>
                    <div className="text-[#94999C] text-[14px]">Value</div>
                    <div className="text-[16px]">
                      {range.start}
                      {range.end && `- ${range.end}`}
                    </div>
                  </div>
                  <div>
                    <div className="text-[#94999C] text-[14px]">Entity</div>
                    <div className="text-[16px]">
                      {uom?.entity}
                    </div>
                  </div>
                </>
              );
            })}

          <div>
            <div className="text-[#94999C] text-[14px]">Fees</div>
            <div className="text-[16px]">
              {savedTariff.tiers && savedTariff.tiers.length > 0
                ? savedTariff.tiers[selectedTier].rate
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-2 py-2">
          <button onClick={() => setSelectedProfile(null)}>
            <ArrowLeftIcon className="h-6 w-6 my-auto" />
          </button>
          <h2 className="text-black text-[24px] font-semibold ">
            {isEditMode ? "Add Billing Profile" : billingProfile.name}
          </h2>
          {billingProfile.status && (
            <StatusIcon status={billingProfile.status} />
          )}
        </div>
        {billingProfile.status === BillingStatus.ACTIVE && (
          <div className="flex gap-2">
            <button
              onClick={() => setShowAddClientModal(true)}
              className="px-8 rounded-lg py-1.5 my-2 bg-[#C93A31] hover:opacity-80 text-[16px] text-white">
              Add Clients
            </button>

            <button
              onClick={() => {
                appState.showConfirmation(
                  "Deactivate Billing Profile",
                  `Are you sure you want to deactivate ${
                    billingProfile.name || ""
                  } ?`,
                  () => {
                    saveBillingProfile({
                      ...billingProfile,
                      status: BillingStatus.INACTIVE,
                    });
                  },
                  appState.hideConfirmation,
                );
              }}
              className="px-8 rounded-lg py-1.5 my-2 bg-[#417492] hover:opacity-80 text-[16px] text-white">
              Deactivate
            </button>
          </div>
        )}
      </div>
      {isEditMode && (
        <div className="text-[#717679] text-[14px]">
          Create and manage your billing profiles from here
        </div>
      )}

      {isEditMode && (
        <>
          <div className="text-[20px] mt-4 font-semibold">Basic Info</div>
          <div className="flex gap-2 my-2">
            <div className="text-[14px] text-[#717679]">
              Billing Profile Name
            </div>
            <InformationCircleIcon
              data-tip
              data-for="profileNameInfo"
              className="text-xs cursor-pointer"
              width={15}
            />
            <ReactTooltip id="profileNameInfo" place="top" effect="solid">
              Profile name is a unique identifier. Please make sure it is
              distinct and easy to recognise.
            </ReactTooltip>
          </div>
          <input
            value={billingProfile.name}
            onChange={(e) =>
              setBillingProfile({ ...billingProfile, name: e.target.value })
            }
            className="border border-[#DDDFE0] rounded bg-transparent p-1 w-[500px]"
          />
        </>
      )}
      <div className="flex gap-2 w-[500px] mt-4">
        <div className="w-full">
          <div className="text-[14px] text-[#717679] my-2">Currency</div>
          {isEditMode ? (
            <select
              value={billingProfile?.currency}
              onChange={(e) =>
                setBillingProfile({
                  ...billingProfile,
                  currency: e.target.value,
                })
              }
              className="border border-[#DDDFE0] rounded bg-transparent p-1 w-full">
              {billingProfileFormConfig?.enums?.currency.map(
                (currency, index) => {
                  return <option key={index}>{currency}</option>;
                },
              )}
            </select>
          ) : (
            <div>{billingProfile.currency}</div>
          )}
        </div>
        <div className="w-full">
          <div className="text-[14px] text-[#717679] my-2">Metric System</div>
          {isEditMode ? (
            <select
              value={billingProfile?.metricSystem}
              onChange={(e) =>
                setBillingProfile({
                  ...billingProfile,
                  metricSystem: e.target.value,
                })
              }
              className="border border-[#DDDFE0] rounded bg-transparent p-1 w-full">
              {billingProfileFormConfig?.enums?.metricSystems.map(
                (metricSystem, index) => {
                  return <option key={index}>{metricSystem}</option>;
                },
              )}
            </select>
          ) : (
            <div>{billingProfile.metricSystem}</div>
          )}
        </div>
      </div>

      <div className="text-[20px] mt-8 font-semibold">Manage Tariffs</div>

      <div className="flex gap-4">
        {billingProfileFormConfig?.enums?.fulfillmentTypes.map(
          (fulfillment, index) => {
            return (
              <button
                key={index}
                onClick={() => setFulfillmentType(fulfillment)}
                className={`${
                  fulfillmentType === fulfillment
                    ? "text-[#224E73] border-[#224E73]"
                    : "text-[#717679]"
                } border-b-2 border-transparent text-[16px] my-2`}>
                {capitalize(fulfillment.replace("_", "-"))}
              </button>
            );
          },
        )}
      </div>
      <p className="text-[#717679] text-[14px] my-2">
        For the selected Fulfilment Service, you can add tariffs under different
        categories by clicking on them
      </p>

      {billingProfileFormConfig?.fulfillmentTariffCombinations[
        fulfillmentType
      ].map((fulfillment, index) => {
        let savedSubTariffs = billingProfile?.tariffs?.filter(
          (tariff) =>
            tariff.fulfillmentType === fulfillmentType &&
            tariff.tariffType === fulfillment.tariff,
        )?.length;
        return (
          <TariffAccordion
            icon={subTariffIcons[index]}
            key={index}
            title={fulfillment.tariff}
            subtitle={`${savedSubTariffs} tariffs selected`}>
            <div className="flex flex-wrap gap-2">
              {fulfillment.subTariffs.map((subTariff, index) => {
                let savedTariff = billingProfile.tariffs?.find((tariff) => {
                  return (
                    tariff.fulfillmentType === fulfillmentType &&
                    tariff.subTariffType === subTariff &&
                    tariff.tariffType === fulfillment.tariff
                  );
                });
                return (
                  <div
                    key={index}
                    onClick={(e) => {
                      setTariffType(fulfillment.tariff);
                      setSubTariffType(subTariff);
                      setShowSidePanel(true);
                    }}
                    className={`${
                      savedTariff
                        ? "bg-white border-[#DDDFE0]"
                        : "bg-[#F9F9F9] border-transparent"
                    } border rounded-lg w-[500px] h-[170px] flex flex-col p-4 relative cursor-pointer`}>
                    <div className="flex justify-between">
                      <div className="text-[#111827] text-[18px] font-semibold">
                        {subTariff.replace("_", "-")}
                      </div>

                      <div className="flex gap-2">
                        {isEditMode && savedTariff && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              appState.showConfirmation(
                                "Delete Tariff",
                                "Are you sure you want to delete the tariff?",
                                () => {
                                  deleteTariff(savedTariff);
                                  appState.hideConfirmation();
                                },
                                appState.hideConfirmation,
                              );
                            }}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20"
                                stroke="#C93A31"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        )}

                        {isEditMode && (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 6H6C4.89543 6 4 6.89543 4 8V18C4 19.1046 4.89543 20 6 20H16C17.1046 20 18 19.1046 18 18V14M14 4H20M20 4V10M20 4L10 14"
                              stroke="#224E73"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    {savedTariff ? (
                      <SavedTariffCard savedTariff={savedTariff} />
                    ) : (
                      <div className="mt-4 text-[#94999C]">
                        No tariffs added this sub category
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </TariffAccordion>
        );
      })}

      {showSidePanel && (
        <TariffSidePanel
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          tariffType={tariffType}
          subTariffType={subTariffType}
          fulfillmentType={fulfillmentType}
          billingProfileFormConfig={billingProfileFormConfig}
          savedTariff={billingProfile.tariffs?.find((tariff) => {
            return (
              tariff.fulfillmentType === fulfillmentType &&
              tariff.subTariffType === subTariffType &&
              tariff.tariffType === tariffType
            );
          })}
          currency={billingProfile.currency}
          billingProfile={billingProfile}
          saveTariff={saveTariff}
          isEditMode={isEditMode}
        />
      )}

      {isEditMode && (
        <div className="flex gap-4 justify-end my-2">
          <button
            onClick={() => setSelectedProfile(null)}
            className="px-8 rounded-lg hover:opacity-80 py-1.5 border border-[#DDD] text-[16px]">
            Cancel
          </button>
          <button
            onClick={() => {
              appState.showConfirmation(
                `Create Billing Profile`,
                <>
                  <div>
                    Are you sure you want to save {billingProfile.name || ""} as
                    Draft?
                  </div>
                  <p className="text-[14px] text-gray-600">
                    Note: Only Active profiles can be assigned to clients.
                  </p>
                </>,
                () => {
                  saveBillingProfile({
                    ...billingProfile,
                    status: BillingStatus.DRAFT,
                  });
                  appState.hideConfirmation();
                },
                appState.hideConfirmation,
              );
            }}
            className="px-8 rounded-lg py-1.5 bg-[#C93A31] hover:opacity-80 text-[16px] text-white">
            Save as Draft
          </button>
          <button
            onClick={() => {
              appState.showConfirmation(
                "Create Billing Profile",
                <>
                  <div>
                    Are you sure you want to submit {billingProfile.name || ""}?
                  </div>
                  <p className="text-[14px] text-gray-600 w-[400px] m-auto leading-normal">
                    Note: After submission, the profile will become active,
                    allowing you to assign it to one or more clients.
                  </p>
                </>,
                () => {
                  saveBillingProfile({
                    ...billingProfile,
                    status: BillingStatus.ACTIVE,
                  });
                  appState.hideConfirmation();
                },
                appState.hideConfirmation,
              );
            }}
            className="px-8 rounded-lg py-1.5 bg-[#417492] hover:opacity-80 text-[16px] text-white">
            Submit
          </button>
        </div>
      )}
      {showAddClientModal && (
        <AddClientModal dismiss={() => setShowAddClientModal(false)} />
      )}
    </div>
  );
};

export default BillingProfileSummary;
