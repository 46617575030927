import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { useState } from "react";

const AssignBillingProfileForm = ({
  negativeAction,
  billingProfileOptions,
  onSubmit,
  onChangeBillingProfile,
  selectedBillingProfile,
}) => {
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(null);
  return (
    <Modal
      title={"Assign Billing Profile"}
      negativeAction={negativeAction}
      positiveAction={onSubmit}
      initialFocus={null}>
      <div className="flex space-x-6 mt-4">
        <Dropdown
          placeholder={"Select Billing Profile"}
          list={billingProfileOptions}
          labelKey="name"
          valueKey="id"
          name="billingProfiles"
          setSelected={(e) => {
            onChangeBillingProfile(e);
          }}
          selectedValue={selectedBillingProfile}
        />
      </div>
      <div className="flex space-x-6 mt-4">
        <Dropdown
          placeholder={"Select Billing Period"}
          list={[
            { name: "Weekly" },
            { name: "Monthly" },
            { name: "Quarterly" },
          ]}
          labelKey="name"
          valueKey="name"
          name="billingPeriod"
          setSelected={(e) => {
            setSelectedBillingPeriod(e);
          }}
          selectedValue={selectedBillingPeriod}
        />
      </div>
    </Modal>
  );
};

export default AssignBillingProfileForm;
