import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const MultipleSelectStationForm = ({
  onClose,
  title,
  onChangeDropdown,
  selectedStation,
  onSubmit,
  stations,
  negativeAction,
}) => {
  let history = useHistory();
  return (
    <Modal
      title={title}
      negativeAction={negativeAction ?? (() => history.goBack())}
      positiveAction={onSubmit}
      onClose={onClose ?? (() => history.goBack())}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Station
          </span>
        </div>

        <div>
          <Dropdown
            placeholder={"Station"}
            list={stations}
            labelKey="name"
            valueKey="id"
            name="selectedStation"
            setSelected={onChangeDropdown}
            selectedValue={selectedStation}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MultipleSelectStationForm;
