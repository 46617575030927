import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import SearchSortFilter from "#components/common/SearchSortFilter";
import QuickFilters from "#components/common/QuickFilters";
import Modal from "#components/utils/Modal";
import { useState } from "react";
import { Tab } from "@headlessui/react";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const headers = [
  "Code",
  "Name",
  "Email",
  "Phone",
  "Products",
  "Addresses",
  "Status",
  "Warehouse",
  "Actions",
];
const noValuesText = "No Vendors";

const VendorList = ({
  vendors,
  filters,
  onChangeFilter,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  editVendor,
  deleteVendor,
  availableInventory,
}) => {
  const [viewConsigModal, setViewConsigModal] = useState(false);
  const [viewConsig, setViewConsig] = useState(null);
  const [viewAddressModal, setViewAddressModal] = useState(false);
  const [viewAddress, setViewAddress] = useState(null);
  let tabs = {};
  let addressTabs = {};

  addressTabs = {
    ...addressTabs,
    Address: <AddressItem item={viewAddress} />,
  };

  tabs = {
    ...tabs,
    Items: <OrderLineItems item={viewConsig} />,
  };

  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      <QuickFilters
        warehouseFilterName={"warehouses"}
        warehouses={warehouses}
        filters={filters}
        onChangeFilter={onChangeFilter}
      />
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Code",
            value: "code",
          },
          {
            name: "Name",
            value: "name",
          },
          {
            name: "Email",
            value: "email",
          },
          {
            name: "Status",
            value: "active",
          },
        ]}
      />
      <div className="overflow-x-auto">
        <CustomTableWithTheme>
          <thead className="p-4 rounded-full bg-primaryAccent">
            <tr className="text-textWhite font-montserrat border-left">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {vendors.length === 0 ? (
              <tr className="bg-white">
                {headers.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {vendors &&
              vendors.length > 0 &&
              vendors.map((vendor, index) => (
                <tr
                  key={vendor.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`p-5 text-left font-semibold tracking-wider rounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {vendor.code}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    {vendor.name}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    {vendor.email}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    {vendor.phone}
                  </td>
                  {/* {console.log(availableInventory,"ooss")} */}
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    <div>
                      <button
                        onClick={() => {
                          setViewConsigModal(true);
                          setViewConsig(vendor?.vendorProducts);
                        }}
                        className="text-sm underline text-2C7695">
                        Click to View
                      </button>
                    </div>
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    <div>
                      <button
                        onClick={() => {
                          setViewAddressModal(true);
                          setViewAddress(vendor?.addresses);
                        }}
                        className="text-sm underline text-2C7695">
                        Click to View
                      </button>
                    </div>
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    {vendor?.active === true ? "Active" : "Inactive"}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    {warehouses &&
                      warehouses.find((item) =>
                        vendor?.warehouses?.includes(item.id),
                      )?.name}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    <div className="flex space-x-4 items-center">
                      <TableFieldButton
                        onClick={() => editVendor(vendor.id)}
                        text={<PencilIcon className="w-6 h-6" />}
                      />
                      <TableFieldButton
                        text={<TrashIcon className="w-6 h-6 " />}
                        // disabled={cycleCountPlan.executions?.length >= 1 ? true : false}
                        onClick={() => deleteVendor(vendor.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </CustomTableWithTheme>
      </div>

      {viewConsigModal && (
        <Modal
          negativeAction={() => setViewConsigModal(false)}
          title={`Vendor Products`}
          noPadding={true}
          positiveAction={() => setViewConsigModal(false)}
          positiveText="OK">
          <div className="bg-EFE9DC p-4 overflow-auto">
            <Tab.Group>
              <Tab.List className="flex items-center">
                <div className="flex-1 p-1 px-0 space-x-4 rounded-xl"></div>
              </Tab.List>
              <Tab.Panels className="mt-2">
                {tabs &&
                  Object.values(tabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="flex flex-col mt-4 bg-EBEBEB border textWhite-300">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </Modal>
      )}

      {viewAddressModal && (
        <Modal
          negativeAction={() => setViewAddressModal(false)}
          title={`Vendor Addresses`}
          noPadding={true}
          positiveAction={() => setViewAddressModal(false)}
          positiveText="OK">
          <div className="bg-EFE9DC p-4 overflow-auto">
            <Tab.Group>
              <Tab.List className="flex items-center">
                <div className="flex-1 p-1 px-0 space-x-4 rounded-xl"></div>
              </Tab.List>
              <Tab.Panels className="mt-2">
                {addressTabs &&
                  Object.values(addressTabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="flex flex-col mt-4 bg-EBEBEB border textWhite-300">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </Modal>
      )}
    </div>
  );
};

const OrderLineItems = ({ item }) => {
  const headers = ["SKUs", "Name"];
  // {console.log(item, "itemssssggg")}
  if (item && item?.length === 0) {
    return (
      <div className="flex justify-center items-center">
        <p className="text-2C7695 font-montserrat text-lg font-semibold">
          No Products
        </p>
      </div>
    );
  }
  return (
    <table className="px-2 min-w-full divide-y divide-gray-200">
      <thead className="p-4 rounded-full bg-primaryAccent px-12">
        <tr className="text-textWhite font-montserrat border-left px-12">
          {headers.map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {item?.map((i, index) => (
          <tr
            key={i.id}
            className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
              }`}>
              {i?.sku}
            </td>

            <td
              className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
              {i?.name}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const AddressItem = ({ item }) => {
  const headers = [
    "Address",
    "Suite Number",
    "City",
    "State",
    "Pincode",
    "Country",
  ];
  {
    console.log(item, "itemssssggg");
  }
  return (
    <table className="px-2 min-w-full divide-y divide-gray-200">
      <thead className="p-4 rounded-full bg-primaryAccent px-12">
        <tr className="text-textWhite font-montserrat border-left px-12">
          {headers.map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {item.map((i, index) => (
          <tr
            key={i.id}
            className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
              }`}>
              {i?.address}
            </td>

            <td
              className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
              {i?.suiteNumber}
            </td>

            <td
              className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
              {i?.city}
            </td>

            <td
              className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
              {i?.state}
            </td>

            <td
              className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
              {i?.pincode}
            </td>

            <td
              className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
              {i?.country}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default VendorList;
