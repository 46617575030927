import { useState } from "react";
import Stats from "#components/common/Stats";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  RadialBarChart,
  PieChart,
  Pie,
  RadialBar,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  ShoppingCartIcon,
  GiftIcon,
  CashIcon,
  DocumentTextIcon,
  ClipboardListIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/outline";

import TableTabs from "#components/utils/TableTabs";
import { useCubeQuery } from "@cubejs-client/react";
import TotalOrdersRow from "#components/dashboard/TotalOrdersRow";

const query1 = {
  measures: ["orders.completed_count"],
  timeDimensions: [
    {
      dimension: "orders.createdat",
      dateRange: "Last 30 days",
    },
  ],
  order: {
    "orders.createdat": "asc",
  },
  filters: [
    {
      member: "orders.tenant",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.customer",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.warehouse",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
  ],
};

const query2 = {
  measures: ["orders.total_orders_count"],
  timeDimensions: [
    {
      dimension: "orders.createdat",
      dateRange: "Last 30 days",
    },
  ],
  order: {
    "orders.createdat": "asc",
  },
  filters: [
    {
      member: "orders.tenant",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.customer",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
    {
      member: "orders.warehouse",
      operator: "equals",
      values: ["62cdb0ac6227b7ed224d79aa"],
    },
  ],
};

const data = [
  {
    name: "S",
    "Day of Week": 30,
    amt: 15,
  },
  {
    name: "M",
    uv: 3000,
    "Day of Week": 10,
    amt: 45,
  },
  {
    name: "T",
    "Day of Week": 40,
    amt: 34,
  },
  {
    name: "W",
    "Day of Week": 20,
    amt: 50,
  },
  {
    name: "T",
    "Day of Week": 60,
    amt: 30,
  },
  {
    name: "F",
    "Day of Week": 20,
    amt: 40,
  },
  {
    name: "S",
    "Day of Week": 10,
    amt: 10,
  },
];

const orderFillRateChart = (data1, data2) => {
  // data1 = data2 * 0.8
  return (
    <PieChart width={350} height={250} className="m-auto">
      <Pie
        startAngle={0}
        endAngle={180}
        data={[
          { name: "Unprocessed", value: data2 - data1 },
          { name: "Completed", value: data1 },
        ]}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="80%"
        outerRadius={120}
        innerRadius={70}
        fill="#8884d8">
        <Cell key={0} fill={"#F4C261"} />
        <Cell key={1} fill={"#224E73"} />
      </Pie>
      <Legend />
      <Tooltip />
    </PieChart>
  );
};

const orderFillRateRenderer = (
  renderProps1,
  renderProps2,
  setCompletedCount,
  setTotalCount,
) => {
  const resultSet1 = renderProps1.resultSet;
  const error1 = renderProps1.error;
  const resultSet2 = renderProps2.resultSet;
  const error2 = renderProps2.error;
  if (resultSet1 && resultSet2) {
    const completed = resultSet1.chartPivot()[0]
      ? resultSet1.chartPivot()[0]["orders.completed_count"]
      : 0;
    const total = resultSet2.chartPivot()[0]
      ? resultSet2.chartPivot()[0]["orders.total_orders_count"]
      : 0;
    setCompletedCount(completed);
    setTotalCount(total);
    return orderFillRateChart(completed, total);
  } else if (error1 && error1.toString()) {
    return <h4>{error1.toString()}</h4>;
  } else if (error2 && error2.toString()) {
    return <h4>{error2.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const OrderFillRateComponent = ({
  setTotalCount,
  setCompletedCount,
  dateRange,
  tenantId,
  customers,
  warehouses,
}) => {
  query1["timeDimensions"][0]["dateRange"] = dateRange;
  query1["filters"][0].values = [tenantId];
  query1["filters"][1].values = customers;
  query1["filters"][2].values = warehouses;

  query2["timeDimensions"][0]["dateRange"] = dateRange;
  query2["filters"][0].values = [tenantId];
  query2["filters"][1].values = customers;
  query2["filters"][2].values = warehouses;
  const renderProps1 = useCubeQuery(query1);
  const renderProps2 = useCubeQuery(query2);
  return orderFillRateRenderer(
    renderProps1,
    renderProps2,
    setCompletedCount,
    setTotalCount,
  );
};

const OrderLeadTimeRow = ({ dateRange, tenantId, customers, warehouses }) => {
  const [completedCount, setCompletedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  return (
    <div className="grid grid-cols-8 gap-4">
      <div className="bg-white p-2 col-span-4 rounded-xl shadow-lg px-4 py-4">
        <div className="text-lg">Order Fill Rate</div>
        <div className="w-full mt-5">
          <Stats
            stats={[
              {
                name: "Total Orders",
                stat: totalCount.toString(),
              },
              {
                name: "Total Completed",
                stat: completedCount.toString(),
              },
              {
                name: "Unprocessed",
                stat: (totalCount - completedCount).toString(),
              },
            ]}
            grid={"order-fill-rate"}
          />
          <div className="mt-1 text-3xl font-semibold text-gray-900 flex items-center justify-center -mb-10">
            Order Fill Rate:{" "}
            {totalCount
              ? ((completedCount / totalCount) * 100).toFixed(2).toString() +
                "%"
              : "N/A"}
          </div>
          <OrderFillRateComponent
            setCompletedCount={setCompletedCount}
            setTotalCount={setTotalCount}
            dateRange={dateRange}
            key={"fill_rate_" + dateRange.toString().replaceAll(" ", "_")}
            tenantId={tenantId}
            customers={customers}
            warehouses={warehouses}
          />
        </div>
      </div>

      <div className="bg-white p-2 col-span-4 rounded-xl shadow-lg">
        <TotalOrdersRow
          dateRange={dateRange}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
        />
      </div>
    </div>
  );
};

const CountryWise = () => (
  <div className="bg-F8F8F8 p-2">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      <thead>
        <tr className="border-b border-textWhite">
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Name
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Hrs
          </th>

          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            United States
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            12 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-F4C261 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 2%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            Canada
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            12 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-F4C261 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 2%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            Mexico
          </td>
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            12 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-50BFC3 text-white font-medium inline-flex items-center">
              <ArrowDownIcon className="w-4 h-4" /> 2%
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const CustomerWise = () => (
  <div className="bg-F8F8F8 p-2">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      <thead>
        <tr className="border-b border-textWhite">
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Name
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Hrs
          </th>

          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            JCPenney
          </td>
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            4 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-F4C261 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 30%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            Zales
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            5 hours
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-F4C261 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 23%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            Bluestem Fingerhut
          </td>
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            13 hours
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-50BFC3 text-white font-medium inline-flex items-center">
              <ArrowDownIcon className="w-4 h-4" /> 12%
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const ProductWise = () => (
  <div className="bg-F8F8F8 p-2">
    <table className="min-w-full divide-y divide-gray-200 font-montserrat">
      <thead>
        <tr className="border-b border-textWhite">
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            SKU
          </th>
          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Hrs
          </th>

          <th
            scope="col"
            className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            RYKT4450WS
          </td>
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            3.5 hrs
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-red-600 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 70%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            RGS0829BT
          </td>{" "}
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            2.5 hours
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-F4C261 text-white font-medium inline-flex items-center">
              <ArrowUpIcon className="w-4 h-4" /> 22%
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            RGS0212BT
          </td>
          <td className="px-1 py-3 text-left font-medium text-5F666B text-gray-500 tracking-wider">
            8 hours
          </td>
          <td className="px-1 py-3 text-left font-medium text-2C7695 text-gray-500 tracking-wider">
            <div className="border rounded-md p-1 px-6 text-sm text-center bg-50BFC3 text-white font-medium inline-flex items-center">
              <ArrowDownIcon className="w-4 h-4" /> 25%
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
export default OrderLeadTimeRow;
