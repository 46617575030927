import { useState, useEffect} from "react";


const RateShoppingLoader = () => {
  const maxPercentage = 95;
  const [percentage, setPercentage] = useState(0);
  
  useEffect(() => {
    let interval = setInterval(() => {
      setPercentage(
        (percentage) => percentage + Math.min((maxPercentage - percentage)/100, Math.random() * 0.5) // Logic to make the progress look random
      );
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div class="block text-center text-xl mt-8 mb-2">
      <img className="m-auto" src="https://hopstack-pub.s3.us-east-1.amazonaws.com/hour-glass.svg" />
      <div className="px-10 pt-2">
        <div class="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
          <div class="bg-2C7695 h-1 rounded-full transition-all" style={{width: `${percentage}%`}}></div>
        </div>
      </div>
      <div className="pt-5">Fetching Prices & Delivery Estimates of the available carriers. This might take a moment</div>
    </div>
  );
};

RateShoppingLoader.propTypes = {};

export default RateShoppingLoader;
