import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const printBol = async (
  id,
  noOfCopies = 1,
  printer = null,
  download = false,
  appState  = null
) => {

  if (!id) {
    return appState && appState.setAlert("Could not print", "error", 5000);
  }

  try {
    htmlToImage
      .toPng(document.getElementById(id), { quality: 1 })
      .then((dataUrl) => {
        const pdf = new jsPDF({
          orientation: "portrait",
          format: "a4",
        });
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        console.log(`width ${width}`);
        console.log(`height: ${height}`);
        pdf.addImage(dataUrl, "PNG", 0, 0, width, height);
        if (download) {
          pdf.output("dataurlnewwindow");
          pdf.save(`${id}.pdf`);
          return;
        } else {
          const out = pdf.output("datauristring");
          // console.log(out);
          // return;
          // pdf.output;
          // canvas.toBlob((blob) => {
          const cpj = new JSPM.ClientPrintJob();
          let myPrinter;
          if (printer) {
            myPrinter = new JSPM.InstalledPrinter(printer);
          } else {
            myPrinter = new JSPM.DefaultPrinter();
          }
          cpj.clientPrinter = myPrinter;
          for (let i = 0; i < noOfCopies; i++) {
            const myFile = new JSPM.PrintFilePDF(
              out,
              JSPM.FileSourceType.URL,
              `${id}_${i}.pdf`,
              1
            );
            cpj.files.push(myFile);
          }
          cpj.sendToClient();
        }
      });
  } catch (err) {
    throw err;
  }
};
