import { Tab } from "@headlessui/react";
import { UserAddIcon } from "@heroicons/react/outline";
// import PageButton from "#components/shared/PageButton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ tabs, extra, selected, onChange }) => {
  console.log(tabs);
  return (
    <div className="flex  items-center justify-center py-2">
      <div className="inline-flex bg-primaryAccent rounded-full items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="flex p-1 px-1 rounded-xl">
            {tabs &&
              tabs.map((tab, index) => (
                <div
                  key={index}
                  className={classNames(
                    "px-6 py-1 text-sm leading-5 font-regular rounded-full",
                    "focus:outline-none focus:ring-0 font-montserrat font-bold",
                    selected === tab
                      ? "bg-white text-primaryAccent"
                      : "border-transparent bg-transparent border rounded-full text-white"
                  )}
                  onClick={() => onChange(tab)}
                >
                  {tab}
                </div>
              ))}
          </div>
          <div className="items-center justify-end">{extra}</div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
