import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_WORKFLOWS, GET_WORKFLOW, GET_ENTITY_TYPES } from "#queries";
import { SAVE_WORKFLOW, DELETE_WORKFLOW, ADD_TO_WORKFLOW } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import { arrayMoveImmutable } from "array-move";

const withWorkflowsLogic = (WrappedComponent) => {
  return (props) => {
    const auth = useContext(AuthContext);
    const [entityTypes, setEntityTypes] = useState([]);
    const [eligiblePrints, setEligiblePrints] = useState(null);
    const [eligibleScans, setEligibleScans] = useState(null);
    const [workflows, setWorkflows] = useState(null);
    const [buildWorkflow, setBuildWorkflow] = useState(null);
    const [filters, setFilters] = useState({});
    const appState = useContext(AppStateContext);
    const masterData = useContext(MasterDataContext);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const workflowsQuery = useQuery(GET_WORKFLOWS);
    const getWorkflowQuery = useQuery(GET_WORKFLOW);
    const saveWorkflowQuery = useQuery(SAVE_WORKFLOW);
    const addToWorkflowQuery = useQuery(ADD_TO_WORKFLOW);
    const deleteWorkflowQuery = useQuery(DELETE_WORKFLOW);
    const [customerFilter, setCustomerFilter] = useState({
      customer: null,
      warehouse: null,
    });
    const entityTypesQuery = useQuery(GET_ENTITY_TYPES);

    useEffect(() => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }
        setCustomerFilter({
          warehouse: warehouses[0],
          customer: customers[0],
        });
      }
    }, [auth]);

    useEffect(() => {
      fetchEntities();
      fetchEntityTypes();
    }, [customerFilter]);

    const fetchEntities = () => {
      const filtersSet = {};
      if (customerFilter.warehouse) {
        filtersSet["warehouse"] = customerFilter.warehouse.id;
      }
      if (customerFilter.customer) {
        filtersSet["customer"] = customerFilter.customer.id;
      }
      if (customerFilter.warehouse || customerFilter.customer) {
        setFilters({ ...filtersSet });
        workflowsQuery.fetchData({
          filters: { ...filtersSet },
        });
      }
    };

    const fetchEntityTypes = () => {
      const filtersSet = { entityParent: ["LOCATION", "TOTE"] };
      // if (customerFilter.warehouse) {
      //   filtersSet["warehouse"] = [customerFilter.warehouse.id];
      // }
      // if (customerFilter.customer) {
      //   filtersSet["customer"] = [customerFilter.customer.id];
      // }
      entityTypesQuery.fetchData({ filters: filtersSet });
    };

    useEffect(() => {
      if (entityTypesQuery.data) {
        setEntityTypes(entityTypesQuery.data.entityTypes);
      }

      if (entityTypesQuery.error) {
        setEntityTypes([]);
      }
    }, [entityTypesQuery.data, entityTypesQuery.error]);

    useEffect(() => {
      if (workflowsQuery.error) {
        appState.setAlert(workflowsQuery.error.message, "error", 5000);
        setWorkflows([]);
      }

      if (workflowsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (workflowsQuery.data) {
        let arrToInsert = workflowsQuery.data.workflows;
        if (arrToInsert.length === 0) {
          arrToInsert = [
            {
              workflowType: "INBOUND",
              customer: filters["customer"],
              warehouse: filters["warehouse"],
            },
            {
              workflowType: "OUTBOUND",
              customer: filters["customer"],
              warehouse: filters["warehouse"],
            },
          ];
        }
        setWorkflows(arrToInsert);
      }
    }, [workflowsQuery.data, workflowsQuery.error, workflowsQuery.loading]);

    useEffect(() => {
      if (getWorkflowQuery.data) {
        setSelectedWorkflow(getWorkflowQuery.data.workflow);
      }

      if (getWorkflowQuery.error) {
        setSelectedWorkflow(null);
      }
    }, [
      getWorkflowQuery.loading,
      getWorkflowQuery.data,
      getWorkflowQuery.error,
    ]);

    useEffect(() => {
      if (addToWorkflowQuery.data) {
        appState.setAlert(
          addToWorkflowQuery.data.addToWorkflow.message,
          "success",
          2500,
        );
        workflowsQuery.fetchData({
          filters: {
            customer: filters["customer"],
            warehouse: filters["warehouse"],
          },
        });
        setBuildWorkflow(null);
      }

      if (addToWorkflowQuery.error) {
        // alert();
        appState.setAlert(addToWorkflowQuery.error.message, "error", 5000);
      }
    }, [
      addToWorkflowQuery.loading,
      addToWorkflowQuery.data,
      addToWorkflowQuery.error,
    ]);

    useEffect(() => {
      if (saveWorkflowQuery.error) {
        appState.setAlert(saveWorkflowQuery.error.message, "error", 5000);
      }

      if (saveWorkflowQuery.data) {
        appState.setAlert(
          saveWorkflowQuery.data.saveWorkflow.message,
          "success",
          2500,
        );
        setSelectedWorkflow(null);
        workflowsQuery.fetchData({
          filters: {
            customer: filters["customer"],
            warehouse: filters["warehouse"],
          },
        });
      }
    }, [
      saveWorkflowQuery.loading,
      saveWorkflowQuery.error,
      saveWorkflowQuery.data,
    ]);

    useEffect(() => {
      if (deleteWorkflowQuery.data) {
        appState.hideConfirmation();
        workflowsQuery.fetchData({
          filters,
        });
      }

      if (deleteWorkflowQuery.error) {
        appState.hideConfirmation();
      }
    }, [
      deleteWorkflowQuery.loading,
      deleteWorkflowQuery.data,
      deleteWorkflowQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this workflow?",
        () => {
          deleteWorkflowQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const onChange = (e) => {
      const workflow = {
        ...buildWorkflow,
      };

      workflow[e.target.name] =
        e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
      setBuildWorkflow(workflow);
    };

    const onChangeRegular = (e) => {
      const workflow = {
        ...selectedWorkflow,
      };

      workflow[e.target.name] =
        e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
      setSelectedWorkflow(workflow);
    };

    const onChangeDropdown = (field, value) => {
      const workflow = {
        ...buildWorkflow,
      };

      if (field === "hopstackModule") {
        workflow.displayFields = [];
        workflow.actions = [];
        const hopstackModule = masterData.hopstackModules.find(
          (item) => item.name === value,
        );
        if (hopstackModule && hopstackModule.displayFields) {
          hopstackModule.displayFields.map((item, idx) =>
            workflow.displayFields.push({
              name: item.name,
              value: item.value,
              enabled: false,
            }),
          );
          if (hopstackModule.scans) {
            // hopstackModule.scans
            //   .filter((item) => item.editable === false)
            //   .map((item, idx) =>
            //     workflow.actions.push({
            //       name: item.name,
            //       value: item.value,
            //       editable: false,
            //       typeOfAction: "SCAN",
            //     })
            //   );
          }
          if (hopstackModule.prints) {
            hopstackModule.prints
              .filter((item) => item.editable === false)
              .map((item, idx) =>
                workflow.actions.push({
                  name: item.name,
                  value: item.value,
                  editable: false,
                  typeOfAction: "PRINT",
                }),
              );
          }
        }
      }

      workflow[field] = value;
      setBuildWorkflow(workflow);
    };

    const onChangeDropdownRegular = (field, value) => {
      const workflow = {
        ...selectedWorkflow,
      };

      workflow[field] = value;
      setSelectedWorkflow(workflow);
    };

    const onChangeMultiSelect = (field, value) => {
      const workflow = {
        ...buildWorkflow,
      };
      workflow[field] = value;
      setBuildWorkflow(workflow);
      console.log(workflow);
    };

    const onChangeMultiSelectRegular = (field, value) => {
      const workflow = {
        ...selectedWorkflow,
      };
      workflow[field] = value;
      setSelectedWorkflow(workflow);
      console.log(workflow);
    };

    const setEnabled = (fieldIdx, value) => {
      if (buildWorkflow) {
        let displayField = buildWorkflow.displayFields[fieldIdx];
        displayField.enabled = value;
        buildWorkflow.displayFields[fieldIdx] = displayField;
        setBuildWorkflow({ ...buildWorkflow });
      }
    };

    const onDrop = ({ removedIndex, addedIndex }, id) => {
      const copiedWorkflow = JSON.parse(
        JSON.stringify(workflows.find((item) => item.id === id)),
      );
      const stages = [...copiedWorkflow.stages];
      const newItems = arrayMoveImmutable(stages, removedIndex, addedIndex);
      copiedWorkflow.stages = newItems;
      // setSelectedWorkflow({
      //   ...copiedWorkflow,
      // });
      const workflowIdx = workflows.findIndex((item) => item.id === id);
      workflows[workflowIdx] = { ...copiedWorkflow };
      setWorkflows([...workflows]);
      saveWorkflowQuery.fetchData({
        ...copiedWorkflow,
      });
    };

    // const setBuildWorkflow = (workflowType) => {
    //   const workflowIdx = workflows.findIndex(
    //     (item) => item.workflowType === workflowType
    //   );

    //   if (workflowIdx !== -1) {
    //     const selected = workflows[workflowIdx];
    //     if (!selected.stations) {
    //       selected.stations = [];
    //     }
    //     selected.stations.push("Hello World");
    //     const copyWorkflows = workflows;
    //     copyWorkflows[workflowIdx] = selected;
    //     setWorkflows([...copyWorkflows]);
    //   }
    // };

    const addScan = () => {
      if (buildWorkflow && buildWorkflow.workflowType === "INBOUND") {
        setEligibleScans([
          ...entityTypes.map((item) => ({
            ...item,
            name: `${item.entityParent} - ${item.name}`,
          })),
          ...masterData.inboundVariables,
        ]);
      } else if (buildWorkflow && buildWorkflow.workflowType === "OUTBOUND") {
        setEligibleScans([
          ...entityTypes.map((item) => ({
            ...item,
            name: `${item.entityParent} - ${item.name}`,
          })),
          ...masterData.outboundVariables,
        ]);
      } else if (buildWorkflow && buildWorkflow.workflowType === "MISC") {
        setEligibleScans([
          ...entityTypes.map((item) => ({
            ...item,
            name: `${item.entityParent} - ${item.name}`,
          })),
          ...masterData.miscVariables,
        ]);
      }
    };

    const submitScans = () => {
      if (!buildWorkflow.buildScans || buildWorkflow.buildScans.length === 0) {
        appState.setAlert(`Please select at least 1 field`);
        return;
      }
      if (buildWorkflow) {
        if (!buildWorkflow.actions) {
          buildWorkflow.actions = [];
        }
        buildWorkflow.actions.push({
          typeOfAction: "SCAN",
          fields: buildWorkflow.buildScans,
          manualEntryEnabled: buildWorkflow.manualEntryEnabled,
        });
        setBuildWorkflow({
          ...buildWorkflow,
          buildScans: null,
        });
        setEligibleScans(null);
      }
    };

    const submitPrint = () => {
      if (!buildWorkflow.buildPrint) {
        appState.setAlert(`Please select a print field`);
        return;
      }
      if (buildWorkflow) {
        if (!buildWorkflow.actions) {
          buildWorkflow.actions = [];
        }
        buildWorkflow.actions.push({
          typeOfAction: "PRINT",
          fields: [buildWorkflow.buildPrint],
          printTrigger: buildWorkflow.printTrigger,
          allowReprint: buildWorkflow.allowReprint,
        });
        setBuildWorkflow({
          ...buildWorkflow,
          buildPrints: null,
        });
        setEligiblePrints(null);
      }
    };

    const addPrint = () => {
      if (buildWorkflow && buildWorkflow.workflowType === "INBOUND") {
        setEligiblePrints(masterData.inboundVariables);
      } else if (buildWorkflow && buildWorkflow.workflowType === "OUTBOUND") {
        setEligiblePrints(masterData.outboundVariables);
      } else if (buildWorkflow && buildWorkflow.workflowType === "MISC") {
        setEligiblePrints(masterData.miscVariables);
      }
    };

    const removeAction = (idx) => {
      if (buildWorkflow) {
        buildWorkflow.actions = buildWorkflow.actions.filter(
          (item, index) => index !== idx,
        );
        setBuildWorkflow({
          ...buildWorkflow,
        });
      }
    };

    const saveWorkflow = () => {
      saveWorkflowQuery.fetchData({
        ...selectedWorkflow,
      });
    };

    const addToWorkflow = () => {
      console.log(buildWorkflow);
      console.log(workflows);
      const workflow = workflows.find(
        (item) => item.workflowType === buildWorkflow.workflowType,
      );
      const toUpdate = {
        id: workflow.id,
        actions: buildWorkflow.actions,
        displayFields: buildWorkflow.displayFields,
        name: buildWorkflow.name,
        enabled: true,
        ...buildWorkflow,
      };
      if (buildWorkflow.stageId) {
        toUpdate.stageId = buildWorkflow.stageId;
      }
      if (!toUpdate.name) {
        return appState.setAlert(`Please provide a name`, "error", 5000);
      }
      addToWorkflowQuery.fetchData(toUpdate);
    };

    const toggleStage = (workflowIdx, stageIdx, enabled) => {
      const workflow = workflows[workflowIdx];
      console.log(`Workflow Logic`);
      console.log(workflow);
      const stage = workflow.stages[stageIdx];
      stage.enabled = enabled;
      workflow.stages[stageIdx] = stage;
      workflows[workflowIdx] = workflow;

      setWorkflows([...workflows]);
      addToWorkflowQuery.fetchData({
        id: workflow.id,
        ...stage,
      });
    };

    const removeStage = (workflowIdx, stageIdx) => {
      const workflow = workflows[workflowIdx];
      console.log(`Workflow Logic`);
      console.log(workflow);
      workflow.stages = workflow.stages.filter((item, idx) => idx !== stageIdx);
      workflows[workflowIdx] = workflow;
      setWorkflows([...workflows]);

      saveWorkflowQuery.fetchData({
        ...workflow,
      });
    };

    console.log(masterData.hopstackModules);
    return (
      <WrappedComponent
        workflows={workflows}
        masterData={masterData}
        fetchWorkflow={(id) => getWorkflowQuery.fetchData({ id })}
        selectedWorkflow={selectedWorkflow}
        setSelectedWorkflow={setSelectedWorkflow}
        saveWorkflow={saveWorkflow}
        addToWorkflow={addToWorkflow}
        onChangeFilter={(field, value, autoSubmit = false) => {
          setFilters({
            ...filters,
            [field]: value,
          });
          if (autoSubmit) {
            workflowsQuery.fetchData({
              filters: {
                ...filters,
                [field]: value,
              },
            });
          }
        }}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        onChangeMultiSelect={onChangeMultiSelect}
        onChangeRegular={onChangeRegular}
        onChangeDropdownRegular={onChangeDropdownRegular}
        onChangeMultiSelectRegular={onChangeMultiSelectRegular}
        deleteButtonClicked={deleteButtonClicked}
        onDrop={onDrop}
        setEnabled={setEnabled}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        setFilters={setFilters}
        filters={filters}
        buildWorkflow={buildWorkflow}
        setBuildWorkflow={setBuildWorkflow}
        addScan={addScan}
        addPrint={addPrint}
        eligibleScans={eligibleScans}
        eligiblePrints={eligiblePrints}
        setEligibleScans={setEligibleScans}
        setEligiblePrints={setEligiblePrints}
        submitScans={submitScans}
        submitPrint={submitPrint}
        removeAction={removeAction}
        toggleStage={toggleStage}
        removeStage={removeStage}
        writable={props.writable}
      />
    );
  };
};

export default withWorkflowsLogic;
