import Modal from "#components/utils/Modal";
import BundleProductsDetail from "#components/bundles/BundleProductsDetail";
import { useState } from "react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";

const ExpandedSku = ({ negativeAction, fetchedSku, customers }) => {
  const headers = [
    "Image",
    "SKU",
    "Client",
    "Name",
    "ASIN",
    "Fn SKU",
    "Attributes",
    "Base UOM",
    "UOM Configuration",
    "SKU Type",
  ];
  if (!fetchedSku.typeOfProduct) {
    fetchedSku.typeOfProduct = "STANDALONE";
  }
  if (
    fetchedSku.typeOfProduct === "BUNDLE" &&
    fetchedSku.products?.length > 0
  ) {
    headers.push("Bundled Products");
  }
  const [attributes, setAttributes] = useState(null);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [uom, setUom] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showBundleProductsDetail, setShowBundleProductsDetail] =
    useState(false);

  const image =
    fetchedSku.images && fetchedSku.images[0]?.display_url
      ? fetchedSku.images[0]?.display_url
      : FALLBACK_IMAGE_URL;

  const handleImageLoad = (event) => {
    event.target.style.display = "block";
    event.target.previousSibling.style.display = "none"; // Hide the spinner
  };

  const handleImageError = (event) => {
    event.target.src = FALLBACK_IMAGE_URL; // Incase loading fails
  };

  // NOTE : only added for Amazon as of now
  const isMarketplaceProduct = (source) => ["FBA", "FBM"].includes(source);

  const checkAttributesPresent = (fetchedSku) => {
    const attrs = isMarketplaceProduct(fetchedSku?.source)
      ? fetchedSku?.marketplaceAttributes?.attributes
      : fetchedSku?.attributes;

    const customAttrs = fetchedSku?.customAttributes;
    if (
      (!attrs || Object.keys(attrs).length === 0) &&
      (!customAttrs || customAttrs.length === 0)
    ) {
      return false;
    }
    return true;
  };

  const handleAttributeClick = () => {
    let attrs = isMarketplaceProduct(fetchedSku.source)
      ? fetchedSku?.marketplaceAttributes?.attributes
      : fetchedSku?.attributes;
    setAttributes(attrs);
    setCustomAttributes(fetchedSku?.customAttributes);
  };

  if (attributes || customAttributes.length > 0) {
    return (
      <Modal
        negativeAction={() => {
          setAttributes(null);
          setCustomAttributes([]);
        }}
        title={`Item Attributes`}
        noPadding={true}
        positiveAction={() => {
          setAttributes(null);
          setCustomAttributes([]);
        }}
        positiveText="OK">
        <div className="space-y-4 p-4">
          {attributes && Object.keys(attributes).length > 0 && (
            <>
              <div>
                <span className="font-bold font-montserrat text-454A4F text-lg pb-4">
                  Attributes
                </span>
              </div>
              {Object.keys(attributes).map((item, idx) => {
                if (
                  typeof attributes[item] !== "object" &&
                  attributes[item] !== null &&
                  !Array.isArray(attributes[item])
                ) {
                  return (
                    <div key={idx}>
                      <span className="font-montserrat text-454A4F text-md pb-4">
                        {item.toUpperCase()}:{" "}
                        {[true, false].includes(attributes[item])
                          ? attributes[item] === true
                            ? "Yes"
                            : "No"
                          : attributes[item]}
                      </span>
                    </div>
                  );
                }
              })}
            </>
          )}
          {customAttributes && customAttributes.length > 0 && (
            <>
              <div>
                <span className="font-bold font-montserrat text-454A4F text-lg pb-4">
                  Custom Attributes
                </span>
              </div>
              {customAttributes.map((item, idx) => {
                if (
                  typeof item.value !== "object" &&
                  item.value !== null &&
                  !Array.isArray(item.value)
                ) {
                  return (
                    <div key={idx}>
                      <span className="font-montserrat text-454A4F text-md pb-4">
                        {item.key}: {item.value}
                      </span>
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
      </Modal>
    );
  }

  if (uom) {
    return (
      <Modal
        negativeAction={() => setUom(null)}
        title={`Unit of Measurement`}
        noPadding={true}
        positiveAction={() => setUom(null)}
        positiveText="OK">
        <div className="space-y-4 p-4">
          <table className="px-2 min-w-full divide-y divide-gray-200">
            <thead className="p-4 rounded-full bg-primaryAccent px-12">
              <tr className="text-textWhite font-montserrat border-left px-12 border-l-8 border-primaryAccent">
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                  Base UOM
                </th>
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                  Target UOM
                </th>
              </tr>
            </thead>
            <tbody>
              {uom.map((item, idx) => (
                <tr key={idx} className="bg-white">
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-primaryAccent`}>
                    {item.baseUomQuantity}
                  </td>

                  <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                    {item.baseUom}
                  </td>

                  <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                    {item.targetUom}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }

  if (showBundleProductsDetail) {
    return (
      <BundleProductsDetail
        products={fetchedSku.products}
        onClose={() => setShowBundleProductsDetail(false)}
      />
    );
  }

  return (
    <Modal
      negativeAction={negativeAction}
      title={`SKU Details`}
      noPadding={true}
      positiveAction={negativeAction}
      positiveText="OK">
      <div className="bg-EFE9DC p-4 overflow-auto">
        {showImage && (
          <Modal
            negativeAction={() => setShowImage(false)}
            title={`Image Preview`}
            noPadding={true}
            positiveAction={() => setShowImage(false)}
            positiveText="OK">
            <div className="space-y-4 p-4">
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="w-10 h-10 border-t-4 border-zinc-300 border-solid rounded-full animate-spin"></div>
              </div>
              <img
                src={image}
                alt="No Image"
                className="hidden h-1/2 w-1/2 mx-auto rounded-lg overflow-hidden object-contain"
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            </div>
          </Modal>
        )}
        <table className="px-2 min-w-full divide-y divide-gray-200">
          <thead className="p-4 rounded-full bg-primaryAccent px-12">
            <tr className="text-textWhite font-montserrat border-left px-12 border-l-8 border-primaryAccent">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            <tr key={fetchedSku.id} className="bg-white">
              <td
                className={`p-2 text-left font-semibold text-primaryAccent tracking-wider rounded-tl rounded-bl border-l-8 border-primaryAccent`}>
                <div
                  onClick={() => setShowImage(true)}
                  className="relative h-20 w-40">
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="w-10 h-10 border-t-4 border-zinc-300 border-solid rounded-full animate-spin"></div>
                  </div>
                  <img
                    className="hidden h-20 w-40 mt-2 object-contain bg-gray-300 rounded-lg overflow-hidden"
                    src={image}
                    alt="No Image"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                  <ArrowsExpandIcon className="absolute h-10 w-10 bottom-0 right-0 p-2 text-white" />
                </div>
              </td>
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-wider border-primaryAccent`}>
                {fetchedSku.sku}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                {customers &&
                  customers.find((item) => item.id === fetchedSku.customer)
                    ?.name}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                <div className="w-96">{fetchedSku.name}</div>
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                {buildMarketplaceHyperlink(
                  fetchedSku.asin,
                  "ASIN",
                  fetchedSku.source,
                  fetchedSku?.marketplaceAttributes?.attributes
                    ?.marketplaceCountryCode,
                )}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                {fetchedSku.fnSku}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                {checkAttributesPresent(fetchedSku) ? (
                  <div
                    className="text-primaryAccent cursor-pointer"
                    onClick={() => handleAttributeClick(fetchedSku)}>
                    View
                  </div>
                ) : (
                  "No Attributes"
                )}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                {fetchedSku.baseUom}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                {fetchedSku.uomConfiguration &&
                Object.keys(fetchedSku.uomConfiguration).length > 0 &&
                Object.values(fetchedSku.uomConfiguration).filter(
                  (i) => i !== null,
                ) ? (
                  <div
                    className="text-primaryAccent cursor-pointer"
                    onClick={() => setUom(fetchedSku.uomConfiguration)}>
                    View
                  </div>
                ) : (
                  "No UOM Configuration"
                )}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                {fetchedSku.typeOfProduct.toLowerCase()}
              </td>

              {fetchedSku.typeOfProduct === "BUNDLE" && (
                <>
                  {fetchedSku.products && fetchedSku.products.length > 0 && (
                    <td className="px-2 pl-4  py-1 text-left font-medium  text-tableText  tracking-wider rounded-br rounded-tr">
                      <div
                        className="text-primaryAccent cursor-pointer"
                        onClick={() => setShowBundleProductsDetail(true)}>
                        View
                      </div>
                    </td>
                  )}
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ExpandedSku;
