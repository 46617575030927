import Modal from "#components/utils/Modal";
import TableFieldButton from "#components/utils/TableFieldButton";
import { AdjustmentsIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";

const InventoryExplanation = ({
  inventoryExplanation,
  index,
  setInventoryExplanations,
  inventoryExplanations,
  explainInventory,
  getSpecificInventory,
  setProblemQuantityClicked,
  setProblemReason,
  writable,
}) => {
  const noValuesText = "No Data";
  return (
    <Modal
      negativeAction={() =>
        setInventoryExplanations(
          inventoryExplanations.filter((item, idx) => idx !== index),
        )
      }
      title={`Inventory Breakdown`}
      noPadding={true}
      positiveAction={() =>
        setInventoryExplanations(
          inventoryExplanations.filter((item, idx) => idx !== index),
        )
      }
      positiveText="OK">
      <div className="bg-EFE9DC p-4 overflow-auto">
        <table className="px-2 min-w-full divide-y divide-gray-200">
          <thead className="p-4 rounded-full bg-primaryAccent px-12">
            <tr className="text-textWhite font-montserrat border-left px-12 border-l-8 border-primaryAccent">
              {inventoryExplanation.explanations.headers.map(
                (header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                      key={headerIdx}>
                      {header}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                      key={headerIdx}>
                      {header}
                    </th>
                  ),
              )}
            </tr>
          </thead>
          <tbody>
            {inventoryExplanation &&
            inventoryExplanation.explanations?.entities?.length === 0 ? (
              <tr className="bg-white">
                {inventoryExplanation.explanations.headers.map(
                  (header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                    ) : (
                      <td
                        className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                        key={headerIdx}></td>
                    ),
                )}
              </tr>
            ) : null}
            {inventoryExplanation?.explanations?.entities?.map((item, idx) => {
              return (
                <tr
                  key={idx}
                  className={`${
                    idx % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } p-2`}>
                  {inventoryExplanation.explanations.headers
                    .filter(
                      (item) => item !== "Action" && item !== "warehouseId",
                    )
                    .map((header, headerIdx) => (
                      <td
                        className="pl-4 px-1 py-2 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr"
                        key={headerIdx}>
                        <RenderHeaderValue
                          header={header}
                          headerIdx={headerIdx}
                          explainInventory={explainInventory}
                          inventoryExplanation={inventoryExplanation}
                          item={item}
                        />
                      </td>
                    ))}
                  {writable &&
                    ["storageQuantity"].includes(
                      inventoryExplanation.typeOfBreakdown,
                    ) &&
                    inventoryExplanation.typeOfInnerBreakdown && (
                      <td className="pl-4 px-1 py-4 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                        <TableFieldButton
                          text={<AdjustmentsIcon className="w-6 h-6" />}
                          onClick={() => getSpecificInventory(item.id)}
                        />
                      </td>
                    )}
                  {writable &&
                    ["problemQuantity"].includes(
                      inventoryExplanation.typeOfBreakdown,
                    ) && (
                      <td className="pl-4 px-1 py-4 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                        <TableFieldButton
                          text={<AdjustmentsIcon className="w-6 h-6" />}
                          onClick={() => {
                            setProblemQuantityClicked(true);
                            setProblemReason({
                              issueType: item["Type of Issue"],
                              warehouseId: item["warehouseId"],
                              quantity: item["Qty"],
                            });
                          }}
                        />
                      </td>
                    )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

const RenderHeaderValue = ({
  header,
  headerIdx,
  explainInventory,
  inventoryExplanation,
  item,
}) => {
  if (
    headerIdx === 0 &&
    ["storageQuantity"].includes(inventoryExplanation.typeOfBreakdown) &&
    !inventoryExplanation.typeOfInnerBreakdown
  ) {
    return (
      <div
        className="cursor-pointer hover:underline font-bold text-primaryAccent"
        onClick={() =>
          explainInventory(
            inventoryExplanation.mainId,
            "storageQuantity",
            item[header],
          )
        }>
        {item[header]}
      </div>
    );
  }

  if (["Consignment Info"].includes(header)) {
    return (
      <div className="group relative cursor-pointer">
        <p className="font-medium truncate" data-tip data-for={`${item.id}`}>
          {item.consignmentInfo.orderId ||
            item.consignmentInfo.consignmentNumber}
        </p>
      </div>
    );
  }

  return <>{item[header]}</>;
};

export default InventoryExplanation;
