import withWarehousesLogic from "#components/HOC/withWarehousesLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import WarehousesList from "#components/warehouses/WarehousesList";
import WarehouseForm from "#components/warehouses/WarehouseForm";
import { UserAddIcon } from "@heroicons/react/outline";
import BulkUpload from "../components/bulkUpload/UploadBulk";
import { ModalProvider } from "../components/bulkUpload/useReducer";

const Warehouses = ({
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  fetchWarehouse,
  saveWarehouse,
  deleteButtonClicked,
  onChange,
  onChangeAdress,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  total,
  pageNumber,
  checkPagination,
  perPage,
  setPerPage,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Warehouse Management</PageTitle>
      </div>

      <div className="items-center justify-end space-x-2 flex mb-4">
        <AddButton
          text="Add Warehouse"
          onClick={() => setSelectedWarehouse({ active: true })}
          icon={UserAddIcon}
          disabled={!writable}
        />
        <AddButton
          text="Download Template"
          onClick={() =>
            (window.location =
              "https://templates-onboarding.s3.amazonaws.com/uat/Warehouses.xlsx")
          }
          disabled={!writable}
        />

        <BulkUpload
          dashboardFields={dashboardFields}
          saveBulkUpload={(rows) => saveBulkUpload(rows)}
          errorMessage={errorMessage}
          successMessage={successMessage}
          validate={validate}
          validationResult={validationResult}
        />
      </div>
    </div>
    <WarehousesList
      onChangeSearchKeyword={onChangeSearchKeyword}
      filters={filters}
      submitFilters={submitFilters}
      clearFilters={clearFilters}
      setSort={setSort}
      sort={sort}
      clearKeyword={clearKeyword}
      setShowFilters={setShowFilters}
      warehouses={warehouses}
      editButtonClicked={(e) => {
        fetchWarehouse(e.id);
      }}
      deleteButtonClicked={(e) => deleteButtonClicked(e)}
      headers={[
        "Name",
        "Code",
        "Type of Warehouse",
        "Status",
        "Last Updated",
        "Action",
      ]}
      noValuesText="No Warehouses"
      writable={writable}
      total={total}
      pageNumber={pageNumber}
      checkPagination={checkPagination}
      perPage={perPage}
      setPerPage={setPerPage}
    />
    {selectedWarehouse && (
      <WarehouseForm
        title={selectedWarehouse.id ? "Edit Warehouse" : "Add Warehouse"}
        selectedWarehouse={selectedWarehouse}
        onChange={onChange}
        onChangeAdress={onChangeAdress}
        onClose={() => setSelectedWarehouse()}
        onSubmit={() => saveWarehouse(selectedWarehouse)}
        warehouses={warehouses}
        setSelectedWarehouse={setSelectedWarehouse}
      />
    )}
  </div>
);

export default withWarehousesLogic(Warehouses);
