import React from "react";

const CustomTableWithTheme = ({ children }) => {
  return (
    <div className="relative text-[16px] xl:text-sm w-full h-[70vh] overflow-auto">
      <table className="text-[16px] w-full divide-y relative divide-gray-200 whitespace-nowrap">
        {children}
      </table>
    </div>
  );
};

export default CustomTableWithTheme;
