import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import ModalButton from "#components/utils/ModalButton";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";
import Checkbox from "#components/utils/Checkbox";
import { isDelmarTenant } from "#utils/tenantCheck";

const OrderFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  outboundExceptions,
  customers,
  warehouses,
  clearFilters,
  possibleSubStatuses,
  tenant,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="flex space-x-6 mt-4">
      <ModalButton
        onClick={clearFilters}
        text={`Reset Filters`}
        className="bg-red-500"
      />
    </div>
    <div className="flex space-x-6 mt-4">
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Order Start Date
          </span>
        </label>
        <TextField
          type="date"
          label="Select Date"
          placeholder=" "
          onChange={(e) => onChangeFilter("startDate", e.target.value)}
          value={filters["startDate"]}
        />
      </div>
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Order End Date
          </span>
        </label>
        <TextField
          type="date"
          label="Select Date"
          placeholder=" "
          onChange={(e) => onChangeFilter("endDate", e.target.value)}
          value={filters["endDate"]}
        />
      </div>
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Sub Status
          </span>
        </label>
        <MultiSelectAutoComplete
          options={possibleSubStatuses}
          labelKey="value"
          valueKey="value"
          setValues={(e) => onChangeFilter("subStatus", e)}
          values={filters["subStatus"]}
          rounded={true}
        />
      </div>
    </div>
    <div className="flex space-x-6 justify-between mt-4">
      {!outboundExceptions && (
        <>
          <div className="flex-1">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Status
              </span>
            </label>
            <MultiSelectAutoComplete
              options={[
                { name: "UNPROCESSED" },
                { name: "BATCHED" },
                { name: "IN-PROCESS" },
                { name: "INCOMPLETE" },
                { name: "EXCEPTION" },
                { name: "CANCELLED" },
                { name: "ON_HOLD" },
                { name: "PARTIAL_COMPLETED" },
                { name: "COMPLETED" },
              ]}
              labelKey="name"
              valueKey="name"
              setValues={(e) => onChangeFilter("orderStatus", e)}
              values={filters["orderStatus"]}
              rounded={true}
            />
          </div>
          <div className="flex-1">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Carrier
              </span>
            </label>
            <MultiSelectAutoComplete
              options={[
                { name: "DHL" },
                { name: "ABX EXPRESS" },
                { name: "AWB SHOPEE" },
                { name: "UPS" },
                { name: "FEDEX" },
                { name: "NINJAVAN" },
              ]}
              labelKey="name"
              valueKey="name"
              setValues={(e) => onChangeFilter("carrier", e)}
              values={filters["carrier"]}
              rounded={true}
            />
          </div>
        </>
      )}
      <>
        {isDelmarTenant(tenant.subdomain) === false && (
          <div className="flex-1">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Client(s)
              </span>
            </label>
            <MultiSelectAutoComplete
              options={getFilteredCustomerList(filters["warehouse"], customers)}
              labelKey="name"
              valueKey="id"
              setValues={(selectedOptions) =>
                onChangeFilter("customer", selectedOptions)
              }
              values={filters["customer"]}
              rounded={true}
            />
          </div>
        )}
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Warehouse(s)
            </span>
          </label>
          <MultiSelectAutoComplete
            options={warehouses}
            labelKey="name"
            valueKey="id"
            setValues={(selectedOptions) =>
              onChangeFilter("warehouse", selectedOptions)
            }
            values={filters["warehouse"]}
            rounded={true}
          />
        </div>
      </>
      {outboundExceptions && (
        <>
          <div className="flex-1">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Status
              </span>
            </label>
            <MultiSelectAutoComplete
              options={[{ name: "INCOMPLETE" }, { name: "EXCEPTION" }]}
              labelKey="name"
              valueKey="name"
              setValues={(e) => onChangeFilter("orderStatus", e)}
              values={filters["orderStatus"]}
              rounded={true}
            />
          </div>
          <div className="flex-1">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Exception Type
              </span>
            </label>
            <MultiSelectAutoComplete
              options={[
                { name: "INVALID_CARRIER" },
                { name: "BIN_LOCATION_MISSING" },
                { name: "PRODUCT_MISSING_IN_DATA" },
                { name: "INSUFFICIENT_QUANTITY" },
              ]}
              labelKey="name"
              valueKey="name"
              setValues={(e) => onChangeFilter("exceptionType", e)}
              values={filters["exceptionType"]}
              rounded={true}
            />
          </div>
        </>
      )}
    </div>
    {!outboundExceptions && isDelmarTenant(tenant.subdomain) && (
      <div className="flex">
        <div className="mt-4">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Order Line Items
            </span>
          </label>
          <div className="flex">
            <Checkbox
              role="checkbox"
              onChange={() =>
                onChangeFilter(
                  "multiLine",
                  filters["multiLine"] === false ? null : false,
                )
              }
              name="binLocations"
              value={"Single-Line"}
              checked={filters && filters["multiLine"] === false}
            />
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Single-Line
              </span>
            </label>
          </div>
          <div className="flex">
            <Checkbox
              role="checkbox"
              onChange={() =>
                onChangeFilter(
                  "multiLine",
                  filters["multiLine"] === true ? null : true,
                )
              }
              name="multiLine"
              value={"Multi-Line"}
              checked={filters && filters["multiLine"] === true}
            />
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Multi-Line
              </span>
            </label>
          </div>
        </div>
      </div>
    )}
  </Modal>
);

export default OrderFilters;
