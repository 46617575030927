import { useState, useRef, useEffect } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function AutocompleteSingleSelectDropdown({
  options,
  value,
  onChange,
  labelKey = "name",
  valueKey = "id",
  placeholder,
  onKeyDown,
  secondaryLabelKey,
  showValueAsSelected = false,
  showCheckedIndicator = true,
}) {
  const [text, setText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const ref = useRef();

  const select = (option) => {
    onChange(option);
    setShowOptions(false);
  };

  const handleChange = (text) => {
    setText(text);
    if (onKeyDown) {
      onKeyDown(text);
    }
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const filteredOptions =
    options && options.length > 0
      ? options.filter((option) =>
          option?.[labelKey]?.toLowerCase()?.includes(text?.toLowerCase()),
        ).sort((a, b) => a[labelKey].localeCompare(b[labelKey]))
      : [];

  const moveCursorDown = () => {
    if (cursor < filteredOptions.length - 1) {
      setCursor((c) => c + 1);
    }
  };

  const moveCursorUp = () => {
    if (cursor > 0) {
      setCursor((c) => c - 1);
    }
  };

  const handleNav = (e) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
      case "Space":
        if (cursor >= 0 && cursor < filteredOptions.length) {
          select(filteredOptions[cursor][valueKey]);
        }
        break;
    }
  };

  useEffect(() => {
    const listener = (e) => {
      if (!ref || !ref.current || !ref.current.contains(e.target)) {
        setShowOptions(false);
        setCursor(-1);
      }
    };

    document.addEventListener("click", listener);
    document.addEventListener("focusin", listener);
    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("focusin", listener);
    };
  }, []);
  return (
    <div className="comboDropdown" ref={ref}>
      <div
        className="comboDropdownTextField"
        onClick={() => setShowOptions(!showOptions)}
        ref={ref}>
        <div className={`comboDropdownTextFieldLabel flex`}>
          {placeholder ||
            options.find((o) => o[valueKey] === value)?.[labelKey]}
          {(showValueAsSelected || value) && showCheckedIndicator && (
            <CheckCircleIcon className="comboDropdownChecked" />
          )}
        </div>
        <div className="comboDropdownTextFieldArrow pointer-events-none">
          {showOptions === true ? (
            <ChevronUpIcon className="w-6 h-6" />
          ) : (
            <ChevronDownIcon className="w-6 h-6" />
          )}
        </div>
      </div>
      {showOptions && (
        <div className="comboDropdownList">
          {/* Add a search field here */}
          {options && options.length > 0 && (
            <div className="comboDropdownSearchWrapper">
              <input
                type="search"
                placeholder="Search"
                className="comboDropdownSearchField"
                onKeyDown={handleNav}
                onChange={(e) => handleChange(e.target.value)}
                autoFocus
              />
              <div className="comboDropdownTextFieldArrow">
                <SearchIcon className="w-6 h-6" />
              </div>
            </div>
          )}

          <div className="comboDropdownResults">
            {filteredOptions.length === 0 ? (
              <>
                <div className="comboDropdownNoResults">No results</div>
              </>
            ) : (
              filteredOptions.map((option, i, arr) => (
                <div
                  key={i}
                  className={`comboDropdownListItem ${
                    cursor === i && "activeItemScroll"
                  }`}
                  onClick={() => {
                    select(option[valueKey]);
                  }}>
                  {option[labelKey]}{" "}
                  {secondaryLabelKey &&
                    option[secondaryLabelKey] &&
                    `(${option[secondaryLabelKey]})`}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}
