import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

/**
 * A SlideOverModal component that presents content in a slide-over panel.
 *
 * @param {boolean} open - A flag indicating whether the modal is open.
 * @param {function} onClose - A function to be called when the modal requests to be closed.
 * @param {string} title - The title of the modal's content.
 * @param {JSX.Element} children - The content to display inside the modal.
 * @param {boolean} staticBackdrop - If true, clicking on the backdrop does not close the modal.
 *
 * This modal is implemented using the headlessui's Dialog and Transition components. It uses transitions
 * to slide in and out of view, covering approximately 80% of the screen from the right side by default. It features a semi-transparent
 * backdrop and a close button which, by default, appears in the upper-right corner of the modal.
 *
 * To Do:
 * - Add accessibility improvements, such as trapping focus within the modal when open.
 * - Implement responsiveness to adjust the width on smaller screens.
 * - Provide customization options for the modal's appearance, such as width or background color.
 * - Include the ability to specify the position from which the modal should appear (e.g., from the left, right, bottom, etc.).
 */
const SlideOverModal = ({
  open,
  onClose,
  title,
  children,
  width = "w-4/5",
  staticBackdrop = false,
}) => {
  return (
    <Transition.Root appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={staticBackdrop ? () => {} : onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel
                  className={`pointer-events-auto absolute top-0 right-0 h-screen ${width}`}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute right-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={onClose}>
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="p-8 flex h-full flex-col  bg-white shadow-xl">
                    <Dialog.Title className="font-semibold text-gray-900 py-2 text-2xl font-montserrat">
                      {title}
                    </Dialog.Title>
                    <div className="flex-1 overflow-auto">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOverModal;
