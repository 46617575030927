import React, { useState, useEffect } from "react";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import { useCubeQuery } from "@cubejs-client/react";
import "antd/lib/table/style/index.css";
import "antd/lib/select/style/index.css";
import "antd/lib/pagination/style/index.css";
import "antd/lib/spin/style/index.css";
import Stats from "#components/common/Stats";
import TextField from "#components/utils/TextField";
import {
  SearchIcon,
  XIcon,
  FilterIcon,
  DownloadIcon,
} from "@heroicons/react/solid";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import DetailedStat from "#components/spaceManagementDashboard/DetailedStat";
import { makeDetailedStatQueries } from "#components/spaceManagementDashboard/queries";
import Modal from "#components/utils/Modal";
import _ from "lodash";
import moment from "moment";
import { Table } from "antd";
import { useForm } from "#hooks/useForm";

const LabelColorShades = [
  "#B3BFCA",
  "#textWhite",
  "#2C7695",
  "#primaryAccent",
  "#1D3752",
];

const BinAgeingCategories = [
  {
    name: "<7 Days",
    key: "Skubinmappings.min_bin_less_than_7days",
    statKey: "BIN_AGE_LT_7",
    view: "BIN_AGE_LT_7",
    fill: LabelColorShades[0],
  },
  {
    name: "7 - 15 Days",
    key: "Skubinmappings.min_bin_7_to_15days",
    statKey: "BIN_AGE_7_15",
    view: "BIN_AGE_7_15",
    fill: LabelColorShades[1],
  },
  {
    name: "15 - 30 Days",
    key: "Skubinmappings.min_bin_15_to_30days",
    statKey: "BIN_AGE_15_30",
    view: "BIN_AGE_15_30",
    fill: LabelColorShades[2],
  },
  {
    name: "30 - 60 Days",
    key: "Skubinmappings.min_bin_30_to_60days",
    statKey: "BIN_AGE_30_60",
    view: "BIN_AGE_30_60",
    fill: LabelColorShades[3],
  },
  {
    name: ">60 Days",
    key: "Skubinmappings.min_bin_greater_than_60days",
    statKey: "BIN_AGE_GT_60",
    view: "BIN_AGE_GT_60",
    fill: LabelColorShades[4],
  },
];
const TopBinAgesOptions = [
  { name: "Top 10", value: "10" },
  { name: "Top 20", value: "20" },
  { name: "Top 30", value: "30" },
];

const SpaceStats = ({
  dateRange,
  customDate,
  tenantId,
  customers,
  warehouses,
}) => {
  const [selectedStatDetail, setSelectedStatDetail] = useState(null);
  const [selectedStatQuery, setSelectedStatQuery] = useState(null);
  const [selectedBinLocation, setSelectedBinLocation] = useState(null);
  const [selectedTopBinAges, setSelectedTopBinAges] = useState(
    TopBinAgesOptions[0],
  );
  const [binSearchFilter, setBinSearchFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [binAgeCustomDate, setBinAgeCustomDate] = useState(
    makeDefaultCustomDateRange(),
  );
  const { onChange, onSubmit, values } = useForm(() => {
    setBinAgeCustomDate(values);
  }, makeDefaultCustomDateRange());

  const {
    resultSet: customBinAgeResultSet,
    isLoading: customBinAgeIsLoading,
    error: customBinAgeError,
  } = useCubeQuery({
    measures: ["Skubinmappings.min_bin_age"],
    timeDimensions: [],
    order: [["Skubinmappings.min_bin_age", "asc"]],
    dimensions: ["Skubinmappings.binlocation"],
    filters: [
      {
        member: "Skubinmappings.min_bin_age",
        operator: "gt",
        values: [
          Math.floor(new Date(binAgeCustomDate.start).getTime()).toString(),
        ],
      },
      {
        member: "Skubinmappings.min_bin_age",
        operator: "lt",
        values: [
          Math.floor(new Date(binAgeCustomDate.end).getTime()).toString(),
        ],
      },
      {
        member: "Skubinmappings.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "Skubinmappings.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "Skubinmappings.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  });
  const {
    resultSet: customBinAgeResultSet2,
    isLoading: customBinAgeIsLoading2,
    error: customBinAgeError2,
  } = useCubeQuery({
    measures: ["Skubinmappings.avg_bin_age"],
    timeDimensions: [],
    order: {
      "Skubinmappings.created_at": "asc",
    },
    dimensions: ["Skubinmappings.binlocation"],
    filters: [
      {
        member: "Skubinmappings.min_bin_age",
        operator: "gt",
        values: [Math.floor(new Date(binAgeCustomDate.start)).toString()],
      },
      {
        member: "Skubinmappings.min_bin_age",
        operator: "lt",
        values: [Math.floor(new Date(binAgeCustomDate.end)).toString()],
      },
      {
        member: "Skubinmappings.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "Skubinmappings.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "Skubinmappings.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
  });
  useEffect(() => {
    console.log(
      "ASDASDASD",
      customBinAgeResultSet2,
      customBinAgeIsLoading2,
      customBinAgeError2,
    );
  }, [customBinAgeResultSet2, customBinAgeIsLoading2, customBinAgeError2]);
  const { resultSet: binLocationTotal } = useCubeQuery({
    dimensions: [],
    timeDimensions: [],
    order: {},
    filters: [
      {
        member: "Binlocations.binlocationtype",
        operator: "notEquals",
        values: ["VIRTUAL_RECEIVING_BUFFER"],
      },
      {
        member: "Binlocations.tenant",
        operator: "equals",
        values: [tenantId],
      },
      // {
      //   member: "BinlocationsCustomer.customers",
      //   operator: "equals",
      //   values: customers,
      // },
      {
        member: "BinlocationsWarehouse.warehouses",
        operator: "equals",
        values: warehouses,
      },
    ],
    measures: ["Binlocations.count"],
  });
  const { resultSet: binLocationsFilled } = useCubeQuery({
    dimensions: [],
    timeDimensions: [],
    order: {},
    filters: [
      {
        member: "Skubinmappings.binlocation",
        operator: "notContains",
        values: ["VIRTUAL"],
      },
      {
        member: "Skubinmappings.unallocatedQuantity",
        operator: "gt",
        values: ["0"],
      },
      {
        member: "Skubinmappings.tenant",
        operator: "equals",
        values: [tenantId],
      },
      {
        member: "Skubinmappings.customer",
        operator: "equals",
        values: customers,
      },
      {
        member: "Skubinmappings.warehouse",
        operator: "equals",
        values: warehouses,
      },
    ],
    measures: ["Skubinmappings.count"],
  });

  const isCustomDate = dateRange === "Custom Date";
  const appliedDateRange = isCustomDate
    ? { dateRange: [customDate.start, customDate.end] }
    : { dateRange };

  const { resultSet: binAgeResultSet, isLoading: binAgeResultIsLoading } =
    useCubeQuery({
      measures: [
        "Skubinmappings.min_bin_less_than_7days",
        "Skubinmappings.min_bin_7_to_15days",
        "Skubinmappings.min_bin_15_to_30days",
        "Skubinmappings.min_bin_30_to_60days",
        "Skubinmappings.min_bin_greater_than_60days",
      ],
      timeDimensions: [],
      order: {
        "Skubinmappings.created_at": "asc",
      },
      dimensions: ["Skubinmappings.binlocation", "Skubinmappings.sku"],
      filters: [
        {
          member: "Skubinmappings.binlocationtype",
          operator: "equals",
          values: ["BIN"],
        },
        {
          member: "Skubinmappings.unallocatedQuantity",
          operator: "gt",
          values: ["0"],
        },
        {
          member: "Skubinmappings.tenant",
          operator: "equals",
          values: [tenantId],
        },
        {
          member: "Skubinmappings.customer",
          operator: "equals",
          values: customers,
        },
        {
          member: "Skubinmappings.warehouse",
          operator: "equals",
          values: warehouses,
        },
      ],
    });
  const { resultSet: binLocationResultSet, isLoading: binLocationIsLoading } =
    useCubeQuery({
      dimensions: [
        "Skubinmappings.binlocation",
        "Skubinmappings.sku",
        "Skubinmappings.updated_at",
        "Skubinmappings.unallocatedQuantity",
        "Productvariants.attributesCollectionname",
        "Productvariants.attributesColorname",
        "Productvariants.attributesSizename",
      ],
      timeDimensions: [],
      order: {
        "Skubinmappings.binlocation": "asc",
      },
      filters: [
        {
          member: "Skubinmappings.binlocation",
          operator: "equals",
          values: [selectedBinLocation],
        },
        {
          member: "Skubinmappings.unallocatedQuantity",
          operator: "gt",
          values: ["0"],
        },
        {
          member: "Skubinmappings.tenant",
          operator: "equals",
          values: [tenantId],
        },
        {
          member: "Skubinmappings.customer",
          operator: "equals",
          values: customers,
        },
        {
          member: "Skubinmappings.warehouse",
          operator: "equals",
          values: warehouses,
        },
      ],
    });

  React.useEffect(() => {
    setSelectedStatQuery(
      makeDetailedStatQueries(
        dateRange,
        {
          ...customDate,
        },
        binAgeCustomDate,
        tenantId,
        customers,
        warehouses,
      ).find((query) => query.statKey === selectedStatDetail),
    );
  }, [dateRange, selectedStatDetail]);

  const onChangeBinSearch = (e) => setBinSearchFilter(e);

  const debouncedBinSearch = _.debounce(onChangeBinSearch, 1000);

  const data = preprocessBinAgeData(
    binAgeResultSet?.loadResponse.results[0].data,
  );

  return (
    <>
      {selectedBinLocation && (
        <Modal
          title={selectedBinLocation}
          negativeAction={() => setSelectedBinLocation(null)}>
          <div className="overflow-y-scroll">
            <Table
              className="max-h-96"
              dataSource={binLocationResultSet?.loadResponse.results[0].data}
              loading={binLocationIsLoading}
              columns={[
                {
                  title: "SKU",
                  dataIndex: "Skubinmappings.sku",
                  key: "Skubinmappings.sku",
                  render: (text) => <a>{text}</a>,
                },
                {
                  title: "Quantity",
                  dataIndex: "Skubinmappings.unallocatedQuantity",
                  key: "Skubinmappings.unallocatedQuantity",
                  render: (text) => <a>{text}</a>,
                },
                {
                  title: "Age (days)",
                  dataIndex: "Skubinmappings.updated_at",
                  key: "Skubinmappings.updated_at",
                  sorter: (a, b) =>
                    a["Skubinmappings.updated_at"] <
                    b["Skubinmappings.updated_at"],
                  sortDirections: ["descend"],
                  render: (text) => (
                    <a>{moment().diff(moment(text), "days")}</a>
                  ),
                },
                {
                  title: "Name",
                  dataIndex: ["Productvariants.attributesCollectionname"],
                  key: "Skubinmappings.updated_at",
                  render: (text) => (
                    <div className="flex flex-col">
                      <p>{text}</p>
                    </div>
                  ),
                },
                {
                  title: "Color",
                  dataIndex: ["Productvariants.attributesColorname"],
                  key: "Skubinmappings.updated_at",
                  render: (text) => (
                    <div className="flex flex-col">
                      <p>{text}</p>
                    </div>
                  ),
                },
                {
                  title: "Size",
                  dataIndex: ["Productvariants.attributesSizename"],
                  key: "Skubinmappings.updated_at",
                  render: (text) => (
                    <div className="flex flex-col">
                      <p>{text}</p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </Modal>
      )}
      {selectedStatDetail &&
      selectedStatQuery &&
      (selectedStatDetail === "BIN_AGE_CUSTOM"
        ? customBinAgeResultSet2?.loadResponses[0]?.data
        : true) ? (
        <DetailedStat
          title={selectedStatQuery.title}
          onClose={() => {
            setSelectedStatDetail(null);
            setBinSearchFilter("");
          }}
          stat={selectedStatQuery}
          selectedBinLocation={selectedBinLocation}
          selectedStatDetail={selectedStatDetail}
          setSelectedBinLocation={setSelectedBinLocation}
          data={
            selectedStatDetail === "BIN_AGE_CUSTOM"
              ? customBinAgeResultSet2?.loadResponses[0]?.data
              : data
                  .filter((item) => item[selectedStatQuery.query.measures[0]])
                  .filter(
                    (item) =>
                      item.skus.filter((sku) =>
                        sku["Skubinmappings.sku"].includes(
                          binSearchFilter.toUpperCase(),
                        ),
                      ).length > 0 ||
                      item.binLocation.includes(binSearchFilter.toUpperCase()),
                  )
          }
          onChangeSearchFilter={debouncedBinSearch}
          dataIsLoading={customBinAgeIsLoading2}
          searchFilter={binSearchFilter}
        />
      ) : (
        <>
          <Stats
            stats={[
              {
                name: "Space Utilization (%)",
                renderValue: () =>
                  binLocationsFilled?.loadResponse?.results[0]?.data[0] &&
                  binLocationTotal?.loadResponse?.results[0]?.data[0]
                    ? Math.ceil(
                        (binLocationsFilled.loadResponse.results[0].data[0][
                          "Skubinmappings.count"
                        ] /
                          binLocationTotal?.loadResponse?.results[0]?.data[0][
                            "Binlocations.count"
                          ]) *
                          100,
                      )
                    : 0,
              },
              {
                name: "Avg. Doc to Stock time (Days)",
                query: {
                  measures: ["Productvariants.avg_sku_putaway_time_in_days"],
                  timeDimensions: [
                    {
                      dimension: "Productvariants.updated_at",
                      ...appliedDateRange,
                    },
                  ],
                  filters: [
                    {
                      member: "Productvariants.tenant",
                      operator: "equals",
                      values: [tenantId],
                    },
                    {
                      member: "Productvariants.customer",
                      operator: "equals",
                      values: customers,
                    },
                    {
                      member: "Productvariants.warehouse",
                      operator: "equals",
                      values: warehouses,
                    },
                  ],
                },
                //setShowView: (view) => setSelectedStatDetail(view),
                view: "AVG_DOC_TO_STOCK",
              },
              {
                name: "Avg. Shelf Time (Days)",
                query: {
                  measures: ["Productvariants.avg_sku_fulfilled_time_in_days"],
                  timeDimensions: [
                    {
                      dimension: "Productvariants.updated_at",
                      ...appliedDateRange,
                    },
                  ],
                  filters: [
                    {
                      member: "Productvariants.tenant",
                      operator: "equals",
                      values: [tenantId],
                    },
                    {
                      member: "Productvariants.customer",
                      operator: "equals",
                      values: customers,
                    },
                    {
                      member: "Productvariants.warehouse",
                      operator: "equals",
                      values: warehouses,
                    },
                  ],
                },
                //setShowView: (view) => setSelectedStatDetail(view),
                view: "AVG_SHELF",
              },
              {
                name: "Avg. Bin Age (Days)",
                query: {
                  measures: ["Skubinmappings.avg_bin_age"],
                  timeDimensions: [
                    {
                      dimension: "Skubinmappings.updated_at",
                      ...appliedDateRange,
                    },
                  ],
                  order: {
                    "Skubinmappings.created_at": "asc",
                  },
                  filters: [
                    {
                      member: "Skubinmappings.tenant",
                      operator: "equals",
                      values: [tenantId],
                    },
                    {
                      member: "Skubinmappings.customer",
                      operator: "equals",
                      values: customers,
                    },
                    {
                      member: "Skubinmappings.warehouse",
                      operator: "equals",
                      values: warehouses,
                    },
                  ],
                },
              },
            ]}
          />
          <p className="text-xl">Bin Ageing</p>
          <Stats
            grid="bin-age"
            stats={[
              ...BinAgeingCategories.map((item) => ({
                ...item,
                setShowView: (view) => setSelectedStatDetail(view),
                renderValue: () =>
                  data.reduce(
                    (prev, curr) => (curr[item.key] ? prev + 1 : prev),
                    0,
                  ),
              })),
            ]}></Stats>
          <div className="flex">
            <Stats
              grid={"custom-bin-age"}
              stats={[
                {
                  name: `${binAgeCustomDate.start} - ${binAgeCustomDate.end}`,
                  setShowView: () => setSelectedStatDetail("BIN_AGE_CUSTOM"),
                  renderValue: () =>
                    customBinAgeIsLoading
                      ? "Loading..."
                      : customBinAgeError
                      ? "Error"
                      : customBinAgeResultSet === null
                      ? "Loading..."
                      : customBinAgeResultSet?.loadResponses[0]?.data?.reduce(
                          (prev, curr) => prev + 1,
                          0,
                        ),
                },
              ]}
            />
            <button
              type="button"
              className={`ml-auto py-1 h-12 w-72 bg-F8F8F8 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-5F666B font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`}
              onClick={() => {
                setShowFilters(true);
              }}>
              <FilterIcon className="w-7 h-7 mr-1" />
              Change Custom Range
            </button>
          </div>
          {showFilters && (
            <Modal
              title="Set Custom Date"
              negativeAction={() => setShowFilters(false)}
              positiveAction={(e) => {
                onSubmit(e);
                setShowFilters(false);
              }}>
              <div className="space-y-4">
                <div className="flex-1">
                  <label className="block text-left mb-2">
                    <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                      Start Date
                    </span>
                  </label>
                  <TextField
                    type="date"
                    id="start"
                    label="Select Start Date"
                    placeholder=" "
                    onChange={onChange}
                    value={values["start"]}
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-left mb-2">
                    <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                      End Date
                    </span>
                  </label>
                  <TextField
                    type="date"
                    id="end"
                    label="Select End Date"
                    placeholder=" "
                    onChange={onChange}
                    value={values["end"]}
                  />
                </div>
              </div>
            </Modal>
          )}
          <div>
            <div className="w-full flex justify-between">
              <div className="pt-4 text-xl">Most Aged Bins</div>
              <div className="w-1/4 mb-4">
                <RoundedDropdown
                  placeholder={"Most Aged Bins"}
                  list={TopBinAgesOptions}
                  labelKey="name"
                  valueKey="value"
                  name="topBinAges"
                  selectedValue={selectedTopBinAges.value}
                  setSelected={(e) =>
                    setSelectedTopBinAges(
                      TopBinAgesOptions.find((option) => option.value === e),
                    )
                  }
                />
              </div>
            </div>
            <Table
              loading={binAgeResultIsLoading}
              columns={makeDetailedStatQueries(
                dateRange,
                { ...customDate },
                binAgeCustomDate,
              )
                .find(
                  (query) =>
                    query.statKey ===
                    BinAgeingCategories[BinAgeingCategories.length - 1].statKey,
                )
                //Remove sorting options from columns
                .tableColumns.map(({ sorter, ...column }) => ({ ...column }))}
              dataSource={data
                .sort((a, b) => b.averageBinAge - a.averageBinAge)
                .slice(0, parseInt(selectedTopBinAges.value))}
              pagination={{ showSizeChanger: false }}
              onRow={(record) => ({
                onClick: () => setSelectedBinLocation(record["binLocation"]),
              })}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SpaceStats;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
}) => {
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central">
      {value}
    </text>
  );
};

const preprocessBinAgeData = (data) =>
  Object.entries(_.groupBy(data, "Skubinmappings.binlocation")).map(
    ([binLocation, skus]) => {
      let [latestPutaway, averageBinAge, oldestSkuAge] = [
        0,
        0,
        Number.MAX_SAFE_INTEGER,
      ];

      const uniqueSkus = skus.length;
      let oldestPutawaySkuObj = {};

      for (let sku of skus) {
        const skuPutawayObj = Object.entries(sku).reduce(
          (prev, curr) =>
            !["Skubinmappings.binlocation", "Skubinmappings.sku"].includes(
              curr[0],
            ) && parseInt(curr[1]) > 0
              ? { [curr[0]]: parseInt(curr[1]) }
              : prev,
          { "": 0 },
        );

        const skuPutaway = skuPutawayObj[Object.keys(skuPutawayObj)[0]];
        if (skuPutaway > latestPutaway) {
          latestPutaway = skuPutaway;
        }

        if (skuPutaway < oldestSkuAge) {
          oldestSkuAge = skuPutaway;
          oldestPutawaySkuObj = JSON.parse(JSON.stringify(skuPutawayObj));
        }

        averageBinAge += moment().diff(moment(skuPutaway), "days");
      }
      averageBinAge = averageBinAge / skus.length;

      return {
        binLocation,
        uniqueSkus,
        latestPutaway,
        averageBinAge,
        oldestSkuAge,
        skus,
        ...oldestPutawaySkuObj,
      };
    },
  );

const makeDefaultCustomDateRange = () => ({
  start: moment().subtract(7, "d").format("YYYY-MM-DD"),
  end: moment().format("YYYY-MM-DD"),
});
