import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_CONFIGURATION } from "#queries";
import { SAVE_CONFIGURATION } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "../../contexts/masterData";

const withVariableConfiguratorLogic = (WrappedComponent) => {
  return (props) => {
    const [customerFilter, setCustomerFilter] = useState({
      customer: null,
      warehouse: null,
    });
    const auth = useContext(AuthContext);
    const masterData = useContext(MasterDataContext);
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [selectedConfiguration, setSelectedConfiguration] = useState({});
    const appState = useContext(AppStateContext);
    const getConfigurationQuery = useQuery(GET_CONFIGURATION);
    const saveConfigurationQuery = useQuery(SAVE_CONFIGURATION);
    // const deleteConfigurationQuery = useQuery(DELETE_CONFIGURATION);

    useEffect(() => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000
          );
        }
        setCustomerFilter({
          warehouse: warehouses[0],
          customer: customers[0],
        });
      }
    }, [auth]);

    useEffect(() => {
      fetchEntities();
    }, [customerFilter]);

    const fetchEntities = () => {
      const filtersSet = {};
      if (customerFilter.warehouse) {
        filtersSet["warehouse"] = customerFilter.warehouse.id;
      }
      if (customerFilter.customer) {
        filtersSet["customer"] = customerFilter.customer.id;
      }
      if (customerFilter.warehouse || customerFilter.customer) {
        setFilters({ ...filtersSet });
        getConfigurationQuery.fetchData({ ...filtersSet });
      }
    };

    useEffect(() => {
      if (getConfigurationQuery.data) {
        setSelectedConfiguration(getConfigurationQuery.data.configuration);
      }

      if (getConfigurationQuery.error) {
        setSelectedConfiguration({
          customer: filters["customer"],
          warehouse: filters["warehouse"],
          outboundVariables: [],
          inboundVariables: [],
        });
      }
    }, [
      getConfigurationQuery.loading,
      getConfigurationQuery.data,
      getConfigurationQuery.error,
    ]);

    useEffect(() => {
      if (saveConfigurationQuery.error) {
        // appState.setAlert(saveConfigurationQuery.error.message, "error", 5000);
      }

      if (saveConfigurationQuery.data && selectedConfiguration) {
        appState.setAlert(
          saveConfigurationQuery.data.saveConfiguration.message,
          "success",
          5000
        );

        getConfigurationQuery.fetchData({ ...filters });
      }
    }, [
      saveConfigurationQuery.loading,
      saveConfigurationQuery.error,
      saveConfigurationQuery.data,
    ]);

    // useEffect(() => {
    //   if (deleteConfigurationQuery.data) {
    //     appState.hideConfirmation();
    //     fetchEntities();
    //   }

    //   if (deleteConfigurationQuery.error) {
    //     appState.hideConfirmation();
    //   }
    // }, [
    //   deleteConfigurationQuery.loading,
    //   deleteConfigurationQuery.data,
    //   deleteConfigurationQuery.error,
    // ]);

    // const deleteButtonClicked = (id) => {
    //   appState.showConfirmation(
    //     "Confirm",
    //     "Are you sure you want to delete this configuration?",
    //     () => {
    //       deleteConfigurationQuery.fetchData({ id });
    //     },
    //     appState.hideConfirmation
    //   );
    // };

    const addVariableToSelected = (item) => {
      const copiedSelectedConfiguration = JSON.parse(
        JSON.stringify(selectedConfiguration)
      );
      if (!copiedSelectedConfiguration.outboundVariables) {
        copiedSelectedConfiguration.outboundVariables = [];
      }

      copiedSelectedConfiguration.outboundVariables.push(item);
      setSelectedConfiguration({ ...copiedSelectedConfiguration });
      saveConfigurationQuery.fetchData({
        configurationInput: copiedSelectedConfiguration,
      });
    };

    const removeVariableFromSelected = (item) => {
      const copiedSelectedConfiguration = JSON.parse(
        JSON.stringify(selectedConfiguration)
      );
      if (!copiedSelectedConfiguration.outboundVariables) {
        copiedSelectedConfiguration.outboundVariables = [];
      }

      copiedSelectedConfiguration.outboundVariables =
        copiedSelectedConfiguration.outboundVariables.filter(
          (i) => i.name !== item.name
        );
      setSelectedConfiguration({ ...copiedSelectedConfiguration });
      saveConfigurationQuery.fetchData({
        configurationInput: copiedSelectedConfiguration,
      });
    };

    return (
      <WrappedComponent
        selectedConfiguration={selectedConfiguration}
        saveConfiguration={(configuration) => {
          console.log(configuration);
          saveConfigurationQuery.fetchData({ ...configuration });
        }}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        selectedCustomer={selectedCustomer}
        selectedWarehouse={selectedWarehouse}
        onChangeFilter={(field, value, autoSubmit = false) => {
          setFilters({
            ...filters,
            [field]: value,
          });
          if (autoSubmit) {
            getConfigurationQuery.fetchData({
              ...filters,
              [field]: value,
            });
          }
        }}
        availableVariables={
          masterData.outboundVariables &&
          selectedConfiguration?.outboundVariables
            ? masterData.outboundVariables.filter(
                (item) =>
                  selectedConfiguration.outboundVariables.findIndex(
                    (i) => i.name === item.name
                  ) === -1
              )
            : []
        }
        selectedVariables={
          selectedConfiguration && selectedConfiguration.outboundVariables
            ? selectedConfiguration.outboundVariables
            : []
        }
        addVariableToSelected={addVariableToSelected}
        removeVariableFromSelected={removeVariableFromSelected}
        filters={filters}
        setFilters={setFilters}
        writable={props.writable}
      />
    );
  };
};

export default withVariableConfiguratorLogic;
