import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import Toggle from "#components/utils/Toggle";
import _ from "lodash";

const AddPrintForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedWorkflow,
  onSubmit,
  eligiblePrints,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Select Print
        </span>
      </div>
      <div>
        <div id="selectingStations">
          <Dropdown
            placeholder={"Select Print"}
            list={eligiblePrints.map((item) => ({
              name: item.name,
              value: item,
            }))}
            labelKey="name"
            valueKey="value"
            name="print"
            setSelected={(e) => {
              onChangeDropdown("buildPrint", e);
            }}
            selectedValue={selectedWorkflow?.buildPrint}
          />
        </div>
      </div>
      <div>
        <div id="selectingStations">
          <Dropdown
            placeholder={"Trigger"}
            list={[
              { name: "Before activity start" },
              { name: "Before every item" },
              { name: "After every item" },
              { name: "After activity completion" },
            ]}
            labelKey="name"
            valueKey="name"
            name="printTrigger"
            setSelected={(e) => {
              onChangeDropdown("printTrigger", e);
            }}
            selectedValue={selectedWorkflow.printTrigger}
          />
        </div>
      </div>
      <div className="flex space-x-4 items-center px-2">
        <div>
          <Toggle
            enabled={selectedWorkflow.allowReprint}
            setEnabled={(e) => onChangeDropdown("allowReprint", e)}
          />
        </div>
        <div className="text-lg">Allow Re-print</div>
      </div>
    </div>
  </Modal>
);

export default AddPrintForm;
