import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Menu, Transition, Disclosure } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import GraphForm from "#components/dashboard/GraphForm";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { useCubeQuery } from "@cubejs-client/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const chartComponent = (graph, data, resultSet) => {
  const colors = ["#primaryAccent", "#F4C261", "#7A77FF"];
  if (graph.isTimeDimension) {
    data = data.map((d) => {
      let date = new Date(d["x"]);
      d["x"] = date.toLocaleDateString();
      return d;
    });
  }
  if (graph.type === "Bar Graph") {
    return (
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" title="Days" />
          <YAxis />
          <Tooltip />
          <Legend />
          {resultSet.seriesNames().map((series, i) => (
            <Bar
              key={series.key}
              stackId="a"
              dataKey={series.key}
              name={series.title.split(" ").slice(1).join(" ")}
              fill={colors[i]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" title="Days" />
          <YAxis />
          <Tooltip />
          <Legend />
          {resultSet.seriesNames().map((series, i) => (
            <Line
              type="monotone"
              dataKey={series.key}
              name={series.title.split(" ").slice(1).join(" ")}
              stroke="#2C7695"
              activeDot={{ r: 8 }}
              strokeWidth={4}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  }
};

const chartRenderer = (graph, { resultSet, error }) => {
  if (resultSet) {
    return chartComponent(graph, resultSet.chartPivot(), resultSet);
  } else if (error && error.toString()) {
    return <h4>{error.toString()}</h4>;
  } else {
    return <h4>Loading...</h4>;
  }
};

const GraphComponent = ({ graph, dateRange }) => {
  const query = {
    measures: graph.measures,
    dimensions: graph.isTimeDimension ? undefined : graph.dimensions,
    timeDimensions: graph.isTimeDimension
      ? graph.dimensions.map((dim) => ({
          dimension: dim,
          granularity: "day",
          dateRange: dateRange,
        }))
      : undefined,
  };
  const renderProps = useCubeQuery(query);
  return chartRenderer(graph, renderProps);
};

const Graphs = ({ dashboard, dateRange }) => {
  return (
    <div class="grid grid-cols-2 gap-4 mt-4">
      {dashboard.graphs.map((graph) => (
        <div className="bg-white p-2 rounded-xl shadow-lg px-4 py-4">
          <div className="text-lg">{graph.name}</div>
          <div className="w-full mt-5" style={{ height: "300px" }}>
            <GraphComponent graph={graph} dateRange={dateRange} />
          </div>
        </div>
      ))}
    </div>
  );
};

const CustomDashboardComponent = ({
  dashboards,
  dashboard,
  selectedGraph,
  setSelectedGraph,
  onChange,
  cubeMeta,
  addGraph,
  dateRange,
  selectedDashboard,
  setSelectedDashboard,
  deleteDashboard
}) => {
  return (
    <>
      <div className="w-full flex">
        <div className="ml-auto">
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Open dashboard menu</span>
                <DotsVerticalIcon className="h-8 w-8 rounded-full" alt="" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                          setSelectedDashboard(dashboard.dashboard)
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700",
                        "cursor-pointer"
                      )}
                    >
                      Edit...
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        deleteDashboard(dashboard.dashboard)
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700",
                        "cursor-pointer"
                      )}
                    >
                      Delete
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        setSelectedGraph({
                          dashboard: dashboard.dashboard.id,
                        });
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700",
                        "cursor-pointer"
                      )}
                    >
                      Create Graph...
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          {selectedGraph && (
            <GraphForm
              onClose={() => {
                setSelectedGraph(null);
              }}
              title={selectedGraph.id ? "Edit Graph" : "Add Graph"}
              onChange={onChange}
              selectedGraph={selectedGraph}
              setSelectedGraph={setSelectedGraph}
              onSubmit={() => {
                addGraph(selectedGraph);
              }}
              dashboards={dashboards}
              cubeMeta={cubeMeta}
            />
          )}
        </div>
      </div>
      <Graphs dashboard={dashboard} dateRange={dateRange} />
    </>
  );
};

export default CustomDashboardComponent;
