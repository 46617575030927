import { GET_REPORTS, GET_REPORTS_METADATA, GET_REPORT } from "../../queries";
import {
  SAVE_REPORT,
  START_REPORT_GENERATION,
  DELETE_REPORT,
  UPDATE_REPORT,
} from "../../mutations";
import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { EntityContext } from "#contexts/entity";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { PrinterIcon, EyeIcon } from "@heroicons/react/solid";
import Papa from "papaparse";
const ALERT_TIMEOUT_MS = 5000;

const withReportsLogic = (WrappedComponent) => {
  return (props) => {
    const REPORT_GET_MODES = {
      DEFAULT: "none",
      CSV: "CSV",
      PDF: "PDF",
      PREVIEW: "Preview",
    };
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const [selectedReport, setSelectedReport] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const reportsQuery = useQuery(GET_REPORTS);
    const reportsMetadataQuery = useQuery(GET_REPORTS_METADATA);
    const saveReportQuery = useQuery(SAVE_REPORT);
    const [reportsMetadata, setReportsMetadata] = useState(null);
    const getReportQuery = useQuery(GET_REPORT);
    const getReportPollQuery = useQuery(GET_REPORT);
    const startReportGenerationQuery = useQuery(START_REPORT_GENERATION);
    const [reportMode, setReportMode] = useState(REPORT_GET_MODES.DEFAULT);
    const [previewData, setPreviewData] = useState(null);
    const deleteReportQuery = useQuery(DELETE_REPORT);
    const updateReportQuery = useQuery(UPDATE_REPORT);

    useEffect(() => {
      if (getReportQuery.loading && reportMode !== REPORT_GET_MODES.DEFAULT) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (getReportQuery.data) {
        if (getReportQuery?.data?.report?.id) {
          // report is ready
          let url = null;
          if (reportMode === REPORT_GET_MODES.CSV) {
            url = getReportQuery.data.report.csvUrl;
            window.open(url, "_blank");
            setReportMode(REPORT_GET_MODES.DEFAULT);
          } else if (reportMode === REPORT_GET_MODES.PDF) {
            url = getReportQuery.data.report.pdfUrl;
            window.open(url, "_blank");
            setReportMode(REPORT_GET_MODES.DEFAULT);
          } else if (reportMode === REPORT_GET_MODES.PREVIEW) {
            url = getReportQuery.data.report.previewUrl;
            fetch(url)
              .then((response) => {
                response.text().then((csvString) => {
                  // Parse the CSV string
                  const result = Papa.parse(csvString, {
                    dynamicTyping: true,
                  });
                  const csvData = result.data;
                  setPreviewData(csvData);
                });
              })
              .catch((err) => {
                appState.setAlert(err.message, "error", 5000);
              });
            setReportMode(REPORT_GET_MODES.DEFAULT);
          }
        } else {
          appState.setAlert(
            getReportQuery?.data?.report?.error || "Failed to get report.",
            "error",
            5000,
          );
        }
      } else if (
        getReportQuery.error &&
        reportMode !== REPORT_GET_MODES.DEFAULT
      ) {
        appState.setAlert(getReportQuery.error.message, "error", 5000);
      }
    }, [getReportQuery.loading, getReportQuery.error, getReportQuery.data]);

    useEffect(() => {
      if (getReportPollQuery?.data?.report?.status === "GENERATED") {
        appState.setAlert(
          `Report "${getReportPollQuery.data.report.reportName}" is ready to download.`,
          "success",
          5000,
        );
        entity.setEntities({
          ...entity,
          entities: entity.entities.map((report) => {
            if (report.id === getReportPollQuery.data.report.id) {
              return getReportPollQuery.data.report;
            }
            return report;
          }),
        });
      } else if (getReportPollQuery?.data?.report?.error) {
        appState.setAlert(
          getReportPollQuery?.data?.report?.error,
          "error",
          5000,
        );
      }

      if (getReportPollQuery.error) {
        appState.setAlert(
          getReportQuery?.error?.message || "Failed to get report.",
          "error",
          5000,
        );
      }
    }, [
      getReportPollQuery.data,
      getReportPollQuery.loading,
      getReportPollQuery.error,
    ]);
    const downloadCSV = (id) => {
      setReportMode(REPORT_GET_MODES.CSV);
      getReportQuery.fetchData({
        reportId: id,
      });
    };
    const downloadPDF = (id) => {
      setReportMode(REPORT_GET_MODES.PDF);
      getReportQuery.fetchData({
        reportId: id,
      });
    };
    const viewReport = (id) => {
      setReportMode(REPORT_GET_MODES.PREVIEW);
      getReportQuery.fetchData({
        reportId: id,
      });
    };
    const menuItems = (report) => {
      const arr = [];
      if (report.status === "GENERATED") {
        arr.push({
          title: "Download CSV",
          icon: <PrinterIcon className="w-5 h-5 mr-2" />,
          onClick: downloadCSV,
        });
        arr.push({
          title: "View Report",
          icon: <EyeIcon className="w-5 h-5 mr-2" />,
          onClick: viewReport,
        });
      }
      return arr;
    };
    useEffect(() => {
      if (auth && auth.user) {
        entity.setSort({ sort: "reportName" });
        let filterSet = {
          customers: null,
          keyword: null,
          module: null,
          warehouses: null,
          role: "ADMIN",
        };
        if (auth.user?.role?.toLowerCase() !== "admin") {
          filterSet.customers = auth.user?.customers;
          filterSet.role = auth.user?.role;
        }
        entity.setFilters(filterSet);
        setTimeout(() => {
          reportsQuery.fetchData({
            perPage: entity.perPage,
            paginated: false,
            pageNumber: entity.pageNumber,
            sort: entity.sort,
            filters: filterSet,
          });
        }, 500);
      }
      return () => {
        entity.resetEntities();
      };
    }, [auth]);

    useEffect(() => {
      reportsMetadataQuery.fetchData();
    }, []);

    useEffect(() => {
      if (reportsMetadataQuery.data) {
        setReportsMetadata(reportsMetadataQuery.data.reportsMetadata);
      }
    }, [
      reportsMetadataQuery.loading,
      reportsMetadataQuery.error,
      reportsMetadataQuery.data,
    ]);

    useEffect(() => {
      if (saveReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveReportQuery.data) {
        if (saveReportQuery?.data?.createReport?.message) {
          appState.setAlert(
            saveReportQuery.data.createReport.message,
            "success",
            5000,
          );
          setSelectedReport(null);
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        } else {
          appState.setAlert(
            saveReportQuery.data.createReport.error || "Failed to save report.",
            "error",
            5000,
          );
        }
      } else if (saveReportQuery.error) {
        appState.setAlert(saveReportQuery.error.message, "error", 5000);
      }
    }, [saveReportQuery.loading, saveReportQuery.error, saveReportQuery.data]);

    useEffect(() => {
      const autoRefreshInterval = setInterval(() => {
        const reports = entity.entities;
        for (const report of reports) {
          if (report.status === "GENERATING") {
            setReportMode(REPORT_GET_MODES.DEFAULT);
            getReportPollQuery.fetchData({
              reportId: report.id,
            });
          }
        }
      }, 2000);
      return () => {
        clearInterval(autoRefreshInterval);
      };
    }, [entity.entities]);

    useEffect(() => {
      if (reportsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (reportsQuery.data) {
        entity.setEntities({
          ...reportsQuery.data.reports,
          ...reportsQuery.variables,
        });
        appState.removeLoading();
      }
    }, [reportsQuery.loading, reportsQuery.error, reportsQuery.data]);

    const selectReport = (id) => {
      setSelectedReport({ id });
    };

    useEffect(() => {
      if (startReportGenerationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (startReportGenerationQuery.data) {
        if (startReportGenerationQuery?.data?.generateReport?.message) {
          appState.setAlert(
            startReportGenerationQuery.data.generateReport.message,
            "success",
            5000,
          );
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        } else {
          appState.setAlert(
            startReportGenerationQuery?.data?.generateReport?.error ||
              "Failed to generate report.",
            "error",
            5000,
          );
        }
      } else if (startReportGenerationQuery.error) {
        appState.setAlert(
          startReportGenerationQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      startReportGenerationQuery.loading,
      startReportGenerationQuery.error,
      startReportGenerationQuery.data,
    ]);

    const queueReportGeneration = (id) => {
      startReportGenerationQuery.fetchData({
        generateReportId: id,
      });
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return reportsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const onChange = (event) => {
      let { name, value, type } = event.target;
      if (type === "number") {
        value = parseInt(value);
      } else if (name === "reportName") {
        /**
         * ensure that the report name value only contains:
         * 1. alphanumeric characters
         * 2. spaces
         * 3. underscores
         * 4. dashes
         */
        value = value.replace(/[^a-zA-Z0-9 _-]/g, "");
      }
      setSelectedReport((prevReport) => ({
        ...prevReport,
        [name]: value,
      }));
    };

    const onChangeDropdown = (field, value) => {
      const report = {
        ...selectedReport,
      };
      report[field] = value;
      if (field === "module") {
        report.selectedVariables = [];
      }
      setSelectedReport(report);
    };

    const onChangeDropdownMulti = (field, value) => {
      const report = {
        ...selectedReport,
      };
      if (!report[field]) {
        report[field] = [];
      }
      if (report[field].includes(value)) {
        report[field] = report[field].filter((item) => item !== value);
      } else {
        report[field] = [...report[field], value];
      }

      setSelectedReport(report);
    };

    useEffect(() => {
      if (updateReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (updateReportQuery.data) {
        if (updateReportQuery?.data?.updateReport?.message) {
          appState.setAlert(
            updateReportQuery.data.updateReport.message,
            "success",
            5000,
          );
          setSelectedReport(null);
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        } else {
          appState.setAlert(
            updateReportQuery.data.updateReport.error ||
              "Failed to update report.",
            "error",
            5000,
          );
        }
      } else if (updateReportQuery.error) {
        appState.setAlert(updateReportQuery.error.message, "error", 5000);
      }
    }, [
      updateReportQuery.loading,
      updateReportQuery.error,
      updateReportQuery.data,
    ]);

    const saveReport = (report) => {
      appState.setLoading();
      if (!report.customers || report.customers?.length === 0) {
        if (auth.user?.role?.toLowerCase() === "admin") {
          report.customers = [];
        }
        report.customers = auth.user?.customers;
      }
      if (!report.warehouses) {
        report.warehouses = [];
      }
      const reportInput = {
        ...report,
      };
      delete reportInput.customer;
      delete reportInput.warehouse;
      delete reportInput.status;
      delete reportInput.pdfUrl;
      delete reportInput.csvUrl;
      delete reportInput.previewUrl;
      if (report.id) {
        updateReportQuery.fetchData({
          updateReportInput: reportInput,
        });
      } else {
        saveReportQuery.fetchData({
          createReportInput: reportInput,
        });
      }
    };

    useEffect(() => {
      if (deleteReportQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (deleteReportQuery.data) {
        if (deleteReportQuery?.data?.deleteReport?.message) {
          appState.setAlert(
            deleteReportQuery.data.deleteReport.message,
            "success",
            5000,
          );
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        } else {
          appState.setAlert(
            deleteReportQuery?.data?.deleteReport?.error ||
              "Failed to delete report.",
            "error",
            5000,
          );
        }
      } else if (deleteReportQuery.error) {
        appState.setAlert(deleteReportQuery.error.message, "error", 5000);
      }
    }, [
      deleteReportQuery.loading,
      deleteReportQuery.error,
      deleteReportQuery.data,
    ]);

    const deleteButtonClicked = (id) => {
      deleteReportQuery.fetchData({
        deleteReportId: id,
      });
    };

    return (
      <WrappedComponent
        {...props}
        fetchedReport={getReportQuery?.data?.report}
        previewData={previewData}
        menuItems={menuItems}
        setPreviewData={setPreviewData}
        reports={entity.entities}
        total={entity.total}
        selectReport={selectReport}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        writable={props.writable}
        perPage={entity.perPage}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        onChangeDropdownMulti={onChangeDropdownMulti}
        deleteButtonClicked={deleteButtonClicked}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          reportsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            reportsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(event) =>
          entity.setFilters({
            ...entity.filters,
            keyword: event.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        setSelectedReport={setSelectedReport}
        selectedReport={selectedReport}
        clearFilters={() => {
          let filterSet = {
            customers: null,
            keyword: null,
            module: null,
            warehouses: null,
            role: "ADMIN",
          };
          if (auth.user?.role?.toLowerCase() !== "admin") {
            filterSet.customers = auth.user?.customers;
            filterSet.role = auth.user?.role;
          }
          entity.setFilters(filterSet);
          setSelectedCustomer(null);
          setSelectedWarehouse(null);
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: filterSet,
            sort: entity.sort,
          });
        }}
        saveReport={saveReport}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={(warehouseId) => {
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              warehouses: [warehouseId],
            },
          });
          setSelectedWarehouse(warehouseId);
        }}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(customerId) => {
          reportsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              customers: [customerId],
            },
          });
          setSelectedCustomer(customerId);
        }}
        reportsMetadata={reportsMetadata}
        queueReportGeneration={queueReportGeneration}
      />
    );
  };
};

export default withReportsLogic;
