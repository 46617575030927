const defaultBoxTypes = [
  {
    name: "Large",
    height: 24,
    width: 16,
    length: 19,
    maxWeight: 65,
  },
  {
    name: "Medium",
    height: 19,
    width: 14,
    length: 17,
    maxWeight: 65,
  },
  {
    name: "Small-18",
    height: 18,
    width: 12,
    length: 12,
    maxWeight: 65,
  },
  {
    name: "Small-17",
    height: 17,
    width: 11,
    length: 13,
    maxWeight: 65,
  },
  {
    name: "Shoe",
    height: 16,
    width: 10,
    length: 6,
    maxWeight: 65,
  },
  {
    name: "6's",
    height: 6,
    width: 6,
    length: 6,
    maxWeight: 10,
  },
];

export default defaultBoxTypes;