import withPickingsLogic from "#components/HOC/withPickingsLogic";
import moment from "moment-timezone";
import _ from "lodash";
import {
  FastForwardIcon,
  PencilAltIcon,
  TrashIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import Stats from "#components/common/Stats";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedBatch from "#components/batches/outbound/ExpandedBatch";
import PickingsStats from "#components/pickings/CustomPickingsStats";
import AssignBatchPickerForm from "#components/pickings/AssignBatchPicker";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useEffect, useState } from "react";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";
import { Menu } from "@headlessui/react";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
const noValuesText = "No Pickings";
import { renderStatus } from "../../utils/renderStatus";
import { isDelmarTenant } from "#utils/tenantCheck";
import Checkbox from "#components/utils/Checkbox";
import Toggle from "#components/utils/Toggle";
import AddButton from "#components/utils/AddButton";
const PICKINGS_TABLE_NAME = "pickingsList";

const PickingsList = ({
  displayBatches,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  setShowFilters,
  filters,
  clearKeyword,
  clearFilters,
  showFilters,
  onChangeFilter,
  allData,
  users,
  usersMap,
  expandedBatch,
  setExpandedBatch,
  expandBatch,
  stats,
  setStats,
  productivityStats,
  setProductivityStats,
  fetchBatchesForStats,
  selectedPicker,
  setSelectedPicker,
  fetchBatchesForProductivityStats,
  customers,
  warehouses,
  selectedBatch,
  setSelectedBatch,
  prioritizeBatch,
  onAssignBatchPicker,
  showPickerSelector,
  setShowPickerSelector,
  writable,
  deleteBatch,
  tenant,
  selectedRows,
  selectRow,
  selectAllRows,
  allRowsSelected,
  showOnlySelected,
  setShowOnlySelected,
  clearSelectedBatches,
  downloadBatchesCsv,
}) => {
  const menuItems = (batch) => {
    const arr = [];

    if (writable) {
      arr.push({
        title: "Re-assign Picker",
        icon: PencilAltIcon,
        onClick: () => {
          setShowPickerSelector(true);
          setSelectedBatch(batch);
        },
      });
      arr.push({
        title: "Prioritize",
        icon: FastForwardIcon,
        onClick: () => {
          prioritizeBatch(batch);
        },
      });
      arr.push({
        title: "Delete",
        icon: TrashIcon,
        onClick: () => {
          deleteBatch(batch);
        },
      });
    }

    return arr;
  };

  const getInitialHeaders = () => {
    const arr = [
      getHeaderObject("Batch ID", "id", false),
      getHeaderObject("User", "user", false),
      getHeaderObject("Assigned Time", "assignedTime"),
      getHeaderObject(
        "First Pick",
        "firstCompletedTime",
        true,
        "attributes.firstCompletedTime",
      ),
      getHeaderObject(
        "Last Pick",
        "lastCompletedTime",
        true,
        "attributes.lastCompletedTime",
      ),
      getHeaderObject("# orders", "orders", false),
      getHeaderObject("# unique products", "products", false),
      getHeaderObject("Total Qty", "quantity", false),
      getHeaderObject("Completion", "completion"),
      getHeaderObject("Status", "status"),
      getHeaderObject("Tote", "tote"),
      getHeaderObject(
        "Requested Dropoff Station",
        "requestedDropoffPackingStation",
      ),
      getHeaderObject("Dropoff Station", "dropoffStation"),
      getHeaderObject("Client(s)", "customer"),
      getHeaderObject("Warehouse", "warehouse"),
    ];

    if (isDelmarTenant(tenant?.subdomain)) {
      arr.push(
        getHeaderObject("Multiple Line Item Batch", "multipleLineItemBatch"),
      );
      arr.push(getHeaderObject("Classification", "classification"));
    }

    if (writable) {
      arr.push(getHeaderObject("Action", "action"));
    }

    return arr;
  };
  const [headers, setHeaders] = useState(getInitialHeaders());
  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      PICKINGS_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  return (
    <>
      <Stats
        stats={[
          {
            name: "Total Batches",
            stat: allData.total,
            view: "BATCHES",
            setShowView: () => {
              setStats(null);
              setProductivityStats(null);
            },
          },
          {
            name: "Completion",
            stat:
              allData.totalCompleted && allData.total
                ? allData.totalCompleted?.toString() +
                  " / " +
                  parseInt(
                    (allData.totalCompleted / allData.total) * 100,
                  ).toString() +
                  " %"
                : "",
            stringValue: true,
          },
          {
            name: "Picks per Hour",
            stat: allData.completionPerHour,
            view: "PICKINGS_STATS",
            setShowView: () => {
              fetchBatchesForStats();
              fetchBatchesForProductivityStats();
            },
          },
          {
            name: "Items in Error",
            stat: allData.itemsInError,
          },
        ]}
      />
      {filters && (filters["startDate"] || filters["endDate"]) && (
        <div className="flex items-end justify-end text-primaryAccent font-semibold text-2xl">
          Date Range:{" "}
          {filters && filters["startDate"] ? filters["startDate"] : "Beginning"}{" "}
          - {filters && filters["endDate"] ? filters["endDate"] : "Now"}
        </div>
      )}
      {stats && productivityStats ? (
        <PickingsStats
          stats={stats}
          productivityStats={productivityStats}
          setSelectedPicker={setSelectedPicker}
          selectedPicker={selectedPicker}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          filters={filters}
          fetchBatchesForStats={fetchBatchesForStats}
          fetchBatchesForProductivityStats={fetchBatchesForProductivityStats}
        />
      ) : (
        <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
          {(customers?.length > 1 || warehouses?.length > 1) && (
            <QuickFilters
              warehouseFilterName={"warehouses"}
              customerFilterName={"customers"}
              customers={customers}
              warehouses={warehouses}
              filters={filters}
              onChangeFilter={onChangeFilter}
            />
          )}
          <SearchSortFilter
            onChangeSearchKeyword={onChangeSearchKeyword}
            filters={filters}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            setSort={setSort}
            sort={sort}
            setShowFilters={setShowFilters}
            clearFilters={clearFilters}
            choices={choices}
            headers={headers}
            setShowHeaders={setShowHeaders}
            tableName={PICKINGS_TABLE_NAME}
          />

          {(selectedRows.length > 0 || showOnlySelected) && (
            <div className="flex space-x-4 items-center px-2">
              <div>
                <Toggle
                  enabled={showOnlySelected}
                  setEnabled={(key) => setShowOnlySelected(key)}
                />
              </div>
              <div className="text-2xl">
                Show only selected batches(
                {selectedRows.length})
              </div>
              <AddButton
                text={"Clear"}
                onClick={() => {
                  clearSelectedBatches();
                }}
              />
              <AddButton
                text={"Export to CSV"}
                onClick={() => {
                  downloadBatchesCsv();
                }}
              />
            </div>
          )}
          <CustomTableWithTheme>
            <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
              <tr className="text-textWhite font-montserrat border-left">
                <th
                  scope="col"
                  className="px-2 pl-6 py-3 text-left tracking-wider font-medium">
                  <Checkbox
                    role="checkbox"
                    onChange={selectAllRows}
                    name="select All Rows"
                    value={"Select All Rows"}
                    checked={allRowsSelected}
                  />
                </th>
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {displayBatches.length === 0 ? (
                <tr className="bg-white">
                  {headers.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                    ) : (
                      <td
                        className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                        key={headerIdx}></td>
                    ),
                  )}
                </tr>
              ) : null}
              {displayBatches.map((picking, rowIndex) => (
                <tr
                  key={picking.id}
                  className={`${
                    rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-wider rounded-tl rounded-bl border-l-8  ${
                      rowIndex % 2 === 0
                        ? "border-F4C261"
                        : "border-primaryAccent"
                    }`}>
                    <Checkbox
                      role="checkbox"
                      onChange={(_) => {
                        selectRow(rowIndex);
                      }}
                      name="batch"
                      value={picking.id}
                      checked={selectedRows.includes(picking.id)}
                    />
                  </td>
                  {showHeaders.map((header, columnIndex) => {
                    let value = header.correspondingValue;
                    let cellStyle = cellStyleForTable(
                      value,
                      ["id"],
                      columnIndex + 1,
                      rowIndex,
                    );
                    if (value == "id") {
                      return (
                        <td
                          className={cellStyle}
                          onClick={() => expandBatch(picking.id)}>
                          {picking.id}
                        </td>
                      );
                    }
                    if (value == "user") {
                      return (
                        <td className={cellStyle}>
                          {usersMap[picking.user]?.name || "-"}
                        </td>
                      );
                    }
                    if (value == "assignedTime") {
                      return (
                        <td className={cellStyle}>
                          {moment(
                            picking.assignedTime
                              ? picking.assignedTime
                              : picking.createdAt,
                          ).format("MMM Do YYYY, h:mm a")}
                        </td>
                      );
                    }
                    if (value == "firstCompletedTime") {
                      return (
                        <td className={cellStyle}>
                          {picking.attributes?.firstCompletedTime &&
                            moment(
                              picking.attributes.firstCompletedTime,
                            ).format("MMM Do YYYY, h:mm a")}
                        </td>
                      );
                    }
                    if (value == "lastCompletedTime") {
                      return (
                        <td className={cellStyle}>
                          {picking.attributes?.lastCompletedTime &&
                            moment(picking.attributes.lastCompletedTime).format(
                              "MMM Do YYYY, h:mm a",
                            )}
                        </td>
                      );
                    }
                    if (value === "quantity") {
                      return (
                        <td className={cellStyle}>{picking.itemsLength}</td>
                      );
                    }
                    if (value === "products") {
                      return (
                        <td className={cellStyle}>
                          {picking.numberOfUniqueProducts}
                        </td>
                      );
                    }
                    if (value === "orders") {
                      return (
                        <td className={cellStyle}>
                          {picking.attributes?.humanReadableOrderIds?.length >
                          0 ? (
                            <ExpandedMultipleItem
                              items={picking.attributes?.humanReadableOrderIds}
                              title={`${picking.attributes?.humanReadableOrderIds?.length}`}
                              expandThreshold={0}
                            />
                          ) : (
                            `-`
                          )}
                        </td>
                      );
                    }
                    if (value == "completion") {
                      return (
                        <td className={cellStyle}>
                          {picking.completion && `${picking.completion}%`}
                        </td>
                      );
                    }
                    if (value == "status") {
                      return (
                        <td className={cellStyle}>
                          {renderStatus(picking.status)}
                        </td>
                      );
                    }
                    if (value === "tote") {
                      return <td className={cellStyle}>{picking.tote}</td>;
                    }
                    if (value === "warehouse") {
                      return (
                        <td className={cellStyle}>
                          {warehouses?.find((i) =>
                            picking?.warehouse?.includes(i.id),
                          )?.name || `-`}
                        </td>
                      );
                    }
                    if (value === "multipleLineItemBatch") {
                      return (
                        <td className={cellStyle}>
                          {picking.attributes?.multipleLineItemBatch
                            ? "Yes"
                            : "No"}
                        </td>
                      );
                    }
                    if (value === "classification") {
                      return (
                        <td className={cellStyle}>
                          {picking.classification || `-`}
                        </td>
                      );
                    }
                    if (value === "customer") {
                      return (
                        <td className={cellStyle}>
                          {customers?.filter((i) =>
                            picking?.customer?.includes(i.id),
                          )?.length === 1 ? (
                            customers?.find((i) =>
                              picking?.customer?.includes(i.id),
                            )?.name
                          ) : (
                            <ExpandedMultipleItem
                              items={customers
                                ?.filter((i) =>
                                  picking?.customer?.includes(i.id),
                                )
                                .map((customer) => customer.name)}
                            />
                          )}
                        </td>
                      );
                    }
                    if (value === "requestedDropoffPackingStation") {
                      return (
                        <td className={cellStyle}>
                          {picking.attributes?.requestedDropoffPackingStation ||
                            "-"}
                        </td>
                      );
                    }
                    if (value === "dropoffStation") {
                      return (
                        <td className={cellStyle}>
                          {picking.attributes?.dropoffStation || "-"}
                        </td>
                      );
                    }
                    if (writable && value === "action") {
                      return (
                        <td className={cellStyle}>
                          <Menu as="div" className="text-left relative">
                            <Menu.Button>
                              <DotsVerticalIcon className="w-8 bg-E1D3B8 p-2 text-md text-5F666B font-montserrat rounded h-8" />
                            </Menu.Button>
                            <Menu.Items
                              className={`${
                                columnIndex > showHeaders.length / 2 &&
                                "right-0"
                              } absolute z-10 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                              {menuItems(picking).map((menuItem, idx) => (
                                <Menu.Item key={idx}>
                                  {({ active }) => (
                                    <button
                                      className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                                      onClick={menuItem["onClick"]}>
                                      {menuItem.icon && (
                                        <menuItem.icon className="w-8 h-8 pr-2" />
                                      )}
                                      {menuItem.title}
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Menu>
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </CustomTableWithTheme>
        </div>
      )}
      {selectedBatch && showPickerSelector && (
        <AssignBatchPickerForm
          onClose={() => setSelectedBatch(null)}
          title={"Assign Picker"}
          onSubmit={onAssignBatchPicker}
          selectedBatch={selectedBatch}
          setSelectedBatch={setSelectedBatch}
          users={users}
        />
      )}
      {!stats && (
        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
        />
      )}
      {expandedBatch && (
        <ExpandedBatch
          batchItems={expandedBatch.items}
          negativeAction={() => setExpandedBatch(null)}
          tableName={"picking.orderDetails"}
          orderIds={expandedBatch.orderIds}
          batch={expandedBatch}
          usersMap={usersMap}
        />
      )}
      {showFilters && (
        <BatchFilters
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          users={
            users
              ? users.filter(
                  (item) =>
                    item.hopstackModules &&
                    item.hopstackModules.includes("Picking"),
                )
              : []
          }
          customers={customers}
          warehouses={warehouses}
        />
      )}
    </>
  );
};

export default withPickingsLogic(PickingsList);
