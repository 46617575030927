import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import {
  EyeIcon,
  DocumentSearchIcon,
  DotsVerticalIcon,
  ClipboardCheckIcon,
  CheckCircleIcon,
  DownloadIcon,
  MinusCircleIcon,
  HomeIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import {
  PrinterIcon,
  PencilAltIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { Menu } from "@headlessui/react";
import AddButton from "#components/utils/AddButton";
import Checkbox from "#components/utils/Checkbox";
import SearchSortFilter from "#components/common/SearchSortFilter";
import ExceptionReasons from "#components/orders/ExceptionReasons";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import cellStyleForTable from "../common/CellStyleForTable";
import { getCurrentValueOfFilter } from "../../utils/getCurrentValueOfFilter";
import CustomSelectDropDown from "../common/CustomSelectDropDown";
import Toggle from "#components/utils/Toggle";
import TableFilters from "../common/TableFilters";

const noValuesText = "No Orders";
const ORDER_MANAGEMENT_TABLE_NAME = "orderManagement";
import { isDelmarTenant } from "#utils/tenantCheck";
import getCarrierTrackingURL from "../../utils/getCarrierTrackingUrl";
import { OrderSource } from "../../utils/enums/orderSourceEnum";

const OrdersList = ({
  writable,
  orders,
  filters,
  onChangeFilter,
  getAudit,
  selectOrder,
  selectedOrders,
  runAutoBatching,
  simulateAutoBatching,
  createManualBatch,
  clearSelectedOrders,
  expandOrder,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  sort,
  setSort,
  setShowFilters,
  clearFilters,
  printShippingLabel,
  downloadShippingLabel,
  customers,
  markCompleted,
  markInProcess,
  outboundExceptions,
  tryResolve,
  viewShipmentPlan,
  updateTracking,
  updateCarrier,
  viewShippingAddress,
  expandedException,
  setExpandedException,
  expandException,
  tenant,
  printBol,
  downloadBol,
  deleteOrder,
  downloadOrderReport,
  cancelOrders,
  selectAllRows,
  allRowsSelected,
  downloadFnSkuLabels,
  printFnSkuLabels,
  handleAssignWarehouse,
  editOrder,
  markShippedOnMarketplace,
  tryMassResolveExceptions,
  possibleSubStatuses,
  showOnlySelected,
  setShowOnlySelected,
  showBatchSettings,
  setShowBatchSettings,
  batchSettingsEnabled,
  warehouses,
  bolUploadEnabled,
  printBoxLabels,
  downloadBoxLabels,
  printPalletLabels,
  downloadPalletLabels,
  total,
  dataTableFilters,
  printPackingLabels,
  downloadPackingLabel,
  packingLabelsEnabled,
  downloadPackingList,
  csvPackingListEnabled,
  printOrder,
  groupedOrders,
  setSelectedOrderNotes,
  viewInvoiceLabels,
}) => {
  const intialHeaders = () => {
    const returnHeaders = [];
    returnHeaders.push(
      getHeaderObject("Order ID", "orderId"),
      getHeaderObject("Reference", "reference"),
      getHeaderObject("Client", "customer", false),
      getHeaderObject("Warehouse", "warehouse", false),
      getHeaderObject("Price", "orderValue"),
      getHeaderObject("Order Date", "orderDate"),
      getHeaderObject("Status", "orderStatus"),
      getHeaderObject("Sub-Status", "subStatus"),
      getHeaderObject("Requested Carrier", "shippingRequests", false),
      getHeaderObject("Carrier", "carrier"),
      getHeaderObject("Tracking Number", "trackingNumber"),
      getHeaderObject("Pro Number", "proNumber"),
      getHeaderObject("Source", "source"),
      getHeaderObject("Order Type", "orderType"),
      getHeaderObject("Delivery Method", "deliveryMethod"),
      getHeaderObject("Tote", "tote"),
      getHeaderObject("Sub-Tote", "subTote"),
      getHeaderObject("Multi-Line Order", "multipleLineItems"),
      getHeaderObject("Number of Products", "numberOfProducts"),
      getHeaderObject("Total Quantity", "totalQuantity"),
      getHeaderObject("Action", "action", false),
      getHeaderObject("Notes", "notes", false, null),
    );

    // ONLY FOR DELMAR. UNTIL WE CAN RETRIEVE THESE FIELDS DYNAMICALLY FROM THE BACKEND.
    if (isDelmarTenant(tenant?.subdomain)) {
      returnHeaders.push(getHeaderObject("Classification", "classification"));
      returnHeaders.push(getHeaderObject("Resizing", "resizingOrder"));
    }

    return returnHeaders;
  };

  const [headers, setHeaders] = useState(intialHeaders());

  const renderGroupName = (groupName) => {
    if (groupName === null || groupName === undefined) {
      return "No Value";
    }

    if (filters?.groupBy === "warehouse") {
      return warehouses?.find((item) => item.id === groupName)?.name;
    }

    if (filters?.groupBy === "customer") {
      return customers?.find((item) => item.id === groupName)?.name;
    }
    return groupName;
  };

  const [ORDER_STATUS, SET_ORDER_STATUS] = useState(
    outboundExceptions
      ? [
          { name: "EXCEPTION", id: "EXCEPTION" },
          { name: "INCOMPLETE", id: "INCOMPLETE" },
        ]
      : [
          { name: "UNPROCESSED", id: "UNPROCESSED" },
          { name: "BATCHED", id: "BATCHED" },
          { name: "IN-PROCESS", id: "IN-PROCESS" },
          { name: "INCOMPLETE", id: "INCOMPLETE" },
          { name: "CANCELLED", id: "CANCELLED" },
          { name: "ON_HOLD", id: "ON_HOLD" },
          { name: "PARTIAL_COMPLETED", id: "PARTIAL_COMPLETED" },
          { name: "COMPLETED", id: "COMPLETED" },
          { name: "BATCHED_PARTIALLY", id: "BATCHED_PARTIALLY" },
        ],
  );

  const CUSTOMERS_OBJECT_LIST = [
    ...customers?.map((customer) => {
      return {
        id: customer.id,
        name: customer.name,
      };
    }),
  ];

  const WAREHOUSES_OBJECT_LIST = [
    { label: "All", value: warehouses?.map((item) => item.id) },
    ...warehouses?.map((warehouse) => {
      return {
        value: warehouse.id,
        label: warehouse.name,
      };
    }),
  ];
  const [showHeaders, setShowHeaders] = useState(headers);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      ORDER_MANAGEMENT_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
    }
  }, [auth.user]);

  const menuItems = (order) => {
    const arr = [];

    const isBackOrder = order.childOrders?.length > 0;

    const isSTO = order.orderType === "STO";

    if (writable && isOrderEditable(order) && !isBackOrder) {
      arr.push({
        title: "Edit Order",
        icon: PencilAltIcon,
        onClick: editOrder,
        field: "id",
      });
    }

    if (order.orderStatus === "INCOMPLETE" && !isBackOrder) {
      arr.push({
        title: "Mark IN-PROCESS",
        icon: ClipboardCheckIcon,
        onClick: markInProcess,
      });
    }

    if (outboundExceptions && !isBackOrder) {
      if (writable)
        arr.push({
          title: "Resolve Exception",
          icon: ClipboardCheckIcon,
          onClick: tryResolve,
        });
      arr.push({
        title: "Check Reasons",
        icon: ClipboardCheckIcon,
        onClick: expandException,
        field: "id",
      });
    }

    if (order.source !== "FBA" && !isBackOrder) {
      arr.push({
        title: "Shipping Address",
        icon: EyeIcon,
        onClick: viewShippingAddress,
        field: "id",
      });
    }

    if (order.source === "FBA" && !isBackOrder) {
      arr.push({
        title: "Edit Shipment Plan",
        icon: PencilIcon,
        onClick: viewShipmentPlan,
        field: "id",
      });
      arr.push({
        title: "Print FNSKUs",
        icon: PrinterIcon,
        onClick: printFnSkuLabels,
        field: "id",
      });
      arr.push({
        title: "Download FNSKUs",
        icon: DownloadIcon,
        onClick: downloadFnSkuLabels,
        field: "id",
      });
    }

    if (
      packingLabelsEnabled &&
      order?.subStatus === "PACKING_COMPLETED" &&
      !isBackOrder &&
      !isSTO
    ) {
      arr.push({
        title: "Print Packing Label",
        icon: PrinterIcon,
        onClick: printPackingLabels,
      });
      arr.push({
        title: "Download Packing Label",
        icon: DownloadIcon,
        onClick: downloadPackingLabel,
      });
    }

    if (
      order?.subStatus === "PACKING_COMPLETED" &&
      csvPackingListEnabled &&
      !isBackOrder
    ) {
      arr.push({
        title: "Packing List (CSV)",
        icon: DownloadIcon,
        onClick: downloadPackingList,
      });
    }

    arr.push({
      title: "Audit Trail",
      icon: DocumentSearchIcon,
      onClick: getAudit,
    });

    if (writable && !isBackOrder && !isSTO) {
      if (!outboundExceptions) {
        if (order.orderStatus !== "COMPLETED") {
          arr.push({
            title: "Update Carrier",
            icon: PencilAltIcon,
            onClick: updateCarrier,
            field: "id",
          });
        }
        arr.push({
          title: "Update Shipping Info",
          icon: PencilAltIcon,
          onClick: updateTracking,
          field: "id",
        });
      }
      if (
        order.orderStatus === "COMPLETED" &&
        [
          OrderSource.FBA,
          OrderSource.SHOPIFY,
          OrderSource.SALLA,
          OrderSource.NETSUITE,
          OrderSource.ZID,
          OrderSource.APPARELMAGIC,
        ].includes(order.source)
      ) {
        arr.push({
          title: "Mark Complete on Marketplace",
          icon: PencilIcon,
          onClick: markShippedOnMarketplace,
        });
      }
      if (tenant?.features?.multiwarehouse)
        arr.push({
          title: "Assign Warehouse",
          icon: HomeIcon,
          onClick: handleAssignWarehouse,
        });
    }
    if (!outboundExceptions && !isBackOrder && !isSTO) {
      if (tenant?.typeOfCustomer?.includes("B2B") || bolUploadEnabled) {
        arr.push({
          title: "Download BOL",
          icon: DownloadIcon,
          onClick: downloadBol,
          field: "id",
        });
        arr.push({
          title: "Print BOL",
          icon: PrinterIcon,
          onClick: printBol,
          field: "id",
        });
      }
      if (order?.shippingLabel) {
        arr.push({
          title: "Print Shipping Label",
          icon: PrinterIcon,
          onClick: printShippingLabel,
          field: "id",
        });
        arr.push({
          title: "Download Shipping Label",
          icon: DownloadIcon,
          onClick: downloadShippingLabel,
          field: "id",
        });
      }
      if (order?.invoiceUrls) {
        arr.push({
          title: "View Invoice Labels",
          icon: EyeIcon,
          onClick: viewInvoiceLabels,
          field: "invoiceUrls",
        });
      }
      if (order.source === "FBA" && order?.boxLabels) {
        arr.push(
          {
            title: "Print Box Labels",
            icon: PrinterIcon,
            onClick: printBoxLabels,
            field: "id",
          },
          {
            title: "Download Box Labels",
            icon: DownloadIcon,
            onClick: downloadBoxLabels,
            field: "id",
          },
        );
      }
      if (order.source === "FBA" && order.typeOfShipment === "LTL") {
        arr.push(
          {
            title: "Print Pallet Labels",
            icon: PrinterIcon,
            onClick: printPalletLabels,
            field: "id",
          },
          {
            title: "Download Pallet Labels",
            icon: DownloadIcon,
            onClick: downloadPalletLabels,
            field: "id",
          },
        );
      }
    }

    if (downloadOrderReport && !isBackOrder && !isSTO) {
      arr.push({
        title: "Download Report",
        icon: DownloadIcon,
        onClick: downloadOrderReport,
        vars: "id",
      });
    }

    if (order.source === "Hopstack" && !isBackOrder) {
      arr.push({
        title: "Delete Order",
        icon: TrashIcon,
        onClick: deleteOrder,
      });
    }

    if (isDelmarTenant(tenant?.subdomain) && !isBackOrder) {
      arr.push({
        title: "Print Order",
        icon: PrinterIcon,
        onClick: printOrder,
      });
    }

    // ANY ORDER THAT'S NOT COMPLETED OR CANCELLED CAN BE CANCELLED.
    // WE WILL HAVE BACKEND PROTECTIONS LATER ON FOR FULL STATE DIAGRAM IMPLEMENTATION OF ORDER STATUS CHANGES
    if (
      ["CANCELLED", "COMPLETED"].includes(order.orderStatus) === false &&
      writable &&
      !isBackOrder
    ) {
      arr.push({
        title: "Mark as Cancelled",
        icon: MinusCircleIcon,
        onClick: cancelOrders,
      });
    }

    if (
      ["CANCELLED", "COMPLETED", "EXCEPTION"].includes(order.orderStatus) ===
        false &&
      writable &&
      !isBackOrder
    ) {
      arr.push({
        title: "Mark Complete",
        icon: CheckCircleIcon,
        onClick: markCompleted,
      });
    }

    return arr;
  };
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const allEligibleDataTableFilters = () => {
    const returnArr = [];
    if (warehouses?.length > 1) {
      returnArr.push({
        displayLabel: "Warehouse",
        data: warehouses,
        key: "warehouse",
      });
    }

    if (customers?.length > 1) {
      returnArr.push({
        displayLabel: "Client",
        data: CUSTOMERS_OBJECT_LIST,
        key: "customer",
      });
    }
    returnArr.push(
      {
        displayLabel: "Status",
        data: ORDER_STATUS,
        key: "orderStatus",
      },
      {
        displayLabel: "Sub-Status",
        data: possibleSubStatuses,
        key: "subStatus",
      },
    );

    if (dataTableFilters && dataTableFilters.length > 0) {
      // REASON FOR THE FILTER HERE IS TO ENSURE NULL DATA IS STILL SENT, BASICALLY THAT COVERS DATE FIELDS
      // EMPTY ARRAYS SHOULD NOT BE SENT
      returnArr.push(
        ...dataTableFilters.filter((i) => i.data === null || i.data.length > 0),
      );
    }
    return returnArr;
  };

  return (
    <>
      {expandedException && expandedException.exceptions && (
        <ExceptionReasons
          exceptionReasons={expandedException.exceptions}
          negativeAction={() => setExpandedException(null)}
        />
      )}
      <div className="bg-E2E2E2 px-4 w-full overflow-auto rounded-xl border border-gray-300 pb-20">
        <TableFilters
          onChangeFilter={onChangeFilter}
          filters={filters}
          totalResults={total}
          dataTableFilters={allEligibleDataTableFilters()}
          clearFilters={clearFilters}
          submitFilters={submitFilters}
        />
        {!filters?.groupBy && (
          <SearchSortFilter
            onChangeSearchKeyword={onChangeSearchKeyword}
            filters={filters}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            setSort={setSort}
            sort={sort}
            headers={headers}
            setShowHeaders={setShowHeaders}
            tableName={ORDER_MANAGEMENT_TABLE_NAME}
            setShowBatchSettings={
              writable && batchSettingsEnabled && setShowBatchSettings
            }
            runAutoBatching={
              !selectedOrders ||
              (selectedOrders.length === 0 &&
                !outboundExceptions &&
                runAutoBatching)
            }
            clearSelectedOrdersButton={
              selectedOrders.length > 0 && (
                <AddButton
                  text="Clear"
                  onClick={clearSelectedOrders}
                  styles={["bg-F4C261 text-black text-lg"]}
                />
              )
            }
            manualBatchButton={
              selectedOrders.length > 0 && (
                <AddButton
                  text={
                    outboundExceptions ? "Bulk Resolve" : "Create Manual Batch"
                  }
                  onClick={
                    outboundExceptions
                      ? tryMassResolveExceptions
                      : createManualBatch
                  }
                  styles={["text-lg"]}
                />
              )
            }
          />
        )}

        {!filters?.groupBy &&
          (selectedOrders.length > 0 || showOnlySelected) && (
            <div className="flex space-x-4 items-center px-2">
              <div>
                <Toggle
                  enabled={showOnlySelected}
                  setEnabled={(key) => setShowOnlySelected(key)}
                />
              </div>
              <div className="text-2xl">
                Show only selected orders(
                {selectedOrders.length})
              </div>
            </div>
          )}
        {!filters?.groupBy && (
          <CustomTableWithTheme>
            <thead className="p-4 bg-primaryAccent sticky top-0 left-0 z-0">
              <tr className="text-textWhite font-montserrat border-left">
                <th
                  scope="col"
                  className="px-2 pl-6 py-3 text-left tracking-wider font-medium">
                  <Checkbox
                    role="checkbox"
                    onChange={selectAllRows}
                    name="binLocations"
                    value={"Select All Bin Locations"}
                    checked={allRowsSelected}
                  />
                </th>
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-2 pl-4  py-3 text-left tracking-wider font-medium cursor-pointer"
                      key={headerIdx}
                      onClick={() => {
                        if (!header.sortable) {
                          return;
                        }

                        if (sort === header.correspondingValue) {
                          setSort(`-${header.correspondingValue}`);
                        } else {
                          setSort(header.correspondingValue);
                        }
                      }}>
                      <div className="flex items-center">
                        {header.name}
                        {renderSort(header, sort)}
                      </div>
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {orders.length === 0 ? (
                <tr className="bg-white">
                  {showHeaders.map((_, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                    ) : (
                      <td
                        className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider"
                        key={headerIdx}></td>
                    ),
                  )}
                  <td
                    scope="col"
                    className="px-2 pl-6 py-3 text-left tracking-wider font-medium"></td>
                </tr>
              ) : null}
              {orders.map((order, rowIndex) => (
                <tr
                  key={order.id}
                  className={`${
                    rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-wider rounded-tl rounded-bl border-l-8  ${
                      rowIndex % 2 === 0
                        ? "border-F4C261"
                        : "border-primaryAccent"
                    }`}>
                    <Checkbox
                      role="checkbox"
                      onChange={(_) => {
                        selectOrder(rowIndex, shiftKeyPressed);
                      }}
                      name="order"
                      value={order.id}
                      checked={selectedOrders.includes(order.id)}
                    />
                  </td>
                  {showHeaders.map((header, columnIndex) => {
                    let value = header.correspondingValue;
                    let cellStyle = cellStyleForTable(
                      value,
                      ["orderId"],
                      columnIndex + 1,
                      rowIndex,
                    );
                    if (value === "orderId") {
                      return (
                        <td className={cellStyle}>
                          {order.childOrders?.length > 0 ? (
                            <div className="flex gap-x-2 items-center mt-4">
                              <a onClick={() => expandOrder(order)}>
                                {order.orderId}
                              </a>
                              <BackOrderIcon />
                            </div>
                          ) : (
                            <a onClick={() => expandOrder(order)}>
                              {order.orderId}
                            </a>
                          )}
                          <br />
                          {order.cannotFulfil && (
                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                              Insufficient Inventory
                            </span>
                          )}
                        </td>
                      );
                    }
                    if (value === "customer") {
                      return (
                        <td className={cellStyle}>
                          {customers &&
                            customers.find((item) => item.id === order.customer)
                              ?.name}
                        </td>
                      );
                    }

                    if (value === "warehouse") {
                      return (
                        <td className={cellStyle}>
                          {warehouses &&
                            warehouses.find(
                              (item) => item.id === order.warehouse,
                            )?.name}
                        </td>
                      );
                    }
                    if (value === "orderValue") {
                      return (
                        <td className={cellStyle}>
                          ${_.round(order.orderValue, 2).toFixed(2)}
                        </td>
                      );
                    }
                    if (value === "orderDate") {
                      return (
                        <td className={cellStyle}>
                          {moment(order.orderDate).format("MMM Do YYYY")}
                        </td>
                      );
                    }
                    if (value === "orderStatus") {
                      return <td className={cellStyle}>{order.orderStatus}</td>;
                    }
                    if (value === "subStatus") {
                      return <td className={cellStyle}>{order.subStatus}</td>;
                    }
                    if (value === "source") {
                      return <td className={cellStyle}>{order.source}</td>;
                    }
                    if (value === "orderType") {
                      return (
                        <td className={cellStyle}>
                          {order.orderType === "STO"
                            ? "Stock Transfer"
                            : order.orderType}
                        </td>
                      );
                    }
                    if (value === "carrier") {
                      return <td className={cellStyle}>{order.carrier}</td>;
                    }
                    if (value === "reference") {
                      return <td className={cellStyle}>{order.reference}</td>;
                    }
                    if (value === "classification") {
                      return (
                        <td className={cellStyle}>{order.classification}</td>
                      );
                    }
                    if (value === "multipleLineItems") {
                      return (
                        <td className={cellStyle}>
                          {order.multipleLineItems ? "Yes" : "No"}
                        </td>
                      );
                    }
                    if (value === "numberOfProducts") {
                      return (
                        <td className={cellStyle}>{order.numberOfProducts}</td>
                      );
                    }
                    if (value === "totalQuantity") {
                      return (
                        <td className={cellStyle}>{order.totalQuantity}</td>
                      );
                    }
                    if (value === "resizingOrder") {
                      return (
                        <td className={cellStyle}>
                          {order.customAttributes?.resizingOrder === true
                            ? "Yes"
                            : "No"}
                        </td>
                      );
                    }
                    if (value === "trackingNumber") {
                      return getCarrierTrackingURL(
                        order.trackingNumber,
                        order.carrier,
                        cellStyle,
                      );
                    }
                    if (value === "proNumber") {
                      return <td className={cellStyle}>{order.proNumber}</td>;
                    }
                    if (value === "shippingRequests") {
                      return (
                        <td className={cellStyle}>
                          {order.shippingRequests &&
                            order.shippingRequests.length &&
                            order.shippingRequests[0].title}
                        </td>
                      );
                    }
                    if (value === "deliveryMethod") {
                      return (
                        <td className={cellStyle}>
                          {order.deliveryMethod || "-"}
                        </td>
                      );
                    }
                    if (value === "tote") {
                      return <td className={cellStyle}>{order.tote}</td>;
                    }
                    if (value === "subTote") {
                      return <td className={cellStyle}>{order.subTote}</td>;
                    }
                    if (value === "notes") {
                      return (
                        <td className={cellStyle}>
                          {order?.notes?.length > 0 ? (
                            <EyeIcon
                              className="text-black-600 text-xs cursor-pointer"
                              width={35}
                              onClick={() => setSelectedOrderNotes(order.notes)}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      );
                    }
                    if (value === "action") {
                      return (
                        <td className={cellStyle}>
                          <Menu as="div" className="text-left">
                            <Menu.Button>
                              <DotsVerticalIcon className="w-8 bg-E1D3B8 p-2 text-md text-5F666B font-montserrat rounded h-8" />
                            </Menu.Button>
                            <Menu.Items
                              className={`${
                                columnIndex > showHeaders.length / 2 &&
                                "right-0"
                              } absolute z-10 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                              <div className="max-h-96 overflow-y-scroll">
                                {menuItems(order).map((menuItem, idx) => (
                                  <Menu.Item key={idx}>
                                    {({ active }) => (
                                      <button
                                        className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                                        onClick={() =>
                                          menuItem["onClick"](
                                            order[
                                              menuItem["field"]
                                                ? menuItem["field"]
                                                : "id"
                                            ],
                                          )
                                        }>
                                        {menuItem.icon && (
                                          <menuItem.icon className="w-8 h-8 pr-2" />
                                        )}
                                        {menuItem.title}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Menu>
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </CustomTableWithTheme>
        )}
        {filters?.groupBy && (
          <CustomTableWithTheme>
            <thead className="p-4 bg-primaryAccent sticky top-0 left-0 z-0">
              <tr className="text-textWhite font-montserrat border-left">
                <th
                  scope="col"
                  className="px-2 pl-6 py-3 text-left tracking-wider font-medium">
                  Value
                </th>
                <th
                  scope="col"
                  className="px-2 pl-6 py-3 text-left tracking-wider font-medium">
                  Count
                </th>
              </tr>
            </thead>
            <tbody>
              {!groupedOrders?.length ? (
                <tr className="bg-white">
                  <td>{noValuesText}</td>
                  <td></td>
                </tr>
              ) : (
                groupedOrders.map((group, idx) => (
                  <tr key={idx} className="bg-white">
                    <td className="p-5 text-left font-semibold text-primaryAccent tracking-wider rounded-tl rounded-bl border-l-8 border-F4C261">
                      {renderGroupName(group._id)}
                    </td>
                    <td className="p-5 text-left font-semibold text-primaryAccent tracking-wider">
                      {group.total}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </CustomTableWithTheme>
        )}
      </div>
    </>
  );
};

const renderSort = (header, sort) => {
  if (!header || !header.sortable) {
    return null;
  }

  if (sort && sort.replace("-", "") === header.correspondingValue) {
    if (sort.indexOf("-") !== -1) {
      return <ChevronDownIcon className="w-6 h-6 text-green-400" />;
    }

    return <ChevronUpIcon className="w-6 h-6 text-green-400" />;
  }
  return <ChevronUpIcon className="w-6 h-6 text-gray-400" />;
};

const isOrderEditable = ({ source, orderStatus }) => {
  const orderStatuses = ["UNPROCESSED", "EXCEPTION"];
  const sources = ["Hopstack"];

  return sources.includes(source) && orderStatuses.includes(orderStatus);
};

const BackOrderIcon = () => {
  return (
    <div className="bg-primaryAccent px-1 rounded">
      <span className="font-bold text-sm text-white">B</span>
    </div>
  );
};

export default OrdersList;
