import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

const TariffAccordion = ({
  title,
  subtitle,
  icon,
  isActive = false,
  scrollBehavior = "overflow-y-auto",
  style = {},
  children,
}) => {
  const [active, setActive] = useState(isActive);

  return (
    <div
      className={`p-2 mb-4 border border-[#DDDFE0]  rounded-md w-full duration-500`}>
      <div
        className={`flex justify-between cursor-pointer ${
          active ? "pb-2" : ""
        }`}
        onClick={() => setActive(!active)}>
        <div>
          <div className="flex gap-2">
            <img src={icon} className="w-10 h-10 my-auto mx-1" />
            <div>
              <div className="font-semibold text-lg text-primaryAccent">
                {title}
              </div>
              <div className="text-md text-[#717679]">{subtitle}</div>
            </div>
          </div>
        </div>
        <div className="text-xl cursor-pointer my-auto mr-2">
          {active ? (
            <ChevronUpIcon className="w-6 h-6" />
          ) : (
            <ChevronDownIcon className="w-6 h-6" />
          )}
        </div>
      </div>
      {active && (
        <div
          className={`pt-2 pb-2 ${scrollBehavior}`}
          style={{ minHeight: "100%", maxHeight: "100%", ...style }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default TariffAccordion;
