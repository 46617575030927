import moment from "moment-timezone";
import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  EyeIcon,
  DotsVerticalIcon,
  TrashIcon,
  PencilAltIcon,
  ExclamationIcon,
  DownloadIcon,
  ClipboardCopyIcon,
  ClipboardCheckIcon,
  DocumentSearchIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import SearchSortFilter from "#components/common/SearchSortFilter";
import QuickFilters from "#components/common/QuickFilters";
import { useState } from "react";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth";
import { useEffect } from "react";
import cellStyleForTable from "../common/CellStyleForTable";
import ModalV2 from "../utils/ModalV2";
import MinimalModal from "../utils/MinimalModal";
const noValuesText = "No Consignments";
const CONSIGNMENT_ITEM_TABLE_NAME = "consignments";

const ConsignmentsList = ({
  writable,
  consignments,
  setItems,
  filters,
  onChangeFilter,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  editConsignment,
  editConsignmentNotes,
  editWarehouseNotes,
  deleteConsignment,
  undoConsignmentReceive,
  downloadConsignmentReport,
  receiveConsignment,
  getAudit,
  user,
  changeStatusConsignmentHandle,
  receiveConsignmentPartially,
  downloadConsignmentItemList,
  tenant,
  getDamagedInfo,
  showConsignmentDamageInfo,
  closeConsignmentDamageInfo,
  getConsignmentDetails,
}) => {
  const menuItems = (consignment) => {
    const isSTO = consignment.orderType === "STO";

    const returnArr = [
      {
        title: "View Items",
        icon: EyeIcon,
        onClick: setItems,
        vars: "workingList",
      },
      {
        title: "Audit Trail",
        icon: DocumentSearchIcon,
        onClick: getAudit,
        vars: "id",
      },
      {
        title: "Add Notes",
        icon: PencilAltIcon,
        onClick: editConsignmentNotes,
        vars: "id",
      },
      {
        title: "Download Report",
        icon: DownloadIcon,
        onClick: downloadConsignmentReport,
        vars: "id",
      },
      {
        title: "Download Item List",
        icon: DownloadIcon,
        onClick: downloadConsignmentItemList,
        vars: "id",
      },
    ];

    if (!isSTO) {
      returnArr.push({
        title: "Edit",
        icon: PencilAltIcon,
        onClick: editConsignment,
        vars: "id",
      });
    }

    if (!isSTO) {
      returnArr.push({
        title: "Delete",
        icon: TrashIcon,
        onClick: deleteConsignment,
        vars: "id",
      });
    }

    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "CANCELLED", "CLOSED", "DRAFT"].includes(
        consignment.status,
      ) === false
    ) {
      returnArr.push({
        title: "Mark Received",
        icon: ClipboardCheckIcon,
        onClick: receiveConsignment,
        vars: "id",
      });
    }
    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "CANCELLED", "CLOSED", "DRAFT"].includes(
        consignment.status,
      ) === false &&
      !isSTO
    ) {
      returnArr.push({
        title: "Mark Closed",
        icon: ClipboardCheckIcon,
        onClick: changeStatusConsignmentHandle,
        vars: "id",
      });
    }

    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "CANCELLED", "CLOSED", "DRAFT"].includes(
        consignment.status,
      ) === false
    ) {
      returnArr.push({
        title: "Receive Current",
        icon: ClipboardCheckIcon,
        onClick: receiveConsignmentPartially,
        vars: "id",
      });
    }

    if (
      user?.role?.toLowerCase() === "admin" &&
      ["COMPLETED", "RECEIVING_STARTED"].includes(consignment.status)
    ) {
      returnArr.push({
        title: "Undo Receiving",
        icon: ClipboardCheckIcon,
        onClick: undoConsignmentReceive,
        vars: "id",
      });
    }
    return returnArr;
  };

  const initialHeaders = () => {
    const returnHeaders = [
      getHeaderObject("Action", "action", false),
      getHeaderObject("Order ID", "orderId"),
      getHeaderObject("Status", "status"),
      getHeaderObject("Warehouse Notes", "warehouseNotes", false, null, false),
      getHeaderObject("Client Notes", "clientNotes", false, null, false),
      getHeaderObject("No. of SKUs", "items", false),
      getHeaderObject("Total Qty", "totalQty", false),
      getHeaderObject("Received Qty", "receivedQty", false),
      getHeaderObject("Consignment Date", "consignmentDate"),
      getHeaderObject("Added Date", "createdAt"),
      getHeaderObject("Tracking #", "trackingNumber", false),
      getHeaderObject("Supplier", "supplier"),
      getHeaderObject("Order Type", "orderType"),
    ];
    if (warehouses?.length > 1) {
      returnHeaders.push(getHeaderObject("Warehouse", "warehouse", false));
    }
    if (customers?.length > 1) {
      returnHeaders.push(getHeaderObject("Client", "client", false));
    }
    if (
      tenant?.typeOfCustomer?.includes("Prep Center") &&
      tenant?.features?.dropship === true
    ) {
      returnHeaders.push(getHeaderObject("Dropship", "dropship", false));
      returnHeaders.push(
        getHeaderObject("Packing Method(Dropship)", "isCasePack", false),
      );
      returnHeaders.push(
        getHeaderObject("Dropship Type", "dropshipType", false),
      );
    }

    return returnHeaders;
  };

  const [headers, setHeaders] = useState(initialHeaders());

  const [showHeaders, setShowHeaders] = useState(
    headers.filter((header) => header.enabled),
  );
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  const [showImageModal, setShowImageModal] = useState(null);
  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      CONSIGNMENT_ITEM_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      {showConsignmentDamageInfo && (
        <>
          {showImageModal && (
            <MinimalModal
              noPadding={true}
              onClose={() => setShowImageModal(null)}>
              <img src={showImageModal}></img>
            </MinimalModal>
          )}
          <ModalV2
            onClose={closeConsignmentDamageInfo}
            xIconClicked={closeConsignmentDamageInfo}
            title={`Damage Info`}
            contextText={`Retrieve damaged items, photos, and notes for specific products captured during receiving`}
            noPadding={true}
            scrollWithin={true}>
            <div className="p-2">
              <div className="flex py-2 justify-end font-medium text-black-500">
                Total Damaged Qty:{" "}
                {showConsignmentDamageInfo.reduce(
                  (acc, item) => acc + item.quantity,
                  0,
                )}
              </div>
              <table className="min-w-full">
                <thead className="bg-gray-50 sticky top-0">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Product Info
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Images
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Damaged Qty
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {showConsignmentDamageInfo?.length > 0 &&
                    showConsignmentDamageInfo?.map((item) => (
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-base font-medium text-black-500">
                          {item.sku}
                        </td>
                        <td className="px-6 py-4 whitespace text-sm font-normal">
                          <div
                            className=" text-gray-900"
                            style={{
                              maxWidth: "500px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}>
                            {item.name}
                          </div>
                          {item.asin && (
                            <div>
                              <br />
                              <div className="text-black-500 font-medium">
                                ASIN: {item.asin}
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          <div className="flex space-x-2">
                            {item.workflowImages?.signedImageUrls?.map(
                              (image, index) => (
                                <div className="cursor-pointer">
                                  <img
                                    key={index}
                                    src={image}
                                    className="h-10 w-10 object-cover rounded-md"
                                    onClick={() => {
                                      setShowImageModal(image);
                                    }}
                                  />
                                </div>
                              ),
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="flex space-x-2 justify-center">
                            {item.quantity}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                          {item.remarks}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </ModalV2>
        </>
      )}
      <div className="my-4">
        <QuickFilters
          warehouses={warehouses}
          customers={customers}
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          filters={filters}
          onChangeFilter={onChangeFilter}
          statuses={[
            { name: "DRAFT" },
            { name: "UNPROCESSED" },
            { name: "RECEIVING_STARTED" },
            { name: "COMPLETED" },
            { name: "CLOSED" },
          ]}
        />
      </div>
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={choices}
        headers={headers}
        setShowHeaders={setShowHeaders}
        tableName={CONSIGNMENT_ITEM_TABLE_NAME}
      />
      <CustomTableWithTheme>
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
          <tr className="text-textWhite font-montserrat border-left">
            {showHeaders.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header.name}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header.name}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {consignments.length === 0 ? (
            <tr className="bg-white">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {consignments &&
            consignments.length > 0 &&
            consignments.map((consignment, rowIndex) => (
              <tr
                key={consignment.id}
                className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["orderId"],
                    columnIndex,
                    rowIndex,
                  );
                  if (value == "action") {
                    return (
                      <td className={cellStyle}>
                        {writable && (
                          <Menu as="div" className="inline-block text-right">
                            <div>
                              <Menu.Button>
                                <TableFieldButton
                                  text={
                                    <DotsVerticalIcon className="w-6 h-6" />
                                  }
                                />
                              </Menu.Button>
                            </div>
                            <Menu.Items
                              className={`${
                                columnIndex > showHeaders.length / 2 &&
                                "right-0"
                              } z-50 absolute w-56 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                              <div className="h-96 overflow-y-scroll">
                                {menuItems(consignment).map((menuItem, idx) => (
                                  <Menu.Item key={idx}>
                                    {({ active }) => (
                                      <button
                                        className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                                        onClick={() =>
                                          menuItem["onClick"](
                                            consignment[menuItem["vars"]],
                                          )
                                        }>
                                        {menuItem.icon && (
                                          <menuItem.icon className="w-8 h-8 pr-2" />
                                        )}
                                        {menuItem.title}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Menu>
                        )}
                      </td>
                    );
                  }
                  if (value == "orderId") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() => {
                          consignment.workingList &&
                          consignment.workingList.length > 0
                            ? setItems(
                                consignment.workingList.map((item) => ({
                                  ...item,
                                  consignmentId: consignment.id,
                                })),
                              )
                            : setItems(consignment.workingList);

                          getConsignmentDetails(consignment.id);
                        }}>
                        {consignment.orderId}
                      </td>
                    );
                  }

                  if (value === "warehouseNotes") {
                    return (
                      <td className={cellStyle}>
                        {consignment?.workingList?.findIndex(
                          (item) => !!item.warehouseNotes,
                        ) !== -1 ? (
                          <EyeIcon
                            className="text-red-600 text-xs cursor-pointer"
                            width={35}
                            onClick={() => editWarehouseNotes(consignment.id)}
                            title="There are notes"
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    );
                  }

                  if (value === "clientNotes") {
                    return (
                      <td className={cellStyle}>
                        {consignment.notes ? (
                          <EyeIcon
                            className="text-red-600 text-xs cursor-pointer"
                            width={35}
                            onClick={() => editConsignmentNotes(consignment.id)}
                            title="There are notes"
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    );
                  }

                  if (value == "supplier") {
                    return (
                      <td className={cellStyle}>{consignment.supplier}</td>
                    );
                  }

                  if (value == "dropship") {
                    return (
                      <td className={cellStyle}>
                        {consignment.dropship ? "Yes" : "No"}
                      </td>
                    );
                  }

                  if (value == "isCasePack") {
                    return (
                      <td className={cellStyle}>
                        {consignment.isCasePack ? "Case Pack" : "Regular Pack"}
                      </td>
                    );
                  }

                  if (value == "dropshipType") {
                    return (
                      <td className={cellStyle}>{consignment.dropshipType}</td>
                    );
                  }
                  if (value == "trackingNumber") {
                    return (
                      <td className={cellStyle}>
                        {consignment.trackingNumber &&
                        typeof consignment.trackingNumber === "object" &&
                        consignment.trackingNumber.length > 0
                          ? consignment.trackingNumber.join(",")
                          : consignment.trackingNumber}
                      </td>
                    );
                  }
                  if (value == "items") {
                    return (
                      <td className={cellStyle}>
                        {consignment.items ? consignment.items.length : null}
                      </td>
                    );
                  }
                  if (value == "totalQty") {
                    return (
                      <td className={cellStyle}>
                        {consignment.workingList
                          ? _.sumBy(consignment.workingList, "quantity")
                          : null}
                      </td>
                    );
                  }
                  if (value == "receivedQty") {
                    let receivedQty = consignment?.workingList?.reduce(
                      (acc, item) =>
                        acc + (item.quantity - item.availableQuantity),
                      0,
                    );
                    return (
                      <td className={cellStyle}>
                        <div>{receivedQty}</div>
                        {consignment.hasDamagedItems && (
                          <div
                            class="text-primaryAccent text-sm underline cursor-pointer"
                            onClick={() => {
                              getDamagedInfo(consignment.id);
                            }}>
                            Damage Info
                          </div>
                        )}
                      </td>
                    );
                  }
                  if (value == "consignmentDate") {
                    return (
                      <td className={cellStyle}>
                        {consignment.consignmentDate
                          ? moment(consignment.consignmentDate).format(
                              "MMM Do YYYY",
                            )
                          : null}
                      </td>
                    );
                  }
                  if (value == "createdAt") {
                    return (
                      <td className={cellStyle}>
                        {consignment.createdAt
                          ? moment(consignment.createdAt).format(
                              "MMM Do YYYY, h:mm a",
                            )
                          : null}
                      </td>
                    );
                  }
                  if (value == "status") {
                    return <td className={cellStyle}>{consignment.status}</td>;
                  }
                  if (value === "client") {
                    return (
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {customers &&
                          customers.find(
                            (item) => item.id === consignment.customer,
                          )?.name}
                      </td>
                    );
                  }
                  if (value === "warehouse") {
                    return (
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {warehouses &&
                          warehouses.find(
                            (item) => item.id === consignment.warehouse,
                          )?.name}
                      </td>
                    );
                  }
                  if (value == "orderType") {
                    return (
                      <td className={cellStyle}>
                        {consignment.orderType === "STO"
                          ? "Stock Transfer"
                          : consignment.orderType}
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default ConsignmentsList;
