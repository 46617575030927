import Modal from "#components/utils/Modal";
import _ from "lodash";

const OrderFilters = ({ negativeAction, exceptionReasons }) => {
  const headers = ["Type Of Exception", "Description"];
  return (
    <Modal
      title={"Exception Reasons"}
      negativeAction={negativeAction}
      positiveAction={negativeAction}
      initialFocus={null}>
      <table className="px-2 min-w-full divide-y divide-gray-200">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {exceptionReasons.map((item, index) => (
            <tr
              key={item.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {item.typeOfException}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {item.description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

export default OrderFilters;
