import _ from "lodash";
import CustomSelectDropDown from "./CustomSelectDropDown";
import { useState } from "react";
import Autocomplete from "#components/utils/Autocomplete";

const QuickFilters = ({
  customers,
  warehouses,
  warehouseFilterName,
  customerFilterName,
  onChangeFilter,
  filters,
  statuses,
  isInventoryLoading,
  applyCustomZIndexForTopQuickFilters = false,
  width = "1/2",
}) => {
  const WAREHOUSE_OBJECT_LIST = [
    { name: "All Warehouses", id: null },
    ...(warehouses?.map((warehouse) => {
      return {
        id: warehouse.id,
        name: `${warehouse.name}`,
      };
    }) ?? []).sort((a, b) => a.name.localeCompare(b.name)),
];

  const [customersObjectList, setCustomersObjectList] = useState(
    getFilteredCustomers(filters[warehouseFilterName], customers),
  );

  const STATUS_OBJECT_LIST = [
    { name: "All", id: statuses?.map((item) => item.name) },
    ...(statuses?.map((status) => ({
        id: status.name,
        name: status.name,
    })) ?? []).sort((a, b) => a.name.localeCompare(b.name)),
];


  return (
    <div className={`w-${width} flex gap-4 my-4`}>
      {warehouses && warehouses.length > 1 && (
        <>
          <Autocomplete
            options={WAREHOUSE_OBJECT_LIST}
            labelKey="name"
            disabled={isInventoryLoading}
            valueKey="id"
            onChange={(selectedOption) => {
              const filterValue = determineValue(selectedOption);
              setCustomersObjectList(
                getFilteredCustomers(filterValue, customers),
              );
              if (filterValue.length === 0) {
                onChangeFilter(warehouseFilterName, null, true);
              } else {
                onChangeFilter(warehouseFilterName, filterValue, true);
              }
            }}
            value={getCurrentValueOfFilter(
              filters[warehouseFilterName],
              WAREHOUSE_OBJECT_LIST,
            )}
            placeholder={"All Warehouses"}
            id={`SELECT_WAREHOUSE`}
          />
        </>
      )}
      {customers && customers.length > 1 && (
        <>
          <Autocomplete
            options={customersObjectList}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              const filterValue = determineValue(selectedOption);
              if (filterValue.length === 0) {
                onChangeFilter(customerFilterName, null, true);
              } else {
                onChangeFilter(customerFilterName, filterValue, true);
              }
            }}
            disabled={isInventoryLoading}
            value={getCurrentValueOfFilter(
              filters[customerFilterName],
              customersObjectList,
            )}
            placeholder={"All Clients"}
            id={`SELECT_CUSTOMER`}
          />
        </>
      )}
      {statuses && statuses.length > 0 && (
        <>
          <Autocomplete
            options={STATUS_OBJECT_LIST}
            labelKey="name"
            valueKey="id"
            onChange={(option) => {
              if (typeof option == "string") {
                return onChangeFilter("status", [option], true);
              } else return onChangeFilter("status", [...option], true);
            }}
            value={getCurrentValueOfFilter(
              filters["status"],
              STATUS_OBJECT_LIST,
            )}
            placeholder={"All Statuses"}
            id={`SELECT_STATUS`}
          />
        </>
      )}
    </div>
  );
};

/**
 * filters the customers based on the warehouse selected in the dropdown.
 * @param {Array<string>} selectedWarehouses - The value of filters[warehouseFilterName] which is array of string ids for the option all.
 * @param {Array<Object>} customers - An array of customers object
 * @returns {Array<Object>} An array of objects with customer id as value & name as label.
 */
function getFilteredCustomers(selectedWarehouses, customers) {
  // incase the function being called when customers doesn't exists.
  if (!customers || customers.length < 1) {
    return [];
  }
  let filteredCustomers = null;
  if (selectedWarehouses && selectedWarehouses.length === 1) {
    // option chosen is not All.
    // filtered customers should only be related to selectedWarehouse
    filteredCustomers = customers.filter(
      (customer) =>
        customer.warehouses &&
        customer.warehouses.includes(selectedWarehouses[0]),
    );
  } else {
    // either selectedWarehouse is undefined or null as the property warehouseFilterName is not in filters object
    // or its an array of string where the option All is chosen.
    // return all the customers including option All for CUSTOMERS_OBJECT_LIST
    filteredCustomers = customers;
  }
  return [
    { name: "All", id: filteredCustomers.map((customer) => customer.id) },
    ...filteredCustomers.map((customer) => {
      return {
        name: `${customer.name} - ${customer.code}`,
        id: customer.id,
      };
    }).sort((a, b) => a.name.localeCompare(b.name)),
  ];
}

const determineValue = (selectedOption) => {
  if (!selectedOption) return [];

  if (typeof selectedOption === "string") {
    return [selectedOption];
  }

  return [...selectedOption];
};

export const getCurrentValueOfFilter = (checkValue, checkList) => {
  if (!checkValue) return "";

  if (checkValue.length > 1) return "";
  else return checkList.find((option) => option.id === checkValue[0])?.id;
};

export default QuickFilters;
