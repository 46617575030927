import { useState } from "react";
import Select from "react-select";

const SelectCustomerCode = ({ customers, onSubmit, onSkip }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  return (
    <div className="w-full h-screen -mt-20 flex items-center justify-center">
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg">
          <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
            <Select
              options={
                customers &&
                customers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => ({
                  value: item.id,
                  label: `${item.name} - ${item.code}`,
                }))
              }
              value={selectedCustomer}
              onChange={(e) => setSelectedCustomer(e)}
              className="w-96"
              placeholder="Select Client"
              isClearable={true}
            />
            <div className="flex space-x-2">
              {onSkip && (
                <div
                  className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 bg-red-400`}
                  onClick={onSkip}>
                  Skip
                </div>
              )}

              <div
                className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 ${
                  !!selectedCustomer ? "bg-2C7695" : "bg-gray-400"
                }`}
                onClick={() =>
                  !!selectedCustomer
                    ? onSubmit(selectedCustomer)
                    : console.log()
                }>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCustomerCode;
