import withGroupFormLogic from "#components/HOC/withGroupFormLogic";
import TextField from "#components/utils/TextField";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Autocomplete from "#components/utils/Autocomplete";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Switch from "#components/utils/TabSwitch";

const GroupForm = ({
  masterData,
  selectedGroup,
  editField,
  editFieldCondition,
  addBlankCondition,
  removeCondition,
  saveGroup,
  title,
  onChangeCriteria,
  onChangeMainCriteria,
}) => {
  const history = useHistory();
  const dataFields = (masterField) => {
    switch (masterField) {
      case "shippingAddress.country":
        return masterData.allCountries;
      case "customerName":
        return masterData.allCustomers;
      case "warehouseName":
        return masterData.allWarehouses;
      case "group":
        return masterData.allGroups;
      case "resizingOrder":
        return masterData.allBooleanChoices;
      case "combinedOrder":
        return masterData.allBooleanChoices;
      default:
        return [];
    }
  };

  const renderValueField = (condition, index) => {
    if (
      condition.masterField &&
      [
        "shippingAddress.country",
        "customerName",
        "warehouseName",
        "group",
        "resizingOrder",
        "combinedOrder",
      ].includes(condition.masterField)
    ) {
      if (condition.comparisonOperator === "$eq") {
        return (
          <Autocomplete
            options={dataFields(condition.masterField)}
            labelKey="name"
            valueKey="name"
            onChange={(e) => editFieldCondition(condition.id, "value", e)}
            value={condition.value}
            rounded={true}
            id={`${condition.masterField}_${condition.id}`}
          />
        );
      }
      return (
        <MultiSelectAutoComplete
          options={dataFields(condition.masterField)}
          labelKey="name"
          valueKey="name"
          setValues={(e) => editFieldCondition(condition.id, "value", e)}
          values={condition.value}
          rounded={true}
        />
      );
    }

    return (
      <div className="w-96">
        <TextField
          type="text"
          label="Value"
          placeholder="Value"
          onChange={(e) =>
            editFieldCondition(condition.id, "value", e.target.value)
          }
          value={condition.value}
          rounded={true}
        />
      </div>
    );
  };

  const renderInnerConditions = (outerConditions, idx) => {
    const returnDivs = [];
    outerConditions.conditions.map((condition, index) => {
      let filteredComparisonOperators = masterData.comparisonOperators;
      if (condition.masterField) {
        const fieldMaster = _.find(masterData.masterFields, {
          value: condition.masterField,
        });
        filteredComparisonOperators = _.filter(
          filteredComparisonOperators,
          (e) => {
            return e.scope === fieldMaster.fieldType || e.scope === "ALL";
          },
        );
      }
      returnDivs.push(
        <div>
          <div className="flex justify-between mt-2 bg-white items-center p-2">
            <div className="flex space-x-2 items-center">
              {/* <div className="w-48">{condition.id}</div> */}
              <div className="w-48">
                <RoundedDropdown
                  placeholder={"Field"}
                  list={masterData.masterFields}
                  labelKey="name"
                  valueKey="value"
                  name="field"
                  selectedValue={condition.masterField}
                  setSelected={(e) =>
                    editFieldCondition(condition.id, "masterField", e)
                  }
                />
              </div>
              <div className="w-48">
                <RoundedDropdown
                  placeholder={"Operator"}
                  list={masterData.comparisonOperators}
                  labelKey="name"
                  valueKey="value"
                  name="comparisionOperator"
                  selectedValue={condition.comparisonOperator}
                  setSelected={(e) =>
                    editFieldCondition(condition.id, "comparisonOperator", e)
                  }
                />
              </div>
              <div className="mt-1">{renderValueField(condition, index)}</div>
            </div>
            <div className="flex items-center space-x-2">
              <TableFieldButton
                text={
                  <div className="space-x-1 flex items-center">
                    <PlusCircleIcon className="w-6 h-6" />
                    <span>Add More</span>
                  </div>
                }
                onClick={() => addBlankCondition(condition.id)}
              />
              <TableFieldButton
                text={<TrashIcon className="w-7 h-7" />}
                onClick={() => removeCondition(condition.id)}
              />
            </div>
          </div>
          {index !== outerConditions.conditions.length - 1 && (
            <Switch
              enabled={true}
              tabs={["AND", "OR"]}
              selected={outerConditions.mainCriteria === "$or" ? "OR" : "AND"}
              onChange={(tab) => onChangeCriteria(condition.id, tab)}
            />
          )}
        </div>,
      );
    });
    return (
      <div className="">
        <div className="border-2 border-dashed border-primaryAccent mb-2 p-2 py-3 pt-1 shadow-xl">
          {returnDivs}
        </div>
        {idx !== selectedGroup.conditions.length - 1 && (
          <div className="pb-2">
            <Switch
              enabled={true}
              tabs={["AND", "OR"]}
              selected={selectedGroup.mainCriteria === "$or" ? "OR" : "AND"}
              onChange={(tab) => onChangeMainCriteria(tab)}
            />
          </div>
        )}
      </div>
    );
  };

  const renderConditions = () => {
    const returnDivs = [];
    selectedGroup.conditions.forEach((outerConditions, index) => {
      returnDivs.push(renderInnerConditions(outerConditions, index));
    });
    return returnDivs;
  };
  return (
    <div className="m-4 p-5 bg-white">
      <div className="text-2xl text-primaryAccent font-medium">
        {title ? title : "Create Group"}
      </div>
      <div className="w-96 mt-4">
        <TextField
          type="text"
          id="name"
          label="Group Name"
          placeholder="Group Name"
          onChange={editField}
          value={selectedGroup.name}
        />
      </div>
      <div className="mt-10 text-xl font-medium text-454A4F">
        Define Condition
      </div>
      <div className="mt-6 flex items-center">
        <div className="mr-4">When</div>
        <div className="w-48">
          <RoundedDropdown
            placeholder={"Condition"}
            list={masterData.logicalOperators}
            labelKey="name"
            valueKey="value"
            name="mainCriteria"
            selectedValue={selectedGroup.mainCriteria}
            setSelected={(e) => editField("mainCriteria", e)}
          />
        </div>
        <div className="mx-4"> of the conditions are met</div>
      </div>
      <div className="mt-10 bg-EBEBEB p-2 rounded-md">
        {renderConditions()}
        <div
          className="border-2 border-dashed border-2C7695 p-2 rounded-full mt-2 inline-flex cursor-pointer"
          onClick={() => addBlankCondition()}>
          <PlusCircleIcon className="w-6 h-6 mr-2" />
          Add Condition
        </div>
      </div>
      <div className="mt-10">
        <div className="flex items-center justify-center w-full space-x-4">
          <div
            className="w-32 text-center border-2C7695 border rounded-md text-2C7695 py-2"
            onClick={() => history.replace("/rules")}>
            Cancel
          </div>
          <div
            className="w-32 text-center border-1D3752 bg-1D3752 border rounded-md text-white py-2"
            onClick={saveGroup}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

const TableFieldButton = ({ onClick, text, IconText }) => (
  <button
    type="button"
    className={`rounded-full bg-E1D3B8 inline-flex justify-center px-2 py-2 text-lg font-medium text-5F666B font-montserrat font-medium border-textWhite ring-0 focus:ring-0 outline-none focus:outline-none`}
    onClick={onClick}>
    {IconText ? IconText : text || "Button"}
  </button>
);

export default GroupForm;
