import { useState, useMemo } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import BatchConfirmation from "./BatchConfirmation";
import PreviewFulfillmentDetails from "./PreviewFulfillmentDetails";
import SelectPickerForm from "./SelectPickerForm";

const ManualBatch = ({
  createManualBatchFinal,
  negativeAction,
  manualBatchConfig,
  setManualBatchConfig,
  pickers,
  stations,
  orders,
  selectedOrderIds = [],
  tenant,
  batchSettings,
  ordersFulfillmentDetails,
  isBackOrderEnabledForTenant,
  isManualBatchExecuting,
}) => {
  // If back order is not enabled, show the old form for batching
  if (!tenant.settings.backOrderEnabled) {
    return (
      <SelectPickerForm
        negativeAction={negativeAction}
        setManualBatchConfig={setManualBatchConfig}
        onSubmit={() => createManualBatchFinal(selectedOrderIds)}
        pickers={pickers}
        stations={stations}
        selectedOrders={
          orders?.filter((order) => selectedOrderIds.includes(order.id)) || []
        }
        manualBatchConfig={manualBatchConfig}
        tenant={tenant}
        batchSettings={batchSettings}
        isManualBatchExecuting={isManualBatchExecuting}
      />
    );
  }

  const [loading, setLoading] = useState(false);

  // By default the fulfillable orders will be selected for batching.
  // We give option to user to select the partially fulfillable orders. hence we maintain this state and send it to createManualBatchFinal method
  const [selectedOrderIdsForBatching, setSelectedOrderIdsForBatching] =
    useState([]);

  // Orders that can be completely fulfilled
  const [fulfillableOrders, setFulfillableOrders] = useState([]);
  // Orders that are eligible for back orders
  const [partiallyFulfillableOrders, setPartiallyFulfillableOrders] = useState(
    [],
  );
  /**
   * Orders that might not be eligible for back order due to client, integration level setup,
   * Orders where all items have no inventory,
   * Orders which is having any exception like sku missing.
   * Orders which can only be fulfilled by alternate warehouse.
   */
  const [unfulfillableOrders, setUnfulfillableOrders] = useState([]);
  useMemo(() => {
    if (!ordersFulfillmentDetails) {
      setLoading(true);
    } else {
      setFulfillableOrders(ordersFulfillmentDetails.fulfillableOrders);
      setUnfulfillableOrders(ordersFulfillmentDetails.unfulfillableOrders);
      setPartiallyFulfillableOrders(ordersFulfillmentDetails.backOrders);
      setSelectedOrderIdsForBatching(
        ordersFulfillmentDetails.fulfillableOrders.map(({ order }) => order.id),
      );

      setLoading(false);
    }
  }, [ordersFulfillmentDetails]);

  const [selectedStep, setSelectedStep] = useState("01");

  return (
    <SlideOverModal
      open={true}
      onClose={negativeAction}
      title={"Create Manual Batch"}
      width={"w-1/2"}>
      <div className="flex flex-col w-full h-full select-none">
        <main className="flex-grow">
          {loading ? (
            <div class="border rounded w-full h-full mt-2 flex flex-col justify-center items-center">
              <div className="w-10 h-10 border-t-4 border-hyperlinkColor border-solid rounded-full animate-spin"></div>
            </div>
          ) : (
            <>
              {selectedStep === "01" && (
                <PreviewFulfillmentDetails
                  fulfillableOrders={fulfillableOrders}
                  partiallyFulfillableOrders={partiallyFulfillableOrders}
                  unfulfillableOrders={unfulfillableOrders}
                  selectedOrderIds={selectedOrderIds}
                  selectedOrderIdsForBatching={selectedOrderIdsForBatching}
                  setSelectedOrderIdsForBatching={
                    setSelectedOrderIdsForBatching
                  }
                  isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
                />
              )}
              {selectedStep === "02" && (
                <BatchConfirmation
                  pickers={pickers}
                  manualBatchConfig={manualBatchConfig}
                  setManualBatchConfig={setManualBatchConfig}
                  stations={stations}
                  tenant={tenant}
                  batchSettings={batchSettings}
                  orders={orders}
                  fulfillableOrders={fulfillableOrders}
                  partiallyFulfillableOrders={partiallyFulfillableOrders}
                  selectedOrderIdsForBatching={selectedOrderIdsForBatching}
                />
              )}
            </>
          )}
        </main>
        <footer>
          <FooterActions
            negativeAction={negativeAction}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
            createManualBatchFinal={createManualBatchFinal}
            selectedOrderIdsForBatching={selectedOrderIdsForBatching}
            fulfillableOrders={fulfillableOrders}
            partiallyFulfillableOrders={partiallyFulfillableOrders}
          />
        </footer>
      </div>
    </SlideOverModal>
  );
};

const FooterActions = ({
  negativeAction,
  selectedStep,
  setSelectedStep,
  createManualBatchFinal,
  selectedOrderIdsForBatching,
  fulfillableOrders,
  partiallyFulfillableOrders,
}) => {
  const showBatchConfirmation =
    fulfillableOrders.length > 0 || partiallyFulfillableOrders.length > 0;

  return (
    <div className="h-16 flex justify-end items-center space-x-2 mr-2">
      <button
        onClick={negativeAction}
        className={`rounded-md cursor-pointer ${
          showBatchConfirmation
            ? "bg-red-600 hover:bg-red-700"
            : "bg-primaryAccent"
        } inline-flex items-center text-lg justify-center px-8 py-2 text-md text-white font-montserrat font-medium border textWhite-300 ring-0 focus:ring-0 outline-none focus:outline-none`}>
        {showBatchConfirmation ? "Cancel" : "OK"}
      </button>
      {showBatchConfirmation && (
        <button
          onClick={() => selectedStep === "02" && setSelectedStep("01")}
          className={`rounded-md cursor-pointer  inline-flex items-center text-lg justify-center px-8 py-2 text-md text-white font-montserrat font-medium border textWhite-300 ring-0 focus:ring-0 outline-none focus:outline-none ${
            selectedStep === "01"
              ? "bg-gray-200 text-gray-400 cursor-not-allowed"
              : "bg-FC8862"
          }`}>
          Previous
        </button>
      )}
      {showBatchConfirmation && (
        <button
          onClick={() => {
            if (
              selectedStep === "01" &&
              selectedOrderIdsForBatching.length > 0
            ) {
              setSelectedStep("02");
            } else if (selectedStep === "02") {
              createManualBatchFinal(selectedOrderIdsForBatching);
            }
          }}
          className={`rounded-md cursor-pointer  inline-flex items-center text-lg justify-center px-8 py-2 text-md text-white font-montserrat font-medium border textWhite-300 ring-0 focus:ring-0 outline-none focus:outline-none ${
            selectedStep === "02"
              ? "bg-primaryAccent"
              : selectedOrderIdsForBatching.length > 0
              ? "bg-FC8862"
              : "bg-gray-200 text-gray-400 cursor-not-allowed"
          }`}>
          {selectedStep === "02" ? "Create" : "Next"}
        </button>
      )}
    </div>
  );
};

export default ManualBatch;
