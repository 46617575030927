import cellStyleForTable from "#components/common/CellStyleForTable";
import PageTitle from "#components/utils/PageTitle";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import { getHeaderObject } from "#utils/getHeaderObject";

const ListView = ({ nestedEntityItems, scanBarcode }) => {
  if (!nestedEntityItems) {
    return null;
  }

  const headers = (() => {
    const returnHeaders = [];

    returnHeaders.push(getHeaderObject("ASIN", "asin"));
    returnHeaders.push(getHeaderObject("SKU", "sku"));
    returnHeaders.push(getHeaderObject("Product Name", "productName"));
    returnHeaders.push(getHeaderObject("Available Qty", "availableQuantity"));
    returnHeaders.push(getHeaderObject("Action", "action"));

    return returnHeaders;
  })();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="flex-1 items-center justify-center text-center">
      <PageTitle>Total ({nestedEntityItems?.length})</PageTitle>
      <div className="bg-EBEBEB rounded-md m-auto mt-10">
        <table className="divide-y divide-gray-200 m-auto -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserrat border-left">
              {headers.map((header, headerIdx) => (
                <th
                  scope="col"
                  className="px-2 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {nestedEntityItems.length === 0 ? (
              <tr className="bg-white">
                <td className="text-tableText pl-2 px-1 py-2 text-left font-medium tracking-wider rounded-br rounded-tr">
                  {"No items found"}
                </td>
              </tr>
            ) : null}
            {nestedEntityItems.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                {headers.map((header, colIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    [],
                    colIndex + 1,
                    index,
                    true,
                  );

                  if (value === "asin") {
                    return (
                      <td className={classNames(cellStyle, "select-text")}>
                        {buildMarketplaceHyperlink(
                          item.asin,
                          "ASIN",
                          item.source,
                        )}
                      </td>
                    );
                  }

                  if (value === "sku") {
                    return <td className={cellStyle}>{item.sku}</td>;
                  }

                  if (value === "productName") {
                    return <td className={cellStyle}>{item.productName}</td>;
                  }

                  if (value === "availableQuantity") {
                    return (
                      <td className={cellStyle}>{item.availableQuantity}</td>
                    );
                  }

                  if (value === "action") {
                    return (
                      <td className={cellStyle}>
                        <div
                          className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white"
                          onClick={() =>
                            scanBarcode({
                              data: item.sku,
                              receivingBatchId: item.receivingBatchId,
                            })
                          }>
                          Scan
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListView;
