import { useState } from "react";
import withOrdersLogic from "#components/HOC/withOrdersLogic";
import PageTitle from "#components/utils/PageTitle";
import OrdersList from "#components/orders/OrdersList";
import AuditLog from "#components/orders/AuditLog";
import ExpandedOrder from "#components/orders/expandedOrder/ExpandedOrder";
import Modal from "#components/utils/Modal";
import moment from "moment-timezone";
import _ from "lodash";
import CombinedOrdersForm from "#components/orders/CombinedOrdersForm";
import ManualBatch from "#components/orders/batching/ManualBatch";
import BatchDateRangeForm from "#components/orders/BatchDateRangeForm";
import Pagination from "#components/common/Pagination";
import OrderFilters from "#components/orders/OrderFilters";
import {
  UserAddIcon,
  ExclamationIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import OrderTrackingForm from "#components/orders/OrderTrackingForm";
import OrderCarrierForm from "#components/orders/OrderCarrierForm";
import AddButton from "#components/utils/AddButton";
import ExpandedShippingAddress from "#components/orders/ExpandedShippingAddress";
import BolDoc from "#components/common/BolDoc";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import OrderFormWizard from "#components/orders/OrderFormWizard";
import EditShipmentPlan from "../components/orders/EditShipmentPlan";
import FnSkuLabelOptions from "#components/orders/FnSkuLabelOptions";
import AssignWarehouseForm from "../components/orders/AssignWarehouseForm";
import Checkbox from "#components/utils/Checkbox";
import ImportFbaShipment from "#components/orders/ImportFbaShipment";
import BatchSettings from "#components/orders/BatchSettings";
import Popover from "#components/utils/Popover";
import { OrderSource } from "../utils/enums/orderSourceEnum";

const Orders = ({
  writable,
  masterData,
  onChangeFilter,
  filters,
  loading,
  selectedAudit,
  setSelectedAudit,
  selectedAuditUsersInfo,
  setSelectedAuditUsersInfo,
  showAudit,
  setShowAudit,
  getAudit,
  selectOrder,
  selectedOrders,
  clearSelectedOrders,
  quickCreateManualBatch,
  simulateAutoBatching,
  simulatedBatches,
  clearSimulatedBatches,
  calculateShippingRates,
  setSelectedRates,
  total,
  pageNumber,
  checkPagination,
  perPage,
  displayOrders,
  runCombinedOrders,
  expandOrder,
  expandedOrderInfo,
  setExpandedOrderInfo,
  selectedCombinedOrder,
  setShowCombinedOrders,
  onChangeCombinedDropdown,
  setPerPage,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  sort,
  setSort,
  showFilters,
  setShowFilters,
  clearFilters,
  runAutoBatching,
  runAutoBatches,
  createManualBatch,
  selectedPicker,
  setSelectedPicker,
  createManualBatchFinal,
  pickers,
  stations,
  printShippingLabel,
  downloadShippingLabel,
  markCompleted,
  markPartialCompleted,
  markInProcess,
  markUnprocessed,
  markCancelled,
  batchDateRange,
  setBatchDateRange,
  resetAllData,
  viewShipmentPlan,
  shipmentPlan,
  setShipmentPlan,
  customers,
  warehouses,
  addNewOrder,
  selectedOrder,
  setSelectedOrder,
  selectedOrderLineItem,
  setSelectedOrderLineItem,
  updateOrderLineItem,
  onChangeSelectedOrderLineItem,
  onChange,
  onChangeDropdown,
  handleOrderDataSubmission,
  addItem,
  removeItem,
  onChangeItem,
  availableInventory,
  onChangeInventorySearch,
  deliveryInfo,
  setDeliveryInfo,
  saveDeliveryInfo,
  removeDelivery,
  updateTracking,
  updateCarrier,
  trackingUpdate,
  setTrackingUpdate,
  submitTrackingUpdate,
  carrierUpdate,
  setCarrierUpdate,
  submitCarrierUpdate,
  viewShippingAddress,
  shippingAddress,
  setShippingAddress,
  getMarketplaceProducts,
  printBol,
  downloadBol,
  tenant,
  bolDoc,
  setBolDoc,
  carrierList,
  carrierServiceList,
  uploadFile,
  deleteOrder,
  downloadOrderReport,
  fileIndex,
  setFileIndex,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  uploadOrders,
  cancelOrders,
  selectAllRows,
  allRowsSelected,
  shipstationList,
  validate,
  validationResult,
  eHubList,
  downloadFnSkuLabels,
  printFnSkuLabels,
  fnSkuOptions,
  setFnSkuOptions,
  submitFnSkuOptions,
  handleAssignWarehouse,
  assignWarehouse,
  selectedWarehouse,
  setSelectedWarehouse,
  cannotChangeOrderWarehouse,
  availableWarehousesForChange,
  editOrder,
  nonEditableFieldsOfOrder,
  isOrderBeingCreated,
  isOrderBeingEdited,
  setOrderBeingCreated,
  setOrderBeingEdited,
  setOrderToMarkComplete,
  orderToMarkComplete,
  confirmMarkCompleted,
  markCompleteOnMarketplaceCheck,
  setMarkCompleteOnMarketplaceCheck,
  markShippedOnMarketplace,
  shipmentImportSearch,
  setShipmentImportSearch,
  onImportShipmentSearch,
  importShipment,
  setImportShipment,
  importFbaShipmentModal,
  setImportFbaShipmentModal,
  confirmImportShipment,
  onChangeDropdownImport,
  importFilters,
  setImportFilters,
  orderToMarkCancelled,
  setOrderToMarkCancelled,
  setMarkCancelledOnMarketplaceCheck,
  confirmMarkCancelled,
  possibleSubStatuses,
  showOnlySelected,
  setShowOnlySelected,
  showBatchSettings,
  setShowBatchSettings,
  batchSettingsEnabled,
  batchSettings,
  setBatchSettings,
  saveBatchSettings,
  fetchProductSkuBinMappings,
  productSkuBinMappings,
  lotIdSelectionEnabled,
  orders,
  manualBatchConfig,
  setManualBatchConfig,
  bolUploadEnabled,
  multiAccountSupportEnabled,
  marketplaces,
  sellerIds,
  downloadBoxLabels,
  printBoxLabels,
  downloadPalletLabels,
  printPalletLabels,
  updateCarrierInfoQuery,
  dataTableFilters,
  printPackingLabels,
  downloadPackingLabel,
  packingLabelsEnabled,
  orderChangesPreview,
  setOrderChangesPreview,
  downloadPackingList,
  csvPackingListEnabled,
  productSkuBinMappingsWithLpn,
  lpnSelectionEnabled,
  printOrder,
  isBackOrderEnabledForTenant,
  ordersFulfillmentDetails,
  setOrdersFulfillmentDetails,
  groupedOrders,
  selectedOrderNotes,
  setSelectedOrderNotes,
  isManualBatchExecuting,
  viewInvoiceLabels,
}) => {
  const [orderLineItems, setOrderLineItems] = useState(null);
  const showOrderFormWizard = isOrderBeingCreated || isOrderBeingEdited;

  const actionsOnPopover = [
    {
      name: "Regular",
      value: "create",
      onClick: () => {
        addNewOrder("Regular");
      },
    },
  ];
  if (tenant?.settings?.isSTOEnabled === true) {
    actionsOnPopover.push({
      name: "Stock Transfer",
      value: "create-sto",
      onClick: () => {
        addNewOrder("STO");
      },
    });
  }
  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="w-full mb-20">
          <div className="flex items-center pb-4 flex-wrap">
            <div className="flex-1">
              <PageTitle>Order Management({total} orders)</PageTitle>
            </div>

            <div className="items-center justify-start flex space-x-1 flex-wrap space-y-1">
              {writable && (
                <>
                  <Popover
                    title={"Create Order"}
                    showChevron={true}
                    panelClassName={
                      "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray w-full"
                    }
                    showOverlay={true}>
                    {actionsOnPopover.map((action) => {
                      return (
                        <div
                          className="w-full p-3 rounded-lg font-medium text-unselectedTextGray hover:text-primaryAccent hover:bg-hoverHighlight"
                          onClick={
                            action.onClick
                              ? action.onClick
                              : () => console.log(action.value, action.name)
                          }>
                          {action.name}
                        </div>
                      );
                    })}
                  </Popover>
                  {tenant?.typeOfCustomer?.includes("Prep Center") && (
                    <AddButton
                      text="Import FBA Shipment"
                      onClick={() => setImportFbaShipmentModal(true)}
                      icon={DownloadIcon}
                    />
                  )}
                  <AddButton
                    text="Download Template"
                    onClick={() =>
                      (window.location =
                        "https://templates-onboarding.s3.amazonaws.com/prod/Orders.xlsx")
                    }
                    icon={DownloadIcon}
                  />
                  <UploadBulk
                    dashboardFields={dashboardFields}
                    saveBulkUpload={saveBulkUpload}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    validate={validate}
                    validationResult={validationResult}
                  />
                </>
              )}
            </div>
          </div>

          <OrdersList
            writable={writable}
            customers={customers}
            orders={displayOrders}
            masterData={masterData}
            onChangeFilter={onChangeFilter}
            filters={filters}
            loading={loading}
            getAudit={getAudit}
            selectOrder={selectOrder}
            selectedOrders={selectedOrders}
            clearSelectedOrders={clearSelectedOrders}
            quickCreateManualBatch={quickCreateManualBatch}
            createManualBatch={createManualBatch}
            simulateAutoBatching={simulateAutoBatching}
            calculateShippingRates={calculateShippingRates}
            setSelectedRates={setSelectedRates}
            runCombinedOrders={() => setShowCombinedOrders(true)}
            expandOrder={expandOrder}
            onChangeSearchKeyword={onChangeSearchKeyword}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            sort={sort}
            setSort={setSort}
            setShowFilters={setShowFilters}
            clearFilters={clearFilters}
            runAutoBatching={runAutoBatching}
            printShippingLabel={printShippingLabel}
            downloadShippingLabel={downloadShippingLabel}
            viewInvoiceLabels={viewInvoiceLabels}
            markInProcess={markInProcess}
            markUnprocessed={markUnprocessed}
            markCompleted={markCompleted}
            markCancelled={markCancelled}
            markPartialCompleted={markPartialCompleted}
            resetAllData={resetAllData}
            viewShipmentPlan={viewShipmentPlan}
            updateTracking={updateTracking}
            updateCarrier={updateCarrier}
            viewShippingAddress={viewShippingAddress}
            printBol={printBol}
            downloadBol={downloadBol}
            tenant={tenant}
            deleteOrder={deleteOrder}
            downloadOrderReport={downloadOrderReport}
            cancelOrders={cancelOrders}
            selectAllRows={selectAllRows}
            allRowsSelected={allRowsSelected}
            downloadFnSkuLabels={downloadFnSkuLabels}
            printFnSkuLabels={printFnSkuLabels}
            handleAssignWarehouse={handleAssignWarehouse}
            editOrder={editOrder}
            markShippedOnMarketplace={markShippedOnMarketplace}
            possibleSubStatuses={possibleSubStatuses}
            showOnlySelected={showOnlySelected}
            setShowOnlySelected={setShowOnlySelected}
            showBatchSettings={showBatchSettings}
            setShowBatchSettings={setShowBatchSettings}
            batchSettingsEnabled={batchSettingsEnabled}
            warehouses={warehouses}
            bolUploadEnabled={bolUploadEnabled}
            downloadBoxLabels={downloadBoxLabels}
            printBoxLabels={printBoxLabels}
            downloadPalletLabels={downloadPalletLabels}
            printPalletLabels={printPalletLabels}
            total={total}
            dataTableFilters={dataTableFilters}
            printPackingLabels={printPackingLabels}
            downloadPackingLabel={downloadPackingLabel}
            packingLabelsEnabled={packingLabelsEnabled}
            csvPackingListEnabled={csvPackingListEnabled}
            downloadPackingList={downloadPackingList}
            printOrder={printOrder}
            groupedOrders={groupedOrders}
            selectedOrderNotes={selectedOrderNotes}
            setSelectedOrderNotes={setSelectedOrderNotes}
          />

          {selectedWarehouse && (
            <AssignWarehouseForm
              onClose={() => setSelectedWarehouse(null)}
              title={"Assign Warehouse"}
              onSubmit={assignWarehouse}
              warehouses={availableWarehousesForChange}
              setSelectedWarehouse={setSelectedWarehouse}
              selectedWarehouse={selectedWarehouse}
              cannotChangeOrderWarehouse={cannotChangeOrderWarehouse}
            />
          )}
        </div>
      </div>

      {!showOnlySelected && (
        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
        />
      )}

      {showFilters && (
        <OrderFilters
          negativeAction={() => setShowFilters(false)}
          masterData={masterData}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          warehouses={warehouses}
          clearFilters={clearFilters}
          possibleSubStatuses={possibleSubStatuses}
          tenant={tenant}
          submitFilters={submitFilters}
        />
      )}

      {importFbaShipmentModal && (
        <ImportFbaShipment
          shipmentPlan={importShipment}
          negativeAction={() => {
            setImportFbaShipmentModal(false);
            setImportShipment(null);
            setImportFilters({});
            setShipmentImportSearch(null);
          }}
          onSubmit={confirmImportShipment}
          onImportShipmentSearch={onImportShipmentSearch}
          shipmentImportSearch={shipmentImportSearch}
          setShipmentImportSearch={setShipmentImportSearch}
          warehouses={warehouses}
          customers={customers}
          onChangeDropdown={onChangeDropdownImport}
          importFilters={importFilters}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          marketplaces={marketplaces}
          sellerIds={sellerIds}
        />
      )}

      {showBatchSettings && (
        <BatchSettings
          showBatchSettings={showBatchSettings}
          setShowBatchSettings={setShowBatchSettings}
          batchSettings={batchSettings}
          setBatchSettings={setBatchSettings}
          saveBatchSettings={saveBatchSettings}
        />
      )}

      {showOrderFormWizard && (
        <OrderFormWizard
          selectedOrder={selectedOrder}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title="Add Order"
          onClose={() => {
            setSelectedOrder(null);
            setOrderBeingCreated(false);
            setOrderBeingEdited(false);
          }}
          onSubmit={handleOrderDataSubmission}
          customers={customers}
          warehouses={warehouses}
          addItem={addItem}
          removeItem={removeItem}
          onChangeItem={onChangeItem}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          deliveryInfo={deliveryInfo}
          setDeliveryInfo={setDeliveryInfo}
          saveDeliveryInfo={saveDeliveryInfo}
          removeDelivery={removeDelivery}
          getMarketplaceProducts={getMarketplaceProducts}
          carrierList={carrierList}
          carrierServiceList={carrierServiceList}
          tenant={tenant}
          shipstationList={shipstationList}
          eHubList={eHubList}
          setSelectedOrder={setSelectedOrder}
          nonEditableFieldsOfOrder={
            isOrderBeingEdited ? nonEditableFieldsOfOrder : []
          }
          selectOrder={selectOrder}
          isOrderBeingEdited={isOrderBeingEdited}
          fetchProductSkuBinMappings={fetchProductSkuBinMappings}
          productSkuBinMappings={productSkuBinMappings}
          lotIdSelectionEnabled={lotIdSelectionEnabled}
          lpnSelectionEnabled={lpnSelectionEnabled}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          productSkuBinMappingsWithLpn={productSkuBinMappingsWithLpn}
        />
      )}

      {trackingUpdate && (
        <OrderTrackingForm
          trackingUpdate={trackingUpdate}
          setTrackingUpdate={setTrackingUpdate}
          title="Update Shipping Information"
          onClose={() => setTrackingUpdate(null)}
          onSubmit={submitTrackingUpdate}
          uploadFile={uploadFile}
          bolUploadEnabled={bolUploadEnabled}
        />
      )}

      {orderToMarkComplete && (
        <CompleteOrder
          expandedOrderInfo={expandedOrderInfo}
          setExpandedOrderInfo={setExpandedOrderInfo}
          setOrderToMarkComplete={setOrderToMarkComplete}
          setMarkCompleteOnMarketplaceCheck={setMarkCompleteOnMarketplaceCheck}
          confirmMarkCompleted={confirmMarkCompleted}
          orderChangesPreview={orderChangesPreview}
          markCompleteOnMarketplaceCheck={markCompleteOnMarketplaceCheck}
        />
      )}

      {orderToMarkCancelled && (
        <CancelOrder
          expandedOrderInfo={expandedOrderInfo}
          setExpandedOrderInfo={setExpandedOrderInfo}
          setOrderToMarkCancelled={setOrderToMarkCancelled}
          setMarkCancelledOnMarketplaceCheck={
            setMarkCancelledOnMarketplaceCheck
          }
          confirmMarkCancelled={confirmMarkCancelled}
          orderChangesPreview={orderChangesPreview}
        />
      )}

      {carrierUpdate &&
        (tenant?.features?.rateShopping === true ? (
          <OrderFormWizard
            selectedOrder={selectedOrder}
            onChange={onChange}
            onChangeDropdown={onChangeDropdown}
            title="Add Order"
            onClose={() => {
              setSelectedOrder(null);
              setOrderBeingCreated(false);
              setOrderBeingEdited(false);
              setCarrierUpdate(null);
            }}
            onSubmit={() => {
              updateCarrierInfoQuery.fetchData({
                orderInfo: {
                  ...carrierUpdate,
                  ...(selectedOrder?.selectedCarrierRateData || {}),
                  shippingAddress: selectedOrder?.shippingAddress,
                  toValidAddress: selectedOrder?.toValidAddress,
                  insuranceRequired: selectedOrder?.insuranceRequired,
                  estimatedBoxes: selectedOrder?.estimatedBoxes,
                },
              });
            }}
            customers={customers}
            warehouses={warehouses}
            addItem={addItem}
            removeItem={removeItem}
            onChangeItem={onChangeItem}
            availableInventory={availableInventory}
            onChangeInventorySearch={onChangeInventorySearch}
            deliveryInfo={deliveryInfo}
            setDeliveryInfo={setDeliveryInfo}
            saveDeliveryInfo={saveDeliveryInfo}
            removeDelivery={removeDelivery}
            getMarketplaceProducts={getMarketplaceProducts}
            carrierList={carrierList}
            carrierServiceList={carrierServiceList}
            tenant={tenant}
            shipstationList={shipstationList}
            eHubList={eHubList}
            setSelectedOrder={setSelectedOrder}
            nonEditableFieldsOfOrder={
              isOrderBeingEdited ? nonEditableFieldsOfOrder : []
            }
            selectOrder={selectOrder}
            isOrderBeingEdited={!!carrierUpdate}
            fetchProductSkuBinMappings={fetchProductSkuBinMappings}
            productSkuBinMappings={productSkuBinMappings}
            lotIdSelectionEnabled={lotIdSelectionEnabled}
            displayedTabs={["Specify Address", "Box Dimensions", "Tracking"]}
            showTrackingNumberInput={false}
          />
        ) : (
          <OrderCarrierForm
            carrierUpdate={carrierUpdate}
            setCarrierUpdate={setCarrierUpdate}
            title="Update Carrier Information"
            onClose={() => setCarrierUpdate(null)}
            onSubmit={submitCarrierUpdate}
            carrierList={carrierList}
            carrierServiceList={carrierServiceList}
            tenant={tenant}
            shipstationList={shipstationList}
            eHubList={eHubList}
          />
        ))}

      {batchDateRange && (
        <BatchDateRangeForm
          negativeAction={() => setBatchDateRange(null)}
          onSubmit={runAutoBatches}
          batchDateRange={batchDateRange}
          onChange={(field, value) =>
            setBatchDateRange({
              ...batchDateRange,
              [field]: value,
            })
          }
        />
      )}

      {manualBatchConfig && (
        <ManualBatch
          createManualBatchFinal={createManualBatchFinal}
          negativeAction={() => {
            setManualBatchConfig(null);
            setOrdersFulfillmentDetails(null);
          }}
          manualBatchConfig={manualBatchConfig}
          setManualBatchConfig={setManualBatchConfig}
          pickers={pickers}
          stations={stations}
          orders={orders}
          selectedOrderIds={selectedOrders}
          tenant={tenant}
          batchSettings={batchSettings}
          ordersFulfillmentDetails={ordersFulfillmentDetails}
          isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
          isManualBatchExecuting={isManualBatchExecuting}
        />
      )}

      {selectedCombinedOrder && (
        <CombinedOrdersForm
          negativeAction={() => setShowCombinedOrders(null)}
          selectedCombinedOrder={selectedCombinedOrder}
          onChangeDropdown={onChangeCombinedDropdown}
          onSubmit={runCombinedOrders}
        />
      )}

      {expandedOrderInfo && !orderToMarkComplete && !orderToMarkCancelled && (
        <ExpandedOrder
          writable={writable}
          expandedOrderInfo={expandedOrderInfo}
          negativeAction={() => setExpandedOrderInfo(null)}
          selectedOrderLineItem={selectedOrderLineItem}
          setSelectedOrderLineItem={setSelectedOrderLineItem}
          updateOrderLineItem={updateOrderLineItem}
          onChangeSelectedOrderLineItem={onChangeSelectedOrderLineItem}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          customers={customers}
          warehouses={warehouses}
          tenant={tenant}
          packingLabelsEnabled={packingLabelsEnabled}
          printFnSkuLabels={printFnSkuLabels}
          downloadFnSkuLabels={downloadFnSkuLabels}
          printPackingLabels={printPackingLabels}
          downloadPackingLabel={downloadPackingLabel}
          csvPackingListEnabled={csvPackingListEnabled}
          downloadPackingList={downloadPackingList}
          getAudit={getAudit}
          downloadOrderReport={downloadOrderReport}
          printPalletLabels={printPalletLabels}
          downloadPalletLabels={downloadPalletLabels}
          printBoxLabels={printBoxLabels}
          bolUploadEnabled={bolUploadEnabled}
          downloadBol={downloadBol}
          printBol={printBol}
          downloadBoxLabels={downloadBoxLabels}
          printShippingLabel={printShippingLabel}
          downloadShippingLabel={downloadShippingLabel}
          printOrder={printOrder}
          cancelOrders={cancelOrders}
          markCompleted={markCompleted}
        />
      )}

      {shipmentPlan && (
        <EditShipmentPlan
          toEditShipmentPlan={shipmentPlan}
          changeShipmentPlan={setShipmentPlan}
          negativeAction={() => setShipmentPlan(null)}
        />
      )}

      {shippingAddress && (
        <ExpandedShippingAddress
          shippingAddress={shippingAddress}
          negativeAction={() => setShippingAddress(null)}
        />
      )}

      {showAudit && (
        <AuditLog
          selectedAudit={selectedAudit}
          selectedAuditUsersInfo={selectedAuditUsersInfo}
          negativeAction={() => {
            setShowAudit(false);
            setSelectedAudit(null);
            setSelectedAuditUsersInfo(null);
          }}
        />
      )}

      {simulatedBatches.length > 0 && (
        <Modal
          negativeAction={clearSimulatedBatches}
          title={`Simulated Batches (${simulatedBatches.length})`}>
          <table className="min-w-full divide-y divide-gray-200 whitespace-nowrap">
            <thead className="p-4 rounded-full pr-5">
              <tr className="text-white font-montserratborder-left bg-primaryAccent">
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                  Date
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                  # Items
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                  Row #s
                </th>
                <th
                  scope="col"
                  className="px-3 pl-4 py-3 text-left tracking-wider font-medium">
                  Items
                </th>
              </tr>
            </thead>
            <tbody>
              {simulatedBatches.map((batch, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {moment(batch.createdAt).format("MMM Do YYYY, h:mm a")}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {batch.items.length}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {batch.rows?.join(",")}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-bold text-5F666B tracking-wider">
                    <div
                      onClick={() => setOrderLineItems(batch.items)}
                      className="cursor-pointer">
                      View
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {orderLineItems && orderLineItems.length > 0 && (
            <Modal
              negativeAction={() => setOrderLineItems(null)}
              title={`Order Line Items(${orderLineItems.length})`}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="p-4 rounded-full">
                  <tr className="text-white font-montserratborder-left bg-primaryAccent">
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                      Collection
                    </th>
                    <th
                      scope="col"
                      className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                      Color
                    </th>
                    <th
                      scope="col"
                      className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                      Size
                    </th>
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderLineItems.map((orderLineItem, index) => (
                    <tr
                      key={orderLineItem._id}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}>
                      <td
                        className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                          index % 2 === 0
                            ? "border-F4C261"
                            : "border-primaryAccent"
                        }`}>
                        {orderLineItem.sku}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {orderLineItem.attributes?.collection}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {orderLineItem.attributes?.color}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {orderLineItem.attributes?.size}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {orderLineItem.quantity}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        ${_.round(orderLineItem.price, 2).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal>
          )}
        </Modal>
      )}

      {selectedOrderNotes && (
        <Modal
          title={`Order Notes`}
          negativeAction={() => setSelectedOrderNotes(null)}>
          <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
            <thead className="p-4 rounded-full bg-primaryAccent px-12">
              <tr className="text-textWhite font-montserrat border-left px-12">
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                  Notes
                </th>
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedOrderNotes.map((item, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {`#${index + 1}`}
                  </td>

                  <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                    {item.split(", ").map((i) => (
                      <>
                        <div key={i}>{i}</div>
                      </>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}

      {bolDoc && (
        <div className="fixed">
          <BolDoc
            id={bolDoc.orderId}
            date={moment().format("MM/DD/YYYY")}
            pickupDate={moment(
              bolDoc.dateOfShipping
                ? bolDoc.dateOfShipping
                : bolDoc.completedDate,
            ).format("MM/DD/YYYY")}
            deliveryDate={moment(
              bolDoc.deliveryDate ? bolDoc.deliveryDate : bolDoc.completedDate,
            ).format("MM/DD/YYYY")}
            orderNo={bolDoc.orderId}
            weight={_.sumBy(bolDoc.orderLineItems, "attributes.weight")}
            orderLineItems={bolDoc.orderLineItems}
            shippingAddress={bolDoc.shippingAddress}
            tenant={tenant}
            order={bolDoc}
            thirdPartyFreightChargesBill={
              bolDoc.thirdPartyFreightChargesBill || ""
            }
          />
        </div>
      )}

      {fnSkuOptions && (
        <FnSkuLabelOptions
          fnSkuLabelOptions={fnSkuOptions}
          setFnSkuLabelOptions={setFnSkuOptions}
          positiveAction={submitFnSkuOptions}
        />
      )}
    </div>
  );
};

const CompleteOrder = ({
  expandedOrderInfo,
  setExpandedOrderInfo,
  setOrderToMarkComplete,
  setMarkCompleteOnMarketplaceCheck,
  confirmMarkCompleted,
  orderChangesPreview,
  markCompleteOnMarketplaceCheck,
}) => {
  const order = expandedOrderInfo?.order;

  return (
    <Modal
      title={`Order ID : ${order?.orderId} `}
      onClose={() => {
        setOrderToMarkComplete(null);
        setMarkCompleteOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}
      positiveText={"Yes, I confirm"}
      negativeText={"No, Cancel"}
      positiveAction={confirmMarkCompleted}
      negativeAction={() => {
        setOrderToMarkComplete(null);
        setMarkCompleteOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}>
      <div className="flex items-center flex-col justify-center">
        <ExclamationIcon
          className="h-20 w-20 text-white bg-primaryAccent font-light rounded-full p-4"
          aria-hidden="true"
        />
        <h1 className="text-454A4F font-montserrat mt-4 text-2xl text-center">
          Are you sure you want to mark this order as COMPLETED?
        </h1>
        {ModalTable(orderChangesPreview)}
        <h1 className="text-454A4F font-montserrat mt-4 text-xl text-center">
          Note: If you manually complete the order, all allocated product
          quantities will be deducted.
        </h1>
        {[
          OrderSource.FBA,
          OrderSource.SHOPIFY,
          OrderSource.SALLA,
          OrderSource.NETSUITE,
          OrderSource.ZID,
          OrderSource.APPARELMAGIC,
        ].includes(order?.source) && (
          <div className="flex flex-row justify-center mt-2">
            <Checkbox
              name="markShip"
              onChange={() =>
                setMarkCompleteOnMarketplaceCheck(
                  !markCompleteOnMarketplaceCheck,
                )
              }
              checked={markCompleteOnMarketplaceCheck}
            />
            <h1 className="text-454A4F font-montserrat text-xl text-center pl-4">
              Mark as Shipped on {order?.source}
            </h1>
          </div>
        )}
      </div>
    </Modal>
  );
};

const CancelOrder = ({
  expandedOrderInfo,
  setExpandedOrderInfo,
  setOrderToMarkCancelled,
  setMarkCancelledOnMarketplaceCheck,
  confirmMarkCancelled,
  orderChangesPreview,
}) => {
  const order = expandedOrderInfo?.order;

  return (
    <Modal
      title={`Order ID : ${order?.orderId} `}
      onClose={() => {
        setOrderToMarkCancelled(null);
        setMarkCancelledOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}
      positiveText={"Yes, I confirm"}
      negativeText={"No, Cancel"}
      positiveAction={confirmMarkCancelled}
      negativeAction={() => {
        setOrderToMarkCancelled(null);
        setMarkCancelledOnMarketplaceCheck(false);
        setExpandedOrderInfo(null);
      }}>
      <div className="flex items-center flex-col justify-center">
        <ExclamationIcon
          className="h-20 w-20 text-white bg-primaryAccent font-light rounded-full p-4"
          aria-hidden="true"
        />
        <h1 className="text-454A4F font-montserrat mt-4 text-2xl text-center">
          Are you sure you want to mark this order as Cancelled?
        </h1>
        {ModalTable(orderChangesPreview)}
        {["UNPROCESSED", "EXCEPTION"].includes(order?.orderStatus) ===
          false && (
          //This condition is in-place because unprocessed or exception orders
          //won't have stock allocated so there's no need to show the message..
          <h1 className="text-454A4F font-montserrat mt-4 text-xl text-center">
            All allocated stock for this order will be transferred back to
            inventory to the original storage locations from which they were
            allocated. Please ensure it is putaway into the respective storage
            locations.
          </h1>
        )}
        {["FBA"].includes(order?.source) && (
          <div className="flex flex-row justify-center mt-2">
            <h1 className="text-454A4F font-montserrat text-xl text-center pl-4">
              NOTE: Any marketplace shipment associated with this order will
              also be cancelled
            </h1>
          </div>
        )}
      </div>
    </Modal>
  );
};

const ModalTable = (orderChangesPreview) => {
  return (
    <div className="mt-4 overflow-x-auto">
      {orderChangesPreview?.batches?.length > 0 && (
        <div>
          <h2 className="text-454A4F font-montserrat mt-2 text-xl">
            Batches Affected
          </h2>
          <table className="w-full border-collapse mt-2">
            <thead>
              <tr className="bg-454A4F text-white">
                <th className="px-4 py-2">Batch ID</th>
                <th className="px-4 py-2">Type</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">User Name</th>
              </tr>
            </thead>
            <tbody>
              {orderChangesPreview?.batches.map((batch) => (
                <tr key={batch.id} className="hover:bg-gray-200">
                  <td className="border px-4 py-2">{batch.id}</td>
                  <td className="border px-4 py-2">{batch.type}</td>
                  <td className="border px-4 py-2">{batch.status}</td>
                  <td className="border px-4 py-2">{batch.userName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {orderChangesPreview?.skuAudits?.length > 0 && (
        <div>
          <h2 className="text-454A4F font-montserrat mt-4 text-xl">
            Inventory Affected
          </h2>
          <table className="w-full border-collapse mt-2">
            <thead>
              <tr className="bg-454A4F text-white">
                <th className="px-4 py-2">SKU</th>
                <th className="px-4 py-2">Amount</th>
                <th className="px-4 py-2">Bin Location</th>
              </tr>
            </thead>
            <tbody>
              {orderChangesPreview?.skuAudits.map((audit) => (
                <tr key={audit.id} className="hover:bg-gray-200">
                  <td className="border px-4 py-2">
                    {audit.sku || "No SKU ID found"}
                  </td>
                  <td className="border px-4 py-2">{Math.abs(audit.amount)}</td>
                  <td className="border px-4 py-2">{audit.binLocation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default withOrdersLogic(Orders);
