import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import moment from "moment-timezone";
import _ from "lodash";
import PaginationNavigationButtons from "#components/common/PaginationNavigationButtons";
import Modal from "#components/utils/Modal";

const headers = ["Order ID", "Type", "Order Date", "Action"];

const ListView = ({
  orders,
  checkOrderById,
  filters = {},
  setFilters,
  submitFilters,
  ordersData,
  checkPagination,
}) => {
  if (!orders) {
    return (
      <div className="flex-1 max-w-4xl">
        <div className="flex justify-between">
          <PageTitle>Total (0) </PageTitle>
          <PaginationNavigationButtons
            perPage={ordersData.perPage}
            pageNumber={ordersData.pageNumber}
            total={ordersData.total}
            nextPage={() => checkPagination("forward")}
            prevPage={() => checkPagination("backward")}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 max-w-4xl">
      <div className="flex justify-between">
        <PageTitle>Total ({ordersData.total}) </PageTitle>
        <PaginationNavigationButtons
          perPage={ordersData.perPage}
          pageNumber={ordersData.pageNumber}
          total={ordersData.total}
          nextPage={() => checkPagination("forward")}
          prevPage={() => checkPagination("backward")}
        />
      </div>
      <div className="relative">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block p-4 pl-10 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search"
          onChange={(e) =>
            setFilters({
              ...filters,
              keyword: e.target.value,
            })
          }
          value={filters["keyword"]}
          onKeyDown={(e) => (e.key === "Enter" ? submitFilters() : {})}
        />
      </div>
      <div className="bg-EBEBEB mt-10 rounded-md">
        <table className="min-w-full divide-y divide-gray-200 -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {orders.length === 0 && (
              <tr>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
                  No Orders.
                </td>
              </tr>
            )}
            {orders &&
              orders.map((item, index) => (
                <tr
                  key={item.id}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } cursor-pointer`}>
                  <td
                    className={`cursor-pointer p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {item.orderId}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.source}
                    {item.dropship && item.source !== "Dropship"
                      ? " - Dropship"
                      : ""}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.orderDate &&
                      moment(item.orderDate).format("YYYY-MM-DD hh:mm A")}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    <div
                      className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white"
                      onClick={() => checkOrderById(item.orderId)}>
                      Work on this
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListView;
