import TextField from "#components/utils/TextField";
import { useState } from "react";

const ScanOrEnterManually = ({
  text,
  onSubmit,
  onSkip,
  onCancel,
  lpnEnabled,
  setAddLpnToProduct,
  setGenerateLpn,
}) => {
  const [manualValue, setManualValue] = useState(null);
  return (
    <div className="w-full flex items-center justify-center">
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg">
          <div className="flex items-center justify-center pt-5 pb-5">
            <div className="border-r border-gray-400 p-5">
              <div className="rounded-full p-6 bg-primaryAccent shadow-md">
                {" "}
                <img
                  src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                  className="w-14"
                />
              </div>
            </div>
            <div className="text-xl font-medium text-454A4F py-5 px-5">
              {text || "Scan a Tracking Number"}
            </div>
          </div>
          <div className="text-2xl font-montserrat items-center text-center">
            OR ENTER MANUALLY
          </div>
          <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
            <div>
              <TextField
                type="text"
                id="name"
                label="Enter Value"
                placeholder=" "
                onChange={(e) => setManualValue(e.target.value)}
                value={manualValue}
                onKeyDown={(e) =>
                  e.key === "Enter" ? onSubmit({ data: manualValue }) : {}
                }
                autoFocus={true}
              />
            </div>
            <div className="flex space-x-2">
              {onCancel && (
                <div
                  className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 bg-red-600 cursor-pointer`}
                  onClick={onCancel}>
                  Cancel
                </div>
              )}

              {onSkip && (
                <div
                  className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 bg-gray-400 cursor-pointer`}
                  onClick={onSkip}>
                  Skip
                </div>
              )}

              <div
                className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 ${
                  !!manualValue && manualValue.trim() !== ""
                    ? "bg-2C7695 cursor-pointer"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
                onClick={() =>
                  !!manualValue && manualValue.trim() !== ""
                    ? onSubmit({ data: manualValue })
                    : console.log()
                }>
                Submit
              </div>
            </div>
          </div>
          {lpnEnabled && (
            <div className="text-center items-center justify-center">
              <div className="text-2xl font-montserrat items-center text-center">
                OR
              </div>
              <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
                <div className="flex space-x-2">
                  <div
                    className={`text-xl text-white mt-4 rounded-md text-center p-2 bg-2C7695`}
                    onClick={() => setAddLpnToProduct({})}>
                    Add existing LPN/Code
                  </div>

                  <div
                    className="bg-2C7695 text-xl text-white mt-4 rounded-md text-center p-2"
                    onClick={setGenerateLpn}>
                    Generate LPN
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanOrEnterManually;
