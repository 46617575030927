import TextField from "#components/utils/TextField";
import { useEffect, useState } from "react";
import Wizard from "../utils/Wizard";

const EditShipmentPlan = ({
  toEditShipmentPlan,
  negativeAction,
  changeShipmentPlan,
}) => {
  const [shipmentPlan, setShipmentPlan] = useState(toEditShipmentPlan);
  const [getMaxQuantityFor, setGetMaxQuantityFor] = useState({});
  const [pickupAddress, setPickupAddress] = useState(
    toEditShipmentPlan.ShipFromAddress,
  );

  useEffect(() => {
    const maxQuantitiesForItems = {};
    toEditShipmentPlan.Items.forEach((item) => {
      maxQuantitiesForItems[item.SellerSKU] = item.QuantityShipped;
    });
    setGetMaxQuantityFor(maxQuantitiesForItems);
  }, []);

  const onChangeShipmentPlan = (e) => {
    const tempShipmentPlan = { ...shipmentPlan };

    if (e.target.type === "number") {
      tempShipmentPlan[e.target.name] = parseInt(e.target.value);
    } else {
      tempShipmentPlan[e.target.name] = e.target.value;
    }

    setShipmentPlan(tempShipmentPlan);
  };
  const onChangePickupAddress = (e) => {
    const tempPickupAddress = { ...pickupAddress };

    if (e.target.type === "number") {
      tempPickupAddress[e.target.name] = parseInt(e.target.value);
    } else {
      tempPickupAddress[e.target.name] = e.target.value;
    }

    setPickupAddress(tempPickupAddress);
  };

  const onChangeItemQuantity = (sellerSKU, e) => {
    const tempItems = [...shipmentPlan.Items];

    tempItems.find((item) => item.SellerSKU == sellerSKU).QuantityShipped =
      parseInt(e.target.value);

    setShipmentPlan({ ...shipmentPlan, Items: tempItems });
  };

  const onSubmit = () => {
    const transformedSenderAddress = {
      city: pickupAddress.City,
      country: pickupAddress.CountryCode,
      email: pickupAddress.Email,
      line1: pickupAddress.AddressLine1,
      line2: pickupAddress.AddressLine2,
      name: pickupAddress.Name,
      phone: "",
      state: pickupAddress.StateOrProvinceCode,
      zip: pickupAddress.PostalCode,
    };

    const transformedItems = shipmentPlan.Items.map((item) => {
      return { sku: item.SellerSKU, quantity: item.QuantityShipped };
    });

    changeShipmentPlan({
      shipmentId: shipmentPlan.ShipmentId,
      shipmentName: shipmentPlan.ShipmentName,
      labelPrepPreference: "SELLER_LABEL",
      destinationFulfillmentCenterId:
        shipmentPlan.DestinationFulfillmentCenterId,
      items: transformedItems,
      senderAddress: transformedSenderAddress,
      shipmentStatus: shipmentPlan.ShipmentStatus,
    });
  };

  const steps = [
    {
      name: "Shipment Plan Info",
      href: "#",
      render: (
        <div className="p-4 flex flex-col gap-4">
          <div className="text-xl font-montserrat p-4 font-bold pl-2">
            Shipment Details:
          </div>
          <TextField
            type="text"
            id="shipmentName"
            label="Shipment Name"
            placeholder=" "
            value={shipmentPlan.ShipmentName ?? ""}
            name="ShipmentName"
            onChange={onChangeShipmentPlan}
          />
          <div className="flex gap-4">
            <TextField
              type="text"
              disabled={true}
              id="destinationFulfillmentCenterId"
              label="Fulfillment Center ID"
              placeholder=" "
              value={shipmentPlan.DestinationFulfillmentCenterId}
              name="DestinationFulfillmentCenterId"
              onChange={() => {}}
            />
            <TextField
              type="text"
              disabled={true}
              id="labelPrepType"
              label="Label Prep Type"
              placeholder=" "
              value={shipmentPlan.LabelPrepType}
              name="LabelPrepType"
              onChange={() => {}}
            />
          </div>
          <div className="text-xl font-montserrat p-4 font-bold pl-2">
            Shipment Pickup Address:
          </div>

          <div className="flex gap-4">
            <TextField
              type="text"
              id="Name"
              label="Name"
              placeholder=" "
              value={pickupAddress?.Name ?? ""}
              name="Name"
              onChange={onChangePickupAddress}
            />
            <TextField
              type="email"
              id="Email"
              label="Email"
              placeholder=" "
              value={pickupAddress?.Email ?? ""}
              name="Email"
              onChange={onChangePickupAddress}
            />
          </div>
          <div className="flex gap-4">
            <TextField
              type="text"
              id="addressLine1"
              label="Address Line 1"
              placeholder=" "
              value={pickupAddress?.AddressLine1 ?? ""}
              name="AddressLine1"
              onChange={onChangePickupAddress}
            />
            <TextField
              type="text"
              id="addressLine2"
              label="Address Line 2"
              placeholder=" "
              value={pickupAddress?.AddressLine2 ?? ""}
              name="AddressLine2"
              onChange={onChangePickupAddress}
            />
          </div>
          <div className="flex gap-4">
            <TextField
              type="text"
              id="city"
              label="City"
              placeholder=" "
              value={pickupAddress?.City ?? ""}
              name="City"
              onChange={onChangePickupAddress}
            />
            <TextField
              type="text"
              id="state"
              label="State Or Province Code"
              placeholder=" "
              value={pickupAddress?.StateOrProvinceCode ?? ""}
              name="StateOrProvinceCode"
              onChange={onChangePickupAddress}
            />
          </div>
          <div className="flex gap-4">
            <TextField
              type="text"
              id="countryCode"
              label="Country Code"
              placeholder=" "
              value={pickupAddress?.CountryCode ?? ""}
              name="CountryCode"
              onChange={onChangePickupAddress}
            />
            <TextField
              type="text"
              id="PostalCode"
              label="PostalCode"
              placeholder=" "
              value={pickupAddress?.PostalCode ?? ""}
              name="PostalCode"
              onChange={onChangePickupAddress}
            />
          </div>
        </div>
      ),
    },
    {
      name: "Products",
      href: "#",
      render: (
        <div className="flex flex-col gap-4">
          {shipmentPlan.Items.length > 0 ? (
            <div>
              <div className="text-xl font-montserrat p-4 font-bold pl-2">
                Current Products:
              </div>
              <OrderLineItems
                items={shipmentPlan.Items}
                onChangeItemQuantity={onChangeItemQuantity}
                getMaxQuantityFor={getMaxQuantityFor}
              />
            </div>
          ) : (
            "No items available."
          )}
        </div>
      ),
    },
  ];

  return (
    <Wizard
      title={"Order Details : " + shipmentPlan.ShipmentId}
      steps={steps.map(({ render, ...step }) => ({
        ...step,
      }))}
      onSubmit={onSubmit}
      onClose={negativeAction}>
      {(id) => {
        const step = steps.find((_, idx) => idx === id);
        if (step)
          return (
            <div>
              {step.render}
              {step.footer}
            </div>
          );
        return steps[0].render;
      }}
    </Wizard>
  );
};

const OrderLineItems = ({ items, onChangeItemQuantity, getMaxQuantityFor }) => {
  const headers = ["Seller SKU", "FN SKU", "Quantity"];
  return (
    <table className="px-2 divide-y divide-gray-200 border border-gray-400 mb-4">
      <thead className="p-4 rounded-full bg-primaryAccent w-full">
        <tr className="text-textWhite font-montserrat border-left px-12">
          {headers.map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 pl-4 w-full  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 pl-4 w-full  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr
            key={item.SellerSKU}
            className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
              }`}>
              {item.SellerSKU}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {item.FulfillmentNetworkSKU}
            </td>

            <td className="px-2 pl-4 w-10  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              <TextField
                type="number"
                id={item.SellerSKU}
                placeholder=" "
                value={item.QuantityShipped}
                name={`${item.SellerSKU}`}
                max={getMaxQuantityFor[item.SellerSKU]}
                onChange={(e) => onChangeItemQuantity(item.SellerSKU, e)}
                className={"text-center"}
                min={0}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EditShipmentPlan;
