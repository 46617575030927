import {
  BookmarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  FilterIcon,
  SearchIcon,
  SwitchHorizontalIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useEffect, useState, useContext } from "react";
import { pluralize } from "../../../utils/helper-functions";
import noFilterIcon from "#static/images/nofilter.png";
import SectionTabs from "./SectionTabs";
import CatalogMainContent from "./CatalogMainContent";
import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import SaveNewBookmark from "./SaveNewBookmark";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS, GET_PRODUCTS } from "#queries";
import { UPSERT_CATALOG } from "#mutations";
import SlideOverPanel from "#components/common/SlideOverPanel";
import ManageCatalogForm from "#components/catalogs/ManageCatalogForm";

import { AppStateContext } from "#contexts/appState";

const ALERT_TIMEOUT_IN_MS = 5000;

const initialCatalog = {
  name: "",
  products: [],
};

const ManageCatalog = ({
  products,
  total,
  filters,
  onChangeFilter,
  submitFilters,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  productTags,
  getCatalog,
  fetchedCatalog,
  setFetchedCatalog,
  pageSavedFilters,
  applyBookmarkFilters,
  saveBookmark,
  setFilterQueryOperator,
  filterQueryOperator,
  productCategories,
  clearFilters,
}) => {
  const upsertCatalogQuery = useQuery(UPSERT_CATALOG);
  const [componentAlert, setComponentAlert] = useState(null);
  const getCatalogsQuery = useQuery(GET_CATALOGS);
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const [catalogs, setCatalogs] = useState([]);
  const [savingNewBookmark, setSavingNewBookmark] = useState(null);
  const [showFilterSelection, setShowFilterSelection] = useState(false);
  const [showBookmarkSelection, setShowBookmarkSelection] = useState(false);
  const [currentBookmark, setCurrentBookmark] = useState(null);
  const [showCatalogSelection, setShowCatalogSelection] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(true);
  const [addNewCatalog, setAddNewCatalog] = useState(null);
  const appState = useContext(AppStateContext);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (addNewCatalog?.products?.length) {
      fetchCatalogProducts(addNewCatalog.products);
    } else if (addNewCatalog?.products?.length === 0) {
      setAddNewCatalog({
        ...addNewCatalog,
        displayProducts: [],
      });
    }
  }, [addNewCatalog?.products]);

  const fetchCatalogProducts = async (productIds) => {
    const response = await getProductsQuery.fetchData({
      filters: {
        id: productIds,
      },
    });
    if (response.data) {
      setAddNewCatalog({
        ...addNewCatalog,
        displayProducts: response.data.products.entities,
      });
    }
  };

  const fetchInitialData = async () => {
    const catalogsResponse = await getCatalogsQuery.fetchData();

    if (catalogsResponse.data) {
      setCatalogs(catalogsResponse.data.catalogs.entities);
    }
  };

  const filteringCriteria = [
    {
      name: "Tags",
      options: productTags
        ? productTags.map((i) => ({
            id: i.id,
            name: i.name,
          }))
        : [],
      filterName: "tags",
    },
    {
      name: "Categories",
      options: productCategories
        ? productCategories.map((i) => ({
            id: i.id,
            name: i.name,
          }))
        : [],
      filterName: "category",
    },
  ];

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      console.log("filters", filters);
      const setFilters = [];
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          const filter =
            filteringCriteria.find((f) => f.filterName === key) ||
            key === "catalog";
          if (filter) {
            setFilters.push(filter);
          }
        }
      });
      setSelectedFilters(setFilters);
    }
  }, [filters]);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleFilterCriteriaClick = (criteria) => {
    setShowFilterSelection(false);
    selectedFilters.push(criteria);
  };

  const handleCatalogSelection = (catalog) => {
    setShowCatalogSelection(false);
    if (catalog) {
      onChangeFilter("catalog", [catalog.name], true);
    } else {
      onChangeFilter("catalog", null, true);
    }
  };

  const handleSavedFilterClick = (savedFilter) => {
    setCurrentBookmark(savedFilter);
    setShowBookmarkSelection(false);

    if (savedFilter && Object.keys(savedFilter.filters).length > 0) {
      applyBookmarkFilters(savedFilter);
    } else {
      clearFilters();
    }
  };

  const handleCreateCatalogClick = () => {
    setAddNewCatalog(initialCatalog);
  };

  useEffect(() => {
    if (showFilterSelection) {
      setShowBookmarkSelection(false);
      setShowCatalogSelection(false);
    }
  }, [showFilterSelection]);

  useEffect(() => {
    if (showBookmarkSelection) {
      setShowFilterSelection(false);
      setShowCatalogSelection(false);
    }
  }, [showBookmarkSelection]);

  useEffect(() => {
    if (showCatalogSelection) {
      setShowBookmarkSelection(false);
      setShowFilterSelection(false);
    }
  }, [showCatalogSelection]);

  const [filterCriteriaTabs, setFilterCriteriaTabs] = useState([
    {
      name: "Any Criteria",
      current: filterQueryOperator !== "$and",
      tooltip: "Products that match any of the selected criteria",
      value: "$or",
    },
    {
      name: "All Criteria",
      current: filterQueryOperator === "$and",
      tooltip: "Products that match all of the selected criteria",
      value: "$and",
    },
  ]);

  useEffect(() => {
    if (filterCriteriaTabs) {
      const selectedTab = filterCriteriaTabs.find((tab) => tab.current);
      setFilterQueryOperator(selectedTab.value);
    }
  }, [filterCriteriaTabs]);

  useEffect(() => {
    if (currentBookmark && currentBookmark.queryOperator) {
      const selectedTab = filterCriteriaTabs.find(
        (tab) => tab.value === currentBookmark.queryOperator,
      );
      setFilterCriteriaTabs(
        filterCriteriaTabs.map((tab) => {
          tab.current = tab.name === selectedTab.name;
          return tab;
        }),
      );
    }
  }, [currentBookmark]);

  const trySaveBookmark = () => {
    if (selectedFilters.length > 0) {
      if (!currentBookmark) {
        const bookmark = {
          name: "New Bookmark",
          filters,
        };
        setCurrentBookmark(bookmark);
        setSavingNewBookmark(bookmark);
      } else {
        saveBookmark(currentBookmark);
      }
    }
  };

  const submitCatalog = async () => {
    if (addNewCatalog?.name && addNewCatalog?.name?.trim() !== "") {
      const response = await upsertCatalogQuery.fetchData({
        id: addNewCatalog?.id,
        name: addNewCatalog?.name,
        products: addNewCatalog?.products,
      });
      if (response.data) {
        setAddNewCatalog(null);
        fetchInitialData();
        appState.setDismissableAlert(
          response.data.upsertCatalog.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      if (response.error) {
        setComponentAlert({
          msg: response.error.message,
          alertType: "error",
        });
      }
    } else {
      setComponentAlert({
        msg: "Please enter a valid catalog name",
        alertType: "error",
      });
    }
  };

  return (
    <>
      {addNewCatalog && (
        <SlideOverPanel
          open={true}
          setOpen={setAddNewCatalog}
          title="Create Catalog"
          containerStyle={"max-w-7xl"}
          subTitle="Manage catalog name and associated products">
          <ManageCatalogForm
            catalog={addNewCatalog}
            setCatalog={setAddNewCatalog}
            submitCatalog={submitCatalog}
            productCategories={productCategories}
            componentAlert={componentAlert}
            setComponentAlert={setComponentAlert}
          />
        </SlideOverPanel>
      )}
      <div className="flex space-x-4 mt-4">
        <div
          className={`hs-catalog-sidebar hs-catalog-sidebar-${showLeftSidebar}`}>
          <div
            className={`hs-sidebar-section-action absolute -top-3 -right-3 bg-white`}
            onClick={() => setShowLeftSidebar((prevState) => !prevState)}>
            <SwitchHorizontalIcon className="h-5 w-5" />
          </div>
          {showLeftSidebar && (
            <div className="p-4">
              <div className="text-lg text-[#8A8B8E]">
                Catalog ({catalogs.length})
              </div>
              <div className="relative">
                <div className="text-xl text-[#111827] items-center flex">
                  {filters?.catalog?.[0] || "No Catalog"}{" "}
                  {showCatalogSelection ? (
                    <ChevronUpIcon
                      className="h-8 w-8"
                      onClick={() =>
                        setShowCatalogSelection((prevState) => !prevState)
                      }
                    />
                  ) : (
                    <ChevronDownIcon
                      className="h-8 w-8"
                      onClick={() =>
                        setShowCatalogSelection((prevState) => !prevState)
                      }
                    />
                  )}
                </div>
                {showCatalogSelection && (
                  <div className="absolute mt-2 w-96 bg-white border p-4 rounded-lg font-semibold text-lg z-40">
                    <div>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="search"
                          name="search"
                          id="search"
                          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent text-lg"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <div>
                      {catalogs &&
                        catalogs.map((catalog, index) => (
                          <div
                            key={index}
                            className="mt-2 flex p-2 cursor-pointer"
                            onClick={() => handleCatalogSelection(catalog)}>
                            <div className="text-lg font-normal flex-1">
                              {catalog.name}
                            </div>
                            <div className="text-gray-500 ml-2 text-lg font-light">
                              {pluralize(catalog.products?.length, "Product")}
                            </div>
                          </div>
                        ))}
                      <div className="flex">
                        <button
                          className="text-lg text-primaryAccent pr-6 py-2 rounded-lg mt-4"
                          onClick={handleCreateCatalogClick}>
                          + Create Catalog
                        </button>

                        <button
                          className="text-lg text-primaryAccent pr-6 py-2 rounded-lg mt-4"
                          onClick={() => handleCatalogSelection(null)}>
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex mt-2 space-x-4 items-center justify-center">
                <div className="hs-sidebar-section-title flex-1 text-[#111827]">
                  Filter
                </div>
                <div className="relative">
                  <div
                    className={`hs-sidebar-section-action relative ${
                      showBookmarkSelection &&
                      "hs-sidebar-section-action-active"
                    }`}
                    onClick={() =>
                      setShowBookmarkSelection((prevState) => !prevState)
                    }>
                    <BookmarkIcon className="h-5 w-5" />
                  </div>
                  {showBookmarkSelection && (
                    <div className="absolute mt-2 w-96 bg-white border p-4 rounded-lg font-semibold text-lg z-10">
                      <div className="flex items-center">
                        <div>Bookmarks</div>
                        <div>
                          <button
                            className="text-lg text-primaryAccent pr-6 py-2 rounded-lg"
                            onClick={() => handleSavedFilterClick(null)}>
                            (Clear)
                          </button>
                        </div>
                      </div>
                      <div>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent text-lg"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <div>
                        {pageSavedFilters &&
                          pageSavedFilters?.map((savedFilter, index) => (
                            <div
                              key={index}
                              className="mt-2 flex p-2 cursor-pointer"
                              onClick={() =>
                                handleSavedFilterClick(savedFilter)
                              }>
                              <div className="text-lg font-normal flex-1">
                                {savedFilter.name}
                              </div>
                              <div className="text-gray-500 ml-2 text-lg font-light">
                                {pluralize(
                                  Object.keys(savedFilter.filters).length,
                                  "Filter",
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <div
                    className={`hs-sidebar-section-action relative ${
                      showFilterSelection && "hs-sidebar-section-action-active"
                    }`}
                    onClick={() =>
                      setShowFilterSelection((prevState) => !prevState)
                    }>
                    <FilterIcon className="h-5 w-5" />
                  </div>
                  {showFilterSelection && (
                    <div className="absolute mt-2 w-96 bg-white border p-4 rounded-lg font-semibold text-lg z-50">
                      <div>Add Filter</div>
                      <div>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent text-lg"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <div>
                        {filteringCriteria
                          .filter(
                            (i) =>
                              !selectedFilters.find((f) => f.name === i.name),
                          )
                          .map((criteria, index) => (
                            <div
                              key={index}
                              className="mt-2 flex p-2 cursor-pointer"
                              onClick={() =>
                                handleFilterCriteriaClick(criteria)
                              }>
                              <div className="text-lg font-normal flex-1">
                                {criteria.name}
                              </div>
                              <div className="text-gray-500 ml-2 text-lg font-light">
                                {pluralize(criteria.options.length, "Item")}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {selectedFilters.length === 0 && (
                <div className="items-center justify-center h-full text-center pb-10 m-auto">
                  <img src={noFilterIcon} />
                  <div className="text-black text-xl font-semibold font-inter mt-4 text-center">
                    No Filters selected
                  </div>
                  <div className="text-gray-500 text-lg font-light font-inter mt-2 text-center">
                    Add filters from the filter icon on top and save it for
                    future usage
                  </div>
                  <button
                    className="bg-primaryAccent text-white px-6 py-2 rounded-lg mt-4"
                    onClick={() => setShowFilterSelection(true)}>
                    Add Filter
                  </button>
                </div>
              )}
              {selectedFilters.length > 0 && (
                <div className="p-4 flex-1 flex-grow">
                  <SectionTabs
                    tabs={filterCriteriaTabs}
                    setTabs={setFilterCriteriaTabs}
                  />
                  <div className="mt-4 space-y-4">
                    {selectedFilters.map((filter, index) => (
                      <div key={index}>
                        <div className="text-lg font-normal">{filter.name}</div>
                        <div className="flex items-center">
                          <AutocompleteMultiSelectDropdown
                            key={index}
                            options={filter.options}
                            labelKey={"name"}
                            valueKey={"name"}
                            onChange={(values) => {
                              onChangeFilter(
                                filter.filterName,
                                values && values.length ? values : null,
                                true,
                              );
                            }}
                            values={filters[filter.filterName]}
                            placeholder={`${
                              filters[filter.filterName]?.length || 0
                            } selected`}
                          />
                          <XCircleIcon
                            onClick={() => {
                              onChangeFilter(filter.filterName, null, true);
                              setSelectedFilters(
                                selectedFilters.filter(
                                  (f) => f.name !== filter.name,
                                ),
                              );
                            }}
                            className="h-12 w-12 pl-2 text-[#8C8C8C]"
                          />
                        </div>
                        <div className="filterWrapperCatalog">
                          <div className="appliedFiltersInnerWrapper">
                            {filters[filter.filterName] &&
                              filters[filter.filterName].map(
                                (filterValue, idx) => (
                                  <div
                                    className="appliedFilterWrapper"
                                    key={idx}
                                    title={filter.name}>
                                    <span className="appliedFilterText">
                                      {filterValue || null}
                                    </span>
                                    <span
                                      className="appliedFilterClose"
                                      onClick={() => {
                                        onChangeFilter(
                                          filter.filterName,
                                          filters[filter.filterName].filter(
                                            (item) => item !== filterValue,
                                          ).length > 0
                                            ? filters[filter.filterName].filter(
                                                (item) => item !== filterValue,
                                              )
                                            : null,
                                          true,
                                        );
                                      }}>
                                      <XIcon className="h-6 w-6" />
                                    </span>
                                  </div>
                                ),
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center mt-4">
                    <button
                      className="bg-primaryAccent text-white px-6 py-2 rounded-lg w-full"
                      onClick={trySaveBookmark}>
                      Save Filters
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <CatalogMainContent
          products={products}
          total={total}
          filters={filters}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          selectProduct={selectProduct}
          selectedProducts={selectedProducts}
          selectAllRows={selectAllRows}
          allRowsSelected={allRowsSelected}
          getCatalog={getCatalog}
          fetchedCatalog={fetchedCatalog}
          setFetchedCatalog={setFetchedCatalog}
          productCategories={productCategories}
        />
        {savingNewBookmark && (
          <SaveNewBookmark
            savingNewBookmark={savingNewBookmark}
            setSavingNewBookmark={setSavingNewBookmark}
            saveBookmark={() => saveBookmark(savingNewBookmark)}
          />
        )}
      </div>
    </>
  );
};

export default ManageCatalog;
