import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon } from "@heroicons/react/outline";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import truncate from "#utils/truncate";
import { getHeaderObject } from "#utils/getHeaderObject";
import cellStyleForTable from "#components/common/CellStyleForTable";

const OrderLineItems = ({
  writable,
  orderLineItems,
  setSelectedOrderLineItem,
  order,
  multiAccountSupportEnabled,
  marketplace,
  sellerId,
  warehouses,
}) => {
  const headers = (() => {
    const returnHeaders = [
      getHeaderObject("SKU", "sku"),
      getHeaderObject("Location", "binLocation"),
      getHeaderObject("Name", "productName"),
    ];

    if (["FBA", "Amazon FBM"].includes(order.source)) {
      returnHeaders.push(getHeaderObject("ASIN", "asin"));
    }
    if (order.source === "FBA") {
      returnHeaders.push(getHeaderObject("FNSKU", "fnSku"));
    }
    returnHeaders.push(getHeaderObject("Quantity", "quantity"));
    returnHeaders.push(getHeaderObject("Form Factor", "formFactor"));
    if (orderLineItems.findIndex((i) => !!i.lotId) !== -1) {
      returnHeaders.push(getHeaderObject("Lot/Batch ID", "lotId"));
    }
    if (orderLineItems.findIndex((i) => !!i.nestedFormFactorId) !== -1) {
      returnHeaders.push(getHeaderObject("LPN", "nestedFormFactorId"));
    }
    if (multiAccountSupportEnabled) {
      returnHeaders.push(getHeaderObject("Marketplace", "marketplace"));
      returnHeaders.push(getHeaderObject("Seller", "sellerId"));
    }

    return returnHeaders;
  })();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className="relative text-[16px] xl:text-sm w-full h-fit overflow-auto border border-gray-300">
      <table className="text-[16px] w-full divide-y relative divide-gray-200 whitespace-nowrap">
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 z-10">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 pl-4 py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orderLineItems.length === 0 ? (
            <tr className="bg-white">
              <td className="text-tableText pl-2 px-1 py-2 text-left font-medium tracking-wider rounded-br rounded-tr">
                {"No line items found for this order"}
              </td>
            </tr>
          ) : null}
          {orderLineItems.map((item, rowIndex) => (
            <tr
              key={item.id}
              className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              {headers.map((header, columnIndex) => {
                let value = header.correspondingValue;
                let cellStyle = cellStyleForTable(
                  value,
                  [],
                  columnIndex + 1,
                  rowIndex,
                  true,
                );

                if (value === "sku") {
                  return (
                    <td className={classNames(cellStyle, "select-text")}>
                      {item.sku}
                    </td>
                  );
                }
                if (value === "binLocation") {
                  return <td className={cellStyle}>{item.binLocation}</td>;
                }
                if (value === "productName") {
                  return (
                    <td className={cellStyle}>
                      {truncate(item.productName, 40)}
                    </td>
                  );
                }
                if (value === "asin") {
                  return (
                    <td className={cellStyle}>
                      {buildMarketplaceHyperlink(
                        item.asin,
                        "ASIN",
                        item.source,
                      )}
                    </td>
                  );
                }
                if (value === "fnSku") {
                  return <td className={cellStyle}>{item.fnSku}</td>;
                }
                if (value === "quantity") {
                  return <td className={cellStyle}>{item.quantity}</td>;
                }
                if (value === "formFactor") {
                  return <td className={cellStyle}>{item.formFactor}</td>;
                }
                if (value === "lotId") {
                  return <td className={cellStyle}>{item.lotId || "-"}</td>;
                }
                if (value === "nestedFormFactorId") {
                  return (
                    <td className={cellStyle}>
                      {item.nestedFormFactorId || "-"}
                    </td>
                  );
                }
                if (value === "marketplace") {
                  return <td className={cellStyle}>{marketplace}</td>;
                }
                if (value === "sellerId") {
                  return <td className={cellStyle}>{sellerId}</td>;
                }
                if (value === "status") {
                  return <td className={cellStyle}>{item.status}</td>;
                }
                if (value === "action") {
                  return (
                    <td className={cellStyle}>
                      <TableFieldButton
                        onClick={() => setSelectedOrderLineItem(item)}
                        text={<PencilIcon className="w-6 h-6" />}
                        disabled={!writable}
                      />
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderLineItems;
