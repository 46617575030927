import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useQuery } from "#hooks/useQuery";
import { usePresignedURL } from "#hooks/usePresignedURL";
import { GET_BATCHES } from "#queries";
import { SAVE_TENANT_PROFILE } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";

/**
 * HOC component with all the logic for api calls
 * @param {React.FunctionComponent} WrappedComponent Page component to be enriched
 * @returns Enriched page component
 */
const withProfilesLogic = (WrappedComponent) => {
  return (props) => {
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const saveTenant = useQuery(SAVE_TENANT_PROFILE);

    const onChange = (e) => {
      const profile = {
        ...selectedProfile,
      };

      profile[e.target.name] = e.target.value;
      setSelectedProfile(profile);
    };

    useEffect(() => {
      setSelectedProfile(appState?.tenant?.profile || {});
    }, [appState.tenant]);

    const onSubmit = () => {
      saveTenant.fetchData({
        tenantProfileInput: {
          id: appState.tenant.id,
          profile: { ...selectedProfile },
        },
      }).then(() => {
        // Perform a hard refresh of the window
        window.location.reload();
      });

    };

    useEffect(() => {
      if (saveTenant.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveTenant.data) {
        appState.setAlert(saveTenant.data.saveTenant.message, "success", 5000);
      }
      if (saveTenant.error) {
        appState.setAlert(saveTenant.error.message, "error", 5000);
      }
    }, [saveTenant.loading, saveTenant.data, saveTenant.error]);

    const keyGenerator = () => {
      return `logo-${appState.tenant.id}`;
    };

    return (
      <WrappedComponent
        selectedProfile={selectedProfile}
        onChange={onChange}
        onSubmit={onSubmit}
        keyGenerator={keyGenerator}
      />
    );
  };
};

export default withProfilesLogic;
