import moment from "moment-timezone";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  PencilIcon,
  TrashIcon,
  AdjustmentsIcon,
} from "@heroicons/react/outline";
import SearchSortFilter from "#components/common/SearchSortFilter";
import _ from "lodash";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";
import cellStyleForTable from "../common/CellStyleForTable";
import QuickFilters from "../common/QuickFilters";

const SKU_BIN_MAPPING_TABLE_NAME = "skuBinMappingTable";

const SkuBinMappingsList = ({
  skuBinMappings,
  editButtonClicked,
  deleteButtonClicked,
  noValuesText,
  onChangeSearchKeyword,
  getSpecificInventory,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  getSku,
  customers,
  warehouses,
  writable,
  onChangeFilter,
  multiAccountSupportEnabled,
}) => {
  const initialHeaders = () => {
    let returnHeaders = [getHeaderObject("SKU", "sku")];
    if (multiAccountSupportEnabled) {
      returnHeaders.push(getHeaderObject("Marketplace", "marketplace"));
      returnHeaders.push(getHeaderObject("Seller", "seller"));
    }
    returnHeaders = returnHeaders.concat([
      getHeaderObject("Pallet ID", "PalletId", true, "palletId"),
      getHeaderObject("Lot ID/ Batch ID", "lotId"),
      getHeaderObject("Serial Number", "serialNumber"),
      getHeaderObject("Tote", "tote"),
      getHeaderObject("Bin Location", "binLocation"),
      getHeaderObject("Allocated Quantity", "allocatedQuantity"),
      getHeaderObject("Unallocated Quantity", "unallocatedQuantity"),
      getHeaderObject("Created At", "createdAt"),
      getHeaderObject("Expiry Date", "expiryDate", true, "bestByDate"),
      getHeaderObject("LPN Form Factor", "nestedFormFactor"),
      getHeaderObject("LPN", "nestedFormFactorId"),
    ]);

    if (customers?.length > 1) {
      returnHeaders.push(getHeaderObject("Client", "client"));
    }

    if (warehouses?.length > 1) {
      returnHeaders.push(getHeaderObject("Warehouse", "warehouse"));
    }

    return returnHeaders;
  };
  const [headers, setHeaders] = useState(initialHeaders());

  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(
      auth,
      headers,
      SKU_BIN_MAPPING_TABLE_NAME,
    );
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  return (
    <>
      <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20 mt-4">
        {(customers.length > 1 || warehouses.length > 1) && (
          <QuickFilters
            warehouseFilterName={"warehouse"}
            customerFilterName={"customer"}
            customers={customers}
            warehouses={warehouses}
            filters={filters}
            onChangeFilter={onChangeFilter}
          />
        )}
        <SearchSortFilter
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          clearFilters={clearFilters}
          headers={headers}
          setShowHeaders={setShowHeaders}
          tableName={SKU_BIN_MAPPING_TABLE_NAME}
          choices={choices}
        />
        <CustomTableWithTheme>
          <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
            <tr className="text-textWhite font-montserrat border-left">
              {showHeaders.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                    key={headerIdx}>
                    {header.name}
                  </th>
                ),
              )}
              {writable && (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={showHeaders.length}>
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {skuBinMappings.length === 0 ? (
              <tr className="bg-white">
                {showHeaders.map((_, headerIdx) =>
                  headerIdx === 0 ? (
                    <td
                      className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                      key={headerIdx}>
                      {noValuesText}
                    </td>
                  ) : (
                    <td
                      className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                      key={headerIdx}></td>
                  ),
                )}
              </tr>
            ) : null}
            {skuBinMappings.map((skuBinMapping, rowIndex) => (
              <tr
                key={skuBinMapping.id}
                className={`${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                {showHeaders.map((header, columnIndex) => {
                  let value = header.correspondingValue;
                  let cellStyle = cellStyleForTable(
                    value,
                    ["sku"],
                    columnIndex,
                    rowIndex,
                  );
                  if (value == "sku") {
                    return (
                      <td
                        className={cellStyle}
                        onClick={() => getSku(skuBinMapping.product)}>
                        {skuBinMapping.sku}
                      </td>
                    );
                  }
                  if (value == "marketplace") {
                    return (
                      <td className={cellStyle}>{skuBinMapping.marketplace}</td>
                    );
                  }
                  if (value == "seller") {
                    return (
                      <td className={cellStyle}>{skuBinMapping.sellerId}</td>
                    );
                  }

                  if (value == "PalletId") {
                    return (
                      <td className={cellStyle}>{skuBinMapping.palletId}</td>
                    );
                  }
                  if (value == "lotId") {
                    return <td className={cellStyle}>{skuBinMapping.lotId}</td>;
                  }
                  if (value == "serialNumber") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.serialNumber}
                      </td>
                    );
                  }
                  if (value == "tote") {
                    return <td className={cellStyle}>{skuBinMapping.tote}</td>;
                  }
                  if (value == "binLocation") {
                    return (
                      <td className={cellStyle}>{skuBinMapping.binLocation}</td>
                    );
                  }
                  if (value == "allocatedQuantity") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.allocatedQuantity}
                      </td>
                    );
                  }
                  if (value == "unallocatedQuantity") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.unallocatedQuantity}
                      </td>
                    );
                  }
                  if (value == "createdAt") {
                    return (
                      <td className={cellStyle}>
                        {moment(skuBinMapping.createdAt).format(
                          "YYYY-MM-DD hh:mm A",
                        )}
                      </td>
                    );
                  }
                  if (value == "expiryDate") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.bestByDate &&
                          moment(skuBinMapping.bestByDate).format("YYYY-MM-DD")}
                      </td>
                    );
                  }
                  if (value === "nestedFormFactor") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.nestedFormFactor}
                      </td>
                    );
                  }
                  if (value === "nestedFormFactorId") {
                    return (
                      <td className={cellStyle}>
                        {skuBinMapping.nestedFormFactorId}
                      </td>
                    );
                  }
                  if (value == "client") {
                    return (
                      <td className={cellStyle}>
                        {customers &&
                          customers.find(
                            (item) => item.id === skuBinMapping.customer,
                          )?.name}
                      </td>
                    );
                  }
                  if (value === "warehouse") {
                    return (
                      <td className={cellStyle}>
                        {warehouses &&
                          warehouses.find(
                            (item) => item.id === skuBinMapping.warehouse,
                          )?.name}
                      </td>
                    );
                  }
                })}
                {writable && (
                  <td
                    className={cellStyleForTable(
                      "action",
                      ["sku"],
                      showHeaders.length,
                      rowIndex,
                    )}>
                    <div className="flex space-x-4 items-center">
                      <TableFieldButton
                        onClick={() => editButtonClicked(skuBinMapping)}
                        text={<PencilIcon className="w-6 h-6" />}
                        disabled={!writable}
                      />
                      <TableFieldButton
                        text={<AdjustmentsIcon className="w-6 h-6" />}
                        onClick={() => getSpecificInventory(skuBinMapping.id)}
                        disabled={!writable}
                      />
                      <TableFieldButton
                        text={<TrashIcon className="w-6 h-6" />}
                        onClick={() => deleteButtonClicked(skuBinMapping.id)}
                        disabled={!writable}
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </CustomTableWithTheme>
      </div>
    </>
  );
};

export default SkuBinMappingsList;
