import { useState, useContext, useEffect } from "react";
import PageTitle from "#components/utils/PageTitle";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";
import _ from "lodash";
import BarcodeForm from "#components/receiver/BarcodeForm";
import moment from "moment-timezone";
import Dropdown from "#components/utils/Dropdown";
import ReceiverNotes from "./ReceiverNotes";
import TextField from "#components/utils/TextField";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import Modal from "#components/utils/Modal";
import { AppStateContext } from "#contexts/appState";
import { SUPPORTED_SIZES_WITHOUT_CANVAS } from "#utils/printCanvasLabelPdf";
import AmazonPrepInstructions from "#components/products/AmazonPrepInstructions";
import NumberField from "#components/utils/NumberField";
import ReactTooltip from "react-tooltip";

const ALERT_TIMEOUT_IN_MS = 3000;

const AddingFormFactor = ({
  setAddingFormFactor,
  addingFormFactor,
  printCode,
}) => {
  const appState = useContext(AppStateContext);
  const { item, idx, updateItem } = addingFormFactor;

  return (
    <Modal
      title={`Add LPN to Product`}
      negativeAction={() => {
        setAddingFormFactor(null);
        updateItem(idx, "nestedFormFactor", null);
        updateItem(idx, "nestedFormFactorId", null);
        updateItem(idx, "dimensions", null);
        updateItem(idx, "numberOfCodes", null);
      }}
      positiveAction={() => {
        if (!item.nestedFormFactor) {
          return appState.setAlert(
            `Select a form factor`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
        if (!item.nestedFormFactorId) {
          return appState.setAlert(
            `Enter a code or generate it`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
        appState.setAlert(
          `LPN ${item.nestedFormFactor} ${item.nestedFormFactorId} added successfully.`,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        setAddingFormFactor(null);
      }}
      id="userFormModal">
      {item.nestedFormFactorId && (
        <img
          id={`label-placeholder-${item.nestedFormFactorId}`}
          style={{ position: "fixed", top: 10000 }}
        />
      )}
      <div>
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Form Factor
          </span>
        </label>
        <Dropdown
          placeholder={"Select"}
          list={[{ name: "Case" }, { name: "Carton" }, { name: "Pallet" }]}
          labelKey="name"
          valueKey="name"
          name="formFactor"
          setSelected={(e) => updateItem(idx, "nestedFormFactor", e)}
          selectedValue={item.nestedFormFactor}
        />
      </div>
      <div className="flex mt-2 space-x-2">
        <input
          type="text"
          onChange={(e) =>
            updateItem(idx, "nestedFormFactorId", e.target.value)
          }
          placeholder="Code"
          value={item.nestedFormFactorId}
          className="p-4 text-lg"
        />
        <button
          type="button"
          className="inline-flex items-center text-lg font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2"
          onClick={() =>
            updateItem(
              idx,
              "nestedFormFactorId",
              Math.floor(10000000 + Math.random() * 90000000).toString(),
            )
          }>
          Auto-Generate
        </button>
      </div>
      <div className="font-medium font-montserrat text-454A4F text-lg mt-4">
        Optional Print
      </div>
      <div className="flex space-x-2">
        <Dropdown
          placeholder={"Select Dimensions"}
          list={SUPPORTED_SIZES_WITHOUT_CANVAS.map((i) => ({
            name: i,
          }))}
          labelKey="name"
          valueKey="name"
          name="printSize"
          setSelected={(e) => updateItem(idx, "dimensions", e)}
          selectedValue={item.dimensions}
        />
        <NumberField
          onChange={(e) =>
            updateItem(idx, "numberOfCodes", parseInt(e.target.value))
          }
          placeholder="# to print"
          value={item.numberOfCodes}
          className="p-4 text-lg"
          min="0"
        />
        <button
          type="button"
          className="inline-flex items-center text-lg font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2"
          onClick={() =>
            printCode(
              item.nestedFormFactorId,
              item.numberOfCodes,
              item.dimensions,
              true,
            )
          }>
          Print
        </button>
        <button
          type="button"
          className="inline-flex items-center text-lg font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2"
          onClick={() =>
            printCode(
              item.nestedFormFactorId,
              item.numberOfCodes,
              item.dimensions,
              false,
            )
          }>
          Download
        </button>
      </div>
    </Modal>
  );
};

const DetailedView = ({
  currentSku,
  confirmItem,
  cancelItem,
  scanBarcode,
  currentItems,
  updateItem,
  removeItem,
  addItem,
  specificConsignment,
  currentProduct,
  printCode,
  prepEnabled,
  confirmPrepItem,
  eligibleConsignments,
}) => {
  const [addingFormFactor, setAddingFormFactor] = useState(null);
  const [manualValue, setManualValue] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [notes, setNotes] = useState(null);
  const [confirmedPrep, setConfirmedPrep] = useState(false);
  // Initialize state for each item in currentItems array
  const [productHasSerialNumberArray, setProductHasSerialNumberArray] =
    useState(currentItems.map(() => false));

  const handleSerialNumberChange = (index, item) => {
    const newProductHasSerialNumberArray = [...productHasSerialNumberArray];
    newProductHasSerialNumberArray[index] = item?.serialNumber?.length > 0;
    setProductHasSerialNumberArray(newProductHasSerialNumberArray);
  };

  useEffect(() => {
    // Item can be added or removed from beginning, between or middle, so have to traverse the array to check for serial number
    const newProductHasSerialNumberArray = [];
    for (const item of currentItems) {
      item?.serialNumber?.length > 0
        ? newProductHasSerialNumberArray.push(true)
        : newProductHasSerialNumberArray.push(false);
    }
    setProductHasSerialNumberArray(newProductHasSerialNumberArray);
  }, [currentItems]);

  const formFactorsFromConsignment = [currentProduct?.baseUom || "Each"];

  if (eligibleConsignments) {
    for (const consignment of eligibleConsignments) {
      for (const item of consignment.workingList.filter(
        (i) => i.sku === currentSku.sku && i.availableQuantity > 0,
      )) {
        if (item.formFactor) {
          formFactorsFromConsignment.push(item.formFactor);
        }
      }
    }
  }

  return (
    <div className="flex-col items-center justify-center max-w-7xl mx-auto">
      {barcode && (
        <BarcodeForm
          onChange={setBarcode}
          onSubmit={() => {
            scanBarcode({ data: barcode });
            setBarcode(null);
          }}
          onClose={() => setBarcode(null)}
          title="Enter Value"
        />
      )}
      {addingFormFactor && (
        <AddingFormFactor
          addingFormFactor={addingFormFactor}
          setAddingFormFactor={setAddingFormFactor}
          printCode={printCode}
        />
      )}
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        <div className="grid grid-cols-3">
          <div
            className={`flex-1 bg-gray-100 w-full ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="text-xl font-medium text-454A4F space-y-2">
              {currentSku.asin && (
                <LineItemValue value={currentSku.asin} title="ASIN" idx={7} />
              )}

              <LineItemValue value={currentSku.name} title="Name" idx={2} />
              <LineItemValue value={currentSku.sku} title="SKU" idx={3} />
              {currentSku.fnSku && (
                <LineItemValue
                  value={currentSku.fnSku}
                  title="Fn SKU"
                  idx={4}
                />
              )}

              {currentSku.upc && (
                <LineItemValue
                  value={currentSku?.upc?.join(", ")}
                  title="UPC"
                  idx={8}
                />
              )}

              {!specificConsignment?.dropship && (
                <>
                  <LineItemValueWithOptions
                    value={currentSku.tote}
                    title="Tote (optional)"
                    idx={5}
                    setBarcode={setBarcode}
                  />
                  <LineItemValueWithOptions
                    value={currentSku.binLocation}
                    title="Bin (optional)"
                    idx={6}
                    setBarcode={setBarcode}
                  />
                </>
              )}

              {specificConsignment && (
                <>
                  <LineItemValue
                    value={_.sumBy(
                      specificConsignment.workingList.filter(
                        (item) => item.sku === currentSku.sku,
                      ),
                      "quantity",
                    )}
                    title="Expected Qty"
                    idx={3}
                  />
                  <LineItemValue
                    value={
                      _.sumBy(
                        specificConsignment.workingList.filter(
                          (item) => item.sku === currentSku.sku,
                        ),
                        "quantity",
                      ) -
                      _.sumBy(
                        specificConsignment.workingList.filter(
                          (item) => item.sku === currentSku.sku,
                        ),
                        "availableQuantity",
                      )
                    }
                    title="Received Qty"
                    idx={2}
                  />
                  {specificConsignment.notes && (
                    <LineItemValue
                      value={specificConsignment.notes}
                      title="Client Notes"
                      idx={7}
                    />
                  )}
                  {prepEnabled &&
                    currentProduct &&
                    currentProduct.prepInstructions &&
                    currentProduct.prepInstructions.length > 0 && (
                      <AmazonPrepInstructions
                        prepInstructions={currentProduct.prepInstructions}
                      />
                    )}
                </>
              )}
              {!specificConsignment?.dropship && (
                <div className="flex-col items-center justify-center p-10 pt-5 pb-5 bg-white">
                  <div>
                    <TextField
                      type="text"
                      id="name"
                      label="Scan Tote/Bin"
                      placeholder=" "
                      onChange={(e) => setManualValue(e.target.value)}
                      value={manualValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          scanBarcode({ data: manualValue });
                          setManualValue("");
                        } else {
                          console.log("else");
                        }
                      }}
                      autoFocus={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex-1 flex items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-8">
        {currentItems &&
          currentItems.map((item, idx) => (
            <>
              <div className="flex-col">
                <div className="flex space-x-4 items-center bg-white shadow-md p-4">
                  <div className="w-40">
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Form Factor
                      </span>
                    </label>
                    <Dropdown
                      placeholder={"Select"}
                      list={[
                        { name: "Each" },
                        { name: "Case" },
                        { name: "Carton" },
                        { name: "Pallet" },
                      ]}
                      labelKey="name"
                      valueKey="name"
                      name="formFactor"
                      setSelected={(e) => updateItem(idx, "formFactor", e)}
                      selectedValue={item.formFactor}
                    />
                  </div>
                  <div data-for={"quantity"} data-tip={true}>
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Quantity
                      </span>
                    </label>
                    <NumberField
                      onChange={(e) =>
                        updateItem(
                          idx,
                          "quantity",
                          isNaN(
                            parseInt(
                              productHasSerialNumberArray[idx] ||
                                item.attributes?.serialNumberRequired
                                ? 1
                                : e.target.value,
                            ),
                          ) === false
                            ? parseInt(
                                productHasSerialNumberArray[idx] ||
                                  item.attributes?.serialNumberRequired
                                  ? 1
                                  : e.target.value,
                              )
                            : null,
                        )
                      } //Set immutable value for quantity to 1 for item when the serialNumberRequired flag is true
                      placeholder="Qty"
                      disabled={
                        productHasSerialNumberArray[idx] ||
                        item.attributes?.serialNumberRequired
                      }
                      value={
                        productHasSerialNumberArray[idx] ||
                        item.attributes?.serialNumberRequired
                          ? 1
                          : item.quantity
                      }
                      className="p-4 text-lg rounded-md w-24"
                      min="0"
                    />
                    {/* Below message to shown upon hovering on quantity field */}
                    {(productHasSerialNumberArray[idx] ||
                      item.attributes.serialNumberRequired) && (
                      <ReactTooltip
                        id="quantity"
                        place="top"
                        type="info"
                        effect="solid">
                        <div className="text-xl max-w-xl whitespace-normal">
                          Each item is associated with a unique serial number.
                          Therefore, when a serial number is required or added,
                          the quantity cannot exceed 1
                        </div>
                      </ReactTooltip>
                    )}
                  </div>
                  <div className="w-48">
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Condition
                      </span>
                    </label>
                    <Dropdown
                      placeholder={"Select"}
                      list={[
                        { name: "Good", status: "CONFIRMED" },
                        { name: "Used", status: "USED" },
                        { name: "Damaged", status: "DAMAGED" },
                        {
                          name: "Box Damaged",
                          status: "BOX_DAMAGED",
                        },
                        {
                          name: "No Box",
                          status: "NO_BOX",
                        },
                        {
                          name: "Plain Box",
                          status: "PLAIN_BOX",
                        },
                        {
                          name: "Wrong Item",
                          status: "WRONG_ITEM",
                        },
                      ]}
                      labelKey="name"
                      valueKey="status"
                      name="status"
                      setSelected={(e) => updateItem(idx, "status", e)}
                      selectedValue={item.status}
                    />
                  </div>
                  <div>
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Expiry Date
                      </span>
                    </label>
                    <input
                      type="date"
                      onChange={(e) =>
                        updateItem(idx, "bestByDate", e.target.value)
                      }
                      placeholder="Select Expiry date"
                      value={item.bestByDate}
                      className="p-4 rounded-md text-lg"
                      min={moment().format("YYYY-MM-DD")}
                    />
                  </div>

                  <div>
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Lot ID
                      </span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => updateItem(idx, "lotId", e.target.value)}
                      placeholder="Lot ID"
                      value={item.lotId}
                      className="p-4 text-lg rounded-md w-36"
                    />
                  </div>
                  <div>
                    <label className="block text-left mb-2">
                      <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                        Serial Number
                      </span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        updateItem(idx, "serialNumber", e.target.value);
                        if (e.target.value.length > 0) {
                          updateItem(idx, "quantity", 1);
                          handleSerialNumberChange(idx, {
                            ...item,
                            serialNumber: e.target.value,
                          });
                        } else {
                          handleSerialNumberChange(idx, {
                            ...item,
                            serialNumber: e.target.value,
                          });
                        }
                      }}
                      placeholder="Serial Number"
                      value={item.serialNumber}
                      className="p-4 text-lg rounded-md w-36"
                    />
                  </div>

                  {item.formFactor === "Pallet" && (
                    <div>
                      <label className="block text-left mb-2">
                        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                          Pallet ID
                        </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          updateItem(idx, "palletId", e.target.value)
                        }
                        placeholder="Pallet ID"
                        value={item.palletId}
                        className="p-4 text-lg rounded-md w-36"
                      />
                    </div>
                  )}

                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center text-2xl font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2"
                      onClick={() => removeItem(idx)}>
                      -
                    </button>
                  </div>
                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex items-center text-2xl font-medium text-white bg-2C7695 font-montserrat font-medium border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2"
                      onClick={addItem}>
                      +
                    </button>
                  </div>
                </div>
                <div className="flex space-x-4 items-center bg-white shadow-md p-4">
                  <div>
                    <button
                      type="button"
                      className="text-center inline-flex items-center text-xl font-medium text-white bg-FC8862 font-montserrat font-medium border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2"
                      onClick={() => setNotes({ idx, remarks: item.remarks })}>
                      + Notes
                    </button>
                  </div>
                  {item.nestedFormFactor && (
                    <>
                      <div className="text-xl font-montserrat">
                        LPN Form Factor: {item.nestedFormFactor}
                      </div>
                      <div className="text-xl font-montserrat">
                        LPN Code: {item.nestedFormFactorId}
                      </div>
                    </>
                  )}
                  <div>
                    <button
                      type="button"
                      className="text-center inline-flex items-center text-xl font-medium text-white bg-FC8862 font-montserrat font-medium border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2"
                      onClick={() =>
                        setAddingFormFactor({ idx, item, updateItem })
                      }>
                      {item.nestedFormFactor && item.nestedFormFactorId
                        ? `Edit LPN`
                        : `Add LPN`}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>

      <div className="mt-20 flex items-center justify-center space-x-6">
        {prepEnabled &&
          currentProduct?.prepInstructions?.length > 0 &&
          !confirmedPrep && (
            <div className="text-2xl text-black w-64 text-center py-4">
              Have you prepped this item?
            </div>
          )}
        {prepEnabled && !confirmedPrep && (
          <div
            className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
            onClick={() => {
              confirmPrepItem("CONFIRMED");
              setConfirmedPrep(true);
            }}>
            Yes
          </div>
        )}
        {(!prepEnabled || confirmedPrep) && (
          <div
            className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
            onClick={() => confirmItem("CONFIRMED")}>
            Confirm
          </div>
        )}

        <div
          className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4"
          onClick={cancelItem}>
          Cancel
        </div>
      </div>
      {notes && (
        <ReceiverNotes
          title="Add Note"
          onClose={() => setNotes(null)}
          onSubmit={() => {
            updateItem(notes.idx, "remarks", notes.remarks);
            setNotes(null);
          }}
          notes={notes.remarks}
          onChange={(e) =>
            setNotes({
              ...notes,
              remarks: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="w-full flex space-x-1 shadow-md m-auto" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`bg-white flex-1 border p-4 rounded-tr-md rounded-br-md`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);
const LineItemValueWithOptions = ({ title, value, idx, setBarcode }) => (
  <div className="w-full flex space-x-1 shadow-md m-auto" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md">
      {value}
    </div>
    <div
      className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md"
      onClick={() => setBarcode({ data: "" })}>
      Scan or{" "}
      <span className="text-blue-600 cursor-pointer">Enter Manually</span>
    </div>
  </div>
);

export default DetailedView;
