import { useState, useEffect } from "react";
import TextField from "#components/utils/TextField";
import AddButton from "#components/utils/AddButton";
import Modal from "#components/utils/Modal";
import _ from "lodash";

import {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
} from "../../utils/Metrics";

const STANDARD_PALLET = {
  length: 48,
  width: 40,
  existingIndex: -1,
};

const AwaitingPalletInformation = ({
  currentBatch,
  order,
  printBoxLabels,
  transportLoading,
  confirmDropoff,
  submitPalletInformation,
  downloadBoxLabels,
}) => {
  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  const preferredWeightUnit = useWeightUnitAbbreviation();
  useEffect(() => {
    printBoxLabels && printBoxLabels();
  }, []);
  const [pallets, setPallets] = useState(order?.pallets || []);
  const [addingPallet, setAddingPallet] = useState(null);
  return (
    <>
      <div className="w-full h-screen -mt-20 flex items-center justify-center">
        <div className="flex-col">
          <div className="bg-white border rounded-2xl shadow-lg w-full">
            <div className="flex items-center justify-center pt-5 pb-5">
              <div className="border-r border-gray-400 p-5">
                <div className="rounded-full p-6 bg-primaryAccent shadow-md">
                  {" "}
                  <img
                    src="https://hopstack-pub.s3.amazonaws.com/icons/completed_tick.png"
                    className="w-14"
                  />
                </div>
              </div>
              <div className="text-3xl font-medium text-454A4F py-5 px-5">
                <div>
                  Box labels generated successfully. <br />
                  Please provide the pallet information.
                </div>
              </div>
            </div>
            {order?.boxLabels && printBoxLabels && (
              <div className="flex-1 flex space-x-2 px-4">
                <div
                  className={`flex-1 text-xl text-black border-black border mt-4 rounded-md text-center py-2 bg-transparent cursor-pointer`}
                  onClick={printBoxLabels}>
                  Print Box Labels
                </div>
                <div
                  className={`flex-1 text-xl text-black border-black border mt-4 rounded-md text-center py-2 cursor-pointer`}
                  onClick={downloadBoxLabels}>
                  Download Box Labels
                </div>
              </div>
            )}
            <div className="flex-col items-center justify-center p-10 pt-5 pb-5 space-y-2">
              <Pallets
                pallets={pallets}
                setPallets={setPallets}
                setAddingPallet={setAddingPallet}
              />

              <AddButton
                text={`Add ${pallets?.length ? "another" : ""} Pallet`}
                onClick={() => setAddingPallet(STANDARD_PALLET)}
              />
              <div className="flex space-x-2">
                <div
                  className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 cursor-pointer ${
                    pallets.length > 0 ? "bg-primaryAccent" : "bg-gray-400"
                  }`}
                  onClick={() =>
                    pallets.length > 0 ? submitPalletInformation(pallets) : {}
                  }>
                  Submit
                </div>
                <div
                  className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 bg-red-600 cursor-pointer`}
                  onClick={confirmDropoff}>
                  Skip
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addingPallet && (
        <Modal
          title={`${addingPallet.existingIndex !== -1 ? "Edit" : "Add"} Pallet`}
          positiveAction={() => {
            addingPallet.existingIndex !== -1
              ? (pallets[addingPallet.existingIndex] = _.omit(
                  addingPallet,
                  "existingIndex",
                ))
              : pallets.push(_.omit(addingPallet, "existingIndex"));
            setAddingPallet(null);
          }}
          negativeAction={() => setAddingPallet(null)}>
          <div className="space-y-4">
            <div>
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Pallet Details
              </span>
            </div>

            <div className="flex items-center">
              <TextField
                type="number"
                id="length"
                label={`Length(${preferredDimensionUnit})`}
                placeholder=" "
                value={addingPallet.length}
                onChange={(e) =>
                  setAddingPallet({
                    ...addingPallet,
                    length: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
            </div>
            <div className="flex items-center">
              <TextField
                type="number"
                id="width"
                label={`Width(${preferredDimensionUnit})`}
                placeholder=" "
                value={addingPallet.width}
                onChange={(e) =>
                  setAddingPallet({
                    ...addingPallet,
                    width: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
            </div>
            <div className="flex items-center">
              <TextField
                type="number"
                id="height"
                label={`Height(${preferredDimensionUnit})`}
                placeholder=" "
                value={addingPallet.height}
                onChange={(e) =>
                  setAddingPallet({
                    ...addingPallet,
                    height: parseInt(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
            </div>
            <div className="flex items-center">
              <TextField
                type="number"
                id="weight"
                label={`Total Weight(${preferredWeightUnit})`}
                placeholder=" "
                value={addingPallet.weight}
                onChange={(e) =>
                  setAddingPallet({
                    ...addingPallet,
                    weight: parseFloat(e.target.value),
                  })
                }
                min="0"
                step="0.01"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const Pallets = ({ pallets, setPallets, setAddingPallet }) => {
  const preferredWeightUnit = useWeightUnitAbbreviation();

  return (
    <>
      <div className="text-xl font-bold text-454A4F">Pallets:</div>
      <table className="px-2 min-w-full divide-y divide-gray-200">
        <thead className="p-4 rounded-full">
          <tr className="text-textWhite font-montserratborder-left bg-primaryAccent">
            <th
              scope="col"
              className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
              Length
            </th>

            <th
              scope="col"
              className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
              Width
            </th>
            <th
              scope="col"
              className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
              Height
            </th>
            <th
              scope="col"
              className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
              Total Weight{preferredWeightUnit}
            </th>
            <th
              scope="col"
              className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {pallets.map((pallet, index) => (
            <tr
              key={index}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {pallet.length}
              </td>
              <td className="px-2 pl-4 py-1 text-left font-medium text-5F666B tracking-wider">
                {pallet.width}
              </td>
              <td className="px-2 pl-4 py-1 text-left font-medium text-5F666B tracking-wider">
                {pallet.height}
              </td>
              <td className="px-2 pl-4 py-1 text-left font-medium text-5F666B tracking-wider">
                {pallet.weight}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                <div className="flex space-x-2">
                  <AddButton
                    text="Edit"
                    onClick={() =>
                      setAddingPallet({ ...pallet, existingIndex: index })
                    }
                  />
                  <AddButton
                    text="Remove"
                    onClick={() =>
                      setPallets(pallets.filter((_, i) => i !== index))
                    }
                    styles={["bg-red-600"]}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AwaitingPalletInformation;
