import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_BIN_LOCATIONS,
  SEARCH_LOCATIONS,
  GET_BIN_LOCATIONS_FIELDS,
} from "#queries";
import {
  SAVE_BIN_LOCATION,
  BULK_UPLOAD_BIN_LOCATIONS,
  CREATE_LOCATION_TYPE,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { EntityContext } from "#contexts/entity";
import readXlsxFile from "read-excel-file";
import {
  printCanvasLabelPdf,
  SUPPORTED_SIZES_WITHOUT_CANVAS,
} from "#utils/printCanvasLabelPdf";
import { printCanvasLabelRemote } from "#utils/printCanvasLabelRemote";
import { printThirtyUpLabel } from "#utils/printThirtyUpLabel";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import { GET_LOCATION_BY_ID, SEARCH_LOCATION_TYPES } from "../../queries";
import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  UPDATE_LOCATION,
  UPDATE_MANY_LOCATIONS,
} from "../../mutations";

const withBinLocationsLogic = (WrappedComponent) => {
  return (props) => {
    const [fileIndex, setFileIndex] = useState(Math.random() * 100);
    const entity = useContext(EntityContext);
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const [entityTypes, setEntityTypes] = useState([]);
    const [customerFilter, setCustomerFilter] = useState({
      customer: null,
      warehouse: null,
    });
    const [showFilters, setShowFilters] = useState(false);
    const auth = useContext(AuthContext);
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const appState = useContext(AppStateContext);
    const [allBinLocationsSelected, setAllBinLocationsSelected] =
      useState(false);
    const [selectedPrint, setSelectedPrint] = useState(null);
    const [selectedPrints, setSelectedPrints] = useState(null);
    const [selectedBulkEditBinLocations, setSelectedBulkEditBinLocations] =
      useState(null);
    const [selectedBinLocation, setSelectedBinLocation] = useState(null);
    const [selectedBinLocations, setSelectedBinLocations] = useState([]);
    const [emptyBinLocation, setEmptyBinLocation] = useState(null);
    const binLocationsQuery = useQuery(GET_BIN_LOCATIONS);
    const searchLocationsQuery = useQuery(SEARCH_LOCATIONS);
    const getLocationByIdQuery = useQuery(GET_LOCATION_BY_ID);
    const addLocationTypeQuery = useQuery(CREATE_LOCATION_TYPE);
    const searchLocationTypesQuery = useQuery(SEARCH_LOCATION_TYPES);
    const addLocationQuery = useQuery(CREATE_LOCATION);
    const editLocationQuery = useQuery(UPDATE_LOCATION);
    const deleteLocationQuery = useQuery(DELETE_LOCATION);
    const saveMultipleBinLocationQuery = useQuery(SAVE_BIN_LOCATION);
    const updateManyLocationsQuery = useQuery(UPDATE_MANY_LOCATIONS);
    const getLocationFields = useQuery(GET_BIN_LOCATIONS_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_BIN_LOCATIONS);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [lastSelectedRow, setLastSelectedRow] = useState(null);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "BinLocations" }),
    );
    const [validationResult, setValidationResult] = useState(null);

    useEffect(() => {
      if (getLocationFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getLocationFields.data) {
        setDashboardFields(getLocationFields.data.binLocationFields);
      }
    }, [
      getLocationFields.loading,
      getLocationFields.data,
      getLocationFields.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        binLocationsQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadBinLocations.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadBinLocations
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehousesList;
        const customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }
        setCustomerFilter({
          warehouse: warehouses[0],
          customer: customers[0],
        });
      }
      getLocationFields.fetchData();
    }, []);

    useEffect(() => {
      fetchEntities();
      fetchEntityTypes();

      return () => {
        entity.resetEntities();
      };
    }, [customerFilter]);

    const fetchEntityTypes = () => {
      searchLocationTypesQuery.fetchData({});
    };

    useEffect(() => {
      if (updateManyLocationsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (updateManyLocationsQuery.data?.updateManyLocations?.error) {
        appState.setAlert(
          updateManyLocationsQuery.data.updateManyLocations.error,
          "error",
          5000,
        );
      } else if (updateManyLocationsQuery.data?.updateManyLocations?.data) {
        appState.setAlert(
          "Successfully updated bin locations",
          "success",
          5000,
        );
        setSelectedBulkEditBinLocations(null);
        setSelectedBinLocations([]);
        searchLocationsQuery.fetchData({
          warehouses: entity.filters.warehouses || undefined,
          code: entity.filters.keyword || undefined,
          pagination: {
            limit: entity.perPage,
            offset: entity.pageNumber * entity.perPage - entity.perPage,
          },
          perPage: entity.perPage,
          pageNumber: 1,
          filters: {
            ...entity.filters,
          },
          sort: entity.sort,
          ...entity.filters,
        });
      }

      if (updateManyLocationsQuery.error) {
        appState.setAlert(
          updateManyLocationsQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      updateManyLocationsQuery.data,
      updateManyLocationsQuery.error,
      updateManyLocationsQuery.loading,
    ]);

    useEffect(() => {
      if (searchLocationTypesQuery.data) {
        setEntityTypes(searchLocationTypesQuery.data.searchLocationTypes?.data);
      }

      if (searchLocationTypesQuery.error) {
        setEntityTypes([]);
      }
    }, [searchLocationTypesQuery.data, searchLocationTypesQuery.error]);

    useEffect(() => {
      if (searchLocationsQuery.loading) {
        appState.setPageLoading();
      } else {
        appState.removePageLoading();
      }
      if (searchLocationsQuery.data) {
        entity.setEntities({
          entities: searchLocationsQuery.data.searchLocations?.data?.locations,
          total: searchLocationsQuery.data.searchLocations.data.total,
          ...searchLocationsQuery.variables,
        });
        appState.removeLoading();
      }
    }, [
      searchLocationsQuery.loading,
      searchLocationsQuery.error,
      searchLocationsQuery.data,
    ]);
    const fetchEntities = () => {
      const filtersSet = {};
      entity.setFilters(filtersSet);
      searchLocationsQuery.fetchData({
        warehouses: entity.filters.warehouses || undefined,
        code: entity.filters.keyword || undefined,
        pagination: {
          limit: entity.perPage,
          offset: entity.pageNumber * entity.perPage - entity.perPage,
        },
        perPage: entity.perPage,
        pageNumber: 1,
        filters: filtersSet,
        sort: entity.sort,
      });
    };

    useEffect(() => {
      if (addLocationTypeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (addLocationTypeQuery.data) {
        searchLocationTypesQuery.fetchData({});
        setAddCustomEntity(null);
      }

      if (addLocationTypeQuery.error) {
        appState.setAlert(addLocationTypeQuery.error.message, "error", 5000);
      }
    }, [
      addLocationTypeQuery.loading,
      addLocationTypeQuery.data,
      addLocationTypeQuery.error,
    ]);

    useEffect(() => {
      if (getLocationByIdQuery.data) {
        setSelectedBinLocation(getLocationByIdQuery.data.getLocationById?.data);
      }

      if (getLocationByIdQuery.error) {
        setSelectedBinLocation(null);
      }
    }, [
      getLocationByIdQuery.loading,
      getLocationByIdQuery.data,
      getLocationByIdQuery.error,
    ]);
    const clearSelectedBinLocationAndRefresh = () => {
      setSelectedBinLocation(null);
      searchLocationsQuery.fetchData({
        warehouses: entity.filters.warehouses || undefined,
        code: entity.filters.keyword || undefined,
        pagination: {
          limit: entity.perPage,
          offset: entity.pageNumber * entity.perPage - entity.perPage,
        },
        perPage: entity.perPage,
        pageNumber: 1,
        filters: entity.filters,
        sort: entity.sort,
      });
    };
    useEffect(() => {
      if (addLocationQuery.loading) {
        appState.setLoading();
      }
      if (addLocationQuery.error) {
        appState.setAlert(addLocationQuery.error.message, "error", 5000);
        appState.removeLoading();
      }

      if (
        addLocationQuery.data?.createLocation?.data?.id &&
        selectedBinLocation
      ) {
        appState.setAlert("Location added successfully", "success", 5000);
        clearSelectedBinLocationAndRefresh();
        appState.removeLoading();
      } else if (addLocationQuery.data?.createLocation?.error) {
        appState.setAlert(
          addLocationQuery.data.createLocation.error,
          "error",
          5000,
        );
        appState.removeLoading();
      }
    }, [
      addLocationQuery.loading,
      addLocationQuery.error,
      addLocationQuery.data,
    ]);

    useEffect(() => {
      if (editLocationQuery.loading) {
        appState.setLoading();
      }
      if (editLocationQuery.error) {
        appState.setAlert(editLocationQuery.error.message, "error", 5000);
        appState.removeLoading();
      }

      if (
        editLocationQuery.data?.updateLocation?.data?.id &&
        selectedBinLocation
      ) {
        appState.setAlert("Location updated successfully", "success", 5000);
        clearSelectedBinLocationAndRefresh();
        appState.removeLoading();
      } else if (editLocationQuery.data?.updateLocation?.error) {
        appState.setAlert(
          editLocationQuery.data.updateLocation.error,
          "error",
          5000,
        );
        appState.removeLoading();
      }
    }, [
      editLocationQuery.loading,
      editLocationQuery.error,
      editLocationQuery.data,
    ]);

    useEffect(() => {
      if (deleteLocationQuery.data?.deleteLocation?.data) {
        searchLocationsQuery.fetchData({
          warehouses: entity.filters.warehouses || undefined,
          code: entity.filters.keyword || undefined,
          pagination: {
            limit: entity.perPage,
            offset: entity.pageNumber * entity.perPage - entity.perPage,
          },
          perPage: entity.perPage,
          pageNumber: 1,
          filters: {
            ...entity.filters,
          },
          sort: entity.sort,
          ...entity.filters,
        });
        appState.hideConfirmation();
      } else if (deleteLocationQuery.data?.deleteLocation?.error) {
        appState.setAlert(
          deleteLocationQuery.data.deleteLocation.error,
          "error",
          5000,
        );
        appState.removeLoading();
        appState.hideConfirmation();
      }

      if (deleteLocationQuery.error) {
        appState.hideConfirmation();
      }
    }, [
      deleteLocationQuery.loading,
      deleteLocationQuery.data,
      deleteLocationQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this location?",
        () => {
          deleteLocationQuery.fetchData({ locationId: id });
        },
        appState.hideConfirmation,
      );
    };

    const onChange = (e, entity = "selectedBinLocation") => {
      if (entity === "selectedBinLocation") {
        const binLocation = {
          ...selectedBinLocation,
        };

        binLocation[e.target.name] =
          e.target.type === "number"
            ? parseInt(e.target.value)
            : e.target.value;
        setSelectedBinLocation(binLocation);
      } else {
        const binLocation = {
          ...emptyBinLocation,
        };

        binLocation[e.target.name] =
          e.target.type === "number"
            ? parseInt(e.target.value)
            : e.target.value;
        setEmptyBinLocation(binLocation);
      }
    };

    const onChangeDropdown = (field, value, entity = "selectedBinLocation") => {
      if (field === "type") {
        if (value === "addCustom") {
          return setAddCustomEntity({
            entityParent: "LOCATION",
            name: "",
          });
        }
      }
      if (entity === "selectedBinLocation") {
        const binLocation = {
          ...selectedBinLocation,
        };

        binLocation[field] = value;
        setSelectedBinLocation(binLocation);
      } else {
        const binLocation = {
          ...emptyBinLocation,
        };

        binLocation[field] = value;
        setEmptyBinLocation(binLocation);
      }
    };

    const saveEmptyBinLocation = () => {
      appState.setLoading();
      const binLocation = {
        ...emptyBinLocation,
      };

      if (!binLocation.type) {
        appState.setAlert("Select a Location Type", "error", 5000);
        appState.removeLoading();
        return;
      }

      if (!binLocation.numberOfBins) {
        appState.setAlert("Select number of locations", "error", 5000);
        appState.removeLoading();
        return;
      }

      if (binLocation.numberOfBins <= 0) {
        appState.setAlert(
          "Number of locations should be at least 1",
          "error",
          5000,
        );
        appState.removeLoading();
        return;
      }

      if (!binLocation.namingStart) {
        appState.setAlert("Select a naming sequence start", "error", 5000);
        appState.removeLoading();
        return;
      }

      if (!binLocation.namingIncrement) {
        appState.setAlert("Select a naming sequence increment", "error", 5000);
        appState.removeLoading();
        return;
      }

      if (!binLocation.row) {
        appState.setAlert("Please enter a row", "error", 5000);
        appState.removeLoading();
        return;
      }

      if (!binLocation.section) {
        appState.setAlert("Please provide a section", "error", 5000);
        appState.removeLoading();
        return;
      }

      let currentNumber = binLocation.namingStart;
      const binLocationType = entityTypes.find(
        (i) => i.id === binLocation.type,
      );
      let namingConvention = naming(binLocationType);
      for (let i = 0; i < binLocation.numberOfBins; i++) {
        let numberValue = `${currentNumber}`;
        if (currentNumber < 10) {
          numberValue = `00${currentNumber}`;
        } else if (currentNumber < 100) {
          numberValue = `0${currentNumber}`;
        }
        let rowValue = `${binLocation.row}`;
        if (binLocation.row < 10) {
          rowValue = `0${binLocation.row}`;
        }
        let barcode = `${namingConvention}-${binLocation.section}-${rowValue}-${numberValue}`;
        if (binLocation.prefix && binLocation.prefix.trim() !== "") {
          barcode = `${binLocation.prefix.trim()}-${barcode}`;
        }
        addLocationQuery.fetchData({
          code: barcode,
          name: barcode,
          storageType: "Regular",
          type: binLocation.type,
          warehouse:
            binLocation.warehouse || auth?.user?.warehouses?.[0] || undefined,
        });
        currentNumber += binLocation.namingIncrement;
      }
      setTimeout(() => {
        searchLocationsQuery.fetchData({
          warehouses: entity.filters.warehouses || undefined,
          code: entity.filters.keyword || undefined,
          pagination: {
            limit: entity.perPage,
            offset: entity.pageNumber * entity.perPage - entity.perPage,
          },
          perPage: entity.perPage,
          pageNumber: 1,
          filters: entity.filters,
          sort: entity.sort,
        });
        setEmptyBinLocation(null);
        appState.removeLoading();
      }, 2000);
    };

    const selectBinLocation = (index, shiftKeyPressed) => {
      const selectedListCopy = [...selectedBinLocations];
      const entities = entity.entities;
      if (shiftKeyPressed) {
        if (lastSelectedRow !== null) {
          const firstIndex = Math.min(lastSelectedRow, index);
          const lastIndex = Math.max(lastSelectedRow, index);
          for (let i = firstIndex; i <= lastIndex; i++) {
            if (!selectedListCopy.includes(entities[i].id)) {
              selectedListCopy.push(entities[i].id);
            }
          }
        } else {
          setLastSelectedRow(index);
        }
      } else {
        setLastSelectedRow(index);
        const idx = selectedListCopy.indexOf(entities[index].id);
        if (idx !== -1) {
          selectedListCopy.splice(idx, 1);
        } else {
          selectedListCopy.push(entities[index].id);
        }
      }

      setSelectedBinLocations(selectedListCopy);
    };

    const clearSelectedBinLocations = () => {
      setSelectedBinLocations([]);
    };

    const queueSinglePrint = (id) => {
      const binLocation = entity.displayEntities.find((item) => item.id === id);
      if (!binLocation) {
        return;
      }
      setSelectedPrints({
        barcodes: [binLocation.code],
        barcodeType: "1D Barcode",
        noOfCopies: 1,
        printerType: "Thermal",
        printSize: "3x1",
      });
    };

    const queueMultiplePrints = () => {
      const binLocationBarcodes = entity.displayEntities
        .filter((item) => selectedBinLocations.includes(item.id))
        .map((item) => item.code);
      if (!binLocationBarcodes || binLocationBarcodes.length === 0) {
        return;
      }

      setSelectedPrints({
        barcodes: binLocationBarcodes,
        barcodeType: "1D Barcode",
        noOfCopies: 1,
        printerType: "Thermal",
        printSize: "3x1",
      });
    };

    const bulkEditBinlocations = () => {
      const binLocationBarcodes = entity.displayEntities
        .filter((item) => selectedBinLocations.includes(item.id))
        .map((item) => item.id);
      if (!binLocationBarcodes || binLocationBarcodes.length === 0) {
        return;
      }

      setSelectedBulkEditBinLocations({
        ids: binLocationBarcodes,
      });
    };

    const selectAllBinLocations = () => {
      if (allBinLocationsSelected === true) {
        setSelectedBinLocations([]);
        setAllBinLocationsSelected(false);
      } else {
        setAllBinLocationsSelected(true);
        setSelectedBinLocations(entity.displayEntities.map((item) => item.id));
      }
    };

    const printSelectedBarcodes = (print = true) => {
      // if (["tosprep"].includes(appState.subdomain)) {
      //   selectedPrints.barcodes.map((item) => {
      //     printCanvasLabelRemote(
      //       {
      //         code: `${item}`,
      //         quantity: selectedPrints.noOfCopies,
      //         dimensions: selectedPrints.printSize.replace("-Landscape", ""),
      //       },
      //       null,
      //       appState,
      //     );
      //   });
      //   return;
      // }
      if (selectedPrints.printSize === "4x6-Landscape") {
        selectedPrints.barcodes.map((item) => {
          printCanvasLabelPdf(
            `${item}_${selectedPrints.printSize}`,
            selectedPrints.noOfCopies,
            null,
            null,
            "4x6",
            appState,
            print,
          );
        });
      } else if (selectedPrints.printSize === "FNSKU") {
        appState.setLoading();
        const barcodesToPrint = [];
        for (const barcode of selectedPrints.barcodes) {
          for (let i = 0; i < selectedPrints.noOfCopies; i++) {
            barcodesToPrint.push(barcode);
          }
        }
        printThirtyUpLabel(barcodesToPrint, 1, null, appState);
      } else {
        selectedPrints.barcodes.map((item) => {
          printCanvasLabelPdf(
            SUPPORTED_SIZES_WITHOUT_CANVAS.includes(selectedPrints.printSize)
              ? `${item}`
              : `${item}_${selectedPrints.printSize}`,
            selectedPrints.noOfCopies,
            null,
            null,
            selectedPrints.printSize,
            appState,
            print,
          );
        });
      }
    };

    const uploadMultipleEntries = async (files) => {
      const rows = await readXlsxFile(files);
      setFileIndex(Math.random * 100);
      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          continue;
        }
        const row = rows[i];
        if (!row[0] || !row[1]) {
          continue;
        }
        let warehouses = [];
        let customers = [];
        if (row[2] && row[2].toString().trim() !== "") {
          let warehouseNames = row[2]
            .split(",")
            .map((item) => item.trim().toLowerCase());
          warehouses = auth.user.warehousesList
            .filter(
              (item) =>
                warehouseNames.includes(item.name.toLowerCase()) ||
                warehouseNames.includes(item.code.toLowerCase()),
            )
            .map((item) => item.id.toString());
          if (warehouses.length === 0) {
            return appState.setAlert(
              `Invalid warehouse(s) on row ${i + 1}`,
              "error",
              5000,
            );
          }
        }
        if (row[3] && row[3].toString().trim() !== "") {
          let customerNames = row[3]
            .split(",")
            .map((item) => item.trim().toLowerCase());
          customers = auth.user.customersList
            .filter(
              (item) =>
                customerNames.includes(item.name.toLowerCase()) ||
                customerNames.includes(item.code.toLowerCase()),
            )
            .map((item) => item.id.toString());
          if (customers.length === 0) {
            return appState.setAlert(
              `Invalid client(s) on row ${i + 1}`,
              "error",
              5000,
            );
          }
        }
        saveMultipleBinLocationQuery.fetchData({
          binLocationInput: {
            barcode: row[1].toString(),
            binLocationType: row[0].toString(),
            warehouses,
            customers,
          },
        });
      }

      appState.setLoading();
      setTimeout(() => {
        binLocationsQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        appState.removeLoading();
      }, 1500);
    };

    const onChangeMultiSelect = (field, value) => {
      if (selectedBinLocation) {
        const binLocation = {
          ...selectedBinLocation,
        };
        binLocation[field] = value;
        setSelectedBinLocation(binLocation);
      } else {
        const binLocation = {
          ...emptyBinLocation,
        };
        binLocation[field] = value;
        setEmptyBinLocation(binLocation);
      }
    };

    const addBlankBinLocation = () => {
      const blank = {};
      if (filters) {
        if (filters["customers"]) {
          blank["customers"] = filters["customers"];
        }
        if (filters["warehouses"]) {
          blank["warehouses"] = filters["warehouses"];
        }
      }

      setSelectedBinLocation({
        ...blank,
      });
    };

    const addBlankEmptyBinLocation = () => {
      const blank = {};
      if (filters) {
        if (filters["customers"]) {
          blank["customers"] = filters["customers"];
        }
        if (filters["warehouses"]) {
          blank["warehouses"] = filters["warehouses"];
        }
      }

      setEmptyBinLocation({
        ...blank,
      });
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        const vars = {
          warehouses: entity.filters.warehouses || undefined,
          code: entity.filters.keyword || undefined,
          pagination: {
            limit: entity.perPage,
            offset: (entity.pageNumber - 1) * entity.perPage - entity.perPage,
          },
          perPage: entity.perPage,
          pageNumber: entity.pageNumber - 1,
          filters: entity.filters || {},
          sort: entity.sort,
        };
        return searchLocationsQuery.fetchData(vars);
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          warehouses: entity.filters.warehouses || undefined,
          code: entity.filters.keyword || undefined,
          pagination: {
            limit: entity.perPage,
            offset: (entity.pageNumber + 1) * entity.perPage - entity.perPage,
          },
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters || {},
          sort: entity.sort,
        };
        return searchLocationsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        binLocations={entity.entities}
        displayBinLocations={entity.displayEntities}
        addBlankBinLocation={addBlankBinLocation}
        addBlankEmptyBinLocation={addBlankEmptyBinLocation}
        locationTypes={entityTypes}
        fetchBinLocation={(id) =>
          getLocationByIdQuery.fetchData({ locationId: id })
        }
        selectedBinLocation={selectedBinLocation}
        emptyBinLocation={emptyBinLocation}
        setSelectedBinLocation={setSelectedBinLocation}
        //
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        //
        setEmptyBinLocation={setEmptyBinLocation}
        saveBinLocation={(binLocation) => {
          if (binLocation.id) {
            editLocationQuery.fetchData({
              locationId: binLocation.id,
              code: binLocation.code || undefined,
              name: binLocation.code || undefined,
              storageType: binLocation.storageType || undefined,
              warehouse: binLocation.warehouse || undefined,
              locationType: binLocation.type || undefined,
            });
          } else {
            addLocationQuery.fetchData({
              ...binLocation,
              name: binLocation.code,
              storageType: binLocation.storageType,
              warehouse:
                binLocation.warehouse ||
                auth?.user?.warehouses?.[0] ||
                undefined,
            });
          }
        }}
        saveEmptyBinLocation={saveEmptyBinLocation}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        deleteButtonClicked={deleteButtonClicked}
        selectedBinLocations={selectedBinLocations}
        selectBinLocation={selectBinLocation}
        clearSelectedBinLocations={clearSelectedBinLocations}
        selectedPrint={selectedPrint}
        setSelectedPrint={setSelectedPrint}
        printSelectedBarcodes={printSelectedBarcodes}
        queueMultiplePrints={queueMultiplePrints}
        queueSinglePrint={queueSinglePrint}
        selectedPrints={selectedPrints}
        setSelectedPrints={setSelectedPrints}
        selectAllBinLocations={selectAllBinLocations}
        allBinLocationsSelected={allBinLocationsSelected}
        uploadMultipleEntries={uploadMultipleEntries}
        onChangeMultiSelect={onChangeMultiSelect}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          setFilters({
            ...filters,
            customers: [e],
          });
        }}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={(e) => {
          setFilters({
            ...filters,
            warehouses: [e],
          });
          setSelectedWarehouse(e);
          searchLocationsQuery.fetchData({
            warehouses: [e],
            code: entity.filters.keyword || undefined,
            pagination: {
              limit: entity.perPage,
              offset: entity.pageNumber * entity.perPage - entity.perPage,
            },
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              warehouses: [e],
            },
            sort: entity.sort,
          });
        }}
        addCustomEntity={addCustomEntity}
        setAddCustomEntity={setAddCustomEntity}
        onSubmitCustomEntity={() =>
          addLocationTypeQuery.fetchData({
            code: addCustomEntity?.code,
            name: addCustomEntity?.name,
          })
        }
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          searchLocationsQuery.fetchData({
            warehouses: entity.filters.warehouses || undefined,
            code: entity.filters.keyword || undefined,
            pagination: {
              limit: perPage,
              offset: entity.pageNumber * perPage - perPage,
            },
            perPage: perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          searchLocationsQuery.fetchData({
            warehouses: entity.filters.warehouses || undefined,
            code: entity.filters.keyword || undefined,
            pagination: {
              limit: entity.perPage,
              offset: entity.pageNumber * entity.perPage - entity.perPage,
            },
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort: entity.sort,
            ...entity.filters,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          searchLocationsQuery.fetchData({
            warehouses: entity.filters.warehouses || undefined,
            code: undefined,
            pagination: {
              limit: entity.perPage,
              offset: entity.pageNumber * entity.perPage - entity.perPage,
            },
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: undefined },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value || undefined,
          });
          if (autoSubmit) {
            searchLocationsQuery.fetchData({
              warehouses: entity.filters.warehouses || undefined,
              code: entity.filters.keyword || undefined,
              pagination: {
                limit: entity.perPage,
                offset: entity.pageNumber * entity.perPage - entity.perPage,
              },
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value || undefined,
              },
              sort: entity.sort,
              [field]: value || undefined,
            });
          }
        }}
        onChangeMultiSelectFilter={(field, value) =>
          entity.setFilters({
            ...entity.filters,
            [field]: value || undefined,
          })
        }
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,

            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          searchLocationsQuery.fetchData({
            warehouses: entity.filters.warehouses || undefined,
            code: entity.filters.keyword || undefined,
            pagination: {
              limit: entity.perPage,
              offset: entity.pageNumber * entity.perPage - entity.perPage,
            },
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          searchLocationsQuery.fetchData({
            warehouses: undefined,
            code: undefined,
            pagination: {
              limit: entity.perPage,
              offset: entity.pageNumber * entity.perPage - entity.perPage,
            },
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
        writable={props.writable}
        bulkEditBinlocations={bulkEditBinlocations}
        selectedBulkEditBinLocations={selectedBulkEditBinLocations}
        setSelectedBulkEditBinLocations={setSelectedBulkEditBinLocations}
        saveBulkEditBinLocations={() => {
          updateManyLocationsQuery.fetchData({
            ids: selectedBulkEditBinLocations?.ids,
            locationType: selectedBulkEditBinLocations?.type || undefined,
            storageType: selectedBulkEditBinLocations?.storageType || undefined,
            warehouse: selectedBulkEditBinLocations?.warehouse || undefined,
          });
        }}
        fileIndex={fileIndex}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
      />
    );
  };
};

const naming = (locationType) => {
  return locationType && locationType.code
    ? locationType.code.toUpperCase()
    : locationType.name.toUpperCase();
};

export default withBinLocationsLogic;
