import TextField from "#components/utils/TextField";
import AddButton from "#components/utils/AddButton";
import validator from "validator";
import AddImageDropDown from "#components/products/AddImageDropDown";
import { FileUpload } from "#components/utils/FileUpload";
import { ExclamationIcon, CheckCircleIcon } from "@heroicons/react/outline";

const ValidatorSchema = {
  emailAddress: (input) => (!validator.isEmail(input) ? "Invalid email" : null),
  phoneNumber: (input) =>
    !validator.isMobilePhone(input) ? "Invalid mobile number" : null,
};

/**
 *
 * @param {object} props.selectedProfile profile object to be displayed and edited
 * @param {object} props.onChange form change handler passed from HOC
 * @param {object} props.onSubmit form submit handler passed from HOC
 * @param {object} props.onImageUploadError image upload error handler passed from HOC
 * @param {object} props.keyGenerator a function to return a unique key. this function is used by
 *                                    FileUpload component. the key returned will be the filename
 *                                    of s3 object
 * @returns Renders a form
 */
export const MyProfileForm = ({
  selectedProfile,
  onChange,
  onSubmit,
  onImageUploadError,
  keyGenerator,
}) => {
  return (
    <div className="flex flex-col justify-between p-4">
      <h1 className="text-primaryAccent text-2xl font-semibold pb-4">
        Business Details
      </h1>
      <div className="grid grid-rows-2 gap-0">
        <div className="text-primaryAccent text-2xl font-semibold mb-2">
          Warehouse Information
        </div>
        <div>
          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white">
              <TextField
                type="text"
                id="businessName"
                label="Business Name"
                value={selectedProfile?.businessName}
                name="businessName"
                onChange={onChange}
              />
            </div>
            <div className="bg-white">
              <TextField
                type="text"
                id="businessAddress"
                label="Business Address"
                value={selectedProfile?.businessAddress}
                name="businessAddress"
                onChange={onChange}
                showAddressSuggestions
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-5">
        <h1 className="text-primaryAccent text-2xl font-semibold pb-4">
          Address
        </h1>
        <div>
          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white">
              <TextField
                type="text"
                id="billingAddress"
                label="Billing Address"
                value={selectedProfile?.billingAddress}
                name="billingAddress"
                onChange={onChange}
                showAddressSuggestions
              />
            </div>
            <div className="bg-white">
              <TextField
                type="text"
                id="shippingAddress"
                label="Shipping Address"
                value={selectedProfile?.shippingAddress}
                name="shippingAddress"
                onChange={onChange}
                showAddressSuggestions
              />{" "}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6 mt-4">
            <div className="bg-white">
              <TextField
                type="text"
                id="phoneNumber"
                label="Business Phone Number"
                value={selectedProfile?.phoneNumber}
                name="phoneNumber"
                onChange={onChange}
                validate={ValidatorSchema["phoneNumber"]}
              />
            </div>
            <div className="bg-white">
              <TextField
                type="text"
                id="emailAddress"
                label="Business Contact Email Address"
                value={selectedProfile?.emailAddress}
                name="emailAddress"
                onChange={onChange}
                validate={ValidatorSchema["emailAddress"]}
              />
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-xl mt-8 text-primaryAccent font-bold">
            Upload Business Logo
          </h3>
          <FileUpload
            type={"image"}
            id={"logo"}
            customKeyGenerator={keyGenerator}
            onError={onImageUploadError}
            onSuccess={onChange}
            alerts>
            {(handleChange, filename, success, failed) => (
              <div className="mt-3">
                <input
                  id="file-upload"
                  type={"file"}
                  onChange={handleChange}
                  className="hidden"
                />
                <label
                  for="file-upload"
                  className="cursor-pointer relative block mt-3 w-20 h-20 text-primaryAccent border-1 border-gray-200 p-4 bg-white">
                  <span
                    className={`absolute top-0 right-0 w-6 h-6 ${
                      failed ? "text-red-400" : ""
                    } ${success ? "text-green-400" : ""}`}>
                    {failed && <ExclamationIcon />}
                    {success && <CheckCircleIcon />}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </label>
                {filename && (
                  <label className="text-base mt-2">{filename}</label>
                )}
              </div>
            )}
          </FileUpload>
        </div>
      </div>
      <div className="mt-4">
        <AddButton text="Confirm" onClick={() => onSubmit()} />
      </div>
    </div>
  );
};
