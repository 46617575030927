import { useState } from "react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import ModalV3 from "#components/utils/ModalV3";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";

const SaveNewBookmark = ({
  setSavingNewBookmark,
  savingNewBookmark,
  saveBookmark,
}) => {
  const [componentAlert, setComponentAlert] = useState(null);

  const trySaveBookmark = () => {
    if (!savingNewBookmark.name || savingNewBookmark.name.trim() === "") {
      setComponentAlert({
        msg: "Bookmark name is required",
        alertType: ALERT_TYPES.ERROR,
      });
      return;
    }
    saveBookmark(savingNewBookmark);
  };

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }
    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] p-4 rounded-md flex items-center border-[${borderColor}] border-2 mb-2`}>
        <span className="mr-2 flex-1 flex items-center text-lg space-x-2">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="text-[#717679] font-bold float-right flex items-center justify-center"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };

  return (
    <ModalV3
      isOpen={true}
      onClose={() => setSavingNewBookmark(null)}
      title="Save Bookmark">
      <div className="p-4 min-w-3xl modalV3">
        <div className="flex items-center justify-between pb-4 font-inter">
          <span className="font-semibold text-2xl text-[#454A4F] font-inter">
            Save Bookmark
          </span>
          <XIcon
            className="w-6 h-6 cursor-pointer"
            onClick={() => setSavingNewBookmark(null)}
          />
        </div>
        {componentAlert && renderAlert(componentAlert)}
        <div className="flex flex-col space-y-4 max-w-content py-4">
          <div className="text-xl">
            Are you sure you want to save this bookmark?
          </div>
        </div>
        <div className="flex flex-col space-y-4 max-w-content">
          <div className="space-y-2">
            <span className="font-normal text-[#717679] text-lg">
              Bookmark Name
            </span>
            <input
              type="text"
              className="border-2 border-[#DDDFE0] p-4 w-full rounded-md"
              placeholder="Enter Bookmark Name"
              value={savingNewBookmark.name}
              onChange={(e) =>
                setSavingNewBookmark({
                  ...savingNewBookmark,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-1 items-end justify-end space-x-2">
            <button
              className="border-primaryAccent border-2 text-primaryAccent p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
              onClick={() => setSavingNewBookmark(null)}>
              Cancel
            </button>
            <button
              className="bg-primaryAccent border-primaryAccent border-2 text-white p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
              onClick={trySaveBookmark}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalV3>
  );
};

export default SaveNewBookmark;
