import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_DEVICES,
  GET_STATIONS,
  GET_STATION,
  GET_STATION_FIELDS,
} from "#queries";
import {
  SAVE_STATION,
  DELETE_STATION,
  BULK_UPLOAD_STATION_FIELDS,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { MasterDataContext } from "#contexts/masterData";
import { AuthContext } from "#contexts/auth";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";

const withStationsLogic = (WrappedComponent) => {
  return (props) => {
    const [filters, setFilters] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const appState = useContext(AppStateContext);
    const masterData = useContext(MasterDataContext);
    const entity = useContext(EntityContext);
    const auth = useContext(AuthContext);
    const [selectedStation, setSelectedStation] = useState(null);
    const devicesQuery = useQuery(GET_DEVICES);
    const stationsQuery = useQuery(GET_STATIONS);
    const getStationQuery = useQuery(GET_STATION);
    const saveStationQuery = useQuery(SAVE_STATION);
    const deleteStationQuery = useQuery(DELETE_STATION);
    const [showFilters, setShowFilters] = useState(false);
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const getStationFields = useQuery(GET_STATION_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_STATION_FIELDS);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Stations" }),
    );
    const [validationResult, setValidationResult] = useState(null);

    useEffect(() => {
      if (getStationFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getStationFields.data) {
        setDashboardFields(getStationFields.data.stationFields);
      }
    }, [
      getStationFields.loading,
      getStationFields.data,
      getStationFields.error,
    ]);

    useEffect(() => {
      if (uploadBulk.data) {
        stationsQuery.fetchData({
          perPage: entity.perPage,
          filters: {},
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadStations.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadStations
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      stationsQuery.fetchData({
        perPage: entity.perPage,
        filters: {},
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    }, []);

    useEffect(() => {
      if (stationsQuery.data) {
        entity.setEntities({
          ...stationsQuery.data.stations,
          ...stationsQuery.variables,
        });
        appState.removeLoading();
      }
    }, [stationsQuery.loading, stationsQuery.error, stationsQuery.data]);

    useEffect(() => {
      devicesQuery.fetchData();
      getStationFields.fetchData();
    }, []);

    useEffect(() => {
      if (getStationQuery.data) {
        appState.removeLoading();
        setSelectedStation(getStationQuery.data.station);
      }

      if (getStationQuery.error) {
        appState.removeLoading();
      }

      if (getStationQuery.loading) {
        appState.setLoading();
      }
    }, [getStationQuery.loading, getStationQuery.data, getStationQuery.error]);

    useEffect(() => {
      if (saveStationQuery.data) {
        setSelectedStation(null);
        stationsQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (saveStationQuery.error) {
        appState.removeLoading();
        appState.setAlert(saveStationQuery.error.message, "error", 5000);
      }

      if (saveStationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      saveStationQuery.loading,
      saveStationQuery.data,
      saveStationQuery.error,
    ]);

    useEffect(() => {
      if (deleteStationQuery.data) {
        appState.hideConfirmation();
        stationsQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }
      if (deleteStationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      deleteStationQuery.loading,
      deleteStationQuery.data,
      deleteStationQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this station?",
        () => {
          deleteStationQuery.fetchData({ id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const onChange = (e) => {
      const station = {
        ...selectedStation,
      };

      station[e.target.name] =
        e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
      setSelectedStation(station);
    };

    const onChangeDropdown = (field, value) => {
      const station = {
        ...selectedStation,
      };

      station[field] = value;
      setSelectedStation(station);
    };

    const onChangeMultiSelect = (field, value) => {
      const station = {
        ...selectedStation,
      };
      station[field] = value;
      setSelectedStation(station);
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return stationsQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    return (
      <WrappedComponent
        devices={devicesQuery.data ? devicesQuery.data.devices : []}
        stations={entity.displayEntities}
        masterData={masterData}
        fetchStation={(id) => getStationQuery.fetchData({ id })}
        selectedStation={selectedStation}
        setSelectedStation={setSelectedStation}
        saveStation={(station) => {
          saveStationQuery.fetchData({ ...station });
        }}
        //
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        //
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        onChangeMultiSelect={onChangeMultiSelect}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={(e) => {
          setSelectedCustomer(e);
          setFilters({
            ...filters,
            customers: [e],
          });
          stationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              customers: [e],
            },
            sort: entity.sort,
          });
        }}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={(e) => {
          setFilters({
            ...filters,
            warehouses: [e],
          });
          setSelectedWarehouse(e);
          stationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              warehouses: [e],
            },
            sort: entity.sort,
          });
        }}
        deleteButtonClicked={deleteButtonClicked}
        writable={props.writable}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          stationsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          stationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          stationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            stationsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,

                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          stationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={() => {
          entity.setFilters({});
          stationsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {},
            sort: entity.sort,
          });
        }}
        addCustomEntity={addCustomEntity}
        setAddCustomEntity={setAddCustomEntity}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
      />
    );
  };
};

export default withStationsLogic;
