import withStationsLogic from "#components/HOC/withStationsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import StationsList from "#components/stations/StationsList";
import StationForm from "#components/stations/StationForm";
import { UserAddIcon } from "@heroicons/react/outline";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import UploadBulk from "../components/bulkUpload/UploadBulk";

const Stations = ({
  masterData,
  stations,
  selectedStation,
  setSelectedStation,
  fetchStation,
  saveStation,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  devices,
  onChangeMultiSelect,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  writable,
  dashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Station Management</PageTitle>
      </div>

      <div className="flex items-center justify-end space-x-2 mb-4">
        <AddButton
          text="Add Station"
          onClick={() => setSelectedStation({})}
          icon={UserAddIcon}
          disabled={!writable}
        />
        <AddButton
          text="Download Template"
          onClick={() =>
            (window.location =
              "https://templates-onboarding.s3.amazonaws.com/uat/Stations.xlsx")
          }
          disabled={!writable}
        />
        <UploadBulk
          dashboardFields={dashboardFields}
          saveBulkUpload={saveBulkUpload}
          errorMessage={errorMessage}
          successMessage={successMessage}
          validate={validate}
          validationResult={validationResult}
        />
      </div>
    </div>
    <StationsList
      stations={stations}
      editButtonClicked={(e) => {
        fetchStation(e.id);
      }}
      deleteButtonClicked={(e) => deleteButtonClicked(e)}
      masterData={masterData}
      headers={[
        "Name",
        "Module",
        "Warehouses",
        "Clients",
        "Action",
      ]}
      noValuesText="No Stations"
      devices={devices}
      customers={customers}
      warehouses={warehouses}
      writable={writable}
      onChangeSearchKeyword={onChangeSearchKeyword}
      filters={filters}
      submitFilters={submitFilters}
      clearKeyword={clearKeyword}
      setSort={setSort}
      sort={sort}
      setShowFilters={setShowFilters}
      showFilters={showFilters}
      clearFilters={clearFilters}
      onChangeFilter={onChangeFilter}
      total={total}
      pageNumber={pageNumber}
      checkPagination={checkPagination}
      perPage={perPage}
      setPerPage={setPerPage}
    />
    {selectedStation && (
      <StationForm
        title={selectedStation.id ? "Edit Station" : "Add Station"}
        selectedStation={selectedStation}
        onChange={onChange}
        onClose={() => setSelectedStation()}
        onSubmit={() => saveStation(selectedStation)}
        hopstackModules={masterData?.hopstackModules}
        onChangeDropdown={onChangeDropdown}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        devices={devices}
        onChangeMultiSelect={onChangeMultiSelect}
        customers={customers}
        warehouses={warehouses}
      />
    )}
  </div>
);

export default withStationsLogic(Stations);
