import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";

const DetailedView = ({ currentItem, confirmItem, completion }) => {
  return (
    <div className="min-w-max flex-col items-center justify-center max-w-4xl">
      <PageTitle>Product Details</PageTitle>
      <div className="text-xl font-medium text-454A4F space-y-2 ">
        <div className="w-full h-64 bg-white flex items-center justify-center rounded-md">
          <img src={currentItem.image} className="max-w-xs" />
        </div>
        <LineItemValue value={currentItem.sku} title="SKU" idx={1} />
        <LineItemValue value={currentItem.quantity} title="Quantity" idx={2} />
        <LineItemValue value={currentItem.name} title="Product Name" idx={3} />
        <LineItemValue value={`${completion}%`} title="Completion" idx={4} />
      </div>

      <div className="mt-20 flex items-center justify-center space-x-6">
        <div
          className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
          onClick={() => confirmItem("CONFIRMED")}
        >
          Confirm Item
        </div>
        <div
          className="text-2xl text-white w-64 rounded-md bg-red-500 text-center py-4"
          onClick={() => confirmItem("DAMAGED")}
        >
          Report Damaged
        </div>
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}
    >
      {title}
    </div>
    <div className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md">
      {value}
    </div>
  </div>
);

export default DetailedView;
