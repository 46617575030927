import withCycleCounterLogic from "#components/HOC/withCycleCounterLogic";
import CycleCounterNewBatchRequest from "#components/cycleCounter/CycleCounterNewBatchRequest";
import SingleLineItemBatch from "#components/cycleCounter/SingleLineItemBatch";
import _ from "lodash";

const CycleCounter = ({
  getNewBatch,
  currentBatch,
  scanBarcode,
  workingItems,
  increaseItemQuantity,
  decreaseItemQuantity,
  confirmPickItem,
  confirmDropoff,
  loading,
  simulateTote,
  currentItem,
  connected,
  setConnected,
  retryProgloveConnection,
}) => {
  console.log(currentBatch, "curr");
  if (!loading && !currentBatch) {
    return (
      <>
        {/* <h1 className="fixed bottom-6 right-5 padding-10 font-bold text-2xl">
            {connected ? (
            <span className="text-green-400">Connected to ProGlove</span>
          ) : (
            <span
              className="text-red-400 cursor-pointer"
              onClick={retryProgloveConnection}
            >
              Not Connected
            </span>
          )}
        </h1> */}
        <CycleCounterNewBatchRequest getNewBatch={getNewBatch} />
      </>
    );
  }
  if (currentBatch) {
    return (
      <>
        {/* <h1 className="fixed bottom-6 right-5 padding-10 font-bold text-2xl">
          {connected ? (
            <span className="text-green-400">Connected to ProGlove</span>
          ) : (
            <span
              className="text-red-400 cursor-pointer"
              onClick={retryProgloveConnection}
            >
              Not Connected
            </span>
          )}
        </h1> */}
        <SingleLineItemBatch
          currentBatch={currentBatch}
          scanBarcode={scanBarcode}
          workingItems={workingItems}
          increaseItemQuantity={increaseItemQuantity}
          decreaseItemQuantity={decreaseItemQuantity}
          confirmPickItem={confirmPickItem}
          confirmDropoff={confirmDropoff}
          simulateTote={simulateTote}
          currentItem={currentItem}
        />
      </>
    );
  }
  return null;
};

export default withCycleCounterLogic(CycleCounter);
