import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CONSOLIDATED_OUTBOUND_BATCHES,
  GET_USERS,
  FETCH_SPECIFIC_BATCH,
} from "#queries";
import {
  PRIORITIZE_BATCH,
  ASSIGN_BATCH_PICKER,
  DELETE_BATCH,
} from "#mutations";
import _ from "lodash";
import moment from "moment-timezone";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
import { useCubeQuery } from "@cubejs-client/react";
import Papa from "papaparse";
const ALERT_TIMEOUT_IN_MS = 5000;

const withOutboundSummaryLogic = (WrappedComponent) => {
  return (props) => {
    const [expandedBatch, setExpandedBatch] = useState(null);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);
    const fetchSpecificBatch = useQuery(FETCH_SPECIFIC_BATCH);
    const batchesQuery = useQuery(GET_CONSOLIDATED_OUTBOUND_BATCHES);
    const usersQuery = useQuery(GET_USERS);

    const [selectedBatch, setSelectedBatch] = useState(null);
    const [showPickerSelector, setShowPickerSelector] = useState(false);

    const [showFilters, setShowFilters] = useState(false);
    const [batchItems, setBatchItems] = useState(false);

    const [showView, setShowView] = useState("BATCHES");
    const [selectedPicker, setSelectedPicker] = useState(null);

    const auth = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [usersMap, setUsersMap] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    const prioritizeBatchQuery = useQuery(PRIORITIZE_BATCH);
    const assignBatchPickerQuery = useQuery(ASSIGN_BATCH_PICKER);
    const deleteBatchQuery = useQuery(DELETE_BATCH);

    useEffect(() => {
      usersQuery.fetchData({
        perPage: 1000,
        paginated: false,
      });
    }, []);

    useEffect(() => {
      if (usersQuery.data?.users?.entities) {
        let usersData = usersQuery.data.users.entities;
        let usersDataMap = {};
        usersData.forEach((user) => {
          usersDataMap[`${user.id}`] = user;
        });
        setUsers(usersData);
        setUsersMap(usersDataMap);
      }
    }, [usersQuery.data]);

    useEffect(() => {
      if (fetchSpecificBatch.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (fetchSpecificBatch.data) {
        setExpandedBatch(fetchSpecificBatch.data.batch);
      }

      if (fetchSpecificBatch.error) {
        setExpandedBatch(null);
      }
    }, [
      fetchSpecificBatch.loading,
      fetchSpecificBatch.error,
      fetchSpecificBatch.data,
    ]);

    useEffect(() => {
      loadFirstTimeData();

      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    const loadFirstTimeData = () => {
      const setFilters = {
        typeOfBatch: "PICKING",
        startDate: moment().utc().startOf("day").format("YYYY-MM-DD"),
      };
      entity.setFilters(setFilters);

      batchesQuery.fetchData({
        perPage: entity.perPage,
        filters: setFilters,
        paginated: false,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    useEffect(() => {
      if (
        batchesQuery.data &&
        batchesQuery.data.getConsolidatedOutboundBatches
      ) {
        console.log(batchesQuery.data.getConsolidatedOutboundBatches);
        entity.setEntities({
          ...batchesQuery.data.getConsolidatedOutboundBatches,
          ...batchesQuery.variables,
        });
      }
    }, [batchesQuery.loading, batchesQuery.error, batchesQuery.data]);

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return batchesQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const selectAllRows = () => {
      if (allRowsSelected === true) {
        setSelectedRows([]);
        setAllRowsSelected(false);
      } else {
        setAllRowsSelected(true);
        setSelectedRows(entity.entities.map((item) => item.id));
      }
    };

    /** Action to select a row in the table and add it to selectedRows */
    const selectRow = (index) => {
      const selectedRowsCopy = [...selectedRows];
      if (selectedRowsCopy.includes(entity.entities[index].id)) {
        selectedRowsCopy.splice(
          selectedRowsCopy.indexOf(entity.entities[index].id),
          1,
        );
      } else {
        selectedRowsCopy.push(entity.entities[index].id);
      }
      setSelectedRows(selectedRowsCopy);
    };

    const displayBatches = () => {
      if (showOnlySelected === true) {
        return entity.entities.filter((item) => selectedRows.includes(item.id));
      } else {
        return entity.displayEntities;
      }
    };

    /** utility function to download csv file */
    const downloadCsv = (csvString, fileName) => {
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    /** generate csv string for batces based on selected rows in the table */
    const generateBatchesCsvString = () => {
      let csvHeaders = [
        "Batch ID",
        "Order ID",
        "SKU",
        "Item Name",
        "Storage location",
        "Required Quantity",
        "Picked Quantity",
        "Picker",
        "Assigned Time(batch)",
        "First Pick(batch)",
        "Last Pick(batch)",
        "Batch Status",
        "Batch Classification",
        "Rows",
      ];
      let csvData = [csvHeaders];

      selectedRows.forEach((batchId) => {
        const batch = entity.entities.find((item) => item.id === batchId);
        const firstCompletedTime = batch?.attributes?.firstCompletedTime;
        const firstPickTime = firstCompletedTime
          ? moment(firstCompletedTime).format("MMM Do YYYY, h:mm a")
          : null;

        const lastCompletedTime = batch?.attributes?.lastCompletedTime;
        const lastPickTime = lastCompletedTime
          ? moment(lastCompletedTime).format("MMM Do YYYY, h:mm a")
          : null;

        const assignedTime = batch?.assignedTime
          ? moment(batch.assignedTime).format("MMM Do YYYY, h:mm a")
          : null;

        batch.items.forEach((item) => {
          csvData.push([
            batch.id,
            batch?.attributes?.humanReadableOrderMapping?.[item.order],
            item.sku,
            item.productName,
            item.binLocation,
            item.quantity,
            item.availableQuantity,
            usersMap[batch.user]?.name,
            assignedTime,
            firstPickTime,
            lastPickTime,
            batch.status,
            batch.classification,
            batch.rows,
          ]);
        });
      });
      return Papa.unparse(csvData);
    };

    /** Action to download batches csv file based on selected rows in the table */
    const downloadBatchesCsv = () => {
      const csvString = generateBatchesCsvString();
      const fileName = `${new Date()
        .toDateString()
        .replace(/\s+/g, "-")}-Picking-Batch-List.csv`;
      downloadCsv(csvString, fileName);
    };

    useEffect(() => {
      if (assignBatchPickerQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (assignBatchPickerQuery.data) {
        appState.setAlert(
          assignBatchPickerQuery.data.updateBatchUser?.message ||
            "User is assigned to given batch",
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (assignBatchPickerQuery.error) {
        appState.setAlert(
          assignBatchPickerQuery.error?.message || "Error in assigning picker",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      assignBatchPickerQuery.loading,
      assignBatchPickerQuery.error,
      assignBatchPickerQuery.data,
    ]);

    useEffect(() => {
      if (prioritizeBatchQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (prioritizeBatchQuery.data) {
        appState.setAlert(
          prioritizeBatchQuery.data.updateBatchPriority?.message ||
            "Successfully prioritized batch",
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (prioritizeBatchQuery.error) {
        appState.setAlert(
          prioritizeBatchQuery.error?.message || "Error in prioritizing batch",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      prioritizeBatchQuery.loading,
      prioritizeBatchQuery.error,
      prioritizeBatchQuery.data,
    ]);

    useEffect(() => {
      if (deleteBatchQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteBatchQuery.data) {
        appState.setAlert(
          deleteBatchQuery.data.deleteBatch.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        batchesQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: entity.pageNumber,
          sort: entity.sort,
        });
      }

      if (deleteBatchQuery.error) {
        appState.setAlert(
          deleteBatchQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      deleteBatchQuery.loading,
      deleteBatchQuery.error,
      deleteBatchQuery.data,
    ]);

    const onAssignBatchPicker = () => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want assign a different Picker?",
        () => {
          assignBatchPickerQuery
            .fetchData({
              batchId: selectedBatch.id,
              userId: selectedBatch.user,
            })
            .then(() => {
              const vars = {
                perPage: entity.perPage,
                pageNumber: entity.pageNumber,
                filters: entity.filters,
                paginated: true,
                sort: entity.sort,
              };
              batchesQuery.fetchData(vars).then((r) => {
                setShowPickerSelector(false);
                appState.hideConfirmation();
              });
            });
        },
        appState.hideConfirmation,
      );
    };

    const onPrioritizeBatch = (batch) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want prioritize this batch?",
        () => {
          prioritizeBatchQuery.fetchData({ batchId: batch.id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
        // "The batch was prioritized",
        // "Batch prioritizing failed",
      );
    };

    const onDeleteBatch = (batch) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want delete this batch and unallocate all orders from it?",
        () => {
          deleteBatchQuery.fetchData({ batchId: batch.id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
        // "The batch was prioritized",
        // "Batch prioritizing failed",
      );
    };

    return (
      <WrappedComponent
        {...props}
        batchItems={batchItems}
        setBatchItems={setBatchItems}
        allData={batchesQuery.data ? batchesQuery.data.getAllBatches : {}}
        batches={entity.entities}
        displayBatches={displayBatches()}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          batchesQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            batchesQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          batchesQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        showView={showView}
        setShowView={setShowView}
        users={users}
        usersMap={usersMap}
        expandBatch={(batchId) => fetchSpecificBatch.fetchData({ batchId })}
        expandedBatch={expandedBatch}
        setExpandedBatch={setExpandedBatch}
        setSelectedPicker={setSelectedPicker}
        selectedPicker={selectedPicker}
        warehouses={auth?.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth?.user?.customersList ? auth.user.customersList : []}
        selectedBatch={selectedBatch}
        setSelectedBatch={setSelectedBatch}
        showPickerSelector={showPickerSelector}
        setShowPickerSelector={setShowPickerSelector}
        selectedRows={selectedRows}
        selectRow={selectRow}
        selectAllRows={selectAllRows}
        allRowsSelected={allRowsSelected}
        showOnlySelected={showOnlySelected}
        setShowOnlySelected={setShowOnlySelected}
        clearSelectedBatches={() => {
          setSelectedRows([]);
          setAllRowsSelected(false);
          setShowOnlySelected(false);
        }}
        downloadBatchesCsv={downloadBatchesCsv}
        prioritizeBatch={onPrioritizeBatch}
        deleteBatch={onDeleteBatch}
        onAssignBatchPicker={onAssignBatchPicker}
      />
    );
  };
};

export default withOutboundSummaryLogic;
