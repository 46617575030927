const HollowButton = ({ text, colour, onClick, styles }) => {
  return (
    <div
      className={`px-4 py-1.5 rounded border border-${colour} border-solid cursor-pointer ${styles}`}
      onClick={onClick}>
      <div className={`text-sm text-${colour} font-semibold`}>{text}</div>
    </div>
  );
};
export default HollowButton;
