import withWorkflowsLogic from "#components/HOC/withWorkflowsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import WorkflowsList from "#components/workflows/WorkflowsList";
import WorkflowForm from "#components/workflows/WorkflowForm";
import AddScanForm from "#components/workflows/AddScanForm";
import AddPrintForm from "#components/workflows/AddPrintForm";
import BuildWorkflowForm from "#components/workflows/BuildWorkflowForm";
import {
  UserAddIcon,
  PlusIcon,
  PencilIcon,
  SelectorIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { PencilAltIcon } from "@heroicons/react/solid";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import TableFieldButton from "#components/utils/TableFieldButton";
import _ from "lodash";
import { Container, Draggable } from "react-smooth-dnd";
import Switch from "#components/utils/Switch";
import QuickFilters from "#components/common/QuickFilters";
import { getFilteredCustomerList } from "../utils/getFilteredCustomerList";

const Workflows = ({
  masterData,
  workflows,
  selectedWorkflow,
  setSelectedWorkflow,
  fetchWorkflow,
  saveWorkflow,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  onChangeFilter,
  devices,
  onChangeMultiSelect,
  customers,
  selectedCustomer,
  onDrop,
  setEnabled,
  warehouses,
  selectedWarehouse,
  buildWorkflow,
  setBuildWorkflow,
  addPrint,
  addScan,
  eligiblePrints,
  eligibleScans,
  setEligibleScans,
  setEligiblePrints,
  submitPrint,
  submitScans,
  removeAction,
  addToWorkflow,
  toggleStage,
  removeStage,
  onChangeRegular,
  onChangeDropdownRegular,
  onChangeMultiSelectRegular,
  setFilters,
  filters,
  writable,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Workflow Management</PageTitle>
      </div>
    </div>
    {(customers.length > 1 || warehouses.length > 1) && (
      <div className="py-5 flex">
        <div className="flex-1 justify-between flex min-w-max h-10 items-center">
          <div className="w-96 flex space-x-4">
            {warehouses && warehouses.length > 1 && (
              <RoundedDropdown
                placeholder={"Warehouse"}
                list={warehouses}
                labelKey="name"
                valueKey="id"
                name="warehouse"
                selectedValue={filters["warehouse"]}
                setSelected={(value) =>
                  onChangeFilter("warehouse", value, true)
                }
              />
            )}
            {customers && customers.length > 1 && (
              <RoundedDropdown
                placeholder={"Client"}
                list={getFilteredCustomerList(filters["warehouse"], customers)}
                labelKey="name"
                valueKey="id"
                name="customer"
                selectedValue={filters["customer"]}
                setSelected={(value) => onChangeFilter("customer", value, true)}
              />
            )}
          </div>
        </div>
      </div>
    )}
    <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
      {workflows &&
        workflows.map((item, idx) => (
          <div className="border bg-white border-black text-center" key={idx}>
            <div className="px-2 py-2 bg-primaryAccent flex items-center justify-center">
              <div className="flex-1 font-montserrat text-xl text-white -mr-10">
                {item.workflowType} WORKFLOW
              </div>
              {item.workflowType === "OUTBOUND" && (
                <div className="mr-2">
                  <TableFieldButton
                    text={<PencilIcon className="w-6 h-6" />}
                    onClick={() => fetchWorkflow(item.id)}
                    disabled={!writable}
                  />{" "}
                </div>
              )}

              <AddButton
                text="Add New"
                onClick={() =>
                  setBuildWorkflow({
                    workflowType: item.workflowType,
                    customer: filters["customer"],
                    warehouse: filters["warehouse"],
                    displayFields:
                      item.workflowType === "INBOUND"
                        ? masterData.inboundVariables.map((item, idx) => ({
                            ...item,
                            enabled: false,
                          }))
                        : masterData.outboundVariables.map((item, idx) => ({
                            ...item,
                            enabled: false,
                          })),
                  })
                }
                disabled={!writable}
              />
            </div>
            {item.stages && item.stages.length > 0 && (
              <Container lockAxis="y" onDrop={(e) => onDrop(e, item.id)}>
                {item.stages.map((stage, index) => (
                  <Draggable key={stage.id} handle=".drag-handle">
                    <div className="flex p-4 text-xl items-center">
                      <SelectorIcon className="w-6 h-6" />
                      <div className="flex-1 mx-4 items-center text-left justify-center text-xl">
                        <div>{stage.name}</div>
                      </div>

                      <div className="flex items-center">
                        <TrashIcon
                          className="w-8 h-8 -mt-2 ml-1 cursor-pointer"
                          onClick={() => writable && removeStage(idx, index)}
                        />
                        <PencilIcon
                          className="w-8 h-8 -mt-2 ml-1 cursor-pointer"
                          onClick={() =>
                            writable &&
                            setBuildWorkflow({
                              workflowType: item.workflowType,
                              customer: item.customer,
                              warehouse: item.warehouse,
                              ...stage,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Draggable>
                ))}
              </Container>
            )}
            {(!item.stages || item.stages.length === 0) && (
              <div className="p-4 text-lg">No Workflows</div>
            )}
          </div>
        ))}
    </div>
    {eligibleScans && (
      <AddScanForm
        title="Add Entry"
        eligibleScans={eligibleScans}
        selectedWorkflow={buildWorkflow}
        onClose={() => {
          setEligibleScans(null);
        }}
        onChangeMultiSelect={onChangeMultiSelect}
        onSubmit={submitScans}
      />
    )}
    {eligiblePrints && (
      <AddPrintForm
        title="Add Print"
        eligiblePrints={eligiblePrints}
        selectedWorkflow={buildWorkflow}
        onClose={() => {
          setEligiblePrints(null);
        }}
        onChangeDropdown={onChangeDropdown}
        onSubmit={submitPrint}
      />
    )}
    {buildWorkflow && (
      <BuildWorkflowForm
        selectedWorkflow={buildWorkflow}
        onClose={() => {
          !eligibleScans && !eligiblePrints && setBuildWorkflow(null);
        }}
        hopstackModules={
          masterData.hopstackModules
            ? masterData.hopstackModules.filter(
                (item) => item.moduleType === buildWorkflow.workflowType,
              )
            : []
        }
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        customers={customers}
        warehouses={warehouses}
        setEnabled={setEnabled}
        onSubmit={addToWorkflow}
        addScan={addScan}
        addPrint={addPrint}
        removeAction={removeAction}
        onChangeMultiSelect={onChangeMultiSelect}
        resultOptions={
          buildWorkflow.workflowType === "INBOUND"
            ? masterData.inboundResults
            : masterData.outboundResults
        }
      />
    )}

    {selectedWorkflow && (
      <WorkflowForm
        title={selectedWorkflow.id ? "Edit Workflow" : "Add Workflow"}
        selectedWorkflow={selectedWorkflow}
        onChange={onChangeRegular}
        onChangeDropdown={onChangeDropdownRegular}
        onClose={() => setSelectedWorkflow(null)}
        onSubmit={saveWorkflow}
        hopstackModules={masterData?.hopstackModules}
        devices={devices}
        onChangeMultiSelect={onChangeMultiSelectRegular}
        onDrop={onDrop}
        setEnabled={setEnabled}
      />
    )}
  </div>
);

export default withWorkflowsLogic(Workflows);
