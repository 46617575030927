import { CogIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import _ from "lodash";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { TrashIcon } from "@heroicons/react/solid";

const UserRolesList = ({
  userRoles,
  users,
  headers,
  customers,
  warehouses,
  editUserRolePermissionButtonClicked,
  filters,
  onChangeFilter,
  deleteUserRole,
}) => {
  const getUsersForRole = (role) => {
    const filteredUsers = users.filter((user) => user.role == role);
    const userNamesList = filteredUsers.map((user) => user && user.name);
    return userNamesList;
  };

  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-14">
      <div className="mt-6">
        <CustomTableWithTheme>
          <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
            <tr className="text-textWhite font-montserrat border-left">
              {headers.map((header, headerIdx) => (
                <th
                  scope="col"
                  className="px-2 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {userRoles.map((userRole, index) => {
              const menuItems = [];
              if (userRole.name != "ASSOCIATE")
                menuItems.push({
                  title: "Role Permissions",
                  icon: CogIcon,
                  onClick: () => editUserRolePermissionButtonClicked(userRole),
                });

              if (!getUsersForRole(userRole.name).length > 0)
                menuItems.push({
                  title: "Delete Role",
                  icon: TrashIcon,
                  onClick: () => deleteUserRole(userRole.id),
                });

              return (
                <tr
                  key={userRole.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-wider rounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {userRole.name}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    <ExpandedMultipleItem
                      items={getUsersForRole(userRole.name)}
                    />
                  </td>
                  <td className="px-1 py-1 text-left font-medium text-tableText tracking-wider rounded-br rounded-tr">
                    {menuItems.length > 0 && (
                      <div className="flex min-w-max space-x-2 ml-4 justify-start items-center">
                        <Menu as="div">
                          <Menu.Button>
                            <DotsVerticalIcon className="w-8 h-8 text-lg rounded font-montserrat p-1 font-bold bg-actionMenuBG " />
                          </Menu.Button>
                          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="">
                              {menuItems.map((menuItem, idx) => (
                                <Menu.Item key={idx}>
                                  {() => (
                                    <button
                                      className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                                      onClick={menuItem.onClick}>
                                      {menuItem.icon && (
                                        <menuItem.icon className="w-8 h-8 pr-2" />
                                      )}
                                      {menuItem.title}
                                    </button>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Menu>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </CustomTableWithTheme>
      </div>
    </div>
  );
};

export default UserRolesList;
