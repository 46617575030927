import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const statuses = [
  { id: "active", name: "Active" },
  { id: "suspended", name: "Suspended" },
  { id: "invited", name: "Invited" }
]
const UserFilters = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  warehouses,
  customers,
  roleTypes,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="flex space-x-6 mt-4">
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Role
          </span>
        </label>
        <MultiSelectAutoComplete
          options={roleTypes}
          labelKey="name"
          valueKey="name"
          setValues={(e) => onChangeFilter("role", e)}
          values={filters["role"]}
          rounded={true}
        />
      </div>
      {warehouses.length > 1 && (
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Warehouse
            </span>
          </label>
          <RoundedDropdown
            placeholder={"Warehouses"}
            list={warehouses}
            labelKey="name"
            valueKey="id"
            name="warehouse"
            selectedValue={filters["warehouses"]}
            setSelected={(value) => onChangeFilter("warehouses", value)}
          />
        </div>
      )}

      {customers.length > 1 && (
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Client
            </span>
          </label>
          <RoundedDropdown
            placeholder={"Client"}
            list={getFilteredCustomerList(filters["warehouses"], customers)}
            labelKey="name"
            valueKey="id"
            name="customer"
            selectedValue={filters["customers"]}
            setSelected={(value) => onChangeFilter("customers", value)}
          />
        </div>
      )}
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Status
          </span>
        </label>
        <RoundedDropdown
          placeholder={"Status"}
          list={statuses}
          labelKey="name"
          valueKey="id"
          name="status"
          selectedValue={filters["status"]}
          setSelected={(value) => onChangeFilter("status", value)}
        />
      </div>
    </div>
  </Modal>
);

export default UserFilters;
