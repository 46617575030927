import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import Autocomplete from "../utils/Autocomplete";

const EditProblemSkuForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  onSubmit,
  fetchedInventory,
  problemQuantityItem,
  debouncedBinLocationSearch,
  binLocationsOptions,
  warehouses,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          SKU Details
        </span>
      </div>
      <div className={`grid grid-cols-4 flex items-start`}>
        <div className="font-montserrat font-medium text-lg">SKU:</div>
        <div className="font-montserrat font-medium text-lg col-span-3">
          {problemQuantityItem?.sku}
        </div>
      </div>
      <div className={`grid grid-cols-4 flex items-start`}>
        <div className="font-montserrat font-medium text-lg">Problem:</div>
        <div className="font-montserrat font-medium text-lg col-span-3">
          {fetchedInventory?.reason}
        </div>
      </div>
      <div className={`grid grid-cols-4 flex items-start`}>
        <div className="font-montserrat font-medium text-lg">
          Total Quantity:
        </div>
        <div className="font-montserrat font-medium text-lg col-span-3">
          {fetchedInventory?.quantity}
        </div>
      </div>
      <div className={`grid grid-cols-4 flex items-start`}>
        <div className="font-montserrat font-medium text-lg">Warehouse:</div>
        <div className="font-montserrat font-medium text-lg col-span-3">
          {warehouses?.find((i) => i.id === fetchedInventory?.warehouse)?.name}
        </div>
      </div>

      <div>
        <TextField
          type="number"
          id="quantity"
          label="Quantity"
          placeholder=" "
          onChange={onChange}
          name="revisedStock"
          value={fetchedInventory?.revisedStock}
        />
      </div>
      <div>
        <Dropdown
          placeholder={"Select Action"}
          list={[{ name: "Discard" }, { name: "Add to inventory" }]}
          labelKey="name"
          valueKey="name"
          name="action"
          setSelected={(e) => {
            onChangeDropdown("action", e);
          }}
          selectedValue={fetchedInventory?.action}
        />
      </div>
      {fetchedInventory?.action === "Add to inventory" && (
        <div>
          <Autocomplete
            options={binLocationsOptions}
            labelKey="code"
            valueKey="code"
            onChange={(e) => onChangeDropdown("binLocation", e)}
            onKeyDown={(e) =>
              debouncedBinLocationSearch(e, fetchedInventory?.warehouse)
            }
            value={fetchedInventory?.binLocation}
            placeholder="Select Storage Location"
            id="BIN_LOCATION"
          />
        </div>
      )}
    </div>
  </Modal>
);

export default EditProblemSkuForm;
