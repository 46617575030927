import withVariableConfiguratorLogic from "#components/HOC/withVariableConfiguratorLogic";
import PageTitle from "#components/utils/PageTitle";
import GroupsList from "#components/groups/GroupsList";
import AddButton from "#components/utils/AddButton";
import { useHistory } from "react-router-dom";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import QuickFilters from "#components/common/QuickFilters";
import {
  ArrowRightIcon,
  SelectorIcon,
  TrashIcon,
} from "@heroicons/react/outline";

const VariableConfigurator = ({
  groups,
  masterData,
  customers,
  warehouses,
  onChangeFilter,
  filters,
  availableVariables,
  selectedVariables,
  addVariableToSelected,
  removeVariableFromSelected,
  writable,
}) => {
  const history = useHistory();

  return (
    <div>
      <div className="flex items-center mb-4">
        <div className="flex-1">
          <PageTitle>Configure Variables</PageTitle>
          {(customers.length > 1 || warehouses.length > 1) && (
            <div className="py-5 flex">
              <div className="flex-1 justify-between flex min-w-max h-10 items-center">
                <div className="w-96 flex space-x-4">
                  {warehouses && warehouses.length > 1 && (
                    <RoundedDropdown
                      placeholder={"Warehouse"}
                      list={warehouses}
                      labelKey="name"
                      valueKey="id"
                      name="warehouse"
                      selectedValue={filters["warehouse"]}
                      setSelected={(e) => onChangeFilter("warehouse", e, true)}
                    />
                  )}
                  {customers && customers.length > 1 && (
                    <RoundedDropdown
                      placeholder={"Client"}
                      list={customers}
                      labelKey="name"
                      valueKey="id"
                      name="customer"
                      selectedValue={filters["customer"]}
                      setSelected={(e) => onChangeFilter("customer", e, true)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <VariableList
          variables={availableVariables}
          title="Available Variables"
          toggleVariable={addVariableToSelected}
          writable={writable}
        />
        <VariableList
          variables={selectedVariables}
          title="Selected Variables"
          toggleVariable={removeVariableFromSelected}
          selected={true}
          writable={writable}
        />
      </div>
    </div>
  );
};

const VariableList = ({
  variables,
  title,
  selected,
  toggleVariable,
  writable,
}) => (
  <div className="border bg-white border-black text-center">
    <div className="px-2 py-2 bg-primaryAccent flex items-center justify-center">
      <div className="flex-1 font-montserrat text-xl text-white -mr-10">
        {title}
      </div>
    </div>
    {variables.map((item, idx) => (
      <div key={idx} className="flex p-4 text-xl items-center">
        <div className="flex-1 items-center text-left justify-center text-xl">
          <div>
            {idx + 1}. {item.name}
          </div>
        </div>

        <div className="flex items-center">
          {selected ? (
            <TrashIcon
              className="w-8 h-8 -mt-2 ml-1 cursor-pointer"
              onClick={() => writable && toggleVariable(item)}
            />
          ) : (
            <ArrowRightIcon
              className="w-8 h-8 -mt-2 ml-1 cursor-pointer"
              onClick={() => writable && toggleVariable(item)}
            />
          )}
        </div>
      </div>
    ))}
  </div>
);

export default withVariableConfiguratorLogic(VariableConfigurator);
