import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { VERIFY_SET_PASSWORD_TOKEN } from "#queries";
import { SET_PASSWORD } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import { useParams } from "react-router-dom";
import { AppStateContext } from "#contexts/appState";

const withSetPasswordLogic = (WrappedComponent) => {
  return (props) => {
    const { token } = useParams();
    const history = useHistory();
    const verifySetPasswordTokenQuery = useQuery(VERIFY_SET_PASSWORD_TOKEN);
    const setPasswordQuery = useQuery(SET_PASSWORD);
    const [values, setValues] = useState({ password: "" });
    const appState = useContext(AppStateContext);
    const [isExpired, setExpired] = useState(false);

    useEffect(() => {
      if (token) {
        verifySetPasswordTokenQuery.fetchData({
          token,
        });
      }
    }, []);

    useEffect(() => {
      if (verifySetPasswordTokenQuery.loading) {
        appState.setLoading();
      } else {
        if (
          verifySetPasswordTokenQuery.error &&
          verifySetPasswordTokenQuery.error.message
        ) {
          setExpired(true);
        }
        if (
          verifySetPasswordTokenQuery.data &&
          verifySetPasswordTokenQuery.data.verifySetPasswordToken
        ) {
          setExpired(false);
        }
        appState.removeLoading();
      }
    }, [
      verifySetPasswordTokenQuery.loading,
      verifySetPasswordTokenQuery.error,
      verifySetPasswordTokenQuery.data,
    ]);

    useEffect(() => {
      if (setPasswordQuery.loading) {
        appState.setLoading();
      } else {
        if (setPasswordQuery.data && setPasswordQuery.data.setPassword) {
          appState.setAlert(
            "You've successfuly set password. Please login to continue"
          );
          history.push("/");
        }
        appState.removeLoading();
      }
    }, [
      setPasswordQuery.loading,
      setPasswordQuery.error,
      setPasswordQuery.data,
    ]);

    const onSubmit = () => {
      setPasswordQuery.fetchData({ token, password: values.password });
    };

    const onChange = (e) => {
      setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
      <WrappedComponent
        onSubmit={onSubmit}
        values={values}
        onChange={onChange}
        isExpired={isExpired}
      />
    );
  };
};

export default withSetPasswordLogic;
