import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { EntityContext } from "#contexts/entity";
import { GET_MATERIALS, GET_MATERIAL } from "#queries";
import { SAVE_MATERIAL, DELETE_MATERIAL } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";

const withMaterialsLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const entity = useContext(EntityContext);

    const materialsQuery = useQuery(GET_MATERIALS);
    const getMaterialQuery = useQuery(GET_MATERIAL);
    const saveMaterialQuery = useQuery(SAVE_MATERIAL);
    const deleteMaterialQuery = useQuery(DELETE_MATERIAL);

    const [selectedMaterial, setSelectedMaterial] = useState(null);

    /**
     *
     * @param {Object} filters
     * @param {String} filters.name
     * @param {String[]} filters.ids
     * @param {Number} perPage
     * @param {Number} pageNumber
     * @param {String} sort // e.g: -updatedAt, updatedAt, -name, name
     *
     */
    const fetchMaterials = ({ filters, perPage, pageNumber, sort }) => {
      materialsQuery.fetchData({
        filters: {
          ...filters,
          name: filters?.keyword,
          keyword: undefined,
        },
        perPage,
        pageNumber,
        sort,
      });
    };

    const fetchMaterial = (id) => {
      getMaterialQuery.fetchData({ id });
    };

    useEffect(() => {
      fetchMaterials({
        filter: {},
        perPage: entity.perPage,
        pageNumber: entity.pageNumber,
        sort: entity.sort,
      });

      return () => {
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      if (materialsQuery.data && materialsQuery.data) {
        entity.setEntities({
          ...materialsQuery.data.materials.meta,
          ...materialsQuery.variables,
          entities: materialsQuery.data?.materials?.data,
        });
      }

      if (materialsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [materialsQuery.loading, materialsQuery.data]);

    useEffect(() => {
      if (getMaterialQuery.data) {
        setSelectedMaterial(getMaterialQuery.data.material);
      }

      if (getMaterialQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getMaterialQuery.loading,
      getMaterialQuery.data,
    ]);

    useEffect(() => {
      if (saveMaterialQuery.data) {
        setSelectedMaterial(null);
        materialsQuery.fetchData();
      }
      if (saveMaterialQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveMaterialQuery.error) {
        appState.setAlert(saveMaterialQuery.error.message, "error", 5000);
      }
    }, [
      saveMaterialQuery.loading,
      saveMaterialQuery.data,
      saveMaterialQuery.error,
    ]);

    useEffect(() => {
      if (deleteMaterialQuery.data) {
        appState.hideConfirmation();
        materialsQuery.fetchData();
      }
      if (deleteMaterialQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteMaterialQuery.error) {
        appState.setAlert(deleteMaterialQuery.error.message, "error", 5000);
        appState.hideConfirmation();
      }
    }, [
      deleteMaterialQuery.loading,
      deleteMaterialQuery.data,
      deleteMaterialQuery.error,
    ]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this material?",
        () => {
          deleteMaterialQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };

    const roleWise = (materials, role) =>
      _.filter(materials, (e) => e.role.name === role);

    const onChange = (e) => {
      const material = {
        ...selectedMaterial,
      };

      material[e.target.name] = e.target.value;
      setSelectedMaterial(material);
    };

    const onChangeMultiSelect = (field, value) => {
      const material = {
        ...selectedMaterial,
      };

      material[field] = value;
      setSelectedMaterial(material);
    };

    const submitFilters = () => {
      fetchMaterials({
        filters: { ...entity.filters },
        pageNumber: 1,
        perPage: entity.perPage,
        sort: entity.sort,
      });
    };

    const setSort = (key) => {
      const sort = entity.sort === key ? `-${key}` : key;
      entity.setSort({ sort });
      fetchMaterials({
        filters: { ...entity.filters },
        pageNumber: 1,
        perPage: entity.perPage,
        sort: sort,
      });
    };

    const clearFilter = () => {
      entity.setFilters({});
      fetchMaterials({
        filters: {},
        pageNumber: 1,
        perPage: entity.perPage,
        sort: entity.sort,
      });
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          sort: entity.sort,
        };
        return fetchMaterials(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const setPerPage = (perPage) => {
      entity.setPerPage({ perPage });
      fetchMaterials({
        perPage,
        filters: entity.filters,
        pageNumber: 1,
        sort: entity.sort,
      });
    };

    return (
      <WrappedComponent
        {...props}
        materials={entity.entities}
        fetchMaterial={fetchMaterial}
        entity={entity}
        fetchMaterials={fetchMaterials}
        setPerPage={setPerPage}
        checkPagination={checkPagination}
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
        saveMaterial={(material) => {
          saveMaterialQuery.fetchData({ materialInput: material });
        }}
        roleWise={roleWise}
        onChange={onChange}
        onChangeMultiSelect={onChangeMultiSelect}
        deleteButtonClicked={deleteButtonClicked}
        subdomain={appState.subdomain}
        role={auth?.user?.role}
        submitFilters={submitFilters}
        setSort={setSort}
        clearFilter={clearFilter}
      />
    );
  };
};

export default withMaterialsLogic;
