import Select from "react-select";
import { useState } from "react";

const SelectWarehouseCode = ({ warehouses, onSubmit, onSkip }) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  return (
    <div className="w-full h-screen -mt-20 flex items-center justify-center">
      <div className="flex-col">
        <div className="bg-white border rounded-2xl shadow-lg">
          <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
            <Select
              options={
                warehouses &&
                warehouses
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => ({
                  value: item.id,
                  label: `${item.name} - ${item.code}`,
                }))
              }
              value={selectedWarehouse}
              onChange={(e) => setSelectedWarehouse(e)}
              className="w-96"
              placeholder="Select Warehouse"
              isClearable={true}
            />
            <div className="flex space-x-2">
              <div
                className={`flex-1 text-xl text-white mt-4 rounded-md text-center py-2 ${
                  !!selectedWarehouse ? "bg-2C7695" : "bg-gray-400"
                }`}
                onClick={() =>
                  !!selectedWarehouse
                    ? onSubmit(selectedWarehouse)
                    : console.log()
                }>
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectWarehouseCode;
