import { useState } from "react";
import Select from "react-select";

const BundlerNewBatchRequest = ({ warehouses, setSelectedWarehouse }) => {
  const defaultWarehouse = warehouses?.length === 1 ? warehouses[0] : null;
  const [selectedWarehouse, setWarehouse] = useState(defaultWarehouse);

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="items-center justify-center space-x-4">
        <div className="w-1/2 p-5 mb-10 min-w-1/2 flex items-center gap-20">
          <div className="w-50">
            <Select
              options={
                warehouses &&
                warehouses.map((item) => ({
                  value: item.id,
                  label: `${item.name} - ${item.code}`,
                }))
              }
              value={selectedWarehouse}
              onChange={(selectedOption) => {
                setWarehouse(selectedOption);
              }}
              className="w-96"
              placeholder="Select Warehouse"
              isClearable={true}
            />
          </div>
        </div>
        <div className="py-10 self-center flex items-center justify-center">
          <div
            className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
            onClick={() => setSelectedWarehouse(selectedWarehouse.value)}>
            View Tasks
          </div>
        </div>
      </div>
    </div>
  );
};

export default BundlerNewBatchRequest;
