import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { LIST_DASHBOARDS, LIST_GRAPHS } from "#queries";
import {
  ADD_DASHBOARD,
  EDIT_DASHBOARD,
  DELETE_DASHBOARD,
  ADD_GRAPH,
} from "#mutations";
import { CubeProvider } from "@cubejs-client/react";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import cubejs from "@cubejs-client/core";

const withDashboardLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const masterData = useContext(MasterDataContext);
    const [dashboards, setDashboards] = useState([]);
    const [allGraphs, setAllGraphs] = useState([]);
    const [dashboardGraphMap, setDashboardGraphMap] = useState({});
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [selectedGraph, setSelectedGraph] = useState(null);
    const [global, setGlobal] = useState(true);
    const listDashboardsQuery = useQuery(LIST_DASHBOARDS);
    const addDashboardQuery = useQuery(ADD_DASHBOARD);
    const editDashboardQuery = useQuery(EDIT_DASHBOARD);
    const deleteDashboardQuery = useQuery(DELETE_DASHBOARD);
    const [customersList, setCustomersList] = useState(null);
    const addGraphQuery = useQuery(ADD_GRAPH);
    const listGraphsQuery = useQuery(LIST_GRAPHS);
    const [cubeMeta, setCubeMeta] = useState(null);
    const [tabsKey, setTabsKey] = useState(1);
    const [customers, setCustomers] = useState(null);
    const [warehouses, setWarehouses] = useState(null);
    const [role, setRole] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const { cubeService: API_URL } = localStorage;
    const CUBEJS_TOKEN =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDk2OTY5NDcsImV4cCI6MTY0OTc4MzM0N30.OX0BuUnGOyk0ZcrODwrryRQR8k6W3ehpeG-JGkYI1Eg";
    const cubejsApi = cubejs(CUBEJS_TOKEN, {
      apiUrl: `${API_URL}/cubejs-api/v1`,
    });
    // global.cubejsApi = cubejsApi

    useEffect(() => {
      if (auth?.user?.customers && auth?.user?.warehouses) {
        if (!customers) {
          setCustomers(auth?.user?.customers);
        }
        if (!warehouses) {
          setWarehouses(auth?.user?.warehouses);
        }
      }
      if (auth?.user?.role && auth?.user?.role !== role) {
        setRole(auth.user.role.toLowerCase());
      }
      if (appState?.tenant?.id !== tenantId) {
        setTenantId(appState?.tenant?.id);
      }
    }, [auth, appState]);
    useEffect(() => {
      // listDashboardsQuery.fetchData();
      // listGraphsQuery.fetchData();
      // cubejsApi
      //   .meta()
      //   .then((r) => {
      //     setCubeMeta(r);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    }, []);

    useEffect(() => {
      if (listDashboardsQuery.data && listGraphsQuery.data) {
        const dashboards = listDashboardsQuery.data.listDashboards;
        const graphs = listGraphsQuery.data.listGraphs;

        // sorting the graphs so that they maintain an order every time they are rendered
        // sorting by createdAt timestamp makes logical sense
        // TODO maintaing an order of the graphs on UI needs a proper solution - support rearranging graphs on dashboards
        graphs.sort((a, b) => {
          return a.createdAt - b.createdAt;
        });

        const dashboardsMap = {};
        const dashboardsNameMap = {};
        for (let i = 0; i < dashboards.length; i++) {
          dashboardsMap[dashboards[i]["id"]] = dashboards[i];
          dashboardsNameMap[dashboards[i]["name"]] = {
            dashboard: dashboards[i],
            graphs: [],
          };
        }
        for (let i = 0; i < graphs.length; i++) {
          const graph = graphs[i];
          if (dashboardsMap[graph.dashboard]) {
            if (
              Object.keys(dashboardsNameMap).includes(
                dashboardsMap[graph.dashboard].name,
              )
            ) {
              dashboardsNameMap[
                dashboardsMap[graph.dashboard].name
              ].graphs.push(JSON.parse(JSON.stringify(graph)));
            } else {
              dashboardsNameMap[dashboardsMap[graph.dashboard].name] = {
                dashboard: JSON.parse(
                  JSON.stringify(dashboardsMap[graph.dashboard]),
                ),
                graphs: [JSON.parse(JSON.stringify(graph))],
              };
            }
          }
        }
        setDashboards(dashboards);
        setAllGraphs(graphs);
        setDashboardGraphMap(dashboardsNameMap);
      }
      if (listDashboardsQuery.loading || listGraphsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (listDashboardsQuery.error || listGraphsQuery.error) {
        appState.setAlert(
          listDashboardsQuery.error?.message || listGraphsQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      listDashboardsQuery.loading,
      listGraphsQuery.loading,
      listDashboardsQuery.data,
      listGraphsQuery.data,
      listDashboardsQuery.error,
      listGraphsQuery.error,
    ]);

    useEffect(() => {
      if (addDashboardQuery.data) {
        listDashboardsQuery.fetchData();
        listGraphsQuery.fetchData();
        setSelectedDashboard(null);
      }
      if (addDashboardQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (addDashboardQuery.error) {
        appState.setAlert(addDashboardQuery.error.message, "error", 5000);
      }
    }, [
      addDashboardQuery.loading,
      addDashboardQuery.data,
      addDashboardQuery.error,
    ]);

    useEffect(() => {
      if (editDashboardQuery.data) {
        listDashboardsQuery.fetchData();
        listGraphsQuery.fetchData();
        setSelectedDashboard(null);
        location.reload();
      }
      if (editDashboardQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (editDashboardQuery.error) {
        appState.setAlert(editDashboardQuery.error.message, "error", 5000);
      }
    }, [
      editDashboardQuery.loading,
      editDashboardQuery.data,
      editDashboardQuery.error,
    ]);

    useEffect(() => {
      if (deleteDashboardQuery.data) {
        listDashboardsQuery.fetchData();
        listGraphsQuery.fetchData();
        setSelectedDashboard(null);
        location.reload();
      }
      if (deleteDashboardQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteDashboardQuery.error) {
        appState.setAlert(deleteDashboardQuery.error.message, "error", 5000);
      }
    }, [
      deleteDashboardQuery.loading,
      deleteDashboardQuery.data,
      deleteDashboardQuery.error,
    ]);

    useEffect(() => {
      if (addGraphQuery.data) {
        listDashboardsQuery.fetchData();
        listGraphsQuery.fetchData();
        setSelectedGraph(null);
      }
      if (addGraphQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (addGraphQuery.error) {
        appState.setAlert(addGraphQuery.error.message, "error", 5000);
      }
    }, [addGraphQuery.loading, addGraphQuery.data, addGraphQuery.error]);

    const addDashboard = (dashboard) => {
      dashboard.global = global;
      dashboard.creator = "-";
      addDashboardQuery.fetchData(dashboard);
    };

    const editDashboard = (dashboard) => {
      dashboard.global = global;
      dashboard.creator = "-";
      dashboard.dashboardId = dashboard.id;
      dashboard.id = undefined;
      editDashboardQuery.fetchData(dashboard);
    };

    const deleteDashboard = ({ id }) => {
      deleteDashboardQuery.fetchData({
        dashboardId: id,
      });
    };

    const addGraph = (graph) => {
      addGraphQuery.fetchData(graph);
    };

    const roleWise = (customers, role) =>
      _.filter(customers, (e) => e.role.name === role);

    const onChange = (e) => {
      const dashboard = {
        ...selectedDashboard,
      };

      dashboard[e.target.name] = e.target.value;
      setSelectedDashboard(dashboard);
    };

    const onGraphChange = (e) => {
      const graph = {
        ...selectedGraph,
      };

      graph[e.target.name] = e.target.value;
      setSelectedGraph(graph);
    };

    return (
      <CubeProvider cubejsApi={cubejsApi}>
        <WrappedComponent
          {...props}
          dashboards={dashboards}
          graphs={allGraphs}
          dashboardGraphMap={dashboardGraphMap}
          selectedDashboard={selectedDashboard}
          addDashboard={addDashboard}
          editDashboard={editDashboard}
          setSelectedDashboard={setSelectedDashboard}
          onChange={onChange}
          global={global}
          setGlobal={setGlobal}
          cubeMeta={cubeMeta}
          selectedGraph={selectedGraph}
          setSelectedGraph={setSelectedGraph}
          onGraphChange={onGraphChange}
          addGraph={addGraph}
          tabsKey={"tabs-key"}
          deleteDashboard={deleteDashboard}
          tenantId={tenantId}
          customers={customers}
          warehouses={warehouses}
          customersList={customersList}
          role={role}
          tenant={appState.tenant}
          sectionPermissions={props.sectionPermissions}
          masterData={masterData}
        />
      </CubeProvider>
    );
  };
};

export default withDashboardLogic;
