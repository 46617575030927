import { Tab } from "@headlessui/react";
import DetailedView from "#components/cycleCounter/DetailedView";
import ListView from "#components/cycleCounter/ListView";
import CompletedBatch from "#components/cycleCounter/CompletedBatch";
import _ from "lodash";

const SingleLineItemBatch = ({
  currentBatch,
  scanBarcode,
  increaseItemQuantity,
  decreaseItemQuantity,
  confirmPickItem,
  confirmDropoff,
  currentItem,
}) => {
  console.log(currentBatch, "%%%");
  if (currentBatch.status === "COMPLETED") {
    return (
      <CompletedBatch
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
      />
    );
  }

  if (!currentItem) {
    currentItem = {};
  }

  const scannedBin = currentItem.scannedBin === true;
  const tabs = {
    "Detail View": (
      <DetailedView
        currentItem={currentItem}
        currentBatch={currentBatch}
        scannedBin={scannedBin}
        scanBarcode={scanBarcode}
        increaseItemQuantity={increaseItemQuantity}
        decreaseItemQuantity={decreaseItemQuantity}
        confirmPickItem={confirmPickItem}
      />
    ),
    "List View": <ListView currentBatch={currentBatch} />,
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <div className="flex-col pt-24">
        <Tab.Group>
          <Tab.List className="flex items-center justify-center">
            <div className=" p-2 space-x-2 rounded-full bg-primaryAccent">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "px-6 py-2.5 leading-5 font-regular rounded-full",
                        "focus:outline-none focus:ring-0 font-montserrat text-lg",
                        selected
                          ? "bg-white text-primaryAccent font-medium"
                          : "border-primaryAccent bg-transparent border rounded-full text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col mt-4 bg-transparent p-4 items-center">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default SingleLineItemBatch;
