import withPutawaysLogic from "#components/HOC/withPutawaysLogic";
import moment from "moment-timezone";
import _ from "lodash";
import Stats from "#components/common/Stats";
import Pagination from "#components/common/Pagination";
import SearchSortFilter from "#components/common/SearchSortFilter";
import PutawaysStats from "#components/putaways/CustomPutawaysStats";

import BatchFilters from "#components/batches/outbound/BatchFilters";
import ExpandedBatch from "#components/batches/outbound/ExpandedBatch";
import { useContext, useEffect, useState } from "react";
import {
  getHeaderObject,
  getSortableColumns,
  getUpdatedHeaders,
} from "../../utils/getHeaderObject";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import { AuthContext } from "../../contexts/auth";
import QuickFilters from "../common/QuickFilters";

const noValuesText = "No Putaways";

const PUTAWAY_TABLE_NAME = "putaway";

const PutawaysList = ({
  total,
  perPage,
  setPerPage,
  pageNumber,
  checkPagination,
  allData,
  filters,
  sort,
  setSort,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  users,
  displayBatches,
  expandedBatch,
  setExpandedBatch,
  showFilters,
  onChangeFilter,
  expandBatch,
  stats,
  setStats,
  productivityStats,
  setProductivityStats,
  fetchBatchesForStats,
  fetchBatchesForProductivityStats,
  setSelectedAssociate,
  selectedAssociate,
  customers,
  warehouses,
  usersMap,
}) => {
  const [headers, setHeaders] = useState([
    getHeaderObject("Batch ID", "id", false),
    getHeaderObject("User", "name", false),
    getHeaderObject("Assigned Time", "assignedTime"),
    getHeaderObject(
      "First Putaway",
      "firstCompletedTime",
      true,
      "attributes.firstCompletedTime",
    ),
    getHeaderObject(
      "Last Putaway",
      "lastCompletedTime",
      true,
      "attributes.lastCompletedTime",
    ),
    getHeaderObject("# items", "quantity", false),
    getHeaderObject("Completion", "completion"),
    getHeaderObject("Status", "status"),
    getHeaderObject("Form Factor", "formFactor"),
    getHeaderObject("LPN Form Factor", "nestedFormFactor"),
    getHeaderObject("LPN", "nestedFormFactorId"),
  ]);

  const [showHeaders, setShowHeaders] = useState(headers);
  const [choices, setChoices] = useState(getSortableColumns(headers));
  const auth = useContext(AuthContext);

  useEffect(() => {
    const updatedHeaders = getUpdatedHeaders(auth, headers, PUTAWAY_TABLE_NAME);
    if (updatedHeaders) {
      setHeaders(updatedHeaders);
      setShowHeaders(updatedHeaders.filter((header) => header.enabled));
      setChoices(getSortableColumns(updatedHeaders));
    }
  }, [auth.user]);

  useEffect(() => {
    setChoices(getSortableColumns(showHeaders));
  }, [showHeaders]);

  return (
    <>
      <Stats
        stats={[
          {
            name: "Total Putaways",
            renderValue: () => allData.total,
            setShowView: () => {
              setStats(null);
              setProductivityStats(null);
            },
          },
          {
            name: "Completion",
            stringValue: true,
            renderValue: () =>
              allData.totalCompleted && allData.total
                ? allData.totalCompleted?.toString() +
                  " / " +
                  parseInt(
                    (allData.totalCompleted / allData.total) * 100,
                  ).toString() +
                  "%"
                : "",
          },
          {
            name: "Putaways Per Hour",
            renderValue: () => allData.completionPerHour,
            view: "RECEIVINGS_STATS",
            setShowView: () => {
              fetchBatchesForStats();
              fetchBatchesForProductivityStats();
            },
          },
          {
            name: "Items in Error",
            renderValue: () => allData.itemsInError,
          },
        ]}
      />
      {filters && (filters["startDate"] || filters["endDate"]) && (
        <div className="flex items-end justify-end text-primaryAccent font-bold text-2xl">
          Date Range:{" "}
          {filters && filters["startDate"] ? filters["startDate"] : "Beginning"}{" "}
          - {filters && filters["endDate"] ? filters["endDate"] : "Now"}
        </div>
      )}
      {stats && productivityStats ? (
        <PutawaysStats
          stats={stats}
          productivityStats={productivityStats}
          setSelectedAssociate={setSelectedAssociate}
          selectedAssociate={selectedAssociate}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          filters={filters}
          fetchBatchesForStats={fetchBatchesForStats}
          fetchBatchesForProductivityStats={fetchBatchesForProductivityStats}
        />
      ) : (
        <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
          {(customers?.length > 1 || warehouses?.length > 1) && (
            <QuickFilters
              warehouseFilterName={"warehouses"}
              customerFilterName={"customers"}
              customers={customers}
              warehouses={warehouses}
              filters={filters}
              onChangeFilter={onChangeFilter}
            />
          )}
          <SearchSortFilter
            onChangeSearchKeyword={onChangeSearchKeyword}
            filters={filters}
            submitFilters={submitFilters}
            clearKeyword={clearKeyword}
            setSort={setSort}
            sort={sort}
            setShowFilters={setShowFilters}
            clearFilters={clearFilters}
            choices={choices}
            headers={headers}
            setShowHeaders={setShowHeaders}
            tableName={PUTAWAY_TABLE_NAME}
          />

          <CustomTableWithTheme>
            <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
              <tr className="text-textWhite font-montserrat border-left">
                {showHeaders.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                      key={headerIdx}>
                      {header.name}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {displayBatches?.length === 0 ? (
                <tr className="bg-white">
                  {showHeaders.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <td
                        className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                        key={headerIdx}>
                        {noValuesText}
                      </td>
                    ) : (
                      <td
                        className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                        key={headerIdx}></td>
                    ),
                  )}
                </tr>
              ) : null}
              {displayBatches &&
                displayBatches.map((putaway, index) => (
                  <tr
                    key={putaway.id}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}>
                    {showHeaders.map((header, idx) => {
                      let value = header.correspondingValue;
                      let cellStyle =
                        (value == "id"
                          ? "text-primaryAccent hover:underline cursor-pointer"
                          : "text-tableText") +
                        (idx == 0
                          ? ` p-5 text-left font-semibold tracking-widerrounded-tl rounded-bl border-l-8  ${
                              index % 2 === 0
                                ? "border-F4C261"
                                : "border-primaryAccent"
                            }`
                          : " pl-4 px-1 py-1 text-left font-medium  tracking-wider rounded-br rounded-tr");
                      if (value == "id") {
                        return (
                          <td
                            className={cellStyle}
                            onClick={() => expandBatch(putaway.id)}>
                            {putaway.id}
                          </td>
                        );
                      }
                      if (value == "name") {
                        return (
                          <td className={cellStyle}>
                            {users &&
                              users.find(
                                (item) => item.id.toString() === putaway.user,
                              )?.name}
                          </td>
                        );
                      }
                      if (value == "assignedTime") {
                        return (
                          <td className={cellStyle}>
                            {moment(
                              putaway.assignedTime
                                ? putaway.assignedTime
                                : putaway.createdAt,
                            ).format("MMM Do YYYY, h:mm a")}
                          </td>
                        );
                      }
                      if (value == "firstCompletedTime") {
                        return (
                          <td className={cellStyle}>
                            {putaway.attributes?.firstCompletedTime &&
                              moment(
                                putaway.attributes.firstCompletedTime,
                              ).format("MMM Do YYYY, h:mm a")}
                          </td>
                        );
                      }
                      if (value == "lastCompletedTime") {
                        return (
                          <td className={cellStyle}>
                            {putaway.attributes?.lastCompletedTime &&
                              moment(
                                putaway.attributes.lastCompletedTime,
                              ).format("MMM Do YYYY, h:mm a")}
                          </td>
                        );
                      }
                      if (value == "quantity") {
                        return (
                          <td className={cellStyle}>{putaway.quantity}</td>
                        );
                      }
                      if (value == "completion") {
                        return (
                          <td className={cellStyle}>
                            {putaway.completion && `${putaway.completion}%`}
                          </td>
                        );
                      }
                      if (value == "status") {
                        return <td className={cellStyle}>{putaway.status}</td>;
                      }
                      if (value === "formFactor") {
                        return (
                          <td className={cellStyle}>{putaway.formFactor}</td>
                        );
                      }
                      if (value === "nestedFormFactor") {
                        return (
                          <td className={cellStyle}>
                            {putaway.nestedFormFactor}
                          </td>
                        );
                      }
                      if (value === "nestedFormFactorId") {
                        return (
                          <td className={cellStyle}>
                            {putaway.nestedFormFactorId}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
            </tbody>
          </CustomTableWithTheme>
        </div>
      )}
      {!stats && (
        <Pagination
          showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
          showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
          showingTotal={total}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
        />
      )}

      {expandedBatch && (
        <ExpandedBatch
          batchItems={expandedBatch.items}
          negativeAction={() => setExpandedBatch(null)}
          tableName={"putaway.orderDetails"}
          batch={expandedBatch}
          usersMap={usersMap}
        />
      )}
      {showFilters && (
        <BatchFilters
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          users={
            users
              ? users.filter(
                  (item) =>
                    item.hopstackModules &&
                    item.hopstackModules.includes("Putaway"),
                )
              : []
          }
          customers={customers}
          warehouses={warehouses}
        />
      )}
    </>
  );
};

export default withPutawaysLogic(PutawaysList);
