const Tooltip = ({ message }) => {
  return (
    <div class="relative group inline-blockcursor-pointer ml-2">
      <div class="rounded-full text-center align-middle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </div>
      <div class="absolute left-0 mt-1 w-48 p-2 bg-white border border-gray-300 text-sm text-gray-700 rounded-md shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out z-10">
        {message}
      </div>
    </div>
  );
};

export default Tooltip;
