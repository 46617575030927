import Modal from "#components/utils/Modal";

const ExpandedShippingAddress = ({ shippingAddress, negativeAction }) => {
  let address = shippingAddress.shippingAddress || {};
  return (
    <Modal
      negativeAction={negativeAction}
      title={`Shipping Address`}
      noPadding={true}
      positiveAction={negativeAction}
      positiveText="OK">
      <div className="p-4">
        <div className="text-xl font-montserrat p-4 font-bold pl-2">
          Name: {address.name}
        </div>
        <div className="text-xl font-montserrat p-4 font-bold pl-2">
          Email: {address.email || shippingAddress.email}
        </div>
        <div className="text-xl font-montserrat p-4 font-bold pl-2">
          Phone: {address.phone}
        </div>
        <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
          <thead className="p-4 rounded-full bg-primaryAccent px-12">
            <tr className="text-textWhite font-montserrat border-left px-12">
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Line 1
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Line 2
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                City
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                State
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Zip
              </th>
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                Country
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white">
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
                {address.line1}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {address.line2}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {address.city}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {address.state}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {address.zip}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {address.country}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ExpandedShippingAddress;

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};
