import Modal from "#components/utils/Modal";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const InventoryFilters = ({
  onSubmit,
  negativeAction,
  onChangeFilter,
  filters,
  customers,
  warehouses,
}) => (
  <Modal
    title={"Filters"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}
    initialFocus={null}>
    <div className="flex space-x-6 justify-between mt-4">
      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Warehouses
          </span>
        </label>
        <MultiSelectAutoComplete
          options={warehouses}
          labelKey="name"
          valueKey="id"
          setValues={(selectedOptions) =>
            onChangeFilter("warehouse", selectedOptions)
          }
          values={filters["warehouse"]}
          rounded={true}
        />
      </div>

      <div className="flex-1">
        <label className="block text-left mb-2">
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Clients
          </span>
        </label>
        <MultiSelectAutoComplete
          options={getFilteredCustomerList(filters["warehouse"], customers)}
          labelKey="name"
          valueKey="id"
          setValues={(selectedOptions) =>
            onChangeFilter("customer", selectedOptions)
          }
          values={filters["customer"]}
          rounded={true}
        />
      </div>
    </div>
  </Modal>
);

export default InventoryFilters;
