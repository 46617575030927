const ResolvementConfirmation = ({
  orders,
  partiallyFulfillableOrders,
  selectedOrderIdsForBatching,
}) => {
  const selectedOrders =
    orders?.filter((order) => selectedOrderIdsForBatching.includes(order.id)) ||
    [];

  const partiallyFulfillableOrderIds = partiallyFulfillableOrders.map(
    (backOrder) => backOrder.originalOrder.id,
  );

  return (
    <>
      <p className="text-base font-semibold py-2">Selected Orders</p>
      {/* table with order and status */}
      <table className="px-2 min-w-full divide-y divide-gray-200 border border-gray-300">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
              key={"orderId"}>
              Order ID
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
              key={"status"}>
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedOrders.map((order, index) => (
            <tr
              key={order.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {order.orderId}
              </td>
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <p className="border-2 border-black rounded-2xl p-1 text-base w-max">
                  {partiallyFulfillableOrderIds.includes(order.id)
                    ? "Partially Fulfillable"
                    : "Fulfillable"}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ResolvementConfirmation;
