import Modal from "#components/utils/Modal";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";

const CombinedOrdersForm = ({
  onChangeDropdown,
  onSubmit,
  negativeAction,
  selectedCombinedOrder,
}) => (
  <Modal
    title={"Combine Orders"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Combining Criteria
        </span>
      </div>

      <div>
        <Dropdown
          placeholder={"Select Field"}
          list={[
            { name: "Address" },
            { name: "Customer Name", disabled: true },
            { name: "Source", disabled: true },
            { name: "Vendor Name", disabled: true },
          ]}
          labelKey="name"
          valueKey="name"
          name="combinedCriteria"
          setSelected={(e) => {
            onChangeDropdown("combinedCriteria", e);
          }}
          selectedValue={selectedCombinedOrder.combinedCriteria}
        />
      </div>
    </div>
  </Modal>
);

export default CombinedOrdersForm;
