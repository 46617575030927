const Checkbox = ({
  role,
  label,
  checked,
  name,
  id,
  onChange,
  htmlFor,
  value,
  filled,
  disabled,
}) => (
  <div className="flex cursor-pointer items-center">
    <div className="flex items-center h-5">
      <input
        id={id}
        aria-describedby="offers-description"
        name={name}
        type={role || "checkbox"}
        checked={checked}
        className={`focus:ring-0 cursor-pointer h-6 w-6 text-2C7695 border-2C7695 ${
          role === "radio" ? "rounded-xl" : "rounded"
        } ${filled ? "bg-2C7695" : ""} ${disabled ? "bg-gray-400" : ""}`}
        onChange={disabled ? () => {} : onChange}
        value={value}
      />
    </div>
    {label && <div className="ml-2 text-sm">
      <label
        htmlFor={htmlFor || "offers"}
        className="font-medium text-gray-700 text-lg font-montserrat">
        {label}
      </label>
    </div>}
  </div>
);

export default Checkbox;
