import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import Toggle from "#components/utils/Toggle";

const BatchConfirmation = ({
  pickers,
  manualBatchConfig,
  setManualBatchConfig,
  stations,
  tenant,
  batchSettings,
  orders,
  fulfillableOrders,
  partiallyFulfillableOrders,
  selectedOrderIdsForBatching,
}) => {
  const selectedOrders =
    orders?.filter((order) => selectedOrderIdsForBatching.includes(order.id)) ||
    [];

  // orders returned from backend won't contain child orders for a back order.
  // In case of completely fulfilling the last child of a back order which will be in fulfillableOrders, we need to display it as well in the selected orders.
  const fulfillableBackOrders = fulfillableOrders.filter(
    ({ order }) => order.originalOrder,
  );
  if (fulfillableBackOrders.length > 0) {
    selectedOrders.push(...fulfillableBackOrders.map(({ order }) => order));
  }

  const partiallyFulfillableOrderIds = partiallyFulfillableOrders.map(
    (backOrder) => backOrder.originalOrder.id,
  );

  const showBatchSize =
    batchSettings &&
    batchSettings.pickingStrategy &&
    batchSettings.pickingStrategy !== "PICK_BY_ORDER";

  const showPreppingStation =
    selectedOrders.findIndex((order) => order.source === "FBA") !== -1;

  const showCombineOrders =
    tenant?.features?.combinedOrders === true &&
    selectedOrders.findIndex(
      (i) => i.customAttributes?.combinedOrder === true,
    ) !== -1;

  return (
    <>
      <p className="text-xl font-semibold py-2">Batch Details</p>
      <div className="flex gap-x-4">
        {showBatchSize && (
          <div className="w-179">
            <label className="block text-sm font-medium text-lightGray mb-1">
              Batch Size
            </label>
            <input
              type="number"
              id="batchSize"
              label="Batch Size"
              placeholder=""
              onChange={(e) =>
                setManualBatchConfig({
                  ...manualBatchConfig,
                  maxOrdersInBatch: isNaN(parseInt(e.target.value))
                    ? null
                    : parseInt(e.target.value),
                })
              }
              value={parseInt(manualBatchConfig.maxOrdersInBatch)}
              name="Batch Size"
              min="1"
              className="border border-borderGray rounded w-full p-2"
              autoFocus={false}
            />
          </div>
        )}

        <div className="w-179">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Picker
          </label>
          <AutocompleteDropdownV2
            options={pickers}
            labelKey="name"
            valueKey="id"
            onChange={(selectedOption) => {
              setManualBatchConfig({
                ...manualBatchConfig,
                picker: selectedOption,
              });
            }}
            value={manualBatchConfig.picker}
            placeholder=""
          />
        </div>

        {showPreppingStation && (
          <div className="w-179">
            <label className="block text-sm font-medium text-lightGray mb-1">
              Prepping Station
            </label>
            <AutocompleteDropdownV2
              options={stations.filter((i) => i.hopstackModule === "Prepping")}
              labelKey="name"
              valueKey="name"
              onChange={(selectedOption) => {
                setManualBatchConfig({
                  ...manualBatchConfig,
                  preppingStation: selectedOption,
                });
              }}
              value={manualBatchConfig.preppingStation}
              placeholder=""
            />
          </div>
        )}

        <div className="w-179">
          <label className="block text-sm font-medium text-lightGray mb-1">
            Packing Station
          </label>
          <AutocompleteDropdownV2
            options={stations.filter((i) => i.hopstackModule === "Packing")}
            labelKey="name"
            valueKey="name"
            onChange={(selectedOption) => {
              setManualBatchConfig({
                ...manualBatchConfig,
                packingStation: selectedOption,
              });
            }}
            value={manualBatchConfig.packingStation}
            placeholder=""
          />
        </div>
      </div>

      {showCombineOrders && (
        <div className="flex space-x-4 items-center py-4">
          <Toggle
            enabled={manualBatchConfig.combineOrders}
            setEnabled={(e) =>
              setManualBatchConfig({
                ...manualBatchConfig,
                combineOrders: e,
              })
            }
          />
          <div className="block text-sm font-medium text-lightGray">
            Combine Orders?
          </div>
        </div>
      )}

      <p className="text-base font-semibold py-2">Selected Orders</p>
      {/* table with order and status */}
      <table className="px-2 min-w-full divide-y divide-gray-200 border border-gray-300">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
              key={"orderId"}>
              Order ID
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
              key={"status"}>
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedOrders.map((order, index) => (
            <tr
              key={order.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {order.orderId}
              </td>
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <p className="border-2 border-black rounded-2xl p-1 text-base w-max">
                  {partiallyFulfillableOrderIds.includes(order.id)
                    ? "Partially Fulfillable"
                    : "Fulfillable"}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BatchConfirmation;
