import { useState } from "react";
import withPrepperLogic from "#components/HOC/withPrepperLogic";
import PickerBagCode from "#components/picker/common/PickerBagCode";
import PrepperBatch from "#components/prepper/PrepperBatch";
import MultipleSelectStationForm from "#components/common/MultipleSelectStationForm";
import SelectCustomerCode from "#components/picker/common/SelectCustomerCode";
import PickerNewBatchRequest from "#components/picker/common/PickerNewBatchRequest";
import FnSkuLabel2 from "#components/common/FnSkuLabel2";
import OrdersListView from "#components/prepper/OrdersListView";
import { Tab } from "@headlessui/react";
import Single30UpFnSkuLabel from "#components/common/Single30UpFnSkuLabel";
import { useHistory } from "react-router-dom";

const Prepper = ({
  currentBatch,
  scanBarcode,
  currentItemMismatch,
  setCurrentItemMismatch,
  confirmPrepItem,
  confirmDropoff,
  loading,
  currentUser,
  selectedStation,
  stations,
  logout,
  onSubmitStation,
  onSelectStation,
  printShippingLabel,
  simulateTote,
  simulateItemScan,
  weight,
  printTestFnSkus,
  getNewBatch,
  workflow,
  customer,
  setCustomer,
  customers,
  onSubmitCustomer,
  onSkipCustomer,
  order,
  printAllFnSkus,
  fnSkus,
  changeQuantity,
  subdomain,
  cancelItem,
  proceedToPacking,
  orders,
  checkOrderById,
  ordersData,
  filters,
  setFilters,
  checkPagination,
  submitFilters,
  restartActivity,
  pauseActivity,
  unprepItems,
  updateBestByDate,
  moveToPacker,
  currentProduct,
  onChangeMultiSelect,
  tenant,
}) => {
  const history = useHistory();
  if (moveToPacker) {
    history.push("/packer");
  }

  if (!currentUser || !currentUser.station) {
    return (
      <MultipleSelectStationForm
        title={"Please choose station."}
        selectedStation={selectedStation}
        onSubmit={onSubmitStation}
        stations={stations}
        onChangeDropdown={onSelectStation}
      />
    );
  }

  if (!loading && !currentBatch) {
    if (workflow) {
      if (
        workflow.entryPoints &&
        workflow.entryPoints.findIndex(
          (item) => item.name === "ENTER_CUSTOMER_CODE",
        ) !== -1
      ) {
        if (customer === undefined) {
          return (
            <SelectCustomerCode
              customers={customers}
              onSubmit={onSubmitCustomer}
              onSkip={onSkipCustomer}
            />
          );
        }
        return (
          <>
            {customer &&
              customers &&
              customers.findIndex((item) => item.id === customer) !== -1 && (
                <div className="text-2xl bg-black  left-0 w-full text-center text-white">
                  {customers.find((item) => item.id === customer).name}
                </div>
              )}

            <PickerNewBatchRequest
              getNewBatch={getNewBatch}
              secondaryOption={customer ? () => setCustomer(undefined) : null}
            />
          </>
        );
      }
    }

    const [selectedIndexTop, setSelectedIndexTop] = useState(0);
    const tabs = {
      Scan: (
        <>
          <PickerBagCode
            scanBarcode={scanBarcode}
            simulateTote={simulateTote}
            getNewBatch={getNewBatch}
            printTestFnSkus={printTestFnSkus}
            subdomain={subdomain}
            tabView={true}
          />
        </>
      ),
      Orders: (
        <OrdersListView
          orders={orders}
          checkOrderById={checkOrderById}
          filters={filters}
          setFilters={setFilters}
          submitFilters={submitFilters}
          ordersData={ordersData}
          checkPagination={checkPagination}
        />
      ),
    };
    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
    return (
      <>
        <div className="flex-col pt-4">
          <Tab.Group
            selectedIndex={selectedIndexTop}
            onChange={setSelectedIndexTop}>
            <Tab.List className="flex items-center justify-center">
              <div className=" p-2 space-x-2 rounded-full bg-primaryAccent">
                {tabs &&
                  Object.keys(tabs).map((tab) => (
                    <Tab
                      key={tab}
                      className={({ selected }) =>
                        classNames(
                          "px-6 py-2.5 leading-5 font-regular rounded-full",
                          "focus:outline-none focus:ring-0 font-montserrat text-lg",
                          selected
                            ? "bg-white text-primaryAccent font-medium"
                            : "border-primaryAccent bg-transparent border rounded-full text-white",
                        )
                      }>
                      {tab}
                    </Tab>
                  ))}
              </div>
            </Tab.List>
            <div className="block">
              <Tab.Panels className="mt-2">
                {tabs &&
                  Object.values(tabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="flex flex-col bg-transparent p-4 items-center">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
      </>
    );
  }
  if (currentBatch) {
    return (
      <>
        {customer &&
          customers &&
          customers.findIndex((item) => item.id === customer) !== -1 && (
            <div className="text-2xl bg-black left-0 w-full text-center text-white">
              {customers.find((item) => item.id === customer).name} -{" "}
              {order ? order.orderId : ""}
            </div>
          )}
        {(!workflow || subdomain === "tosprep") &&
          currentBatch &&
          currentBatch.currentItem && (
            <div className="fixed" style={{ marginTop: 2000 }}>
              {subdomain === "allpoints" ? (
                <Single30UpFnSkuLabel
                  id={currentBatch.currentItem.fnSku}
                  value={currentBatch.currentItem.fnSku}
                  text1={truncate(currentBatch.currentItem.productName)}
                  text2={
                    currentBatch.currentItem.bestByDate
                      ? `Best by: ${currentBatch.currentItem.bestByDate}`
                      : null
                  }
                />
              ) : (
                <>
                  <img
                    id={`label-placeholder-${currentBatch.currentItem.fnSku}`}
                    style={{ position: "fixed", top: 10000 }}
                  />
                  <FnSkuLabel2
                    id={currentBatch.currentItem.fnSku}
                    value={currentBatch.currentItem.fnSku}
                    text1={truncate(currentBatch.currentItem.productName)}
                    text2={
                      currentBatch.currentItem.bestByDate
                        ? `Best by: ${currentBatch.currentItem.bestByDate}`
                        : null
                    }
                  />
                </>
              )}
            </div>
          )}

        <PrepperBatch
          currentBatch={currentBatch}
          scanBarcode={scanBarcode}
          currentItemMismatch={currentItemMismatch}
          setCurrentItemMismatch={setCurrentItemMismatch}
          confirmPrepItem={confirmPrepItem}
          confirmDropoff={confirmDropoff}
          printShippingLabel={printShippingLabel}
          simulateItemScan={simulateItemScan}
          workflow={workflow}
          printAllFnSkus={printAllFnSkus}
          changeQuantity={changeQuantity}
          subdomain={subdomain}
          cancelItem={cancelItem}
          proceedToPacking={proceedToPacking}
          stations={stations}
          selectedStation={selectedStation}
          restartActivity={restartActivity}
          pauseActivity={pauseActivity}
          unprepItems={unprepItems}
          order={order}
          updateBestByDate={updateBestByDate}
          currentProduct={currentProduct}
          onChangeMultiSelect={onChangeMultiSelect}
          tenant={tenant}
        />
        {/* {fnSkus &&
          fnSkus.map((item, idx) => (
            <div className="z-50" style={{ marginTop: 1000 }} key={idx}>
              <ThirtyUpLabels values={item.values} id={item.id} />
            </div>
          ))} */}
        {fnSkus && (
          <div className="z-50" style={{ position: "fixed", marginTop: 1000 }}>
            {fnSkus.map((item, idx) => (
              <>
                <img
                  id={`label-placeholder-${item}`}
                  style={{ position: "fixed", top: 10000 }}
                  key={idx}
                />
                <FnSkuLabel2
                  id={item}
                  value={item}
                  key={idx}
                  text1={truncate(
                    currentBatch.workingList?.find((i) => i.fnSku == item)
                      ?.productName,
                  )}
                  text2={
                    currentBatch.workingList?.find((i) => i.fnSku == item)
                      ?.bestByDate
                      ? `Best by: ${
                          currentBatch.workingList?.find((i) => i.fnSku == item)
                            ?.bestByDate
                        }`
                      : null
                  }
                />
              </>
            ))}
          </div>
        )}
      </>
    );
  }
  return null;
};

const truncate = (str) => {
  return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
};

export default withPrepperLogic(Prepper);
