import PageTitle from "#components/utils/PageTitle";
import { QrcodeIcon, PlusIcon, MinusIcon } from "@heroicons/react/outline";
import _ from "lodash";
import QRCode from "react-qr-code";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

const DetailedView = ({
  currentItem,
  currentBatch,
  scanBarcode,
  decreaseItemQuantity,
  increaseItemQuantity,
  confirmPickItem,
}) => {
  const scannedBin = currentItem.scannedBin === true;
  const scannedSubTote = currentItem.scannedSubTote === true;

  return (
    <div className="min-w-max flex-col items-center justify-center max-w-4xl">
      <PageTitle>Product Details</PageTitle>
      <div className="text-xl font-medium text-454A4F space-y-2 ">
        <LineItemValue
          value={currentItem.binLocation}
          title="Bin Location"
          idx={2}
        />
        <LineItemValue
          value={currentItem.availableQuantity}
          title="System Count"
          idx={5}
        />
        <LineItemValue value={currentItem.sku} title="SKU" idx={3} />
        <LineItemValue value={currentItem.fnSku} title="FN SKU" idx={4} />
        <LineItemValue value={currentItem.asin} title="ASIN" idx={5} />{" "}
        <LineItemValue
          value={currentItem.sellerSku}
          title="Seller SKU"
          idx={6}
        />
        <LineItemValue
          value={currentItem.productName}
          title="Product Name"
          idx={4}
        />
      </div>
      <div
        onClick={() => {
          if (scannedBin) {
            scanBarcode(currentItem.sku);
          } else {
            scanBarcode(currentItem.binLocation);
          }
        }}>
        <div className="mt-4 flex items-center justify-center">
          <div
            className={`mt-4 rounded-full border-4 border-FC8862 p-5 shadow-md ${
              scannedBin && scannedSubTote && "bg-gray-400 border-gray-600"
            }`}>
            {" "}
            <QRCode
              value={qrcodeValue(
                scannedBin,
                scannedSubTote,
                currentBatch.orderSubTotes,
                currentItem.order,
                currentItem.binLocation,
              )}
              size="100"
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-center text-2xl text-454A4F">
          {scanItemMessage(
            scannedBin,
            scannedSubTote,
            currentBatch.orderSubTotes,
            currentItem.order,
          )}
        </div>
      </div>
      <div className="grid grid-cols-3 mt-10">
        <div className="bg-white shadow-md rounded-md items-center justify-center text-center py-10">
          <div className="flex items-center justify-center text-4xl text-2C7695">
            {currentItem.scannedSkus}
          </div>
          <div className="text-3xl text-5F666B py-2">Scanned Quantity</div>
        </div>
        <div
          className="bg-white shadow-md  items-center justify-center bg-454A4F flex items-center justify-center"
          onClick={decreaseItemQuantity}>
          <MinusIcon className="w-10 h-10 text-white" />
        </div>
        <div
          className="bg-white shadow-md rounded-tr-md rounded-br-md flex items-center justify-center bg-454A4F border-l border-white"
          onClick={increaseItemQuantity}>
          <PlusIcon className="w-10 h-10 text-white" />
        </div>
      </div>
      <div className="mt-20 flex items-center justify-center">
        {
          <div
            onClick={confirmPickItem}
            className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4">
            Confirm Item
          </div>
        }
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md">
      {buildMarketplaceHyperlink(value, title)}
    </div>
  </div>
);

const qrcodeValue = (
  scannedBin,
  scannedSubTote,
  orderSubTotes,
  id,
  binLocation,
) => {
  if (scannedBin) {
    return `NOVALUE`;
  }

  return binLocation;
};

const scanItemMessage = (scannedBin, scannedSubTote, orderSubTotes, id) => {
  if (scannedBin) {
    return `Scan SKUs`;
  }

  if (!scannedBin) {
    return "Scan Bin";
  }
};

export default DetailedView;
