import withShippersLogic from "#components/HOC/withShippersLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import ShippersList from "#components/shippers/ShippersList";
import ShipperForm from "#components/shippers/ShipperForm";
import { UserAddIcon } from "@heroicons/react/outline";
import _ from "lodash";

const Shippers = ({
  masterData,
  shippers,
  selectedShipper,
  setSelectedShipper,
  fetchShipper,
  saveShipper,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  devices,
  onChangeMultiSelect,
  warehouses,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
}) => {
  const headers = ["Name", "Warehouses", "Action"];
  return (
    <>
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-1">
            <PageTitle>Shipper Management</PageTitle>
          </div>
          <div className="items-center justify-end flex space-x-2 mb-4">
            <AddButton
              text="Add Shipper"
              onClick={() =>
                setSelectedShipper({
                  warehouses: warehouses.length > 0 ? [warehouses[0].id] : [],
                })
              }
              icon={UserAddIcon}
              disabled={!writable}
            />
          </div>
        </div>

        <ShippersList
          shippers={shippers}
          editButtonClicked={(e) => {
            fetchShipper(e.id);
          }}
          deleteButtonClicked={(e) => deleteButtonClicked(e)}
          masterData={masterData}
          headers={headers}
          noValuesText="No Shippers"
          warehouses={warehouses}
          writable={writable}
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setSort={setSort}
          sort={sort}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          clearFilters={clearFilters}
          onChangeFilter={onChangeFilter}
          total={total}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        {selectedShipper && (
          <ShipperForm
            title={selectedShipper.id ? "Edit Shipper" : "Add Shipper"}
            selectedShipper={selectedShipper}
            onChange={onChange}
            onClose={() => setSelectedShipper(null)}
            onSubmit={() => saveShipper(selectedShipper)}
            hopstackModules={masterData?.hopstackModules}
            onChangeDropdown={onChangeDropdown}
            devices={devices}
            onChangeMultiSelect={onChangeMultiSelect}
            warehouses={warehouses}
            shippers={shippers}
          />
        )}
      </div>
    </>
  );
};
export default withShippersLogic(Shippers);
