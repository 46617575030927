import moment from "moment-timezone";

const ReceivedAgeTableColumns = [
  {
    title: "SKU",
    dataIndex: "Productvariants.sku",
    key: "Productvariants.sku",
    render: (text) => <a>{text}</a>,
  },

  {
    title: "Last Received",
    dataIndex: "Productvariants.last_received",
    key: "Productvariants.last_received",
    render: (text) => (
      <a className="text-black">{text && moment(text).format("LL")}</a>
    ),
  },
  {
    title: "Last Putaway",
    dataIndex: "Productvariants.last_putaway",
    key: "Productvariants.last_putaway",
    render: (text) => (
      <a className="text-black">{text && moment(text).format("LL")}</a>
    ),
  },
  {
    title: "Received Age (in Days)",
    dataIndex: "Productvariants.doc_to_stock",
    key: "Productvariants.doc_to_stock",
    render: (text) => (
      <a className="text-black">
        {Math.ceil(parseInt(text) / (1000 * 3600 * 24))}
      </a>
    ),
  },
];

const ReceivedAgeQuery = {
  dimensions: [
    "Productvariants.sku",
    "Productvariants.last_received",
    "Productvariants.last_putaway",
    "Productvariants.doc_to_stock",
  ],
  timeDimensions: [],
  order: [["Productvariants.doc_to_stock", "desc"]],
};

const BinAgeingColumns = [
  {
    title: "Bin Location",
    dataIndex: "binLocation",
    key: "binLocation",
    render: (text) => <a className="text-blue-500 cursor-pointer">{text}</a>,
  },
  {
    title: "# of SKUs",
    dataIndex: "uniqueSkus",
    key: "uniqueSkus",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Avg. Bin Age",
    dataIndex: "averageBinAge",
    key: "averageBinAge",
    sorter: (a, b) => a.averageBinAge > b.averageBinAge,
    sortDirections: ["descend"],
    defaultSortOrder: "descend",
    render: (text) => <a>{parseInt(text)}</a>,
  },
  {
    title: "Latest Putaway",
    dataIndex: "latestPutaway",
    key: "latestPutaway",
    render: (text) => <a>{moment(text).format("ll")}</a>,
  },
  {
    title: "Oldest Sku Age",
    dataIndex: "oldestSkuAge",
    key: "oldestSkuAge",
    render: (text) => <a>{moment().diff(moment(text), "days")}</a>,
  },
];

//Custom filters for each query
const BinAgeingQuery = {
  dimensions: ["Skubinmappings.binlocation", "Skubinmappings.sku"],
  filters: [
    {
      member: "Skubinmappings.binlocationtype",
      operator: "equals",
      values: ["BIN"],
    },
  ],
};
export const makeDetailedStatQueries = (
  dateRange,
  customDate,
  binAgeCustomDate,
  tenantId,
  customers,
  warehouses,
) => {
  const appliedDate =
    dateRange === "Custom Date"
      ? { dateRange: [customDate.start, customDate.end] }
      : { dateRange };
  return [
    {
      title: "Doc To Stock (in Days)",
      statKey: "AVG_DOC_TO_STOCK",
      tableColumns: [
        {
          title: "SKU",
          dataIndex: "Productvariants.sku",
          key: "Productvariants.sku",
          render: (text) => <a>{text}</a>,
        },
        {
          title: "Last Received",
          dataIndex: "Productvariants.last_received",
          key: "Productvariants.last_received",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Last Putaway",
          dataIndex: "Productvariants.last_putaway",
          key: "Productvariants.last_putaway",
          render: (text) => (
            <a className="text-black">{text && moment(text).format("LL")}</a>
          ),
        },
        {
          title: "Doc to Stock (in Days)",
          dataIndex: "Productvariants.doc_to_stock",
          key: "Productvariants.doc_to_stock",
          render: (text) => <a className="text-black">{text}</a>,
        },
      ],
      query: {
        dimensions: [
          "Productvariants.sku",
          "Productvariants.last_received",
          "Productvariants.last_putaway",
        ],
        timeDimensions: [],
        order: [["Productvariants.doc_to_stock", "desc"]],
        filters: [
          {
            member: "Productvariants.doc_to_stock",
            operator: "set",
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "SKU Shelf time (in Days)",
      statKey: "AVG_SHELF",
      tableColumns: [...BinAgeingColumns],
      query: {
        ...BinAgeingQuery,
        filters: [
          {
            member: "Productvariants.shelf_time",
            operator: "gt",
            values: ["0"],
          },
          {
            member: "Productvariants.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Productvariants.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Productvariants.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Bin Age <7 Days",
      statKey: "BIN_AGE_LT_7",
      tableColumns: [...BinAgeingColumns],
      query: {
        ...BinAgeingQuery,
        measures: ["Skubinmappings.min_bin_less_than_7days"],
        filters: [
          {
            member: "Skubinmappings.binlocationtype",
            operator: "equals",
            values: ["BIN"],
          },
          {
            member: "Skubinmappings.min_bin_less_than_7days",
            operator: "set",
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Bin Age 7 - 15 Days",
      statKey: "BIN_AGE_7_15",
      tableColumns: [...BinAgeingColumns],
      query: {
        ...BinAgeingQuery,
        measures: ["Skubinmappings.min_bin_7_to_15days"],
        filters: [
          {
            member: "Skubinmappings.binlocationtype",
            operator: "equals",
            values: ["BIN"],
          },
          {
            member: "Skubinmappings.min_bin_7_to_15days",
            operator: "set",
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: `Bin Age ${binAgeCustomDate.start} to ${binAgeCustomDate.end}`,
      statKey: "BIN_AGE_CUSTOM",
      tableColumns: [
        {
          title: "Bin Location",
          dataIndex: "Skubinmappings.binlocation",
          key: "Skubinmappings.binlocation",
          render: (text) => (
            <a className="text-blue-500 cursor-pointer">{text}</a>
          ),
        },
        {
          title: "Average Bin Age",
          dataIndex: "Skubinmappings.avg_bin_age",
          key: "Skubinmappings.avg_bin_age",
          render: (text) => <a>{Math.round(parseInt(text))}</a>,
        },
      ],
      query: {
        measures: ["Skubinmappings.avg_bin_age"],
        timeDimensions: [],
        order: {
          "Skubinmappings.created_at": "asc",
        },
        dimensions: ["Skubinmappings.binlocation"],
        filters: [
          {
            member: "Skubinmappings.min_bin_age",
            operator: "gt",
            values: [Math.floor(new Date(binAgeCustomDate.start)).toString()],
          },
          {
            member: "Skubinmappings.min_bin_age",
            operator: "lt",
            values: [Math.floor(new Date(binAgeCustomDate.end)).toString()],
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Bin Age 15 - 30 Days",
      statKey: "BIN_AGE_15_30",
      tableColumns: [...BinAgeingColumns],
      query: {
        ...BinAgeingQuery,
        measures: ["Skubinmappings.min_bin_15_to_30days"],
        filters: [
          {
            member: "Skubinmappings.binlocationtype",
            operator: "equals",
            values: ["BIN"],
          },
          {
            member: "Skubinmappings.min_bin_15_to_30days",
            operator: "set",
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Bin Age 30 - 60 Days",
      statKey: "BIN_AGE_30_60",
      tableColumns: [...BinAgeingColumns],
      query: {
        ...BinAgeingQuery,
        measures: ["Skubinmappings.min_bin_30_to_60days"],
        filters: [
          {
            member: "Skubinmappings.binlocationtype",
            operator: "equals",
            values: ["BIN"],
          },
          {
            member: "Skubinmappings.min_bin_30_to_60days",
            operator: "set",
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
    {
      title: "Bin Age >60 Days",
      statKey: "BIN_AGE_GT_60",
      tableColumns: [...BinAgeingColumns],
      query: {
        ...BinAgeingQuery,
        measures: ["Skubinmappings.min_bin_greater_than_60days"],
        filters: [
          {
            member: "Skubinmappings.binlocationtype",
            operator: "equals",
            values: ["BIN"],
          },
          {
            member: "Skubinmappings.min_bin_greater_than_60days",
            operator: "set",
          },
          {
            member: "Skubinmappings.tenant",
            operator: "equals",
            values: [tenantId],
          },
          {
            member: "Skubinmappings.customer",
            operator: "equals",
            values: customers,
          },
          {
            member: "Skubinmappings.warehouse",
            operator: "equals",
            values: warehouses,
          },
        ],
      },
    },
  ];
};

const renderDate = (text) => (
  <a className="text-black">{moment().diff(moment(text), "days")}</a>
);
