// react imports
import { useState, useContext } from "react";
// icons import
import { PencilIcon } from "@heroicons/react/outline";
// contexts import
import { AppStateContext } from "#contexts/appState";
// components import
import ManageTags from "#components/catalogs/ManageTags";
import AddButton from "#components/utils/AddButton";
// other imports
import PropTypes from "prop-types";

/**
 * Renders a component for managing tags input.
 * @param {Object} props - The component props.
 * @param {Object} props.selectedProduct - The selected product.
 * @param {Function} props.setSelectedProduct - The function to set the selected product.
 */
const TagsInput = ({ selectedProduct, setSelectedProduct }) => {
  const appState = useContext(AppStateContext);

  const [manageTags, setManageTags] = useState(false);

  const handleManageTagClick = () => {
    if (!selectedProduct.customer) {
      appState.setAlert(`Please select Customer`);
      return setManageTags(false);
    }
    setManageTags(true);
  };

  return (
    <div>
      <div
        className="outline relative border-2 border-2C7695 rounded-lg overflow-visible w-full mb-5 cursor-pointer"
        onClick={handleManageTagClick}>
        <div className="flex justify-between items-center p-2">
          <label className="block text-left">
            <span className="font-medium font-montserrat text-primaryAccent text-lg p-2">
              Tags
            </span>
          </label>
          <div class="inline-flex h-10">
            <AddButton disabled={false} text="Manage Tags" icon={PencilIcon} />
          </div>
        </div>
        {selectedProduct?.tags?.length > 0 && (
          <div className="p-2">
            {selectedProduct.tags.map((tag) => (
              <div
                className="m-1 rounded-full bg-primaryAccent text-white inline-block"
                key={tag.id}>
                <div className="flex justify-between items-center h-8">
                  <div className="flex-2 pl-3 pr-5">{tag.name}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {manageTags && (
        <ManageTags
          writable={true}
          editable={false}
          deletable={false}
          selectable={true}
          negativeAction={() => setManageTags(false)}
          positiveText={"Add"}
          positiveAction={(selectedTags) => {
            setSelectedProduct({
              ...selectedProduct,
              tags: selectedTags,
            });
            setManageTags(false);
          }}
          selectedTags={selectedProduct.tags}
          selectedCustomers={[selectedProduct.customer]}
        />
      )}
    </div>
  );
};

TagsInput.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
};

export default TagsInput;
