import withStatsLogic from "#components/HOC/withStatsLogic";
import PickupsByTime from "#components/stats/PickupsByTime";
import AveragePickupDelay from "#components/stats/AveragePickupDelay";

const Stats = ({ stats, graphs }) => (
  <>
    <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-10">
      <div>
        <span className="text-gray-900 text-xl"># Pickups By Hour</span>
        <PickupsByTime
          data={graphs.hourlyPickups}
          currentTimezone={"America/New_York"}
        />
      </div>
      <div>
        <span className="text-gray-900 text-xl"># Delayed Pickups</span>
        <AveragePickupDelay
          data={graphs.averagePickupDelays}
          currentTimezone={"America/New_York"}
        />
      </div>
    </div>
    {/* <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-10">
  <div>
    <span className="text-gray-900 text-xl"># Deliveries</span>
    <ShipmentsByDate
      data={shipment.graphs.dailyShipments}
      currentTimezone={"America/New_York"}
    />
  </div>
  <div>
    <span className="text-gray-900 text-xl">On-Time Shipments %</span>
    <CarrierPickupsByDate
      data={shipment.graphs.onTimeShipmentsPercentage}
      currentTimezone={"America/New_York"}
    />
  </div>
</div> */}
  </>
);

export default withStatsLogic(Stats);
