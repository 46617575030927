import React from "react";
import withReportsLogic from "#components/HOC/withReportsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import ReportsList from "#components/reports/ReportsList";
import ReportForm from "#components/reports/ReportsForm";
import ReportPreview from "#components/reports/ReportPreview";
import { PrinterIcon, UserAddIcon } from "@heroicons/react/outline";
import Modal from "#components/utils/Modal";
import ModalButton from "#components/utils/ModalButton";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import CustomEntityForm from "#components/common/CustomEntityForm";
import { useEffect } from "react";

const Reports = ({
  previewData,
  setPreviewData,
  reportsMetadata,
  reports,
  selectedReport,
  setSelectedReport,
  fetchReport,
  saveReport,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  onChangeMultiSelect,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  addCustomEntity,
  setAddCustomEntity,
  onSubmitCustomEntity,
  writable,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  showFilters,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeDropdownMulti,
  queueReportGeneration,
  menuItems,
  fetchedReport,
}) => {
  const headers = [
    "Report Name",
    "Start Date",
    "End Date",
    "Report Type",
    "Status",
    "Warehouses",
    "Clients",
    "Actions",
  ];

  return (
    <>
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-1">
            <PageTitle>Reports</PageTitle>
          </div>
          <div className="items-center justify-end flex space-x-2 mb-4">
            <AddButton
              text="Add Report"
              onClick={() => {
                setSelectedReport({
                  selectedVariables: [],
                });
              }}
              icon={UserAddIcon}
              disabled={!writable}
            />
          </div>
        </div>
        <div className="w-96 flex space-x-4 mb-5">
          {warehouses.length > 1 && (
            <RoundedDropdown
              placeholder={"Warehouse"}
              list={warehouses}
              labelKey="name"
              valueKey="id"
              name="warehouse"
              selectedValue={selectedWarehouse}
              setSelected={setSelectedWarehouse}
            />
          )}
          {customers.length > 1 && (
            <RoundedDropdown
              placeholder={"Client"}
              list={customers}
              labelKey="name"
              valueKey="id"
              name="customer"
              selectedValue={selectedCustomer}
              setSelected={setSelectedCustomer}
            />
          )}
        </div>
        <ReportsList
          menuItems={menuItems}
          reports={reports}
          setSort={setSort}
          sort={sort}
          editButtonClicked={(report) => {
            setSelectedReport(report);
          }}
          deleteButtonClicked={(report) => deleteButtonClicked(report)}
          headers={headers}
          noValuesText="No Reports"
          customers={customers}
          warehouses={warehouses}
          writable={writable}
          onChangeSearchKeyword={onChangeSearchKeyword}
          filters={filters}
          submitFilters={submitFilters}
          clearKeyword={clearKeyword}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          clearFilters={clearFilters}
          onChangeFilter={onChangeFilter}
          total={total}
          pageNumber={pageNumber}
          checkPagination={checkPagination}
          perPage={perPage}
          setPerPage={setPerPage}
          queueReportGeneration={queueReportGeneration}
        />
        {selectedReport && (
          <ReportForm
            title={selectedReport.id ? "Edit Report" : "Add Report"}
            selectedReport={selectedReport}
            onChange={onChange}
            onClose={() => setSelectedReport(null)}
            onSubmit={() => saveReport(selectedReport)}
            onChangeDropdown={onChangeDropdown}
            onChangeMultiSelect={onChangeMultiSelect}
            customers={customers}
            warehouses={warehouses}
            reportsMetadata={reportsMetadata}
            onChangeDropdownMulti={onChangeDropdownMulti}
          />
        )}
        {previewData && (
          <ReportPreview
            title="Report Preview"
            previewData={previewData}
            onClose={() => setPreviewData(null)}
            onSubmit={() => setPreviewData(null)}
            fetchedReport={fetchedReport}
          />
        )}
        {addCustomEntity && (
          <CustomEntityForm
            addCustomEntity={addCustomEntity}
            setAddCustomEntity={setAddCustomEntity}
            onSubmit={onSubmitCustomEntity}
          />
        )}
      </div>
    </>
  );
};

export default withReportsLogic(Reports);
