import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import _ from "lodash";

import {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
} from "../../utils/Metrics";
const CurrentPackBoxes = ({
  currentBatch,
  groupedCasesBySku,
  setGroupedCasesBySku,
}) => {
  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  const preferredWeightUnit = useWeightUnitAbbreviation();
  const headers = [
    "SKU",
    "ASIN",
    "FNSKU",
    "# cases",
    `Length(${preferredDimensionUnit})`,
    `Width(${preferredDimensionUnit})`,
    `Height(${preferredDimensionUnit})`,
    `Weight(${preferredWeightUnit})`,
  ];
  return (
    <>
      <div className="text-2xl text-center p-2">
        This is a case-pack shipment
      </div>
      <div className="text-2xl text-center p-2">Confirm Cases</div>
      {Object.keys(groupedCasesBySku)?.length > 0 && (
        <>
          <div className="text-2xl text-center p-2">
            Number of cases:{" "}
            {_.flatten(Object.values(groupedCasesBySku)).length}
          </div>
          <div className="text-2xl text-center p-2">
            Total Weight :{" "}
            {_.sumBy(_.flatten(Object.values(groupedCasesBySku)), "weight") ||
              0}{" "}
            {preferredWeightUnit}
          </div>
        </>
      )}
      <div className="bg-white border rounded-2xl shadow-lg">
        <div className="flex items-center justify-center pt-5 pb-5">
          <div className="flex-col">
            <table className="min-w-full divide-y divide-gray-200 -mt-4 border-gray-400 border">
              <thead className="p-4 rounded-full">
                <tr className="text-primaryAccent font-montserratborder-left">
                  {headers.map((header, headerIdx) =>
                    headerIdx === 0 ? (
                      <th
                        scope="col"
                        className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                        key={headerIdx}>
                        {header}
                      </th>
                    ) : (
                      <th
                        scope="col"
                        className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                        key={headerIdx}>
                        {header}
                      </th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                {groupedCasesBySku &&
                  Object.keys(groupedCasesBySku)?.length > 0 &&
                  Object.keys(groupedCasesBySku).map((skuInCase, index) => (
                    <tr
                      key={skuInCase}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}>
                      <td
                        className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                          index % 2 === 0
                            ? "border-F4C261"
                            : "border-primaryAccent"
                        }`}>
                        {skuInCase}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        <span className="font-bold">
                          {buildMarketplaceHyperlink(
                            currentBatch?.workingList?.find(
                              (item) => item.sku === skuInCase,
                            )?.asin,
                            "ASIN",
                            "FBA",
                          )}
                        </span>
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {
                          currentBatch?.workingList?.find(
                            (item) => item.sku === skuInCase,
                          )?.fnSku
                        }
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {groupedCasesBySku[skuInCase].length}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        <input
                          type="number"
                          value={groupedCasesBySku[skuInCase][0].length}
                          onChange={(e) =>
                            setGroupedCasesBySku({
                              ...groupedCasesBySku,
                              [skuInCase]: groupedCasesBySku[skuInCase].map(
                                (item) => ({
                                  ...item,
                                  length:
                                    isNaN(parseFloat(e.target.value)) === false
                                      ? parseFloat(e.target.value)
                                      : null,
                                }),
                              ),
                            })
                          }
                          placeholder="Length"
                          min="0"
                        />
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        <input
                          type="number"
                          value={groupedCasesBySku[skuInCase][0].width}
                          onChange={(e) =>
                            setGroupedCasesBySku({
                              ...groupedCasesBySku,
                              [skuInCase]: groupedCasesBySku[skuInCase].map(
                                (item) => ({
                                  ...item,
                                  width:
                                    isNaN(parseFloat(e.target.value)) === false
                                      ? parseFloat(e.target.value)
                                      : null,
                                }),
                              ),
                            })
                          }
                          placeholder="Width"
                          min="0"
                        />
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        <input
                          type="number"
                          value={groupedCasesBySku[skuInCase][0].height}
                          onChange={(e) =>
                            setGroupedCasesBySku({
                              ...groupedCasesBySku,
                              [skuInCase]: groupedCasesBySku[skuInCase].map(
                                (item) => ({
                                  ...item,
                                  height:
                                    isNaN(parseFloat(e.target.value)) === false
                                      ? parseFloat(e.target.value)
                                      : null,
                                }),
                              ),
                            })
                          }
                          placeholder="Height"
                          min="0"
                        />
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        <input
                          type="number"
                          value={groupedCasesBySku[skuInCase][0].weight}
                          onChange={(e) =>
                            setGroupedCasesBySku({
                              ...groupedCasesBySku,
                              [skuInCase]: groupedCasesBySku[skuInCase].map(
                                (item) => ({
                                  ...item,
                                  weight:
                                    isNaN(parseFloat(e.target.value)) === false
                                      ? parseFloat(e.target.value)
                                      : null,
                                }),
                              ),
                            })
                          }
                          placeholder="Weight"
                          min="0"
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentPackBoxes;
