import {
  PencilIcon,
  TrashIcon,
  EyeIcon,
  DocumentSearchIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import SearchSortFilter from "#components/common/SearchSortFilter";
import _ from "lodash";
import QuickFilters from "#components/common/QuickFilters";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import StatCard from "../common/StatCard";

const UsersList = ({
  users,
  allUsers,
  isStatsLoading,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  customers,
  warehouses,
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  setShowFilters,
  clearFilters,
  onChangeFilter,
  resetPasswordButtonClicked,
  toggleSuspendUserButtonClicked,
  getAudit,
}) => {
  const getStatusChip = (activated, suspended) => {
    const chipStyle = "p-1 rounded-full m-auto text-center ";
    if (suspended)
      return (
        <div
          className={
            chipStyle +
            (suspended && "bg-suspendedTextColor/20 text-deactivatedTextColor")
          }>
          {"Suspended"}
        </div>
      );

    return (
      <div
        className={
          chipStyle +
          (activated
            ? "bg-activeTextColor/20 text-activeTextColor"
            : "bg-inActiveTextColor/20 text-inActiveTextColor")
        }>
        {activated ? "Active" : !suspended ? "Invited" : "Inactive"}
      </div>
    );
  };

  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-14">
      {(customers.length > 1 || warehouses.length > 1) && (
        <QuickFilters
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          customers={customers}
          warehouses={warehouses}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      )}

      {users && (
        <div className="grid grid-cols-4 gap-4">
          <StatCard
            title={"Total User Accounts"}
            stat={allUsers.length}
            loading={isStatsLoading}
          />
          <StatCard
            title={"Current Active Users"}
            stat={allUsers.filter((user) => user.activated).length}
            loading={isStatsLoading}
          />
          <StatCard
            title={"Suspended Users"}
            stat={allUsers.filter((user) => user.suspended).length}
            loading={isStatsLoading}
          />
          <StatCard
            title={"Invited Users"}
            stat={
              allUsers.filter((user) => !!!user.activated && !!!user.suspended)
                .length
            }
            loading={isStatsLoading}
          />
        </div>
      )}

      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Date",
            value: "createdAt",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 pl-4 py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    No Users Found.
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : (
            users.map((user, index) => {
              const menuItems = [
                {
                  title: "Reset Password",
                  icon: EyeIcon,
                  onClick: () => resetPasswordButtonClicked(user),
                },
                {
                  title: user.suspended ? "Unsuspend User" : "Suspend User",
                  icon: DocumentSearchIcon,
                  onClick: () =>
                    toggleSuspendUserButtonClicked({
                      ...user,
                      suspended: !user.suspended,
                    }),
                },
                {
                  title: "Edit User",
                  icon: PencilIcon,
                  onClick: () => editButtonClicked(user),
                },
                {
                  title: "Delete User",
                  icon: TrashIcon,
                  onClick: () => deleteButtonClicked(user.id),
                },
                {
                  title: "View Audit Trail",
                  icon: DocumentSearchIcon,
                  onClick: () => getAudit(user.id),
                },
              ];

              return (
                <tr
                  key={user.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-wider rounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {user.name}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                    {user.warehouses && user.warehouses.length > 0 ? (
                      <ExpandedMultipleItem
                        items={warehouses
                          .filter((warehouse) =>
                            user.warehouses.includes(warehouse.id),
                          )
                          .map((warehouse) => warehouse.name)}
                      />
                    ) : (
                      <ExpandedMultipleItem
                        items={warehouses.map((warehouse) => warehouse.name)}
                      />
                    )}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                    {user.customers && user.customers.length > 0 ? (
                      <ExpandedMultipleItem
                        items={customers
                          .filter((customer) =>
                            user.customers.includes(customer.id),
                          )
                          .map((customer) => customer.name)}
                      />
                    ) : (
                      <ExpandedMultipleItem
                        items={
                          user.warehouses && user.warehouses.length > 0
                            ? customers
                                .filter(
                                  (customer) =>
                                    customer.warehouses &&
                                    customer.warehouses.some((warehouse) =>
                                      user.warehouses.includes(warehouse),
                                    ),
                                )
                                .map((customer) => customer.name)
                            : customers.map((customer) => customer.name)
                        }
                      />
                    )}
                  </td>
                  <td className="pl-4 px-1 py-1 overflow-ellipsis text-left font-medium text-tableText tracking-wider">
                    {user.role}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                    <ExpandedMultipleItem items={user.hopstackModules} />
                  </td>
                  <td className="pl-4 mx-auto px-1 py-1 tracking-wider">
                    {getStatusChip(user.activated, user.suspended)}
                  </td>
                  <td className="pl-4 px-1 py-1 overflow-ellipsis text-left font-medium text-tableText tracking-wider">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-1 py-1 text-left font-medium text-tableText tracking-wider rounded-br rounded-tr">
                    <div className="flex min-w-max space-x-2 justify-center items-center">
                      <Menu as="div">
                        <Menu.Button>
                          <DotsVerticalIcon className="w-8 h-8 text-lg rounded font-montserrat p-1 font-bold bg-actionMenuBG " />
                        </Menu.Button>
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="">
                            {menuItems.map((menuItem, idx) => (
                              <Menu.Item key={idx}>
                                {() => (
                                  <button
                                    className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                                    onClick={menuItem.onClick}>
                                    {menuItem.icon && (
                                      <menuItem.icon className="w-8 h-8 pr-2" />
                                    )}
                                    {menuItem.title}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Menu>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default UsersList;
