import { useState } from "react";
import withBundlerLogic from "#components/HOC/withBundlerLogic";
import PickerBagCode from "#components/picker/common/PickerBagCode";
import BundlerBatch from "#components/bundler/BundlerBatch";
import PageHeader from "#components/bundler/PageHeader";
import BundlerListView from "#components/bundler/BundlerListView";
import BundlerNewBatchRequest from "#components/bundler/BundlerNewBatchRequest";
import { Tab } from "@headlessui/react";

const Bundler = ({
  selectedWarehouse,
  setSelectedWarehouse,
  setSelectedTote,
  bundlingTasks,
  childTask,
  deleteChildTask,
  selectedBundle,
  selectedBundleItem,
  scanBundle,
  scanBundleItem,
  confirmBundleItem,
  printBundlingLabels,
  confirmBundle,
  warehouses,
  customers,
  customer,
  onChangeScannedQuantity,
}) => {
  if (!selectedWarehouse) {
    return (
      <BundlerNewBatchRequest
        warehouses={warehouses}
        setSelectedWarehouse={setSelectedWarehouse}
      />
    );
  }

  if (childTask) {
    return (
      <>
        <PageHeader
          customer={customer}
          customers={customers}
          selectedBundle={selectedBundle}
        />
        <BundlerBatch
          childTask={childTask}
          selectedBundle={selectedBundle}
          selectedBundleItem={selectedBundleItem}
          scanBundleItem={scanBundleItem}
          confirmBundleItem={confirmBundleItem}
          confirmBundle={confirmBundle}
          printBundlingLabels={printBundlingLabels}
          onChangeScannedQuantity={onChangeScannedQuantity}
          skipBundle={deleteChildTask}
        />
      </>
    );
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(1);
  const tabs = {
    Scan: (
      <>
        <PickerBagCode
          scanBarcode={(code) => setSelectedTote(code)}
          tabView={true}
        />
      </>
    ),
    Tasks: (
      <BundlerListView
        bundlingTasks={bundlingTasks}
        scanBundle={scanBundle}
        warehouses={warehouses}
        customers={customers}
      />
    ),
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="flex-col pt-36">
        <Tab.Group
          selectedIndex={selectedTabIndex}
          onChange={setSelectedTabIndex}>
          <Tab.List className="flex items-center justify-center">
            <div className=" p-2 space-x-2 rounded-full bg-primaryAccent">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "px-6 py-2.5 leading-5 font-regular rounded-full",
                        "focus:outline-none focus:ring-0 font-montserrat text-lg",
                        selected
                          ? "bg-white text-primaryAccent font-medium"
                          : "border-primaryAccent bg-transparent border rounded-full text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col bg-transparent p-4 items-center">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default withBundlerLogic(Bundler);
