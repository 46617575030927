import PageTitle from "#components/utils/PageTitle";
import TextField from "#components/utils/TextField";
import withBillingProfileLogic from "#components/HOC/withBillingProfileLogic";
import BillingProfilesList from "#components/billingProfiles/BillingProfilesList";
import BillingProfileSummary from "#components/billingProfiles/BillingProfileSummary";
import BillingProfileFormWizard from "#components/billingProfiles/BillingProfileFormWizard";
import {
  ArrowLeftIcon,
  DotsVerticalIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/outline";

import { useEffect, useState } from "react";
import SlideOverPanel from "../components/common/SlideOverPanel";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "../constants/feature-flags";

export const BillingStatus = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
  INACTIVE: "INACTIVE",
};

const BillingProfiles = ({
  billingProfiles,
  billingProfileFormConfig,
  selectedProfile,
  setSelectedProfile,
  fetchBillingProfiles,
  saveBillingProfile,
  setSort,
  sort,
  subdomain,
  perPage,
  fetchAudits,
  setPerPage,
  pageNumber,
  selectedAudit,
  setSelectedAudit,
  total,
  checkPagination,
  role,
}) => {

  if (!role || role.toLowerCase() !== "admin") {
    return (
      <div className="flex items-center justify-center text-2xl h-full mt-4">
        Coming Soon! Keep watching this space.
      </div>
    );
  }
  const ldClient = useLDClient();
  const billingModuleEnabled =
    ldClient?.variation(featureFlags.BILLING_MODULE_ENABLED, false) ?? false;

  if (!billingModuleEnabled)
    return (
      <div className="flex items-center justify-center text-2xl h-full mt-4">
        Coming Soon! Keep watching this space.
      </div>
    );

  return (
    <div className="p-4 bg-white">
      {selectedProfile ? (
        <BillingProfileSummary
          saveBillingProfile={saveBillingProfile}
          setSelectedProfile={setSelectedProfile}
          billingProfileFormConfig={billingProfileFormConfig}
          billingProfile={selectedProfile}
          setBillingProfile={setSelectedProfile}
        />
      ) : (
        <BillingProfilesList
          selectedAudit={selectedAudit}
          setSelectedAudit={setSelectedAudit}
          fetchAudits={fetchAudits}
          perPage={perPage}
          setPerPage={setPerPage}
          pageNumber={pageNumber}
          setSort={setSort}
          sort={sort}
          total={total}
          checkPagination={checkPagination}
          fetchBillingProfiles={fetchBillingProfiles}
          billingProfiles={billingProfiles}
          setSelectedProfile={setSelectedProfile}
        />
      )}
    </div>
  );
};

export default withBillingProfileLogic(BillingProfiles);
