import withStockTransferLogic from "#components/HOC/withStockTransferLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import StockTransferList from "#components/stockTransfer/StockTransferList";
import StockTransferForm from "#components/stockTransfer/StockTransferForm";
import { UserAddIcon } from "@heroicons/react/outline";
import ExpandedStockTransfer from "../components/stockTransfer/ExpandedStockTransfer";

// We are changing Stock Transfer text (under Inventory Menu) to Bin to Bin Transfer
// Because we have added new feature called Stock Transfer Order under Order Management
// and to remove the confusion between these two 

const StockTransfer = ({
  stockTransfers,
  warehouses,
  selectedStockTransfer,
  setSelectedStockTransfer,
  fetchStockTransfer,
  saveStockTransfer,
  deleteButtonClicked,
  executePlanClicked,
  planToActivate,
  setPlanToActivate,
  executePlan,
  users,
  onChangeDropdown,
  addItems,
  onChangeItem,
  removeItem,
  expandedStockTransfer,
  setExpandedStockTransfer,
  setShowExpand,
  showExpand,
  availableInventory,
  onChangeInventorySearch,
  binLocations,
  onChangeBinLocationSearch,
  skuBinLocations,
  onChangeSkuBinLocationSearch,
}) => {
  return (
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-1">
          <PageTitle>Bin to Bin Transfer</PageTitle>
        </div>

        <div className="items-center justify-end flex space-x-2 mb-4">
          <AddButton
            text="Create Bin to Bin Transfer"
            onClick={() => setSelectedStockTransfer({})}
            icon={UserAddIcon}
          />
        </div>
      </div>

      <StockTransferList
        stockTransfers={stockTransfers}
        editButtonClicked={(e) => {
          fetchStockTransfer(e.id);
          setShowExpand(false);
        }}
        deleteButtonClicked={(e) => deleteButtonClicked(e)}
        headers={["SKU", "Date Created", "Status", "Action"]}
        noValuesText="No Bin to Bin Transfers"
        warehouses={warehouses}
        executePlanClicked={executePlanClicked}
        executePlan={() => executePlan(planToActivate)}
        expandStockTransfer={(id) => {
          setShowExpand(true);
          fetchStockTransfer(id);
        }}
        setPlanToActivate={setPlanToActivate}
        planToActivate={planToActivate}
      />

      {!showExpand && selectedStockTransfer && (
        <StockTransferForm
          title={
            selectedStockTransfer.id
              ? "Edit Bin to Bin Transfer"
              : "Create Bin to Bin Transfer"
          }
          removeItem={removeItem}
          addItems={addItems}
          onChangeItem={onChangeItem}
          onChangeDropdown={onChangeDropdown}
          warehouses={warehouses}
          users={users}
          selectedStockTransfer={selectedStockTransfer}
          onClose={() => setSelectedStockTransfer()}
          onSubmit={saveStockTransfer}
          onChangeInventorySearch={onChangeInventorySearch}
          availableInventory={availableInventory}
          binLocations={binLocations}
          onChangeBinLocationSearch={onChangeBinLocationSearch}
          skuBinLocations={skuBinLocations}
          onChangeSkuBinLocationSearch={onChangeSkuBinLocationSearch}
        />
      )}

      {showExpand && expandedStockTransfer && (
        <ExpandedStockTransfer
          stockTransfer={expandedStockTransfer}
          warehouses={warehouses}
          users={users}
          negativeAction={() => setExpandedStockTransfer(null)}
          setShowExpand={setShowExpand}
        />
      )}
    </div>
  );
};

export default withStockTransferLogic(StockTransfer);
