// components imports
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
// other imports
import PropTypes from "prop-types";

/**
 * TagForm component for saving/updating tag data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.tagFormData - The tag form data.
 * @param {boolean} props.tagBeingEdited - Boolean specifying if the tag being edited.
 * @param {Array<Object>} props.customers - The array of customer objects.
 * @param {Function} props.setTagFormData - The function to set the tag form data.
 * @param {Function} props.saveTag - The function to save the tag.
 * @param {Function} props.updateTag - The function to update the tag.
 * @returns {JSX.Element} - The rendered component.
 */
const TagForm = ({
  tagFormData,
  tagBeingEdited,
  customers,
  setTagFormData,
  saveTag,
  updateTag,
}) => {
  return (
    <Modal
      title={`${tagBeingEdited ? "Edit" : "Create"} Tag`}
      negativeAction={() => {
        setTagFormData(null);
      }}
      positiveAction={() => {
        if (tagBeingEdited) updateTag();
        else saveTag();
      }}
      positiveText={tagBeingEdited ? "Edit" : "Create"}>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={(e) =>
            setTagFormData({ ...tagFormData, name: e.target.value })
          }
          value={tagFormData.name}
        />
        {customers.length > 1 ? (
          <div id="selectingStations">
            <label className="block text-left my-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Select Clients
              </span>
            </label>
            <MultiSelectAutoComplete
              options={customers}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOptions) =>
                setTagFormData({
                  ...tagFormData,
                  customers: selectedOptions,
                })
              }
              values={tagFormData?.customers ?? []}
            />
          </div>
        ) : (
          <div>
            <Dropdown
              placeholder={"Select Client"}
              list={customers}
              labelKey="name"
              valueKey="id"
              name="customer"
              setSelected={(selectedOption) => {
                setTagFormData({
                  ...tagFormData,
                  customers: [selectedOption],
                });
              }}
              selectedValue={tagFormData.customers[0]}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

TagForm.propTypes = {
  tagFormData: PropTypes.object.isRequired,
  tagBeingEdited: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  setTagFormData: PropTypes.func.isRequired,
  saveTag: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
};

export default TagForm;
