const arr = [24, 64, 96, 108];

const ProgressBar = ({ percent }) => {
  return (
    <div className="bg-EBEBEB w-11/12 align-center ml-6 absolute rounded-md h-2 my-2">
      <div
        className={`w-${arr[percent]} h-2 bg-primaryAccent rounded-md  duration-1000`}></div>
    </div>
  );
};

export default ProgressBar;
