import { LocationCapacityUnitsEnum } from "../../constants/location-capacity";

const randomId = () => Math.random().toString(36).substring(2, 15);

class LocationTree {
  constructor() {
    this.root = new LocationTreeNode("root", "root", 0, "root");
    this.hierarchyLastChecked = new Date();
    this.hierarchy = [];
  }
  isEmpty = () => {
    return this.root.children.length === 0;
  };
  addChildren = (parent, children) => {
    parent.children = parent.children.concat(children);
  };
  dfsTraverse = (callback) => {
    const ret = [];
    const traverse = (node) => {
      if (node.depth !== 0) {
        const returnValue = callback(node);
        ret.push(returnValue);
      }
      for (const child of node.children) {
        traverse(child);
      }
    };
    traverse(this.root);
    return ret;
  };
  dfsTraverseAsync = async (callback) => {
    const ret = [];
    const traverse = async (node) => {
      if (node.depth !== 0) {
        const returnValue = await callback(node);
        ret.push(returnValue);
      }
      for (const child of node.children) {
        await traverse(child);
      }
    };
    await traverse(this.root);
    return ret;
  };
  getHierarchyArray = () => {
    const hierarchy = [];
    const depthVisited = {};
    this.dfsTraverse((node) => {
      if (!depthVisited[node.depth] && node.depth !== 0) {
        hierarchy.push(node.type);
        depthVisited[node.depth] = true;
      }
    });
    this.hierarchy = hierarchy;
    return hierarchy;
  };
}

class LocationTreeNode {
  constructor(name, code, capacity, type, parent) {
    this.id = randomId();
    this.children = []; // LocationTreeNode[]
    this.name = name || "";
    this.code = code || "";
    this.capacity = capacity || {
      amount: 0,
      unit: LocationCapacityUnitsEnum.CBM,
    };
    this.type = type || "";
    this.parent = parent || null;
    this.depth = parent ? parent.depth + 1 : 0;
    this.backendId = null;
  }

  ensureUniformHierarchyAcrossTree = (newChildType) => {
    if (this.parent) {
      const parent = this.parent;
      const siblings = [];
      for (const sibling of parent.children) {
        if (sibling.id !== this.id) {
          siblings.push(sibling);
        }
      }
      for (const sibling of siblings) {
        if (sibling.children.length > 0) {
          if (sibling.children[0].type !== newChildType) {
            return sibling.children[0].type;
          }
        }
      }
    }
    return null;
  };

  clearChildren = () => {
    this.children = [];
  };

  setChildren = (children, locationTypes) => {
    this.addChildren(children, locationTypes, true);
  };

  addChildren = (children, locationTypes, clearExisting = false) => {
    // validate hierarchy
    // get type of all new children. ensure all new children are of the same type
    const newChildrenType = children[0].type;
    for (const child of children) {
      if (child.type !== newChildrenType) {
        throw new Error("All new locations must be of the same type");
      }
    }
    const expectedSiblingType =
      this.ensureUniformHierarchyAcrossTree(newChildrenType);
    if (expectedSiblingType) {
      throw new Error(
        `All children of ${this.code} must be of type ${
          locationTypes.find((t) => t.id === expectedSiblingType).name
        }`,
      );
    }
    if (this.children.length !== 0) {
      // ensure all new children are of the same type as the existing children
      const existingChildrenType = this.children[0].type;
      if (newChildrenType !== existingChildrenType && !clearExisting) {
        throw new Error(
          `All children of ${this.code} must be of type ${
            locationTypes.find((t) => t.id === existingChildrenType).name
          }`,
        );
      }
    }
    if (clearExisting) {
      this.clearChildren();
    }
    this.children = this.children.concat(children);
  };
}

export { LocationTree, LocationTreeNode };
