import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * A modal component for displaying images.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be displayed within the modal.
 * @param {boolean} [props.noPadding] - Determines whether to apply padding to the modal content. Default is false.
 * @param {function} [props.onClose] - The function to be called when the modal is closed.
 * @param {string} [props.minWidth] - The minimum width of the modal.
 * @param {string} [props.maxWidth] - The maximum width of the modal.
 * @param {string} [props.minHeight] - The minimum height of the modal.
 * @param {string} [props.maxHeight] - The maximum height of the modal.
 * @param {boolean} [props.scrollWithin] - Determines whether the modal content should have internal scrolling. Default is false.
 * @param {function} [props.xIconClicked] - The function to be called when the X icon is clicked.
 * @returns {JSX.Element} The rendered MinimalModal component.
 */
const MinimalModal = ({
  children,
  noPadding,
  onClose,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  scrollWithin = false,
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (scrollWithin && contentRef.current) {
      // Wait for the next frame to set the scroll position
      requestAnimationFrame(() => {
        contentRef.current.scrollTop = 0;
      });
    }
  }, []);

  const getClassesForChildren = () => {
    const classNames = [];
    if (!noPadding) classNames.push("mx-4 py-4");
    if (scrollWithin) classNames.push("overflow-y-auto");
    return classNames.join(" ").trim();
  };

  const getStylePropsForChildren = () => {
    const styles = {};
    if (scrollWithin) {
      // Set a max height for the content area to enable internal scrolling
      styles.maxHeight = "calc(100vh - 12rem)"; // Adjust the value as needed
      styles.overflowY = "auto";
    }
    if (minHeight) styles.minHeight = minHeight;
    if (maxHeight) styles.maxHeight = maxHeight;
    return styles;
  };

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto userFormModal"
          onClose={onClose}
          initialFocus={null}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <div className="flex items-center justify-center min-h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div
                  className={`inline-block mx-auto overflow-visible text-left align-middle transition-all transform bg-white text-black shadow-xl `}
                  style={{ minWidth, maxWidth }}>
                  <div
                    ref={contentRef}
                    className={getClassesForChildren()}
                    style={getStylePropsForChildren()}>
                    {children}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

MinimalModal.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  onClose: PropTypes.func,
  percent: PropTypes.number,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  scrollWithin: PropTypes.bool,
};

export default MinimalModal;
