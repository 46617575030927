import moment from "moment-timezone";
import { GET_ALL_BATCHES } from "#queries";
import { useEffect, useState } from "react";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import DetailedProductivityReport from "./DetailedProductivityReport";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const CustomPutawaysStats = ({
  stats,
  productivityStats,
  setSelectedAssociate,
  selectedAssociate,
  onChangeFilter,
  submitFilters,
  filters,
  fetchBatchesForStats,
  fetchBatchesForProductivityStats,
}) => {
  console.log(stats);
  const [item, setItem] = useState(0);
  useEffect(() => {
    if (productivityStats[item]) {
      setSelectedAssociate(productivityStats[item]);
    }
  }, [productivityStats]);
  return (
    <>
      <>
        <div className="tableWrapper">
          {selectedAssociate && (
            <DetailedProductivityReport
              selectedAssociate={selectedAssociate}
              setSelectedAssociate={setSelectedAssociate}
              onChangeFilter={onChangeFilter}
              submitFilters={submitFilters}
              filters={filters}
              fetchBatchesForStats={fetchBatchesForStats}
              fetchBatchesForProductivityStats={
                fetchBatchesForProductivityStats
              }
            />
          )}
          <CustomTableWithTheme className="min-w-full divide-y divide-gray-200 whitespace-nowrap">
            <thead className="p-4 bg-primaryAccent rounded-full">
              <tr className="text-textWhite font-montserrat border-left">
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={0}>
                  Associate
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={0}>
                  Items Received
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={0}>
                  First Batch Assigned At
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={0}>
                  Last Batch Completed At
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={0}>
                  Active Putaway Time
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={0}>
                  Time per Putaway
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={0}>
                  Putaways Per Hour
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(stats)
                .sort()
                .map((item, idx) => (
                  <tr
                    key={idx}
                    className={`${idx % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                    <td
                      className={`p-5 text-left hover:underline font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 cursor-pointer  ${
                        idx % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                      }`}
                      onClick={() => {
                        setSelectedAssociate(productivityStats[item]);
                        setItem(item);
                      }}>
                      {stats[item].name}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {stats[item].itemsProcessed}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {stats[item].firstBatchAssignedAt}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {stats[item].lastBatchAssignedAt}
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {stats[item].activeMinutes} mins
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {stats[item].timePerItem} seconds
                    </td>

                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                      {stats[item].itemsPerHour}
                    </td>
                  </tr>
                ))}
            </tbody>
          </CustomTableWithTheme>
          <div className="mt-10"></div>
        </div>
      </>
    </>
  );
};

export default CustomPutawaysStats;
