import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import {
  GET_CURRENT_INWARD_ITEM,
  GET_INVENTORY,
  GET_STATIONS,
  GET_CONSIGNMENTS,
  GET_CONSIGNMENT,
  FETCH_SPECIFIC_ORDER,
  GET_SKU,
} from "#queries";
import {
  SCAN_INWARD_RECEIVER_BARCODE,
  CONFIRM_INWARD_ITEM,
  IMMEDIATE_SCAN,
  CHECK_FOR_CONSIGNMENT,
  ADD_LPN_TO_PRODUCT,
  GENERATE_LPN,
  LOGOUT_USER,
  CANCEL_RECEIVING_ITEM,
  PRINT_REQUEST,
  SET_STATION,
  SUBMIT_NUMBER_OF_CARTONS,
  LOG_LABEL_PRINT,
} from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import io from "socket.io-client";
import { printCanvasLabel } from "#utils/printCanvasLabel";
import { printShippingLabel } from "#utils/printShippingLabel";
import { printCanvasLabelRemote } from "#utils/printCanvasLabelRemote";
import { printCanvasLabelPdf } from "#utils/printCanvasLabelPdf";
import { printBol } from "#utils/printBol";
import { TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM } from "../../constants/printer-settings";
import { handleFnSkuPrinting } from "./withPrepperLogic";

const ALERT_TIMEOUT_IN_MS = 5000;

const withInwardReceiverLogic = (WrappedComponent) => {
  return () => {
    const history = useHistory();
    const location = useLocation();

    const [bolDoc, setBolDoc] = useState(null);
    const [socket, setSocket] = useState(null);
    const consignmentsQuery = useQuery(GET_CONSIGNMENTS);
    const getConsignmentQuery = useQuery(GET_CONSIGNMENT);
    const getOrderForBolPrint = useQuery(FETCH_SPECIFIC_ORDER);
    const [codeToPrint, setCodeToPrint] = useState(null);
    const [consignments, setConsignments] = useState([]);
    const [generatedLPN, setGeneratedLPN] = useState(null);
    const [printedCodes, setPrintedCodes] = useState(null);
    const [inventorySearchKeyword, setInventorySearchKeyword] = useState("");
    const [addLpnToProduct, setAddLpnToProduct] = useState(null);
    const [generateLpn, setGenerateLpn] = useState(null);
    const [availableInventory, setAvailableInventory] = useState([]);
    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const [trackingNumber, setTrackingNumber] = useState(undefined);
    const [currentScan, setCurrentScan] = useState(null);
    const [showScanner, setShowScanner] = useState(false);
    const [currentSku, setCurrentSku] = useState(null);
    const confirmItemQuery = useQuery(CONFIRM_INWARD_ITEM);
    const currentSkuQuery = useQuery(GET_CURRENT_INWARD_ITEM);
    const scanBarcodeQuery = useQuery(SCAN_INWARD_RECEIVER_BARCODE);
    const immediateScanQuery = useQuery(IMMEDIATE_SCAN);
    const checkForConsignmentQuery = useQuery(CHECK_FOR_CONSIGNMENT);
    const cancelItemQuery = useQuery(CANCEL_RECEIVING_ITEM);
    const inventoryQuery = useQuery(GET_INVENTORY);
    const addLpnQuery = useQuery(ADD_LPN_TO_PRODUCT);
    const generateLpnQuery = useQuery(GENERATE_LPN);
    const logoutQuery = useQuery(LOGOUT_USER);
    const logLabelPrintQuery = useQuery(LOG_LABEL_PRINT);

    const stationsQuery = useQuery(GET_STATIONS);
    const printRequestQuery = useQuery(PRINT_REQUEST);
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const setStationQuery = useQuery(SET_STATION);
    const submitNumberOfCartonsQuery = useQuery(SUBMIT_NUMBER_OF_CARTONS);
    const [consignmentsData, setConsignmentsData] = useState({});
    const [specificConsignment, setSpecificConsignment] = useState(null);
    const [currentItems, setCurrentItems] = useState(null);
    const getCurrentProductQuery = useQuery(GET_SKU);
    const [currentProduct, setCurrentProduct] = useState(null);
    const statusList = [
      {
        label: "Pending Orders",
        value: "pending",
        filterValue: ["RECEIVING_STARTED", "UNPROCESSED"],
      },
      {
        label: "Completed Orders",
        value: "completed",
        filterValue: ["COMPLETED"],
      },
      {
        label: "All Orders",
        value: "all",
        filterValue: ["RECEIVING_STARTED", "UNPROCESSED", "COMPLETED"],
      },
    ];

    useEffect(() => {
      setQueryParamsToCurrentScan();
    }, [location]);

    const setQueryParamsToCurrentScan = (newScan = false) => {
      // Parse the query parameters
      const queryParams = new URLSearchParams(location.search);
      const selectedCustomer = queryParams.get("customer");
      const selectedStation = queryParams.get("station");

      if (currentScan && !newScan) {
        setCurrentScan({
          ...currentScan,
          customer: selectedCustomer,
          station: selectedStation,
        });
      } else {
        setCurrentScan({
          customer: selectedCustomer,
          station: selectedStation,
        });
      }

      if (selectedStation) {
        setSelectedStation(selectedStation);
        setStationQuery.fetchData({ station: selectedStation });
      }
    };

    useEffect(() => {
      if (currentScan && currentScan.customer) {
        const filters = {
          customers: [currentScan.customer],
          status: consignmentsData.filters?.status || statusList[0].filterValue,
        };
        setConsignmentsData({
          ...consignmentsData,
          pageNumber: 1,
          perPage: 25,
          sort: "-status",
          filters,
        });
        consignmentsQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters,
          sort: "-status",
        });
      }
    }, [currentScan?.customer, consignmentsData.filters?.status]);

    useEffect(() => {
      if (getCurrentProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCurrentProductQuery.data) {
        setCurrentProduct(getCurrentProductQuery.data.specificInventory);
        setCurrentSku({
          ...currentSku,
          formFactor:
            getCurrentProductQuery.data.specificInventory.baseUom ||
            currentSku.formFactor,
        });
      }

      if (getCurrentProductQuery.error) {
        setCurrentProduct(null);
      }
    }, [
      getCurrentProductQuery.loading,
      getCurrentProductQuery.data,
      getCurrentProductQuery.error,
    ]);

    useEffect(() => {
      if (getConsignmentQuery.data) {
        setSpecificConsignment(getConsignmentQuery.data.consignment);
        if (getConsignmentQuery.data.consignment.status === "COMPLETED") {
          setQueryParamsToCurrentScan(true);
          setSpecificConsignment(null);
        }
      }

      if (getConsignmentQuery.error) {
        setSpecificConsignment(null);
        appState.setAlert(
          getConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (getConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getConsignmentQuery.data,
      getConsignmentQuery.loading,
      getConsignmentQuery.error,
    ]);

    useEffect(() => {
      if (logoutQuery.data) {
        appState.setAlert(logoutQuery.data.logout.message);
        auth.logout();
      }

      if (logoutQuery.error) {
        auth.logout();
      }
    }, [logoutQuery.data, logoutQuery.loading, logoutQuery.error]);

    useEffect(() => {
      if (currentScan?.consignmentId) {
        getConsignmentQuery.fetchData({
          id: currentScan.consignmentId,
        });
      } else {
        setSpecificConsignment(null);
      }
    }, [currentScan?.consignmentId]);

    useEffect(() => {
      if (currentSku?.consignmentIds) {
        consignmentsQuery.fetchData({
          perPage: 25,
          pageNumber: 1,
          filters: {
            ids: currentSku.consignmentIds,
          },
        });
      }
    }, [currentSku?.consignmentIds]);

    useEffect(() => {
      if (consignmentsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (consignmentsQuery.data) {
        setConsignmentsData({
          ...consignmentsData,
          consignments: consignmentsQuery.data.consignments.entities,
          total: consignmentsQuery.data.consignments.total,
        });
        setConsignments(consignmentsQuery.data.consignments.entities);
      }

      if (consignmentsQuery.error) {
        setConsignmentsData({});
        setConsignments([]);
      }
    }, [
      consignmentsQuery.data,
      consignmentsQuery.loading,
      consignmentsQuery.error,
    ]);

    useEffect(() => {
      if (auth?.user?.id && !socket) {
        setSocket(
          io(localStorage.socketService, {
            path: "/socket-service/socket.io",
          }),
        );

        // socket.on("printMessage", (payload) => {
        //   appState.setAlert(`Printing`, "success", ALERT_TIMEOUT_IN_MS);
        //   setCodeToPrint(payload.codes);
        //   setTimeout(() => {
        //     for (const code of payload.codes) {
        //       printCanvasLabel(`${code}_4x6`, 1);
        //     }
        //   }, 1000);
        // });
      }
    }, [auth?.user]);

    useEffect(() => {
      if (socket) {
        console.log(socket);
        socket.on("LPN", (payload) => {
          appState.setAlert(`Printing`, "success", ALERT_TIMEOUT_IN_MS);
          if (appState.subdomain === "tosprep") {
            for (const code of payload.codes) {
              printCanvasLabelRemote(
                {
                  code: `${code}`,
                  quantity: 1,
                  dimensions:
                    TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM[appState.subdomain] ||
                    TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM.default,
                },
                null,
                appState,
              );
            }
          } else {
            setCodeToPrint(payload.codes);
            setTimeout(() => {
              for (const code of payload.codes) {
                printCanvasLabelPdf(
                  `${code}`,
                  1,
                  null,
                  null,
                  TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM[appState.subdomain] ||
                    TENANT_FNSKU_LABEL_PRINTING_SIZE_ENUM.default,
                  appState,
                  true,
                );
              }
            }, 1000);
          }
          for (let code of payload.codes) {
            logLabelPrintQuery.fetchData({
              type: "LPN",
              customer: payload.customer,
              quantity: 1,
              code,
            });
          }
        });

        socket.on("SHIPPING_LABEL", (payload) => {
          appState.setAlert(
            `Printing shipping label`,
            "success",
            ALERT_TIMEOUT_IN_MS,
          );
          printShippingLabel(
            payload.codes[0],
            appState,
            "Datamax I-4212 (Printer 1)",
          );
          logLabelPrintQuery.fetchData({
            type: "SHIPPING_LABEL",
            customer: payload.customer,
            quantity: 1,
            code: payload.codes[0],
          });
        });

        socket.on("BOL", (payload) => {
          // appState.setAlert(`Printing BOL`, "success", ALERT_TIMEOUT_IN_MS);
          // printShippingLabel(payload.codes[0], appState, "RICOH IM 5000 PCL 6");
          getOrderForBolPrint.fetchData({ orderId: payload.codes[0] });
          // logLabelPrintQuery.fetchData({
          //   type: "BOL",
          //   customer: payload.customer,
          //   quantity: 1,
          //   code: payload.codes[0],
          // });
        });

        // socket.on("printFile", (payload) => {
        //   appState.setAlert(`Printing file`, "success", ALERT_TIMEOUT_IN_MS);
        //   printShippingLabel(payload.url, appState);
        // });

        // socket.on("printFile", (payload) => {
        //   appState.setAlert(`Printing shipping label`, "success", ALERT_TIMEOUT_IN_MS);
        //   printShippingLabel(payload.url, appState);
        // });

        socket.on("connect", function () {
          console.log("connected!");
        });

        socket.on("message", (message) => {
          console.log(message);
        });
        socket.emit("subscribe", { roomId: auth.user.station });
      }
    }, [socket]);

    useEffect(() => {
      const client = new W3CWebSocket("ws://127.0.0.1:8456");
      client.onopen = () => {
        console.log("Connected to electron app");
      };
      client.onmessage = (message) => {
        console.log("message received", message);
        const parsed = JSON.parse(message.data);
        let relevant = parsed.data;
        if (relevant && relevant.data) {
          relevant = relevant.data;
        }

        switch (relevant.key) {
          case "scanner-data":
            if (relevant.message) {
              return immediateScanQuery.fetchData({
                barcode: relevant.message,
              });
            }
          case "dymo-m10-attach":
            if (relevant.message === "attached") {
            } else if (relevant.message === "detached") {
            }
            break;

          case "dymo-m10-error":
            break;
        }
      };
    }, []);

    useEffect(() => {
      if (getOrderForBolPrint.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getOrderForBolPrint.data) {
        appState.setLoading();
        setBolDoc(getOrderForBolPrint.data.order);
        setTimeout(() => {
          printBol(
            getOrderForBolPrint.data.order.orderId,
            1,
            appState.subdomain === "ctw" ? "RICOH IM 5000 PCL 6" : null,
            false,
            appState,
          );
          appState.removeLoading();
        }, 5000);
      }
    }, [
      getOrderForBolPrint.loading,
      getOrderForBolPrint.error,
      getOrderForBolPrint.data,
    ]);

    useEffect(() => {
      if (setStationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (setStationQuery.error) {
        if (
          setStationQuery.error?.message &&
          setStationQuery.error.message.indexOf("already") !== -1
        ) {
          appState.showConfirmation(
            "Error logging in",
            setStationQuery.error.message,
            () => {
              setStationQuery.fetchData({
                station: selectedStation,
                logoutAll: true,
              });
              appState.hideConfirmation();
            },
            appState.hideConfirmation,
          );
        } else {
          appState.setAlert(
            setStationQuery.error.message,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
      }

      if (setStationQuery.data && setStationQuery.data) {
        // appState.setAlert(setStationQuery.data.message, "success", ALERT_TIMEOUT_IN_MS);
        window.location = "/receiver";
        // history.push("/receiver");
      }
    }, [setStationQuery.data, setStationQuery.error, setStationQuery.loading]);

    useEffect(() => {
      stationsQuery.fetchData();
    }, []);

    useEffect(() => {
      if (stationsQuery.data) {
        const foundStations = stationsQuery.data.stations.entities.filter(
          (item) =>
            item.hopstackModule.toString().toLowerCase() === "receiving",
        );
        setStations(foundStations);
      }

      if (stationsQuery.error) {
        setStations([]);
      }
    }, [stationsQuery.data, stationsQuery.loading, stationsQuery.error]);

    useEffect(() => {
      if (checkForConsignmentQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (checkForConsignmentQuery.data) {
        if (checkForConsignmentQuery.data.checkForConsignment.consignments) {
          setConsignments(
            checkForConsignmentQuery.data.checkForConsignment.consignments,
          );
        }
        if (currentScan) {
          if (
            checkForConsignmentQuery.data.checkForConsignment.consignments
              .length === 1
          ) {
            return setCurrentScan({
              ...currentScan,
              trackingNumber: null,
              consignmentId:
                checkForConsignmentQuery.data.checkForConsignment
                  .consignments[0].id,
            });
          }
          return setCurrentScan({
            ...currentScan,
            trackingNumber: currentScan.consignmentId ? null : trackingNumber,
          });
        }
      }

      if (checkForConsignmentQuery.error) {
        setConsignments([]);
        appState.setAlert(
          checkForConsignmentQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        setTrackingNumber(null);
        setCurrentScan({
          ...currentScan,
          trackingNumber: null,
        });
      }
    }, [
      checkForConsignmentQuery.loading,
      checkForConsignmentQuery.error,
      checkForConsignmentQuery.data,
    ]);

    const checkConsignmentById = (e) => {
      if (e.data) {
        setCurrentScan({
          ...currentScan,
          consignmentId: e.data,
        });
        setTrackingNumber(null);
        checkForConsignmentQuery.fetchData({
          trackingNumber: null,
          customer: currentScan.customer,
          consignmentId: e.data,
        });
      }
    };

    const scanBarcode = (e) => {
      if (e.data) {
        immediateScanQuery.fetchData({ barcode: e.data });
        setShowScanner(false);
      }
    };

    useEffect(() => {
      if (immediateScanQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (immediateScanQuery.data) {
        const barcode = immediateScanQuery.data.immediateScan.barcode;
        if (!currentScan) {
          return;
        }
        if (!currentScan.trackingNumber && !consignments?.length) {
          // TRACKING NUMBER
          setTrackingNumber(barcode);
          return checkForConsignmentQuery.fetchData({
            trackingNumber: [barcode],
            customer: currentScan.customer,
          });
        }

        if (addLpnToProduct && addLpnToProduct.productId) {
          return setAddLpnToProduct({
            ...addLpnToProduct,
            lpn: barcode,
          });
        }

        if (generateLpn) {
          return setGenerateLpn({
            ...generateLpn,
            useCode: barcode,
          });
        }
        return scanBarcodeQuery.fetchData({
          code: barcode,
          trackingNumber: currentScan.trackingNumber,
          customer: currentScan.customer,
          consignmentIds: consignments.map((item) => item.id),
        });
      }
    }, [
      immediateScanQuery.loading,
      immediateScanQuery.error,
      immediateScanQuery.data,
    ]);

    useEffect(() => {
      currentSkuQuery.fetchData();
    }, []);
    useEffect(() => {
      if (currentSkuQuery.loading) {
        appState.setLoading();
      }
      if (currentSkuQuery.data && currentSkuQuery.data.getCurrentInwardItem) {
        let quantity = currentSkuQuery.data.getCurrentInwardItem.quantity;
        if (currentSku && currentSku.quantity >= quantity) {
          quantity = currentSku.quantity;
        }
        setCurrentSku({
          ...currentSkuQuery.data.getCurrentInwardItem,
          quantity,
        });
        if (!currentItems) {
          setCurrentItems([
            {
              ...currentSkuQuery.data.getCurrentInwardItem,
              quantity,
              formFactor:
                currentSkuQuery.data.getCurrentInwardItem.formFactor ||
                currentProduct?.baseUom ||
                "Each",
              status: "CONFIRMED",
            },
          ]);
        }
        setCurrentScan({
          trackingNumber:
            currentSkuQuery.data.getCurrentInwardItem.trackingNumber,
          customer: currentSkuQuery.data.getCurrentInwardItem.customer,
          consignmentId:
            currentSkuQuery.data.getCurrentInwardItem.consignmentId,
        });
        appState.removeLoading();
        // setLoadingFirstTime(false);
        if (currentSkuQuery.data.getCurrentInwardItem.productId) {
          getCurrentProductQuery.fetchData({
            id: currentSkuQuery.data.getCurrentInwardItem.productId,
          });
        }
      }

      if (currentSkuQuery.error) {
        appState.removeLoading();
        setCurrentItems(null);
        setCurrentSku(null);
        setCurrentProduct(null);
      }
    }, [currentSkuQuery.loading, currentSkuQuery.data, currentSkuQuery.error]);

    useEffect(() => {
      if (confirmItemQuery.loading) {
        appState.setLoading();
      } else {
        if (
          confirmItemQuery.data &&
          confirmItemQuery.data.confirmInwardItem.message
        ) {
          appState.setAlert(
            confirmItemQuery.data.confirmInwardItem.message,
            "success",
            2000,
          );

          currentSkuQuery.fetchData();
          if (specificConsignment && specificConsignment.id) {
            getConsignmentQuery.fetchData({ id: specificConsignment.id });
            checkForConsignmentQuery.fetchData({
              trackingNumber: null,
              customer: currentScan.customer,
              consignmentId: specificConsignment.id,
            });
          }
        }
        if (confirmItemQuery.error) {
          appState.setAlert(
            confirmItemQuery.error.message,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      confirmItemQuery.loading,
      confirmItemQuery.data,
      confirmItemQuery.error,
    ]);

    useEffect(() => {
      if (scanBarcodeQuery.loading) {
        appState.setLoading();
      } else {
        if (
          scanBarcodeQuery.data &&
          scanBarcodeQuery.data.scanInwardReceiverBarcode &&
          scanBarcodeQuery.data.scanInwardReceiverBarcode.message
        ) {
          currentSkuQuery.fetchData();
          appState.setAlert(
            scanBarcodeQuery.data.scanInwardReceiverBarcode.message,
            "success",
            2000,
          );
        } else if (scanBarcodeQuery.error) {
          appState.setAlert(scanBarcodeQuery.error.message, "error");
        }
        appState.removeLoading();
        // setLoadingFirstTime(false);
      }
    }, [
      scanBarcodeQuery.loading,
      scanBarcodeQuery.data,
      scanBarcodeQuery.error,
    ]);

    const decreaseItemQuantity = () => {
      const item = { ...currentSku };

      if (!item) {
        return;
      }

      if (item.quantity === 1) {
        return;
      }

      item.quantity -= 1;

      item.quantity = Math.max(0, item.quantity);

      setCurrentSku({ ...item });
    };

    const increaseItemQuantity = () => {
      const item = { ...currentSku };

      if (!item) {
        return;
      }

      item.quantity += 1;

      setCurrentSku({ ...item });
    };

    const submitCustomerCode = (customerCodeInput) => {
      if (!customerCodeInput) {
        return appState.setAlert(
          `Please provide a customer code`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      customerCodeInput = customerCodeInput.value;

      const customersList = auth.user.customersList;
      if (!customersList || customersList.length === 0) {
        return appState.setAlert(
          `No customers found`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      let customer = customersList.find(
        (item) => item.id === customerCodeInput,
      );
      if (!customer) {
        return appState.setAlert(
          `No customer found with that code`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      updateUrlParams("customer", customer.id);

      setCurrentScan({ customer: customer.id });
    };

    const onDateChange = (e) => {
      const currentSkuCopy = { ...currentSku };
      currentSkuCopy.bestByDate = e;
      setCurrentSku({
        ...currentSkuCopy,
      });
    };

    useEffect(() => {
      if (inventoryQuery.loading) {
        // appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (inventoryQuery.data) {
        setAvailableInventory(
          inventoryQuery.data.inventory.entities.filter((item) => !!item.sku),
        );
        if (
          inventoryQuery.data.inventory.entities.filter((item) => !!item.sku)
            .length === 0
        ) {
          if (
            inventoryQuery.variables &&
            inventoryQuery.variables.filters &&
            inventoryQuery.variables.filters.id?.length > 0
          ) {
            return inventoryQuery.fetchData({
              perPage: 10,
              pageNumber: 1,
              filters: {
                keyword: inventorySearchKeyword,
                customer: [currentScan.customer],
              },
              paginated: false,
            });
          }
        }
      }

      if (inventoryQuery.error) {
        setAvailableInventory([]);
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    const onChangeInventorySearch = () => {
      // console.log(consignments.length);
      let productIds;
      if (consignments.length > 0) {
        productIds = _.flatten(
          consignments.map((item) => item.workingList.map((i) => i.productId)),
        );
      }
      if (productIds && productIds.length > 0) {
        inventoryQuery.fetchData({
          perPage: 10,
          pageNumber: 1,
          filters: {
            keyword: inventorySearchKeyword,
            customer: [currentScan.customer],
            id: productIds,
          },
          paginated: false,
        });
      } else {
        inventoryQuery.fetchData({
          perPage: 10,
          pageNumber: 1,
          filters: {
            keyword: inventorySearchKeyword,
            customer: [currentScan.customer],
          },
          paginated: false,
        });
      }
    };

    useEffect(() => {
      if (inventorySearchKeyword) {
        debouncedInventorySearch();
      }
    }, [inventorySearchKeyword]);

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    useEffect(() => {
      if (addLpnQuery.error) {
        appState.setAlert(
          addLpnQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (addLpnQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (addLpnQuery.data) {
        appState.setAlert(
          addLpnQuery.data.addLpnToProduct.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        const lpn = addLpnToProduct.lpn;
        setAddLpnToProduct(null);
        return scanBarcodeQuery.fetchData({
          code: lpn,
          trackingNumber: currentScan.trackingNumber,
          customer: currentScan.customer,
          consignmentId: currentScan.consignmentId,
        });
      }
    }, [addLpnQuery.error, addLpnQuery.loading, addLpnQuery.data]);

    const submitLpn = () => {
      if (!addLpnToProduct) {
        appState.setAlert(
          `Please provide an LPN`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      if (!addLpnToProduct.productId) {
        appState.setAlert(
          `Please provide a product ID`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      if (!addLpnToProduct.lpn) {
        appState.setAlert(
          `Please provide an LPN`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      addLpnQuery.fetchData({
        ...addLpnToProduct,
      });
    };

    const saveAndPrint = () => {
      if (!generateLpn) {
        appState.setAlert(
          `Please generate an LPN`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      if (!generateLpn.formFactor) {
        appState.setAlert(
          `Please select a form factor`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      if (!generateLpn.products || generateLpn.products.length === 0) {
        appState.setAlert(
          `Please select at least 1 product`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }

      if (!generateLpn.quantity || generateLpn.quantity === 0) {
        appState.setAlert(`Minimum quantity 1`, "error", ALERT_TIMEOUT_IN_MS);
        return;
      }

      for (const product of generateLpn.products) {
        if (!product.productId) {
          appState.setAlert(
            `Please select products`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return;
        }
        if (!product.quantity || product.quantity === 0) {
          appState.setAlert(
            `Please select at least 1 quantity for a product`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return;
        }
        if (!product.formFactor) {
          appState.setAlert(
            `Please specify each product's form factor`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return;
        }
      }
      generateLpnQuery.fetchData({
        trackingNumber: currentScan.trackingNumber
          ? [currentScan.trackingNumber]
          : null,
        customer: currentScan.customer,
        formFactor: generateLpn.formFactor,
        quantity: generateLpn.quantity,
        products: generateLpn.products,
      });
    };

    useEffect(() => {
      if (generateLpnQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (generateLpnQuery.error) {
        appState.setAlert(
          generateLpnQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (generateLpnQuery.data) {
        appState.setAlert(
          generateLpnQuery.data.generateLpn.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        // setPrintedCodes(generateLpnQuery.data.generateLpn.generatedCodes);
        setGeneratedLPN({
          codes: generateLpnQuery.data.generateLpn.generatedCodes,
          station: null,
        });
        setGenerateLpn(null);
      }
    }, [
      generateLpnQuery.error,
      generateLpnQuery.data,
      generateLpnQuery.loading,
    ]);

    useEffect(() => {
      if (printRequestQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (printRequestQuery.data) {
        appState.setAlert(
          printRequestQuery.data.printRequest.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (printRequestQuery.error) {
        appState.setAlert(
          `Could not trigger re-print`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }, [
      printRequestQuery.error,
      printRequestQuery.loading,
      printRequestQuery.data,
    ]);

    useEffect(() => {
      if (cancelItemQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (cancelItemQuery.data) {
        appState.setAlert(
          cancelItemQuery.data.cancelReceivingItem.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        currentSkuQuery.fetchData();
      }

      if (cancelItemQuery.error) {
        appState.setAlert(
          cancelItemQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        currentSkuQuery.fetchData();
      }
    }, [cancelItemQuery.error, cancelItemQuery.data, cancelItemQuery.loading]);

    const cancelItem = () => {
      appState.showConfirmation(
        "Confirm",
        "This action cannot be undone.",
        () => {
          cancelItemQuery.fetchData({
            id: currentSku.id,
          }),
            appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const reprint = () => {
      if (!generatedLPN || !generatedLPN.station) {
        if (stations.length > 0) {
          setGeneratedLPN({
            ...generatedLPN,
            station: stations[0].id,
          });
        } else {
          appState.setAlert(
            `Please select a station`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return;
        }
      }

      printRequestQuery.fetchData({
        requestType: "LPN",
        station: generatedLPN.station ? generatedLPN.station : stations[0]?.id,
        codes: generatedLPN.codes,
      });
    };

    const confirmItemDamaged = () => {
      if (isNaN(currentSku.quantity) || currentSku.quantity <= 0) {
        appState.setAlert(
          `Please input a valid quantity`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return;
      }
      appState.showConfirmation(
        "Confirmation",
        "Please confirm you would like to mark this item as damaged",
        () => {
          confirmItemQuery.fetchData({
            item: {
              id: currentSku.id,
              quantity: currentSku.quantity,
              bestByDate: currentSku.bestByDate,
              tote: currentSku.tote,
              binLocation: currentSku.binLocation,
              lotId: currentSku.lotId,
              palletId: currentSku.palletId,
            },
            status: "DAMAGED",
          });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    const checkPagination = (direction) => {
      let vars = {
        filters: consignmentsData.filters,
        sort: consignmentsData.sort,
        perPage: 25,
      };
      if (direction === "backward") {
        vars["pageNumber"] = consignmentsData.pageNumber - 1;
      } else {
        vars["pageNumber"] = consignmentsData.pageNumber + 1;
      }
      setConsignmentsData({
        ...consignmentsData,
        pageNumber: vars["pageNumber"],
      });
      return consignmentsQuery.fetchData(vars);
    };

    useEffect(() => {
      if (submitNumberOfCartonsQuery.data) {
        appState.setAlert(
          submitNumberOfCartonsQuery.data.submitNumberOfCartons.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        setCurrentScan({
          ...currentScan,
          trackingNumber: undefined,
        });
      }
      if (submitNumberOfCartonsQuery.error) {
        appState.setAlert(
          submitNumberOfCartonsQuery.error.message,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
      if (submitNumberOfCartonsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      submitNumberOfCartonsQuery.error,
      submitNumberOfCartonsQuery.loading,
      submitNumberOfCartonsQuery.data,
    ]);

    const confirmNumberOfItems = (manualValue) => {
      const consignmentId = currentScan.consignmentId;

      if (!consignmentId) {
        return appState.setAlert(
          `Please choose a specific consignment`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      return submitNumberOfCartonsQuery.fetchData({
        consignmentId,
        ...manualValue,
      });
    };

    const updateItem = (idx, field, value) => {
      let itemsCopy = [...currentItems];
      itemsCopy[idx][field] = value;
      setCurrentItems(itemsCopy);
    };

    const removeItem = (idx) => {
      let itemsCopy = [...currentItems];
      if (itemsCopy.length > 1) {
        itemsCopy = itemsCopy.filter((item, index) => index !== idx);
        setCurrentItems(itemsCopy);
      } else {
        appState.setAlert(
          `There needs to be at least 1 item`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    };

    const addItem = () => {
      let itemsCopy = [...currentItems];
      itemsCopy.push({
        ...currentSku,
        formFactor: "Each",
        quantity: 1,
        status: "CONFIRMED",
      });
      setCurrentItems(itemsCopy);
    };

    const printCode = (code, numberToPrint, dimensions, print = true) => {
      if (!code) {
        return appState.setAlert(
          `Generate/Enter a code first.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      if (!dimensions) {
        return appState.setAlert(
          `Specify the dimensions`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }

      numberToPrint = numberToPrint || 1;
      printCanvasLabelPdf(
        `${code}`,
        numberToPrint,
        null,
        null,
        dimensions || "4x6",
        appState,
        print,
      );
    };

    const updateUrlParams = (paramName, paramValue) => {
      // Create a URLSearchParams object based on the current search string
      const searchParams = new URLSearchParams(location.search);

      searchParams.has(paramName)
        ? searchParams.set(paramName, paramValue)
        : searchParams.append(paramName, paramValue);

      // Use history to update the URL without navigating away
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    };

    return (
      <WrappedComponent
        currentSku={currentSku}
        showScanner={showScanner}
        closeScanner={() => setShowScanner(false)}
        scanBarcode={scanBarcode}
        checkConsignmentById={checkConsignmentById}
        decreaseItemQuantity={decreaseItemQuantity}
        increaseItemQuantity={increaseItemQuantity}
        confirmPrepItem={() => {
          if (
            appState?.tenant?.features?.combinedReceiveAndPrep === true &&
            currentProduct?.source === "FBA"
          ) {
            if (
              currentItems.find(
                (item) => isNaN(item.quantity) || item.quantity <= 0,
              )
            ) {
              appState.setAlert(
                `Please input a valid quantity for all items`,
                "error",
                ALERT_TIMEOUT_IN_MS,
              );
              return;
            }
            currentItems.forEach((item) =>
              handleFnSkuPrinting(
                appState,
                item.fnSku,
                item.quantity,
                currentProduct?.name || currentProduct?.productName,
                item.bestByDate,
              ),
            );
          }
        }}
        confirmItem={() => {
          if (
            currentItems.find(
              (item) => isNaN(item.quantity) || item.quantity <= 0,
            )
          ) {
            appState.setAlert(
              `Please input a valid quantity for all items`,
              "error",
              ALERT_TIMEOUT_IN_MS,
            );
            return;
          }

          confirmItemQuery.fetchData({
            item: {
              id: currentSku.id,
              quantity: currentSku.quantity,
              bestByDate: currentSku.bestByDate,
              lotId: currentSku.lotId,
              palletId: currentSku.palletId,
              tote: currentSku.tote,
              binLocation: currentSku.binLocation,
              remarks: currentSku.remarks,
              formFactor: currentSku.formFactor,
              status: currentSku.status,
              serialNumber: currentSku.serialNumber,
            },
            items: currentItems.map((item) => ({
              id: currentSku.id,
              quantity: item.quantity,
              bestByDate: item.bestByDate,
              lotId: item.lotId,
              palletId: item.palletId,
              tote: currentSku.tote,
              binLocation: currentSku.binLocation,
              remarks: item.remarks,
              formFactor: item.formFactor,
              status: item.status,
              consignmentId: item.consignmentId,
              nestedFormFactor: item.nestedFormFactor,
              nestedFormFactorId: item.nestedFormFactorId,
              serialNumber: item.serialNumber,
            })),
          });
        }}
        loading={currentSkuQuery.loading}
        currentScan={currentScan}
        setCurrentScan={setCurrentScan}
        customers={
          auth.user && auth.user.customersList ? auth.user.customersList : []
        }
        submitCustomerCode={submitCustomerCode}
        onDateChange={onDateChange}
        cancelCurrentCustomerAndTracking={() => {
          if (currentScan) {
            if (currentScan.trackingNumber !== undefined) {
              setCurrentScan({
                ...currentScan,
                trackingNumber: undefined,
              });
            } else {
              setCurrentScan(null);
              setTrackingNumber(undefined);
              history.push({
                pathname: location.pathname,
                search: "",
              });
            }
          } else {
            setCurrentScan(null);
            setTrackingNumber(undefined);
          }
        }}
        availableInventory={availableInventory}
        addLpnToProduct={addLpnToProduct}
        setAddLpnToProduct={setAddLpnToProduct}
        generateLpn={generateLpn}
        setGenerateLpn={setGenerateLpn}
        inventorySearchKeyword={inventorySearchKeyword}
        setInventorySearchKeyword={setInventorySearchKeyword}
        submitLpn={submitLpn}
        submitNullValue={() => {
          setTrackingNumber(null);
          checkForConsignmentQuery.fetchData({
            trackingNumber: null,
            customer: currentScan.customer,
          });
        }}
        saveAndPrint={saveAndPrint}
        printedCodes={printedCodes}
        setPrintedCodes={setPrintedCodes}
        cancelItem={cancelItem}
        reprint={reprint}
        stations={stations}
        generatedLPN={generatedLPN}
        setGeneratedLPN={setGeneratedLPN}
        currentUser={auth.user}
        onSelectStation={(e) => setSelectedStation(e)}
        onSubmitStation={() => {
          if (selectedStation) {
            updateUrlParams("station", selectedStation);
            setStationQuery.fetchData({ station: selectedStation });
          } else {
            appState.setAlert(
              "Please select a station",
              "error",
              ALERT_TIMEOUT_IN_MS,
            );
          }
        }}
        selectedStation={selectedStation}
        confirmItemDamaged={confirmItemDamaged}
        setCurrentSku={setCurrentSku}
        codeToPrint={codeToPrint}
        consignments={consignmentsData.consignments}
        total={consignmentsData.total}
        pageNumber={consignmentsData.pageNumber}
        checkPagination={checkPagination}
        perPage={consignmentsData.perPage}
        specificConsignment={specificConsignment}
        filters={consignmentsData.filters}
        setFilters={(filters) =>
          setConsignmentsData({
            ...consignmentsData,
            filters,
          })
        }
        submitFilters={() =>
          consignmentsQuery.fetchData({
            perPage: 25,
            pageNumber: 1,
            filters: consignmentsData.filters,
          })
        }
        consignmentsData={consignmentsData}
        subdomain={appState.subdomain}
        confirmNumberOfItems={confirmNumberOfItems}
        currentItems={currentItems}
        setCurrentItems={setCurrentItems}
        updateItem={updateItem}
        removeItem={removeItem}
        addItem={addItem}
        logout={() => logoutQuery.fetchData()}
        bolDoc={bolDoc}
        setBolDoc={setBolDoc}
        tenant={appState.tenant}
        statusList={statusList}
        currentProduct={currentProduct}
        printCode={printCode}
        prepEnabled={
          appState?.tenant?.features?.combinedReceiveAndPrep === true
        }
        eligibleConsignments={consignments}
      />
    );
  };
};

export default withInwardReceiverLogic;
