const ExpandedShipmentPlan = ({ shipmentPlan }) => {
  return (
    <div>
      <div className="mb-4">
        <p className="text-xl font-montserrat font-bold pb-4">
          Shipment Details:
        </p>
        <div className="w-max border border-gray-500 rounded-xl p-3">
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Shipment ID: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.ShipmentId}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Fulfillment Center: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.DestinationFulfillmentCenterId}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Label Prep Type: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.LabelPrepType}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <p className="text-xl font-montserrat font-bold pb-4">
          Shipment Address:
        </p>
        <div className="w-max border border-gray-500 rounded-xl p-3">
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Name: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.ShipToAddress?.Name}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Address: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.ShipToAddress?.AddressLine1}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">City: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.ShipToAddress?.City}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">State: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.ShipToAddress?.StateOrProvinceCode}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Country: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.ShipToAddress?.CountryCode}
            </span>
          </div>
          <div className="grid grid-cols-3 p-2">
            <span className="font-bold">Postal Code: </span>
            <span className="ml-4 font-medium col-span-2">
              {shipmentPlan.ShipToAddress?.PostalCode}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <p className="text-xl font-montserrat font-bold pb-4">Items:</p>
        <PlanOrderLineItems items={shipmentPlan.Items} />
      </div>
    </div>
  );
};

const PlanOrderLineItems = ({ items }) => {
  const headers = ["Seller SKU", "FN SKU", "Quantity"];
  return (
    <div className="relative text-[16px] xl:text-sm w-max h-fit overflow-auto border border-gray-300">
      <table className="text-[16px] w-full divide-y relative divide-gray-200 whitespace-nowrap">
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 z-10">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) => (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length === 0 ? (
            <tr className="bg-white">
              <td className="text-tableText pl-2 px-1 py-2 text-left font-medium tracking-wider rounded-br rounded-tr">
                {"No items found in this shipment plan."}
              </td>
            </tr>
          ) : null}
          {items.map((item, index) => (
            <tr
              key={item.SellerSKU}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td className={`p-5 text-left font-semibold text-primaryAccent`}>
                {item.SellerSKU}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {item.FulfillmentNetworkSKU}
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                {item.Quantity}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandedShipmentPlan;
