import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "#hooks/useQuery";
import { GET_SKU_BUNDLE, GET_INVENTORY } from "#queries";
import { SAVE_SKU_BUNDLE } from "#mutations";
import { AppStateContext } from "#contexts/appState";
import _ from "lodash";

const withSkuBundlesFormLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const history = useHistory();
    const [selectedSkuBundle, setSelectedSkuBundle] = useState(null);
    const [bundleItemQuantity, setBundleItemQuantity] = useState(1);
    const [skuBundleContents, setSkuBundleContents] = useState({
      leftItems: [],
      rightItems: [],
      total: 0,
    });
    const [showBundleItemQuantityForm, setShowBundleItemQuantityForm] =
      useState(null);
    const inventoryQuery = useQuery(GET_INVENTORY);
    const getSkuBundleQuery = useQuery(GET_SKU_BUNDLE);
    const saveSkuBundleQuery = useQuery(SAVE_SKU_BUNDLE);

    useEffect(() => {
      const skuId =
        history.location.search &&
        history.location.search.split("=").length &&
        history.location.search.split("=")[1];

      if (skuId) {
        getSkuBundleQuery.fetchData({ id: skuId });
      } else {
        setSelectedSkuBundle({});
      }
    }, []);

    useEffect(() => {
      if (inventoryQuery && selectedSkuBundle) {
        setSkuBundleContents({
          ...skuBundleContents,
          total: inventoryQuery.data?.inventory.total || 0,
          rightItems: selectedSkuBundle.bundle || skuBundleContents.rightItems,
          leftItems: [
            ..._.filter(
              inventoryQuery.data?.inventory.entities,
              (el) =>
                (selectedSkuBundle.bundle || skuBundleContents.rightItems)
                  .map((item) => item.sku)
                  .indexOf(el.sku) === -1
            ),
          ],
        });
      }
    }, [selectedSkuBundle]);

    useEffect(() => {
      if (getSkuBundleQuery.data) {
        setSelectedSkuBundle(getSkuBundleQuery.data.skuBundle);
      }

      if (getSkuBundleQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      getSkuBundleQuery.loading,
      getSkuBundleQuery.data,
      getSkuBundleQuery.error,
    ]);
    useEffect(() => {
      if (inventoryQuery.data && inventoryQuery.data.inventory) {
        setSkuBundleContents({
          ...skuBundleContents,
          total: inventoryQuery.data.inventory.total,
          rightItems: skuBundleContents.rightItems || selectedSkuBundle?.bundle,
          leftItems: [
            ..._.filter(
              inventoryQuery.data?.inventory.entities || [],
              (el) =>
                (skuBundleContents.rightItems || selectedSkuBundle?.bundle)
                  .map((item) => item.sku)
                  .indexOf(el.sku) === -1
            ),
          ],
        });
      }

      if (inventoryQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [inventoryQuery.loading, inventoryQuery.error, inventoryQuery.data]);

    useEffect(() => {
      if (saveSkuBundleQuery.data) {
        setSelectedSkuBundle(null);
        history.push("/skuBundles");
      }
      if (saveSkuBundleQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveSkuBundleQuery.error) {
        appState.setAlert(saveSkuBundleQuery.error.message, "error", 5000);
      }
    }, [
      saveSkuBundleQuery.loading,
      saveSkuBundleQuery.data,
      saveSkuBundleQuery.error,
    ]);

    useEffect(() => {
      let quantity = 0;
      if (
        skuBundleContents.rightItems.filter((item) => {
          if (item.sku === showBundleItemQuantityForm) {
            quantity = item.quantity;
          }
          return true;
        }).length
      ) {
        setBundleItemQuantity(quantity);
      }
    }, [showBundleItemQuantityForm]);

    const onChange = (e) => {
      const skuBundle = {
        ...selectedSkuBundle,
      };

      skuBundle[e.target.name] = e.target.value;
      setSelectedSkuBundle(skuBundle);
    };

    const onChangeBundleItemQuantity = (e) => {
      setBundleItemQuantity(parseInt(e.target.value));
    };

    const onAddBundleItem = (sku, quantity) => {
      if (quantity === undefined) {
        setShowBundleItemQuantityForm(sku);
        return;
      }
      if (
        skuBundleContents.rightItems.filter((item) => item.sku === sku).length
      ) {
        setSkuBundleContents({
          ...skuBundleContents,
          rightItems: skuBundleContents.rightItems.map((item) => {
            if (item.sku === sku)
              return { ...item, quantity: bundleItemQuantity };
            return item;
          }),
        });
        setShowBundleItemQuantityForm(null);
        return;
      }
      const rightItems = [
        ...skuBundleContents.rightItems,
        { sku, quantity: bundleItemQuantity },
      ];
      setSkuBundleContents({
        ...skuBundleContents,
        leftItems: [
          ..._.filter(
            inventoryQuery.data.inventory.entities,
            (el) => rightItems.map((item) => item.sku).indexOf(el.sku) === -1
          ),
        ],
        rightItems,
      });
      setShowBundleItemQuantityForm(null);
    };

    const onRemoveBundleItem = (sku) => {
      const rightItems = skuBundleContents.rightItems.filter(
        (item) => item.sku !== sku
      );
      const leftItems = _.filter(
        inventoryQuery.data?.inventory.entities || [],
        (el) => rightItems.map((item) => item.sku).indexOf(el.sku) === -1
      );
      setSkuBundleContents({
        ...skuBundleContents,
        leftItems,
        rightItems,
      });
    };

    const onChangeInventorySearch = (keyword) => {
      inventoryQuery.fetchData({
        perPage: 100,
        pageNumber: 1,
        filters: { keyword },
        paginated: false,
      });
    };

    const debouncedInventorySearch = _.debounce(onChangeInventorySearch, 1000);

    return (
      <WrappedComponent
        selectedSkuBundle={selectedSkuBundle}
        title={selectedSkuBundle?.name}
        setSelectedSkuBundle={setSelectedSkuBundle}
        skuBundleSuggestionHeaders={["SKU", "Name", "Action"]}
        skuBundleContentHeaders={["SKU", "Quantity", "Action"]}
        saveSkuBundle={(skuBundle) =>
          saveSkuBundleQuery.fetchData({
            ...skuBundle,
            enabled: selectedSkuBundle.enabled || false,
            bundle: skuBundleContents.rightItems,
          })
        }
        onCancel={() => history.push("/skuBundles")}
        setShowBundleItemQuantityForm={setShowBundleItemQuantityForm}
        showBundleItemQuantityForm={showBundleItemQuantityForm}
        skuBundleContents={skuBundleContents}
        bundleItemQuantity={bundleItemQuantity}
        onChangeBundleItemQuantity={onChangeBundleItemQuantity}
        onChangeInventorySearch={(e) =>
          debouncedInventorySearch(e.target.value)
        }
        onAddBundleItem={onAddBundleItem}
        onRemoveBundleItem={onRemoveBundleItem}
        onChange={onChange}
        setEnabled={() =>
          setSelectedSkuBundle({
            ...selectedSkuBundle,
            enabled: !selectedSkuBundle.enabled,
          })
        }
      />
    );
  };
};

export default withSkuBundlesFormLogic;
