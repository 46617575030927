import HollowButton from "../../utils/hollowButton";
import { ChevronDownIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import * as SlateTable from "../common/SlateTable";
import STORAGE_SECTION_STATUSES from "../../constants/storage-section-statuses";
import { Menu } from "@headlessui/react";
import FilledButton from "../../utils/filledButton";
import {
  PencilIcon,
  RefreshIcon,
  DuplicateIcon,
  SwitchHorizontalIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { LocationTree } from "../../utils/storage-management/location-tree";
import { Modal } from "../common/Modal";
import DropdownInput from "../common/DropdownInput";
import MessageDisplay from "../common/MessageDisplay";

const WarehouseStorageSections = ({
  storageSections,
  setStorageSections,
  warehouses,
  selectedStorageSection,
  setSelectedStorageSection,
  INITIAL_STORAGE_SECTION_STATE,
  setLocationTree,
  locationTypes,
  deleteStorageSection,
  storageSectionStatusUpdate,
  setStorageSectionStatusUpdate,
  submitStorageSectionStatusUpdate,
  setLoading,
  buildLocationTreeFromStorageSection,
  showAlert,
  storageSectionToDelete,
  setStorageSectionToDelete,
  getCurrentHierarchyArray,
}) => {
  const storageSectionMenu = [
    {
      icon: PencilIcon,
      text: "Edit Storage Section",
      action: (storageSection) => {
        setLoading(true);
        buildLocationTreeFromStorageSection(storageSection, true)
          .then(() => {
            setSelectedStorageSection({
              ...storageSection,
              type: storageSection.storageType,
              edit: true,
            });
            setLoading(false);
          })
          .catch((e) => {
            showAlert(e.message, "error", 5000);
            setLoading(false);
          });
      },
    },
    {
      icon: RefreshIcon,
      text: "Update Status",
      action: (storageSection) => {
        setStorageSectionStatusUpdate({
          ...JSON.parse(JSON.stringify(storageSection)),
          currentStatus: storageSection.status,
        });
      },
    },
    {
      icon: DuplicateIcon,
      text: "Duplicate Storage Type",
      action: (storageSection) => {
        setLoading(true);
        buildLocationTreeFromStorageSection(storageSection, false)
          .then(() => {
            setSelectedStorageSection({
              ...storageSection,
              id: null,
              status: STORAGE_SECTION_STATUSES.ACTIVE,
              type: storageSection.storageType,
              edit: false,
            });
            setLoading(false);
          })
          .catch((e) => {
            showAlert(e.message, "error", 5000);
            setLoading(false);
          });
      },
    },
    // {
    //   icon: SwitchHorizontalIcon,
    //   text: "Audit Trails",
    //   action: (storageSection) => {},
    // },
    {
      icon: TrashIcon,
      text: "Delete Storage Type",
      action: (storageSection) => {
        setStorageSectionToDelete(storageSection);
      },
    },
  ];
  return (
    <>
      {Object.keys(storageSections).map((warehouseId) => (
        <div className="mt-2">
          <div className="flex">
            <div className="flex">
              <div className="text-base font-semibold">
                {`${
                  warehouses.find((w) => w.id === warehouseId)?.name ||
                  warehouseId
                } (${storageSections[warehouseId].storageSections.length})`}
              </div>
              <button
                className="my-0 ml-2 p-0 h-6"
                onClick={() => {
                  setStorageSections({
                    ...storageSections,
                    [warehouseId]: {
                      ...storageSections[warehouseId],
                      collapsed: !storageSections[warehouseId].collapsed,
                    },
                  });
                }}>
                <ChevronDownIcon
                  className={`transition-transform transform ${
                    !storageSections[warehouseId].collapsed ? "rotate-180" : ""
                  } h-5 w-5`}
                />
              </button>
            </div>
            <div className="ml-auto">
              <HollowButton
                text="Create Storage Section"
                colour={"primaryAccent"}
                onClick={() => {
                  setSelectedStorageSection({
                    ...INITIAL_STORAGE_SECTION_STATE,
                    warehouse: warehouseId,
                  });
                  setLocationTree(new LocationTree());
                }}
              />
            </div>
          </div>
          <div
            className={`mt-2 w-full duration-500 transition-all overflow-hidden ${
              storageSections[warehouseId].collapsed ? "max-h-0" : ""
            }`}>
            <SlateTable.SlateTable>
              <SlateTable.SlateTableHeader>
                <SlateTable.SlateTableHeaderCell first={true}>
                  Storage Section
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Location Hierarchy
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Status
                </SlateTable.SlateTableHeaderCell>
                <SlateTable.SlateTableHeaderCell>
                  Action
                </SlateTable.SlateTableHeaderCell>
              </SlateTable.SlateTableHeader>
              <SlateTable.SlateTableBody>
                {storageSections[warehouseId].storageSections.map(
                  (storageSection) => (
                    <SlateTable.SlateTableBodyRow>
                      <SlateTable.SlateTableBodyRowCell first={true}>
                        {storageSection.name}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        {storageSection.code +
                          (storageSection.hierarchy.length > 0 ? " > " : "") +
                          storageSection.hierarchy
                            .map((h) => {
                              const locationType = locationTypes.find(
                                (lt) => lt.id === h,
                              );
                              return locationType ? locationType.name : "";
                            })
                            .join(" > ")}
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <StorageSectionColorBadge
                          status={storageSection.status}
                        />
                      </SlateTable.SlateTableBodyRowCell>
                      <SlateTable.SlateTableBodyRowCell>
                        <Menu as="div" className="">
                          <Menu.Button>
                            <DotsVerticalIcon className="h-4 w-4 cursor-pointer" />
                          </Menu.Button>
                          <>
                            <Menu.Items
                              as="div"
                              className="z-10 rounded bg-white p-2 rounded border-solid border border-grayBorder absolute w-max origin-top-right right-8">
                              {storageSectionMenu.map((menu) => (
                                <Menu.Item
                                  as="div"
                                  className={`cursor-pointer `}
                                  onClick={() => {
                                    menu.action(storageSection);
                                  }}>
                                  {({ active }) => (
                                    <div
                                      className={`flex rounded transition ${
                                        active ? "bg-lightBlueMenu" : ""
                                      } h-9`}>
                                      <menu.icon
                                        className="mx-2 my-1.5 h-6 w-6 cursor-pointer"
                                        color={active ? "#224E73" : "#737579"}
                                      />
                                      <span
                                        className={`my-2 mr-2 text-sm ${
                                          active
                                            ? "text-primaryAccent"
                                            : "text-grayText"
                                        }`}>
                                        {menu.text}
                                      </span>
                                    </div>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </>
                        </Menu>
                      </SlateTable.SlateTableBodyRowCell>
                    </SlateTable.SlateTableBodyRow>
                  ),
                )}
              </SlateTable.SlateTableBody>
            </SlateTable.SlateTable>
          </div>
        </div>
      ))}

      {storageSectionToDelete && (
        <Modal
          title="Confirm Delete Storage Section"
          onClose={() => {
            setStorageSectionToDelete(null);
          }}
          minWidth={"945px"}
          maxWidth={"945px"}>
          <div className="text-base font-normal leading-6 tracking-normal text-left">
            Are you sure you want to delete storage section “
            <span className="font-semibold">{storageSectionToDelete.name}</span>
            ” ?
          </div>
          <div className="flex mt-2">
            <MessageDisplay customStyles={"min-w-64 w-max h-max pb-4"}>
              <div className="mt-4 ml-4 mr-4 text-gray-500 text-base font-medium leading-17 tracking-normal text-left">
                Location Hierarchy
              </div>
              <div className="mt-1 ml-4 mr-4 text-lg font-semibold leading-19 tracking-normal text-left">
                {getCurrentHierarchyArray(storageSectionToDelete.hierarchy)
                  .length
                  ? getCurrentHierarchyArray(
                      storageSectionToDelete.hierarchy,
                    ).join(" > ")
                  : "-"}
              </div>
              <div className="mt-1 ml-4 mr-4 text-base font-normal leading-5 tracking-normal text-left">
                Ex:{" "}
                {`${storageSectionToDelete.code} > ${getCurrentHierarchyArray(
                  storageSectionToDelete.hierarchy,
                )
                  .map((locationType) => {
                    const type = locationTypes.find(
                      (type) => type.name === locationType,
                    );
                    let code = type.codeType === "NUMERIC" ? "1" : "A";
                    if (type.threeDimRange) {
                      code = "1.1.1";
                    }
                    return `${locationType}-${code}`;
                  })
                  .join(" > ")}`}
              </div>
            </MessageDisplay>
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setStorageSectionToDelete(null);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Submit"}
              colour={"primaryAccent"}
              onClick={() => {
                deleteStorageSection(storageSectionToDelete);
                storageSectionToDelete(null);
              }}
              className="ml-4"
            />
          </div>
        </Modal>
      )}

      {storageSectionStatusUpdate && (
        <Modal
          title={`Update Status`}
          onClose={() => {
            setStorageSectionStatusUpdate(null);
          }}
          minWidth={"845px"}
          maxWidth={"845px"}>
          <div className="flex">
            <div className="w-64">
              <div className="text-base font-medium leading-22 tracking-normal text-left">
                Current Status
              </div>
              <div className="mt-2">
                <StorageSectionColorBadge
                  status={storageSectionStatusUpdate.currentStatus}
                />
              </div>
            </div>
            <div>
              <div className="text-base font-medium leading-22 tracking-normal text-left">
                Select New Status
              </div>
              <div className="mt-2">
                <DropdownInput
                  name={"status"}
                  onChange={(e) => {
                    setStorageSectionStatusUpdate({
                      ...storageSectionStatusUpdate,
                      status: e.target.value,
                    });
                  }}
                  value={storageSectionStatusUpdate.status}
                  width={"w-60"}
                  options={Object.values(STORAGE_SECTION_STATUSES).map(
                    (status) => {
                      return {
                        value: status,
                        label: status
                          .replace(/_/g, " ")
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1),
                          )
                          .join(" "),
                      };
                    },
                  )}
                  valueKey={"value"}
                  labelKey={"label"}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setStorageSectionStatusUpdate(null);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Save"}
              colour={"primaryAccent"}
              onClick={submitStorageSectionStatusUpdate}
              className="ml-4"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

const StorageSectionColorBadge = ({ status }) => {
  let modifiedStatusToDisplay = status.replace(/_/g, " ");
  // capitalize every word
  modifiedStatusToDisplay = modifiedStatusToDisplay
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  switch (status) {
    case STORAGE_SECTION_STATUSES.ACTIVE:
      return <GreenBadge text={modifiedStatusToDisplay} />;
    case STORAGE_SECTION_STATUSES.UNDER_REPAIR:
      return <YellowBadge text={modifiedStatusToDisplay} />;
      break;
    case STORAGE_SECTION_STATUSES.AVAILABLE:
      return <GreenBadge text={modifiedStatusToDisplay} />;
    case STORAGE_SECTION_STATUSES.UNAVAILABLE:
      return <RedBadge text={modifiedStatusToDisplay} />;
    case STORAGE_SECTION_STATUSES.DISABLED:
      return <RedBadge text={modifiedStatusToDisplay} />;
  }
};

const GreenBadge = ({ text }) => {
  return (
    <div className="bg-green-100 text-green-800 text-sm h-8 w-max pt-1.5 font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
      {text}
    </div>
  );
};

const YellowBadge = ({ text }) => {
  return (
    <div className="bg-yellow-100 text-yellow-800 text-sm h-8 w-max pt-1.5 font-medium me-2 px-2.5 py-0.5 rounded-full ">
      {text}
    </div>
  );
};

const RedBadge = ({ text }) => {
  return (
    <div className="bg-red-100 text-red-800 text-sm h-8 w-max pt-1.5 font-medium me-2 px-2.5 py-0.5 rounded-full ">
      {text}
    </div>
  );
};

export default WarehouseStorageSections;
