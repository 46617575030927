import TextField from "#components/utils/TextField";
import validator from "validator";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const ResetPasswordBox = ({
  onSubmit,
  onChange,
  resetPassword,
  tenant,
  values,
}) => {
  const notValid = !validator.isEmail(values.email);
  return (
    <div className="bg-white xs:10/12 sm:w-8/12 md:6/12 max-w-xl min-w-xl rounded-2xl p-10 flex flex-col space-y-6 items-center justify-center">
      <div className="text-primaryAccent text-2xl font-medium">
        Reset Password
      </div>
      <div className="text-454A4F text-2xl font-medium">
        {tenant
          ? `Welcome back, team ${tenant.name}`
          : "Welcome to Hopstack Inc."}
      </div>
      <form
        onSubmit={onSubmit}
        className="w-full flex-col space-y-8 items-center justify-center"
        noValidate>
        <TextField
          label="Email"
          id="email"
          type="email"
          placeholder=" "
          onChange={onChange}
          value={values.email}
          error={(value) => {
            if (!validator.isEmail(value)) {
              return "Please enter a valid email";
            }
            return "";
          }}
        />
        <div className="w-full flex items-center justify-center">
          <input
            type="submit"
            disabled={notValid}
            className={`${
              notValid ? "opacity-60" : ""
            } cursor-pointer inline-flex justify-center p-4 text-2xl font-normal text-textWhite bg-primaryAccent font-montserrat border border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none w-64`}
            value="Reset Password"
          />
        </div>
        <div
          onClick={resetPassword}
          className="cursor-pointe flex justify-center items-center gap-2 font-medium text-lg text-primaryAccent">
          <ArrowLeftIcon className="h-6 w-6" />
          Back to log in
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordBox;
