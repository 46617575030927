import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Autocomplete from "#components/utils/Autocomplete";
import _ from "lodash";

const ReceivingConsignmentForm = ({
  onClose,
  title,
  receivingConsignment,
  setReceivingConsignment,
  onSubmit,
  debouncedBinLocationSearch,
  binLocationsOptions,
  onChangeDropdown,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      id="userFormModal"
      onClose={onClose}
      positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Receiving Details
          </span>
        </div>

        <>
          <div>
            <TextField
              type="date"
              label="Best By/Expiry Date (optional)"
              placeholder=" "
              onChange={(e) =>
                setReceivingConsignment({
                  ...receivingConsignment,
                  bestByDate: e.target.value,
                })
              }
              value={receivingConsignment.bestByDate}
              name={"bestByDate"}
            />
          </div>
          <div>
            <TextField
              type="text"
              label=""
              placeholder="Pallet ID (optional)"
              onChange={(e) =>
                setReceivingConsignment({
                  ...receivingConsignment,
                  palletId: e.target.value,
                })
              }
              value={receivingConsignment.palletId}
              name={"palletId"}
            />
          </div>
          <div>
            <TextField
              type="text"
              label=""
              placeholder="Lot ID / Batch ID (optional)"
              onChange={(e) =>
                setReceivingConsignment({
                  ...receivingConsignment,
                  lotId: e.target.value,
                })
              }
              value={receivingConsignment.lotId}
              name={"lotId"}
            />
          </div>
          {!receivingConsignment?.dropship && (
            <div>
              <Autocomplete
                options={binLocationsOptions?.entities}
                labelKey="barcode"
                valueKey="barcode"
                placeholder="Bin Location (optional)"
                onChange={(e) => onChangeDropdown("binLocation", e)}
                onKeyDown={(e) => debouncedBinLocationSearch(e)}
                value={receivingConsignment?.binLocation}
                id="BIN_LOCATION"
              />
            </div>
          )}
        </>
      </div>
    </Modal>
  );
};
export default ReceivingConsignmentForm;
