import { useState, useEffect, useContext } from "react";
import TextField from "#components/utils/TextField";
import { useQuery } from "#hooks/useQuery";
import {GET_USER} from "#queries"
import { SAVE_USER } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import ModalButton from "#components/utils/ModalButton";
import { MasterDataContext } from "#contexts/masterData";
import Modal from "#components/utils/Modal";
import { useHistory } from "react-router-dom";

const AcceptTermsAndConditionsLayout = ({ user, onLogout }) => {
  const appState = useContext(AppStateContext);
  const { push } = useHistory();
  const saveUserQuery = useQuery(SAVE_USER);
  const masterData = useContext(MasterDataContext);
  const getUserQuery = useQuery(GET_USER);
  const [fetchedUser, setFetchedUser] = useState(null);

  useEffect(() => {
    getUserQuery.fetchData({id: user.id});
  }, []);

  useEffect(() => {
    if (getUserQuery.data) {
      setFetchedUser(getUserQuery.data.user);
    }
    if (getUserQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

  useEffect(() => {
    if (saveUserQuery.data) {
      appState.hideConfirmation();
      push("/");
      window.location.reload();
    }

    if (saveUserQuery.error) {
      appState.setAlert(saveUserQuery.error.message, "error", 5000);
      appState.hideConfirmation();
    }

    if (saveUserQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [saveUserQuery.loading, saveUserQuery.data, saveUserQuery.error]);

  return (
    <Modal onClose={onLogout}>
      <div className="space-y-4 text-center text-2xl font-medium text-primaryAccent">
        <div className="mb-2">{<RenderImage />}</div>
        <p className="pb-8 font-bold">We've updated our T&C</p>
        <p>
          We have updated our{" "}
          <a
            className="underline"
            href="https://www.hopstack.io/terms-of-service">
            Terms Of Service
          </a>
          ,{" "}
          <a className="underline" href="https://www.hopstack.io/terms-of-use">
            Terms of Use
          </a>{" "}
          and{" "}
          <a className="underline" href="https://hopstack.io/privacy-policy">
            Privacy Policy
          </a>
          . In order to continue using the Hopstack platform, you'll need to
          review and accept these updates. We appreciate your cooperation.
          <br />
          <br />
          Please note, that clicking on Deny will log you out of the platform.
          <br />
          <br />
          If you have any questions, please contact us at support@hopstack.io
        </p>
      </div>
      <div className="flex justify-evenly mt-5 text-2xl w-full pb-10 pt-10">
        <button
          className="p-2 pl-20 pr-20 bg-green-500 text-white"
          onClick={() =>
            saveUserQuery.fetchData({
              ...fetchedUser,
              termsAndConditionsAccepted: true,
            })
          }>
          Accept
        </button>
        <button
          className="p-2 pl-20 pr-20 bg-red-500 text-white"
          onClick={onLogout}>
          Deny
        </button>
      </div>
    </Modal>
  );
};

const RenderImage = () => (
  <div className="flex justify-center items-center">
    <img
      className="h-16 w-auto"
      src="https://hopstack-pub.s3.amazonaws.com/logo.png"
      alt="Hopstack"
    />
    <h1 className="font-hammersmith text-3xl text-primaryAccent px-2 font-normal">
      Hopstack Inc
    </h1>
  </div>
);

export default AcceptTermsAndConditionsLayout;
