import Checkbox from "#components/utils/Checkbox";
import CatalogProductActions from "./CatalogProductActions";

const ListViewProducts = ({
  products,
  selectAllRows,
  allRowsSelected,
  selectProduct,
  selectedProducts,
  getCatalog,
  actionsOnProduct,
  productCategories,
  shiftKeyPressed,
  selectAllDisabled,
}) => {
  return (
    <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg overflow-y-visible">
      <table className="min-w-full divide-y divide-gray-300 overflow-y-visible">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 text-left text-lg font-normal text-[#717679] sm:pl-6">
              <Checkbox
                role="checkbox"
                onChange={selectAllRows}
                name="selectAllRows"
                checked={allRowsSelected}
                disabled={!!selectAllDisabled}
              />
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-lg font-normal text-[#717679] sm:pl-6">
              Product Info
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Description
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Category
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Price
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Customer
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-normal text-[#717679]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {!products.length && (
            <tr>
              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                <div className="text-[#111827] text-lg">No products found</div>
              </td>
            </tr>
          )}
          {products?.length > 0 &&
            products.map((product, rowIndex) => (
              <tr key={product.sku}>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  <Checkbox
                    role="checkbox"
                    onChange={(_) => {
                      selectProduct(rowIndex, shiftKeyPressed);
                    }}
                    name="product"
                    value={product.id}
                    checked={selectedProducts?.includes(product.id)}
                    disabled={!!selectAllDisabled}
                  />
                </td>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 overflow-ellipsis">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => getCatalog(product.id)}>
                    <div className="h-16 w-16 flex-shrink-0">
                      {product.images?.[0]?.url && (
                        <img
                          className="h-16 w-16 rounded-lg"
                          src={product.images?.[0]?.url}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="ml-4">
                      <div className="mt-1 text-[#111827] text-lg">
                        {product.name}
                      </div>
                      <div className="font-medium text-[#717679] text-lg">
                        SKU: {product.sku}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-3 py-4 text-lg text-[#111827]">
                  {product.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-lg text-[#111827]">
                  {product.category
                    ? productCategories?.find((i) => i.id === product.category)
                        ?.name || "-"
                    : "-"}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-lg text-[#111827]">
                  {product.pricing?.computedPriceFields?.totalPrice
                    ? `$${product.pricing?.computedPriceFields?.totalPrice.toFixed(
                        2,
                      )}`
                    : "-"}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-lg text-[#111827]">
                  All
                </td>
                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-lg text-[#111827]">
                  <CatalogProductActions
                    actionsOnProduct={actionsOnProduct}
                    product={product}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListViewProducts;
