import { useForm, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import { useContext } from "react";

import {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
} from "../../utils/Metrics";

const RateShoppingLtlPallet = ({ onPalletSubmit, palletInfo }) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors: palletErrors },
  } = useForm({
    defaultValues: {
      pallets:
        palletInfo.length > 0
          ? palletInfo
          : [
              {
                length: 48,
                width: 40,
                height: null,
                weight: null,
              },
            ],
    },
  });

  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  const preferredWeightUnit = useWeightUnitAbbreviation();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "pallets", // unique name for your Field Array
    rules: { minLength: 1, required: "Atleast one pallet is required" },
  });

  const savePallets = (data) => {
    onPalletSubmit && onPalletSubmit(data.pallets);
  };

  const duplicatePallet = (index) => {
    append(getValues(`pallets.${index}`));
  };

  return (
    <form onSubmit={handleSubmit(savePallets)}>
      <div className="border-2 rounded-lg p-4 mb-5">
        {fields.map((field, index) => (
          <div key={field.id}>
            <div className="flex justify-between">
              <div className="mt-5">Pallet {index + 1}</div>
              <div>
                <div
                  className="inline-block mt-4 text-2C7695 cursor-pointer"
                  onClick={() => duplicatePallet(index)}>
                  Duplicate
                </div>
                <div
                  className="inline-block mt-4 text-red-500 cursor-pointer pl-2"
                  onClick={() => remove(index)}>
                  Remove
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between">
              <div className="flex-grow-1">
                <label className="text-sm">
                  Length({preferredDimensionUnit})
                </label>
                <input
                  type="number"
                  className={`border-1 block p-2 w-full focus:outline-none text-lg bg-transparent font-montserrat placeholder-gray-400 ${
                    palletErrors?.pallets?.[index]?.length?.message
                      ? "border-red-500"
                      : "border-2C7695"
                  }`}
                  {...register(`pallets.${index}.length`, {
                    required: "Required",
                    valueAsNumber: true,
                  })}
                />
                <label className="absolute text-sm text-red-500 pl-1">
                  {palletErrors?.pallets?.[index]?.length?.message}
                </label>
              </div>
              <div className="flex-grow-1">
                <label className="text-sm">
                  Width({preferredDimensionUnit})
                </label>
                <input
                  type="number"
                  className={`border-1 block p-2 w-full focus:outline-none text-lg bg-transparent font-montserrat placeholder-gray-400 ${
                    palletErrors?.pallets?.[index]?.width?.message
                      ? "border-red-500"
                      : "border-2C7695"
                  }`}
                  {...register(`pallets.${index}.width`, {
                    required: "Required",
                    valueAsNumber: true,
                  })}
                />
                <label className="absolute text-sm text-red-500 pl-1">
                  {palletErrors?.pallets?.[index]?.width?.message}
                </label>
              </div>
              <div className="flex-grow-1">
                <label className="text-sm">
                  Height({preferredDimensionUnit})
                </label>
                <input
                  type="number"
                  className={`border-1 block p-2 w-full focus:outline-none text-lg bg-transparent font-montserrat placeholder-gray-400 ${
                    palletErrors?.pallets?.[index]?.height?.message
                      ? "border-red-500"
                      : "border-2C7695"
                  }`}
                  {...register(`pallets.${index}.height`, {
                    required: "Required",
                    valueAsNumber: true,
                  })}
                />
                <label className="absolute text-sm text-red-500 pl-1">
                  {palletErrors?.pallets?.[index]?.height?.message}
                </label>
              </div>
              <div className="flex-grow-1">
                <label className="text-sm">Weight({preferredWeightUnit})</label>
                <input
                  type="number"
                  className={`border-1 block p-2 w-full focus:outline-none text-lg bg-transparent font-montserrat placeholder-gray-400 ${
                    palletErrors?.pallets?.[index]?.weight?.message
                      ? "border-red-500"
                      : "border-2C7695"
                  }`}
                  {...register(`pallets.${index}.weight`, {
                    required: "Required",
                    valueAsNumber: true,
                  })}
                />
                <label className="absolute text-sm text-red-500 pl-1">
                  {palletErrors?.pallets?.[index]?.weight?.message}
                </label>
              </div>
            </div>
          </div>
        ))}
        <div className="text-red-500">
          {palletErrors?.pallets?.root?.message}
        </div>
        <div className="block">
          <div
            className="mt-5 cursor-pointer text-2C7695 inline-block"
            onClick={() =>
              append({
                length: 48,
                width: 40,
                height: null,
                weight: null,
              })
            }>
            + Add Pallet
          </div>
        </div>
        {onPalletSubmit && (
          <div className="text-right">
            <input
              type="submit"
              className="inline-block right mt-2 text-l text-white w-20 rounded-md text-center py-2 cursor-pointer bg-2C7695"
              value="Save"
            />
          </div>
        )}
      </div>
    </form>
  );
};

RateShoppingLtlPallet.propTypes = {
  onPalletSubmit: PropTypes.func,
  palletInfo: PropTypes.arrayOf({
    length: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default RateShoppingLtlPallet;
