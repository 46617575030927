import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { XIcon } from "@heroicons/react/solid";

const Modal = ({ title, onClose, minWidth, maxWidth, children }) => {
  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto userFormModal"
          onClose={onClose}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            </Transition.Child>
            <div className="flex items-center justify-center min-h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <div
                  className={`inline-block mx-auto overflow-visible text-left align-middle transition-all transform bg-white shadow-xl border-blue-500`}
                  style={{ minWidth, maxWidth }}>
                  <div className="flex justify-center px-6 pt-6 pb-4">
                    <h1 className="mr-auto text-2xl font-semibold leading-7 tracking-normal text-left text-black">
                      {title}
                    </h1>
                    <XIcon
                      className="w-6 h-6 cursor-pointer"
                      onClick={onClose}
                    />
                  </div>
                  <div className="px-6 pb-6">{children}</div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { Modal };
