import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

const ShipperForm = ({
  onClose,
  title,
  onChange,
  selectedShipper,
  onSubmit,
  warehouses,
  onChangeMultiSelect,
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      {warehouses.length > 1 && (
        <div>
          <div id="selectingStations">
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
                Select Warehouses
              </span>
            </label>
            <MultiSelectAutoComplete
              options={warehouses}
              labelKey={"name"}
              valueKey={"id"}
              setValues={(selectedOptions) =>
                onChangeMultiSelect("warehouses", selectedOptions)
              }
              values={selectedShipper.warehouses || []}
              emptyValuesAccountsForAll={true}
            />
          </div>
        </div>
      )}
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedShipper.name}
        />
      </div>
    </div>
  </Modal>
);

export default ShipperForm;
