import { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useQuery } from "#hooks/useQuery";
import { VERIFY_EMAIL } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";

const withEmailVerificationLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const history = useHistory();
    const verifyEmailQuery = useQuery(VERIFY_EMAIL);
    const [expired, setExpired] = useState(false);
    const { token } = useParams();

    useEffect(() => {
      verifyEmailQuery.fetchData({ token });
    }, []);

    useEffect(() => {
      if (verifyEmailQuery.loading) {
        appState.setLoading();
      } else {
        if (verifyEmailQuery.error && verifyEmailQuery.error.message) {
          setExpired(true);
          appState.setAlert(verifyEmailQuery.error.message, "error", 2000);
          history.push("/");
        }
        if (verifyEmailQuery.data && verifyEmailQuery.data.verifyEmail) {
          setExpired(false);
          appState.setAlert(
            verifyEmailQuery.data.verifyEmail.message,
            "success",
            2000
          );
          history.push("/");
        }
        appState.removeLoading();
      }
    }, [
      verifyEmailQuery.loading,
      verifyEmailQuery.error,
      verifyEmailQuery.data,
    ]);

    return <WrappedComponent expired={expired} />;
  };
};

export default withEmailVerificationLogic;
