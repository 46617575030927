import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import moment from "moment-timezone";
import PaginationNavigationButtons from "#components/common/PaginationNavigationButtons";
import Modal from "#components/utils/Modal";
import CustomSelectDropDown from "#components/common/CustomSelectDropDown";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";

const headers = [
  "Order ID",
  "Carrier",
  "Tracking #s",
  "Consignment Date",
  "Action",
];

const ListView = ({
  consignmentsData,
  checkConsignmentById,
  filters,
  setFilters,
  submitFilters,
  checkPagination,
  statusList,
}) => {
  const [consignmentItems, setConsignmentItems] = useState(null);
  return (
    <div className="flex-1 max-w-4xl">
      <div className="flex justify-between">
        <PageTitle>Total ({consignmentsData.total}) </PageTitle>
        <div className="flex items-right">
          <CustomSelectDropDown
            options={statusList}
            defaultValue={statusList[0]}
            placeholder="Status"
            onChange={(option) => {
              setFilters({
                ...filters,
                status: option.filterValue,
              });
            }}
            className="min-w-1/4"
          />
          <PaginationNavigationButtons
            perPage={consignmentsData.perPage}
            pageNumber={consignmentsData.pageNumber}
            total={consignmentsData.total}
            nextPage={() => checkPagination("forward")}
            prevPage={() => checkPagination("backward")}
          />
        </div>
      </div>
      <div className="relative">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block p-4 pl-10 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search"
          onChange={(e) =>
            setFilters({
              ...filters,
              keyword: e.target.value,
            })
          }
          value={filters["keyword"]}
          onKeyDown={(e) => (e.key === "Enter" ? submitFilters() : {})}
        />
      </div>
      <div className="bg-EBEBEB mt-10 rounded-md">
        <table className="min-w-full divide-y divide-gray-200 -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {consignmentsData.consignments.length === 0 && (
              <tr>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
                  No Consignments.
                </td>
              </tr>
            )}
            {consignmentsData.consignments &&
              consignmentsData.consignments.map((item, index) => (
                <tr
                  key={item.id}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  } cursor-pointer`}>
                  <td
                    className={`cursor-pointer p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}
                    onClick={() => {
                      item.workingList && item.workingList.length > 0
                        ? setConsignmentItems(
                            item.workingList.map((i) => ({
                              ...i,
                              item: i.id,
                            })),
                          )
                        : setConsignmentItems(item.workingList);
                    }}>
                    {item.orderId}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.supplier}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.trackingNumber &&
                    typeof item.trackingNumber === "object" &&
                    item.trackingNumber.length > 0
                      ? item.trackingNumber.join(",")
                      : item.trackingNumber}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.consignmentDate
                      ? moment(item.consignmentDate).format("MMM Do YYYY")
                      : null}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    <div
                      className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white"
                      onClick={() => checkConsignmentById({ data: item.id })}>
                      Work on this
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {consignmentItems && consignmentItems.length > 0 && (
        <Modal
          negativeAction={() => setConsignmentItems(null)}
          title={`Items in consignment (${consignmentItems.length})`}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="p-4 rounded-full">
              <tr className="text-white font-montserratborder-left bg-primaryAccent">
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                  ASIN
                </th>

                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                  SKU
                </th>

                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                  Name
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                  To be received
                </th>
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                  Form Factor
                </th>
              </tr>
            </thead>
            <tbody>
              {consignmentItems.map((item, index) => (
                <tr
                  key={item.sku}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider max-w-2xl">
                    {item.sku}
                  </td>
                  {/* <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.sellerSku}
                  </td> */}
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider max-w-2xl">
                    {item.productName}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.quantity}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.availableQuantity}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.formFactor}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};

export default ListView;
