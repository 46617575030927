import moment from "moment-timezone";
import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import {
  EyeIcon,
  DocumentSearchIcon,
  DotsVerticalIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";
import { PrinterIcon } from "@heroicons/react/solid";
import { Menu } from "@headlessui/react";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import AddButton from "#components/utils/AddButton";
import Checkbox from "#components/utils/Checkbox";
import SearchSortFilter from "#components/common/SearchSortFilter";

const headers = [
  "SKU",
  "Name",
  "Date Received",
  "Quantity",
  "Status",
  "Action",
];
const noValuesText = "No Received SKUs";

const ReceivedSkusList = ({
  receivedSkus,
  getAudit,
  masterData,
  filters,
  onChangeFilter,
  selectedReceivedSkus,
  setSelectedReceivedSkus,
  selectedSkus,
  selectSku,
  runAutoBatching,
  clearSelectedSkus,
  getSku,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  fetchReceivedSku,
}) => {
  const menuItems = [
    // {
    //   title: "Audit Trail",
    //   icon: DocumentSearchIcon,
    //   onClick: getAudit,
    //   vars: "id",
    // },
    {
      title: "Edit",
      icon: PencilAltIcon,
      onClick: fetchReceivedSku,
      vars: "id",
    },
  ];
  return (
    <div className="tableWrapper">
      <div className="py-5 flex">
        <div className="flex-1 justify-between flex min-w-max h-10 items-center">
          <div className="w-96 flex space-x-4">
            <RoundedDropdown
              placeholder={"Status"}
              list={[{ name: "UNPROCESSED" }, { name: "RECEIVING_COMPLETED" }]}
              labelKey="name"
              valueKey="name"
              name="status"
              selectedValue={
                filters["status"] &&
                filters["status"].length == 1 &&
                filters["status"][0]
              }
              setSelected={(e) => onChangeFilter("status", [e])}
            />
          </div>
          {/* {!selectedSkus ||
            (selectedSkus.length === 0 && (
              <div className="flex space-x-2">
                <AddButton text="Run Auto-Batching" onClick={runAutoBatching} />{" "}
                <AddButton
                  text="Export to CSV"
                  onClick={() => alert("Exporting")}
                />
              </div>
            ))} */}
          {/* {selectedSkus.length > 0 && (
            <div className="flex space-x-2">
              <AddButton text="Create Manual Batch" onClick={() => alert()} />{" "}
              <AddButton text="Clear" onClick={clearSelectedSkus} />{" "}
            </div>
          )} */}
        </div>
      </div>

      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Date",
            value: "dateReceived",
          },
          {
            name: "Status",
            value: "status",
          },
          {
            name: "SKU",
            value: "sku",
          },
          {
            name: "Name",
            value: "name",
          },
        ]}
      />
      <table className="min-w-full divide-y divide-gray-200 mb-20">
        <thead className="p-4 rounded-full bg-primaryAccent">
          <tr className="text-textWhite font-montserrat border-left">
            <th
              scope="col"
              className="pl-4 px-1 py-3 text-left tracking-wider font-medium"></th>
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {receivedSkus.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
              <th
                scope="col"
                className="pl-4 px-1 py-3 text-left tracking-wider font-medium"></th>
            </tr>
          ) : null}
          {receivedSkus.map((receivedSku, index) => (
            <tr
              key={receivedSku.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                <Checkbox
                  role="checkbox"
                  onChange={(e) => selectSku(receivedSku.id)}
                  name="order"
                  value={receivedSku.id}
                  checked={selectedSkus.includes(receivedSku.id)}
                />
              </td>

              <td
                className="pl-4 px-1 py-1 text-left font-medium text-primaryAccent tracking-wider rounded-br rounded-tr cursor-pointer"
                onClick={() => getSku(receivedSku.sku)}>
                {receivedSku.sku}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {receivedSku.name} | {receivedSku.attributes?.colorName} |{" "}
                <span className="text-red-500">
                  Size: {receivedSku.attributes?.sizeName}
                </span>
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {moment(receivedSku.dateReceived).format("MMM Do YYYY, h:mm a")}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {receivedSku.quantity}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {receivedSku.status}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {/* <TableFieldButton
                text={<EyeIcon className="w-6 h-6" />}
                onClick={() => alert()}
              /> */}
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button>
                      <TableFieldButton
                        text={<DotsVerticalIcon className="w-6 h-6" />}
                      />
                    </Menu.Button>
                  </div>
                  <Menu.Items className="z-50 absolute right-0 w-56 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <div className="">
                      {menuItems.map((menuItem, idx) => (
                        <Menu.Item key={idx}>
                          {({ active }) => (
                            <button
                              className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                              onClick={() =>
                                menuItem["onClick"](
                                  receivedSku[menuItem["vars"]],
                                )
                              }>
                              {menuItem.icon && (
                                <menuItem.icon className="w-8 h-8 pr-2" />
                              )}
                              {menuItem.title}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReceivedSkusList;
