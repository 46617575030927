import { useState } from "react";
import CatalogEnhancedSearch from "#components/catalogs/catalogTabs/CatalogEnhancedSearch";
import {
  PlusIcon,
  ViewListIcon,
  ViewGridIcon,
  MinusIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import noFilterIcon from "#static/images/nofilter.png";
import ListViewProducts from "#components/catalogs/catalogTabs/ListViewProducts";
import GridViewProducts from "#components/catalogs/catalogTabs/GridViewProducts";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";

const ManageCatalogForm = ({
  catalog,
  setCatalog,
  submitCatalog,
  productCategories,
  componentAlert,
  setComponentAlert,
}) => {
  const [showListView, setShowListView] = useState(true);
  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }

    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] p-4 rounded-md flex items-center border-[${borderColor}] border-2 mb-2`}>
        <span className="mr-2 flex-1 flex items-center text-lg space-x-2">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="text-[#717679] font-bold float-right flex items-center justify-center"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };
  const actionsOnProduct = (product) => {
    if (catalog?.products?.includes(product.id)) {
      return [
        {
          name: "Remove from Catalog",
          value: "removeFromCatalog",
          icon: <MinusIcon className="h-6 w-6" />,
          onClick: () => {
            setCatalog({
              ...catalog,
              products: catalog.products.filter((id) => id !== product.id),
            });
            setComponentAlert({
              msg: "Product removed from catalog",
              alertType: "success",
            });
          },
        },
      ];
    }
    return [
      {
        name: "Add to Catalog",
        value: "addToCatalog",
        icon: <PlusIcon className="h-6 w-6" />,
        onClick: () => {
          setCatalog({
            ...catalog,
            products: [...catalog.products, product.id],
          });
          setComponentAlert({
            msg: "Product added to catalog",
            alertType: "success",
          });
        },
      },
    ];
  };
  return (
    <>
      <div className="p-6">
        {componentAlert && renderAlert(componentAlert)}
        <div className="text-2xl font-semibold">Basic Info</div>
        <div className="flex">
          <div className="catalog-textfield-wrapper">
            <label className="catalog-textfield-label">Catalog Name</label>
            <input
              className="catalog-textfield"
              type="text"
              value={catalog.name}
              onChange={(e) => setCatalog({ ...catalog, name: e.target.value })}
              placeholder="Enter catalog name"
            />
          </div>
          <div className="catalog-textfield-wrapper"></div>
        </div>
        <div className="text-2xl font-semibold my-6">Add Products</div>
        <div className="flex">
          <CatalogEnhancedSearch
            products={[]}
            selectProduct={() => {}}
            selectedProducts={[]}
            allRowsSelected={false}
            selectAllRows={() => {}}
            getCatalog={() => {}}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={false}
          />
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-left ${
                showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(true)}>
              <ViewListIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-right ${
                !showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(false)}>
              <ViewGridIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </span>
        </div>

        {!catalog?.products?.length && (
          <div className="flex-col items-center justify-center text-center p-16">
            <img src={noFilterIcon} className="w-64 mx-auto" />
            <div className="text-black text-2xl font-semibold font-inter mt-4 text-center">
              No products added
            </div>
            <div className="text-[#717679] text-2xl font-normal font-inter mt-4 text-center">
              Add products by using the search above
            </div>
          </div>
        )}
        {catalog?.displayProducts?.length > 0 && (
          <div className="overflow-y-scroll h-96 mt-4">
            {showListView ? (
              <ListViewProducts
                products={catalog.displayProducts}
                selectProduct={() => {}}
                selectedProducts={[]}
                allRowsSelected={false}
                selectAllRows={() => {}}
                getCatalog={() => {}}
                actionsOnProduct={actionsOnProduct}
                productCategories={productCategories}
                shiftKeyPressed={false}
                selectAllDisabled={true}
              />
            ) : (
              <GridViewProducts
                products={catalog.displayProducts}
                selectProduct={() => {}}
                selectedProducts={[]}
                allRowsSelected={false}
                selectAllRows={() => {}}
                getCatalog={() => {}}
                actionsOnProduct={actionsOnProduct}
                productCategories={productCategories}
                shiftKeyPressed={false}
                selectAllDisabled={true}
              />
            )}
          </div>
        )}

        <div className="flex items-end justify-end space-x-2 absolute bottom-4 right-4">
          <button
            className="border-primaryAccent border-2 text-primaryAccent p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
            onClick={() => setCatalog(null)}>
            Cancel
          </button>
          <button
            className="bg-primaryAccent border-primaryAccent border-2 text-white p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
            onClick={submitCatalog}>
            Create
          </button>
        </div>
      </div>
    </>
  );
};

export default ManageCatalogForm;
