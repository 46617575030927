import withGroupsLogic from "#components/HOC/withGroupsLogic";
import PageTitle from "#components/utils/PageTitle";
import GroupsList from "#components/groups/GroupsList";
import AddButton from "#components/utils/AddButton";
import { useHistory } from "react-router-dom";
import RoundedDropdown from "#components/utils/RoundedDropdown";

const Rules = ({
  rules,
  masterData,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  writable,
}) => {
  const history = useHistory();
  return (
    <div>
      <div className="flex items-center mb-4">
        <div className="flex-1">
          <PageTitle>Rules</PageTitle>
          <div className="w-96 flex space-x-4 mb-5">
            <RoundedDropdown
              placeholder={"Warehouse"}
              list={warehouses}
              labelKey="name"
              valueKey="id"
              name="warehouse"
              selectedValue={selectedWarehouse}
              setSelected={setSelectedWarehouse}
            />
            <RoundedDropdown
              placeholder={"Client"}
              list={customers}
              labelKey="name"
              valueKey="id"
              name="customer"
              selectedValue={selectedCustomer}
              setSelected={setSelectedCustomer}
            />
          </div>
        </div>

        <div className="items-center justify-end">
          <AddButton
            text="Add Rule"
            onClick={() => history.push("/rule")}
            disabled={!writable}
          />
        </div>
      </div>

      {!rules || rules.length === 0 ? (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true">
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No Rules</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new rule.
          </p>
          <div className="mt-6">
            <button
              type="button"
              className={`inline-flex items-center text-sm font-medium text-white ${
                writable ? "bg-2C7695" : "bg-gray-500"
              } font-montserrat font-medium border-transparent rounded-full ring-0 focus:ring-0 outline-none focus:outline-none px-4 py-2`}
              onClick={() => history.push("/rule")}
              disabled={!writable}>
              Add Rule
            </button>
          </div>
        </div>
      ) : (
        <GroupsList
          masterData={masterData}
          writable={writable}
        />
      )}
    </div>
  );
};

export default withGroupsLogic(Rules);
