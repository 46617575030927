import { useState, useEffect, useContext } from "react";
import { useTempQuery } from "#hooks/useTempQuery";
import { useQuery } from "#hooks/useQuery";
import {
  GET_GROUPS,
  GET_GROUP,
  GET_RULE_ENGINE_MASTER_DATA,
  GET_CUSTOMERS,
  GET_WAREHOUSES,
} from "#queries";
import { SAVE_GROUP, DELETE_GROUP } from "#mutations";
import _ from "lodash";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import { AppStateContext } from "#contexts/appState";

const withGroupsLogic = (WrappedComponent) => {
  return (props) => {
    const appState = useContext(AppStateContext);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const groupsQuery = useTempQuery(GET_GROUPS);
    const masterDataQuery = useTempQuery(GET_RULE_ENGINE_MASTER_DATA);
    const customersQuery = useQuery(GET_CUSTOMERS);
    const warehousesQuery = useQuery(GET_WAREHOUSES);
    const getGroupQuery = useTempQuery(GET_GROUP);
    const saveGroupQuery = useQuery(SAVE_GROUP);
    const deleteGroupQuery = useQuery(DELETE_GROUP);

    useEffect(() => {
      groupsQuery.fetchData();
      masterDataQuery.fetchData();
      customersQuery.fetchData();
      warehousesQuery.fetchData();
    }, []);

    useEffect(() => {
      if (groupsQuery.loading || masterDataQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      groupsQuery.loading,
      groupsQuery.error,
      groupsQuery.data,
      masterDataQuery.loading,
      masterDataQuery.error,
      masterDataQuery.data,
    ]);

    if (!groupsQuery.loading || groupsQuery.data) {
      return (
        <WrappedComponent
          groups={groupsQuery.data ? groupsQuery.data.groups : []}
          fetchGroup={(id) => getGroupQuery.fetchData({ id })}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          saveGroup={(group) => {
            saveGroupQuery.fetchData({ ...group });
            getGroupQuery.fetchData({ id: null });
          }}
          masterData={
            masterDataQuery.data ? masterDataQuery.data.masterData : {}
          }
          customers={
            customersQuery.data ? customersQuery.data.customers.entities : []
          }
          warehouses={
            warehousesQuery.data ? warehousesQuery.data.warehouses.entities : []
          }
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={(e) => {
            setSelectedCustomer(e);
          }}
          writable={props.writable}
        />
      );
    } else {
      return <LoadingIndicator />;
    }
  };
};

export default withGroupsLogic;
