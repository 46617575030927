import CurrentCasePackBoxes from "#components/packer/CurrentCasePackBoxes";

const ConfirmBoxContents = ({
  currentBatch,
  weight,
  setWeight,
  order,
  restartActivity,
  pauseActivity,
  unpackItem,
  confirmBoxes,
  setWeightOfBox,
  handleFindAndPack,
  isAddItemQuantityExceed,
  isSetAddItemQuantityExceed,
  itemsToUnPack,
  setItemsToUnPack,
  handleFindAndUnpackItem,
  groupedCasesBySku,
  setGroupedCasesBySku,
}) => {
  return (
    <>
      <div className="w-full mt-20 flex items-center justify-center">
        <div className="flex space-x-4 mt-24">
          <div className="max-h-xs bg-white border rounded-2xl shadow-lg">
            <CurrentCasePackBoxes
              currentBatch={currentBatch}
              order={order}
              weight={weight}
              setWeight={setWeight}
              setWeightOfBox={setWeightOfBox}
              unpackItem={unpackItem}
              handleFindAndPack={handleFindAndPack}
              isAddItemQuantityExceed={isAddItemQuantityExceed}
              isSetAddItemQuantityExceed={isSetAddItemQuantityExceed}
              itemsToUnPack={itemsToUnPack}
              setItemsToUnPack={setItemsToUnPack}
              handleFindAndUnpackItem={handleFindAndUnpackItem}
              groupedCasesBySku={groupedCasesBySku}
              setGroupedCasesBySku={setGroupedCasesBySku}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 flex items-center justify-center space-x-4">
        <div
          className="text-2xl text-white w-64 rounded-md bg-primaryAccent text-center py-4 cursor-pointer"
          onClick={confirmBoxes}>
          Confirm
        </div>
        <div
          className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4 cursor-pointer"
          onClick={pauseActivity}>
          Pause Packing
        </div>
      </div>
    </>
  );
};
export default ConfirmBoxContents;

const truncate = (str) => {
  return str && str.length > 100 ? str.substring(0, 100) + "..." : str;
};
