import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import {
  TrashIcon,
  PlusIcon,
  QrcodeIcon,
} from "@heroicons/react/outline";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import Toggle from "#components/utils/Toggle";
import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PrinterIcon } from "@heroicons/react/solid";
import { Menu } from "@headlessui/react";

const WorkflowForm = ({
  onClose,
  onChange,
  selectedWorkflow,
  onSubmit,
  onChangeMultiSelect,
  setEnabled,
  addScan,
  addPrint,
  removeAction,
  resultOptions,
}) => {
  const menuItems = [
    {
      title: "Add Entry",
      icon: QrcodeIcon,
      onClick: addScan,
    },
    {
      title: "Add Print",
      icon: PrinterIcon,
      onClick: addPrint,
    },
  ];
  return (
    <Modal title={"Add New"} negativeAction={onClose} positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Stage Details
          </span>
        </div>
        <div>
          <TextField
            type="text"
            id="name"
            label="Name"
            placeholder="Name"
            onChange={onChange}
            value={selectedWorkflow.name}
          />
        </div>
        {selectedWorkflow.workflowType === "INBOUND" && (
          <div className="flex space-x-4 items-center px-2">
            <div>
              <Toggle
                enabled={selectedWorkflow.lpnEnabled}
                setEnabled={(e) => onChangeMultiSelect("lpnEnabled", e)}
              />
            </div>
            <div className="text-lg">Enable LPN Generation</div>
          </div>
        )}

        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Positive Actions
          </span>
        </div>
        <div>
          <div id="selectingStations">
            <MultiSelectAutoComplete
              options={resultOptions}
              labelKey={"name"}
              valueKey={"value"}
              setValues={(e) => onChangeMultiSelect("positiveActions", e)}
              values={
                selectedWorkflow && selectedWorkflow.positiveActions
                  ? selectedWorkflow.positiveActions
                  : []
              }
            />
          </div>
        </div>
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Negative Actions
          </span>
        </div>
        <div>
          <div id="selectingStations">
            <MultiSelectAutoComplete
              options={resultOptions}
              labelKey={"name"}
              valueKey={"value"}
              setValues={(e) => onChangeMultiSelect("negativeActions", e)}
              values={
                selectedWorkflow && selectedWorkflow.negativeActions
                  ? selectedWorkflow.negativeActions
                  : []
              }
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div className="border bg-white border-black text-center">
            <div className="px-2 py-2 bg-primaryAccent flex items-center justify-center">
              <div className="flex-1 font-montserrat text-xl text-white">
                Display Fields
              </div>
            </div>
            {(!selectedWorkflow.displayFields ||
              selectedWorkflow.displayFields.length === 0) && (
              <div className="p-2 text-lg">No Fields</div>
            )}
            <div className="overflow-x-scroll max-h-72">
              {selectedWorkflow.displayFields &&
                selectedWorkflow.displayFields.map((item, idx) => (
                  <div className="flex space-x-4 items-center px-2">
                    <div>
                      <Toggle
                        enabled={item.enabled}
                        setEnabled={(e) => setEnabled(idx, e)}
                      />
                    </div>
                    <div className="text-lg">{item.name}</div>
                  </div>
                ))}
            </div>
          </div>
          <div className="border bg-white border-black text-center">
            <div className="px-2 py-2 bg-primaryAccent flex items-center justify-center">
              <div className="flex-1 font-montserrat text-xl text-white">
                Actions
              </div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button>
                    <TableFieldButton text={<PlusIcon className="w-4 h-4" />} />
                  </Menu.Button>
                </div>
                <Menu.Items className="z-50 absolute right-0 w-56 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                  <div className="">
                    {menuItems.map((menuItem, idx) => (
                      <Menu.Item key={idx}>
                        {() => (
                          <button
                            className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                            onClick={menuItem["onClick"]}
                          >
                            {menuItem.icon && (
                              <menuItem.icon className="w-8 h-8 pr-2" />
                            )}
                            {menuItem.title}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Menu>
            </div>
            {(!selectedWorkflow.actions ||
              selectedWorkflow.actions.length === 0) && (
              <div className="p-2 text-lg">No Actions</div>
            )}
            <div className="overflow-x-scroll max-h-72">
              {selectedWorkflow.actions &&
                selectedWorkflow.actions.map((item, idx) => (
                  <div className="flex space-x-4 items-center px-2 min-h-12 border-b border-black py-1">
                    {item.typeOfAction === "SCAN" ? (
                      <QrcodeIcon className="w-8 h-8" />
                    ) : (
                      <PrinterIcon className="w-8 h-8" />
                    )}

                    <div className="text-lg flex-1">
                      {item.fields.length > 0 &&
                        item.fields.map((i) => i.name).join(", ")}
                    </div>
                    {/* <PencilAltIcon className="w-8 h-8" onClick={() => {}} /> */}
                    <TrashIcon
                      className="w-8 h-8"
                      onClick={() => removeAction(idx)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WorkflowForm;
