import { useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import moment from "moment-timezone";
import ListView from "#components/prepper/ListView";
import TextField from "#components/utils/TextField";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import BundlePrepDetails from "#components/prepper/BundlePrepDetails";
import NumberField from "#components/utils/NumberField";

const DetailedView = ({
  currentBatch,
  confirmItem,
  currentItemMismatch,
  setCurrentItemMismatch,
  confirmPrepItem,
  simulateItemScan,
  workflow,
  printAllFnSkus,
  changeQuantity,
  subdomain,
  cancelItem,
  scanBarcode,
  restartActivity,
  pauseActivity,
  updateBestByDate,
  currentProduct,
  onChangeMultiSelect,
  tenant,
}) => {
  const [manualValue, setManualValue] = useState(null);
  const prepOptions = [
    { name: "FNSKU" },
    { name: "Poly bag" },
    { name: "Bubble wrap" },
    { name: "Bundle" },
    { name: "Bubble & bundle" },
    { name: "Handling" },
    { name: "S+L" },
  ];

  if (!currentBatch.currentItem) {
    return (
      <>
        <div className="w-full mt-20 flex max-w-4xl items-center justify-center">
          <div className="flex-col">
            <div className="bg-white border rounded-2xl shadow-lg">
              <div className="flex items-center justify-center pt-5 pb-5">
                <div className="border-r border-gray-400 p-5">
                  <div className="rounded-full p-6 bg-primaryAccent shadow-md">
                    <img
                      src="https://hopstack-pub.s3.amazonaws.com/icons/barcode_scan.png"
                      className="w-14"
                    />
                  </div>
                </div>
                <div className="flex-col">
                  <div className="text-xl font-medium text-454A4F py-5 px-5 pb-1">
                    Please Scan the Next Item
                  </div>
                  <div className="text-xl font-medium text-454A4F py-0 px-5 block">
                    Completion: {currentBatch.completion}%
                  </div>
                </div>
              </div>
              <div className="text-2xl font-montserrat items-center text-center">
                OR ENTER MANUALLY
              </div>
              <div className="flex-col items-center justify-center p-10 pt-5 pb-5">
                <div>
                  <TextField
                    type="text"
                    id="name"
                    label="Enter Value"
                    placeholder=" "
                    onChange={(e) => setManualValue(e.target.value)}
                    value={manualValue}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        scanBarcode(manualValue);
                        setManualValue("");
                      }
                    }}
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex items-center justify-center space-x-4">
          <div
            className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4 cursor-pointer"
            onClick={restartActivity}>
            Restart Prepping
          </div>
          <div
            className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4 cursor-pointer"
            onClick={pauseActivity}>
            Pause Prepping
          </div>
        </div>
      </>
    );
  }

  const { currentItem } = currentBatch;
  return (
    <div className="flex-col items-center justify-center max-w-7xl mx-auto">
      <div className="pt-8">
        <PageTitle>Product Details</PageTitle>
        <div className="grid grid-cols-3">
          <div
            className={`flex-1 bg-gray-100 w-full ${
              currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
            }`}>
            <div className="text-xl font-medium text-454A4F space-y-2">
              <LineItemDesc title={"SKU"} value={currentItem.sku} idx={1} />
              <LineItemDesc
                title={"Product"}
                value={truncate(currentItem.productName)}
                idx={2}
              />
              {currentItem.upc && (
                <LineItemDesc
                  value={currentItem?.upc?.join(", ")}
                  title="UPC"
                  idx={2}
                />
              )}

              <LineItemDesc
                title={"Total Quantity"}
                value={
                  currentBatch.workingList &&
                  _.sumBy(
                    currentBatch.workingList.filter(
                      (item) =>
                        item.sku.toString() === currentItem.sku.toString(),
                    ),
                    "quantity",
                  )
                }
                idx={2}
              />
              <LineItemDesc
                title={"Remaining Quantity"}
                value={
                  currentBatch.workingList &&
                  _.sumBy(
                    currentBatch.workingList.filter(
                      (item) =>
                        item.sku.toString() === currentItem.sku.toString() &&
                        item.status === "UNPROCESSED",
                    ),
                    "quantity",
                  )
                }
                idx={2}
              />
              {currentItem.fnSku && (
                <>
                  <LineItemDesc
                    title={"FN SKU"}
                    value={currentItem.fnSku}
                    idx={1}
                  />
                </>
              )}

              {currentItem.asin && (
                <LineItemDesc title={"ASIN"} value={currentItem.asin} idx={1} />
              )}

              {currentItem.prepInstructions &&
                currentItem.prepInstructions.length > 0 && (
                  <div className="w-full flex space-x-1 shadow-md">
                    <div
                      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 border-F4C261"
      }`}>
                      Amazon Prep Instructions
                    </div>
                    <div
                      className={`bg-white flex-1 border p-4 rounded-tr-md rounded-br-md space-y-2`}>
                      {currentItem.prepInstructions.map((item, idx) => (
                        <div key={idx} className="flex">
                          Instruction: {item.PrepInstruction} | Owner:{" "}
                          {item.PrepOwner}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          </div>
          {currentProduct?.images && (
            <div className="flex-1 flex items-center justify-center bg-gray-300">
              <WorkflowProductImages
                images={[
                  currentProduct.images.find(
                    (i) => i.purpose === "OPERATIONS",
                  ) || currentProduct.images[0],
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>
      </div>
      {currentItem.validScans && currentItem.validScans.length > 1 && (
        <BundlePrepDetails
          currentItem={currentItem}
          currentBatch={currentBatch}
          simulateItemScan={simulateItemScan}
        />
      )}

      {tenant?.settings?.activities?.prepping?.isAdditionalPrepInstructions && (
        <div className="flex mt-10 text-center items-center justify-center align-top">
          <div className="text-xl pr-2">Additional Prep Instructions</div>
          <MultiSelectAutoComplete
            options={prepOptions}
            labelKey={"name"}
            valueKey={"name"}
            setValues={(values) => {
              onChangeMultiSelect("additionalPrepInstructions", values);
            }}
            values={currentItem?.additionalPrepInstructions || []}
          />
        </div>
      )}

      <div className="flex mt-10 text-center items-center justify-center">
        <div className="text-xl pr-2">Quantity</div>
        <NumberField
          onChange={(e) => changeQuantity(parseInt(e.target.value))}
          placeholder="Quantity"
          value={currentItem.quantity}
          className="p-4 text-2xl"
          min="0"
        />
      </div>
      <div className="flex mt-10 text-center items-center justify-center">
        <div className="text-xl pr-2">Expiry Date</div>
        <input
          type="date"
          onChange={(e) => updateBestByDate(e.target.value)}
          placeholder="Select Expiry date"
          value={currentItem.bestByDate}
          className="p-4 rounded-md text-lg"
          min={moment().format("YYYY-MM-DD")}
        />
      </div>
      {currentItem.validScans &&
        currentItem.validScans.findIndex(
          (item) => item.scannedItem === false,
        ) === -1 &&
        currentItemMismatch === null && (
          <div className="mt-20 flex items-center justify-center space-x-4">
            <div className="text-2xl text-black w-64 text-center py-4">
              Have you prepped this item?
            </div>
            <div
              className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
              onClick={() => setCurrentItemMismatch("CONFIRMED")}>
              Yes
            </div>
            <div
              className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4"
              onClick={cancelItem}>
              Cancel
            </div>
          </div>
        )}
      {currentItem.validScans &&
        currentItem.validScans.findIndex(
          (item) => item.scannedItem === false,
        ) === -1 &&
        currentItemMismatch !== null && (
          <div className="mt-20 flex items-center justify-center space-x-4">
            <div
              className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4"
              onClick={() => setCurrentItemMismatch(null)}>
              Back
            </div>
            <div
              className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
              onClick={() => confirmPrepItem(currentItem)}>
              Confirm Prep
            </div>
            {!workflow && (
              <div
                className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
                onClick={() => setCurrentItemMismatch("CONFIRMED")}>
                Re-Print FNSKU
              </div>
            )}
          </div>
        )}
    </div>
  );
};

const LineItemDesc = ({ title, value, idx }) => (
  <div className="w-full flex space-x-1 shadow-md">
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className={`bg-white flex-1 border p-4 rounded-tr-md rounded-br-md`}>
      {["ASIN"].includes(title)
        ? buildMarketplaceHyperlink(value, title)
        : value}
    </div>
  </div>
);

export default DetailedView;

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};
