import React, { useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Toggle from "#components/utils/Toggle";
import { useContext } from "react";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { useQuery } from "#hooks/useQuery";
import { GET_USER } from "#queries";
import { SAVE_USER } from "#mutations";
import { useEffect } from "react";
import { AdjustmentsIcon } from "@heroicons/react/outline";

const EditColumns = ({
  headers,
  setShowHeaders,
  tableName,
  forceShowSidebar = false,
}) => {
  const [showSidebar, setShowSidebar] = useState(forceShowSidebar);
  const [selectAll, setSelectAll] = useState(true);

  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const saveUserQuery = useQuery(SAVE_USER);
  const getUserQuery = useQuery(GET_USER);
  const [fetchedUser, setFetchedUser] = useState(null);

  useEffect(() => {
    if (auth?.user?.id) getUserQuery.fetchData({ id: auth?.user?.id });
  }, []);

  useEffect(() => {
    if (getUserQuery.data) {
      setFetchedUser(getUserQuery.data.user);
    }
    if (getUserQuery.loading) {
      appState.setLoading();
    } else {
      appState.removeLoading();
    }
  }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

  useEffect(() => {
    if (saveUserQuery.data) {
      appState.setAlert("Table Preferences saved!", "success", 5000);
      setShowSidebar(false);
      setTimeout(() => window.location.reload(false), 2000);
    }
    if (saveUserQuery.error) {
      appState.setAlert(
        "Some error occurred. Please try later.",
        "error",
        5000,
      );
    }
  }, [saveUserQuery.data, saveUserQuery.error, saveUserQuery.loading]);

  const saveUserTablePreferences = () => {
    let preferences = auth.user?.tablePreferences?.filter(
      (table) => table.name !== tableName,
    );
    saveUserQuery.fetchData({
      ...fetchedUser,
      tablePreferences: [
        ...(preferences ?? []),
        { name: tableName, columns: headers },
      ],
    });
  };

  function onDragEnd(result) {
    if (result.destination.index === -1) return;
    const newHeaders = headers;
    const [reorderedheaders] = newHeaders.splice(result.source.index, 1);
    newHeaders.splice(result.destination.index, 0, reorderedheaders);
    setShowHeaders([...newHeaders.filter((header) => header.enabled)]);
  }

  return (
    <>
      {!showSidebar && (
        <AdjustmentsIcon
          className="w-10 h-10 cursor-pointer"
          onClick={() => setShowSidebar(!showSidebar)}
        />
      )}
      <div
        className={`top-0 right-0 w-full items-end fixed h-screen flex flex-col z-50 ${
          showSidebar ? "visible" : "hidden"
        }`}>
        <div className="w-1/3 z-50 bg-primaryAccent p-2 2xl:p-5 pl:5 opacity-100 2xl:pl-10 text-white h-screen">
          <button
            className={`flex justify-end text-white w-full h-4 2xl:h-10 items-center`}
            onClick={() => setShowSidebar(!showSidebar)}>
            <XIcon className="w-6 h-6 cursor-pointer" />
          </button>
          <h3 className="2xl:mt-16 mb-6 2xl:mb-10 text-lg 2xl:text-4xl font-semibold text-white">
            Columns
          </h3>
          <div className="flex w-min ml-1 gap-2 p-2 items-center bg-gray-800 border-2">
            <Toggle
              enabled={selectAll}
              setEnabled={() => {
                headers.forEach(
                  (header) => header.editable && (header.enabled = !selectAll),
                );
                let newHeaders = headers.filter((header) => header.enabled);
                setShowHeaders([...newHeaders]);
                setSelectAll(!selectAll);
              }}
            />
            <div className="text-xs 2xl:text-[16px] text-white">All</div>
          </div>
          <div className="h-[60vh] overflow-auto px-1">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="Column">
                {(provided) => (
                  <ul ref={provided.innerRef} {...provided.droppableProps}>
                    {headers.map((column, idx) => (
                      <Draggable
                        draggableId={column.id}
                        index={idx}
                        key={column.id}>
                        {(provided) => (
                          <li
                            id={column.id}
                            className="flex gap-2 my-2 p-2 items-center justify-between bg-gray-800 border-2"
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            ref={provided.innerRef}>
                            <div className="flex gap-2 items-center">
                              <Toggle
                                disabled={!column.editable}
                                enabled={column.enabled}
                                setEnabled={() => {
                                  let index = headers.findIndex(
                                    (obj) => obj.name == column.name,
                                  );
                                  headers[index].enabled =
                                    !headers[index].enabled;
                                  let newHeaders = headers.filter(
                                    (header) => header.enabled,
                                  );
                                  setShowHeaders([...newHeaders]);
                                }}
                              />
                              <div className="text-xs 2xl:text-[16px] text-white">
                                {column.name}
                              </div>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                              />
                            </svg>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="mt-8 2xl:mt-10 flex gap-4 text-body font-semibold text-white">
            <button
              onClick={saveUserTablePreferences}
              className="px-4 py-2 border bg-white text-black rounded-full">
              Save
            </button>
            <button
              onClick={() => setShowSidebar(!showSidebar)}
              className="px-4 py-2 border bg-red-500 rounded-full">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditColumns;
