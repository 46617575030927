import moment from "moment-timezone";
import _ from "lodash";
import TableFieldButton from "#components/utils/TableFieldButton";
import { TrashIcon } from "@heroicons/react/outline";
import SearchSortFilter from "#components/common/SearchSortFilter";
import QuickFilters from "../common/QuickFilters";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const headers = [
  "Unique Identifier",
  "No. of products",
  "Added Date",
  "Client",
  "Action",
];
const noValuesText = "No Bundles";

const BundlesList = ({
  bundles,
  setProducts,
  filters,
  onChangeFilter,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  customers,
  warehouses,
  deleteButtonClicked,
  writable,
}) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      {(customers.length > 1 || warehouses.length > 1) && (
        <QuickFilters
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          customers={customers}
          warehouses={warehouses}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      )}
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Unique Identifier",
            value: "uniqueIdentifier",
          },
          {
            name: "Added Date",
            value: "createdAt",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="p-4 rounded-full sticky top-0 left-0 bg-primaryAccent">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {bundles.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {bundles &&
            bundles.length > 0 &&
            bundles.map((bundle, index) => (
              <tr
                key={bundle.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`cursor-pointer p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}
                  onClick={() => setProducts(bundle.products)}>
                  {bundle.uniqueIdentifier}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  {bundle.products?.length}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  {moment(bundle.createdAt).format("MMM Do YYYY, h:mm a")}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {customers &&
                    customers.find((item) => item.id === bundle.customer)?.name}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                  <div className="flex items-center pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                    <div className="flex space-x-2 items-center">
                      <TableFieldButton
                        text={<TrashIcon className="w-6 h-6" />}
                        onClick={() => deleteButtonClicked(bundle.id)}
                        disabled={!writable}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default BundlesList;
