import React, { useCallback } from "react";

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput = ({
  max = Number.MAX_SAFE_INTEGER,
  onChange,
  value,
  type,
  id,
  placeholder,
  label,
  rounded,
  className,
  disabled,
  ...rest
}) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    throw new Error(`invalid value property`);
  }
  const handleKeyDown = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onChange(nextValue);
    },
    [max, onChange, value],
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);
  const valueDisplay = parseFloat(value / 100).toFixed(2);

  return (
    <div className="outline my-4 relative border-2 border-2C7695 rounded-lg overflow-visible w-full">
      <input
        type={type}
        name={id}
        placeholder={placeholder}
        className="block p-4 w-full text-lg appearance-none focus:outline-none focus:ring-0 border-0 focus:border-0 bg-transparent font-montserrat"
        id={id}
        inputMode="numeric"
        onChange={handleChange}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        value={valueDisplay}
      />
      <label
        htmlFor={id}
        className={`absolute top-0 bg-white text-lg p-2 m-2 -z-1 duration-300 origin-0 font-montserrat font-medium ml-2 text-2C7695 ${className}`}>
        {label}
      </label>
    </div>
  );
};

export default CurrencyInput;
