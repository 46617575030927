import React from "react";
import Checkbox from "../utils/Checkbox";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import ExpandedMultipleItem from "../common/ExpandedMultipleItem";
import Pagination from "../common/Pagination";
import SearchSortFilter from "../common/SearchSortFilter";
import {
  PencilIcon,
  TrashIcon,
  PrinterIcon,
  RefreshIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import TableFieldButton from "../utils/TableFieldButton";
import { Menu } from "@headlessui/react";

const ReportsList = ({
  onChangeSearchKeyword,
  filters,
  submitFilters,
  clearKeyword,
  reports,
  headers,
  selectAllReports,
  noValuesText,
  selectReport,
  setSort,
  shiftKeyPressed,
  warehouses,
  customers,
  editButtonClicked,
  writable,
  deleteButtonClicked,
  queueReportGeneration,
  total,
  perPage,
  setPerPage,
  pageNumber,
  checkPagination,
  sort,
  clearFilters,
  menuItems,
}) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={null}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Name",
            value: "reportName",
          },
          {
            name: "Start Date",
            value: "startDate",
          },
          {
            name: "End Date",
            value: "endDate",
          },
          {
            name: "Status",
            value: "status",
          },
          {
            name: "Report Type",
            value: "module",
          }
        ]}
      />

      <CustomTableWithTheme>
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {reports.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
              <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider"></td>
            </tr>
          ) : null}
          {reports &&
            reports.map((report, index) => (
              <tr
                key={report.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                  {report.reportName}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                  {report.startDate ? report.startDate : "-"}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                  {report.endDate ? report.endDate : "-"}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                  {report.module}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                  {report.status}
                </td>

                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                  <ExpandedMultipleItem
                    items={
                      report.warehouses &&
                      warehouses
                        .filter((item) => report.warehouses.includes(item.id))
                        .map((item) => item.name)
                    }
                    emptyText="All Warehouses"
                  />
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider">
                  <ExpandedMultipleItem
                    items={
                      report.customers &&
                      customers
                        .filter((item) => report.customers.includes(item.id))
                        .map((item) => item.name)
                    }
                    emptyText="All Clients"
                  />
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-tableText tracking-wider rounded-br rounded-tr">
                  <div className="flex space-x-4 items-center">
                    <TableFieldButton
                      onClick={() => editButtonClicked(report)}
                      text={<PencilIcon className="w-6 h-6" />}
                      disabled={
                        !writable ||
                        !["CREATED", "GENERATED", "GENERATION_FAILED"].includes(
                          report.status,
                        )
                      }
                      tooltipText="Edit Report"
                    />

                    <TableFieldButton
                      text={<TrashIcon className="w-6 h-6" />}
                      onClick={() => deleteButtonClicked(report.id)}
                      disabled={
                        !writable ||
                        !["CREATED", "GENERATED", "GENERATION_FAILED"].includes(
                          report.status,
                        )
                      }
                      tooltipText="Delete Report"
                    />
                    <TableFieldButton
                      text={
                        <RefreshIcon
                          className="w-6 h-6"
                          style={
                            report.status === "GENERATING"
                              ? {
                                  animation: "rotation 1s infinite linear",
                                }
                              : {}
                          }
                        />
                      }
                      onClick={() => queueReportGeneration(report.id)}
                      disabled={
                        !writable ||
                        !["CREATED", "GENERATED", "GENERATION_FAILED"].includes(
                          report.status,
                        )
                      }
                      tooltipText="Regenerate Report"
                    />
                    {menuItems(report).length ? (
                      <Menu
                        as="div"
                        className="relative inline-block text-left">
                        <div>
                          <Menu.Button>
                            <TableFieldButton
                              text={<DotsVerticalIcon className="w-6 h-6" />}
                            />
                          </Menu.Button>
                        </div>
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-primaryAccent divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                          <div className="">
                            {menuItems(report).map((menuItem, idx) => (
                              <Menu.Item key={idx}>
                                {({ active }) => (
                                  <button
                                    className={`flex cursor-pointer items-center w-full text-white  select-none relative py-4 pl-4 pr-4 border-50BFC3 border-b hover:bg-EBEBEB hover:text-2C7695`}
                                    onClick={() =>
                                      menuItem["onClick"](
                                        report[
                                          menuItem["field"]
                                            ? menuItem["field"]
                                            : "id"
                                        ],
                                      )
                                    }>
                                    {menuItem.icon}
                                    {menuItem.title}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Menu>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CustomTableWithTheme>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default ReportsList;
