import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Transfer from "#components/utils/Transfer";
import Toggle from "#components/utils/Toggle";
import _ from "lodash";

const SkuBundleForm = ({
  onChange,
  selectedSkuBundle,
  title,
  skuBundleSuggestionHeaders,
  skuBundleContentHeaders,
  skuBundleContents,
  showBundleItemQuantityForm,
  setShowBundleItemQuantityForm,
  onRemoveBundleItem,
  onAddBundleItem,
  bundleItemQuantity,
  onChangeBundleItemQuantity,
  onChangeInventorySearch,
  inventorySearchKeyword,
  clearInventorySearch,
  setEnabled,
}) => (
  <>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          {title ? `${title}` : "Create SKU Bundle"}
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedSkuBundle.name}
        />
      </div>
      <div>
        <TextField
          type="text"
          id="sku"
          label="Product Id"
          placeholder=" "
          onChange={onChange}
          value={selectedSkuBundle.sku}
        />
      </div>
      <div>
        <div className="flex space-x-4 items-center">
          <div>Enabled</div>
          <div>
            <Toggle
              enabled={selectedSkuBundle.enabled}
              setEnabled={(e) => setEnabled(selectedSkuBundle.sku)}
            />
          </div>
        </div>
      </div>
      <div>
        <Transfer
          onClickAdd={onAddBundleItem}
          leftHeaders={skuBundleSuggestionHeaders}
          rightHeaders={skuBundleContentHeaders}
          onClickRemove={onRemoveBundleItem}
          leftItems={skuBundleContents.leftItems}
          rightItems={skuBundleContents.rightItems}
          onChangeSearch={onChangeInventorySearch}
          totalSearchResults={skuBundleContents.total}
          searchKeyword={inventorySearchKeyword}
          clearKeyword={clearInventorySearch}
          onClickEditQuantity={(sku) => setShowBundleItemQuantityForm(sku)}
        />
      </div>
    </div>{" "}
    {showBundleItemQuantityForm && (
      <Modal
        title="Add Quantity"
        negativeAction={() => setShowBundleItemQuantityForm(null)}
        positiveAction={() =>
          onAddBundleItem(showBundleItemQuantityForm, bundleItemQuantity)
        }
      >
        <div>
          <TextField
            type="number"
            id="quantity"
            label="Quantity"
            placeholder=" "
            onChange={onChangeBundleItemQuantity}
            value={bundleItemQuantity}
            min="0"
          />
        </div>
      </Modal>
    )}
  </>
);

export default SkuBundleForm;
