import { useState } from "react";
import Modal from "#components/utils/Modal";

const ProductImages = ({ images }) => {
  const [showImage, setShowImage] = useState(null);

  return (
    <>
      <div className="container flex space-x-4 items-center justify-center">
        {images?.length > 0 &&
          images.map((image, idx) => {
            return (
              <div
                className="product-image w-[90%] rounded col-span-1 relative cursor-pointer hover:scale-110 transition ease-in-out inline flex-shrink-0"
                key={image.display_url}
                onClick={() => setShowImage(image.display_url)}>
                <img src={image.display_url} />
              </div>
            );
          })}
      </div>
      {showImage && (
        <Modal
          negativeAction={() => setShowImage(null)}
          title={`Image Preview`}
          noPadding={true}
          positiveAction={() => setShowImage(null)}
          positiveText="OK">
          <div className="space-y-4 p-4">
            <div>
              <img
                className="h-3/4 w-3/4 mx-auto object-contain rounded-lg overflow-hidden"
                src={showImage}
                alt="No Image"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

function isValidURL(string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
}

export default ProductImages;
