import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import Carousel from "../Carousel";
import ProductImages from "#components/products/ProductImages";

const EditProductDetails = ({
  product,
  setProduct,
  productCategories,
  saveProduct,
}) => {
  const images = product?.images?.map((imageInfo) => imageInfo.display_url);
  const videos = product?.vidoes?.map((videoInfo) => videoInfo.display_url);
  const showCarousel = product?.images?.length || product?.videos?.length;
  return (
    <div className="flex-col flex-1">
      <div className="flex flex-1 overflow-y-scroll">
        <div className="flex-1 bg-white flex-shrink-0">
          <div className="catalog-textfield-wrapper">
            <label className="catalog-textfield-label">Product Name</label>
            <input
              className="catalog-textfield"
              type="text"
              value={product.name}
              onChange={(e) => setProduct({ ...product, name: e.target.value })}
            />
          </div>
          <div className="catalog-textfield-wrapper">
            <label className="catalog-textfield-label">Product Category</label>
            <AutocompleteSingleSelectDropdown
              options={productCategories}
              labelKey={"name"}
              valueKey={"id"}
              onChange={(e) => setProduct({ ...product, category: e })}
              value={product.category}
              showCheckedIndicator={false}
            />
          </div>
          <div className="catalog-textfield-wrapper">
            <label className="catalog-textfield-label">
              Product Description
            </label>
            <input
              className="catalog-textfield"
              type="text"
              value={product.description}
              onChange={(e) =>
                setProduct({ ...product, description: e.target.value })
              }
            />
          </div>
          <div className="flex w-full">
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">SKU</label>
              <input
                className="catalog-textfield catalog-textfield-disabled-true"
                type="text"
                value={product.sku}
                onChange={(e) =>
                  setProduct({ ...product, sku: e.target.value })
                }
                disabled={true}
              />
            </div>
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">UPC</label>
              <input
                className="catalog-textfield"
                type="text"
                onChange={(e) =>
                  setProduct({ ...product, upc: e.target.value })
                }
                value={product.upc ? product.upc.join(",") : product.upc}
              />
            </div>
          </div>
          <div className="flex">
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">EAN</label>
              <input
                className="catalog-textfield"
                type="text"
                onChange={(e) =>
                  setProduct({ ...product, ean: e.target.value })
                }
                value={product.ean ? product.ean.join(",") : product.ean}
              />
            </div>
            <div className="catalog-textfield-wrapper">
              <label className="catalog-textfield-label">LPN</label>
              <input
                className="catalog-textfield"
                type="text"
                onChange={(e) =>
                  setProduct({ ...product, name: e.target.value })
                }
                value={product.upc ? product.upc.join(",") : product.upc}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 flex-shrink">
          {showCarousel && (
            <>
              <div className="h-full rounded-lg">
                {showCarousel && (
                  <div className="border border-gray-300 shadow-sm rounded-lg pb-6">
                    <Carousel images={images} vidoes={videos} />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex items-end justify-end space-x-2 absolute bottom-4 right-4">
        <button
          className="border-primaryAccent border-2 text-primaryAccent p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
          onClick={() => setProduct(null)}>
          Cancel
        </button>
        <button
          className="bg-primaryAccent border-primaryAccent border-2 text-white p-2 rounded-lg whitespace-nowrap font-semibold px-6 cursor-pointer"
          onClick={saveProduct}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default EditProductDetails;
