import ReCAPTCHA from "react-google-recaptcha";
import TextField from "#components/utils/TextField";

const config = require("config");

const LoginBox = ({
  onSubmit,
  onChange,
  resetPassword,
  values: { username, password, captcha },
  tenant,
}) => {
  const handleChange = (token) => {
    onChange({
      target: {
        value: token,
        name: "captcha",
      },
    });
  };
  return (
    <div className="bg-white xs:10/12 sm:w-8/12 md:6/12 max-w-xl min-w-xl rounded-2xl p-10 flex flex-col space-y-6 items-center justify-center">
      <div className="text-primaryAccent text-2xl font-medium">Login</div>
      <div className="text-454A4F text-2xl font-medium">
        {tenant
          ? `Welcome back, team ${tenant.name}`
          : "Welcome to Hopstack Inc."}
      </div>
      <form
        onSubmit={(e) => {
          if (!config.CAPTCHA_ENABLED || captcha) onSubmit(e);
        }}
        className="w-full flex-col space-y-6 items-center justify-center"
        noValidate>
        <TextField
          label="Username / Email"
          id="username"
          type="text"
          placeholder=" "
          onChange={onChange}
          value={username}
        />
        <TextField
          label="Password"
          placeholder=" "
          id="password"
          type="password"
          onChange={onChange}
          value={password}
        />
        {config.CAPTCHA_ENABLED && (
          <div style={{ minHeight: "74px" }}>
            <ReCAPTCHA
              name="captcha"
              sitekey={config.CAPTCHA_KEY}
              onChange={handleChange}
            />
          </div>
        )}

        <div
          onClick={resetPassword}
          className="cursor-pointer flex justify-end font-medium text-lg text-primaryAccent">
          Forgot your password?
        </div>
        <div className="w-full flex items-center justify-center">
          <input
            disabled={config.CAPTCHA_ENABLED && !captcha}
            type="submit"
            className={`${
              captcha ? "" : ""
            } disabled:opacity-50 cursor-pointer inline-flex justify-center p-4 text-2xl font-normal text-textWhite bg-primaryAccent font-montserrat border border-transparent rounded-md ring-0 focus:ring-0 outline-none focus:outline-none w-64`}
            value="Login"
          />
        </div>
      </form>
    </div>
  );
};

export default LoginBox;
