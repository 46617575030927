import React from 'react';

const ForbiddenPage = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-white">
      <div className="text-center w-2/3 bg-gray-100 p-16 border border-gray-300 rounded shadow-lg">
        <h1 className="text-6xl mb-8">Oops!</h1>
        <p className="text-2xl text-gray-600">You don't have permission to access this page.</p>
        <p className="text-xl text-gray-500 mt-4">Please contact your administrator or go back to the dashboard.</p>
      </div>
    </div>
  );
}

export default ForbiddenPage;
