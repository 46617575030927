// components Import
import Modal from "#components/utils/Modal";
import Accordian from "#components/utils/Accordian";
import CatalogGallery from "#components/catalogs/CatalogGallery";
import Checkbox from "#components/utils/Checkbox";
import Stats from "#components/common/Stats";
// utils imports
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
// other imports
import PropTypes from "prop-types";

/**
 * @typedef {Object} ExpandedCatalogProps
 * @property {Object} fetchedCatalog - The detailed Catalog (product) object
 * @property {function} setFetchedCatalog - A function to set the fetched catalog.
 * @property {Array<Object>} customers - An array of customer objects.
 * @property {function} explainInventory - A function to fetch details about inventory for explanation.
 */
const ExpandedCatalog = ({ fetchedCatalog, setFetchedCatalog, customers }) => {
  /**
   * Product Images, Videos
   */
  const images = fetchedCatalog?.images?.map(
    (imageInfo) => imageInfo.display_url,
  );
  const videos = fetchedCatalog?.vidoes?.map(
    (videoInfo) => videoInfo.display_url,
  );
  const showCarousel =
    fetchedCatalog.images?.length || fetchedCatalog.videos?.length;

  /**
   * Product Codes
   */
  const productCodes = {
    SKU: fetchedCatalog.sku,
    UPC: fetchedCatalog.upc,
    EAN: fetchedCatalog.ean,
    ASIN: fetchedCatalog.asin,
    FNSKU: fetchedCatalog.fnsku,
    LPN: fetchedCatalog.lpn,
  };

  /**
   * Basic Product Details
   */
  const basicDetails = {
    Source: fetchedCatalog.source,
    Customer: customers?.find(
      (customer) => customer.id === fetchedCatalog.customer,
    )?.name,
    Category: fetchedCatalog.category,
    Type: fetchedCatalog.type,
  };

  /**
   * Product Attributes
   */
  const productAttributes = fetchedCatalog.attributes;
  const basicAttributes = new Set([
    "size",
    "shape",
    "color",
    "weight",
    "weightMeasure",
    "length",
    "width",
    "height",
    "dimensionsUnit",
    "perishable",
    "lotIdRequired",
    "expiryDateRequired",
  ]);
  const attributesDetail = {
    Size: productAttributes?.size,
    Shape: productAttributes?.shape,
    Color: productAttributes?.color,
    Weight:
      productAttributes?.weight || productAttributes?.weightMeasure
        ? `${productAttributes?.weight} ${
            productAttributes?.weightMeasure ?? ""
          }`
        : "",
    Length: productAttributes?.length,
    Width: productAttributes?.width,
    Height: productAttributes?.height,
    "Dimensions Unit": productAttributes?.dimensionsUnit,
    Perishable: productAttributes?.perishable,
    "Lot/Batch Id Required": productAttributes?.lotIdRequired,
    "Expiry Date Required": productAttributes?.expiryDateRequired,
  };

  /**
   * Product Custom Attributes
   */
  const customAttributes = productAttributes
    ? Object.keys(productAttributes)?.filter(
        (attributeKey) => !basicAttributes.has(attributeKey),
      )
    : [];
  const customAttributesDetail = customAttributes.reduce(
    (resultObj, attributeKey) => {
      if (
        productAttributes &&
        productAttributes[attributeKey] &&
        attributeKey !== "update_version_history_ids"
      ) {
        resultObj[attributeKey] = productAttributes[attributeKey];
      }
      return resultObj;
    },
    {},
  );

  /**
   * Product Configurations
   */
  const UOMConfigurations = fetchedCatalog.uomConfigurations;
  const configurations = {
    "Base Unit of Measurement": fetchedCatalog.baseUom,
    // TO DO:
    // to show configurations of UOM.
  };
  const showConfigurations =
    fetchedCatalog.baseUom || UOMConfigurations?.length;

  /**
   * Extracts the Hardiness Zone from a given product description.
   * The function searches the description for a pattern 'HZ: X-Y',
   * where X and Y are numbers representing the Hardiness Zone range.
   * If the pattern is found, it returns the Hardiness Zone as a string (e.g., '4-8').
   * If the pattern is not found, it returns null.
   *
   * @param {string} description - The product description to search within.
   * @returns {string|null} The extracted Hardiness Zone, or null if not found.
   */
  const extractHardinessZone = (description) => {
    const hzPattern =
      /(?:HZ:|Horticultural zones|Hardiness Zone)\s*([0-9]+-[0-9]+)/i;
    const match = description?.match(hzPattern);
    return match ? match[1] : null;
  };

  const hardinessZone = fetchedCatalog.description
    ? extractHardinessZone(fetchedCatalog.description)
    : null;

  return (
    <div>
      <Modal
        negativeAction={() => setFetchedCatalog(null)}
        title={`Catalog Details`}
        minWidth="80vw"
        maxWidth="80vw"
        minHeight="80vh"
        scrollWithin={true}
        positiveAction={() => setFetchedCatalog(null)}
        positiveText="OK">
        <div className="w-full overscroll-y-contain">
          {/* Product Overview */}
          <Accordian title="Product Overview" isActive={true}>
            <div className="grid grid-cols-2 gap-4">
              {/* Left Column - Product Details */}
              <div className="h-full rounded-lg">
                {/* Meta info block */}
                <div className="border border-gray-300 shadow rounded-md">
                  <div className="text-xl font-montserrat p-2 font-bold">
                    {fetchedCatalog.sku}
                  </div>
                  <div className="text-xl font-montserrat p-2">
                    {fetchedCatalog.name}
                  </div>
                  <div className="text-sm font-montserrat p-2">
                    {fetchedCatalog.description}
                  </div>
                </div>
                {/* Product codes block */}
                <span className="block font-bold text-lg text-primaryAccent mt-4">
                  Codes:{" "}
                </span>
                <div className="mt-2 border border-gray-300 shadow rounded-md font-montserrat">
                  {Object.keys(productCodes).map((codeType, index) => {
                    if (productCodes[codeType]) {
                      return (
                        <div className="grid grid-cols-3 p-2" key={index}>
                          <span className="font-bold">{codeType}: </span>
                          <span className="col-span-2">
                            {buildMarketplaceHyperlink(
                              productCodes[codeType],
                              codeType,
                              basicDetails.Source,
                            )}
                          </span>
                        </div>
                      );
                    }
                  })}
                  {fetchedCatalog.source === "Magento" && hardinessZone && (
                    <div className="grid grid-cols-3 p-2">
                      <span className="font-bold">Hardiness Zone: </span>
                      <span className="col-span-2">{hardinessZone}</span>
                    </div>
                  )}
                </div>
                {/* Addtional details */}
                <span className="block font-bold text-lg text-primaryAccent mt-4">
                  Basic details:{" "}
                </span>
                <div className="mt-2 border border-gray-300 shadow rounded-md font-montserrat">
                  {Object.keys(basicDetails).map((detailType, index) => {
                    if (basicDetails[detailType]) {
                      return (
                        <div className="grid grid-cols-3 p-2" key={index}>
                          <span className="font-bold">{detailType}: </span>
                          <span className="col-span-2">
                            {basicDetails[detailType]}
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
                {/* Attributes */}
                {productAttributes && (
                  <>
                    <span className="block font-bold text-lg text-primaryAccent mt-4">
                      Attributes:{" "}
                    </span>
                    <div className="mt-2 border border-gray-300 shadow rounded-md font-montserrat">
                      {Object.keys(attributesDetail).map(
                        (attributeName, index) => {
                          if (attributesDetail[attributeName]) {
                            const attributeValue =
                              attributesDetail[attributeName];
                            return (
                              <div className="grid grid-cols-3 p-2" key={index}>
                                <span className="font-bold">
                                  {attributeName}:{" "}
                                </span>
                                {typeof attributeValue === "boolean" ? (
                                  <Checkbox
                                    checked={attributeValue}
                                    disabled={true}
                                  />
                                ) : (
                                  <span className="col-span-2">
                                    {attributeValue}
                                  </span>
                                )}
                              </div>
                            );
                          }
                        },
                      )}
                    </div>
                  </>
                )}
                {/* Custom Attributes */}
                {customAttributes?.length > 0 && (
                  <>
                    <span className="block font-bold text-lg text-primaryAccent mt-4">
                      Custom attributes:
                    </span>
                    <div className="mt-2 border border-gray-300 shadow rounded-md font-montserrat">
                      {Object.entries(customAttributesDetail)?.map(
                        ([attributeName, attributeValue], index) => {
                          // Check if attributeValue is not an array and not an object, or if it's a boolean
                          if (
                            !Array.isArray(attributeValue) &&
                            (typeof attributeValue !== "object" ||
                              typeof attributeValue === "boolean")
                          ) {
                            return (
                              <div className="grid grid-cols-4 p-2" key={index}>
                                <span className="col-span-2 font-bold">
                                  {attributeName}:{" "}
                                </span>
                                <span className="col-span-2">
                                  {typeof attributeValue === "boolean"
                                    ? attributeValue
                                      ? "Yes"
                                      : "No"
                                    : attributeValue.toString()}
                                </span>
                              </div>
                            );
                          }
                          return null;
                        },
                      )}
                    </div>
                  </>
                )}
                {fetchedCatalog?.pricing && (
                  <>
                    <>
                      <span className="block font-bold text-lg text-primaryAccent mt-4">
                        Pricing:
                      </span>
                      <div className="mt-2 border border-gray-300 shadow rounded-md font-montserrat">
                        {fetchedCatalog.pricing.hasOwnProperty(
                          "standardPrice",
                        ) && (
                          <div className="grid grid-cols-4 p-2">
                            <span className="col-span-2 font-bold">
                              Standard Price:
                            </span>
                            <span className="col-span-2">
                              $
                              {fetchedCatalog.pricing.standardPrice?.toFixed(2)}
                            </span>
                          </div>
                        )}
                        {fetchedCatalog.pricing.hasOwnProperty(
                          "shippingPrice",
                        ) && (
                          <div className="grid grid-cols-4 p-2">
                            <span className="col-span-2 font-bold">
                              Shipping Price:
                            </span>
                            <span className="col-span-2">
                              $
                              {fetchedCatalog.pricing.shippingPrice?.toFixed(2)}
                            </span>
                          </div>
                        )}
                        {fetchedCatalog.pricing.hasOwnProperty("dutyPrice") && (
                          <div className="grid grid-cols-4 p-2">
                            <span className="col-span-2 font-bold">
                              Duty Price:
                            </span>
                            <span className="col-span-2">
                              ${fetchedCatalog.pricing.dutyPrice?.toFixed(2)}
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  </>
                )}
                {fetchedCatalog?.pricing?.computedPriceFields && (
                  <>
                    <>
                      <span className="block font-bold text-lg text-primaryAccent mt-4">
                        Computed Pricing:
                      </span>
                      <div className="mt-2 border border-gray-300 shadow rounded-md font-montserrat">
                        {fetchedCatalog.pricing.computedPriceFields
                          ?.totalPrice && (
                          <div className="grid grid-cols-4 p-2">
                            <span className="col-span-2 font-bold">
                              Total Price:
                            </span>
                            <span className="col-span-2">
                              $
                              {fetchedCatalog.pricing.computedPriceFields?.totalPrice?.toFixed(
                                2,
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  </>
                )}
                {/* UOM Configurations Info */}
                {showConfigurations && (
                  <>
                    <span className="block font-bold text-lg text-primaryAccent mt-4">
                      Configurations:
                    </span>
                    <div className="mt-2 border border-gray-300 shadow rounded-md font-montserrat">
                      {Object.keys(configurations).map((configName, index) => {
                        if (configurations[configName]) {
                          return (
                            <div className="grid grid-cols-4 p-2" key={index}>
                              <span className="col-span-2 font-bold">
                                {configName}:{" "}
                              </span>
                              <span className="col-span-2">
                                {configurations[configName]}
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                )}
              </div>
              {/* Right Column - Product Gallery, Tags */}
              {showCarousel && (
                <div className="h-full rounded-lg">
                  {showCarousel && (
                    <div className="border border-gray-300 shadow-sm rounded-lg pb-6">
                      <CatalogGallery images={images} videos={videos} />
                    </div>
                  )}
                  {/* Tags */}
                  {fetchedCatalog?.tags?.length > 0 && (
                    <div className="mt-4 border border-gray-300 shadow rounded-md font-montserrat">
                      <div className="p-2">
                        <span className="font-bold">Tags: </span>
                        <div className="mt-1">
                          {fetchedCatalog.tags.map((tag) => (
                            <div
                              className="m-1 rounded-full bg-primaryAccent text-white inline-block"
                              key={tag.id}>
                              <div className="flex justify-between items-center h-8">
                                <div className="flex-2 pl-3 pr-5">
                                  {tag.name}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {fetchedCatalog?.materials &&
                    fetchedCatalog?.materials?.length > 0 && (
                      <div>
                        <div className="block font-bold text-lg text-primaryAccent mt-4">
                          Materials ({fetchedCatalog.materials.length})
                        </div>

                        {fetchedCatalog.materials.map((material) => {
                          return (
                            <div className="mt-4 border text-black border-gray-300 shadow rounded-md font-montserrat">
                              <div className="grid grid-cols-4 p-2">
                                <span className="col-span-2 font-bold">
                                  Name:
                                </span>
                                <span className="col-span-2">
                                  {material.name}
                                </span>

                                {material.pricing?.totalPrice && (
                                  <>
                                    <span className="col-span-2 font-bold">
                                      Total Price:
                                    </span>
                                    <span className="col-span-2">
                                      $
                                      {parseFloat(
                                        material.pricing.totalPrice,
                                      ).toFixed(2)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                  <div>
                    <div className="block font-bold text-lg text-primaryAccent mt-4">
                      Total Cost:
                      <span className="ml-2 col-span-2">
                        $
                        {parseFloat(
                          fetchedCatalog?.pricing?.computedPriceFields
                            ?.totalPrice || 0,
                        ).toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Accordian>
        </div>
      </Modal>
    </div>
  );
};

ExpandedCatalog.propTypes = {
  fetchedCatalog: PropTypes.object.isRequired,
  setFetchedCatalog: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
};

export default ExpandedCatalog;
