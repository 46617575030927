import { useState, useEffect } from "react";
import moment from "moment-timezone";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import AddButton from "#components/utils/AddButton";
import Autocomplete from "#components/utils/Autocomplete";
// import Dropdown from "#components/shared/Dropdown";
import Checkbox from "#components/utils/Checkbox";
import Dropdown from "#components/utils/Dropdown";
import _, { find } from "lodash";
import { truncate } from "#utils";
import { CheckIcon } from "@heroicons/react/outline";
import products from "../../pages/products";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const VendorReturnForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  selectedVendorReturn,
  onSubmit,
  warehouses,
  customers,
  inventorySearchKeyword,
  setInventorySearchKeyword,
  inventory,
  vendors,
}) => {
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    let formedSteps = [];

    formedSteps = [
      {
        id: "01",
        name: "Client Info",
        href: "#",
        status: "current",
      },
      { id: "02", name: "Select Products", href: "#", status: "upcoming" },
      { id: "03", name: "Choose Address", href: "#", status: "upcoming" },
    ];

    formedSteps[0] = {
      ...formedSteps[0],
      status: "current",
    };
    setSteps(formedSteps);
    setSelectedStep(formedSteps[0]);
  }, []);

  const onChangeAddress = (selectedAddress) => {
    const item = { ...selectedAddress };
    setSelectedAddress(selectedAddress);
    const vendor = vendors.find((v) => v.id === selectedVendorReturn.vendor);
    onChange({
      target: {
        name: "shippingAddress",
        value: {
          ...(selectedVendorReturn?.shippingAddress || {}),
          name: vendor.name,
          email: vendor.email,
          phone: vendor.phone,
          line1: item.address,
          line2: item.suiteNumber,
          city: item.city,
          state: item.state,
          zip: item.pincode,
          country: item.country,
        },
      },
    });
  };

  const AddressItem = ({ item }) => {
    const headers = [
      "",
      "Address",
      "Suite Number",
      "City",
      "State",
      "Pincode",
      "Country",
    ];
    return (
      <table className="px-2 min-w-full divide-y divide-gray-200">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {item?.map((i, index) => (
            <tr
              key={i.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  `}>
                {/* {console.log(selectedAddress, "selectedAddress")} */}
                <Checkbox
                  role="checkbox"
                  onChange={() => onChangeAddress(i)}
                  name="addTypes"
                  value={"Select"}
                  checked={selectedAddress?.address === i.address}
                />
              </td>

              <td
                className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
                {i?.address}
              </td>

              <td
                className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
                {i?.suiteNumber}
              </td>

              <td
                className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
                {i?.city}
              </td>

              <td
                className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
                {i?.state}
              </td>

              <td
                className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
                {i?.pincode}
              </td>

              <td
                className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl`}>
                {i?.country}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const selectStep = (idx) => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    copiedSteps = copiedSteps.map((item) => ({
      ...item,
      status: "upcoming",
    }));

    copiedSteps[idx].status = "current";

    setSteps(copiedSteps);
    setSelectedStep(copiedSteps[idx]);
  };

  const setNextStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== copiedSteps.length - 1) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx + 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx + 1]);
    }
  };

  const setPrevStep = () => {
    let copiedSteps = JSON.parse(JSON.stringify(steps));

    const foundIdx = copiedSteps.findIndex((item) => item.status === "current");

    if (foundIdx !== 0) {
      copiedSteps = copiedSteps.map((item, idx) => ({
        ...item,
        status: idx === foundIdx - 1 ? "current" : "upcoming",
      }));

      setSteps(copiedSteps);
      setSelectedStep(copiedSteps[foundIdx - 1]);
    }
  };

  useEffect(() => {
    onChange({
      target: {
        name: "orderLineItems",
        value: selectedProducts,
      },
    });
  }, [selectedProducts]);
  console.log(selectedProducts, "lk");
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      minWidth="960px"
      onClose={() => {}}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Product Details
          </span>
        </div>
        <nav aria-label="Progress">
          <ol
            role="list"
            className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex md:flex-1">
                {step.status === "complete" ? (
                  <div className="group flex w-full items-center">
                    <span className="flex items-center px-6 py-4 text-md font-medium">
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                        <CheckIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      <span className="ml-4 text-lg font-medium text-gray-900">
                        {step.name}
                      </span>
                    </span>
                  </div>
                ) : step.status === "current" ? (
                  <div
                    className="flex items-center px-4 py-4 text-md font-medium"
                    aria-current="step">
                    <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                      <span className="text-indigo-600">
                        {(
                          steps.findIndex((i) => i.id === step.id) + 1
                        ).toString()}
                      </span>
                    </span>
                    <span className="ml-4 text-md font-medium text-indigo-600">
                      {step.name}
                    </span>
                  </div>
                ) : (
                  <div
                    className="group flex items-center"
                    onClick={() => selectStep(stepIdx)}>
                    <div className="flex items-center px-4 py-4 text-md font-medium">
                      <span className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">
                          {(
                            steps.findIndex((i) => i.id === step.id) + 1
                          ).toString()}
                        </span>
                      </span>
                      <span className="ml-4 text-md font-medium text-gray-500 group-hover:text-gray-900">
                        {step.name}
                      </span>
                    </div>
                  </div>
                )}

                {stepIdx !== steps.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div
                      className="absolute top-0 right-0 hidden h-full w-5 md:block"
                      aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none">
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
        {selectedStep && selectedStep.id === "01" && (
          <>
            {
              <>
                <div>
                  <Dropdown
                    placeholder={"Select Warehouse"}
                    list={warehouses?.filter((item) => item.name !== "All")}
                    labelKey="name"
                    valueKey="id"
                    name="warehouse"
                    setSelected={(selectedOption) => {
                      onChangeDropdown("warehouse", selectedOption);
                    }}
                    selectedValue={selectedVendorReturn.warehouse}
                  />
                </div>
              </>
            }
            {
              <>
                <div>
                  <Dropdown
                    placeholder={"Select Client"}
                    list={getFilteredCustomerList(
                      selectedVendorReturn.warehouse,
                      customers,
                    )}
                    labelKey="name"
                    valueKey="id"
                    name="customer"
                    setSelected={(selectedOption) => {
                      onChangeDropdown("customer", selectedOption);
                    }}
                    selectedValue={selectedVendorReturn.customer}
                  />
                </div>
              </>
            }

            {
              <>
                <div>
                  <Dropdown
                    placeholder={"Select Vendor"}
                    list={vendors}
                    labelKey="code"
                    valueKey="id"
                    name="vendor"
                    setSelected={(e) => {
                      onChangeDropdown("vendor", e);
                    }}
                    selectedValue={selectedVendorReturn.vendor}
                  />
                </div>
              </>
            }
          </>
        )}

        {selectedStep && selectedStep.id === "02" && (
          <div>
            {selectedProducts &&
              selectedProducts.map((item, idx) => (
                <div className="border p-2">
                  <div className="flex space-x-2 items-center" key={idx}>
                    <div className="w-[200px]">
                      <Autocomplete
                        options={
                          vendors.find(
                            (v) => v.id === selectedVendorReturn.vendor,
                          )?.vendorProducts
                        }
                        labelKey="sku"
                        valueKey="id"
                        onChange={(e) => {
                          setSelectedProducts([
                            ...selectedProducts.map((i, innerIdx) =>
                              innerIdx === idx ? { ...i, productId: e } : i,
                            ),
                          ]);
                        }}
                        value={item.productId}
                        placeholder="SKU"
                        name="sku"
                        onKeyDown={(keyword) =>
                          setInventorySearchKeyword(keyword)
                        }
                        id="SELECT_SKU"
                      />
                    </div>
                    <div>
                      <TextField
                        type="number"
                        id="quantity"
                        label="Quantity"
                        placeholder=" "
                        onChange={(e) => {
                          setSelectedProducts(
                            selectedProducts.map((i, innerIdx) =>
                              innerIdx === idx
                                ? { ...i, quantity: parseInt(e.target.value) }
                                : i,
                            ),
                          );
                        }}
                        value={parseInt(item.quantity)}
                        name="quantity"
                        min="0"
                      />
                    </div>
                    <div className="w-[150px]">
                      <Dropdown
                        placeholder={"Form Factor"}
                        list={[
                          { name: "Each" },
                          { name: "Case" },
                          { name: "Carton" },
                          { name: "Pallet" },
                        ]}
                        labelKey="name"
                        valueKey="name"
                        name="formFactor"
                        setSelected={(e) => {
                          setSelectedProducts(
                            selectedProducts.map((i, innerIdx) =>
                              innerIdx === idx ? { ...i, formFactor: e } : i,
                            ),
                          );
                        }}
                        selectedValue={item.formFactor}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder={"Reason"}
                        list={[
                          { name: "Damaged" },
                          { name: "Over Received" },
                          { name: "Other" },
                        ]}
                        labelKey="name"
                        valueKey="name"
                        name="reason"
                        setSelected={(e) => {
                          setSelectedProducts(
                            selectedProducts.map((i, innerIdx) =>
                              innerIdx === idx ? { ...i, reason: e } : i,
                            ),
                          );
                        }}
                        selectedValue={item.reason}
                      />
                    </div>
                    <span
                      className="text-red-500 text-lg pl-2 font-bold cursor-pointer"
                      onClick={() => {
                        setSelectedProducts(
                          selectedProducts.filter(
                            (i) => i.productId !== item.productId,
                          ),
                        );
                      }}>
                      Remove
                    </span>
                  </div>
                </div>
              ))}
            <AddButton
              text="Add Item"
              onClick={() => {
                setSelectedProducts([
                  ...selectedProducts,
                  {
                    productId: "",
                    quantity: 1,
                    formFactor: "Each",
                    fulfillmentType: "DEFAULT",
                    reason: "",
                  },
                ]);
              }}
            />
          </div>
        )}
        {selectedStep && selectedStep.id === "03" && (
          <>
            <AddressItem
              item={
                vendors.find((v) => v.id === selectedVendorReturn.vendor)
                  ?.addresses
              }
            />
          </>
        )}
      </div>
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination">
        <div className="flex flex-1 justify-between sm:justify-end">
          <div className="flex flex-1 justify-between sm:justify-end space-x-2">
            <div
              onClick={onClose}
              className="rounded-md cursor-pointer bg-red-600 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-red-700">
              Cancel
            </div>
            {selectedStep?.id !== steps[steps.length - 1]?.id && (
              <div
                onClick={onSubmit}
                className={`rounded-md cursor-pointer inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ${
                  selectedStep?.id === steps[steps.length - 1]?.id
                    ? " bg-primaryAccent"
                    : "bg-gray-200 text-gray-400"
                }`}>
                Submit
              </div>
            )}
            <div
              onClick={setPrevStep}
              className={`rounded-md cursor-pointer  inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ${
                selectedStep?.id === steps[0]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"< Previous"}
            </div>
            <div
              onClick={setNextStep}
              className={`rounded-md cursor-pointer  inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ${
                selectedStep?.id === steps[steps.length - 1]?.id
                  ? "bg-gray-200 text-gray-400"
                  : "bg-FC8862"
              }`}>
              {"Next >"}
            </div>
            {selectedStep?.id === steps[steps.length - 1]?.id && (
              <div
                onClick={onSubmit}
                className={`rounded-md cursor-pointer inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none ${
                  selectedStep?.id === steps[steps.length - 1]?.id
                    ? " bg-primaryAccent"
                    : "bg-gray-200 text-gray-400"
                }`}>
                Submit
              </div>
            )}
          </div>
        </div>
      </nav>
    </Modal>
  );
};

export default VendorReturnForm;
