import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment-timezone";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

const GoodsShippedNote = ({ order, tenant, customerName }) => {
  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            backgroundColor: "#224E73",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 10,
          }}>
          <Image
            src={{
              uri:
                tenant && tenant.logo
                  ? tenant.logo
                  : "https://hopstack-pub.s3.amazonaws.com/logo.png",
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            style={{ width: 100 }}
            fixed={true}
          />
        </View>

        <View
          style={{
            padding: "20px",
            fontSize: "15px",
          }}>
          <View>
            <Text
              style={{
                marginBottom: "20px",
                marginTop: "20px",
                fontSize: 24,
                fontWeight: "bold",
              }}>
              Order Report:
            </Text>
          </View>

          <View>
            {order.orderId && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Order Id: {order.orderId}
              </Text>
            )}
            {order.carrier && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Carrier: {order.carrier}
              </Text>
            )}
            {order.carrierService && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Service: {order.carrierService}
              </Text>
            )}
            {order.trackingNumber && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Tracking Number: {order.trackingNumber}
              </Text>
            )}
            {order.orderDate && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Order Date: {moment(order.orderDate).format("YYYY-MM-DD")}
              </Text>
            )}
            {customerName && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Client Name: {customerName}
              </Text>
            )}
            {order.orderStatus === "COMPLETED" && (
              <Text style={{ marginBottom: "15px", fontWeight: "bold" }}>
                Completed on:{" "}
                {moment(order.completedDate).format("MMMM Do YYYY, h:mm:ss a")}
              </Text>
            )}
          </View>
        </View>
        <View style={{ marginTop: "10px", padding: 20 }}>
          <Text style={{ marginBottom: "10px;", fontWeight: "bold" }}>
            Items({order.orderLineItems ? order.orderLineItems.length : 0}
            ):
          </Text>
        </View>
        <ReactPdfTable
          columns={tableColumns(order)}
          data={order.orderLineItems}
        />
        <View style={{ marginTop: 40, padding: 20, flexDirection: "row" }}>
          <Text
            style={{
              fontWeight: "medium",
              fontSize: 16,
            }}>
            Driver's Signature
          </Text>
          <Text style={{ fontWeight: "medium", fontSize: 16 }}>
            {"  "}
            {"     "}__________________________
          </Text>
        </View>
        <View style={{ marginTop: 40, padding: 20, flexDirection: "row" }}>
          <Text
            style={{
              fontWeight: "medium",
              fontSize: 16,
            }}>
            Warehouse Representative's Signature
          </Text>
          <Text style={{ fontWeight: "medium", fontSize: 16 }}>
            {"  "}
            {"     "}__________________________
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const tableColumns = (order, tenantType) => {
  const returnArr = [];
  returnArr.push({ title: "SKU", dataIndex: "sku" });
  returnArr.push({
    title: "UPC",
    dataIndex: "upc",
    render: (_, item) => item.upc?.join(", ") || "-",
  });
  if (tenantType === "Prep Center") {
    returnArr.push({
      title: "ASIN",
      dataIndex: "asin",
      render: (lineItemData, lineItem) => {
        return buildMarketplaceHyperlink(lineItemData, "ASIN", lineItem.source);
      },
    });
  }

  returnArr.push(
    { title: "Lot/Batch ID", dataIndex: "lotId" },
    { title: "Expiry Date", dataIndex: "bestByDate" },
    { title: "Bin Location", dataIndex: "binLocation" },
    { title: "Name", dataIndex: "productName" },
    { title: "Qty", dataIndex: "quantity" },
    {
      title: "Shipped",
      dataIndex: "availableQuantity",
      render: (_, item) =>
        isNaN(item.availableQuantity) === false ? item.availableQuantity : 0,
    },
    {
      title: "Form Factor",
      dataIndex: "formFactor",
      render: (_, item) => (item.formFactor ? item.formFactor : "Each"),
    },
  );

  return returnArr;
};

const ReactPdfTable = ({ columns, data }) => {
  const colWidth = Math.ceil(100 / columns.length);
  const colStyle = { width: colWidth.toString().concat("%") };
  const rowStyles = {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #ccc",
    padding: "4px",
  };
  const headerStyles = {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #ccc",
    padding: "4px",
    backgroundColor: "#ececec",
  };
  return (
    <View style={{ width: "100%", padding: 20 }}>
      <View style={headerStyles}>
        {columns.map((col) => (
          <Text style={{ ...colStyle, fontSize: "12px" }}>{col.title}</Text>
        ))}
      </View>
      <View>
        {data.map((row) => (
          <View style={rowStyles}>
            {columns.map((col) =>
              col.render ? (
                <Text style={{ ...colStyle, fontSize: "10px" }}>
                  {col.render(row[col.dataIndex], row)}
                </Text>
              ) : (
                <Text style={{ ...colStyle, fontSize: "10px" }}>
                  {row[col.dataIndex]}
                </Text>
              ),
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

const Logo = ({ tenant = {} }) => (
  <div className="flex items-center justify-center space-x-2 py-2">
    {tenant && tenant.logo ? (
      <img src={tenant.logo} alt="Custom Logo" className="h-20" />
    ) : (
      <div className="flex items-center justify-center space-x-2 py-2">
        <img
          src="https://hopstack-pub.s3.amazonaws.com/logo.png"
          alt="Custom Logo"
          className="w-10 h-10"
        />
        <span className="font-hammersmith text-white text-xl">
          Hopstack Inc
        </span>
      </div>
    )}
  </div>
);

export default GoodsShippedNote;
