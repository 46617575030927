import ReactTooltip from "react-tooltip";

const renderWithTooltip = ({
  onClick,
  text,
  IconText,
  disabled,
  tooltipText,
}) => {
  const id = `table-button-tooltip-${Math.random()
    .toString(36)
    .substring(2, 8)}`;
  return (
    <>
      <button
        type="button"
        data-tip
        data-for={id}
        disabled={disabled}
        className={
          disabled
            ? `bg-E1D3B8 pointer-events-none opacity-50 inline-flex justify-center px-2 py-2 text-md text-5F666B font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`
            : `bg-E1D3B8 inline-flex justify-center px-2 py-2 text-md text-5F666B font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`
        }
        onClick={onClick}>
        {IconText ? IconText : text || "Button"}
      </button>
      <ReactTooltip id={id} place="top" type="light" effect="float">
        {tooltipText}
      </ReactTooltip>
    </>
  );
};
const renderWithoutTooltip = ({ onClick, text, IconText, disabled }) => (
  <button
    type="button"
    disabled={disabled}
    className={
      disabled
        ? `bg-E1D3B8 pointer-events-none opacity-50 inline-flex justify-center px-2 py-2 text-md text-5F666B font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`
        : `bg-E1D3B8 inline-flex justify-center px-2 py-2 text-md text-5F666B font-montserrat font-medium border textWhite-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`
    }
    onClick={onClick}>
    {IconText ? IconText : text || "Button"}
  </button>
);

const TableFieldButton = ({ onClick, text, IconText, disabled, tooltipText }) =>
  tooltipText
    ? renderWithTooltip({ onClick, text, IconText, disabled, tooltipText })
    : renderWithoutTooltip({ onClick, text, IconText, disabled });

export default TableFieldButton;
