import PickerBagCode from "#components/picker/common/PickerBagCode";
import DetailedView from "#components/picker/order/DetailedView";
import ListView from "#components/picker/order/ListView";
import AllListView from "#components/picker/order/AllListView";
import CompletedBatch from "#components/picker/order/CompletedBatch";
import { Tab } from "@headlessui/react";
import _ from "lodash";

const SingleLineItemBatch = ({
  currentBatch,
  scanBarcode,
  confirmPickItem,
  confirmDropoff,
  simulateTote,
  currentItem = {},
  workflow,
  confirmDropoffWithConfirmation,
  subdomain,
  skipItem,
  onChangeScannedQuantity,
  displayScan,
  setDisplayScan,
  currentProduct,
}) => {
  if (
    workflow &&
    workflow.steps &&
    workflow.steps.findIndex(
      (item) => item.name === "SCAN" && item.mandatory === true,
    ) === -1
  ) {
    return (
      <div className="flex pt-36 items-center justify-center">
        <AllListView
          currentBatch={currentBatch}
          confirmDropoffWithConfirmation={confirmDropoffWithConfirmation}
        />
      </div>
    );
  }

  if (!currentBatch.tote) {
    return (
      <PickerBagCode
        scanBarcode={scanBarcode}
        text={pickerBagText(currentBatch.workflow)}
        simulateTote={simulateTote}
        subdomain={subdomain}
      />
    );
  }

  if (currentBatch.status === "COMPLETED") {
    return (
      <CompletedBatch
        currentBatch={currentBatch}
        confirmDropoff={confirmDropoff}
      />
    );
  }

  const tabs = {
    "Detail View": (
      <DetailedView
        currentItem={currentItem}
        currentBatch={currentBatch}
        scannedBin={currentItem.scannedBin === true}
        scanBarcode={scanBarcode}
        confirmPickItem={confirmPickItem}
        skipItem={skipItem}
        onChangeScannedQuantity={onChangeScannedQuantity}
        displayScan={displayScan}
        setDisplayScan={setDisplayScan}
        currentProduct={currentProduct}
      />
    ),
    "List View": <ListView currentBatch={currentBatch} />,
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="flex-col pt-4">
        <Tab.Group>
          <Tab.List className="flex items-center justify-center">
            <div className=" p-2 space-x-2 rounded-full bg-primaryAccent">
              {tabs &&
                Object.keys(tabs).map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "px-6 py-2.5 leading-5 font-regular rounded-full",
                        "focus:outline-none focus:ring-0 font-montserrat text-lg",
                        selected
                          ? "bg-white text-primaryAccent font-medium"
                          : "border-primaryAccent bg-transparent border rounded-full text-white",
                      )
                    }>
                    {tab}
                  </Tab>
                ))}
            </div>
          </Tab.List>
          <div className="block">
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col mt-4 bg-transparent p-4 items-center">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

const pickerBagText = (workflow) => {
  switch (workflow) {
    case "Pick + Sort":
      return `Please scan a multi-line picker's cart`;
    case "Pick then Sort":
      return `Please scan a picker's cart to get started`;
    default:
      return `Please scan a tote to get started`;
  }
};

export default SingleLineItemBatch;
