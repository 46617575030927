import { useState, useEffect } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";

const ChangeStatusReasonForm = ({
  onClose,
  title,
  changeStatusConsignment,
  onSubmit,
  onChange,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={onClose}
      id="changeStatusReasonModal"
      onClose={onClose}
      positiveAction={onSubmit}>
      <div className="space-y-4">
        <div>
          <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
            Status Change Reason
          </span>
        </div>

        <>
          <div>
            <TextField
              type="text"
              label=""
              placeholder="Reason"
              onChange={onChange}
              value={changeStatusConsignment?.reason}
              name={"reason"}
            />
          </div>
        </>
      </div>
    </Modal>
  );
};

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};
export default ChangeStatusReasonForm;
