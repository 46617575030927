import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import _ from "lodash";

const BatchDateRangeForm = ({
  onSubmit,
  negativeAction,
  batchDateRange,
  onChange,
}) => (
  <Modal
    title={"Run Auto Batching"}
    negativeAction={negativeAction}
    positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Choose Date Range
        </span>
      </div>

      <div className="flex space-x-6 mt-4">
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              Start Date
            </span>
          </label>
          <TextField
            type="date"
            label="Select Date"
            placeholder=" "
            onChange={(e) => onChange("startDate", e.target.value)}
            value={batchDateRange.startDate}
          />
        </div>
        <div className="flex-1">
          <label className="block text-left mb-2">
            <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
              End Date
            </span>
          </label>
          <TextField
            type="date"
            label="Select Date"
            placeholder=" "
            onChange={(e) => onChange("endDate", e.target.value)}
            value={batchDateRange.endDate}
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default BatchDateRangeForm;
