import withDevicesLogic from "#components/HOC/withDevicesLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import Tabs from "#components/utils/Tabs";
import DevicesList from "#components/devices/DevicesList";
import DeviceForm from "#components/devices/DeviceForm";
import { UserAddIcon } from "@heroicons/react/outline";
import RoundedDropdown from "#components/utils/RoundedDropdown";

const Devices = ({
  masterData,
  devices,
  selectedDevice,
  setSelectedDevice,
  fetchDevice,
  saveDevice,
  onChangeDropdown,
  deleteButtonClicked,
  onChange,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  onChangeMultiSelect,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
}) => (
  <div className="p-5">
    <div className="flex items-center">
      <div className="flex-1">
        <PageTitle>Device Management</PageTitle>
      </div>

      <div className="items-center justify-end">
        <AddButton
          text="Add Device"
          onClick={() => setSelectedDevice({})}
          icon={UserAddIcon}
        />
      </div>
    </div>
    <div className="w-96 flex space-x-4 mb-5">
      <RoundedDropdown
        placeholder={"Warehouse"}
        list={warehouses}
        labelKey="name"
        valueKey="id"
        name="warehouse"
        selectedValue={selectedWarehouse}
        setSelected={setSelectedWarehouse}
      />
      <RoundedDropdown
        placeholder={"Client"}
        list={customers}
        labelKey="name"
        valueKey="id"
        name="customer"
        selectedValue={selectedCustomer}
        setSelected={setSelectedCustomer}
      />
    </div>
    <DevicesList
      devices={devices}
      editButtonClicked={(e) => {
        fetchDevice(e.id);
      }}
      deleteButtonClicked={(e) => deleteButtonClicked(e)}
      masterData={masterData}
      headers={[
        "Name",
        "Device Type",
        "Hardware ID",
        "Warehouses",
        "Clients",
        "Action",
      ]}
      noValuesText="No Devices"
      customers={customers}
      warehouses={warehouses}
    />
    {selectedDevice && (
      <DeviceForm
        title={selectedDevice.id ? "Edit Device" : "Add Device"}
        selectedDevice={selectedDevice}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        onClose={() => setSelectedDevice()}
        onSubmit={() => saveDevice(selectedDevice)}
        deviceTypes={masterData.deviceTypes}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        customers={customers}
        warehouses={warehouses}
        onChangeMultiSelect={onChangeMultiSelect}
      />
    )}
  </div>
);


export default withDevicesLogic(Devices);
