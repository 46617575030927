import { DocumentDownloadIcon } from "@heroicons/react/outline";
import withHardwareIntegrationsLogic from "#components/HOC/withHardwareIntegrationsLogic";
import Modal from "#components/utils/Modal";
import moment from "moment-timezone";
import HardwareIntegrationForm from "#components/integrations/HardwareIntegrationForm";
import RoundedDropdown from "#components/utils/RoundedDropdown";
import { getFilteredCustomerList } from "../../utils/getFilteredCustomerList";

const HardwareIntegrations = ({
  integrationProviders,
  getProducts,
  getOrders,
  setOrders,
  setProducts,
  orders,
  products,
  connectIntegration,
  integrations,
  customers,
  selectedCustomer,
  setSelectedCustomer,
  selectedIntegration,
  setSelectedIntegration,
  onChange,
  onChangeDropdown,
  setIntegrationDropdownValue,
  saveIntegration,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  writable,
}) => (
  <>
    <div className="w-96 flex space-x-4 mb-5">
      <RoundedDropdown
        placeholder={"Warehouse"}
        list={warehouses}
        labelKey="name"
        valueKey="id"
        name="warehouse"
        selectedValue={selectedWarehouse}
        setSelected={setSelectedWarehouse}
      />
      <RoundedDropdown
        placeholder={"Client"}
        list={getFilteredCustomerList(selectedWarehouse, customers)}
        labelKey="name"
        valueKey="id"
        name="customer"
        selectedValue={selectedCustomer}
        setSelected={setSelectedCustomer}
      />
    </div>
    <div className="grid grid-cols-3 gap-4">
      {integrationProviders &&
        integrationProviders.map((integration, idx) => (
          <div className="bg-white shadow-lg rounded-lg py-8 pt-0" key={idx}>
            <div className="flex bg-integrationLogoBackground rounded-t-lg py-6 w-full justify-center">
              <img
                src={integration.logo}
                className="w-32 h-20 object-contain"
              />
            </div>
            <div className="flex flex-col items-center py-6 justify-center">
              <div className="text-xl font-medium text-primaryAccent">
                {integration.name}
              </div>
              <div className="text-lg font-normal text-5F666B">
                {integration.description}
              </div>
            </div>
            <div className="flex mx-6 mt-4 space-x-2">
              {integrations.findIndex(
                (item) =>
                  item.integrationType === integration.name &&
                  item.customer === selectedCustomer,
              ) === -1 && (
                <div
                  className={`w-full px-10 py-2 text-white ${
                    writable ? "bg-2C7695" : "bg-gray-500"
                  } items-center justify-center text-center rounded-lg`}
                  onClick={() =>
                    writable && connectIntegration(integration.name)
                  }>
                  Connect
                </div>
              )}
              {integrations.findIndex(
                (item) =>
                  item.integrationType === integration.name &&
                  item.customer === selectedCustomer,
              ) !== -1 && (
                <div className="flex w-full space-between space-x-2">
                  <div
                    className={`flex w-full px-5 py-2 text-white text-md ${
                      writable ? "bg-2C7695" : "bg-gray-500"
                    } items-center justify-center text-center rounded-lg`}
                    onClick={() =>
                      getOrders(
                        integrations.find(
                          (item) =>
                            item.integrationType === integration.name &&
                            item.customer === selectedCustomer,
                        ).id,
                      )
                    }>
                    <DocumentDownloadIcon className="w-5 h-5 mr-1" />
                    Fetch Devices
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

      {selectedIntegration && (
        <HardwareIntegrationForm
          title={
            selectedIntegration.id ? "Edit Integration" : "Add Integration"
          }
          selectedIntegration={selectedIntegration}
          onChange={onChange}
          onChangeDropdown={() => setIntegrationDropdownValue()}
          onClose={() => setSelectedIntegration()}
          onSubmit={saveIntegration}
          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
    </div>
  </>
);

export default withHardwareIntegrationsLogic(HardwareIntegrations);
