import Dropdown from "#components/utils/Dropdown";
import AddButton from "#components/utils/AddButton";

const ORDER_TYPES_DELMAR = ["321", "PAPS", "CANADA"];

const GenerateTruck = ({
  truckIdentifier,
  setTruckIdentifier,
  generateTruck,
  resetAllBarcodes,
}) => {
  return (
    <div className="p-4">
      <div className="flex items-center w-full space-x-4">
        <div className="text-xl">Generate Truck ID</div>
        <div className="w-24">
          <Dropdown
            placeholder="Truck No"
            list={[{ name: "A" }, { name: "B" }, { name: "C" }]}
            labelKey="name"
            valueKey="name"
            setSelected={(e) => {
              setTruckIdentifier(e);
            }}
            selectedValue={truckIdentifier}
          />
        </div>
        {ORDER_TYPES_DELMAR.map((item, idx) => (
          <AddButton
            text={item}
            onClick={() => generateTruck(item)}
            key={idx}
          />
        ))}
        <AddButton
          text="Reset All Barcodes"
          onClick={resetAllBarcodes}
          styles={["ml-4 bg-red-600"]}
        />
      </div>
    </div>
  );
};

export default GenerateTruck;
