import React, { useContext, useEffect, useState } from "react";
import SlideOverPanel from "../common/SlideOverPanel";
import ReactTooltip from "react-tooltip";
import { XIcon } from "@heroicons/react/outline";
import { AppStateContext } from "#contexts/appState";

const TariffSidePanel = ({
  showSidePanel,
  setShowSidePanel,
  fulfillmentType,
  tariffType,
  subTariffType,
  savedTariff,
  billingProfileFormConfig,
  saveTariff,
  currency,
  isEditMode,
}) => {
  const TariffType = {
    FLAT_RATE: "flat_rate",
    VARIABLE_RATE: "variable_rate",
  };

  const CalculationOptions = {
    MAXIMUM_OCCUPANCY: "Maximum Occupancy",
    END_OF_PERIOD_OCCUPANCY: "End of Period Occupancy",
  };

  const [frequencyOpted, setFrequencyOpted] = useState(
    savedTariff?.resetTime ||
      billingProfileFormConfig?.tariffsConfig[tariffType]?.timeFrequency
        ?.required ||
      false,
  );
  const [resetTime, setResetTime] = useState(
    savedTariff?.resetTime ||
      billingProfileFormConfig?.tariffsConfig[tariffType]?.timeFrequency
        ?.options[0],
  );
  const [tariffRateType, setTariffRateType] = useState(
    savedTariff
      ? savedTariff?.flatRate
        ? TariffType.FLAT_RATE
        : TariffType.VARIABLE_RATE
      : TariffType.FLAT_RATE,
  );
  const uomConfig =
    billingProfileFormConfig?.tariffsConfig[tariffType]?.uomEntityCombinations;

  const [tariffDetails, setTariffDetails] = useState({
    tariffType,
    subTariffType,
    fulfillmentType,
  });

  const [shouldShowCalculationOptions, setShouldShowCalculationOptions] =
    useState(
      billingProfileFormConfig?.tariffsConfig[tariffType]
        ?.calculationStrategyOptions?.length > 0,
    );

  const [calculationOption, setCalculationOption] = useState(
    savedTariff?.options?.endOfPeriodCalculation
      ? CalculationOptions.END_OF_PERIOD_OCCUPANCY
      : CalculationOptions.MAXIMUM_OCCUPANCY,
  );

  const appState = useContext(AppStateContext);

  let selectedUom = uomConfig && uomConfig.length > 0 && uomConfig[0].uom;
  let entityTypes = uomConfig.find(
    (uomType) => uomType.uom === selectedUom,
  )?.entityTypes;

  const [uoms, setUoms] = useState(
    savedTariff?.uoms || [
      {
        id: 1,
        uom: selectedUom,
        entity: entityTypes && entityTypes.length > 0 && entityTypes[0],
      },
    ],
  );

  const getUomData = (uomId) => {
    if (uomId === 1) return uomConfig;
    return uomConfig.filter((uomType) => uomType.uom !== uoms[0].uom);
  };

  const [tiers, setTiers] = useState(
    savedTariff
      ? savedTariff?.tiers
      : [
          {
            rate: null,
            ranges: uoms.map((uomData) => {
              return { id: uomData.id, start: 1 };
            }),
          },
        ],
  );

  const getEntityName = (rangeId) => {
    let selectedUom = uoms.find((uom) => uom.id === rangeId);
    return selectedUom ? selectedUom.entity || selectedUom.uom : "UNIT";
  };

  let isSaveDisabled = tiers.some(
    (tier) => tier.rate === null || Number.isNaN(tier.rate),
  );

  useEffect(() => {
    setTariffDetails({
      ...tariffDetails,
      flatRate: tariffRateType === TariffType.FLAT_RATE,
    });
  }, [tariffRateType]);

  return (
    <SlideOverPanel open={showSidePanel} setOpen={setShowSidePanel} title={""}>
      <div className="px-4 relative h-full">
        <div className="mb-16 overflow-y-auto">
          <div className="text-[24px] font-semibold">
            {`${tariffType} - ${subTariffType}`}
          </div>

          <h2 className="font-semibold text-[20px]">Unit of Measure Details</h2>

          {uomConfig && (
            <div className="flex flex-col gap-2 my-2">
              {uoms.map((uomData) => {
                let entityTypes = uomConfig.find(
                  (uomType) => uomType.uom === uomData.uom,
                )?.entityTypes;
                return (
                  <div className="rounded-lg bg-[#FAFAFA] p-3">
                    <div className="flex justify-between">
                      <div className="text-[#717679] text-[16px]">
                        UOM {uomData.id}
                      </div>
                      {uomData.id == 2 && (
                        <button
                          onClick={() => {
                            let updatedUoms = [uoms[0]];
                            setUoms(updatedUoms);
                            if (tariffRateType === TariffType.FLAT_RATE) {
                              setTiers([
                                {
                                  rate: null,
                                  ranges: updatedUoms.map((uomData) => {
                                    return { id: uomData.id, start: 1 };
                                  }),
                                },
                              ]);
                            } else {
                              setTiers([
                                {
                                  rate: null,
                                  ranges: updatedUoms.map((uomData) => {
                                    return { id: uomData.id, start: 1, end: 2 };
                                  }),
                                },
                                {
                                  rate: null,
                                  ranges: updatedUoms.map((uomData) => {
                                    return { id: uomData.id, start: 3 };
                                  }),
                                },
                              ]);
                            }
                          }}>
                          <XIcon className="h-7 w-7 text-[#cool-gray/900] p-1" />
                        </button>
                      )}
                    </div>
                    <div className="text-[#717679] text-[14px] mt-2">
                      Unit of Measure
                    </div>
                    <select
                      disabled={!isEditMode}
                      value={uomData.uom}
                      onChange={(e) =>
                        setUoms(
                          uoms.map((uom) => {
                            if (uom.id === uomData.id) {
                              let selectedUom = e.target.value;
                              let entityTypes = uomConfig.find(
                                (uomType) => uomType.uom === selectedUom,
                              )?.entityTypes;
                              return {
                                ...uom,
                                uom: selectedUom,
                                entity:
                                  entityTypes &&
                                  entityTypes.length > 0 &&
                                  entityTypes[0],
                              };
                            }
                            return uom;
                          }),
                        )
                      }
                      className="rounded border border-[#DDDFE0] w-full">
                      {getUomData(uomData.id).map((uomType, index) => {
                        return <option key={index}>{uomType.uom}</option>;
                      })}
                    </select>

                    <div className="text-[#717679] text-[14px] mt-2">
                      Entity
                    </div>
                    <select
                      disabled={
                        !isEditMode || !entityTypes || entityTypes?.length === 0
                      }
                      value={uomData.entity}
                      onChange={(e) =>
                        setUoms(
                          uoms.map((uom) => {
                            if (uom.id === uomData.id)
                              return { ...uom, entity: e.target.value };
                            return uom;
                          }),
                        )
                      }
                      className={`${
                        !entityTypes || entityTypes?.length === 0
                          ? "cursor-not-allowed bg-gray-100"
                          : ""
                      } rounded border border-[#DDDFE0] w-full`}>
                      {entityTypes &&
                        entityTypes.length > 0 &&
                        entityTypes.map((entity, index) => {
                          return <option key={index}>{entity}</option>;
                        })}
                    </select>
                  </div>
                );
              })}

              {uomConfig.length > 1 &&
                uoms.length === 1 &&
                billingProfileFormConfig?.tariffsConfig[tariffType]
                  ?.numberOfUoms > 1 &&
                isEditMode && (
                  <button
                    onClick={() => {
                      let nextUom = uomConfig.filter(
                        (uomType) => uomType.uom !== uoms[0].uom,
                      )[0]?.uom;
                      let nextEntityTypes = uomConfig.find(
                        (uomType) => uomType.uom === nextUom,
                      )?.entityTypes;
                      let updatedUoms = [
                        ...uoms,
                        {
                          id: 2,
                          uom: nextUom,
                          entity:
                            nextEntityTypes &&
                            nextEntityTypes.length > 0 &&
                            nextEntityTypes[0],
                        },
                      ];
                      setUoms(updatedUoms);
                      if (tariffRateType === TariffType.FLAT_RATE) {
                        setTiers([
                          {
                            rate: null,
                            ranges: updatedUoms.map((uomData) => {
                              return { id: uomData.id, start: 1 };
                            }),
                          },
                        ]);
                      } else {
                        setTiers([
                          {
                            rate: null,
                            ranges: updatedUoms.map((uomData) => {
                              return { id: uomData.id, start: 1, end: 2 };
                            }),
                          },
                          {
                            rate: null,
                            ranges: updatedUoms.map((uomData) => {
                              return { id: uomData.id, start: 3 };
                            }),
                          },
                        ]);
                      }
                    }}
                    className="text-[#417492] font-semibold text-[16px] flex underline">
                    + Add More UOM
                  </button>
                )}
            </div>
          )}

          <h2 className="font-semibold text-[20px] mt-6">
            Time Based Frequency
          </h2>
          <input
            disabled={!isEditMode}
            className="rounded"
            checked={frequencyOpted}
            value={frequencyOpted}
            onChange={() => {
              if (
                !billingProfileFormConfig?.tariffsConfig[tariffType]
                  ?.timeFrequency?.required
              )
                setFrequencyOpted(!frequencyOpted);
            }}
            type="checkbox"
          />
          <label className="ml-2">Opt for time based frequency</label>

          {frequencyOpted && (
            <select
              disabled={!isEditMode}
              value={resetTime}
              onChange={(e) => setResetTime(e.target.value)}
              className="border border-[#DDDFE0] rounded bg-transparent p-1 w-full mt-2">
              {billingProfileFormConfig?.tariffsConfig[
                tariffType
              ]?.timeFrequency?.options.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option.replace("_", "-")}
                  </option>
                );
              })}
            </select>
          )}

          {
            // show calculation options
            shouldShowCalculationOptions && (
              <div className="my-8">
                <h2 className="font-semibold text-[20px] mt-6">
                  Calculation Options
                </h2>
                <div className="flex flex-col">
                  <div>
                    <input
                      disabled={!isEditMode}
                      checked={
                        calculationOption ===
                        CalculationOptions.MAXIMUM_OCCUPANCY
                      }
                      className="cursor-pointer"
                      type="radio"
                      id={CalculationOptions.MAXIMUM_OCCUPANCY}
                      name="calculation_options"
                      value={CalculationOptions.MAXIMUM_OCCUPANCY}
                      onChange={(e) => {
                        setCalculationOption(e.target.value);
                      }}
                    />
                    <label
                      className="ml-2 text-[#344054] font-semibold text-[16px]"
                      for={CalculationOptions.MAXIMUM_OCCUPANCY}>
                      Maximum Occupancy
                    </label>
                  </div>
                  <div>
                    <input
                      disabled={!isEditMode}
                      checked={
                        calculationOption ===
                        CalculationOptions.END_OF_PERIOD_OCCUPANCY
                      }
                      className="cursor-pointer"
                      type="radio"
                      id={CalculationOptions.END_OF_PERIOD_OCCUPANCY}
                      name="calculation_options"
                      value={CalculationOptions.END_OF_PERIOD_OCCUPANCY}
                      onChange={(e) => {
                        setCalculationOption(e.target.value);
                      }}
                    />
                    <label
                      className="ml-2 text-[#344054] font-semibold text-[16px]"
                      for={CalculationOptions.END_OF_PERIOD_OCCUPANCY}>
                      End of Period Occupancy
                    </label>
                  </div>
                </div>
              </div>
            )
          }
          <h2 className="font-semibold text-[20px] mt-6">Tariff Rates</h2>

          <div className="flex gap-8">
            <div>
              <input
                disabled={!isEditMode}
                checked={tariffRateType === TariffType.FLAT_RATE}
                type="radio"
                id={TariffType.FLAT_RATE}
                name="tariff_rates"
                value={TariffType.FLAT_RATE}
                onChange={(e) => {
                  setTiers([
                    {
                      rate: null,
                      ranges: uoms.map((uomData) => {
                        return { id: uomData.id, start: 1 };
                      }),
                    },
                  ]);
                  setTariffRateType(e.target.value);
                }}
              />
              <label
                className="ml-2 text-[#344054] font-semibold text-[16px]"
                for={TariffType.FLAT_RATE}>
                Flat Rate
              </label>
            </div>
            <div>
              <input
                disabled={!isEditMode}
                checked={tariffRateType === TariffType.VARIABLE_RATE}
                type="radio"
                id={TariffType.VARIABLE_RATE}
                name="tariff_rates"
                value={TariffType.VARIABLE_RATE}
                onChange={(e) => {
                  setTiers([
                    {
                      rate: null,
                      ranges: uoms.map((uomData) => {
                        return { id: uomData.id, start: 1, end: 2 };
                      }),
                    },
                    {
                      rate: null,
                      ranges: uoms.map((uomData) => {
                        return { id: uomData.id, start: 3 };
                      }),
                    },
                  ]);
                  setTariffRateType(e.target.value);
                }}
              />
              <label
                className="ml-2 text-[#344054] font-semibold text-[16px]"
                for={TariffType.VARIABLE_RATE}>
                Tiered Rate
              </label>
            </div>
          </div>
          {tariffRateType === TariffType.FLAT_RATE ? (
            tiers.map((tier, tierIndex) => {
              return (
                <div className="flex gap-2 bg-[#FAFAFA] rounded-lg p-3 mt-3">
                  {tier.ranges.map((range, rangeIndex) => {
                    return (
                      <div>
                        <div className="text-[#717679] text-[14px]">
                          Value {`(${getEntityName(range.id)})`}
                        </div>
                        <input
                          disabled={!isEditMode}
                          value={range.start}
                          onChange={(e) => {
                            setTiers(
                              tiers.map((tier, tIndex) => {
                                if (tIndex === tierIndex) {
                                  return {
                                    ...tier,
                                    ranges: tier.ranges.map((range, rIndex) => {
                                      if (rIndex === rangeIndex)
                                        return {
                                          ...range,
                                          start: parseInt(e.target.value),
                                        };
                                      else return range;
                                    }),
                                  };
                                }
                                return tier;
                              }),
                            );
                          }}
                          type="number"
                          min={1}
                          className="border border-[#DDDFE0] rounded bg-transparent p-1 w-[80px]"
                        />
                      </div>
                    );
                  })}
                  <div className="mt-6 mx-2">=</div>
                  <div>
                    <div className="text-[#717679] text-[14px]">
                      Fees ({currency})
                    </div>
                    <div className="flex gap-1">
                      <input
                        disabled={!isEditMode}
                        type="number"
                        step="0.01"
                        value={tier.rate}
                        onChange={(e) => {
                          console.log(parseFloat(e.target.value));
                          setTiers(
                            tiers.map((tier, tIndex) => {
                              if (tIndex === tierIndex) {
                                return {
                                  ...tier,
                                  rate: parseFloat(e.target.value),
                                };
                              }
                              return tier;
                            }),
                          );
                        }}
                        className="border border-[#DDDFE0] rounded bg-transparent p-1 w-[80px]"
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              {tiers.map((tier, tierIndex) => {
                return (
                  <div className="p-3 mt-3 bg-[#FAFAFA] rounded-lg flex justify-between">
                    <div>
                      {tier.ranges.map((range, rangeIndex) => {
                        return (
                          <div className="flex gap-2">
                            <div className="w-[100px]">
                              <div className="text-[#717679] text-[14px] truncate">
                                Value {`(${getEntityName(range.id)})`}
                              </div>
                              <input
                                disabled={!isEditMode}
                                readOnly
                                value={range.start}
                                onChange={(e) => {
                                  setTiers(
                                    tiers.map((tier, tIndex) => {
                                      if (tIndex === tierIndex) {
                                        return {
                                          ...tier,
                                          ranges: tier.ranges.map(
                                            (range, rIndex) => {
                                              if (rIndex === rangeIndex)
                                                return {
                                                  ...range,
                                                  start: parseInt(
                                                    e.target.value,
                                                  ),
                                                };
                                              else return range;
                                            },
                                          ),
                                        };
                                      }
                                      return tier;
                                    }),
                                  );
                                }}
                                type="number"
                                min={1}
                                className="border border-[#DDDFE0] rounded bg-transparent p-1 w-[80px]"
                              />
                            </div>
                            {tierIndex < tiers.length - 1 ? (
                              <>
                                <div className="mt-6 mx-1">To</div>

                                <div className="w-[100px]">
                                  <div className="text-[#717679] text-[14px] truncate">
                                    Value {`(${getEntityName(range.id)})`}
                                  </div>
                                  <input
                                    disabled={!isEditMode}
                                    value={range.end}
                                    onChange={(e) => {
                                      setTiers(
                                        tiers.map((tier, tIndex) => {
                                          if (tIndex === tierIndex) {
                                            return {
                                              ...tier,
                                              ranges: tier.ranges.map(
                                                (range, rIndex) => {
                                                  if (rIndex === rangeIndex)
                                                    return {
                                                      ...range,
                                                      end: parseInt(
                                                        e.target.value,
                                                      ),
                                                    };
                                                  else return range;
                                                },
                                              ),
                                            };
                                          }
                                          // also update start of next tier
                                          if (tIndex === tierIndex + 1) {
                                            return {
                                              ...tier,
                                              ranges: tier.ranges.map(
                                                (range, rIndex) => {
                                                  if (rIndex === rangeIndex)
                                                    return {
                                                      ...range,
                                                      start:
                                                        parseInt(
                                                          e.target.value,
                                                        ) + 1,
                                                    };
                                                  return range;
                                                },
                                              ),
                                            };
                                          }
                                          return tier;
                                        }),
                                      );
                                    }}
                                    type="number"
                                    className="border border-[#DDDFE0] rounded bg-transparent p-1 w-[80px]"
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="mt-6">and more</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="my-auto mx-2">=</div>
                    <div className="my-auto">
                      <div className="text-[#717679] text-[14px]">
                        Fees (per, {currency})
                      </div>
                      <div className="flex gap-1">
                        <input
                          disabled={!isEditMode}
                          step="0.01"
                          value={tier.rate}
                          onChange={(e) => {
                            setTiers(
                              tiers.map((tier, tIndex) => {
                                if (tIndex === tierIndex) {
                                  return {
                                    ...tier,
                                    rate: parseFloat(e.target.value),
                                  };
                                }
                                return tier;
                              }),
                            );
                          }}
                          type="number"
                          className="border border-[#DDDFE0] rounded bg-transparent p-1 w-[80px]"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              {isEditMode && (
                <button
                  onClick={() => {
                    // when a rate is added -> 1) last range gets and end value and 2) a new range is added with just start
                    let lastTier = tiers[tiers.length - 1];
                    setTiers([
                      ...tiers.map((tier, tIndex) => {
                        if (tIndex === tiers.length - 1)
                          return {
                            ...tier,
                            ranges: tier.ranges.map((range) => {
                              return { ...range, end: range?.start + 1 };
                            }),
                          };
                        return tier;
                      }),
                      {
                        rate: null,
                        ranges: lastTier.ranges.map((range) => {
                          return { ...range, start: range.start + 1 + 1 };
                        }),
                      },
                    ]);
                  }}
                  className="text-[#417492] font-semibold text-[16px] flex underline">
                  + Add More Rates
                </button>
              )}
            </div>
          )}
        </div>
        <div className="absolute bottom-0 right-4 shadow-sm bg-white w-full">
          <div className="flex gap-4 justify-end my-2">
            <button
              onClick={() => setShowSidePanel(false)}
              className="w-32 rounded-lg py-1 border border-[#DDD] text-[16px]">
              Cancel
            </button>
            <button
              disabled={!isEditMode}
              data-tip
              data-for="saveTariff"
              onClick={() => {
                if (isSaveDisabled) {
                  appState.setAlert(
                    "Please enter a valid value in the 'Fees' field to save your changes.",
                    "error",
                    5000,
                  );
                } else {
                  if (frequencyOpted) {
                    saveTariff({
                      ...tariffDetails,
                      uoms,
                      tiers,
                      flatRate: tariffRateType === TariffType.FLAT_RATE,
                      resetTime: resetTime,
                      options: {
                        endOfPeriodCalculation:
                          calculationOption ===
                          CalculationOptions.END_OF_PERIOD_OCCUPANCY,
                      },
                    });
                  } else {
                    saveTariff({
                      ...tariffDetails,
                      uoms,
                      tiers,
                      flatRate: tariffRateType === TariffType.FLAT_RATE,
                      options: {
                        endOfPeriodCalculation:
                          calculationOption ===
                          CalculationOptions.END_OF_PERIOD_OCCUPANCY,
                      },
                    });
                  }
                }
              }}
              className={`${
                isSaveDisabled ? "opacity-60" : ""
              } w-32 rounded-lg py-1 bg-[#417492] text-[16px] text-white`}>
              Save
            </button>
            <ReactTooltip
              id="saveTariff"
              place="bottom"
              type="info"
              effect="solid">
              Please enter a valid value in the 'Fees' field to save your
              changes.
            </ReactTooltip>
          </div>
        </div>
      </div>
    </SlideOverPanel>
  );
};

export default TariffSidePanel;
