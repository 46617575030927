// hooks imports
import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
// queries, mutations imports
import {
  GET_PRODUCTS,
  GET_SKU,
  GET_CATALOG,
  GET_ENTITY_TYPES,
  EXPLAIN_INVENTORY,
  GET_TAGS,
  GET_CATEGORIES,
} from "#queries";
import {
  SAVE_PRODUCT,
  SAVE_ENTITY_TYPE,
  DELETE_PRODUCT,
  ADD_TAGS_TO_PRODUCTS,
  SAVE_USER,
} from "#mutations";
// contexts imports
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
// other imports
import _ from "lodash";
import PropTypes from "prop-types";

const ALERT_VISIBILITY_IN_MS = 5000; // 5 seconds
const DEFAULT_QUERY_OPERATOR = "all";

/**
 * Higher-Order Component that is responsible for managing,providing data for catalog management.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped. In this case it comes from catalogs.jsx
 * @returns {React.Component} - The enhanced component with props data.
 */
const withCatalogsLogic = (WrappedComponent) => {
  return (props) => {
    // query declarations
    const saveUserQuery = useQuery(SAVE_USER);
    const getCatalogQuery = useQuery(GET_CATALOG);
    const explainInventoryQuery = useQuery(EXPLAIN_INVENTORY);
    const productsQuery = useQuery(GET_PRODUCTS);
    const tagsQuery = useQuery(GET_TAGS);
    const categoriesQuery = useQuery(GET_CATEGORIES);
    const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
    const saveProductQuery = useQuery(SAVE_PRODUCT);
    const deleteProductQuery = useQuery(DELETE_PRODUCT);
    const getSpecificProduct = useQuery(GET_SKU);
    const saveEntityTypeQuery = useQuery(SAVE_ENTITY_TYPE);
    const addTagsToProductsQuery = useQuery(ADD_TAGS_TO_PRODUCTS);

    // context declarations
    const auth = useContext(AuthContext);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);

    // state declarations
    const [manageTags, setManageTags] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [allProductsSelected, setAllProductsSelected] = useState(false);
    const [lastSelectedRow, setLastSelectedRow] = useState(null);
    const [inventoryExplanations, setInventoryExplanations] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [fetchedCatalog, setFetchedCatalog] = useState(null);
    const [showSingleImageView, setShowSingleImageView] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    const [entityTypes, setEntityTypes] = useState([]);
    const [entityAttributes, setEntityAttributes] = useState({});
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const [filterQueryOperator, setFilterQueryOperator] = useState(
      DEFAULT_QUERY_OPERATOR,
    );
    const [productTags, setProductTags] = useState([]);
    const [productCategories, setProductCategories] = useState([]);

    useEffect(() => {
      if (categoriesQuery.data) {
        setProductCategories(categoriesQuery.data.categories.entities);
      }

      if (categoriesQuery.error) {
        setProductCategories([]);
      }
    }, [categoriesQuery.data, categoriesQuery.error]);

    useEffect(() => {
      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop !==
          document.documentElement.offsetHeight
        ) {
          checkPagination("forward");
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
      fetchEntityTypes();
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    /**
     * Perform side effects based on the state of `entityTypesQuery`.
     * @param {Object} entityTypesQuery - The object containing the query state.
     */
    useEffect(() => {
      if (entityTypesQuery.data) {
        setEntityTypes(entityTypesQuery.data.entityTypes);
      }
      if (entityTypesQuery.error) {
        setEntityTypes([]);
      }
    }, [entityTypesQuery.data, entityTypesQuery.error]);

    /**
     * Perform side effects based on the state of `entityTypes`.
     * @param {Array<Object>} entityTypes - An array of entityTypes objects
     */
    useEffect(() => {
      const attributes = entityTypes.reduce((result, obj) => {
        const entityParent = obj["entityParent"];
        if (!result[entityParent]) {
          result[entityParent] = [];
        }
        result[entityParent].push(obj);
        return result;
      }, {});

      setEntityAttributes(attributes);
    }, [entityTypes]);

    /**
     * Perform side effects based on the state of `saveEntityTypeQuery`.
     * @param {Object} saveEntityTypeQuery - The object containing the query state.
     */
    useEffect(() => {
      if (saveEntityTypeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveEntityTypeQuery.data) {
        const filtersSet = {
          entityParent: [
            "PRODUCTSIZE",
            "PRODUCTSHAPE",
            "PRODUCTCOLOR",
            "PRODUCTCATEGORY",
            "PRODUCTTYPE",
          ],
        };
        entityTypesQuery.fetchData({ filters: filtersSet });
        setAddCustomEntity(null);
      }

      if (saveEntityTypeQuery.error) {
        appState.setDismissableAlert(
          saveEntityTypeQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      saveEntityTypeQuery.loading,
      saveEntityTypeQuery.data,
      saveEntityTypeQuery.error,
    ]);

    /**
     * Perform side effects to load first time data when auth context is updated.
     * @param {Object} auth - The object containing the auth context
     */
    useEffect(() => {
      loadFirstTimeData();
    }, [auth]);

    /**
     * Perform side effects based on the state of `productsQuery`.
     * @param {Object} productsQuery - The object containing the query state.
     */
    useEffect(() => {
      if (productsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (productsQuery.data && productsQuery.data.products) {
        entity.setEntities({
          ...productsQuery.data.products,
          ...productsQuery.variables,
        });
      }
    }, [productsQuery.error, productsQuery.data, productsQuery.loading]);

    /**
     * Perform side effects based on the state of `deleteProductQuery`.
     * @param {Object} deleteProductQuery - The object containing the query state.
     */
    useEffect(() => {
      if (deleteProductQuery.data) {
        appState.hideConfirmation();
        appState.setDismissableAlert(
          deleteProductQuery.data.deleteProduct.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
      }

      if (deleteProductQuery.error) {
        appState.setDismissableAlert(
          deleteProductQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
      }
    }, [
      deleteProductQuery.loading,
      deleteProductQuery.data,
      deleteProductQuery.error,
    ]);

    /**
     * Perform side effects based on the state of `getCatalogQuery`.
     * @param {Object} getCatalogQuery - The object containing the query state.
     */
    useEffect(() => {
      if (getCatalogQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCatalogQuery.data && getCatalogQuery.data.specificCatalog) {
        setFetchedCatalog(getCatalogQuery.data.specificCatalog);
      }

      if (getCatalogQuery.error) {
        appState.setDismissableAlert(
          getCatalogQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [getCatalogQuery.loading, getCatalogQuery.error, getCatalogQuery.data]);

    /**
     * Perform side effects based on the state of `explainInventoryQuery`.
     * @param {Object} explainInventoryQuery - The object containing the query state.
     */
    useEffect(() => {
      if (explainInventoryQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (explainInventoryQuery.data) {
        setInventoryExplanations([
          ...inventoryExplanations,
          explainInventoryQuery.data.explainInventory,
        ]);
      }

      if (explainInventoryQuery.error) {
        appState.setDismissableAlert(
          explainInventoryQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      explainInventoryQuery.loading,
      explainInventoryQuery.error,
      explainInventoryQuery.data,
    ]);

    /**
     * Perform side effects based on the state of `getSpecificProduct`.
     * @param {Object} getSpecificProduct - The object containing the query state.
     */
    useEffect(() => {
      if (
        getSpecificProduct.data &&
        getSpecificProduct.data.specificInventory
      ) {
        setSelectedProduct({
          ...getSpecificProduct.data.specificInventory,
          uomConfiguration: getSpecificProduct.data.specificInventory
            .uomConfiguration
            ? getSpecificProduct.data.specificInventory.uomConfiguration
            : [],
        });
      }

      if (getSpecificProduct.error) {
        appState.setDismissableAlert(
          getSpecificProduct.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getSpecificProduct.loading,
      getSpecificProduct.error,
      getSpecificProduct.data,
    ]);

    /**
     * Perform side effects based on the state of `saveProductQuery`.
     * @param {Object} saveProductQuery - The object containing the query state.
     */
    useEffect(() => {
      if (saveProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveProductQuery.error) {
        appState.setDismissableAlert(
          saveProductQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveProductQuery.data) {
        appState.setDismissableAlert(saveProductQuery.data.saveProduct.message);
        setSelectedProduct(null);
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        });
      }
    }, [
      saveProductQuery.data,
      saveProductQuery.loading,
      saveProductQuery.error,
    ]);

    /**
     * Perform side effects based on the state of `addTagsToProductsQuery`.
     * @param {Object} addTagsToProductsQuery - The object containing the query state.
     */
    useEffect(() => {
      if (addTagsToProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (addTagsToProductsQuery.data) {
        appState.setDismissableAlert(
          addTagsToProductsQuery.data.addTagsToProducts.message,
        );
        setManageTags(false);
      }

      if (addTagsToProductsQuery.error) {
        appState.setDismissableAlert(
          addTagsToProductsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      addTagsToProductsQuery.loading,
      addTagsToProductsQuery.data,
      addTagsToProductsQuery.error,
    ]);

    // fetch the list of required entityTypes using entityTypesQuery
    const fetchEntityTypes = () => {
      const filtersSet = {
        entityParent: [
          "PRODUCTSIZE",
          "PRODUCTSHAPE",
          "PRODUCTCOLOR",
          "PRODUCTCATEGORY",
          "PRODUCTTYPE",
        ],
      };
      entityTypesQuery.fetchData({ filters: filtersSet });
    };

    useEffect(() => {
      if (tagsQuery.data) {
        setProductTags(tagsQuery.data.tags.entities);
      }
    }, [tagsQuery.data]);

    // loads the first time data that are required for this page.
    const loadFirstTimeData = () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        let warehouses = auth.user.warehousesList;
        let customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setDismissableAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        }
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: {
            customer: auth.user.customers,
          },
          paginated: false,
          sort: entity.sort,
          queryOperator: DEFAULT_QUERY_OPERATOR,
        });
        tagsQuery.fetchData({
          perPage: 100,
          pageNumber: 1,
          filters: {},
        });
        categoriesQuery.fetchData();
      }
    };

    // Provide an explanation for inventory items based on the provided parameters.
    const explainInventory = (id, typeOfBreakdown, typeOfInnerBreakdown) => {
      explainInventoryQuery.fetchData({
        id,
        typeOfBreakdown,
        typeOfInnerBreakdown,
      });
    };

    // function to check whether the requested productList page can be obtained from the entityContext or need to fetch from backend
    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < entity.total) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
          queryOperator: filterQueryOperator,
        };
        return productsQuery.fetchData(vars);
      }
    };

    // functions to modify selected product
    const onChange = (event) => {
      const product = {
        ...selectedProduct,
      };

      if (event.target.type === "number") {
        product[event.target.name] = parseInt(event.target.value);
      } else if (
        event.target.name === "upc" ||
        event.target.name === "ean" ||
        event.target.name === "lpn"
      ) {
        product[event.target.name] = event.target.value.split(",");
      } else if (event.target.type === "attribute") {
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[event.target.name] = event.target.value;
      } else {
        product[event.target.name] = event.target.value;
      }
      setSelectedProduct(product);
    };
    const onChangeDropdown = (field, value, entity = "selectedProduct") => {
      const product = {
        ...selectedProduct,
      };
      if (field === "size") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTSIZE",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "shape") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTSHAPE",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "color") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTCOLOR",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "category") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTCATEGORY",
            name: "",
          });
        }
        product[field] = value;
        setSelectedProduct(product);
      }
      if (field === "type") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTTYPE",
            name: "",
          });
        }
        product[field] = value;
        setSelectedProduct(product);
      }
      product[field] = value;
      setSelectedProduct(product);
    };
    const AttributeValues = (name, value) => {
      const product = {
        ...selectedProduct,
      };
      if (!product.attributes) {
        product.attributes = {};
      }

      product.attributes[name] = value;
      setSelectedProduct(product);
    };
    const deleteAttributes = (name) => {
      const product = {
        ...selectedProduct,
      };
      const attributes = product.attributes;
      if (attributes[name]) delete attributes[name];
      if (Object.keys(attributes).length === 0) delete product.attributes;
      setSelectedProduct(product);
    };
    const deleteProduct = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this product?",
        () => {
          deleteProductQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };
    const addTagsToProducts = (selectedTags) => {
      if (selectedTags && !selectedTags.length) {
        return appState.setDismissableAlert(
          "No tags selected to modify the products",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      addTagsToProductsQuery.fetchData({
        productIds: selectedProducts,
        tags: selectedTags,
      });
    };

    // functions for handling selections in the catalog list
    const selectProduct = (index, shiftKeyPressed) => {
      const selectedListCopy = [...selectedProducts];
      const entities = entity.entities;
      if (shiftKeyPressed) {
        if (lastSelectedRow !== null) {
          const firstIndex = Math.min(lastSelectedRow, index);
          const lastIndex = Math.max(lastSelectedRow, index);
          for (let i = firstIndex; i <= lastIndex; i++) {
            if (!selectedListCopy.includes(entities[i].id)) {
              selectedListCopy.push(entities[i].id);
            }
          }
        } else {
          setLastSelectedRow(index);
        }
      } else {
        setLastSelectedRow(index);
        const idx = selectedListCopy.indexOf(entities[index].id);
        if (idx !== -1) {
          selectedListCopy.splice(idx, 1);
        } else {
          selectedListCopy.push(entities[index].id);
        }
      }

      setSelectedProducts(selectedListCopy);
    };
    const selectAllProducts = () => {
      if (allProductsSelected === true) {
        setSelectedProducts([]);
        setAllProductsSelected(false);
      } else {
        setAllProductsSelected(true);
        setSelectedProducts(entity.entities.map((product) => product.id));
      }
    };

    const deleteImage = (idx) => {
      if (selectedProduct && selectedProduct.images) {
        const images = selectedProduct.images.filter(
          (item, index) => index !== idx,
        );
        setSelectedProduct({
          ...selectedProduct,
          images,
        });
      }
    };

    const PAGE_NAME = "CATALOG_MANAGEMENT";

    const saveBookmark = (bookmarkToSave) => {
      if (!auth.user.pageSavedFilters) {
        auth.user.pageSavedFilters = {};
      }

      if (!auth.user.pageSavedFilters[PAGE_NAME]) {
        auth.user.pageSavedFilters[PAGE_NAME] = [];
      }

      const savedFilters = auth.user.pageSavedFilters[PAGE_NAME];
      const existingFilter = savedFilters.find(
        (filter) => filter.name === bookmarkToSave.name,
      );
      if (existingFilter) {
        existingFilter.filters = { ...entity.filters };
        existingFilter.queryOperator = filterQueryOperator;
      } else {
        bookmarkToSave.queryOperator = filterQueryOperator;
        savedFilters.push(bookmarkToSave);
      }

      saveUserQuery.fetchData({
        ...auth.user,
      });
    };

    useEffect(() => {
      if (saveUserQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveUserQuery.data) {
        appState.setDismissableAlert(
          "Bookmark saved successfully",
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveUserQuery.error) {
        appState.setDismissableAlert(
          saveUserQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [saveUserQuery.loading, saveUserQuery.data, saveUserQuery.error]);

    return (
      <WrappedComponent
        {...props}
        displayProducts={entity.displayEntities}
        products={entity.entities}
        productTags={productTags}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        showSingleImageView={showSingleImageView}
        setShowSingleImageView={setShowSingleImageView}
        addCustomEntity={addCustomEntity}
        setAddCustomEntity={setAddCustomEntity}
        onSubmitCustomEntity={() =>
          saveEntityTypeQuery.fetchData({
            entityTypeInput: {
              ...addCustomEntity,
            },
          })
        }
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        AttributeValues={AttributeValues}
        deleteAttributes={deleteAttributes}
        saveProduct={() => {
          saveProductQuery.fetchData({
            ...selectedProduct,
            images:
              selectedProduct.images && selectedProduct.images.length > 0
                ? selectedProduct.images.map((image) => {
                    delete image.display_url;
                    return image;
                  })
                : [],
          });
        }}
        deleteProduct={deleteProduct}
        productSizes={entityAttributes?.["PRODUCTSIZE"]}
        productShapes={entityAttributes?.["PRODUCTSHAPE"]}
        productColors={entityAttributes?.["PRODUCTCOLOR"]}
        productTypes={entityAttributes?.["PRODUCTTYPE"]}
        getCatalog={(id) => {
          getCatalogQuery.fetchData({ id });
        }}
        getSpecificProduct={(id) => {
          getSpecificProduct.fetchData({ id });
        }}
        fetchedCatalog={fetchedCatalog}
        setFetchedCatalog={setFetchedCatalog}
        manageTags={manageTags}
        setManageTags={setManageTags}
        addTagsToProducts={addTagsToProducts}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          productsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
            queryOperator: filterQueryOperator,
          });
        }}
        filters={entity.filters}
        setFilters={entity.setFilters}
        submitFilters={() => {
          setShowFilters(false);
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
            queryOperator: filterQueryOperator,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
            queryOperator: filterQueryOperator,
          });
        }}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            productsQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
              queryOperator: filterQueryOperator,
            });
          }
        }}
        onChangeAttributes={(field, value) => {
          const entityFilters = { ...entity.filters };
          if (!entityFilters.attributes) entityFilters.attributes = {};
          entityFilters.attributes = {
            ...entityFilters.attributes,
            [field]: value,
          };
          entity.setFilters({ ...entityFilters });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        pageSavedFilters={
          auth?.user?.pageSavedFilters && auth.user.pageSavedFilters[PAGE_NAME]
            ? auth.user.pageSavedFilters[PAGE_NAME]
            : []
        }
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
            queryOperator: filterQueryOperator,
          });
        }}
        selectedProducts={selectedProducts}
        allRowsSelected={allProductsSelected}
        selectProduct={selectProduct}
        selectAllRows={selectAllProducts}
        explainInventory={explainInventory}
        inventoryExplanations={inventoryExplanations}
        setInventoryExplanations={setInventoryExplanations}
        filterQueryOperator={filterQueryOperator}
        setFilterQueryOperator={setFilterQueryOperator}
        deleteImage={deleteImage}
        saveBookmark={saveBookmark}
        applyBookmarkFilters={(savedFilter) => {
          entity.setFilters(savedFilter.filters);
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: savedFilter.filters,
            sort: entity.sort,
            queryOperator: savedFilter.queryOperator || filterQueryOperator,
          });
          setFilterQueryOperator(
            savedFilter.queryOperator || filterQueryOperator,
          );
        }}
        productCategories={productCategories}
        setProductCategories={setProductCategories}
      />
    );
  };
};

withCatalogsLogic.propTypes = {
  // The component to be wrapped
  WrappedComponent: PropTypes.elementType.isRequired,
};

export default withCatalogsLogic;
