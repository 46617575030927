import { useState, useEffect } from "react";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import AddButton from "#components/utils/AddButton";
import { truncate } from "#utils";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import Autocomplete from "#components/utils/Autocomplete";

const WizardProductSelector = ({
  warehouses,
  selectedEntity,
  removeItem,
  onChangeInventorySearch,
  availableInventory,
  submitAddedProduct,
  getMarketplaceProducts,
  isMultiFormFactor,
  addingProductCallback,
  multipleFormFactors = true,
  productSkuBinMappings,
  lotIdSelectionEnabled,
  lpnSelectionEnabled,
  multiAccountSupportEnabled,
  productSkuBinMappingsWithLpn,
}) => {
  const [inventorySearchKeyword, setInventorySearchKeyword] = useState("");
  const [addingProduct, setAddingProduct] = useState(null);

  const renderFormFactorList = (product) => {
    return [
      { name: "Each" },
      { name: "Case" },
      { name: "Carton" },
      { name: "Pallet" },
    ].filter(
      (item) =>
        item.name === product.baseUOM ||
        product.uomConfiguration?.map((i) => i.targetUom).includes(item.name),
    );
  };

  const renderQuantityBasedOnWarehouses = (inventory, bucket) => {
    if (selectedEntity?.warehouse && inventory.quantities?.warehouses) {
      const allQuantitiesOfFilteredWarehouses =
        inventory.quantities.warehouses.find(
          (i) => i.warehouseId === selectedEntity.warehouse,
        );
      return allQuantitiesOfFilteredWarehouses?.[bucket] || 0;
    } else {
      return inventory.quantities?.[bucket] || 0;
    }
  };

  useEffect(() => {
    addingProductCallback(addingProduct);
  }, [addingProduct]);

  useEffect(() => {
    if (addingProduct?.productId && !addingProduct?.formFactors?.length > 0) {
      setAddingProduct({
        ...addingProduct,
        formFactors: [
          {
            name: addingProduct.baseUOM ?? "Each",
            quantity: 1,
          },
        ],
      });
    }
  }, [addingProduct?.productId]);

  const changeFormFactorQuantity = (idx, e) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].quantity =
      isNaN(parseInt(e.target.value)) === false
        ? parseInt(e.target.value)
        : null;
    setAddingProduct({ ...clonedProduct });
  };

  const changeFormFactor = (idx, name) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].name = name;
    setAddingProduct({ ...clonedProduct });
  };

  const changeLotId = (idx, lotId) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].lotId = lotId;
    setAddingProduct({ ...clonedProduct });
  };

  const changeNestedFormFactorId = (idx, nestedFormFactorId) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].nestedFormFactorId = nestedFormFactorId;
    setAddingProduct({ ...clonedProduct });
  };

  const removeFormFactor = (idx) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors = clonedProduct.formFactors.filter(
      (item, index) => idx !== index,
    );
    setAddingProduct({ ...clonedProduct });
  };

  return !addingProduct ? (
    <>
      <table className="table-auto items-start text-left w-full">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-2 text-md text-gray-500">SKU</th>
            {warehouses &&
              warehouses.findIndex(
                (i) =>
                  i.typeOfWarehouse &&
                  i.typeOfWarehouse.includes("PREP CENTER"),
              ) !== -1 && (
                <th className="px-6 py-2 text-md text-gray-500">ASIN</th>
              )}
            {!isMultiFormFactor && (
              <>
                <th className="px-6 py-2 text-md text-gray-500">Form Factor</th>
                <th className="px-6 py-2 text-md text-gray-500">Quantity</th>
              </>
            )}
            {isMultiFormFactor && (
              <>
                <th className="px-6 py-2 text-md text-gray-500">
                  Quantity(Each)
                </th>
                <th className="px-6 py-2 text-md text-gray-500">
                  Quantity(Case)
                </th>
                <th className="px-6 py-2 text-md text-gray-500">
                  Quantity(Carton)
                </th>
                <th className="px-6 py-2 text-md text-gray-500">
                  Quantity(Pallet)
                </th>
              </>
            )}
            {lotIdSelectionEnabled && (
              <th className="px-6 py-2 text-md text-gray-500">Lot/Batch ID</th>
            )}
            {lpnSelectionEnabled && (
              <th className="px-6 py-2 text-md text-gray-500">LPN</th>
            )}

            {multiAccountSupportEnabled && (
              <th className="px-6 py-2 text-md text-gray-500">Marketplace</th>
            )}
            {multiAccountSupportEnabled && (
              <th className="px-6 py-2 text-md text-gray-500">Seller</th>
            )}
            <th className="px-6 py-2 text-md text-gray-500">Action</th>
          </tr>
        </thead>
        <tbody>
          {selectedEntity.items &&
            selectedEntity.items.map((item, idx) => (
              <tr index={idx} className="whitespace-nowrap">
                <td className="px-6 py-4 text-md text-gray-500">{item.sku}</td>
                {warehouses &&
                  warehouses.findIndex(
                    (i) =>
                      i.typeOfWarehouse &&
                      i.typeOfWarehouse.includes("PREP CENTER"),
                  ) !== -1 && (
                    <td className="px-6 py-4 text-md text-gray-500">
                      {buildMarketplaceHyperlink(
                        item.asin,
                        "ASIN",
                        item.source,
                      )}
                    </td>
                  )}
                {!isMultiFormFactor && (
                  <>
                    <td className="px-6 py-4 text-md text-gray-500">
                      {item?.formFactors?.length && item?.formFactors[0]?.name}
                    </td>
                    <td className="px-6 py-4 text-md text-gray-500">
                      {item?.formFactors?.length &&
                        item?.formFactors[0]?.quantity}
                    </td>
                    {lotIdSelectionEnabled && (
                      <td className="px-6 py-4 text-md text-gray-500">
                        {item?.formFactors[0]?.lotId}
                      </td>
                    )}
                    {lpnSelectionEnabled && (
                      <td className="px-6 py-4 text-md text-gray-500">
                        {item?.formFactors[0]?.nestedFormFactorId}
                      </td>
                    )}
                  </>
                )}
                {multiAccountSupportEnabled && (
                  <td className="px-6 py-4 text-md text-gray-500">
                    {item?.marketplace}
                  </td>
                )}
                {multiAccountSupportEnabled && (
                  <td className="px-6 py-4 text-md text-gray-500">
                    {item?.seller}
                  </td>
                )}
                {isMultiFormFactor && (
                  <>
                    <td className="px-6 py-4 text-md text-gray-500">
                      {
                        item?.formFactors?.find((i) => i.name === "Each")
                          ?.quantity
                      }
                    </td>
                    <td className="px-6 py-4 text-md text-gray-500">
                      {
                        item?.formFactors?.find((i) => i.name === "Case")
                          ?.quantity
                      }
                    </td>
                    <td className="px-6 py-4 text-md text-gray-500">
                      {
                        item?.formFactors?.find((i) => i.name === "Carton")
                          ?.quantity
                      }
                    </td>
                    <td className="px-6 py-4 text-md text-gray-500">
                      {
                        item?.formFactors?.find((i) => i.name === "Pallet")
                          ?.quantity
                      }
                    </td>
                  </>
                )}

                <td className="px-6 py-4 text-md text-gray-500">
                  <div className="flex space-x-2">
                    <span
                      className="text-blue-500 text-lg pl-2 font-bold cursor-pointer"
                      onClick={() =>
                        setAddingProduct({
                          ...item,
                          addingNew: true,
                          editing: true,
                          idx,
                        })
                      }>
                      Edit
                    </span>
                    <span
                      className="text-red-500 text-lg pl-2 font-bold cursor-pointer"
                      onClick={() => {
                        removeItem(idx);
                      }}>
                      Remove
                    </span>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <AddButton
        text="Add Item"
        onClick={() =>
          setAddingProduct({
            addingNew: true,
          })
        }
      />
    </>
  ) : (
    <>
      <div className="space-y-4 items-center" key={0}>
        <div className="space-y-4">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
            Search
          </label>
          <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block p-4 pl-10 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search by SKU/UPC/LPN/Name"
              onChange={(e) => {
                setInventorySearchKeyword(e.target.value);
                onChangeInventorySearch(e.target.value);
              }}
            />
          </div>
        </div>
        {inventorySearchKeyword && inventorySearchKeyword.trim() !== "" && (
          <div className="max-h-96 overflow-scroll">
            <table className="table-auto items-start text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-2 text-md text-gray-500">ASIN</th>
                  <th className="px-6 py-2 text-md text-gray-500">SKU</th>
                  <th className="px-6 py-2 text-md text-gray-500">Name</th>
                  <th className="px-6 py-2 text-md text-gray-500">
                    Available Qty
                  </th>
                  {multiAccountSupportEnabled && (
                    <th className="px-6 py-2 text-md text-gray-500">
                      Marketplace
                    </th>
                  )}
                  {multiAccountSupportEnabled && (
                    <th className="px-6 py-2 text-md text-gray-500">
                      Seller Account
                    </th>
                  )}
                  <th className="px-6 py-2 text-md text-gray-500">Action</th>
                </tr>
              </thead>
              <tbody class="bg-white">
                {availableInventory.length === 0 && (
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 text-md text-gray-500">
                      No Results
                    </td>
                    <td className="px-6 py-4 text-md text-gray-500"></td>
                    <td className="px-6 py-4 text-md text-gray-500"></td>
                    <td className="px-6 py-4 text-md text-gray-500"></td>
                    {multiAccountSupportEnabled && (
                      <td className="px-6 py-4 text-md text-gray-500"></td>
                    )}
                    {multiAccountSupportEnabled && (
                      <td className="px-6 py-4 text-md text-gray-500"></td>
                    )}
                  </tr>
                )}

                {availableInventory.length > 0 &&
                  availableInventory.map((item, idx) => (
                    <tr index={idx} className="whitespace-nowrap">
                      <td className="px-6 py-4 text-md text-gray-500">
                        {buildMarketplaceHyperlink(
                          item.asin,
                          "ASIN",
                          item.source,
                        )}
                      </td>
                      <td className="px-6 py-4 text-md text-gray-500">
                        {item.sku}
                      </td>
                      <td
                        className="px-6 py-4 text-md text-gray-500"
                        title={item.name}>
                        {truncate(item.name)}
                      </td>
                      <td className="px-6 py-4 text-md text-gray-500">
                        {renderQuantityBasedOnWarehouses(
                          item,
                          "availableToShip",
                        )}
                      </td>
                      {multiAccountSupportEnabled && (
                        <td
                          className="px-6 py-4 text-md text-gray-500"
                          title={item.name}>
                          {
                            item.marketplaceAttributes?.attributes
                              ?.marketplaceCountryCode
                          }
                        </td>
                      )}
                      {multiAccountSupportEnabled && (
                        <td className="px-6 py-4 text-md text-gray-500">
                          {item.marketplaceAttributes?.attributes?.sellerId}
                        </td>
                      )}
                      <td className="px-6 py-4 text-md text-gray-500">
                        <div
                          onClick={() => {
                            setAddingProduct({
                              ...addingProduct,
                              productId: item.id,
                              asin: item.asin,
                              sku: item.sku,
                              sellerSku: item.sellerSku,
                              productName: item.name,
                              availableQty:
                                item.quantities?.availableToShip ?? 0,
                              baseUOM: item.baseUom ?? null,
                              marketplaceAttributes:
                                item.marketplaceAttributes ?? null,
                              attributes: item.attributes ?? null,
                              fnSku: item.fnSku,
                              name: item.name,
                              marketplace:
                                item.marketplaceAttributes?.attributes
                                  ?.marketplaceCountryCode,
                              seller:
                                item.marketplaceAttributes?.attributes
                                  ?.sellerId,
                              uomConfiguration: item.uomConfiguration,
                            });
                            setInventorySearchKeyword(null);
                          }}
                          className="text-md text-white rounded-md text-center p-2 bg-2C7695 cursor-pointer">
                          Select
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {(!inventorySearchKeyword || inventorySearchKeyword.trim() === "") &&
          addingProduct && (
            <>
              {addingProduct.sku && (
                <>
                  <div class="p-6 max-w-2xl bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {addingProduct.productName}
                      </h5>
                    </a>
                    {addingProduct.asin && (
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        ASIN:
                        <span className="font-bold">
                          {buildMarketplaceHyperlink(
                            addingProduct.asin,
                            "ASIN",
                            addingProduct.source,
                          )}
                        </span>
                      </p>
                    )}
                    {addingProduct.sku && (
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        SKU:
                        <span className="font-bold px-2">
                          {addingProduct.sku}
                        </span>
                      </p>
                    )}

                    <div
                      class="cursor-pointer inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                      onClick={() =>
                        setAddingProduct({
                          asin: null,
                          sku: null,
                          name: null,
                          formFactors: [],
                        })
                      }>
                      Remove
                    </div>
                  </div>
                </>
              )}
            </>
          )}

        {addingProduct?.formFactors &&
          addingProduct.formFactors.map((form, idx) => (
            <div className="flex space-x-4 items-center">
              <div>
                <TextField
                  type="number"
                  id="quantity"
                  label="Quantity"
                  placeholder=" "
                  onChange={(e) => changeFormFactorQuantity(idx, e)}
                  value={parseInt(form.quantity)}
                  name="quantity"
                  min="0"
                />
              </div>
              <div>
                <Dropdown
                  placeholder={"Form Factor"}
                  list={renderFormFactorList(addingProduct)}
                  labelKey="name"
                  valueKey="name"
                  name="formFactor"
                  setSelected={(e) => changeFormFactor(idx, e)}
                  selectedValue={form.name}
                />
              </div>
              {lotIdSelectionEnabled && productSkuBinMappings && (
                <div className="w-96">
                  <Autocomplete
                    options={productSkuBinMappings}
                    labelKey="lotId"
                    secondaryLabelKey="unallocatedQuantity"
                    valueKey="lotId"
                    onChange={(e) => changeLotId(idx, e)}
                    value={form.lotId}
                    placeholder={"Lot/Batch ID"}
                    id="LOT_ID"
                  />
                </div>
              )}
              {lpnSelectionEnabled && productSkuBinMappingsWithLpn && (
                <div className="w-96">
                  <Autocomplete
                    options={productSkuBinMappingsWithLpn}
                    labelKey="nestedFormFactorId"
                    secondaryLabelKey="unallocatedQuantity"
                    valueKey="nestedFormFactorId"
                    onChange={(e) => changeNestedFormFactorId(idx, e)}
                    value={form.nestedFormFactorId}
                    placeholder={"LPN"}
                    id="NESTED_FORM_FACTOR_ID"
                  />
                </div>
              )}
            </div>
          ))}
        {multipleFormFactors && (
          <AddButton
            text="Add Form Factor"
            onClick={() =>
              // Only 1 formFactor can be added if multiFormFactor is disabled
              (isMultiFormFactor && addingProduct?.formFactors.length < 4) ||
              (!isMultiFormFactor && addingProduct?.formFactors.length < 1)
                ? setAddingProduct({
                    ...addingProduct,
                    formFactors: [
                      ...(addingProduct?.formFactors ?? []),
                      {
                        name: addingProduct.baseUOM ?? "Each",
                        quantity: 1,
                      },
                    ],
                  })
                : {}
            }
          />
        )}
      </div>

      {selectedEntity.customer && (
        <div className="flex items-center space-x-2 p-2">
          <div>Unable to find product?</div>
          <div
            onClick={getMarketplaceProducts}
            className={`rounded-full cursor-pointer inline-flex items-center justify-center px-2 py-2 text-sm font-medium text-white font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none bg-FC8862
              `}>
            {"Sync Products"}
          </div>
        </div>
      )}
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination">
        <div className="flex flex-1 justify-between sm:justify-end">
          <div className="flex flex-1 justify-between sm:justify-end space-x-2">
            <div
              onClick={() => setAddingProduct(null)}
              className="rounded-md cursor-pointer bg-red-600 inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none hover:bg-red-700">
              Cancel
            </div>
            <div
              onClick={
                addingProduct.sku
                  ? () => {
                      submitAddedProduct(addingProduct);
                      setAddingProduct(null);
                    }
                  : () => {}
              }
              className={`rounded-md ${
                addingProduct.sku ? "bg-224E73 cursor-pointer" : "bg-gray-400"
              } inline-flex items-center text-lg justify-center px-2 py-2 text-md font-medium text-white font-montserrat font-medium border C2D2DF-300 rounded ring-0 focus:ring-0 outline-none focus:outline-none`}>
              Add Product
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default WizardProductSelector;
