import moment from "moment-timezone";
import _ from "lodash";
import SearchSortFilter from "#components/common/SearchSortFilter";
import { AppStateContext } from "#contexts/appState";
import { useContext } from "react";
import CustomSelectDropDown from "../common/CustomSelectDropDown";
import CustomTableWithTheme from "../common/CustomTableWithTheme";

const headers = ["ID", "User", "Client", "Date", "Type", "Status"];
const noValuesText = "No notifications";

const NotificationsList = ({
  notifications,
  filters,
  onChangeFilter,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  setShowFilters,
  clearFilters,
  sort,
  setSort,
  getNotification,
  users,
  customers,
}) => {
  const appState = useContext(AppStateContext);
  const STATUS_OPTIONS = [
    { value: "PENDING", label: "PENDING" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
  ];

  const TYPE_OF_NOTIFICATION = [
    { value: "RECEIVING_APPROVAL", label: "RECEIVING APPROVAL" },
    { value: "SHIPMENT_PLAN_ERROR", label: "SHIPMENT PLAN ERROR" },
    { value: "REPORT_GENERATION_FAILED", label: "REPORT GENERATION FAILED" },
    { value: "REPORT_GENERATED", label: "REPORT GENERATED" },
  ].concat(
    ["62cdb0ac6227b7ed224d79aa", "62bc96621cbab8cc2e0a05aa"].includes(
      appState?.tenant?.id,
    )
      ? [{ value: "NEW_SHOPIFY_ORDER", label: "NEW SHOPIFY ORDER" }]
      : [],
  );

  return (
    <div className="tableWrapper">
      <div className="py-5 flex">
        <div className="flex-1 justify-between flex min-w-max h-10 items-center">
          <div className="w-96 flex items-center space-x-4">
            <CustomSelectDropDown
              label={"Status"}
              options={STATUS_OPTIONS}
              value={
                filters?.status
                  ? STATUS_OPTIONS.find(
                      (option) => option.value == filters.status[0],
                    )
                  : ""
              }
              onChange={(selectedOption) =>
                onChangeFilter("status", [selectedOption.value], true)
              }
              className="min-w-max"
              placeholder="Status"
              isClearable={true}
            />
            <CustomSelectDropDown
              label={"Type Of Notification"}
              options={TYPE_OF_NOTIFICATION}
              value={
                filters?.typeOfNotification
                  ? TYPE_OF_NOTIFICATION.find(
                      (option) => option.value == filters.typeOfNotification[0],
                    )
                  : ""
              }
              onChange={(selectedOption) =>
                onChangeFilter(
                  "typeOfNotification",
                  [selectedOption.value],
                  true,
                )
              }
              className="min-w-max"
              placeholder="Type of Notification"
              isClearable={true}
            />
          </div>
        </div>
      </div>

      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Date",
            value: "createdAt",
          },
          {
            name: "Status",
            value: "status",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="p-4 rounded-full sticky top-0 left-0 bg-primaryAccent">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {notifications.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {notifications.map((notification, index) => (
            <tr
              key={notification.id}
              className={`${
                notification.status === "PENDING"
                  ? "bg-white"
                  : notification.status === "APPROVED"
                  ? "bg-green-100"
                  : "bg-red-200"
              }`}>
              <td
                className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  cursor-pointer ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}
                onClick={() => getNotification(notification.id)}>
                {notification.id}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-primaryAccent tracking-wider rounded-br rounded-tr cursor-pointer">
                {notification.requestor &&
                users &&
                users.length > 0 &&
                users.findIndex((i) => i.id === notification.requestor) !== -1
                  ? users.find((i) => i.id === notification.requestor).name
                  : notification.requestor}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {customers &&
                  customers.find((item) => item.id === notification.customer)
                    ?.name}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {moment(notification.createdAt).format("MMM Do YYYY, h:mm a")}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {notification.typeOfNotification}
              </td>

              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {notification.status}
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
    </div>
  );
};

export default NotificationsList;
