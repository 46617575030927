import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import _ from "lodash";
import SearchSortFilter from "../common/SearchSortFilter";
import CustomTableWithTheme from "../common/CustomTableWithTheme";
import Pagination from "#components/common/Pagination";
import QuickFilters from "../common/QuickFilters";

const StationsList = ({
  stations,
  editButtonClicked,
  deleteButtonClicked,
  headers,
  noValuesText,
  devices,
  customers,
  warehouses,
  writable,
  filters,
  submitFilters,
  clearKeyword,
  setSort,
  sort,
  clearFilters,
  onChangeSearchKeyword,
  total,
  pageNumber,
  perPage,
  setPerPage,
  checkPagination,
  onChangeFilter,
}) => {
  return (
    <div className="bg-E2E2E2 px-4 rounded-xl border border-gray-300 pb-20">
      {(customers.length > 1 || warehouses.length > 1) && (
        <QuickFilters
          warehouseFilterName={"warehouses"}
          customerFilterName={"customers"}
          customers={customers}
          warehouses={warehouses}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />
      )}
      <SearchSortFilter
        onChangeSearchKeyword={onChangeSearchKeyword}
        filters={filters}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setSort={setSort}
        sort={sort}
        setShowFilters={null}
        clearFilters={clearFilters}
        choices={[
          {
            name: "Name",
            value: "name",
          },
          {
            name: "Module",
            value: "hopstackModule",
          },
        ]}
      />
      <CustomTableWithTheme>
        <thead className="p-4 bg-primaryAccent sticky top-0 left-0 ">
          <tr className="text-textWhite font-montserrat border-left">
            {headers.map((header, headerIdx) =>
              headerIdx === 0 ? (
                <th
                  scope="col"
                  className="pl-4 px-1 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ) : (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium"
                  key={headerIdx}>
                  {header}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {stations.length === 0 ? (
            <tr className="bg-white">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <td
                    className="p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261"
                    key={headerIdx}>
                    {noValuesText}
                  </td>
                ) : (
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                    key={headerIdx}></td>
                ),
              )}
            </tr>
          ) : null}
          {stations.map((station, index) => (
            <tr
              key={station.id}
              className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
              <td
                className={`p-5 text-left font-semibold tracking-widerrounded-tl rounded-bl border-l-8  ${
                  index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                }`}>
                {station.name}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {station.hopstackModule}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {station.warehouses
                  ? warehouses
                      .filter((item) => station.warehouses.includes(item.id))
                      .map((item) => item.name)
                      .join(", ")
                  : ""}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                {station.customers
                  ? customers
                      .filter((item) => station.customers.includes(item.id))
                      .map((item) => item.name)
                      .join(", ")
                  : ""}
              </td>
              <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <div className="flex space-x-4 items-center">
                  <TableFieldButton
                    onClick={() => editButtonClicked(station)}
                    text={<PencilIcon className="w-6 h-6" />}
                    disabled={!writable}
                  />
                  <TableFieldButton
                    text={<TrashIcon className="w-6 h-6" />}
                    onClick={() => deleteButtonClicked(station.id)}
                    disabled={!writable}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTableWithTheme>
      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
      />
    </div>
  );
};

export default StationsList;
