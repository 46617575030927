import { useState, useEffect } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { GET_PRODUCTS } from "#queries";
import _ from "lodash";
import noSearchResultsIcon from "#static/images/noSearchResults.jpg";
import ListViewProducts from "./ListViewProducts";

const DEFAULT_SEARCH_FIELDS = [
  {
    name: "SKU",
    value: "sku",
    enabled: false,
  },
  {
    name: "Name",
    value: "name",
    enabled: false,
  },
  {
    name: "Description",
    value: "description",
    enabled: false,
  },
  {
    name: "UPC",
    value: "upc",
    enabled: false,
  },
];

const CatalogEnhancedSearch = (props) => {
  const productsQuery = useQuery(GET_PRODUCTS);
  const [keyword, setKeyword] = useState("");
  const [searchFields, setSearchFields] = useState(DEFAULT_SEARCH_FIELDS);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (keyword && keyword.trim() !== "") {
      onChangeProductSearch(keyword);
    }
  }, [searchFields]);

  const onChangeProductSearch = async (searchKeyword) => {
    const response = await productsQuery.fetchData({
      perPage: 25,
      pageNumber: 1,
      filters: {
        keyword: searchKeyword,
        searchFields: searchFields.filter((i) => i.enabled).map((i) => i.value),
      },
      sort: "-createdAt",
    });

    if (response.data.products) {
      setSearchResults(response.data.products.entities);
    }
  };
  const debouncedProductSearch = _.debounce(onChangeProductSearch, 500);

  return (
    <div className="flex-col flex-1 relative">
      <div className="relative rounded-md shadow-sm flex-1">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          className="block w-full rounded-md border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent text-lg"
          placeholder="Search Products"
          onChange={(e) => {
            setKeyword(e.target.value);
            debouncedProductSearch(e.target.value);
          }}
          value={keyword}
        />
      </div>
      {keyword && keyword.trim() !== "" && (
        <div className="w-full bg-white p-4 absolute border-2 border-[#DDDFE0] mt-4 z-20">
          <div className="flex-col">
            <div className="p-2 flex items-center">
              <h2 className="text-xl font-normal text-[#717679]">Criteria:</h2>
              <div className="flex flex-wrap">
                {searchFields.map((field) => (
                  <button
                    key={field.value}
                    className={`m-2 p-2 ${
                      field.enabled
                        ? "bg-primaryAccent text-white"
                        : "bg-[#F1F8FF] text-primaryAccent"
                    } rounded-md text-xl`}
                    onClick={() => {
                      if (searchFields.length === 1) {
                        return;
                      }
                      // DISABLE THE FIELD
                      const updatedFields = searchFields.map((f) => {
                        if (f.value === field.value) {
                          return {
                            ...f,
                            enabled: !field.enabled,
                          };
                        }
                        return f;
                      });
                      setSearchFields(updatedFields);
                    }}>
                    {field.name}
                  </button>
                ))}
              </div>
            </div>
            {!searchResults || searchResults.length === 0 ? (
              <div className="flex-col items-center justify-center h-full text-center pb-10 m-auto w-full">
                <img src={noSearchResultsIcon} className="mx-auto w-48" />
                <div className="text-2xl text-black mt-4">
                  No Results To Display
                </div>
              </div>
            ) : (
              <ListViewProducts
                {...props}
                products={searchResults}
                selectAllDisabled={true}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CatalogEnhancedSearch;
