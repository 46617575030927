import React, { useState } from "react";
// components import
import Slider from "react-slick";
import ReactPlayer from "react-player";
// other imports
import PropTypes from "prop-types";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";

// TO DO:
// Make this carousel more generic enough to move into #components/utils.

/**
 * CatalogGallery Settings
 */
const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  lazyLoad: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

/**
 * CatalogGallery component for displaying images and videos.
 * Allows selecting and displaying different media.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string[]} props.images - An array of image URLs.
 * @param {string[]} props.videos - An array of video URLs.
 * @param {string} props.width - Width of the slider content specified in pixels or relative units.
 * @param {string} props.height - Height of the slider content specified in pixels or relative units.
 * @returns {JSX.Element} - The rendered component.
 */
const CatalogGallery = ({
  images,
  videos,
  width = "100%",
  height = "20rem",
}) => {
  const handleImageLoad = (event) => {
    event.target.style.display = "block";
    event.target.previousSibling.style.display = "none"; // Hide the spinner
  };

  const handleImageError = (event) => {
    event.target.src = FALLBACK_IMAGE_URL; // Incase loading fails
  };

  return (
    <Slider {...settings} className="w-[80%] h-[85%] mx-auto mt-4 mb-4">
      {images &&
        images.map((imageURL, index) => (
          <div key={imageURL} className="focus:outline-none">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="w-10 h-10 border-t-4 border-zinc-300 border-solid rounded-full animate-spin"></div>
            </div>
            <img
              src={imageURL}
              alt={""}
              className="object-contain"
              style={{ width, height }}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </div>
        ))}
      {videos &&
        videos.map((videoURL, index) => (
          <div className="cursor-pointer" key={videoURL}>
            <ReactPlayer
              url={videoURL}
              width="100%"
              height={height}
              className="mx-auto rounded-md cursor-pointer"
            />
          </div>
        ))}
    </Slider>
  );
};

CatalogGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  videos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CatalogGallery;
