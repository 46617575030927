import PropTypes from "prop-types";
import { useQuery } from "#hooks/useQuery";

import {
  useDimensionUnitAbbreviation,
  useWeightUnitAbbreviation,
} from "../../utils/Metrics";

import { useEffect, useState } from "react";

import { GET_BOX_TYPES } from "#queries";

import defaultBoxTypes from "#constants/default-box-types";

const NUMBER_OF_BOXES_TO_FETCH = 100;

const OrderBoxDimensions = ({ order, setSelectedOrder }) => {
  const [boxTypes, setBoxTypes] = useState([]);
  const boxTypesQuery = useQuery(GET_BOX_TYPES);

  const preferredDimensionUnit = useDimensionUnitAbbreviation();
  const preferredWeightUnit = useWeightUnitAbbreviation();

  const estimatedTotalWeight = order?.orderLineItems?.reduce(
    (acc, orderProduct) => {
      const itemWeight =
        orderProduct?.attributes?.weight ||
        orderProduct?.marketplaceAttributes?.attributes?.weight;
      if (!itemWeight || acc === null) {
        return null;
      }
      return acc + (orderProduct?.formFactors?.[0].quantity || 1) * itemWeight;
    },
    0,
  );

  if (!order.estimatedBoxes) {
    setSelectedOrder({
      ...order,
      estimatedBoxes: [
        {
          height: boxTypes?.[0]?.height,
          totalWeight: null,
          length: boxTypes?.[0]?.length,
          width: boxTypes?.[0]?.width,
          canBeUpdated: boxTypes?.[0]?.name === "Add Custom",
          boxCount: 1,
        },
      ],
    });
  }

  const updateSelectedOrder = (index, dimension) => {
    setSelectedOrder({
      ...order,
      estimatedBoxes: order?.estimatedBoxes?.map((box, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...box,
            ...dimension,
          };
        }
        return box;
      }),
    });
  };

  useEffect(() => {
    boxTypesQuery.fetchData({
      perPage: NUMBER_OF_BOXES_TO_FETCH,
      filters: {},
      paginated: false,
      pageNumber: 1,
    });
  }, []);

  useEffect(() => {
    let customBoxTypes = [];
    if (
      boxTypesQuery.data &&
      boxTypesQuery.data.boxTypes?.entities?.length > 0
    ) {
      // FILTER ONLY THOSE THAT HAVE LENGTH WIDTH AND HEIGHT
      const filteredBoxTypes = boxTypesQuery.data.boxTypes.entities.filter(
        (item) => item.height && item.width && item.length,
      );

      customBoxTypes = customBoxTypes.concat(
        filteredBoxTypes.map((item) => ({
          name: item.name,
          height: item.height,
          width: item.width,
          length: item.length,
        })),
      );
    }

    if (customBoxTypes.length === 0) {
      customBoxTypes = customBoxTypes.concat(defaultBoxTypes);
    }
    // push the custom box
    customBoxTypes.push({
      name: "Add Custom",
      height: "?",
      width: "?",
      length: "?",
    });

    updateSelectedOrder(0, {
      height: customBoxTypes?.[0]?.height,
      width: customBoxTypes?.[0]?.width,
      length: customBoxTypes?.[0]?.length,
      boxId: `${customBoxTypes?.[0].name} - ${customBoxTypes?.[0].length}x${customBoxTypes?.[0].width}x${customBoxTypes?.[0].height} (${preferredDimensionUnit})`,
    });

    setBoxTypes(customBoxTypes);
  }, [boxTypesQuery.loading, boxTypesQuery.error, boxTypesQuery.data]);

  return (
    <div className="flex-col overflow-scroll border-solid px-5">
      <h1 className="text-xl font-bold mb-4">Product Details</h1>
      <div className="bg-yellow-50 border-yellow-300 p-4 flex items-center space-x-3 border-2 rounded-lg ">
        <span className="text-yellow-400 text-2xl">&#x26A0;</span>{" "}
        <span className="text-gray-500">
          We require to have weight and dimension information of the boxes to
          show the carrier rates for the order. If you are not sure of the
          weight and dimension of the boxes enter an approx figure, you can
          change these later in the packing stage.
        </span>
      </div>
      {estimatedTotalWeight && (
        <div className="pt-8">
          Estimated Total Shipment Weight = {estimatedTotalWeight}{" "}
          {preferredWeightUnit}
        </div>
      )}
      <h2 className="text-xl font-bold mt-5">Packing Info</h2>
      {order?.estimatedBoxes?.length > 0 &&
        order?.estimatedBoxes?.map((estimatedBox, index) => {
          return (
            <div className="pt-5 [first-child]:pt-0 border-2 rounded-lg p-3 mt-5 relative">
              <div className="flex gap-4 justify-start">
                <div>
                  <label className="text-sm">Box Type</label>
                  <select
                    className={`border-1 block p-2 w-full pr-10 focus:outline-none text-lg bg-transparent font-montserrat placeholder-gray-400 border-2C7695}`}
                    onChange={(e) => {
                      const selectedBox = boxTypes.find(
                        (boxType) =>
                          `${boxType.name} - ${boxType.length}x${boxType.width}x${boxType.height} (${preferredDimensionUnit})` ===
                          e.currentTarget.value,
                      );
                      updateSelectedOrder(index, {
                        width: selectedBox.width,
                        height: selectedBox.height,
                        length: selectedBox.length,
                        canBeUpdated: selectedBox.name === "Add Custom",
                        boxId: `${selectedBox.name} - ${selectedBox.length}x${selectedBox.width}x${selectedBox.height} (${preferredDimensionUnit})`,
                      });
                    }}
                    value={estimatedBox.boxId}>
                    {boxTypes.map((boxType) => (
                      <option
                        value={`${boxType.name} - ${boxType.length}x${boxType.width}x${boxType.height} (${preferredDimensionUnit})`}>{`${boxType.name} - ${boxType.length}x${boxType.width}x${boxType.height} (${preferredDimensionUnit})`}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="text-sm">Box Count</label>
                  <input
                    type="number"
                    min={0}
                    className={`border-1 block p-2 w-full focus:outline-none text-lg bg-transparent font-montserrat placeholder-gray-400 border-2C7695}`}
                    value={estimatedBox?.boxCount}
                    onChange={(e) =>
                      updateSelectedOrder(index, {
                        boxCount: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex gap-4 justify-between mt-4 w-[70%]	">
                <div className="flex-grow-1">
                  <label className="text-sm">
                    Length ({preferredDimensionUnit})
                  </label>
                  <input
                    type="number"
                    min={0}
                    disabled={!estimatedBox.canBeUpdated}
                    className={`border-1 block p-2 w-full focus:outline-none disabled:bg-gray-200 text-lg bg-transparent font-montserrat placeholder-gray-400 border-2C7695}`}
                    value={estimatedBox?.length}
                    onChange={(e) =>
                      updateSelectedOrder(index, {
                        length: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="flex-grow-1">
                  <label className="text-sm">
                    Width ({preferredDimensionUnit})
                  </label>
                  <input
                    type="number"
                    min={0}
                    disabled={!estimatedBox.canBeUpdated}
                    className={`border-1 block p-2 w-full focus:outline-none disabled:bg-gray-200 text-lg bg-transparent font-montserrat placeholder-gray-400 border-2C7695}`}
                    value={estimatedBox?.width}
                    onChange={(e) =>
                      updateSelectedOrder(index, {
                        width: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="flex-grow-1">
                  <label className="text-sm">
                    Height ({preferredDimensionUnit})
                  </label>
                  <input
                    type="number"
                    min={0}
                    disabled={!estimatedBox.canBeUpdated}
                    className={`border-1 block p-2 w-full focus:outline-none text-lg disabled:bg-gray-200 bg-transparent font-montserrat placeholder-gray-400 border-2C7695}`}
                    value={estimatedBox?.height}
                    onChange={(e) =>
                      updateSelectedOrder(index, {
                        height: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4 justify-start mt-4">
                <div className="flex-grow-1">
                  <label className="text-sm">
                    Total Weight ({preferredWeightUnit})
                  </label>
                  <input
                    type="number"
                    min={0}
                    className={`border-1 block p-2 w-full focus:outline-none text-lg bg-transparent font-montserrat placeholder-gray-400 border-2C7695}`}
                    value={estimatedBox?.totalWeight}
                    onChange={(e) =>
                      updateSelectedOrder(index, {
                        totalWeight: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
                {estimatedBox?.totalWeight && estimatedBox?.boxCount && (
                  <div className="flex items-center">
                    Estimated single box weight ={" "}
                    <b>
                      {Math.round(
                        (estimatedBox?.totalWeight / estimatedBox?.boxCount) *
                          100,
                      ) / 100}{" "}
                      {preferredWeightUnit}
                    </b>
                  </div>
                )}
              </div>
              <div className="absolute top-0 right-8">
                <div
                  className="mt-5 cursor-pointer text-red-500 inline-block"
                  onClick={() => {
                    order.estimatedBoxes.splice(index, 1);
                    setSelectedOrder({
                      ...order,
                      estimatedBoxes: order.estimatedBoxes,
                    });
                  }}>
                  Delete Box
                </div>
              </div>
            </div>
          );
        })}
      <div className="block">
        <div
          className="mt-5 cursor-pointer text-2C7695 inline-block"
          onClick={() =>
            setSelectedOrder({
              ...order,
              estimatedBoxes: [
                ...order.estimatedBoxes,
                {
                  height: boxTypes?.[0]?.height,
                  totalWeight: null,
                  length: boxTypes?.[0]?.length,
                  width: boxTypes?.[0]?.width,
                  boxCount: 1,
                  canBeUpdated: boxTypes?.[0]?.name === "Add Custom",
                },
              ],
            })
          }>
          + Add More
        </div>
      </div>
    </div>
  );
};

OrderBoxDimensions.propTypes = {
  order: PropTypes.shape({
    warehouse: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    orderLineItems: PropTypes.arrayOf(
      PropTypes.shape({
        marketplaceAttributes: {
          attributes: {
            length: PropTypes.number,
            width: PropTypes.number,
            height: PropTypes.number,
            weight: PropTypes.number,
          },
        },
        attributes: {
          length: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
          weight: PropTypes.number,
        },
      }),
    ),
  }),
  updateProductDimensions: PropTypes.func,
  setPrevStep: PropTypes.func,
};

export default OrderBoxDimensions;
