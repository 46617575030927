import { Tab } from "@headlessui/react";
import { UserAddIcon } from "@heroicons/react/outline";
// import PageButton from "#components/shared/PageButton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ tabs, extra }) => {
  return (
    <Tab.Group>
      <Tab.List className="flex items-center">
        <div className="flex-1 p-1 px-0 space-x-4 bg-white">
          {tabs &&
            Object.keys(tabs).map((tab) => (
              <Tab
                key={tab}
                className={({ selected }) =>
                  classNames(
                    "px-3 py-2.5 text-md leading-5 font-medium",
                    "focus:outline-none focus:ring-0 font-montserrat border-b border-b-2",
                    selected
                      ? "border-2C7695 text-2C7695"
                      : "border-transparent bg-transparent rounded-full text-7F878E"
                  )
                }
              >
                {tab}
              </Tab>
            ))}
        </div>
        <div className="items-center justify-end">{extra}</div>
      </Tab.List>
      <Tab.Panels className="mt-2">
        {tabs &&
          Object.values(tabs).map((tabInner, idx) => (
            <Tab.Panel
              key={idx}
              className="flex flex-col mt-4 bg-EBEBEB border textWhite-300"
            >
              {tabInner}
            </Tab.Panel>
          ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;
