import PageTitle from "#components/utils/PageTitle";
import { useState } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import QRCode from "react-qr-code";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import { renderStatus } from "../../utils/renderStatus";
import _ from "lodash";

const ListView = ({
  currentBatch,
  simulateItemScan,
  unpackItem,
  order,
  setSelectedIndex,
  isSetAddItemQuantityExceed,
  handleFindAndUnpackItem,
  tenant,
  reprintShippingLabel,
  warehouses,
}) => {
  const [openRedoModal, setOpenRedoModal] = useState(false);
  const headers = () => {
    const returnArr = [];
    returnArr.push("Warehouse");
    returnArr.push("Order");
    if (order && order.source === "FBA") {
      returnArr.push("ASIN", "FNSKU");
    }

    returnArr.push("SKU", "Location", "Product Name", "Qty", "Packed Qty");

    if (order && order.source === "FBA") {
      returnArr.push("Box Count");
    }

    if (
      currentBatch?.attributes?.multipleLineItemBatch &&
      currentBatch?.attributes?.pickingStrategy === "PICK_PLUS_SORT"
    ) {
      returnArr.push("Sub-Tote Code");
      returnArr.push("Sub-Tote #");
    }

    returnArr.push("Status");

    returnArr.push("Action", "Redo");

    return returnArr;
  };
  /**
   * @typedef DataArray
   * @property {string} sku
   * @property {number} quantity
   * @property {string} binLocation
   * @property {string} status
   * @property {string} order
   * @property {string} availableQuantity
   */
  /**
   * Consolidates an array of data objects, summing the quantities for each SKU.
   * @param {DataArray[]} data
   * @returns {DataArray[]} An array of consolidated data objects. Each object will represent a unique SKU from the input
   * 'data', with a summed 'quantity'.
   *
   * @example
   *  consolidateData([
   *    { sku: "123", quantity: 2 },
   *    { sku: "123", quantity: 3 },
   *    { sku: "456", quantity: 1 },
   *  ]);
   *  // Returns: [{ sku: "123", quantity: 5 }, { sku: "456", quantity: 1 }]
   */
  function consolidateData(data) {
    let consolidated = {};

    for (const obj of data) {
      const { sku, order, binLocation } = obj;
      const uniqueIdentifier = `${sku}-${order}-${binLocation}`;
      if (consolidated[uniqueIdentifier]) {
        consolidated[uniqueIdentifier].availableQuantity +=
          obj.availableQuantity;
        if (obj.status === "CONFIRMED") {
          consolidated[uniqueIdentifier].quantity += obj.quantity;
        }
      } else {
        consolidated[uniqueIdentifier] = {
          ...obj,
          status: obj.status || "UNPROCESSED",
        };
        if (obj.status !== "CONFIRMED") {
          consolidated[uniqueIdentifier] = {
            ...obj,
            quantity: 0,
          };
        }
      }
    }
    return Object.values(consolidated);
  }

  /**
   * Consolidates an array of data objects, summing the quantities for each SKU where the status is "CONFIRMED".
   * @param {DataArray[]} data
   * @returns {DataArray[]} An array of consolidated data objects. Each object will represent a unique SKU from the input
   * 'data', with a summed 'quantity' for SKUs with "CONFIRMED" status.
   *
   * @example
   *  consolidateDataUnpack([
   *    { sku: "123", quantity: 2, status: "CONFIRMED" },
   *    { sku: "123", quantity: 3, status: "CONFIRMED" },
   *    { sku: "456", quantity: 1, status: "UNPROCESSED" },
   *  ]);
   *  // Returns: [{ sku: "123", quantity: 5, status: "CONFIRMED" }]
   */
  function consolidateDataUnpack(data) {
    let consolidated = {};

    for (let obj of data) {
      let sku = obj.sku;
      let boxName = obj.boxName;
      const uniqueKey = `${sku}-${boxName}`;
      if (consolidated[uniqueKey] && obj.status === "CONFIRMED") {
        consolidated[uniqueKey].quantity += obj.quantity;
      } else if (obj.status === "CONFIRMED") {
        consolidated[uniqueKey] = {
          ...obj,
        };
      }
    }
    return Object.values(consolidated);
  }

  const humanizeOrderId = (orderId) => {
    if (currentBatch?.attributes?.orderIds) {
      const values = Object.values(currentBatch.attributes.orderIds);
      const idx = values.indexOf(orderId);
      if (idx > -1 && currentBatch?.attributes?.humanReadableOrderIds) {
        const humanizeOrderIds = Object.values(
          currentBatch.attributes.humanReadableOrderIds,
        );
        return humanizeOrderIds[idx];
      }
    }

    return `${orderId}`;
  };

  const getSubTote = (orderId) => {
    if (currentBatch?.attributes?.subTotes) {
      return currentBatch.attributes.subTotes[orderId];
    }
    return null;
  };

  const getSubToteIdx = (orderId) => {
    if (currentBatch?.attributes?.subTotePositions) {
      return currentBatch.attributes.subTotePositions[orderId];
    }
    if (currentBatch?.attributes?.subTotes) {
      return (
        Object.values(currentBatch.attributes.subTotes).findIndex(
          (i) => i === currentBatch.attributes.subTotes[orderId],
        ) + 1
      );
    }
    return null;
  };

  return (
    <div className="flex-1">
      <PageTitle>Total ({currentBatch?.workingList?.length}) </PageTitle>
      <div className="bg-EBEBEB mt-10 rounded-md">
        <table className="min-w-full divide-y divide-gray-200 -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {headers().map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {consolidateData(currentBatch.workingList)?.map((item, index) => {
              return (
                <tr
                  key={item.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {warehouses?.find((i) => i.id === item.warehouse)?.name ||
                      `-`}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider font-bold">
                    {humanizeOrderId(item.order)}
                  </td>
                  {order?.source === "FBA" && (
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                      {item.asin}
                    </td>
                  )}
                  {order?.source === "FBA" && (
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                      {item.fnSku}
                    </td>
                  )}
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.sku}
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.binLocation}
                  </td>
                  <td
                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider whitespace-nowrap"
                    title={item.productName}>
                    <div className="w-96 overflow-hidden">
                      {item.productName}
                    </div>
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {item.availableQuantity}
                  </td>
                  <td className="pl-4 px-1 py-1 text-center font-medium text-5F666B tracking-wider">
                    {item.quantity}
                  </td>
                  {order?.source === "FBA" && (
                    <td className="pl-4 px-1 py-1 text-center font-medium text-5F666B tracking-wider">
                      {consolidateDataUnpack(currentBatch.workingList)
                        ?.length || 0}
                    </td>
                  )}
                  {currentBatch.workingList.length > 0 &&
                    currentBatch?.attributes?.multipleLineItemBatch && (
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider font-bold">
                        {getSubTote(item.order)}
                      </td>
                    )}
                  {currentBatch.workingList.length > 0 &&
                    currentBatch?.attributes?.multipleLineItemBatch && (
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider font-bold">
                        {getSubToteIdx(item.order)}
                      </td>
                    )}

                  <td className="pl-4 px-1 py-1 font-medium text-5F666B tracking-wider">
                    {renderStatus(
                      item.quantity === item.availableQuantity
                        ? "CONFIRMED"
                        : item.quantity > 0
                        ? "PARTIAL"
                        : "UNPROCESSED",
                    )}
                  </td>

                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    <div
                      className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white"
                      onClick={() => {
                        simulateItemScan(item);
                        setSelectedIndex("Detail View");
                      }}>
                      Scan
                    </div>
                  </td>
                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    <div className="flex space-x-2">
                      {tenant?.settings?.activities?.packing?.allowRedo !==
                        false && (
                        <div
                          className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white  whitespace-nowrap"
                          onClick={() => setOpenRedoModal(true)}>
                          Re-do
                        </div>
                      )}
                      {tenant?.settings?.activities?.packing?.allowReprint && (
                        <div
                          className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white  whitespace-nowrap"
                          onClick={() => reprintShippingLabel(item.order)}>
                          Re-Print
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {openRedoModal && (
        <Modal
          negativeAction={() => setOpenRedoModal(false)}
          positiveAction={() => unpackItem()}
          positiveText={"Re-do"}
          title={`Packed Items (${
            currentBatch.workingList.filter((i) => i.boxName).length
          })`}>
          <div className="h-96 overflow-auto">
            <table className="min-w-full divide-y divide-gray-200 mt-2">
              <thead className="p-4 rounded-full">
                <tr className="text-white font-montserratborder-left bg-primaryAccent">
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                    SKU
                  </th>
                  {order && order.source === "FBA" && (
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                      ASIN
                    </th>
                  )}
                  {order && order.source === "FBA" && (
                    <th
                      scope="col"
                      className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                      FNSKU
                    </th>
                  )}
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                    Box Name
                  </th>
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                    Packed Qty
                  </th>
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-center tracking-wider font-medium">
                    Redo
                  </th>
                </tr>
              </thead>
              <tbody>
                {consolidateDataUnpack(currentBatch.workingList).map(
                  (item, index) => (
                    <tr
                      key={item.id}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}>
                      <td
                        className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                          index % 2 === 0
                            ? "border-F4C261"
                            : "border-primaryAccent"
                        }`}>
                        {item.sku}
                      </td>
                      {order && order.source === "FBA" && (
                        <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                          {buildMarketplaceHyperlink(
                            item.asin,
                            "ASIN",
                            item.source,
                          )}
                        </td>
                      )}
                      {order && order.source === "FBA" && (
                        <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                          {item.fnSku}
                        </td>
                      )}
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {item.productName}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        {item.boxName}
                      </td>

                      <td className="pl-4 px-1 py-1 text-center font-medium text-5F666B tracking-wider">
                        {item.quantity}
                      </td>
                      <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                        <TextField
                          type="number"
                          id="unPackQuantity"
                          placeholder="Unpack Qty"
                          max={item.quantity}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              "",
                            );
                          }}
                          onChange={(e) => {
                            if (e.target.value <= item.quantity) {
                              handleFindAndUnpackItem(
                                currentBatch.workingList?.filter(
                                  (i) => i.boxName === item.boxName,
                                ),
                                item.sku,
                                e.target.value,
                                item.boxName,
                              );
                            } else {
                              e.target.value = 0;
                              isSetAddItemQuantityExceed(true);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ListView;
