import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import SwitchComponent from "#components/utils/Switch"
import _ from "lodash";

const DashboardForm = ({
  onClose,
  title,
  onChange,
  selectedDashboard,
  onSubmit,
  global,
  setGlobal
}) => (
  <Modal title={title} negativeAction={onClose} positiveAction={onSubmit}>
    <div className="space-y-4">
      <div>
        <span className="font-medium font-montserrat text-454A4F text-lg pb-4">
          Dashboard Details
        </span>
      </div>
      <div>
        <TextField
          type="text"
          id="name"
          label="Name"
          placeholder="Name"
          onChange={onChange}
          value={selectedDashboard.name}
        />
      </div>
      <div>
        Global Dashboard
        <SwitchComponent
          enabled={global}
          setEnabled={setGlobal}
        />
      </div>
    </div>
  </Modal>
);

export default DashboardForm;
