import React, { useState } from "react";
import { Table } from "antd";
import "antd/lib/table/style/index.css";
import "antd/lib/select/style/index.css";
import "antd/lib/pagination/style/index.css";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useCubeQuery } from "@cubejs-client/react";
import TextField from "#components/utils/TextField";

const DetailedStat = ({ title, onClose, stat, onChangeSearchFilter }) => {
  const { resultSet, isLoading } = useCubeQuery(stat.query);
  return (
    <div>
      <div className="bg-white shadow-xl flex justify-start p-8">
        <button onClick={onClose}>
          <ChevronLeftIcon className="h-8 w-8" />
        </button>
        <span className="text-xl mt-1 ml-3">{title || "Detailed Report"}</span>
      </div>
      <div className="p-4 bg-white overflow-x-scroll">
        <div className="w-1/3 mb-4">
          <TextField
            type="text"
            label="Search SKUs"
            placeholder="Search SKUs"
            onChange={(e) => onChangeSearchFilter(e.target.value)}
          />
        </div>
        <Table
          columns={stat.tableColumns}
          dataSource={resultSet?.loadResponse?.results[0]?.data ? resultSet?.loadResponse?.results[0]?.data?.map(d => {
            if (stat.statKey.includes("INVENTORY_AGE")) {
              if (!d["Productvariants.inventory_age"] || d["Productvariants.inventory_age"] < 0) {
                d["Productvariants.inventory_age"] = d["Productvariants.since_received"]
              }
            }
            return d
          }).filter((d, i) => {
            const result = resultSet?.loadResponse?.results[0]?.data?.find(d2 => d2["Skubinmappings.binlocation"] === d["Skubinmappings.binlocation"] && d2["Productvariants.sku"] === d["Productvariants.sku"] && d2["Skubinmappings.created_at"] > d["Skubinmappings.created_at"])
            return !!!result
          }) : []}
          loading={isLoading}
          pagination={{ showSizeChanger: false }}
        />
      </div>
    </div>
  );
};

export default DetailedStat;
