const PageHeader = ({ customer, customers, selectedBundle }) => {
  if (
    customer &&
    customers &&
    customers.findIndex((item) => item.id === customer) !== -1
  ) {
    return (
      <div className="text-2xl bg-black absolute top-0 mt-16 left-0 w-full text-center text-white">
        {customers.find((item) => item.id === customer).name}
        {selectedBundle && ` - ${selectedBundle.uniqueIdentifier}`}
      </div>
    );
  }

  return null;
};

export default PageHeader;
