import React from "react";
import PropTypes from "prop-types";

/**
 * @param {string} title
 * @param {string} stat
 * @param {boolean} loading
 * @returns {JSX.Element}
 * @description
 * This component is used to display the stats of the user
 * on the dashboard.
 * @example
 * <StatCard title="Total Users" stat="100" loading={false} />
 */
const StatCard = ({ title, stat, loading = false }) => {
  return (
    <div className="flex text-center max-w-1/2 flex-col bg-white items-center justify-center gap-2 p-4 my-2 rounded-lg shadow text-gray-500">
      <div className="text-xl font-medium">{title}</div>
      <div className="text-2xl font-semibold text-gray-900">
        {loading ? "Loading..." : stat}
      </div>
    </div>
  );
};

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  stat: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default StatCard;
