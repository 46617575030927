import { useState, useEffect, useContext } from "react";
import {
  AdjustmentsIcon,
  ViewGridIcon,
  ViewListIcon,
  PlusIcon,
  TagIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import GridViewProducts from "./GridViewProducts";
import ListViewProducts from "./ListViewProducts";
import Popover from "#components/utils/Popover";
import SlideOverPanel from "../../common/SlideOverPanel";
import CatalogManageTags from "../CatalogManageTags";
import { useQuery } from "../../../hooks/useQuery";
import { GET_SKU } from "#queries";
import { SAVE_PRODUCT, DELETE_PRODUCT } from "#mutations";
import CatalogEditProduct from "./CatalogEditProduct";
import { AppStateContext } from "#contexts/appState";
import CatalogEnhancedSearch from "./CatalogEnhancedSearch";

const ALERT_TIMEOUT_IN_MS = 5000;

function CatalogMainContent({
  products,
  total,
  filters,
  submitFilters,
  onChangeFilter,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  getCatalog,
  productCategories,
  selectAllDisabled,
}) {
  const appState = useContext(AppStateContext);
  const [selectedProductForTags, setSelectedProductForTags] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);

  const [showListView, setShowListView] = useState(true);
  const [showAddTags, setShowAddTags] = useState(false);

  const getSkuQuery = useQuery(GET_SKU);
  const saveProductQuery = useQuery(SAVE_PRODUCT);
  const deleteProductQuery = useQuery(DELETE_PRODUCT);

  const deleteProduct = (id) => {
    // ASK FOR CONFIRMATION
    appState.showConfirmation(
      "Delete Category",
      "Are you sure you want to remove this product?",
      () => confirmDeleteProduct(id),
      appState.hideConfirmation,
    );
  };

  const confirmDeleteProduct = async (id) => {
    const response = await deleteProductQuery.fetchData({ id });
    if (response.error) {
      appState.setDismissableAlert(
        response.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      appState.hideConfirmation();
    }

    if (response.data) {
      appState.setDismissableAlert(
        response.data.deleteProduct.message,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      appState.hideConfirmation();
      submitFilters();
    }
  };

  const fetchProductForTags = async (product) => {
    const response = await getSkuQuery.fetchData({ id: product.id });

    if (response?.data?.specificInventory) {
      setSelectedProductForTags(response.data.specificInventory);
      setShowAddTags(true);
    }
  };

  const fetchProductForEdit = async (product) => {
    const response = await getSkuQuery.fetchData({ id: product.id });

    if (response?.data?.specificInventory) {
      setEditingProduct(response.data.specificInventory);
    }
  };

  const actionsOnPopover = [
    {
      name: "Add Catalog",
      value: "add_catalog",
      icon: <PlusIcon className="h-6 w-6" />,
    },
    {
      name: "Add Tags",
      value: "add_tags",
      icon: <TagIcon className="h-6 w-6" />,
      disabled: !selectedProducts?.length,
      disabledMessage: "Please select a product to add tags",
      onClick: () => setShowAddTags(true),
    },
  ];

  const actionsOnProduct = (product) => {
    return [
      {
        name: "Edit Product Details",
        value: "edit",
        icon: <PencilIcon className="h-6 w-6" />,
        onClick: () => fetchProductForEdit(product),
      },
      {
        name: "Add Tags",
        value: "tags",
        icon: <TagIcon className="h-6 w-6" />,
        onClick: () => fetchProductForTags(product),
      },
      {
        name: "Remove",
        value: "delete",
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => deleteProduct(product.id),
      },
    ];
  };

  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  const saveProduct = async () => {
    const response = await saveProductQuery.fetchData({ ...editingProduct });

    if (response?.data?.saveProduct) {
      setEditingProduct(null);
      appState.setDismissableAlert(
        response.data.saveProduct.message,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      submitFilters();
    }

    if (response?.error) {
      appState.setDismissableAlert(
        response.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return (
    <>
      {showAddTags && (
        <SlideOverPanel
          open={showAddTags}
          setOpen={setShowAddTags}
          title="Add Tags"
          containerStyle={"max-w-3xl"}
          subTitle="Manage or add tags to products">
          <CatalogManageTags product={selectedProductForTags} />
        </SlideOverPanel>
      )}
      {editingProduct && (
        <SlideOverPanel
          open={true}
          setOpen={setEditingProduct}
          title="Edit Product Details"
          containerStyle={"max-w-7xl"}
          subTitle="Manage product attributes, identifiers, images etc">
          <CatalogEditProduct
            product={editingProduct}
            setProduct={setEditingProduct}
            productCategories={productCategories}
            saveProduct={saveProduct}
          />
        </SlideOverPanel>
      )}
      <div className="px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex space-x-4 item-center justify-center mt-2">
          <CatalogEnhancedSearch
            products={products}
            selectProduct={selectProduct}
            selectedProducts={selectedProducts}
            allRowsSelected={allRowsSelected}
            selectAllRows={selectAllRows}
            getCatalog={getCatalog}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={shiftKeyPressed}
          />

          <button className="hs-catalog-topbar-action">
            <AdjustmentsIcon className="h-6 w-6" />
          </button>
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-left ${
                showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(true)}>
              <ViewListIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-right ${
                !showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(false)}>
              <ViewGridIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </span>
          <Popover
            title={"Actions"}
            showChevron={true}
            panelClassName={
              "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
            }
            showOverlay={true}
            customClassName={"py-4"}>
            {actionsOnPopover.map((action, idx) => {
              return (
                <div
                  className={`p-3 rounded-lg font-medium whitespace-nowrap cursor-pointer ${
                    action.disabled
                      ? "cursor-not-allowed text-gray-200"
                      : "text-unselectedTextGray hover:text-primaryAccent hover:bg-hoverHighlight"
                  }`}
                  onClick={
                    action.onClick && !action.disabled
                      ? action.onClick
                      : () =>
                          action.disabledMessage
                            ? alert(action.disabledMessage)
                            : console.log(action.value, action.name)
                  }
                  key={idx}>
                  {action.name}
                </div>
              );
            })}
          </Popover>
        </div>

        <div className="sm:flex sm:items-center mt-2">
          <div className="sm:flex-auto">
            <h1 className="text-lg leading-6 text-[#8A8B8E]">
              Products: {total}
            </h1>
          </div>
        </div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-visible sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 overflow-y-visible">
              {showListView ? (
                <ListViewProducts
                  products={products}
                  selectProduct={selectProduct}
                  selectedProducts={selectedProducts}
                  allRowsSelected={allRowsSelected}
                  selectAllRows={selectAllRows}
                  getCatalog={getCatalog}
                  actionsOnProduct={actionsOnProduct}
                  productCategories={productCategories}
                  shiftKeyPressed={shiftKeyPressed}
                  selectAllDisabled={selectAllDisabled}
                />
              ) : (
                <GridViewProducts
                  products={products}
                  selectProduct={selectProduct}
                  selectedProducts={selectedProducts}
                  allRowsSelected={allRowsSelected}
                  selectAllRows={selectAllRows}
                  getCatalog={getCatalog}
                  actionsOnProduct={actionsOnProduct}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CatalogMainContent;
