import React, { useState, useEffect } from "react";
import TextField from "../utils/TextField";
import RoundedDropdown from "../utils/RoundedDropdown";
import Modal from "#components/utils/Modal";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/solid";
import _ from "lodash";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";

const ReportForm = ({
  onClose,
  title,
  onSubmit,
  selectedReport,
  onChange,
  onChangeDropdown,
  warehouses,
  customers,
  reportsMetadata,
  onChangeDropdownMulti,
}) => {
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(5);
  const [selectedDateRange, setSelectedDateRange] = useState("Custom Date");
  const prev = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      onClose();
    }
  };
  const next = () => {
    if (step < totalSteps) {
      setStep(step + 1);
    } else {
      onSubmit();
    }
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const dateRanges = [
    {
      label: "Today",
      startDate: formatDate(new Date()),
      endDate: formatDate(new Date()),
    },
    {
      label: "Yesterday",
      startDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date);
      })(),
      endDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date);
      })(),
    },
    {
      label: "This week",
      startDate: (() => {
        const date = new Date();
        const day = date.getDay();
        const diff = day === 0 ? 6 : day - 1;
        date.setDate(date.getDate() - diff);
        return formatDate(date);
      })(),
      endDate: formatDate(new Date()),
    },
    {
      label: "This month",
      startDate: (() => {
        const date = new Date();
        date.setDate(1);
        return formatDate(date);
      })(),
      endDate: formatDate(new Date()),
    },
    {
      label: "Last 7 days",
      startDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() - 6);
        return formatDate(date);
      })(),
      endDate: formatDate(new Date()),
    },
    {
      label: "Last 30 days",
      startDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() - 29);
        return formatDate(date);
      })(),
      endDate: formatDate(new Date()),
    },
    {
      label: "Last week",
      startDate: (() => {
        const date = new Date();
        const day = date.getDay();
        const diff = (day === 0 ? 6 : day - 1) + 7;
        date.setDate(date.getDate() - diff);
        return formatDate(date);
      })(),
      endDate: (() => {
        const currentDate = new Date();
        const day = currentDate.getDay();
        const diff = (day === 0 ? 6 : day - 1) + 1;
        currentDate.setDate(currentDate.getDate() - diff);
        currentDate.setHours(23, 59, 59, 999);
        return formatDate(currentDate);
      })(),
    },
    {
      label: "Last month",
      startDate: (() => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        date.setDate(1);
        return formatDate(date);
      })(),
      endDate: (() => {
        const date = new Date();
        date.setDate(0);
        return formatDate(date);
      })(),
    },
    { label: "Custom Date" },
  ];

  useEffect(() => {
    if (
      selectedReport &&
      selectedReport.module &&
      selectedReport.selectedVariables
    ) {
      const defaultVariable = reportsMetadata.modules
        .find((module) => module.name === selectedReport.module)
        .variables.find((variable) => variable.default);

      const containsDefault = selectedReport.selectedVariables.includes(
        defaultVariable.name,
      );
      if (!containsDefault) {
        onChangeDropdownMulti("selectedVariables", defaultVariable.name);
      }
    }
  }, [selectedReport]);

  useEffect(() => {
    if (selectedReport && selectedReport.module) {
      const reportModule = reportsMetadata.modules.find(
        (module) => module.name === selectedReport.module,
      );
      if (reportModule?.dateRangeRequired === false) {
        setTotalSteps(4);
      } else {
        setTotalSteps(5);
      }
    }
  }, [selectedReport]);

  return (
    <Modal
      title={title}
      negativeAction={prev}
      negativeText={step === 1 ? "Cancel" : "Previous"}
      positiveText={step === totalSteps ? "Submit" : "Next"}
      onClose={onClose}
      xIconClicked={onClose}
      positiveAction={next}>
      <div className="space-y-4">
        <ProgressBar progressPercentage={(step / totalSteps) * 100} />
        {step === 1 && (
          <div>
            <div className="font-medium font-montserrat text-darkText text-lg pb-4">
              Report Name
            </div>
            <TextField
              type="text"
              id="reportName"
              label="Report Name"
              placeholder="Report Name"
              onChange={onChange}
              value={selectedReport.reportName}
            />
          </div>
        )}
        {step === 2 && (
          <div>
            {/* Select warehouse */}
            <div className="font-medium font-montserrat text-darkText text-lg pb-4">
              Select Warehouse
            </div>
            <div className="flex flex-row space-x-4">
              <div className="w-96">
                <MultiSelectAutoComplete
                  placeholder={"Select Warehouses"}
                  options={warehouses}
                  labelKey="name"
                  valueKey="id"
                  name="warehouses"
                  values={selectedReport.warehouses}
                  setValues={(value) => {
                    onChange({
                      target: {
                        name: "warehouses",
                        value,
                      },
                    });
                  }}
                  emptyValuesAccountsForAll={true}
                />
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div>
            {/* Select customer */}
            <div className="font-medium font-montserrat text-darkText text-lg pb-4">
              Select Client
            </div>
            <div className="flex flex-row space-x-4">
              <div className="w-96">
                <MultiSelectAutoComplete
                  placeholder={"Select Clients"}
                  options={customers}
                  labelKey="name"
                  valueKey="id"
                  name="customers"
                  values={selectedReport.customers}
                  setValues={(value) => {
                    onChange({
                      target: {
                        name: "customers",
                        value,
                      },
                    });
                  }}
                  emptyValuesAccountsForAll={true}
                />
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <div>
            <div className="font-medium font-montserrat text-darkText text-lg pb-4">
              Select Report Type
            </div>
            <div className="flex flex-row space-x-4 pb-6">
              <div className="w-96">
                <RoundedDropdown
                  placeholder={"Select Report Type"}
                  list={reportsMetadata?.modules}
                  labelKey="name"
                  valueKey="name"
                  name="module"
                  selectedValue={selectedReport.module}
                  setSelected={(selectedModule) => {
                    onChangeDropdown("module", selectedModule);
                  }}
                />
              </div>
            </div>
            {selectedReport.module && (
              <>
                <div>
                  <div className="font-medium font-montserrat text-darkText text-lg pb-4">
                    Select Fields
                  </div>
                  <div className="flex">
                    <div className="w-80 mr-4">
                      <div className="text-center font-medium font-montserrat text-darkText text-lg pb-4 w-full">
                        Available Fields
                      </div>
                      <div className="w-full h-80 overflow-auto">
                        {reportsMetadata.modules
                          .find(
                            (module) => module.name === selectedReport.module,
                          )
                          .variables.filter(
                            (variable) =>
                              !selectedReport.selectedVariables.includes(
                                variable.name,
                              ) && !variable.default,
                          )
                          .map((item, index) => (
                            <div
                              className="w-[300px] flex cursor-pointer hover:bg-gray-200 border-b-2 border-gray-200"
                              key={`available-${index}`}
                              onClick={() => {
                                onChangeDropdownMulti(
                                  "selectedVariables",
                                  item.name,
                                );
                              }}>
                              <span className="font-medium font-montserrat text-darkText text-md pb-1">
                                {item.name}
                              </span>
                              <span className="ml-auto">
                                <ArrowRightIcon className="w-6 h-6 text-arrowColor font-normal" />
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="w-80">
                      <div className="text-center w-full font-medium font-montserrat text-darkText text-lg pb-4">
                        Selected Fields
                      </div>
                      <div className="w-full h-80 overflow-auto">
                        {reportsMetadata.modules
                          .find(
                            (module) => module.name === selectedReport.module,
                          )
                          .variables.sort((a, b) => (a.default ? -1 : 1))
                          .filter(
                            (variable) =>
                              selectedReport.selectedVariables.includes(
                                variable.name,
                              ) || variable.default,
                          )
                          .map((item, index) => (
                            <div
                              className={`w-[300px] flex ${
                                item.default
                                  ? "bg-gray-200"
                                  : "hover:bg-gray-200 cursor-pointer"
                              }   border-b-2 border-gray-200`}
                              key={`selected-${index}`}
                              onClick={() => {
                                if (!item.default) {
                                  onChangeDropdownMulti(
                                    "selectedVariables",
                                    item.name,
                                  );
                                }
                              }}>
                              <span>
                                {!item.default ? (
                                  <ArrowLeftIcon className="w-6 h-6 text-arrowColor font-normal" />
                                ) : null}
                              </span>
                              <span className="font-medium font-montserrat text-darkText text-md pb-1 ml-auto">
                                {item.name} {item.default ? " *" : ""}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex font-medium font-montserrat text-darkText text-sm pb-4 italic mt-2">
                  <span className="ml-auto">* mandatory selection</span>
                </div>
              </>
            )}
          </div>
        )}
        {step === 5 && (
          <div>
            {/* Select date range for the report (start and end date selectors) */}
            <div className="font-medium font-montserrat text-darkText text-lg pb-4">
              Select Date Range
            </div>
            <div className="flex space-x-6 mt-4">
              <div className="flex-1">
                <RoundedDropdown
                  placeholder={"Select Date Range"}
                  list={dateRanges}
                  labelKey="label"
                  valueKey="label"
                  name="selectedDateRange"
                  selectedValue={selectedDateRange}
                  setSelected={(chosenDateRange) => {
                    setSelectedDateRange(chosenDateRange);
                    const dateRange = dateRanges.find(
                      (range) => range.label === chosenDateRange,
                    );
                    if (chosenDateRange !== "Custom Date") {
                      onChange({
                        target: {
                          name: "startDate",
                          value: dateRange.startDate,
                        },
                      });
                      onChange({
                        target: {
                          name: "endDate",
                          value: dateRange.endDate,
                        },
                      });
                    } else {
                      onChange({
                        target: {
                          name: "startDate",
                          value: selectedReport.startDate,
                        },
                      });
                      onChange({
                        target: {
                          name: "endDate",
                          value: selectedReport.endDate,
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex space-x-6 mt-4">
              <div className="flex-1">
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-darkText text-lg pb-4">
                    Start Date
                  </span>
                </label>
                {selectedDateRange === "Custom Date" ? (
                  <TextField
                    type="date"
                    label="Select Date"
                    placeholder=" "
                    name="startDate"
                    onChange={(date) => onChange(date)}
                    value={selectedReport.startDate}
                  />
                ) : (
                  <span className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {selectedReport.startDate}
                  </span>
                )}
              </div>
              <div className="flex-1">
                <label className="block text-left mb-2">
                  <span className="font-medium font-montserrat text-darkText text-lg pb-4">
                    End Date
                  </span>
                </label>
                {selectedDateRange === "Custom Date" ? (
                  <TextField
                    type="date"
                    label="Select Date"
                    placeholder=" "
                    name="endDate"
                    onChange={(date) => onChange(date)}
                    value={selectedReport.endDate}
                  />
                ) : (
                  <span className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                    {selectedReport.endDate}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const ProgressBar = ({ progressPercentage }) => {
  return (
    <div className="h-2 w-full bg-gray-300 rounded-sm">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full rounded-sm ${"bg-[#214E73]"}`}></div>
    </div>
  );
};

export default ReportForm;
