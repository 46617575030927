import { useState } from "react";
import Modal from "#components/utils/Modal";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";

const EditNotificationForm = ({
  onClose,
  title,
  onChange,
  onChangeDropdown,
  fetchedNotification,
  onSubmit,
  rejectNotification,
  approveNotification,
  removeItem,
  xIconClicked,
}) => {
  return (
    <Modal
      title={title}
      negativeAction={rejectNotification}
      positiveAction={
        fetchedNotification.status === "PENDING" && approveNotification
      }
      onClose={onClose}
      neutralAction={onClose}
      negativeText={
        fetchedNotification.rejectText
          ? fetchedNotification.rejectText
          : "Reject"
      }
      positiveText={
        fetchedNotification.approveText
          ? fetchedNotification.approveText
          : "Approve"
      }
      negativeActionColor="bg-red-500 text-white"
      positiveActionColor="bg-green-500 text-white"
      xIconClicked={xIconClicked}>
      <div className="space-y-4">
        {[
          "SHIPMENT_PLAN_ERROR",
          "SHIPMENT_PLAN_SUCCESS",
          "SHIPMENT_PLAN_CREATION_START",
        ].includes(fetchedNotification.typeOfNotification) === false &&
          fetchedNotification.visibleDetails &&
          fetchedNotification.visibleDetails.map((visibleDetail, index) => (
            <div className={`grid grid-cols-4 flex items-start`} key={index}>
              <div className="font-montserrat font-medium text-lg mr-1">
                {visibleDetail.name}
              </div>
              <div className="font-montserrat font-medium text-lg col-span-3">
                {visibleDetail.value}
              </div>
            </div>
          ))}
        {[
          "SHIPMENT_PLAN_ERROR",
          "SHIPMENT_PLAN_SUCCESS",
          "SHIPMENT_PLAN_CREATION_START",
        ].includes(fetchedNotification.typeOfNotification) &&
          fetchedNotification.mainEntity && (
            <>
              <div className="text-lg font-montserrat p-4 font-bold pl-2">
                Message:{" "}
                {fetchedNotification.rawError?.message
                  ? fetchedNotification.rawError.message
                  : fetchedNotification.message}
              </div>
              <div className="max-h-96 overflow-auto">
                <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
                  <thead className="p-4 rounded-full bg-primaryAccent px-12">
                    <tr className="text-textWhite font-montserrat border-left px-12">
                      <th
                        scope="col"
                        className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                        ASIN
                      </th>
                      <th
                        scope="col"
                        className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                        SKU
                      </th>
                      <th
                        scope="col"
                        className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                        Error
                      </th>
                      <th
                        scope="col"
                        className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {fetchedNotification.mainEntity.shipmentPlanProducts.map(
                      (visibleDetail, index) => (
                        <tr className="bg-white" key={index}>
                          <td
                            className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr"
                            key={index}>
                            {buildMarketplaceHyperlink(
                              visibleDetail.item?.asin,
                              "ASIN",
                              visibleDetail.item?.source,
                            ) || "-"}
                          </td>
                          <td
                            className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr"
                            key={index}>
                            {visibleDetail.item?.sellerSku || "-"}
                          </td>
                          <td
                            className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr"
                            key={index}>
                            {visibleDetail.quantity}
                          </td>
                          <td
                            className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr"
                            key={index}>
                            {visibleDetail.error ? (
                              <span className="text-red-400">
                                {visibleDetail.error}
                              </span>
                            ) : (
                              "No Error"
                            )}
                          </td>
                          {/* {visibleDetail.error && ( */}
                          {["SHIPMENT_PLAN_ERROR"].includes(
                            fetchedNotification.typeOfNotification,
                          ) && (
                            <td
                              className="px-2 pl-4  py-1 text-left font-medium  text-red-400  tracking-wider rounded-br rounded-tr cursor-pointer"
                              key={index}
                              onClick={() => removeItem(index)}>
                              Remove
                            </td>
                          )}

                          {/* )} */}
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        {fetchedNotification.editableFields &&
          fetchedNotification.editableFields.map((editableField, index) => (
            <div>
              <TextField
                type="number"
                id={editableField.name.toLowerCase()}
                label={`Edit ${editableField.name.toLowerCase()}`}
                placeholder=" "
                onChange={onChange}
                name={editableField.name.toLowerCase()}
                value={
                  fetchedNotification.mainEntity[
                    editableField.name.toLowerCase()
                  ]
                }
                disabled={fetchedNotification.status !== "PENDING"}
              />
            </div>
          ))}
        {["NEW_USER_JOINED"].includes(fetchedNotification.typeOfNotification) &&
          fetchedNotification.message && (
            <div className="text-lg font-montserrat p-4 font-bold pl-2">
              Message: {fetchedNotification.message}
            </div>
          )}

        <div>
          <TextField
            type="text"
            id="remarks"
            label="Remarks"
            placeholder=" "
            onChange={onChange}
            name="remarks"
            value={fetchedNotification.remarks}
            disabled={fetchedNotification.status !== "PENDING"}
          />
        </div>
      </div>
    </Modal>
  );
};

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};

export default EditNotificationForm;
