import withCatalogsLogic from "../components/HOC/withCatalogsLogic";
import CatalogTabs from "../components/catalogs/CatalogTabs";
import ExpandedCatalog from "../components/catalogs/ExpandedCatalog";

const CatalogV2 = ({
  products,
  total,
  filters,
  onChangeFilter,
  submitFilters,
  customers,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  productTags,
  getCatalog,
  fetchedCatalog,
  setFetchedCatalog,
  pageSavedFilters,
  setFilters,
  saveBookmark,
  applyBookmarkFilters,
  filterQueryOperator,
  setFilterQueryOperator,
  productCategories,
  setProductCategories,
  clearFilters,
}) => {
  return (
    <>
      <div className="flex-col flex-1 min-h-screen">
        <div className="hs-catalog-wrapper">
          <div className="hs-pageTitle">
            Catalog Management
            <div className="hs-pageSubTitle">
              Manage your catalog and products
            </div>
          </div>
          <div className="py-4">
            <CatalogTabs
              products={products}
              total={total}
              filters={filters}
              onChangeFilter={onChangeFilter}
              submitFilters={submitFilters}
              customers={customers}
              selectProduct={selectProduct}
              selectedProducts={selectedProducts}
              selectAllRows={selectAllRows}
              allRowsSelected={allRowsSelected}
              productTags={productTags}
              getCatalog={getCatalog}
              fetchedCatalog={fetchedCatalog}
              setFetchedCatalog={setFetchedCatalog}
              pageSavedFilters={pageSavedFilters}
              setFilters={setFilters}
              saveBookmark={saveBookmark}
              applyBookmarkFilters={applyBookmarkFilters}
              setFilterQueryOperator={setFilterQueryOperator}
              filterQueryOperator={filterQueryOperator}
              productCategories={productCategories}
              setProductCategories={setProductCategories}
              clearFilters={clearFilters}
            />
          </div>
        </div>
      </div>
      {fetchedCatalog && (
        <ExpandedCatalog
          fetchedCatalog={fetchedCatalog}
          setFetchedCatalog={setFetchedCatalog}
          customers={customers}
        />
      )}
    </>
  );
};

// @ts-ignore
export default withCatalogsLogic(CatalogV2);
