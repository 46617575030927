// icons import
import { TrashIcon } from "@heroicons/react/solid";
import { PencilIcon } from "@heroicons/react/outline";
// components import
import Checkbox from "#components/utils/Checkbox";
import TableFieldButton from "#components/utils/TableFieldButton";
// other imports
import PropTypes from "prop-types";

/**
 * TagsList component for displaying a list of tags.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.tags - An array of tag objects to be displayed.
 * @param {boolean} [props.editable=false] - Specifies if the tags are editable.
 * @param {boolean} [props.deletable=false] - Specifies if the tags are deletable.
 * @param {boolean} [props.selectable=false] - Specifies if the tags are selectable.
 * @param {Function} props.setTagFormData - Function to set the tag form data.
 * @param {Function} props.setTagBeingEdited - Function to set that the tag is being edited.
 * @param {Function} props.deleteTag - Function to delete a tag.
 * @returns {ReactNode} The rendered TagsList component.
 */
const TagsList = ({
  tags,
  editable = false,
  deletable = false,
  selectable = false,
  setTagFormData,
  setTagBeingEdited,
  deleteTag,
  handleTagSelection,
  isPresentInSelectedTags,
}) => {
  return (
    <ul role="list" className="mt-1" key={tags}>
      {tags.map((tag, index) => (
        <li
          className={`group flex px-2 py-1 items-center justify-between border-l-4  ${
            index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
          }`}
          key={tag.id}>
          {selectable && (
            <Checkbox
              role="checkbox"
              onChange={(_) => handleTagSelection(tag)}
              name="tag"
              value={tag}
              checked={isPresentInSelectedTags(tag)}
            />
          )}
          <span className="flex-1 pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
            {tag.name}
          </span>
          <div className="hidden group-hover:flex">
            {editable && (
              <div className="mr-2">
                <TableFieldButton
                  text={<PencilIcon className="w-4 h-4" />}
                  onClick={() => {
                    setTagBeingEdited(true);
                    setTagFormData(tag);
                  }}
                />
              </div>
            )}
            {deletable && (
              <div className="mr-4">
                <TableFieldButton
                  text={<TrashIcon className="w-4 h-4" />}
                  onClick={() => deleteTag(tag)}
                />
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

TagsList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  selectable: PropTypes.bool,
  setTagFormData: PropTypes.func.isRequired,
  setTagBeingEdited: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  handleTagSelection: PropTypes.func,
  isPresentInSelectedTags: PropTypes.func,
};

export default TagsList;
