const DropdownInput = ({
  options,
  valueKey,
  labelKey,
  width,
  name,
  onChange,
  value,
  styles,
}) => {
  return (
    <select
      className={`outline-none border-gray-300 rounded ${width || ""} ${
        styles || ""
      }`}
      name={name}
      value={value}
      onChange={onChange}>
      {options.map((o) => (
        <option value={o[valueKey]}>{o[labelKey]}</option>
      ))}
    </select>
  );
};

export default DropdownInput;
