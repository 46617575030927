import PageTitle from "#components/utils/PageTitle";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import _ from "lodash";

const headers = ["ASIN", "SKU", "Name", "Qty"];

const AllListView = ({ currentBatch, confirmDropoffWithConfirmation }) => {
  return (
    <div className="flex-1 max-w-4xl">
      <PageTitle>Total ({currentBatch?.workingList?.length}) </PageTitle>
      <div
        className="bg-EBEBEB mt-10 rounded-md border border-gray-400 p-4 overflow-auto"
        style={{ maxHeight: 800 }}>
        <table className="min-w-full divide-y divide-gray-200 -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {headers.map((header, headerIdx) =>
                headerIdx === 0 ? (
                  <th
                    scope="col"
                    className="pl-4 px-1 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ) : (
                  <th
                    scope="col"
                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                    key={headerIdx}>
                    {header}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {currentBatch.workingList.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.sku}
                </td>
                <td
                  className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                  alt={item.productName}
                  title={item.productName}>
                  {truncate(item.productName)}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {item.quantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-20 flex items-center justify-center space-x-4">
        <div className="text-2xl text-black w-64 text-center py-4">
          Have you picked all these items?
        </div>
        <div
          className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
          onClick={confirmDropoffWithConfirmation}>
          Yes
        </div>
      </div>
    </div>
  );
};

const truncate = (str) => {
  return str && str.length > 50 ? str.substring(0, 50) + "..." : str;
};

export default AllListView;
