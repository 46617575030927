const CycleCounterNewBatchRequest = ({ getNewBatch }) => (
  <div className="-mt-20 h-screen flex items-center justify-center space-x-4">
    <div
      className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
      onClick={getNewBatch}
    >
      Get New Batch
    </div>
  </div>
);

export default CycleCounterNewBatchRequest;
