import Modal from "#components/utils/Modal";

const BundleProductsDetail = ({ products, onClose }) => {
  return (
    <Modal
      negativeAction={() => onClose()}
      title={`Products in Bundle (${products.length})`}>
      <div className="overflow-y-auto" style={{ maxHeight: 500 }}>
        <table className="px-2 min-w-full divide-y divide-gray-200 mr-8">
          <thead className="p-4 bg-primaryAccent sticky top-0 left-0">
            <tr className="text-white font-montserratborder-left bg-primaryAccent">
              <th
                scope="col"
                className="pl-4 px-1 py-3 text-left tracking-wider font-medium">
                SKU
              </th>
              <th
                scope="col"
                className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                Name
              </th>
              <th
                scope="col"
                className="px-1 pl-4 py-3 text-left tracking-wider font-medium">
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr
                key={product.sku}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                <td
                  className={`p-5 text-left font-medium text-5F666B tracking-widerrounded-tl rounded-bl border-l-8  ${
                    index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                  }`}>
                  {product.sku}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider max-w-2xl">
                  {product.name}
                </td>
                <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                  {product.quantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default BundleProductsDetail;
