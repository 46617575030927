import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { XIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const IntegrationWarning = ({ daysLeft, dismissWarning }) => {
  return (
    <div
      className={`font-montserrat relative isolate flex items-center gap-x-6 overflow-hidden ${
        daysLeft < 30 ? "bg-red-600" : "bg-yellow-400"
      } px-6 py-2.5 sm:px-3.5 sm:before:flex-1`}>
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true">
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true">
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p
          className={`text-xl leading-6 ${
            daysLeft < 30 ? "text-white" : "text-gray-900"
          } mb-2`}>
          <strong className="font-semibold">Action Required: </strong>
          <div className="mt-4">
            {" "}
            Your Amazon (LWA) credentials are expiring soon. LWA credentials
            need to be rotated every 180 days. You will need to generate new
            credentials to ensure connectivity with Seller Central. Click{" "}
            <a
              href="https://developer-docs.amazon.com/sp-api/changelog/important-you-must-rotate-your-login-with-amazon-lwa-credentials-client-secrets-for-all-applications-every-180-days"
              className={`text-xl ${
                daysLeft < 30 ? "text-white" : "text-primaryAccent"
              } font-bold`}
              target="_blank">
              here
            </a>{" "}
            to view the official document and steps to be followed from Amazon.
          </div>
          <div className="mt-4 flex items-center">
            Days Remaining: {daysLeft}{" "}
            <button
              data-tip
              data-for="daysRemainingTips"
              className="border-none bg-transparent h-5 w-5 -mt-2 pl-2">
              <ExclamationCircleIcon className="h-8 w-8 cursor-pointer" />
            </button>
            <ReactTooltip
              id="daysRemainingTips"
              place="right"
              type="info"
              effect="solid">
              <div className="text-xl max-w-xl whitespace-normal">
                This is just an indicator. This is calculated from the time you
                entered your credentials into Hopstack, not from the time the
                credentials were generated on Seller Central.
              </div>
            </ReactTooltip>
          </div>
          <div className="mt-4 flex items-center">
            <div className="text-xl whitespace-normal">
              Once you've re-generated the LWA credentials, you'll need to
              logout of the integration on Hopstack and re-enter the
              credentials.
            </div>
          </div>
        </p>
        <Link
          to="/integrations"
          className="flex-none rounded-full bg-primaryAccent px-3.5 py-1 text-xl font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
          Enter new credentials now <span aria-hidden="true">&rarr;</span>
        </Link>
        <div
          onClick={dismissWarning}
          className="flex-none rounded-full bg-red-600 px-3.5 py-1 text-xl font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 cursor-pointer">
          Dismiss
        </div>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={dismissWarning}>
          <span className="sr-only">Dismiss</span>
          <XIcon
            className={`h-5 w-5 ${
              daysLeft < 30 ? "text-white" : "text-gray-900"
            }`}
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
};

export default IntegrationWarning;
