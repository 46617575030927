import { CheckIcon } from "@heroicons/react/solid";
import Modal from "#components/utils/Modal";
import { Tab } from "@headlessui/react";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../../utils/buildMarketplaceHyperlink";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ExpandedOrderOld = ({
  writable,
  order,
  negativeAction,
  selectedOrderLineItem,
  setSelectedOrderLineItem,
  onChangeSelectedOrderLineItem,
  updateOrderLineItem,
  multiAccountSupportEnabled,
  warehouses,
  marketplace,
  sellerId,
}) => {
  let tabs = {};
  tabs = {
    ...tabs,
    "Line Items": (
      <OrderLineItems
        writable={writable}
        orderLineItems={
          order.orderLineItems !== null && order.orderLineItems.length > 0
            ? order.orderLineItems
            : order.orderProducts
        }
        setSelectedOrderLineItem={setSelectedOrderLineItem}
        order={order}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        warehouses={warehouses}
        marketplace={marketplace}
        sellerId={sellerId}
      />
    ),
  };
  if (order.source === "FBA") {
    tabs["Shipment Plan"] = (
      <ExpandedShipmentPlan shipmentPlan={order.shipmentPlan} />
    );
  } else {
    tabs["Shipping Address"] = <ExpandedShippingAddress order={order} />;
  }

  tabs["Attributes"] = <ExpandedAttributes order={order} />;
  return (
    <div>
      <Modal
        negativeAction={negativeAction}
        title={`Order Details`}
        noPadding={true}
        positiveAction={negativeAction}
        positiveText="OK">
        <div className="bg-EFE9DC p-4 overflow-auto">
          <Tab.Group>
            <Tab.List className="flex items-center">
              <div className="flex-1 p-1 px-0 space-x-4 rounded-xl">
                {tabs &&
                  Object.keys(tabs).map((tab) => (
                    <Tab
                      key={tab}
                      className={({ selected }) =>
                        classNames(
                          "px-6 py-2.5 text-sm leading-5 font-regular rounded-full",
                          "focus:outline-none focus:ring-0 font-montserrat",
                          selected
                            ? "bg-white text-5F7174"
                            : "border-transparent bg-E1D3B8 border rounded-full text-primaryAccent",
                        )
                      }>
                      {tab}
                    </Tab>
                  ))}
              </div>
            </Tab.List>
            <Tab.Panels className="mt-2">
              {tabs &&
                Object.values(tabs).map((tabInner, idx) => (
                  <Tab.Panel
                    key={idx}
                    className="flex flex-col mt-4 border textWhite-300">
                    {tabInner}
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Modal>
    </div>
  );
};

const ExpandedShippingAddress = ({ order }) => {
  let address = order.shippingAddress || {};
  return (
    <div className="p-4">
      <div className="text-xl font-montserrat p-4 font-bold pl-2">
        Name: {address.name}
      </div>
      <div className="text-xl font-montserrat p-4 font-bold pl-2">
        Email: {address.email || order.email}
      </div>
      <div className="text-xl font-montserrat p-4 font-bold pl-2">
        Phone: {address.phone}
      </div>
      <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Line 1
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Line 2
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              City
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              State
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Zip
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Country
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
              {address.line1}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {address.line2}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {address.city}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {address.state}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {address.zip}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {address.country}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ExpandedAttributes = ({ order }) => {
  return (
    <div className="space-y-4 p-4">
      <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Attribute
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {order.attributes ? (
            Object.keys(order.attributes).map((item, idx) => (
              <tr key={idx} className="bg-white">
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
                  {item.toUpperCase()}
                </td>

                <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                  {[true, false].includes(order.attributes[item])
                    ? order.attributes[item] === true
                      ? "Yes"
                      : "No"
                    : order.attributes[item]}
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white">
              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
                No Attributes.
              </td>

              <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr"></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const ExpandedShipmentPlan = ({ shipmentPlan }) => {
  return (
    <div className="p-4">
      <div className="text-xl font-montserrat p-4 font-bold pl-2">
        Shipment Details:
      </div>
      <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Shipment ID
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Fulfillment Center
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Label Prep Type
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
              {shipmentPlan.ShipmentId}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {shipmentPlan.DestinationFulfillmentCenterId}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {shipmentPlan.LabelPrepType}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="text-xl font-montserrat p-4 font-bold pl-2">
        Shipment Address:
      </div>
      <table className="px-2 divide-y divide-gray-200 border-gray-400 border">
        <thead className="p-4 rounded-full bg-primaryAccent px-12">
          <tr className="text-textWhite font-montserrat border-left px-12">
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Name
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Address
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              City
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              State
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Country
            </th>
            <th
              scope="col"
              className="px-2 pl-4  py-3 text-left tracking-wider font-medium">
              Postal Code
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white">
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
              {shipmentPlan.ShipToAddress?.Name}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {shipmentPlan.ShipToAddress?.AddressLine1}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {shipmentPlan.ShipToAddress?.City}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {shipmentPlan.ShipToAddress?.StateOrProvinceCode}
            </td>
            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {shipmentPlan.ShipToAddress?.CountryCode}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {shipmentPlan.ShipToAddress?.PostalCode}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="text-xl font-montserrat p-4 font-bold pl-2">Items:</div>
      <PlanOrderLineItems items={shipmentPlan.Items} />
    </div>
  );
};

const PlanOrderLineItems = ({ items }) => {
  const headers = ["Seller SKU", "FN SKU", "Quantity"];
  return (
    <table className="px-2 divide-y divide-gray-200 border border-gray-400 mb-4">
      <thead className="p-4 rounded-full bg-primaryAccent px-12">
        <tr className="text-textWhite font-montserrat border-left px-12">
          {headers.map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr
            key={item.SellerSKU}
            className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
              }`}>
              {item.SellerSKU}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {item.FulfillmentNetworkSKU}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {item.Quantity}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const OrderLineItems = ({
  writable,
  orderLineItems,
  setSelectedOrderLineItem,
  order,
  multiAccountSupportEnabled,
  marketplace,
  sellerId,
  warehouses,
}) => {
  const headers = () => {
    const returnHeaders = ["SKU", "Location", "Name"];
    if (["FBA", "Amazon FBM"].includes(order.source)) {
      returnHeaders.push("ASIN");
    }
    if (order.source === "FBA") {
      returnHeaders.push("FNSKU");
    }
    returnHeaders.push("Quantity");
    returnHeaders.push("Form Factor");
    if (orderLineItems.findIndex((i) => !!i.lotId) !== -1) {
      returnHeaders.push("Lot/Batch ID");
    }
    if (orderLineItems.findIndex((i) => !!i.nestedFormFactorId) !== -1) {
      returnHeaders.push("LPN");
    }
    if (multiAccountSupportEnabled) {
      returnHeaders.push("Marketplace");
      returnHeaders.push("Seller");
    }
    returnHeaders.push("Status");

    return returnHeaders;
  };

  return (
    <table className="px-2 min-w-full divide-y divide-gray-200">
      <thead className="p-4 rounded-full bg-primaryAccent px-12">
        <tr className="text-textWhite font-montserrat border-left px-12">
          {headers().map((header, headerIdx) =>
            headerIdx === 0 ? (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ) : (
              <th
                scope="col"
                className="px-2 pl-4  py-3 text-left tracking-wider font-medium"
                key={headerIdx}>
                {header}
              </th>
            ),
          )}
        </tr>
      </thead>
      <tbody>
        {orderLineItems.map((item, index) => (
          <tr
            key={item.id}
            className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
            <td
              className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
                index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
              }`}>
              {item.sku}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {item.binLocation}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium  text-5F666B  tracking-wider rounded-br rounded-tr">
              {truncate(item.productName)}
            </td>

            {headers().includes("ASIN") && (
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {buildMarketplaceHyperlink(item.asin, "ASIN", item.source)}
              </td>
            )}
            {headers().includes("FNSKU") && (
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {item.fnSku}
              </td>
            )}

            <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
              {item.quantity}
            </td>

            <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
              {item.formFactor}
            </td>

            {headers().includes("Lot/Batch ID") && (
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {item.lotId || "-"}
              </td>
            )}

            {headers().includes("LPN") && (
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {item.nestedFormFactorId || "-"}
              </td>
            )}

            {headers().includes("Marketplace") && (
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {marketplace}
              </td>
            )}

            {headers().includes("Seller") && (
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                {sellerId}
              </td>
            )}

            <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
              {item.status}
            </td>
            {headers().includes("Action") && (
              <td className="px-2 pl-4  py-1 text-left font-medium text-5F666B tracking-wider rounded-br rounded-tr">
                <TableFieldButton
                  onClick={() => setSelectedOrderLineItem(item)}
                  text={<PencilIcon className="w-6 h-6" />}
                  disabled={!writable}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Rates = ({ rates }) => (
  <table className="px-2 min-w-full divide-y divide-gray-200">
    <thead className="p-4 rounded-full">
      <tr className="text-textWhite font-montserratborder-left bg-primaryAccent">
        <th
          scope="col"
          className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
          Service Name
        </th>

        <th
          scope="col"
          className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
          Charge Weight
        </th>
        <th
          scope="col"
          className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
          Total Charge
        </th>
        <th
          scope="col"
          className="px-2 pl-4 py-3 text-left tracking-wider font-medium">
          Selected
        </th>
      </tr>
    </thead>
    <tbody>
      {rates.map((rate, index) => (
        <tr
          key={index}
          className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
          <td
            className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8  ${
              index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
            }`}>
            {rate.serviceName}
          </td>
          <td className="px-2 pl-4 py-1 text-left font-medium text-5F666B tracking-wider">
            {rate.chargeWeight.value} {rate.chargeWeight.unit}
          </td>
          <td className="px-2 pl-4 py-1 text-left font-medium text-5F666B tracking-wider">
            ${_.round(rate.totalCharge.amount, 2).toFixed(2)}
          </td>
          <td className="px-2 pl-4 py-1 text-left font-medium text-5F666B tracking-wider">
            {rate.selected ? (
              <CheckIcon className="w-8 h-8 text-green-400" />
            ) : null}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default ExpandedOrderOld;

const truncate = (str) => {
  return str && str.length > 40 ? str.substring(0, 40) + "..." : str;
};
