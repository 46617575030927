import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";

const PickupsByTime = ({ data, currentTimezone }) => {
  const renderColorfulLegendText = (value, entry) => {
    return <span className="text-gray-900 text-xl">{value}</span>;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        width={400}
        height={200}
        data={data}
        syncId="anyId"
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="_id.hour" />
        <YAxis label={<CustomizedLabel value="# Pickups By Hour" />}></YAxis>
        <Legend
          formatter={renderColorfulLegendText}
          margin={{ top: 30 }}
          wrapperStyle={{ marginTop: 100 }}
        />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="total"
          stroke="#000000"
          fill="#8884d8"
          name={`Hour of Day (${currentTimezone})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const CustomizedLabel = ({ value }) => (
  <text
    x={-60}
    y={10}
    dy={20}
    fill={"#000000"}
    fontSize={20}
    textAnchor="left"
    transform={`rotate(-90 100 100)`}
    letterSpacing="1.5"
  >
    {value}
  </text>
);

export default PickupsByTime;
