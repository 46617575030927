// This file contains constants that are related to bundles, products.

/**
 * Object representing product with mandatory fields that graphql typedef in the server expects.
 * Refer ProductInput in server side typedef for more info
 */
const NEW_PRODUCT = Object.freeze({
  warehouse: "", // must be overwritten
  customer: "", // must be overwritten
  uomConfiguration: [],
  active: true,
  typeOfProduct: "STANDALONE",
});

/**
 * Object representing bundle as a product with mandatory fields that graphql typedef in the server expects.
 * Refer BundleAsProductInput in server side typedef for more info
 */
const NEW_BUNDLE_AS_PRODUCT = Object.freeze({
  name: "",
  source: "",
  warehouse: "", // must be overwritten
  customer: "", // must be overwritten
  active: true,
  sku: "",
  typeOfProduct: "BUNDLE",
  listingStatus: "ACTIVE",
  products: [], // must not be empty
  baseUom: "",
  uomConfiguration: [], // must not be empty
});

export default {
  NEW_PRODUCT,
  NEW_BUNDLE_AS_PRODUCT,
};
