import OrderPicker from "./orderPicker";
import { useLDClient } from "launchdarkly-react-client-sdk";
import featureFlags from "#constants/feature-flags";
import { Link } from "react-router-dom";

const Picker = () => {
  const ldClient = useLDClient();
  const isBundlingWorkflowEnabled =
    ldClient?.variation(featureFlags.IS_BUNDLING_WORKFLOW, false) ?? false;

  if (isBundlingWorkflowEnabled) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="py-10 flex flex-col items-center justify-center">
          <Link to="/picker/order">
            <div className="text-2xl text-white w-72 rounded-md bg-2C7695 text-center py-4">
              Pick for Order
            </div>
          </Link>
          <Link to="/picker/bundle">
            <div className="mt-10 text-2xl text-white w-72 rounded-md bg-2C7695 text-center py-4">
              Pick for Bundle
            </div>
          </Link>
        </div>
      </div>
    );
  }

  // If bundling workflow is not enable just return order picking as the default.
  return <OrderPicker />;
};

export default Picker;
