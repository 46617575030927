import { useEffect, useState } from "react";
import PageTitle from "#components/utils/PageTitle";
import _ from "lodash";
import WorkflowProductImages from "#components/products/WorkflowProductImages";
import NumberField from "#components/utils/NumberField";
import TextField from "#components/utils/TextField";
import Toggle from "#components/utils/Toggle";
import NestedEntityItemsList from "#components/putaway/consignment/NestedEntityItemsList";

const DetailedView = ({
  currentSku,
  confirmItem,
  cancelItem,
  scanBarcode,
  currentProduct,
  requireConsignmentForPutaway,
  nestedEntityItems,
  setNestedEntityItems,
  getNestedEntityItems,
}) => {
  const [scanIndividualItems, setScanIndividualItems] = useState(false);

  const isNestedEntity =
    (currentSku.currentItem.tote ||
      currentSku.currentItem.palletId ||
      currentSku.currentItem.nestedFormFactorId) &&
    !currentSku.currentItem.receivingBatchId; // this will be present for items scanned within a nested entity.

  return (
    <div className="flex-col items-center justify-center max-w-7xl mx-auto">
      {requireConsignmentForPutaway && isNestedEntity && (
        <div className="flex">
          <Toggle
            enabled={scanIndividualItems}
            setEnabled={() => {
              if (scanIndividualItems) {
                setScanIndividualItems(false);
                setNestedEntityItems([]);
              } else {
                setScanIndividualItems(true);
                getNestedEntityItems(currentSku.currentItem.id);
              }
            }}
          />
          <span className="my-auto ml-2 text-md font-medium">
            Scan individual Items
          </span>
        </div>
      )}

      {scanIndividualItems && isNestedEntity ? (
        <NestedEntityItemsList
          nestedEntityItems={nestedEntityItems}
          scanBarcode={scanBarcode}
        />
      ) : (
        <BatchDetails
          currentSku={currentSku}
          currentProduct={currentProduct}
          scanBarcode={scanBarcode}
          confirmItem={confirmItem}
          cancelItem={cancelItem}
        />
      )}
    </div>
  );
};

const BatchDetails = ({
  currentSku,
  currentProduct,
  scanBarcode,
  confirmItem,
  cancelItem,
}) => {
  const [barcode, setBarcode] = useState("");
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity(1);
  }, [currentSku?.currentItem?.id]);

  const renderDisplayFields = () => {
    const returnObjects = [];
    let workflow = currentSku.workflow;
    if (workflow) {
      workflow.displayFields.map((item, idx) => {
        if (currentSku.currentItem) {
          if (
            currentSku.currentItem[item.value] &&
            item.value !== "binLocation"
          ) {
            returnObjects.push(
              <LineItemValue
                value={
                  !Array.isArray(currentSku.currentItem[item.value])
                    ? currentSku.currentItem[item.value]
                    : currentSku.currentItem[item.value]?.join(", ")
                }
                title={item.name}
                idx={idx}
              />,
            );
          } else if (item.value === "binLocation") {
            returnObjects.push(
              <LineItemValueWithOptions
                value={currentSku.currentItem.binLocation}
                title="Bin"
                idx={6}
                setBarcode={setBarcode}
              />,
            );
          }
        }
      });
    }
    return <>{returnObjects}</>;
  };

  return (
    <div className="pt-8">
      <PageTitle>Product Details</PageTitle>
      <div className="grid grid-cols-3">
        <div
          className={`flex-1 bg-gray-100 w-full ${
            currentProduct?.images?.length > 0 ? "col-span-2" : "col-span-3"
          }`}>
          <div className="text-xl font-medium text-454A4F space-y-2">
            {renderDisplayFields()}
          </div>
        </div>
        {currentProduct?.images && (
          <div className="flex-1 flex items-center justify-center bg-gray-300">
            <WorkflowProductImages
              images={[
                currentProduct.images.find((i) => i.purpose === "OPERATIONS") ||
                  currentProduct.images[0],
              ].filter(Boolean)}
            />
          </div>
        )}
      </div>
      <div className="w-96 bg-white mt-8 items-center justify-center flex mx-auto">
        <TextField
          type="text"
          id="name"
          label="Enter or Scan Value"
          placeholder=" "
          onChange={(e) => setBarcode(e.target.value)}
          value={barcode}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              scanBarcode({
                data: barcode,
                receivingBatchId:
                  currentSku.currentItem.receivingBatchId || null,
              });
              setBarcode("");
            }
          }}
          autoFocus={true}
        />
      </div>
      {currentSku.currentItem.name && (
        <div className="flex mt-10 text-center items-center justify-center">
          <div>
            <label className="block text-left mb-2">
              <span className="font-medium font-montserrat text-454A4F text-2xl pb-4">
                Quantity
              </span>
            </label>
            <NumberField
              onChange={(e) => {
                setQuantity(
                  isNaN(parseInt(e.target.value)) === false
                    ? parseInt(e.target.value)
                    : null,
                );
              }}
              placeholder="Qty"
              value={quantity}
              className="p-4 text-lg rounded-md w-36"
              min="0"
            />
          </div>
        </div>
      )}

      <div className="mt-20 flex items-center justify-center space-x-6">
        <div
          className="text-2xl text-white w-64 rounded-md bg-2C7695 text-center py-4"
          onClick={() =>
            confirmItem({ id: currentSku.currentItem.id, quantity })
          }>
          Confirm Item
        </div>
        <div
          className="text-2xl text-white w-64 rounded-md bg-red-600 text-center py-4"
          onClick={cancelItem}>
          Cancel
        </div>
      </div>
    </div>
  );
};

const LineItemValue = ({ title, value, idx }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md">
      {value}
    </div>
  </div>
);

const LineItemValueWithOptions = ({ title, value, idx, setBarcode }) => (
  <div className="w-full max-w-4xl flex space-x-1 shadow-md" key={idx}>
    <div
      className={`text-2C7695 bg-white w-64 p-4 rounded-bl-md rounded-tl-md items-end justify-end flex border-l-8 ${
        idx % 2 === 0 ? "border-primaryAccent" : "border-F4C261"
      }`}>
      {title}
    </div>
    <div className="bg-white flex-1 border p-4 rounded-tr-md rounded-br-md">
      {value}
    </div>
  </div>
);

export default DetailedView;
