import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import PageTitle from "#components/utils/PageTitle";
import ExpandedSku from "#components/inventory/ExpandedSku";
import TableFieldButton from "#components/utils/TableFieldButton";
import { ChevronUpIcon, PencilIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import _ from "lodash";

import { GET_SKU } from "#queries";
import { AppStateContext } from "#contexts/appState";

const taskHeaders = ["Name", "SKU", "ASIN", "Completion", "Status", "Action"];
const bundleHeaders = ["Name", "SKU", "ASIN", "Status", "Action"];

const BundlerListView = ({
  bundlingTasks,
  scanBundle,
  warehouses,
  customers,
  queryFilters,
  onChangeFilter,
}) => {
  const getSpecificProduct = useQuery(GET_SKU);
  const appState = useContext(AppStateContext);

  const [expandedTask, setExpandedTask] = useState(null);
  const [displayBundle, setDisplayBundle] = useState(null);

  const toggleExpand = (e, taskId) => {
    if (["sku", "asin"].includes(e.target.id)) return;

    if (expandedTask === taskId) {
      setExpandedTask(null);
    } else {
      setExpandedTask(taskId);
    }
  };

  const fetchSku = (id) => {
    getSpecificProduct.fetchData({ id });
  };

  useEffect(() => {
    if (getSpecificProduct.data && getSpecificProduct.data.specificInventory) {
      const product = {
        ...getSpecificProduct.data.specificInventory,
        uomConfiguration: getSpecificProduct.data.specificInventory
          .uomConfiguration
          ? getSpecificProduct.data.specificInventory.uomConfiguration
          : [],
      };
      setDisplayBundle(product);
    }

    if (getSpecificProduct.error) {
      appState.setAlert(getSpecificProduct.error.message, "error", 5000);
    }
  }, [
    getSpecificProduct.loading,
    getSpecificProduct.error,
    getSpecificProduct.data,
  ]);

  return (
    <div className="flex-1 w-4/5 p-8">
      <div className="flex justify-between">
        <PageTitle>Total ({bundlingTasks?.length ?? 0}) </PageTitle>
      </div>
      <div className="bg-EBEBEB mt-10 rounded-md">
        <table className="w-full divide-y divide-gray-200 -mt-4">
          <thead className="p-4 rounded-full">
            <tr className="text-primaryAccent font-montserratborder-left">
              {taskHeaders.map((header, headerIdx) => (
                <th
                  scope="col"
                  className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                  key={headerIdx}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bundlingTasks.length === 0 ? (
              <tr>
                <td
                  className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 border-F4C261`}>
                  No Tasks Available.
                </td>
              </tr>
            ) : (
              bundlingTasks.map((task, index) => (
                <React.Fragment key={task.id}>
                  <tr
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    } cursor-pointer  hover:bg-EBEBEB hover:text-2C7695`}
                    onClick={(e) => toggleExpand(e, task.id)}>
                    <td
                      className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 ${
                        index % 2 === 0
                          ? "border-F4C261"
                          : "border-primaryAccent"
                      }`}>
                      {task.bundledInventory[0].productName}
                    </td>
                    <td
                      className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider underline cursor-pointer text-primaryAccent"
                      onClick={() => fetchSku(task.details.bundle.id)}
                      id="sku">
                      {task.bundledInventory[0].sku}
                    </td>
                    <td
                      className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider"
                      id="asin">
                      {buildMarketplaceHyperlink(
                        task.bundledInventory[0].asin,
                        "ASIN",
                      ) || "-"}
                    </td>
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                      {task.completion} %
                    </td>
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                      {renderButton(task.status)}
                    </td>
                    <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                      {expandedTask === task.id ? (
                        <ChevronUpIcon className="w-6 h-6 text-green-400 m-auto" />
                      ) : (
                        <ChevronDownIcon className="w-6 h-6 text-green-400 m-auto" />
                      )}
                    </td>
                  </tr>
                  {/* sub-table for bundle details */}
                  {expandedTask === task.id && (
                    <tr className="bg-gray-100">
                      <td colSpan={taskHeaders.length}>
                        <div className="p-4">
                          <table className="w-full">
                            <thead className="p-4 rounded-full">
                              <tr className="text-primaryAccent font-montserratborder-left">
                                {bundleHeaders.map((header, headerIdx) => (
                                  <th
                                    scope="col"
                                    className="px-1 pl-4 py-3 text-left tracking-wider font-medium text-lg"
                                    key={headerIdx}>
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {task.bundledInventory?.map((bundle) => (
                                <tr
                                  key={bundle.uniqueIdentifier}
                                  className={`${
                                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                  } hover:bg-EBEBEB hover:text-2C7695`}>
                                  <td
                                    className={`p-5 text-left font-semibold text-primaryAccent tracking-widerrounded-tl rounded-bl border-l-8 ${
                                      index % 2 === 0
                                        ? "border-F4C261"
                                        : "border-primaryAccent"
                                    }`}>
                                    {bundle.productName}
                                  </td>
                                  <td
                                    className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider underline cursor-pointer text-primaryAccent"
                                    onClick={() => fetchSku(bundle.productId)}>
                                    {bundle.sku}
                                  </td>
                                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider cursor-pointer">
                                    {buildMarketplaceHyperlink(
                                      bundle.asin,
                                      "ASIN",
                                    ) || "-"}
                                  </td>
                                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                                    {renderButton(bundle.status)}
                                  </td>
                                  <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                                    <div
                                      className="rounded-full px-2 py-1 cursor-pointer text-lg bg-blue-500 text-center text-white"
                                      onClick={() =>
                                        scanBundle(
                                          task.id,
                                          bundle.uniqueIdentifier,
                                        )
                                      }>
                                      Work on this
                                    </div>
                                  </td>
                                  {/* <td className="pl-4 px-1 py-1 text-left font-medium text-5F666B tracking-wider">
                                    <div className="flex space-x-4 items-center justify-center">
                                      {bundle.status !== "COMPLETE" && (
                                        <TableFieldButton
                                          onClick={() =>
                                            scanBundle(
                                              task.id,
                                              bundle.uniqueIdentifier,
                                            )
                                          }
                                          text={
                                            <PencilIcon className="w-6 h-6" />
                                          }
                                        />
                                      )}
                                    </div>
                                  </td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
      {displayBundle && (
        <ExpandedSku
          fetchedSku={displayBundle}
          negativeAction={() => setDisplayBundle(null)}
          customers={customers}
        />
      )}
    </div>
  );
};

const renderButton = (status) => {
  switch (status) {
    case "COMPLETE":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-50BFC3 text-center text-454A4F">
          Completed
        </div>
      );
    case "IN_PROGRESS":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-red-500 text-center text-white">
          Inprogress
        </div>
      );
    case "UNPROCESSED":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-EFE9DC text-center text-454A4F">
          Unprocessed
        </div>
      );
    case "CANCELLED":
      return (
        <div className="rounded-full px-2 py-1 text-lg bg-red-500 text-center text-white">
          Cancelled
        </div>
      );
  }
};

export default BundlerListView;
