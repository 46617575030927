import { DotsVerticalIcon } from "@heroicons/react/outline";
import PopoverOnTableAction from "#components/utils/PopoverOnTableAction";

const CatalogProductActions = ({ actionsOnProduct, product }) => {
  return (
    <div className="relative">
      <PopoverOnTableAction
        icon={<DotsVerticalIcon className="h-6 w-6 text-[#737579]" />}
        panelClassName={
          "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
        }
        customClassName={"py-4"}>
        {actionsOnProduct(product).map((action) => {
          return (
            <div
              className={`p-3 rounded-lg font-medium whitespace-nowrap cursor-pointer ${
                action.disabled
                  ? "cursor-not-allowed text-gray-200"
                  : "text-unselectedTextGray hover:text-primaryAccent hover:bg-hoverHighlight"
              }`}
              onClick={
                action.onClick && !action.disabled
                  ? action.onClick
                  : () =>
                      action.disabledMessage
                        ? alert(action.disabledMessage)
                        : console.log(action.value, action.name)
              }>
              <div className="flex space-x-2">
                <span>{action.icon ? action.icon : null}</span>
                <span>{action.name}</span>
              </div>
            </div>
          );
        })}
      </PopoverOnTableAction>
    </div>
  );
};

export default CatalogProductActions;
